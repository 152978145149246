import React from "react";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/app.css";
import "./Styles/style.css";
import "./Styles/theme-responsive.css";
import { connect } from "react-redux";
import {
  RegisterFooter,
  RegisterHeader,
  NewRegisterWizard,
  withRouter,
} from "../../CommonComponents";
import { Line } from "rc-progress";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import kids from "../../assets/registerImages/parent-kid-car.svg";

import { markReadToDashboard } from "../../../common/actions/userAccount/userAccountAction";
import { withTranslation } from "react-i18next";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class NewChooseRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chooseRole: -1,
      submitButton: true,
      roleData: this.setRoles(),
    };
  }

  setRoles = () => {
    return [
      {
        id: 1,
        title: this.props.t("boardCardScr-titlePrimary"),
        content: this.props.t("boardCardScr-descriptPrimary"),
        select: true,
      },
      {
        id: 2,
        title: this.props.t("boardCardScr-titleDelagate"),
        content: this.props.t("boardCardScr-descriptDelagate"),
      },
      {
        id: 3,
        title: this.props.t("boardCardScr-titleSure"),
        content: this.props.t("boardCardScr-descriptSure"),
      },
    ];
  };
  changeRole = (id) => {
    this.setState({ chooseRole: id, submitButton: false });
  };

  submitForm = () => {
    const { chooseRole } = this.state;
    const { location } = this.props;
    let screenFrom = null;
    if (location && location.state) {
      screenFrom = location.state.screenFrom;
    }

    if (chooseRole === -1) {
      toast.error(this.props.t("role_select_error"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      return;
    }

    if (chooseRole == 1) {
      this.props.navigate("/isParentSelect", {
        state: { screenFrom: this.props.location.state.screenFrom },
      });
    } else {
      if (
        screenFrom === "dashboard" ||
        screenFrom === "childManagement" ||
        screenFrom === "account"
      ) {
        this.props.navigate(-1);
      } else {
        this.props.navigate("/childManagement");
      }
    }
  };

  displayRoles = () => {
    const { chooseRole, roleData } = this.state;
    const roleDetails = roleData.map((item, index) => (
      <div
        className={chooseRole === item.id ? "selected card" : "card"}
        onClick={() => {
          this.changeRole(item.id);
        }}
        key={item.id}
      >
        <h3 className="title-3">{item.title}</h3>
        <p className="helptext">{item.content}</p>
      </div>
    ));
    return <div className="roleList">{roleDetails}</div>;
  };

  render() {
    const { location } = this.props;
    let screenFrom = null;
    if (location && location.state) {
      screenFrom = location.state.screenFrom;
    }
    
    return (
      <section className="registration accountexists-sect">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  
                  <div className="container-sect">
                    <div className="mb-30 text-center">
                      <img src={kids} alt="PikMyKid" title="PikMyKid" />
                    </div>

                    <div className="title-sect mb-40">
                      <h3 className="title-3">
                        {this.props.t("ChooseRoleScr-helpUs")}
                      </h3>
                      <p className="helptext">
                        {this.props.t("ChooseRoleScr-paragraph")}
                      </p>
                    </div>
                    {this.displayRoles()}
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          this.submitForm();
                        }}
                      >
                        {this.props.t("regNameScr-next")}
                      </button>
                    </div>
                    {screenFrom === "dashboard" ||
                    screenFrom === "childManagement" ||
                    screenFrom === "account" ? (
                      <div className="bottom-sect text-center">
                        <button
                          className="btn close-button goback-btn"
                          type="button"
                          onClick={() => {
                            this.props.navigate(-1);
                          }}
                        >
                          {this.props.t("goback")}
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <ToastContainer />
                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    registeredPassword: state.userAccount.registeredPassword,
    registeredFirstname: state.userAccount.registeredFirstname,
    registeredLastname: state.userAccount.registeredLastname,
    getUserInfoResponse: state.userAccount.getUserInfoResponse,
    error: state.userAccount.error,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doMarkReadToDashboard: () => dispatch(markReadToDashboard()),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(NewChooseRole))
);
