import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Colors } from "../../../../theme";
import {
  filterByTypes,
  getMarkStyle,
  canEarlyDismissToday,
  getDismissalInfo,
  earlyDismissNeedPassword,
  getLanguageCode,
  isEarlyDismissalAvailable,
  getKidsName,
  getCurrentThemeColor,
} from "../../../../helper/utility";
import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
  ssoUser_generateOTPForTransaction,
  ssoUser_generateOTPForTransactionClear,
} from "../../../../common/actions/userAccount";
import GLOBAL_CONST from "../../../../helper/AppConstants";

import { Line } from "rc-progress";
import "../../../ChildManagement/styles/style.css";
import MultikidsEarlyDismissalPickup from "./MultikidsEarlyDismissalPickup";
import { withTranslation } from "react-i18next";

import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";

import {
  getPickupEntries,
  getPickupEntriesClear,
  getEarlyDismissalsForDateRange,
  getEarlyDismissalsForDateRangeClear,
} from "../../../../common/actions/students";

import { createLoadingSelector } from "../../../../common/reducers/selectors";

const loadingSelector = createLoadingSelector([
  "change_delegation",
  "sso_user_send_otp_transaction",
]);

class MultikidsCreateEarlyDismissal extends React.Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      selectedOption: null,
    };
    let selectDismissalDate = this.props.selectDate;

    const school = this.props.school;

    const firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);
    if (!selectDismissalDate) {
      selectDismissalDate = new Date();
    }
    if (moment(selectDismissalDate).isBefore(moment(), "date")) {
      selectDismissalDate = new Date();
    }

    const isSelectedPriorToFirstDay = moment(firstDayOfSchool).isAfter(
      moment(selectDismissalDate)
    );
    if (isSelectedPriorToFirstDay) {
      selectDismissalDate = firstDayOfSchool;
    }

    this.state = {
      selectedDate: selectDismissalDate,
      selectedPreviousDate: selectDismissalDate,
      selectedOption: 0,
      previousSelectedOptions: null,
      isFromDateTimePickerVisible: false,
      isUntilDateTimePickerVisible: false,
      lastFetchedDate: null,
      currentPageIndex: 0,
      showTimePicker: true,
      pickedTime: new Date().setMinutes(new Date().getMinutes() + 0),
      earlyDismissalPickupData: {},
      isSubmitNext: false,
      password: "",
      isShowOTPTxt: false,
      otp: "",
      isCollapse: true,
      markedDates: {},
      showCalendarPicker: false,
    };
    this.earlyDismissalPickup = React.createRef();
  }

  componentDidMount() {
    this.dosetHelpList();
    this.getDismissalsList();
  }
  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "ChildManagement",
      screen: GLOBAL_CONST.SCREEN_NAMES.SELECT_WHEN,
      title: this.props.t("selectWhenScr-selectWhen"),
    });
  };

  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  getDismissalsList = () => {
    const {
      doGetPickupEntries,
      school,
      doGetEarlyDismissalsForDateRange,
      selectedKids,
    } = this.props;
    const { isLastDayOfSchoolPast } = this.state;

    let selectedKidsIds = selectedKids.map((child) => {
      return child.StudentID;
    });

    let firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);

    let monthStart = new Date();
    const diffFirstDayofSchool = moment(firstDayOfSchool).diff(
      moment(),
      "months"
    );

    if (diffFirstDayofSchool < -12) {
      endDate = moment().add(12, "M").format("MM-DD-YYYY");
      monthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    } else {
      monthStart = firstDayOfSchool;
    }

    let monthEnd = new Date(school.Dates.LastDayOfSchool);

    var startDate = moment(monthStart).format("MM-DD-YYYY");
    var endDate = moment(monthEnd).format("MM-DD-YYYY");

    if (isLastDayOfSchoolPast) {
      endDate = moment().add(12, "M").format("MM-DD-YYYY");
    }

    doGetPickupEntries({
      studentID: selectedKidsIds[0],
      startDate,
      endDate,
    });

    var eStartDate = moment(monthStart).format("YYYY-MM-DDTHH:mm:ss");
    var eEndDate = moment(monthEnd).format("YYYY-MM-DDTHH:mm:ss");

    doGetEarlyDismissalsForDateRange({
      StudentID: selectedKidsIds[0],
      StartDate: eStartDate,
      EndDate: eEndDate,
    });
  };

  changeMonth = (value) => {
    this.calendar.addMonth(value);
    const { currentSelectedMonth } = this.state;
    const currMonth = moment(currentSelectedMonth).add(value, "M");
    this.setState({
      currentSelectedMonth: currMonth,
      selectedDate: currMonth.toDate(),
    });
  };

  onLeftFlingHandlerStateChange = () => {
    this.changeMonth(1);
  };

  onRightFlingHandlerStateChange = ({ nativeEvent }) => {
    this.changeMonth(-1);
  };

  saveChangesCurrentPageIndex = (currentPageIndex) => {
    this.setState({ currentPageIndex });
  };

  setSelectedDate = (day) => {
    var newDate = day.setHours(0, 1, 0, 0);
    this.setState(
      {
        selectedDate: newDate,
      },
      () => {
        this.getDayRecord("from");
      }
    );
  };

  showDayDetail = (day) => {
    const { markedDates } = this.state;
    const dt = new Date(day.timestamp);
    const selectDateStr = moment(dt).format("YYYY-MM-DD");
    const markedRecord = markedDates[selectDateStr];

    if (markedRecord && markedRecord.record) {
      const dismissalInfo = getDismissalInfo(markedRecord.record, this.props);
      var day2 = moment(markedRecord.record.CalendarDate).format(
        "ddd, MMM Do YYYY"
      );
      toast.info(
        `${dismissalInfo.dismissalText} ${dismissalInfo.dismissalTextSubText}`,
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
        }
      );
    }
  };

  getDayRecord(type) {
    const setDate = type === "from";
    const markedDates = { ...this.state.markedDates };
    var selectDateStr = moment(new Date(setDate)).format("YYYY-MM-DD");

    for (const key in markedDates) {
      if (key === selectDateStr) {
        var selectedMarked = {
          selected: true,
          selectedColor: Colors.blue,
          record: markedDates[key].record,
        };
        markedDates[key] = selectedMarked;
      } else {
        markedDates[key] = getMarkStyle(markedDates[key].record);
      }
    }
    this.setState({ markedDates });
  }

  submitNext = () => {
    const { selectedDate, selectedOption, pickedTime } = this.state;
    const ChildInfo = this.props.ChildInfo;
    const school = this.props.school;
    let selectData = {};

    if (selectedDate == "Select Date") {
      alert(this.props.t("selectWhenScr-selectDate"));
    }
    if (pickedTime == null) {
      alert(this.props.t("earlyDisScr-selectTime"));
    } else {
      selectData = {
        selectedOption,
        showDate: selectedDate,
      };
      if (
        school.ReunificationEnabled &&
        moment(selectedDate).isSame(moment(), "date")
      ) {
        alert(this.props.t("emergency_reunification_pickup_msg"));
        return;
      }

      const result = isEarlyDismissalAvailable(
        school,
        selectedDate,
        pickedTime,
        this.props
      );
      if (result.success === false) {
        alert(result.message);
        return;
      }

      this.showSelectHowUI(selectData);
    }
  };

  showSelectHowUI = (selectData) => {
    this.setState({
      showSelectHowUIFlag: !this.state.showSelectHowUIFlag,
      earlyDismissalPickupData: selectData,
    });
    this.dosetHelpList();
  };

  renderLegendUI = () => {
    return (
      <div className={`datp-col show-legend untilLegend`}>
        <div className="legend-holder">
          <h5 className="legend-title">{this.props.t("Legend")}</h5>
          <ul className="unstyle-list legend-list">
            <li className="green-legend" key={"green-legend"}>
              {this.props.t("Approved_Pickup_Change_Text")}
            </li>
            <li className="black-legend" key={"black-legend"}>
              {this.props.t("Approved_EarlyPickup_Change_Text")}
            </li>
            <li className="red-legend" key={"red-legend"}>
              {this.props.t("Mark_Absent_Text")}
            </li>
            <li className="orange-legend" key={"orange-legend"}>
              {this.props.t("Pending_Approval_Text_p")}
            </li>
            <li className="gray-legend" key={"gray-legend"}>
              {this.props.t("Pending_Approval_Text_e")}
            </li>

            <h6 className="text_italic" key={"pending-legend"}>
              {this.props.t("Pending_Approval_Text2")}
            </h6>
          </ul>
        </div>
      </div>
    );
  };

  saveChangesManuallExcludedDates = (manuallExcludedDates) => {
    this.setState({ manuallExcludedDates });
  };

  getSelectHowUI = () => {
    const { earlyDismissalPickupData, selectedDate, pickedTime } = this.state;

    const { ChildInfo, selectedKids, school } = this.props;

    return (
      <MultikidsEarlyDismissalPickup
        onRef={(ref) => (this.earlyDismissalPickup = ref)}
        school={school}
        selectData={earlyDismissalPickupData}
        doCallbackRefresh={this.props.doCallbackRefresh}
        selectedDate={selectedDate}
        pickedTime={pickedTime}
        selectedKids={selectedKids}
      />
    );
  };

  selectDOW = (item) => {
    let DOW = [...this.state.DOW];
    let selectedDays = { ...this.state.selectedDays };

    item.isSelected = !item.isSelected;
    DOW[item.id] = item;
    if (item.isSelected) {
      selectedDays[item.value.toLowerCase()] = true;
    } else {
      delete selectedDays[item.value.toLowerCase()];
    }
    this.setState({ DOW, selectedDays });
  };

  renderDOW = () => {
    let days = [];
    const { DOW } = this.state;
    for (var i = 0; i < DOW.length; i++) {
      const item = DOW[i];
      days.push(
        <button
          type="button"
          className={
            !item.isSelected
              ? "selected-weekday-btn"
              : "selected-weekday-btn selectedWeekDay"
          }
          onClick={() => {
            this.selectDOW(item);
            this.buildRecurringDates();
          }}
        >
          {item.DOW}
        </button>
      );
    }
    return days;
  };

  getMinMaxDate = (type) => {
    const { selectedDate } = this.state;

    let minimumDate, maximumDate;
    const school = this.props.school;
    const firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);
    const isFuture = moment(firstDayOfSchool).isAfter(moment());

    const maxDate = new Date(school.Dates.LastDayOfSchool);
    if (type === "from") {
      minimumDate = isFuture ? firstDayOfSchool : new Date();
      maximumDate = new Date(maxDate);
    } else if (type === "to") {
      minimumDate = new Date(selectedDate);
      minimumDate = minimumDate.setDate(minimumDate.getDate() + 1);
      maximumDate = new Date(maxDate);
    }
    return {
      minimumDate,
      maximumDate,
    };
  };
  drawTodayEarlyDismissalSchedule = () => {
    const selectedKids = this.props.selectedKids;
    const schools = this.props.school;
    const isEarlyAllowedForToday =
      schools.EarlyDismissalSettings.AllowParentSameDayScheduling;
    const timeTable = schools.EarlyDismissalSettings.EarlyDismissalTimeTable;
    let timeTableView = [];
    const dayIndex = moment(new Date()).format("d");
    const StartTime = new Date(`1/1/2015 ${timeTable[dayIndex].StartTime}`);
    const EndTime = new Date(`1/1/2015 ${timeTable[dayIndex].EndTime}`);
    const { selectedDate, doTodayFlash, doSelectedDateFlash } = this.state;
    const daySelectedIndex = moment(new Date(selectedDate)).format("d");
    let day =
      getLanguageCode() === "en"
        ? GLOBAL_CONST.DAYS_FULL_EN[daySelectedIndex]
        : GLOBAL_CONST.DAYS_FULL_ES[daySelectedIndex];
    let timeStyle = "time";
    if (!timeTable[dayIndex].Active) {
      timeStyle = "time disabletime";
    }

    if (daySelectedIndex === dayIndex) {
      timeTableView.push(
        <ul
          className="ealrydismissalschedule-list"
          key={
            this.props.selectedKids[0].StudentID +
            daySelectedIndex +
            "todayschedule"
          }
        >
          <li
            className="earlydismissalschedule-box"
            key={`${day}-${selectedKids[0].StudentID}-${dayIndex}`}
          >
            {moment(selectedDate).isBefore(new Date()) ? (
              <span className="dayname">{this.props.t("lbl_Today")}</span>
            ) : (
              <span className="dayname">{day}</span>
            )}
            {/* <div className="dayname"></div> */}
            <div className="time-details">
              <span className={timeStyle}>{`${moment(StartTime).format(
                "hh:mm A"
              )}`}</span>
              <i className="time-sep fas fa-arrow-right"></i>
              <span className={timeStyle}>{`${moment(EndTime).format(
                "hh:mm A"
              )}`}</span>
            </div>
          </li>
        </ul>
      );
    }
    if (daySelectedIndex !== dayIndex)
      timeTableView.push(
        <ul
          className="ealrydismissalschedule-list"
          key={
            this.props.selectedKids[0].StudentID + dayIndex + "schedule-list"
          }
        >
          {this.createDay(timeTable, daySelectedIndex, true)}
        </ul>
      );
    return timeTableView;
  };
  createDay = (timeTable, i, isLastRecord) => {
    const StartTime = new Date(`1/1/2015 ${timeTable[i].StartTime}`);
    const EndTime = new Date(`1/1/2015 ${timeTable[i].EndTime}`);

    const day =
      getLanguageCode() === "en"
        ? GLOBAL_CONST.DAYS_FULL_EN[i]
        : GLOBAL_CONST.DAYS_FULL_ES[i];

    const lastRecordView = {
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
    };

    let timeStyle = "time";

    if (!timeTable[i].Active) {
      timeStyle = "time disabletime";
    }
    return (
      <li className="earlydismissalschedule-box" key={`${day}-${i}`}>
        <span className="dayname">{day}</span>
        <div className="time-details">
          <span className={timeStyle}>{`${moment(StartTime).format(
            "hh:mm A"
          )}`}</span>
          <i className="time-sep fas fa-arrow-right"></i>
          <span className={timeStyle}>{`${moment(EndTime).format(
            "hh:mm A"
          )}`}</span>
        </div>
      </li>
    );
  };
  drawEarlyDismissalSchedule = () => {
    const schools = this.props.school;
    let timeTableView = [];
    const timeTable = schools.EarlyDismissalSettings.EarlyDismissalTimeTable;
    for (let j = 1; j < timeTable.length; j++) {
      timeTableView.push(this.createDay(timeTable, j, false));
    }
    timeTableView.push(this.createDay(timeTable, 0, true));
    return <ul className="ealrydismissalschedule-list">{timeTableView}</ul>;
  };

  getSelectDateUI = () => {
    const { isCollapse } = this.state;

    const { school } = this.props;
    const isEarlyAllowedForToday = canEarlyDismissToday(school);

    return (
      <div id="earlydismissal_selectwhen" className="card form-card">
        <div className="form-card-title">
          <div className="fc-titletext-icon">
            <FontAwesomeIcon className="title-icon" icon={faCalendarAlt} />
            <span className="text">{this.props.t("select_when_text")}</span>
          </div>
          <span className="probressbar-holder">
            <Line percent={50} strokeWidth="1" strokeColor="#7ac943" />
          </span>
        </div>
        <form className="form form-style2">
          <div className="form-group">
            <label className="control-label">
              {this.props.t("createMarkAbScr-selectDate")}
            </label>
            {this.showCalendarView()}
          </div>

          <div className="form-group">
            <label className="control-label">
              {this.props.t("select_time")}
            </label>
            <div className="datp-calendar-holder custom-timepicker">
              {this.renderTimePicker()}
            </div>
          </div>
        </form>
      </div>
    );
  };

  validateForm = () => {
    const { school } = this.props;
    const { pickedTime, selectedDate } = this.state;

    const result = isEarlyDismissalAvailable(
      school,
      selectedDate,
      pickedTime,
      this.props
    );
    if (selectedDate == "Select Date") {
      alert(
        this.props.t("Early-Dismissal") +
          "\n" +
          this.props.t("selectWhenScr-selectDate")
      );
      return false;
    } else if (
      school.ReunificationEnabled &&
      moment(selectedDate).isSame(moment(), "date")
    ) {
      alert(
        this.props.t("Early-Dismissal") +
          "\n" +
          this.props.t("emergency_reunification_pickup_msg")
      );
      return false;
    } else if (
      school.EarlyDismissalSettings.AllowParentSameDayScheduling === false &&
      moment(selectedDate).isSame(moment(), "date")
    ) {
      alert(
        this.props.t("Early-Dismissal") +
          "\n" +
          this.props.t("early_dismissal_allow_today_message")
      );
      return false;
    } else if (pickedTime == null) {
      alert(
        this.props.t("Early-Dismissal") +
          "\n" +
          this.props.t("earlyDisScr-selectTime")
      );
      return false;
    } else if (result.success === false) {
      alert(this.props.t("Early-Dismissal") + "\n" + result.message);
      return false;
    } else {
      return true;
    }
  };

  schedulePickupView = () => {
    const { selectedDate, selectedOption, isCollapse } = this.state;
    const { selectedKids, school } = this.props;
    let kidsName = getKidsName(selectedKids);

    const isEarlyAllowedForToday = canEarlyDismissToday(school);

    const isPasswordRequired = earlyDismissNeedPassword(school);

    return (
      <div className="modal-dialog modal-lg multikids-create-earlydismissal">
        <div className="modal-content">
          <div className="scrollY-sm">
            <div className="modal-header">
              <div className="title-section">
                <h4 className="modal-title">
                  {this.props.t("create_earlydismissal")}
                </h4>
                <p className="mb-5px">
                  <small>{this.props.t("early_dismissalfor")}</small>
                </p>
                <p className="modal-helptext">{kidsName}</p>
                <button
                  type="button"
                  className="btn help-icon-btn"
                  onClick={() => {
                    this.openHelpModal();
                  }}
                >
                  {this.props.t("regNameScr-needHelp")}
                </button>
              </div>
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  this.closeModal();
                }}
                aria-label={this.props.t("Close_Button")}
              ></button>
            </div>

            <div className="modal-body pickup-schedule-holder">
              <div className="row">
                <div className="col-sm-6">{this.getSelectDateUI()}</div>
                <div className="col-sm-6">{this.getSelectHowUI()}</div>
              </div>
              <div className="row mt-20">
                <div className="col-sm-12">
                  <div className="card form-card form-collapse">
                    <div
                      className="form-card-title expand-collapse-row"
                      data-bs-toggle="collapse"
                      data-bs-target={"#earlydismissalschedule"}
                      data-toggle="collapse"
                      href="#earlydismissalschedule"
                      onClick={(event) => {
                        this.setState({ isCollapse: !isCollapse });
                      }}
                    >
                      <div className="ealrydismissalschedule-header">
                        <div className="fc-titletext-icon">
                          <span className="title-label">
                            {this.props.t("early_dismissal_schedule")}
                          </span>
                          <em
                            className="red-text allowtoday_alert"
                            key={selectedKids[0].StudentID + "today"}
                          >
                            {isEarlyAllowedForToday === false
                              ? this.props.t(
                                  "early_dismissal_allow_today_message"
                                )
                              : null}
                          </em>
                        </div>
                        {isCollapse && (
                          <div className="todayearlydismissal-schedule">
                            {this.drawTodayEarlyDismissalSchedule()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="allearlydismissal-schedule collapse"
                      id="earlydismissalschedule"
                      data-bs-parent="#earlydismissal_selectwhen"
                    >
                      {this.drawEarlyDismissalSchedule()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-center">
              <button
                type="button"
                className="btn btn-default"
                style={{
                  backgroundColor: getCurrentThemeColor(
                    this.props.selectedTheme
                  ).buttonDefault,
                }}
                onClick={() => {
                  if (this.validateForm()) {
                    let selectData = {};
                    if (this.state.pickedTime != null) {
                      selectData = {
                        selectedOption,
                        showDate: selectedDate,
                      };

                      this.setState(
                        {
                          earlyDismissalPickupData: selectData,
                        },
                        () => {
                          if (isPasswordRequired) {
                            this.openPasswordOtpEntryModal();
                          } else {
                            this.submitForm();
                          }
                        }
                      );
                    }
                  }
                }}
              >
                {this.props.t("create_earlydismissal")}
              </button>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  };

  hideLegendView = (e) => {
    this.setState({
      showCalendarPicker: false,
    });
  };
  showLegendView = (e) => {
    this.setState({
      showCalendarPicker: true,
    });
  };

  showCalendarView = () => {
    const { selectedDate, selectedTillDate, markedDates, showCalendarPicker } =
      this.state;
    let date, minimumDate, maximumDate;

    const pickupDates = filterByTypes(markedDates);

    const school = this.props.school;
    const firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);
    const isFuture = moment(firstDayOfSchool).isAfter(moment());
    const isEarlyAllowedForToday = canEarlyDismissToday(school);
    const maxDate = new Date(school.Dates.LastDayOfSchool);

    date =
      selectedDate && selectedDate !== ""
        ? moment(selectedDate).toDate()
        : new Date();
    minimumDate = isFuture ? firstDayOfSchool : new Date();
    maximumDate = new Date(maxDate);
    if (!isEarlyAllowedForToday) {
      minimumDate = minimumDate.setDate(minimumDate.getDate() + 1);
    }

    const calc = (
      <div className="datp-calendar-holder">
        <div className="datp-col">
          <DatePicker
            id="selectFromDate"
            className="form-control"
            selected={moment(selectedDate).toDate()}
            minDate={minimumDate}
            maxDate={maximumDate}
            onCalendarOpen={this.showLegendView}
            onSelect={this.setSelectedDate}
            onClickOutside={this.hideLegendView}
            dateFormat="eee, MMM do yyyy"
            highlightDates={[
              {
                "react-datepicker__day--highlightedRed": pickupDates.pickupRed,
              },
              {
                "react-datepicker__day--highlightedGreen":
                  pickupDates.pickupGreen,
              },
              {
                "react-datepicker__day--highlightedOrange":
                  pickupDates.pickupLightOrange,
              },
              {
                "react-datepicker__day--highlightedGray":
                  pickupDates.pickupGray,
              },
              {
                "react-datepicker__day--highlightedBlack":
                  pickupDates.pickupBlack,
              },
            ]}
            customInput={<CustomInput />}
          >
            {showCalendarPicker === true && this.renderLegendUI()}
          </DatePicker>
        </div>
        <div className="daysbtn-sect">
          <button
            type="button"
            className="btn todays-btn"
            onClick={() => {
              this.setState({ selectedDate: new Date() });
            }}
          >
            {this.props.t("lbl_Today")}
          </button>

          <button
            type="button"
            className="btn todays-btn"
            onClick={() => {
              let tomarrow = new Date();
              tomarrow.setDate(tomarrow.getDate() + 1);
              this.setState({ selectedDate: tomarrow });
            }}
          >
            {this.props.t("lbl_Tomorrow")}
          </button>
        </div>
      </div>
    );
    return calc;
  };

  renderTimePicker = () => {
    const { pickedTime, showTimePicker } = this.state;
    let timepicker = null;
    {
      timepicker = showTimePicker && (
        <TimePicker
          value={new Date(pickedTime)}
          format={"h:mm a"}
          mode="time"
          name={"pickedTime"}
          disableCalendar={true}
          disableClock={true}
          calendarIcon={"Clock"}
          openWidgetsOnFocus={true}
          isCalendarOpen={false}
          isClockOpen={true}
          clearIcon={null}
          onChange={(value) => {
            this.handleConfirm(value);
          }}
          className="form-control"
        />
      );
    }
    return timepicker;
  };
  hideDatePicker = () => {
    this.setState({ showTimePicker: false });
  };
  handleConfirm = (value) => {
    let date = new Date();
    let time = value.split(":");
    date.setHours(time[0], time[1]);
    this.setState({ pickedTime: date });
  };
  submitForm = () => {
    this.earlyDismissalPickup.submitForm();
  };

  confirmPassword = () => {
    if (this.state.password === "") {
      alert(this.props.t("validate_password"));
      return false;
    }
    this.submitForm();
  };

  handlePassword = (password) => {
    this.setState({ password });
    this.earlyDismissalPickup.setState({ password: password });
  };

  openPasswordOtpEntryModal = () => {
    const { isShowOTPTxt, otpError, otp } = this.state;
    const { isFetching } = this.props;
    this.props.doShowModal({
      modalScreenName: "passwordOtpEntry",
      modalClassName: "passwordOtpEntryModal",
      params: {
        helpTxt: this.props.t("sso_earlydismissal_title"),
        passowrdSavebuttonTxt: this.props.t("CONFIRM_EarlyDismissal_Btn"),
        isFetching: isFetching,
        doGenerateOtp: this.generateOtpForTransaction,
        isShowOTPTxt: isShowOTPTxt,
        handleOtpChange: this.handleChange,
        otpError: otpError,
        otp: otp,
        verifyOtp: this.verifyOtp,
        handlePassword: this.handlePassword,
        confirmPassword: this.confirmPassword,
        doCallbackSetOTP: this.handleChange,
        doCallbackSetPassword: this.handlePassword,
      },
    });
  };
  handleChange = (otp) => {
    this.setState({ otp, otpError: "" });
    this.earlyDismissalPickup.setState({ otp: otp });
  };

  generateOtpForTransaction = () => {
    const { school } = this.props;
    this.props.doSsoUser_generateOTPForTransaction({
      SchoolId: school.SchoolID,
    });
    this.setState({ isShowOTPTxt: true });
  };

  verifyOtp = () => {
    this.submitForm();
  };

  showPasswordOptions = () => {
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <div className="form-group">
                  <p>
                    <label className="control-label">
                      {this.props.t("EarlyDismissal_Password_Desc")}
                    </label>
                  </p>
                  <input
                    type="password"
                    className="form-control"
                    name="PasswordRequiredForChange"
                    onChange={this.handlePassword}
                    placeholder={this.props.t("loginScreen-password")}
                  />
                </div>
                <div className="button-sect">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      this.confirmPassword({ onClose });
                    }}
                  >
                    {this.props.t("CONFIRM_EarlyDismissal_Btn")}
                  </button>
                </div>
                <div className="react-confirm-alert-button-group">
                  <button className="cancel-btn" onClick={onClose}>
                    {this.props.t("regNameScr-cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };

  render() {
    return this.schedulePickupView();
  }

  componentDidUpdate() {
    const {
      pickupEntries,
      doGetPickupEntriesClear,

      earlyDismissalDateRange,
      doGetEarlyDismissalsForDateRangeClear,
    } = this.props;
    let newMarkedDates = {};
    if (pickupEntries !== null && earlyDismissalDateRange !== null) {
      // console.log(
      //   "Inside mark builder " + pickupEntries.CalendarEntries.length
      // );
      newMarkedDates = {};
      for (var i = 0; i < pickupEntries.CalendarEntries.length; i++) {
        const record = { ...pickupEntries.CalendarEntries[i] };
        var day = moment(record.CalendarDate).format("YYYY-MM-DD");
        let earlyDismissalrecord = [];
        if (earlyDismissalDateRange) {
          //console.log("earlyDismissalrecord records ", earlyDismissalDateRange)
          earlyDismissalrecord = earlyDismissalDateRange.filter(
            (item) => moment(item.Date).format("YYYY-MM-DD") === day
          );
        }
        //console.log(`bulding marked dates day ${day}  record.CalendarDate ${record.CalendarDate} `);
        if (record.PM_Name === GLOBAL_CONST.PICKMODE_NAME.EARLY_DEPARTURE) {
          record.isEarlyDepart = true;
          newMarkedDates[day] = getMarkStyle(record);
        } else if (earlyDismissalrecord.length > 0) {
          const edRecord = {
            ...earlyDismissalrecord[0],
            ...record,
          };

          //console.log('earlyDismissalrecord for ', earlyDismissalrecord[0]);
          edRecord.isEarlyDismissal = true;
          edRecord.IsApproved = earlyDismissalrecord[0].IsApproved;
          if (edRecord.DelegateImageUrl === undefined) {
            edRecord.DelegateImageUrl = null;
          }

          newMarkedDates[day] = getMarkStyle(edRecord);
        } else {
          record.isEarlyDismissal = false;
          newMarkedDates[day] = getMarkStyle(record);
          // console.log("pickup for " + day)
        }
      }

      this.setState(
        {
          markedDates: newMarkedDates,
        },
        () => {
          this.getSelectDateDayRecord();
          doGetPickupEntriesClear();
          doGetEarlyDismissalsForDateRangeClear();
        }
      );
    }
  }

  getSelectDateDayRecord() {
    const { selectedDate } = this.state;

    const markedDates = { ...this.state.markedDates };

    // console.log("marked Dates--> ",JSON.stringify(markedDates))
    let currentList = [];
    var selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");

    for (const key in markedDates) {
      if (key === selectDateStr) {
        // console.log(
        //   `****************** getDayRecord ${selectDateStr} key ${key} CalendarDate ${markedDates[key].record.CalendarDate}`
        // );
        var selectedMarked = {
          selected: true,
          selectedColor: Colors.blue,
          className: [markedDates[key].className + " " + "selectedDate"],
          record: markedDates[key].record,
        };
        //alert(selectedMarked.record.CalendarDate);
        markedDates[key] = selectedMarked;
      } else {
        if (markedDates[key] && markedDates[key].record) {
          markedDates[key] = getMarkStyle(markedDates[key].record);
        }
      }
    }

    if (markedDates[selectDateStr]) {
      currentList.push(markedDates[selectDateStr]);
    }

    this.setState({ markedDates, currentList });
  }
}
const CustomInput = React.forwardRef((props, ref) => {
  return (
    <input
      className="form-control"
      onClick={props.onClick}
      value={props.value}
      type="text"
      readOnly={true}
      ref={ref}
    />
  );
});
CustomInput.displayName = "CustomInput";

function mapStateToProps(state) {
  return {
    setCreatePickupButtonsUI: state.userAccount.setCreatePickupButtonsUI,
    setHelpListData: state.userAccount.setHelpListData,
    isFetching: loadingSelector(state),

    loginType: state.userAccount.loginType,
    ssoUserGenerateOTPResponse: state.userAccount.ssoUserGenerateOTPResponse,

    pickupEntries: state.student.pickupEntries,
    relatedDelegations: state.student.relatedDelegations,
    earlyDismissalDateRange: state.student.earlyDismissalDateRange,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),

    doSsoUser_generateOTPForTransaction: (data) =>
      dispatch(ssoUser_generateOTPForTransaction(data)),
    doSsoUser_generateOTPForTransactionClear: () =>
      dispatch(ssoUser_generateOTPForTransactionClear()),

    doGetPickupEntries: (data) => dispatch(getPickupEntries(data)),
    doGetPickupEntriesClear: () => dispatch(getPickupEntriesClear()),

    doGetEarlyDismissalsForDateRange: (data) =>
      dispatch(getEarlyDismissalsForDateRange(data)),
    doGetEarlyDismissalsForDateRangeClear: (data) =>
      dispatch(getEarlyDismissalsForDateRangeClear(data)),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MultikidsCreateEarlyDismissal)
);
