import { getEnvironmentURL, getEnvironment, getAxios } from "./config";
import { validateResponse } from "../../helper/utility";
import { getRegistrationToken, getAuthToken } from "../../helper/utility";
import axios from "axios";

/**
 * API call to http://35.167.52.234/api/registraton/StartRegistration
 * Purpose: Start Registartion
 */
export const startRegistration = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}Registration/StartRegistration`;
  const FullUrl = PostURL + param;

  // console.log(` startRegistration ${FullUrl}`);
  const response = await getAxios().post(FullUrl, {
    Firstname: params.Firstname,
    Lastname: params.Lastname,
    Email: params.Email,
    Locale: params.Locale,
    // registration_token
    RegistrationTokenID: null,
  });

  return validateResponse(response);
};
/**
 * API call to http://35.167.52.234/api/registraton/checkEmail
 * Purpose: Email Check
 */
export const checkEmail = async ({ Email, locale }) => {
  const param = "";
  const PostURL = `${getEnvironment()}registration/checkEmail?Email=${Email}&locale=${locale}`;
  const FullUrl = PostURL + param;
  // console.log(` checkEmail ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/registraton/GetIsEmailVerified
 * Purpose: Email Verification
 */
export const getIsEmailVerified = async ({ Email }) => {
  // alert(registration_token);

  const param = "";
  const PostURL = `${getEnvironment()}Registration/GetIsEmailVerified?RegistrationTokenID=${getRegistrationToken()}&Email=${Email}`;
  const FullUrl = PostURL + param;
  // console.log(` getIsEmailVerified ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

/**
 * API call to  http://35.167.52.234/api/ResendEmailVerificationMail
 * Purpose: resend link to user email
 */
export const ResendEmailVerificationMail = async ({ Email }) => {
  const param = "";
  const PostURL = `${getEnvironment()}Registration/ResendEmailVerificationMail?RegistrationTokenID=${getRegistrationToken()}&Email=${Email}`;
  const FullUrl = PostURL + param;
  // console.log(`ResendEmailVerificationMail  ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/Registration/UpdatePhone
 * Purpose: add user phone number
 */
export const addPhoneNumber = async (params) => {
  // alert(registration_token);
  const param = "";
  const PostURL = `${getEnvironment()}Registration/UpdatePhone`;
  const FullUrl = PostURL + param;
  // console.log(` addPhoneNumber ${FullUrl}`);
  const response = await getAxios().post(FullUrl, {
    RegistrationTokenID: getRegistrationToken(),
    PhoneNumber: params.PhoneNumber,
    CountryCode: params.CountryCode,
    ResendOTP: params.ResendOTP,
  });

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/verify_otp
 * Purpose: verify otp
 */
export const verifyOtp = async ({ OTPCode }) => {
  const param = "";
  const PostURL = `${getEnvironment()}Registration/VerifyPhoneOTP`;
  const FullUrl = PostURL + param;
  // console.log(` verifyOtp ${FullUrl}`);
  const response = await getAxios().post(FullUrl, {
    RegistrationTokenID: getRegistrationToken(),
    OTPCode,
  });

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/resend_otp
 * Purpose: resend otp
 */
export const reSendOtp = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}resend_otp`;
  const FullUrl = PostURL + param;
  // console.log(` reSendOtp ${FullUrl}`);
  const response = await getAxios().post(FullUrl);

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/getUserInfo
 * Purpose: get user information
 */
export const getUserInfo = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}registration/getUserInfo?RegistrationTokenID=${getRegistrationToken()}`;
  const FullUrl = PostURL + param;
  // console.log(`getUserInfo ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/registerUser
 * Purpose:  user registration
 */
export const registerUser = async ({ Password }) => {
  const param = "";
  const PostURL = `${getEnvironment()}Registration/CompleteRegistration`;
  const FullUrl = PostURL + param;
  // console.log(`registerUser ${FullUrl}`);
  const response = await getAxios().post(FullUrl, {
    RegistrationTokenID: getRegistrationToken(),
    Password,
  });

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/account/login
 * Purpose:  Login api
 */
// export const userLogin = async ({ email, password, locale, env }) => {
//   const param = "";
//   const PostURL = `${getEnvironmentURL(env)}account/login`;
//   const FullUrl = PostURL + param;
//   const body = {
//     username: email,
//     password,
//     locale,
//   };

//   // console.log(`userLogin body  + ${FullUrl} + ${JSON.stringify(body)}`);
//   // console.log(FullUrl);
//   const response = await getAxios().post(FullUrl, body);

//   return validateResponse(response);
// };

export const userLogin = async ({
  email,
  password,
  locale,
  phoneNumber,
  useEmail,
}) => {
  const param = "";
  const PostURL = useEmail
    ? `${getEnvironment()}account/login`
    : `${getEnvironment()}v2/account/LoginWithPhone`;
  const FullUrl = PostURL + param;
  const body = {
    username: useEmail ? email : phoneNumber,
    password,
    locale,
  };

  // console.log(
  //   `userLogin body with device info + ${FullUrl} + ${JSON.stringify(body)}`
  // );
  // console.log(FullUrl);
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const getCountryCodesListing = async () => {
  const PostURL = `${getEnvironment()}account/getCountriesAndCountryCodes`;

  const FullUrl = PostURL;
  // console.log(`getCountryCodesListing ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/account/ChangeLocale
 * Purpose:  Change language
 */
export const ChangeLocale = async ({ Locale }) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/ChangeLocale`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    Locale,
  });

  return validateResponse(response);
};

export const updateName = async ({ firstName, lastName }) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/UpdateName`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    Firstname: firstName,
    Lastname: lastName,
  });

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/account/forgotpassword
 * Purpose:  forgotpassword
 */
export const forgotPassword = async ({ email, locale }) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/ForgotPassword`;
  const FullUrl = PostURL + param;
  // console.log(`forgotPassword URL ${FullUrl}`);

  const response = await getAxios().post(FullUrl, {
    Email: email,
    Locale: locale,
  });

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/RegistrationValid
 * Purpose: RegistrationValid
 */
export const registrationValid = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}registration/RegistrationValid?RegistrationTokenID=${getRegistrationToken()}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const updatePassword = async ({ oldPassword, newPassword }) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/UpdatePassword`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    OldPassword: oldPassword,
    NewPassword: newPassword,
  });

  return validateResponse(response);
};

export const submitFeedback = async ({ feedback }) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/SubmitFeedback`;
  const FullUrl = PostURL + param;

  const body = {
    Feedback: feedback,
    Reason: null,
  };
  //console.log("submitFeedback FullUrl ", FullUrl, " body ", body);
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const getDelagates = async ({ fetchAll }) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/GetApprovedDelegates_Active?FetchAll=${fetchAll}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const upsertApprovedDelegate = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}Account/UpsertApprovedDelegate`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    List_ParentDelegateID: params.list_parentDelegateID,
    PhoneNumber: params.phoneNumber,
    Firstname: params.firstName,
    Lastname: params.lastName,
    Students: params.Students,
  });

  return validateResponse(response);
};

export const approvedDelegateChange = async ({
  list_parentDelegateID,
  isActive,
}) => {
  const param = "";
  const PostURL = `${getEnvironment()}Account/ApprovedDelegateChangeActiveState`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    List_ParentDelegateID: list_parentDelegateID,
    IsActive: isActive,
  });

  return validateResponse(response);
};

export const delegateSetDeleted = async ({ list_ParentDelegateId }) => {
  const param = "";
  const PostURL = `${getEnvironment()}Account/ApprovedDelegateSetDeleted?List_ParentDelegateID=${list_ParentDelegateId}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const updateDeviceToken = async ({ deviceType, deviceToken }) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/UpdateDeviceToken`;
  const FullUrl = PostURL + param;

  const body = {
    DeviceType: deviceType,
    DeviceToken: deviceToken,
  };
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const isTokenValid = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}account/IsTokenValid`;
  const FullUrl = PostURL + param;
  // console.log("isTokenValid " + FullUrl);
  const response = await getAxios().get(FullUrl);
  return validateResponse(response);
};
export const getNotifications = async ({
  LastNotificationID,
  notificationTypeId,
}) => {
  const param = "";
  let PostURL = `${getEnvironment()}account/GetNotifications?PageSize=25`;

  if (notificationTypeId > 0) {
    PostURL += `&NotificationTypeID=${notificationTypeId}`;
  }

  if (LastNotificationID > 0) {
    PostURL += `&LastNotificationID=${LastNotificationID}`;
  }

  const FullUrl = PostURL + param;
  // console.log(`getNotifications FullUrl ${FullUrl}`);

  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};
export const getHelpTopics = async ({ schoolIds }) => {
  const param = "";
  const PostURL = `${getEnvironment()}Account/GetHelpTopics`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    SchoolIds: schoolIds,
  });

  return validateResponse(response);
};

export const sendFeedbackWithChild = async (params) => {
  // console.log(`submitFeedbackParameters ${JSON.stringify(params)}`);
  const param = "";
  const PostURL = `${getEnvironment()}account/SendFeedbackFromParentAppIncludingChildInfo`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    List_HelpTopicID: params.list_HelpTopicId,
    SchoolID: params.schoolId,
    StudentIDs: params.studentIds,
    CustomSchoolName: params.customSchoolName,
    CustomStudentName: params.customStudentName,
    Feedback: params.feedback,
  });

  return validateResponse(response);
};

export const getNotificationSettings = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}account/GetNotificationSettings`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const updateParentNotificationSettings = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}Account/UpdateParentNotificationSettings`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    ReceiveStatusnnotification: params.receiveStatusnnotification,
    ReceiveDelegationNotification: params.receiveDelegationNotification,
    ReceiveEmailNotification: params.receiveEmailNotification,
    ReceiveNotification: params.receiveNotification,
    ReceiveNotificationForParentCheckInReminder:
      params.receiveNotificationForParentCheckInReminder,
    ReceivePresentNotification: params.receivePresentNotification,
  });

  return validateResponse(response);
};

export const fetchEta = async (
  deviceLat,
  deviceLon,
  schoolLatitude,
  schoolLongitude
) => {
  const PostURL = "https://router.project-osrm.org/route/v1/driving/";
  // 77.432900000000004,28.6294;79.039158711965868,21.175527110170172?overview=false
  const FullUrl = `${
    PostURL + deviceLon
  },${deviceLat};${schoolLongitude},${schoolLatitude}?overview=false`;

  const response = await getAxios().post(FullUrl);
  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/account/LogErrorsBrowser
 * Purpose:  Log Errors Browser
 */
export const logErrorsBrowser = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/LogErrorsBrowser`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    localtime: params.localtime,
    message: params.message,
    name: params.name,
    stack: params.stack,
    type: params.type,
    url: params.url,
    version: params.version,
    filename: params.filename,
    columnnumber: params.columnnumber,
    linenumber: params.linenumber,
  });

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/account/LogErrorsBrowserWithFiles
 * Purpose:  Log Errors Browser File
 */
export const fileLogErrors = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/LogErrorsBrowserWithFiles`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    OSversion: params.OSversion,
    AppVersion: params.AppVersion,
    DeviceType: params.DeviceType,
    DeviceVersion: params.DeviceVersion,
  });

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/account/ConnectStudent
 * Purpose:  Connect student
 */
export const connectStudent = async (params) => {
  const PostURL = `${getEnvironment()}account/ConnectStudent`;
  const FullUrl = PostURL;
  // console.log("connectStudent FullUrl " + FullUrl);
  const response = await getAxios().post(FullUrl, {
    Code: encodeURI(params.Code),
  });

  return validateResponse(response);
};

export const getFutureCalendarEntriesByParent = async (params) => {
  const PostURL = `${getEnvironment()}student/GetFutureCalendarEntriesByParent`;
  const FullUrl = PostURL;
  // console.log("getFutureCalendarEntriesByParent FullUrl " + FullUrl);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

// Upload Delegate Image
export const uploadDelegateImage = async (image, parentDelegateID) => {
  const parameters = [];
  const PostURL = `${getEnvironment()}account/UploadDelegateImage?parentDelegateID=${parentDelegateID}`;
  if (image) {
    const path = image;
    parameters.push({
      name: "photo",
      filename: `${parentDelegateID}.jpg`,
      type: "image/jpg",
      data: path,
    });
  }

  const formData = new FormData();
  formData.append("image", image);

  const headers = {
    "Content-Type": `multipart/form-data`,
    AuthTokenID: `${getAuthToken()}`,
  };

  const response = await getAxios().postForm(PostURL, formData, headers);
  return validateResponse(response);
};
// Cancel Manual Review Image
export const CancelManualReviewImage = async (parentDelegateID) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/CancelManualReview?parentDelegateId=${parentDelegateID}`;
  const FullUrl = PostURL + param;
  const body = {};
  const response = await getAxios().post(FullUrl, body);
  return validateResponse(response);
};
// Request Manual Review Image
export const RequestManualReviewImage = async (delegateImageId) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/RequestManualReview?delegateImageId=${delegateImageId}`;
  const FullUrl = PostURL + param;
  const body = {};
  const response = await getAxios().post(FullUrl, body);
  return validateResponse(response);
};

// Get Delegate
export const getDelegate = async (delegateId) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/GetDelegateById?delegateId=${delegateId}`;
  const FullUrl = PostURL + param;
  const body = {};
  const response = await getAxios().post(FullUrl, body);
  return validateResponse(response);
};

// Delete User Account
export const deleteAccount = async ({ email, password }) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/deleteAccount`;
  const FullUrl = PostURL + param;
  const body = {
    email,
    password,
  };

  const response = await getAxios().post(FullUrl, body);
  return validateResponse(response);
};

// Upload User Image
export const uploadUserImage = async (image) => {
  const parameters = [];
  const PostURL = `${getEnvironment()}account/UploadUserImage`;

  if (image) {
    const path = image;
    parameters.push({
      name: "photo",
      filename: `${"userProfile"}.jpg`,
      type: "image/jpg",
      data: path,
    });
  }

  const formData = new FormData();
  formData.append("image", image);

  const headers = {
    "Content-Type": "multipart/form-data",
    AuthTokenID: `${getAuthToken()}`,
  };

  //const response = await getAxios().post(PostURL, formData, headers);
  const response = await getAxios().postForm(PostURL, formData, headers);
  return validateResponse(response);
};
// Cancel Manual Review Image
export const userCancelManualReviewImage = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}account/UserCancelManualReview`;
  const FullUrl = PostURL + param;
  const body = {};
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

// Request Manual Review Image
export const userRequestManualReviewImage = async (userImageId) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/UserRequestManualReview?parentImageId=${userImageId}`;
  const FullUrl = PostURL + param;
  const body = {};
  const response = await getAxios().post(FullUrl, body);
  return validateResponse(response);
};

// Get Parent photo
export const getUserProfile = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/account/GetUserInfo`;
  const FullUrl = PostURL + param;
  //console.log(` GetUserProfile Full URL  ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

// SSO user send otp for delete account
export const deleteSSO_UserSendOTP = async ({ deviceType }) => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/account/SendDeleteOtp`;
  const FullUrl = PostURL + param;
  const body = { DeviceType: deviceType };
  // console.log(
  //   `SSO user send otp for delete account Full URL-: ${FullUrl} Param body -: ${JSON.stringify(
  //     body
  //   )}`
  // );
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

// SSO user send otp for delete account
export const ssoUserDelete = async ({ email, OtpCode }) => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/account/DeleteAccount`;
  const FullUrl = PostURL + param;
  const body = {
    email,
    OtpCode,
  };
  // console.log(
  //   `SSO user delete account Full URL-: ${FullUrl} Param body -: ${JSON.stringify(
  //     body
  //   )}`
  // );
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const SSO_User_generateOTPForTransaction = async ({ SchoolId }) => {
  const param = "";
  const PostURL = `${getEnvironment()}/v2/account/GenerateOtpForTransaction`;
  const FullUrl = PostURL + param;
  const body = { SchoolId };
  // console.log(
  //   `SSO user send otp for Mark Absent And ED Full URL-: ${FullUrl} Param body -: ${JSON.stringify(
  //     body
  //   )}`
  // );
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const sendWelcomeEmail = async () => {
  const PostURL = `${getEnvironment()}account/SendWelcomeEmail`;

  const FullUrl = PostURL;
  //console.log(`sendWelcomeEmail ${FullUrl}`);
  const response = await getAxios().post(FullUrl);

  return validateResponse(response);
};

export const getGeoInfo = async () => {
  const PostURL = "https://ipapi.co/json/";
  const response = await axios.post(PostURL);
  return validateResponse(response);
};
