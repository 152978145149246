import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../CommonComponents";
import {
  clearRegistrationInfo,
  loginUser,
  loginUserClear,
  setEnvironment,
  logoutUser,
  getCountryCodes,
  saveRegistrationInfo,
  setLoginType,
  setGoogleLoginCredentials,
  setAppleLoginCredentials,
  saveLoginCredentialsClear,
  setAppleLoginCredentialsClear,
  setGoogleLoginCredentialsClear,
  getGeoInformation,
} from "../../../common/actions/userAccount";

import {
  appleSSOSave,
  checkEmailExist,
  checkEmailExistClear,
  googleSSOClear,
  googleSSOSave,
  ssoUserAccountCheck,
  ssoUserAccountCheckClear,
  performAppleLogin,
  performGoogleLogin,
  setAppleLoginClear,
  setGoogleLoginClear,
} from "../../../common/actions/userAccount/RegistrationAction";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "../../../../node_modules//bootstrap/dist/css/bootstrap.min.css";
import { RegisterFooter, RegisterHeader } from "../../CommonComponents";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import "../../Styles/app.css";
import "../NewRegistration/Styles/style.css";
import "./landing.css";
import "../NewRegistration/Styles/theme-responsive.css";
import { createLoadingSelector } from "../../../common/reducers/selectors";
import {
  getLanguageCode,
  formatPhone,
  isLoggedIn,
  getAppleRedirectURL,
} from "../../../helper/utility";
import Select from "react-dropdown-select";
import { GoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import { Slide, toast, ToastContainer } from "react-toastify";
import {
  markReadToDashboard,
  saveLoginCredentials,
} from "../../../common/actions/userAccount/userAccountAction";
import GLOBAL_CONST from "../../../helper/AppConstants";
import { jwtDecode } from "jwt-decode";

import { history } from "../../../common/store/configureStore";

const loadingSelector = createLoadingSelector([
  "login_user",
  "check_email_exist",
  "sso_exist",
  "login_user",
  "do_apple_login",
  "do_google_login",
]);
const countryCodesLoadingSelector = createLoadingSelector([
  "country_code_list",
]);

// country_calling_code
const defaultCountry = {
  CountryId: 1,
  CountryName: "USA",
  CountryCode: "+1",
  label: "USA(+1)",
};

class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    let { registeredCountryCode, registeredCountryName } = this.props;
    if (registeredCountryCode === null) registeredCountryCode = "+1";
    if (registeredCountryName === null) registeredCountryName = "USA";

    const { registeredPhone } = this.props;

    this.state = {
      userEmail: "",
      password: "",
      env: "prod",
      userEmailError: "",
      userPassError: "",
      hidePassword: true,
      ServerError: "",
      countryCode: defaultCountry.CountryCode,
      countryName: defaultCountry.CountryName,

      phoneNumber: "",
      phoneInvalid: "",
      selectedCountryCode: defaultCountry.CountryCode,
      selectedCountry: this.getCountry(),
      firstLoadDone: false,
      useEmail: true,
    };
    this.selectedCountry = React.createRef();
    this.phone = React.createRef();
    this.userEmail = React.createRef();
  }

  componentDidMount() {
    this.props.doLogoutUser();

    this.props.doGetCountryCodes(() => {
      this.props.doGetGeoInformation((info) => {
        this.setState({
          selectedCountry: this.setCurrentCountry(info.country_calling_code),
          countryCode: info.country_calling_code,
        });
      });
    });

    this.userEmail.current.focus();
  }

  setPasswordVisibility = () => {
    this.setState({ hidePassword: !this.state.hidePassword });
  };

  getCountry() {
    const { registeredCountryName, countryCodeListing } = this.props;
    if (!countryCodeListing) return;
    const items = countryCodeListing.filter((item) => {
      return item.CountryName === registeredCountryName;
    });
    if (items && items.length > 0) {
      return items;
    }

    return [defaultCountry];
  }

  setCurrentCountry = (currentCountry) => {
    const { countryCodeListing, geoInfoData } = this.props;
    if (!countryCodeListing && !geoInfoData) return;
    const items =
      countryCodeListing &&
      countryCodeListing.filter((item) => {
        return item.CountryCode === currentCountry;
      });

    if (items && items.length > 0) {
      return items;
    }

    return [defaultCountry];
  };

  saveCountry = (value) => {
    if (value && value.length > 0) {
      this.setState({
        selectedCountry: value,
        countryCode: value[0].CountryCode,
        countryName: value[0].CountryName,
      });
      this.phone.current.focus();
    }
  };

  submitForm = () => {
    const { doSetEnvironment } = this.props;
    let { env } = this.state;
    let { userEmail, useEmail, password } = this.state;

    if (useEmail) {
      var reg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let isValid = reg.test(userEmail);
      if (reg.test(userEmail) === false) {
        this.setState({
          userEmailError: `${this.props.t("regAcctScr-emailError")}`,
        });
        return;
      }
      if (password === null || password === "") {
        this.setState({
          userPassError: `${this.props.t("loginScreen-passwordError")}`,
        });
        return;
      }

      if (userEmail.includes(".staging")) {
        env = "staging";
      } else if (userEmail.includes(".develop")) {
        env = "develop";
      } else if (userEmail.includes(".localhost")) {
        env = "localhost";
      } else {
        env = "prod";
      }
      this.setState({ env }, () => {
        if (env === "staging") {
          doSetEnvironment({ env: "staging", callback: this.processLogin });
        } else if (env === "develop") {
          doSetEnvironment({ env: "develop", callback: this.processLogin });
        } else if (env === "localhost") {
          doSetEnvironment({ env: "localhost", callback: this.processLogin });
        } else {
          doSetEnvironment({ env: "prod", callback: this.processLogin });
        }
      });
    } else {
      this.processLogin();
    }
  };

  processLogin = () => {
    const { doclearRegistrationInfo, doLogin } = this.props;
    let { userEmail, phoneNumber, useEmail, password, env, countryCode } =
      this.state;

    if (userEmail !== null && userEmail !== undefined) {
      userEmail = userEmail.replace(".staging", "");
      userEmail = userEmail.replace(".develop", "");
      userEmail = userEmail.replace(".localhost", "");
    }
    doclearRegistrationInfo();
    doLogin({
      email: userEmail,
      phoneNumber: countryCode + phoneNumber,
      useEmail,
      password: password,
      locale: getLanguageCode(),
      env,
    });
    this.saveCredentials();
  };

  async saveCredentials() {
    try {
      const { userEmail, phoneNumber, useEmail, password, env, countryCode } =
        this.state;

      if (
        useEmail &&
        (userEmail === null || userEmail === undefined || userEmail === "")
      ) {
        return;
      }
      if (
        !useEmail &&
        (phoneNumber === null ||
          phoneNumber === undefined ||
          phoneNumber === "")
      ) {
        return;
      }

      if (useEmail) {
        let credentials = {
          userEmail,
          password,
        };
        this.props.doSaveLoginCredentials({
          loginCredentials: credentials,
        });
      } else {
        let credentials = {
          phoneNumber,
          password,
        };
        this.props.doSaveLoginCredentials({
          loginCredentials: credentials,
        });
      }
    } catch (err) {
      //console.log("error : ", err);
    }
  }

  handleUserChange = ({ target: { value: userEmail } }) =>
    this.setState({ userEmail, userEmailError: "", ServerError: "" });
  handlePasswordChange = ({ target: { value: password } }) =>
    this.setState({ password, userPassError: "", ServerError: "" });

  handlePhoneChange = ({ target: { value: val } }) => {
    this.setState({ phoneNumber: formatPhone(val), phoneInvalid: "" });
  };
  goToPasswordField = (event) => {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);

    if (event.key === "Enter") {
      form.elements["password"].focus();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      userLoginInfo,
      error,
      doLoginClear,
      registeredPhone,
      appleLoginResponse,
      googleLoginResponse,
      doSetAppleLoginClear,
      doSetGoogleLoginClear,
    } = this.props;
    const { userEmail, phoneNumber } = this.state;

    if (history.action === "POP") {
      if (isLoggedIn()) {
        setTimeout(() => {
          this.props.navigate("/childManagement");
        }, 1000);
      }
    }

    var re = /^\d{8,15}$/;

    if (prevState.userEmail !== userEmail && !this.state.firstLoadDone) {
      // Check if this.state.userEmail does not contain @. can use indexof
      if (userEmail.indexOf("@") === -1 && re.test(userEmail)) {
        this.setState({
          firstLoadDone: true,
          useEmail: false,
          phoneNumber: userEmail,
        });
      }
    }
    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doLoginClear();

      this.props.doGetCountryCodes(() => {
        this.props.doGetGeoInformation((info) => {
          this.setState({
            selectedCountry: this.setCurrentCountry(info.country_calling_code),
            countryCode: info.country_calling_code,
          });
        });
      });
    } else if (userLoginInfo != null && registeredPhone === null) {
      this.checkIfVerifiedAccount();
    }

    if (appleLoginResponse) {
      this.props.doSaveLoginCredentialsClear();
      if (appleLoginResponse.Success === false) {
        toast.info(this.props.t("NoAppleAccount"), {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
        });

        doSetAppleLoginClear();

        setTimeout(() => {
          this.props.navigate("/newregisterPhone");
        }, 2000);
      }
    }
    if (googleLoginResponse) {
      this.props.doSaveLoginCredentialsClear();
      if (googleLoginResponse.Success === false) {
        toast.info(this.props.t("NoGoogleAccount"), {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
        });

        doSetGoogleLoginClear();

        setTimeout(() => {
          this.props.navigate("/newregisterPhone");
        }, 2000);
      }
    }
  }

  checkIfVerifiedAccount = () => {
    const { userLoginInfo } = this.props;
    const isPhoneVerified =
      userLoginInfo.isOTPVerified ||
      userLoginInfo.isOTPVerified === "True" ||
      userLoginInfo.IsPhoneVerified;
    if (isPhoneVerified && userLoginInfo.IsEmailVerified) {
      if (
        userLoginInfo !== null &&
        userLoginInfo !== undefined &&
        userLoginInfo.AuthTokenID &&
        isPhoneVerified &&
        userLoginInfo.IsEmailVerified
      ) {
        setTimeout(() => {
          this.props.navigate("/childManagement");
        }, 1000);
      }
    } else if (!isPhoneVerified) {
      this.props.navigate("/newconfirmPhone");
    } else if (!userLoginInfo.Email) {
      this.props.navigate("/newregisterEmail", {
        state: { screenFrom: "landing" },
      });
    } else if (!userLoginInfo.IsEmailVerified) {
      this.props.navigate("/newconfirmEmail", {
        state: { sendEmail: true, screenFrom: "landing" },
      });
    }
  };

  processGoogleLogin = (userInfo, identityToken) => {
    if (!userInfo.email) {
      alert(
        "Google Account",
        "Your Google account does not have an email address. Please try logging in with your phone number or a valid email address."
      );
      return;
    }

    this.props.doPerformGoogleLogin({
      idToken: identityToken,
    });
  };
  onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "off";
      event.target.attributes.defaultValue = "";
      event.target.value = "";
      this.setState({ userEamil: "" });
    }
  };
  getEmailView = () => {
    const { userEmail, userEmailError } = this.state;
    return (
      <div className="input-wrapper">
        <input
          autoComplete="off"
          type="email"
          ref={this.userEmail}
          id="userEmail"
          className={`form-control ${userEmailError !== "" ? "input-error" : null
            }`}
          placeholder={this.props.t("loginScreen-email")}
          name={"userEmail"}
          value={userEmail}
          onChange={this.handleUserChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") this.goToPasswordField(event);
          }}
        />
        <i className="far fa-user-circle userIcon"></i>
        <span className="error" id="error">
          {" "}
          {userEmailError}
        </span>
      </div>
    );
  };

  getPhoneView = () => {
    const { phoneNumber, countryCode, phoneInvalid, selectedCountry } =
      this.state;

    const { countryCodeListing } = this.props;

    return (
      <div className="input-wrapper">
        <div className="input-row countryselect-list">
          <Select
            key={`key-${this.state.useEmail}`}
            values={selectedCountry}
            multi={false}
            searchable={true}
            options={countryCodeListing}
            labelField={"label"}
            valueField={"CountryId"}
            onChange={(value) => this.saveCountry(value)}
            searchBy={"label"}
            clearOnSelect={true}
            placeholder={this.props.t("Select_Country")}
            name="selectcountry"
            className="form-control"
            ref={this.selectedCountry}
            createNewLabel={true}
            clearOnBlur={true}
            handleKeyDownFn={() => {
              this.setState({ selectedCountry: [] });
            }}
            loading={this.props.isFetchingCountryCodes}
            loadingRenderer={({ props }) => (
              <div className="text-center loader-wrap">
                <i className="fas fa-sync-alt fa-spin"></i>
              </div>
            )}
          />
        </div>

        <div className="input-group">
          <input
            type="text"
            className="form-control input-sm input-noflex"
            readOnly={true}
            value={countryCode}
            name="countrycodename"
          />
          <input
            autoComplete="tel"
            type="text"
            id="phoneNumber"
            className={`form-control ${phoneInvalid !== "" ? "input-error" : null
              }`}
            placeholder={this.props.t("regPhoneScr-phoneNumberPlace")}
            value={phoneNumber}
            onChange={this.handlePhoneChange}
            maxLength={15}
            name="phoneNumber"
            ref={this.phone}
            onKeyDown={(event) => {
              this.goToPasswordField(event);
            }}
          />
          <i className="fas fa-phone userIcon"></i>
        </div>
        <span className="error" id="message">
          {" "}
          {phoneInvalid}
        </span>
      </div>
    );
  };

  render() {
    const { password, userPassError, hidePassword, ServerError, useEmail } =
      this.state;
    const { isFetching, doclearRegistrationInfo } = this.props;
    let screenWidth = window.innerWidth;
    const redirectURL = getAppleRedirectURL(window)
    return (
      <section className="landingPage loginpage">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  <div className="container-sect">
                    <form className="form-style1" autoComplete="off">
                      <div className="title-sect mb-40">
                        <h3 className="title-3">
                          {this.props.t("loginScreen-lets-get-started")}
                        </h3>
                        <p className="helptext">
                          {this.props.t(
                            "loginScreen-please-enter-email-password"
                          )}
                        </p>
                      </div>
                      <div className="form-group">
                        {useEmail && this.getEmailView()}
                        {!useEmail && this.getPhoneView()}
                        <button
                          type="button"
                          className="usephonenumber-label blue-text btn"
                          onClick={() => {
                            this.setState({ useEmail: !useEmail });
                          }}
                        >
                          {useEmail
                            ? this.props.t("usePhoneNumberInstead")
                            : this.props.t("useEmailInstead")}
                        </button>
                      </div>

                      <div className="form-group mb-40">
                        <input
                          type={hidePassword ? "password" : "text"}
                          className={`form-control ${userPassError !== "" ? "input-error" : null
                            }`}
                          placeholder={this.props.t("loginScreen-password")}
                          value={password}
                          onChange={this.handlePasswordChange}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              this.props.doSetLoginType(
                                GLOBAL_CONST.LOGIN_TYPE.EMAIL_OR_PHONE
                              );
                              this.submitForm();
                            }
                          }}
                          name="password"
                        />
                        <i
                          onClick={() => {
                            this.setPasswordVisibility();
                          }}
                          className={
                            hidePassword
                              ? "far fa-eye togglePassword"
                              : "far fa-eye-slash togglePassword1"
                          }
                        ></i>
                        <span className="error" id="error">
                          {" "}
                          {userPassError}
                        </span>

                        <div className="text-left">
                          <Link className="Button-Label" to="/forgotPassword">
                            {this.props.t("loginScreen-ForgotPassword")}
                          </Link>
                        </div>
                      </div>

                      <div className="error" id="error">
                        {" "}
                        {ServerError}
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            this.props.doSetLoginType(
                              GLOBAL_CONST.LOGIN_TYPE.EMAIL_OR_PHONE
                            );
                            this.submitForm();
                          }}
                        >
                          {" "}
                          {isFetching ? (
                            <i className="fas fa-sync-alt fa-spin"></i>
                          ) : null}{" "}
                          &nbsp; {this.props.t("loginScreen-login")}
                        </button>
                        <div className="createaccount-row mt-10">
                          <span className="New-to-Pikmykid">
                            {this.props.t("loginScreen-new-to-pikmykid")}
                          </span>
                          <button
                            type="button"
                            className="btn create-account-label"
                            onClick={() => {
                              doclearRegistrationInfo();
                              this.props.navigate("/newregisterPhone");
                            }}
                          >
                            {this.props.t("loginScreen-create-account")}
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className="form-group mb-40 text-center">
                      <div className="ssoButtonsWrap">
                        <div className="google-sect">
                          <GoogleLogin
                            useOneTap
                            theme={"outline"}
                            type={"standard"}
                            text={this.props.t("continueGoogle")}
                            width={
                              parseInt(screenWidth) >= 1050 ? "" : "200"
                            }
                            onSuccess={async (credentialResponse) => {
                              if (
                                credentialResponse &&
                                credentialResponse.credential != null
                              ) {
                                const identityToken =
                                  credentialResponse.credential;

                                const userInfo = jwtDecode(
                                  credentialResponse.credential
                                );

                                this.props.doSetGoogleCredentials({
                                  googleLoginCredentials: credentialResponse,
                                });
                                this.processGoogleLogin(
                                  userInfo,
                                  identityToken
                                );
                              }
                            }}
                            onError={() => {
                              console.log("Login Failed");
                            }}
                          />
                        </div>

                        <div className="applebtn-sect">
                          <AppleLogin
                            clientId={GLOBAL_CONST.APPLE_SSO_CONFIG.clientId}
                            redirectURI={
                              redirectURL
                            }
                            usePopup={true}
                            callback={this.appleResponse}
                            scope="email"
                            responseMode="query"
                            height={40}
                            render={(renderProps) => (
                              <button
                                onClick={renderProps.onClick}
                                className="applelogin-btn"
                              >
                                <i className="fab fa-apple apple-icon"></i>
                                <span>{this.props.t("continueApple")}</span>
                              </button>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <a
                        className="contactsupport-link"
                        href="mailto:support@pikmykid.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.props.t("helpScr-contact")}
                      </a>
                    </div>
                  </div>
                  <ToastContainer />
                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  appleResponse = (response) => {
    if (!response.error) {
      if (response != null) {
        this.props.doSetAppleCredentials({
          appleLoginCredentials: response,
        });
        this.props.doPerformAppleLogin({
          identityToken: response.authorization.id_token,
        });
      } else {
        this.props.doSetAppleLoginCredentialsClear();
      }
    } else {
      this.props.doSetAppleLoginCredentialsClear();
    }
  };
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    isFetching: loadingSelector(state),
    isFetchingCountryCodes: countryCodesLoadingSelector(state),
    error: state.userAccount.error,
    registeredEmail: state.userAccount.registeredEmail,
    registeredPhone: state.userAccount.registeredPhone,
    registeredCountryCode: state.userAccount.registeredCountryCode,
    countryCodeListing: state.userAccount.countryCodeListing,

    isRegistrationComplete: state.userAccount.isRegistrationComplete,
    ssoGoogleResponse: state.userAccount.ssoGoogleResponse,
    ssoAppleResponse: state.userAccount.ssoAppleResponse,
    checkAccountExist: state.userAccount.checkAccountExist,
    checkAccountExistErr: state.userAccount.checkAccountExistErr,
    checkEmailExistResponse: state.userAccount.checkEmailExistResponse,
    checkEmailExistResponseErr: state.userAccount.checkEmailExistResponseErr,

    appleLoginResponse: state.userAccount.appleLoginResponse,
    googleLoginResponse: state.userAccount.googleLoginResponse,
    didUserLogout: state.userAccount.didUserLogout,
    appleLoginUsedCount: state.userAccount.appleLoginUsedCount,
    loginType: state.userAccount.loginType,
    googleLoginCredentials: state.userAccount.googleLoginCredentials,
    appleLoginCredentials: state.userAccount.appleLoginCredentials,
    loginCredentials: state.userAccount.loginCredentials,
    environment: state.userAccount.environment,
    geoInfoData: state.userAccount.geoInfoData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doclearRegistrationInfo: () => dispatch(clearRegistrationInfo()),
    doLogin: (data) => dispatch(loginUser(data)),
    doLoginClear: () => dispatch(loginUserClear()),
    doSetEnvironment: (data) => dispatch(setEnvironment(data)),
    doGetCountryCodes: (callback) => dispatch(getCountryCodes(callback)),
    doLogoutUser: () => dispatch(logoutUser()),

    doGoogleSSOSave: (data) => dispatch(googleSSOSave(data)),
    doGoogleSSOClear: () => dispatch(googleSSOClear()),
    doAppleSSOSave: () => dispatch(appleSSOSave()),
    doSsoUserAccountCheck: (data) => dispatch(ssoUserAccountCheck(data)),
    doSsoUserAccountCheckClear: () => dispatch(ssoUserAccountCheckClear()),
    doSaveRegistrationInfo: (data) => dispatch(saveRegistrationInfo(data)),
    doCheckEmailExist: (data) => dispatch(checkEmailExist(data)),
    doCheckEmailExistClear: () => dispatch(checkEmailExistClear()),

    doMarkReadToDashboard: () => dispatch(markReadToDashboard()),
    doPerformAppleLogin: (data) => dispatch(performAppleLogin(data)),
    doSetAppleLoginClear: () => dispatch(setAppleLoginClear()),
    doPerformGoogleLogin: (data) => dispatch(performGoogleLogin(data)),
    doSetGoogleLoginClear: () => dispatch(setGoogleLoginClear()),

    doSetLoginType: (data) => dispatch(setLoginType(data)),

    doSetGoogleCredentials: (data) => dispatch(setGoogleLoginCredentials(data)),
    doSetAppleCredentials: (data) => dispatch(setAppleLoginCredentials(data)),
    doSaveLoginCredentials: (data) => dispatch(saveLoginCredentials(data)),

    doSaveLoginCredentialsClear: () => dispatch(saveLoginCredentialsClear()),
    doSetAppleLoginCredentialsClear: () =>
      dispatch(setAppleLoginCredentialsClear()),
    doSetGoogleLoginCredentialsClear: () =>
      dispatch(setGoogleLoginCredentialsClear()),

    doGetGeoInformation: (callback) => dispatch(getGeoInformation(callback)),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPage))
);
