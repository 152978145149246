import GLOBAL_CONST from "../../helper/AppConstants";
import {
  SET_EMAIL_START,
  SET_EMAIL_SUCCESS,
  SET_EMAIL_FAILED,
  SET_EMAIL_CLEAR,
  RE_SEND_OTP_START,
  RE_SEND_OTP_SUCCESS,
  RE_SEND_OTP_FAILED,
  RE_SEND_OTP_CLEAR,
  VERIFIED_EMAIL_START,
  VERIFIED_EMAIL_SUCCESS,
  VERIFIED_EMAIL_FAILED,
  VERIFIED_EMAIL_CLEAR,
  RE_SEND_EMAIL_START,
  RE_SEND_EMAIL_SUCCESS,
  RE_SEND_EMAIL_FAILED,
  RE_SEND_EMAIL_CLEAR,
  VERIFY_OTP_START,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_CLEAR,
  GET_USER_INFO_START,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILED,
  GET_USER_INFO_CLEAR,
  COMPLETE_REGISTRATION_USER_START,
  COMPLETE_REGISTRATION_USER_SUCCESS,
  COMPLETE_REGISTRATION_USER_FAILED,
  COMPLETE_REGISTRATION_USER_CLEAR,
  START_REGISTARTION_START,
  START_REGISTARTION_SUCCESS,
  START_REGISTARTION_FAILED,
  START_REGISTARTION_CLEAR,
  MARK_REGISTRATION_COMPLETE,
  GOOGLE_SSO_RESPONSE_SAVE,
  GOOGLE_SSO_RESPONSE_CLEAR,
  APPLE_SSO_RESPONSE_SAVE,
  APPLE_SSO_RESPONSE_CLEAR,
  SSO_EXIST_START,
  SSO_EXIST_SUCCESS,
  SSO_EXIST_FAILED,
  SSO_EXIST_CLEAR,
  CHECK_EMAIL_EXIST_START,
  CHECK_EMAIL_EXIST_SUCCESS,
  CHECK_EMAIL_EXIST_FAILED,
  CHECK_EMAIL_EXIST_CLEAR,
  DO_APPLE_LOGIN_START,
  DO_APPLE_LOGIN_SUCCESS,
  DO_APPLE_LOGIN_FAILED,
  DO_APPLE_LOGIN_CLEAR,
  SET_APPLE_SSO_START,
  SET_APPLE_SSO_SUCCESS,
  SET_APPLE_SSO_FAILED,
  SET_APPLE_SSO_CLEAR,
  DO_GOOGLE_LOGIN_START,
  DO_GOOGLE_LOGIN_SUCCESS,
  DO_GOOGLE_LOGIN_FAILED,
  DO_GOOGLE_LOGIN_CLEAR,
  SET_GOOGLE_SSO_START,
  SET_GOOGLE_SSO_SUCCESS,
  SET_GOOGLE_SSO_FAILED,
  SET_GOOGLE_SSO_CLEAR,
} from "../actions/userAccount/RegistrationActionType";

import {
  CHECK_EMAIL_START,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILED,
  CHECK_EMAIL_CLEAR,
  ADD_PHONE_NUMBER_USER_START,
  ADD_PHONE_NUMBER_USER_SUCCESS,
  ADD_PHONE_NUMBER_USER_FAILED,
  ADD_PHONE_NUMBER_USER_CLEAR,
  SAVE_REGISTRATION_INFO,
  SAVE_REGISTRATION_CLEAR,
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGIN_USER_CLEAR,
  LOGIN_REGISTERED_USER,
  COUNTRY_CODE_LIST_START,
  COUNTRY_CODE_LIST_SUCCESS,
  COUNTRY_CODE_LIST_FAILED,
  COUNTRY_CODE_LIST_CLEAR,
  LOGOUT_USER,
  CHANGE_LOCALE_START,
  CHANGE_LOCALE_SUCCESS,
  CHANGE_LOCALE_FAILED,
  UPDATE_NAME_START,
  UPDATE_NAME_SUCCESS,
  UPDATE_NAME_FAILED,
  UPDATE_NAME_CLEAR,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_CLEAR,
  RAGISTRATION_VALID_START,
  RAGISTRATION_VALID_SUCCESS,
  RAGISTRATION_VALID_FAILED,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_CLEAR,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILED,
  GET_NOTIFICATIONS_CLEAR,
  SUBMIT_FEEDBACK_START,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILED,
  SUBMIT_FEEDBACK_CLEAR,
  GET_DELEGATES_START,
  GET_DELEGATES_SUCCESS,
  GET_DELEGATES_FAILED,
  GET_DELEGATES_CLEAR,
  APPROVED_DELEGATES_START,
  APPROVED_DELEGATES_SUCCESS,
  APPROVED_DELEGATES_FAILED,
  APPROVED_DELEGATES_CLEAR,
  APPROVED_DELEGATES_CHANGE_START,
  APPROVED_DELEGATES_CHANGE_SUCCESS,
  APPROVED_DELEGATES_CHANGE_FAILED,
  APPROVED_DELEGATES_CHANGE_CLEAR,
  DELEGATES_SET_DELETED_START,
  DELEGATES_SET_DELETED_SUCCESS,
  DELEGATES_SET_DELETED_FAILED,
  DELEGATES_SET_DELETED_CLEAR,
  UPDATE_DEVICE_TOKEN_START,
  UPDATE_DEVICE_TOKEN_SUCCESS,
  UPDATE_DEVICE_TOKEN_FAILED,
  UPDATE_DEVICE_TOKEN_CLEAR,
  GET_TOKEN_VALID_START,
  GET_TOKEN_VALID_SUCCESS,
  GET_TOKEN_VALID_FAILED,
  GET_TOKEN_VALID_CLEAR,
  GET_HELP_TOPICS_START,
  GET_HELP_TOPICS_SUCCESS,
  GET_HELP_TOPICS_FAILED,
  SEND_SUPPORT_REQUEST_START,
  SEND_SUPPORT_REQUEST_SUCCESS,
  SEND_SUPPORT_REQUEST_FAILED,
  SEND_SUPPORT_REQUEST_CLEAR,
  GET_NOTIFICATION_SETTING_START,
  GET_NOTIFICATION_SETTING_SUCCESS,
  GET_NOTIFICATION_SETTING_FAILED,
  UPDATE_NOTIFICATION_SETTING_START,
  UPDATE_NOTIFICATION_SETTING_SUCCESS,
  UPDATE_NOTIFICATION_SETTING_FAILED,
  UPDATE_NOTIFICATION_SETTING_CLEAR,
  LOG_ERRORS_BROWSER_START,
  LOG_ERRORS_BROWSER_SUCCESS,
  LOG_ERRORS_BROWSER_FAILED,
  LOG_ERRORS_BROWSER_CLEAR,
  LOG_ERRORS_FILE_START,
  LOG_ERRORS_FILE_SUCCESS,
  LOG_ERRORS_FILE_FAILED,
  LOG_ERRORS_FILE_CLEAR,
  CONNECT_STUDENT_START,
  CONNECT_STUDENT_SUCCESS,
  CONNECT_STUDENT_FAILED,
  CONNECT_STUDENT_CLEAR,
  GET_NOTIFICATION_SETTING_CLEAR,
  SET_ENVIRONMENT,
  SAVE_LAST_USED_TIMESTAMP,
  SAVE_LOCATION_PERMISSION,
  GET_FUTURE_ENTRIES_START,
  GET_FUTURE_ENTRIES_SUCCESS,
  GET_FUTURE_ENTRIES_FAILURE,
  TOGGLE_MODAL,
  HIDE_MODAL,
  TOGGLE_CHECKIN_QUEST_ANS,
  SET_HELPLIST,
  SET_OLD_HELPLIST,
  GET_FUTURE_ENTRIES_CLEAR,
  SAVE_PHOTO,
  SAVE_ALL_PHOTOS,
  UPLOAD_DELEGATE_IMAGE_START,
  UPLOAD_DELEGATE_IMAGE_SUCCESS,
  UPLOAD_DELEGATE_IMAGE_FAILED,
  UPLOAD_DELEGATE_IMAGE_CLEAR,
  REQUEST_MANUAL_REVIEW_START,
  REQUEST_MANUAL_REVIEW_SUCCESS,
  REQUEST_MANUAL_REVIEW_FAILED,
  REQUEST_MANUAL_REVIEW_CLEAR,
  CANCEL_MANUAL_REVIEW_START,
  CANCEL_MANUAL_REVIEW_SUCCESS,
  CANCEL_MANUAL_REVIEW_FAILED,
  CANCEL_MANUAL_REVIEW_CLEAR,
  ACCOUNT_DELETE_START,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_DELETE_FAILED,
  ACCOUNT_DELETE_CLEAR,
  SAVE_TERMS_ACCEPTED,
  ASK_ME_LATER_SAVE,
  RESET_USER_LOCATION,
  BACKTOSCHOOL_DATA_FILLED,
  UPLOAD_USER_IMAGE_START,
  UPLOAD_USER_IMAGE_SUCCESS,
  UPLOAD_USER_IMAGE_FAILED,
  UPLOAD_USER_IMAGE_CLEAR,
  USER_REQUEST_MANUAL_REVIEW_START,
  USER_REQUEST_MANUAL_REVIEW_SUCCESS,
  USER_REQUEST_MANUAL_REVIEW_FAILED,
  USER_REQUEST_MANUAL_REVIEW_CLEAR,
  USER_CANCEL_MANUAL_REVIEW_START,
  USER_CANCEL_MANUAL_REVIEW_SUCCESS,
  USER_CANCEL_MANUAL_REVIEW_FAILED,
  USER_CANCEL_MANUAL_REVIEW_CLEAR,
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILED,
  GET_USER_PROFILE_CLEAR,
  MARK_READY_TO_DASHBOARD,
  SET_CROPPED_IMAGE,
  SET_CROPPED_IMAGE_CLEAR,
  SET_LOGIN_TYPE,
  SSO_USER_SEND_OTP_CLEAR,
  SSO_USER_SEND_OTP_START,
  SSO_USER_SEND_OTP_SUCCESS,
  SSO_USER_SEND_OTP_FAILED,
  SSO_USER_DELETE_START,
  SSO_USER_DELETE_SUCCESS,
  SSO_USER_DELETE_FAILED,
  SSO_USER_DELETE_CLEAR,
  SSO_USER_SEND_OTP_TRANSACTION_SUCCESS,
  SSO_USER_SEND_OTP_TRANSACTION_START,
  SSO_USER_SEND_OTP_TRANSACTION_FAILED,
  SSO_USER_SEND_OTP_TRANSACTION_CLEAR,
  SET_GOOGLE_LOGIN_CREDENTIALS,
  SET_APPLE_LOGIN_CREDENTIALS,
  SET_APPLE_LOGIN_CREDENTIALS_CLEAR,
  SET_GOOGLE_LOGIN_CREDENTIALS_CLEAR,
  SAVE_LOGIN_CREDENTIALS,
  SAVE_LOGIN_CREDENTIALS_CLEAR,
  GET_GEOINFO_START,
  GET_GEOINFO_SUCCESS,
  GET_GEOINFO_FAILED,
  SAVE_HELP_MESSAGE1,
  SAVE_HELP_MESSAGE2,
  SAVE_HELP_MESSAGE3,
  SAVE_THEME,
} from "../actions/userAccount/userActionType";
const preferredTheme =
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
export const INITIAL_STATE = {
  setEmailResponse: null,

  checkEmailResponse: null,
  verifiedEmailResponse: null,
  addPhoneNumberResponse: null,
  reSendEmailResponse: null,
  verifyOtpResponse: null,
  reSendOtpResponse: null,
  getUserInfoResponse: null,
  completeRegisterUserResponse: null, // REGISTRATION_INFO,
  registartionResponse: null,
  error: null,
  registeredEmail: null,
  registeredFirstname: null,
  registeredLastname: null,
  registeredPhone: null,
  registeredCountryCode: null,
  registeredCountryName: null,
  registeredPassword: null,
  registeredResultCache: null,
  verifiedEmail: null,
  userLoginInfo: null,
  countryCodeListing: [],
  chnageLocaleResponse: null,
  updateNameResponse: null,
  forgotPasswordResponse: null,
  forgotPasswordSuccess: false,
  logErrorsBrowserResponse: null,
  logErrorsFileResponse: null,
  studentConnectResponse: null,
  validRegistartionResponse: null,
  updatePasswordResponse: null,
  notifications: [],
  newFetchedNotifications: [],
  submitFeedbackResponse: null,
  getDelegatesResponse: [],
  approvedDelegatesResponse: [],
  approvedDelegatesChangeResponse: null,
  delegatesSetDeletedResponse: null,
  deviceTokenUpdateResponse: null,
  isTokenValidResponse: null,
  helpTopics: [],
  supportResponse: null,
  settingNotifications: null,
  updateNotificationSetting: null,
  environment: "prod",
  lastUsedTimestamp: new Date().getTime(),
  currentLocationPermission: "notDetermined",
  futureEntries: [],
  modalLevelInfo: [],
  checkInQuestAnsView: {},
  setHelpListData: {},
  oldHelpInfo: {},
  displayAds: false,
  avatarsPaths: {},
  delegateImageUploadResponse: null,
  delegateImageUploadError: null,
  manualReviewRequest: null,
  manualReviewCancelResponse: null,
  deleteAccount: null,
  isTermsAccepted: false,
  isTermsAcceptedDate: null,
  nextAskMeLater: null,
  backToSchoolFilled: false,
  userImageUploadError: null,
  userImageUploadResponse: null,
  userManualReviewCancelResponse: null,
  userManualReviewRequest: null,
  userProfile: null,

  isReadyToDashboard: false,
  appleLoginResponse: null,
  appleSSOResponse: null,
  googleLoginResponse: null,
  googleSSOResponse: null,
  appleSSOCredentials: null,
  googleSSOCredentials: null,
  croppedImageData: null,
  showImageCrop: false,

  ssoUserSendOTPResponse: null,
  ssoUserAccountDeleteResponse: null,
  ssoUserGenerateOTPResponse: null,
  loginType: 0,
  googleLoginCredentials: null,
  appleLoginCredentials: null,
  loginCredentials: null,
  geoInfoData: null,

  helpMessage1: 0,
  helpMessage2: 0,
  helpMessage3: 0,

  selectedTheme: preferredTheme,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
    case SAVE_REGISTRATION_CLEAR:
      return {
        ...INITIAL_STATE,
        environment: state.environment,
        avatarsPaths: state.avatarsPaths,
        lastRatingRun: state.lastRatingRun,
        savedGeoLogFenceEntries: state.savedGeoLogFenceEntries,
        displayedLocationNote: state.displayedLocationNote,
        currentLocationPermission: state.currentLocationPermission,
        isTermsAccepted: state.isTermsAccepted,
        isTermsAcceptedDate: state.isTermsAcceptedDate,
        nextAskMeLater: state.nextAskMeLater,
        didUserLogout: action.payload,
        appleLoginUsedCount: state.appleLoginUsedCount,
        loginType: state.loginType,
      };
    case SAVE_LAST_USED_TIMESTAMP:
      // console.log("SAVE_LAST_USED_TIMESTAMP ");
      return {
        ...state,
        lastUsedTimestamp: new Date().getTime(),
      };
    case START_REGISTARTION_START:
      return {
        ...state,
        error: null,
        registartionResponse: null,
        registeredResultCache: null,
        isEmailVerified: false,
        isPhoneVerified: false,
        isRegistrationComplete: false,
      };
    case START_REGISTARTION_SUCCESS:
      return {
        ...state,
        registartionResponse: action.payload,
        registeredResultCache: action.payload,
        lastRegisterRun: new Date().getTime(),
        error: null,
      };
    case START_REGISTARTION_FAILED:
      return {
        ...state,
        registartionResponse: null,
        error: action.payload,
        registeredResultCache: null,
      };
    case START_REGISTARTION_CLEAR:
      return {
        ...state,
        registartionResponse: null,
        error: action.payload,
      };

    case SET_EMAIL_START:
      return {
        ...state,
        setEmailResponse: null,
        error: null,
      };
    case SET_EMAIL_SUCCESS:
      return {
        ...state,
        setEmailResponse: action.payload,
        error: null,
      };
    case SET_EMAIL_FAILED:
      return {
        ...state,
        setEmailResponse: action.payload,
        error: null,
      };
    case SET_EMAIL_CLEAR:
      return {
        ...state,
        setEmailResponse: null,
        error: null,
      };
    case VERIFIED_EMAIL_START:
      return { ...state, error: null, isEmailVerified: false };
    case VERIFIED_EMAIL_SUCCESS: {
      //console.log("VERIFIED_EMAIL_SUCCESS",action.payload)
      return {
        ...state,
        verifiedEmailResponse: action.payload,
        error: null,
        isEmailVerified: action.payload.Success,
        lastRegisterRun: new Date().getTime(),
      };
    }

    case VERIFIED_EMAIL_FAILED: {
      //console.log("VERIFIED_EMAIL_FAILED",action.payload)
      return {
        ...state,
        verifiedEmailResponse: action.payload,
        error: null,
        isEmailVerified: false,
      };
    }
    case VERIFIED_EMAIL_CLEAR:
      return {
        ...state,
        verifiedEmailResponse: null,
        error: null,
      };

    case ADD_PHONE_NUMBER_USER_SUCCESS:
      return {
        ...state,
        addPhoneNumberResponse: action.payload,
        lastRegisterRun: new Date().getTime(),
        error: null,
      };
    case ADD_PHONE_NUMBER_USER_FAILED:
      return {
        ...state,
        addPhoneNumberResponse: null,
        error: action.payload,
      };
    case ADD_PHONE_NUMBER_USER_CLEAR:
      return {
        ...state,
        addPhoneNumberResponse: null,
        error: null,
      };
    case RE_SEND_EMAIL_START:
      return { ...state, error: null };
    case RE_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        reSendEmailResponse: action.payload,
        lastRegisterRun: new Date().getTime(),
        error: null,
      };
    case RE_SEND_EMAIL_FAILED:
      return {
        ...state,
        reSendEmailResponse: null,
        error: action.payload,
      };
    case RE_SEND_EMAIL_CLEAR:
      return {
        ...state,
        reSendEmailResponse: null,
        error: null,
      };
    case SAVE_REGISTRATION_INFO: {
      let {
        registeredEmail,
        registeredPassword,
        registeredFirstname,
        registeredLastname,
        registeredPhone,
        registeredCountryCode,
        registeredCountryName,
        verifiedEmail,
      } = { ...state };

      if (action.payload.registeredEmail != null) {
        registeredEmail = action.payload.registeredEmail;
      }
      if (action.payload.registeredPassword != null) {
        registeredPassword = action.payload.registeredPassword;
      }
      if (action.payload.registeredFirstname != null) {
        registeredFirstname = action.payload.registeredFirstname;
      }
      if (action.payload.registeredLastname != null) {
        registeredLastname = action.payload.registeredLastname;
      }
      if (action.payload.registeredPhone != null) {
        registeredPhone = action.payload.registeredPhone;
      }
      if (action.payload.registeredCountryCode != null) {
        registeredCountryCode = action.payload.registeredCountryCode;
      }

      if (action.payload.registeredCountryName != null) {
        registeredCountryName = action.payload.registeredCountryName;
      }
      if (action.payload.verifiedEmail != null) {
        verifiedEmail = action.payload.verifiedEmail;
      }

      return {
        ...state,
        registeredEmail,
        registeredPassword,
        registeredFirstname,
        registeredLastname,
        registeredPhone,
        registeredCountryCode,
        registeredCountryName,
        verifiedEmail,
        lastRegisterRun: new Date().getTime(),
      };
    }
    case VERIFY_OTP_START:
      return {
        ...state,
        verifyOtpResponse: null,
        otpError: null,
        isPhoneVerified: false,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verifyOtpResponse: action.payload,
        otpError: null,
        isPhoneVerified: true,
        lastRegisterRun: new Date().getTime(),
      };
    case VERIFY_OTP_FAILED:
      return {
        ...state,
        verifyOtpResponse: null,
        otpError: action.payload,
        isPhoneVerified: false,
      };
    case VERIFY_OTP_CLEAR:
      return {
        ...state,
        verifyOtpResponse: null,
        otpError: null,
      };
    case RE_SEND_OTP_START:
      return { ...state, error: null };
    case RE_SEND_OTP_SUCCESS:
      return {
        ...state,
        reSendOtpResponse: action.payload,
        lastRegisterRun: new Date().getTime(),
        error: null,
      };
    case RE_SEND_OTP_FAILED:
      return {
        ...state,
        reSendOtpResponse: null,
        error: action.payload,
      };
    case RE_SEND_OTP_CLEAR:
      return {
        ...state,
        reSendOtpResponse: null,
        error: null,
      };

    case GET_USER_INFO_START:
      return { ...state, error: null };
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        getUserInfoResponse: action.payload,
        error: null,
      };
    case GET_USER_INFO_FAILED:
      return {
        ...state,
        getUserInfoResponse: null,
        error: action.payload,
      };
    case GET_USER_INFO_CLEAR:
      return {
        ...state,
        getUserInfoResponse: null,
        error: null,
      };

    case COMPLETE_REGISTRATION_USER_START:
      return { ...state, error: null, isRegistrationComplete: false };
    case COMPLETE_REGISTRATION_USER_SUCCESS:
      return {
        ...state,
        completeRegisterUserResponse: action.payload,
        userLoginInfo: action.payload.LoggedInParentInfo,
        isRegistrationComplete: false,
        lastRegisterRun: new Date().getTime(),
        error: null,
      };
    case COMPLETE_REGISTRATION_USER_FAILED:
      return {
        ...state,
        completeRegisterUserResponse: null,
        isRegistrationComplete: false,
        error: action.payload,
      };
    case COMPLETE_REGISTRATION_USER_CLEAR:
      return {
        ...state,
        completeRegisterUserResponse: null,
        error: null,
      };
    case LOGIN_REGISTERED_USER:
      return {
        ...state,
        userLoginInfo: action.payload,
        httpTokenErrorFlagged: false,
        error: null,
      };
    case LOGIN_USER_START:
      return { ...state, error: null, userLoginInfo: null };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        userLoginInfo: action.payload,
        httpTokenErrorFlagged: false,
        isRegistrationComplete: true,
        error: null,
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        userLoginInfo: null,
        error: action.payload,
        isRegistrationComplete: false,
      };
    case LOGIN_USER_CLEAR:
      return {
        ...state,
        userLoginInfo: null,
        error: null,
      };

    case COUNTRY_CODE_LIST_START:
      return { ...state, error: null };
    case COUNTRY_CODE_LIST_SUCCESS:
      return {
        ...state,
        countryCodeListing: action.payload,
        error: null,
      };
    case CHANGE_LOCALE_START:
      return { ...state, error: null, chnageLocaleResponse: null };
    case CHANGE_LOCALE_SUCCESS:
      return {
        ...state,
        chnageLocaleResponse: action.payload,
        error: null,
      };
    case CHANGE_LOCALE_FAILED:
      return {
        ...state,
        chnageLocaleResponse: null,
        error: action.payload,
      };

    case UPDATE_NAME_START:
      return {
        ...state,
        error: null,
        updateNameResponse: null,
      };
    case UPDATE_NAME_SUCCESS: {
      const userLoginInfo = { ...state.userLoginInfo };
      // console.log("userLoginInfo UPDATE_NAME_SUCCESS ", userLoginInfo);
      // console.log("userLoginInfo UPDATE_NAME_SUCCESS action ", action.payload);
      userLoginInfo.firstName = action.payload.firstName;
      userLoginInfo.lastName = action.payload.lastName;
      return {
        ...state,
        updateNameResponse: action.payload.response,
        error: null,
        userLoginInfo,
      };
    }
    case UPDATE_NAME_FAILED:
      return {
        ...state,
        updateNameResponse: null,
        error: action.payload,
      };
    case UPDATE_NAME_CLEAR:
      return {
        ...state,
        updateNameResponse: null,
        error: action.payload,
      };
    case FORGOT_PASSWORD_START:
      return {
        ...state,
        error: null,
        forgotPasswordSuccess: false,
        forgotPasswordResponse: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordResponse: action.payload,
        forgotPasswordSuccess: true,
        error: null,
      };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPasswordResponse: null,
        forgotPasswordSuccess: false,
        error: action.payload,
      };
    case FORGOT_PASSWORD_CLEAR:
      return {
        ...state,
        forgotPasswordResponse: null,
        forgotPasswordSuccess: false,
        error: action.payload,
      };
    case RAGISTRATION_VALID_START:
      return {
        ...state,
        error: null,
        validRegistartionResponse: null,
      };
    case RAGISTRATION_VALID_SUCCESS:
      return {
        ...state,
        validRegistartionResponse: action.payload,

        error: null,
      };
    case RAGISTRATION_VALID_FAILED:
      return {
        ...state,
        validRegistartionResponse: null,
        error: action.payload,
      };

    case UPDATE_PASSWORD_START:
      return {
        ...state,
        error: null,
        updatePasswordResponse: null,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordResponse: action.payload,
        error: null,
      };
    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        updatePasswordResponse: null,
        error: action.payload,
      };
    case UPDATE_PASSWORD_CLEAR:
      return {
        ...state,
        updatePasswordResponse: null,
        error: action.payload,
      };
    case GET_NOTIFICATIONS_START:
      return {
        ...state,
        error: null,
        newFetchedNotifications: [],
      };
    case GET_NOTIFICATIONS_SUCCESS: {
      let notifications = [...state.notifications];
      notifications = notifications.concat(action.payload);
      return {
        ...state,
        notifications,
        newFetchedNotifications: action.payload,
        error: null,
      };
    }
    case GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case GET_NOTIFICATIONS_CLEAR:
      return {
        ...state,
        error: null,
        notifications: [],
      };
    case SUBMIT_FEEDBACK_START:
      return {
        ...state,
        error: null,
        submitFeedbackResponse: null,
      };
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        submitFeedbackResponse: action.payload,
        error: null,
      };
    case SUBMIT_FEEDBACK_FAILED:
      return {
        ...state,
        submitFeedbackResponse: null,
        error: action.payload,
      };
    case SUBMIT_FEEDBACK_CLEAR:
      return {
        ...state,
        submitFeedbackResponse: null,
        error: null,
      };

    case GET_DELEGATES_START:
      return {
        ...state,
        error: null,
        getDelegatesResponse: [],
      };
    case GET_DELEGATES_SUCCESS:
      return {
        ...state,
        getDelegatesResponse: action.payload,
        error: null,
      };
    case GET_DELEGATES_FAILED:
      return {
        ...state,
        getDelegatesResponse: null,
        error: action.payload,
      };
    case GET_DELEGATES_CLEAR:
      return {
        ...state,
        getDelegatesResponse: [],
        error: action.payload,
      };
    case APPROVED_DELEGATES_START:
      return {
        ...state,
        error: null,
        approvedDelegatesResponse: [],
      };
    case APPROVED_DELEGATES_SUCCESS:
      return {
        ...state,
        approvedDelegatesResponse: action.payload,
        error: null,
      };
    case APPROVED_DELEGATES_FAILED:
      return {
        ...state,
        approvedDelegatesResponse: [],
        error: action.payload,
      };
    case APPROVED_DELEGATES_CLEAR:
      return {
        ...state,
        approvedDelegatesResponse: [],
        error: null,
      };
    case APPROVED_DELEGATES_CHANGE_START:
      return {
        ...state,
        error: null,
        approvedDelegatesChangeResponse: null,
      };
    case APPROVED_DELEGATES_CHANGE_SUCCESS:
      return {
        ...state,
        approvedDelegatesChangeResponse: action.payload,
        error: null,
      };
    case APPROVED_DELEGATES_CHANGE_FAILED:
      return {
        ...state,
        approvedDelegatesChangeResponse: null,
        error: action.payload,
      };
    case APPROVED_DELEGATES_CHANGE_CLEAR:
      return {
        ...state,
        approvedDelegatesChangeResponse: null,
        error: action.payload,
      };
    case DELEGATES_SET_DELETED_START:
      return {
        ...state,
        error: null,
        delegatesSetDeletedResponse: null,
      };
    case DELEGATES_SET_DELETED_SUCCESS:
      return {
        ...state,
        delegatesSetDeletedResponse: action.payload,
        error: null,
      };
    case DELEGATES_SET_DELETED_FAILED:
      return {
        ...state,
        delegatesSetDeletedResponse: null,
        error: action.payload,
      };
    case DELEGATES_SET_DELETED_CLEAR:
      return {
        ...state,
        delegatesSetDeletedResponse: null,
        error: action.payload,
      };
    case UPDATE_DEVICE_TOKEN_START:
      return {
        ...state,
        error: null,
        deviceTokenUpdateResponse: null,
      };
    case UPDATE_DEVICE_TOKEN_SUCCESS:
      return {
        ...state,
        deviceTokenUpdateResponse: action.payload,
        error: null,
      };
    case UPDATE_DEVICE_TOKEN_FAILED:
      return {
        ...state,
        deviceTokenUpdateResponse: null,
        error: action.payload,
      };
    case UPDATE_DEVICE_TOKEN_CLEAR:
      return {
        ...state,
        deviceTokenUpdateResponse: null,
        error: action.payload,
      };
    case GET_TOKEN_VALID_START:
      return {
        ...state,
        error: null,
        isTokenValidResponse: null,
      };
    case GET_TOKEN_VALID_SUCCESS:
      return {
        ...state,
        isTokenValidResponse: action.payload,
        error: null,
      };
    case GET_TOKEN_VALID_FAILED:
      return {
        ...state,
        isTokenValidResponse: null,
        error: action.payload,
      };
    case GET_TOKEN_VALID_CLEAR:
      return {
        ...state,
        isTokenValidResponse: null,
        error: action.payload,
      };
    case GET_HELP_TOPICS_START:
      return {
        ...state,
        error: null,
        helpTopics: [],
      };
    case GET_HELP_TOPICS_SUCCESS:
      return {
        ...state,
        helpTopics: action.payload,
        error: null,
      };
    case GET_HELP_TOPICS_FAILED:
      return {
        ...state,
        helpTopics: [],
        error: action.payload,
      };
    case SEND_SUPPORT_REQUEST_START:
      return {
        ...state,
        supportResponse: null,
        error: null,
      };
    case SEND_SUPPORT_REQUEST_SUCCESS:
      return {
        ...state,
        supportResponse: action.payload,
        error: null,
      };
    case SEND_SUPPORT_REQUEST_FAILED:
      return {
        ...state,
        supportResponse: null,
        error: action.payload,
      };
    case SEND_SUPPORT_REQUEST_CLEAR:
      return {
        ...state,
        supportResponse: null,
        error: null,
      };
    case GET_NOTIFICATION_SETTING_START:
      return {
        ...state,
        error: null,
        settingNotifications: null,
      };
    case GET_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        settingNotifications: action.payload,
        error: null,
      };
    case GET_NOTIFICATION_SETTING_FAILED:
      return {
        ...state,
        settingNotifications: null,
        error: action.payload,
      };

    case GET_NOTIFICATION_SETTING_CLEAR:
      return {
        ...state,
        settingNotifications: null,
        error: null,
      };
    case UPDATE_NOTIFICATION_SETTING_START:
      return {
        ...state,
        updateNotificationSetting: null,
        error: null,
      };
    case UPDATE_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        updateNotificationSetting: action.payload,
        error: null,
      };
    case UPDATE_NOTIFICATION_SETTING_FAILED:
      return {
        ...state,
        updateNotificationSetting: null,
        error: action.payload,
      };
    case UPDATE_NOTIFICATION_SETTING_CLEAR:
      return {
        ...state,
        updateNotificationSetting: null,
        error: null,
      };
    case SET_ENVIRONMENT:
      //console.log(`************ SAVE SET_ENVIRONMENT=${action.payload}`);
      return {
        ...state,
        environment: action.payload,
      };
    case SAVE_LOCATION_PERMISSION:
      return {
        ...state,
        currentLocationPermission: action.payload,
      };
    case LOG_ERRORS_BROWSER_START:
      return {
        ...state,
        error: null,
        logErrorsBrowserResponse: null,
      };
    case LOG_ERRORS_BROWSER_SUCCESS:
      return {
        ...state,
        logErrorsBrowserResponse: action.payload,
        error: null,
      };
    case LOG_ERRORS_BROWSER_FAILED:
      return {
        ...state,
        logErrorsBrowserResponse: null,
        error: action.payload,
      };
    case LOG_ERRORS_BROWSER_CLEAR:
      return {
        ...state,
        logErrorsBrowserResponse: null,
        error: action.payload,
      };
    case LOG_ERRORS_FILE_START:
      return {
        ...state,
        error: null,
        logErrorsFileResponse: null,
      };
    case LOG_ERRORS_FILE_SUCCESS:
      return {
        ...state,
        logErrorsFileResponse: action.payload,
        error: null,
      };
    case LOG_ERRORS_FILE_FAILED:
      return {
        ...state,
        logErrorsFileResponse: null,
        error: action.payload,
      };
    case LOG_ERRORS_FILE_CLEAR:
      return {
        ...state,
        logErrorsFileResponse: null,
        error: action.payload,
      };
    case CONNECT_STUDENT_START:
      return {
        ...state,
        error: null,
        studentConnectResponse: null,
      };
    case CONNECT_STUDENT_SUCCESS:
      return {
        ...state,
        studentConnectResponse: action.payload,
        error: null,
      };
    case CONNECT_STUDENT_FAILED:
      return {
        ...state,
        studentConnectResponse: null,
        error: action.payload,
      };
    case CONNECT_STUDENT_CLEAR:
      return {
        ...state,
        studentConnectResponse: null,
        error: null,
      };
    case GET_FUTURE_ENTRIES_START:
      return {
        ...state,
        futureEntries: [],
        error: null,
      };
    case GET_FUTURE_ENTRIES_SUCCESS:
      return {
        ...state,
        futureEntries: action.payload,
        error: null,
      };
    case GET_FUTURE_ENTRIES_FAILURE:
      return {
        ...state,
        futureEntries: [],
        error: action.payload,
      };
    case GET_FUTURE_ENTRIES_CLEAR:
      return {
        ...state,
        futureEntries: [],
      };
    case TOGGLE_MODAL: {
      // let modalLevel = action.payload.modalLevel;
      let modalLevelInfo = [...state.modalLevelInfo];
      if (!modalLevelInfo || modalLevelInfo === undefined) modalLevelInfo = [];
      // console.log("MODAL REDUX TOGGLE_MODAL before ", modalLevelInfo);
      const len = modalLevelInfo.length;
      const modalInfo = action.payload;
      if (
        len < 4 &&
        modalInfo &&
        modalInfo.modalScreenName &&
        modalInfo.modalScreenName !== undefined
      ) {
        modalLevelInfo.push({});
        modalLevelInfo[len] = {
          modalScreenName: action.payload.modalScreenName,
          modalClassName: action.payload.modalClassName,
          params: action.payload.params,
        };
      }
      // console.log("MODAL REDUX TOGGLE_MODAL after ", modalLevelInfo);

      return {
        ...state,
        modalLevelInfo,
      };
    }
    case HIDE_MODAL: {
      const modalLevelInfo = [...state.modalLevelInfo];
      if (modalLevelInfo.length > 0) modalLevelInfo.pop();

      // console.log("MODAL REDUX HIDE_MODAL update ", modalLevelInfo);
      return {
        ...state,
        modalLevelInfo,
      };
    }
    case TOGGLE_CHECKIN_QUEST_ANS: {
      // console.log("TOGGLE_CHECKIN_QUEST_ANS ", action.payload);
      const checkInQuestAnsView = {
        status: action.payload.status,
        showSchoolChildrenList: action.payload.showSchoolChildrenList,
        params: action.payload.params,
      };
      return {
        ...state,
        checkInQuestAnsView,
      };
    }
    case SET_HELPLIST: {
      const setHelpListData = {
        stackType: action.payload.stackType,
        screen: action.payload.screen,
        title: action.payload.title,
        modalLevel: action.payload.modalLevel,
      };
      // console.log("SET_HELPLIST setHelpListData ", setHelpListData);
      return {
        ...state,
        setHelpListData,
      };
    }

    case SAVE_PHOTO: {
      const avatarsPaths = { ...state.avatarsPaths };
      avatarsPaths[action.payload.id] = action.payload.filename;
      return {
        ...state,
        avatarsPaths,
      };
    }
    case SAVE_ALL_PHOTOS: {
      return {
        ...state,
        avatarsPaths: action.payload,
      };
    }

    case UPLOAD_DELEGATE_IMAGE_START:
      return {
        ...state,
        delegateImageUploadError: null,
        delegateImageUploadResponse: null,
      };
    case UPLOAD_DELEGATE_IMAGE_SUCCESS:
      return {
        ...state,
        delegateImageUploadResponse: action.payload,
        delegateImageUploadError: null,
      };
    case UPLOAD_DELEGATE_IMAGE_FAILED:
      return {
        ...state,
        delegateImageUploadResponse: null,
        delegateImageUploadError: action.payload,
      };
    case UPLOAD_DELEGATE_IMAGE_CLEAR:
      return {
        ...state,
        delegateImageUploadResponse: null,
        delegateImageUploadError: null,
      };
    case CANCEL_MANUAL_REVIEW_START:
      return {
        ...state,
        error: null,
        manualReviewCancelResponse: null,
      };
    case CANCEL_MANUAL_REVIEW_SUCCESS:
      return {
        ...state,
        manualReviewCancelResponse: action.payload,
        error: null,
      };
    case CANCEL_MANUAL_REVIEW_FAILED:
      return {
        ...state,
        manualReviewCancelResponse: null,
        error: action.payload,
      };
    case CANCEL_MANUAL_REVIEW_CLEAR:
      return {
        ...state,
        manualReviewCancelResponse: null,
        error: null,
      };
    case REQUEST_MANUAL_REVIEW_START:
      return {
        ...state,
        error: null,
        manualReviewRequest: null,
      };
    case REQUEST_MANUAL_REVIEW_SUCCESS:
      return {
        ...state,
        manualReviewRequest: action.payload,
        error: null,
      };
    case REQUEST_MANUAL_REVIEW_FAILED:
      return {
        ...state,
        manualReviewRequest: null,
        error: action.payload,
      };
    case REQUEST_MANUAL_REVIEW_CLEAR:
      return {
        ...state,
        manualReviewRequest: null,
        error: null,
      };

    case ACCOUNT_DELETE_START:
      return {
        ...state,
        error: null,
        deleteAccount: null,
      };
    case ACCOUNT_DELETE_SUCCESS:
      return {
        ...state,
        deleteAccount: action.payload,
        error: null,
      };
    case ACCOUNT_DELETE_FAILED:
      return {
        ...state,
        deleteAccount: null,
        error: action.payload,
      };
    case ACCOUNT_DELETE_CLEAR:
      return {
        ...state,
        deleteAccount: null,
        error: null,
      };
    case SAVE_TERMS_ACCEPTED: {
      return {
        ...state,
        isTermsAccepted: action.payload,
        isTermsAcceptedDate: new Date().getTime(),
        error: null,
      };
    }
    case ASK_ME_LATER_SAVE:
      return {
        ...state,
        error: null,
        nextAskMeLater: action.payload,
      };
    case BACKTOSCHOOL_DATA_FILLED:
      return {
        ...state,
        backToSchoolFilled: true,
      };
    case UPLOAD_USER_IMAGE_START:
      return {
        ...state,
        userImageUploadError: null,
        userImageUploadResponse: null,
      };
    case UPLOAD_USER_IMAGE_SUCCESS:
      return {
        ...state,
        userImageUploadResponse: action.payload,
        userImageUploadError: null,
      };
    case UPLOAD_USER_IMAGE_FAILED:
      return {
        ...state,
        userImageUploadResponse: null,
        userImageUploadError: action.payload,
      };
    case UPLOAD_USER_IMAGE_CLEAR:
      return {
        ...state,
        userImageUploadResponse: null,
        userImageUploadError: null,
      };
    case USER_REQUEST_MANUAL_REVIEW_START:
      return {
        ...state,
        error: null,
        userManualReviewRequest: null,
      };
    case USER_REQUEST_MANUAL_REVIEW_SUCCESS:
      return {
        ...state,
        userManualReviewRequest: action.payload,
        error: null,
      };
    case USER_REQUEST_MANUAL_REVIEW_FAILED:
      return {
        ...state,
        userManualReviewRequest: null,
        error: action.payload,
      };
    case USER_REQUEST_MANUAL_REVIEW_CLEAR:
      return {
        ...state,
        userManualReviewRequest: null,
        error: null,
      };
    case USER_CANCEL_MANUAL_REVIEW_START:
      return {
        ...state,
        error: null,
        userManualReviewCancelResponse: null,
      };
    case USER_CANCEL_MANUAL_REVIEW_SUCCESS:
      return {
        ...state,
        userManualReviewCancelResponse: action.payload,
        error: null,
      };
    case USER_CANCEL_MANUAL_REVIEW_FAILED:
      return {
        ...state,
        userManualReviewCancelResponse: null,
        error: action.payload,
      };
    case USER_CANCEL_MANUAL_REVIEW_CLEAR:
      return {
        ...state,
        userManualReviewCancelResponse: null,
        error: null,
      };
    case GET_USER_PROFILE_START:
      return {
        ...state,
        error: null,
        userProfile: null,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        error: null,
      };
    case GET_USER_PROFILE_FAILED:
      return {
        ...state,
        userProfile: null,
        error: action.payload,
      };
    case GET_USER_PROFILE_CLEAR:
      return {
        ...state,
        userProfile: null,
        error: null,
      };

    case SSO_EXIST_START:
      return {
        ...state,
        error: null,
        checkAccountExist: null,
      };
    case SSO_EXIST_SUCCESS: {
      const { payload } = action;
      if (payload.calledForLogin) {
        return {
          ...state,
          userLoginInfo: payload.response.Data.LoggedInParentInfo,
          error: null,
        };
      }
      return {
        ...state,
        checkAccountExistErr:
          "User already registered with this Google/Apple, please use login screen to login",
        error: null,
      };
    }
    case SSO_EXIST_FAILED: {
      const { payload } = action;
      if (payload.calledForLogin) {
        return {
          ...state,
          checkAccountExist: null,
          checkAccountExistErr: action.payload,
        };
      }
      return {
        ...state,
        checkAccountExist: action.payload,
      };
    }
    case SSO_EXIST_CLEAR:
      return {
        ...state,
        checkAccountExist: null,
        checkAccountExistErr: null,
        error: null,
      };
    case MARK_REGISTRATION_COMPLETE:
      return {
        ...state,
        isRegistrationComplete: true,
        error: null,
      };
    case GOOGLE_SSO_RESPONSE_SAVE:
      return {
        ...state,
        ssoGoogleResponse: action.payload,
        error: null,
      };
    case GOOGLE_SSO_RESPONSE_CLEAR:
      return {
        ...state,
        ssoGoogleResponse: null,
        error: null,
      };
    case APPLE_SSO_RESPONSE_SAVE:
      return {
        ...state,
        ssoAppleResponse: action.payload,
        error: null,
      };
    case APPLE_SSO_RESPONSE_CLEAR:
      return {
        ...state,
        ssoAppleResponse: null,
        error: null,
      };
    case CHECK_EMAIL_EXIST_START:
      return {
        ...state,
        error: null,
        checkEmailExistResponse: null,
      };
    case CHECK_EMAIL_EXIST_SUCCESS:
      return {
        ...state,
        checkEmailExistResponse: action.payload,
        error: null,
      };
    case CHECK_EMAIL_EXIST_FAILED:
      return {
        ...state,
        checkEmailExistResponse: null,
        checkEmailExistResponseErr: action.payload,
      };
    case CHECK_EMAIL_EXIST_CLEAR:
      return {
        ...state,
        checkEmailExistResponse: null,
        checkEmailExistResponseErr: null,
        error: null,
      };

    case MARK_READY_TO_DASHBOARD:
      return {
        ...state,
        isReadyToDashboard: true,
        error: null,
      };

    case DO_APPLE_LOGIN_START:
      return {
        ...state,
        appleLoginResponse: null,
        appleSSOCredentials: null,
        googleSSOCredentials: null,
        error: null,
      };

    case DO_APPLE_LOGIN_SUCCESS: {
      const serverResponse = { ...action.payload };
      const loginInfo = { ...serverResponse.Data };
      return {
        ...state,
        appleLoginResponse: serverResponse,
        userLoginInfo: loginInfo,
        appleLoginUsedCount:
          state.appleLoginUsedCount === undefined
            ? 1
            : state.appleLoginUsedCount + 1,
        error: null,
        loginType: GLOBAL_CONST.LOGIN_TYPE.APPLE_LOGIN,
      };
    }
    case DO_APPLE_LOGIN_FAILED: {
      const { serverResponse, appleInfo } = action.payload;
      return {
        ...state,
        appleLoginResponse: serverResponse,
        appleSSOCredentials: appleInfo,
        error: null,
      };
    }
    case DO_APPLE_LOGIN_CLEAR:
      return {
        ...state,
        appleLoginResponse: null,
        error: null,
      };

    case SET_APPLE_SSO_START:
      return {
        ...state,
        appleSSOResponse: null,
        error: null,
      };
    case SET_APPLE_SSO_SUCCESS: {
      const serverResponse = { ...action.payload };
      const userLoginInfo = { ...state.userLoginInfo };
      const Data = { ...serverResponse.Data };
      if (Data) {
        userLoginInfo.IsEmailVerified = Data.IsEmailVerified;
        userLoginInfo.IsPhoneVerified = Data.IsPhoneVerified;
        userLoginInfo.Email = Data.Email;
      }
      return {
        ...state,
        appleSSOResponse: serverResponse,
        userLoginInfo,
        appleLoginUsedCount:
          state.appleLoginUsedCount === undefined
            ? 1
            : state.appleLoginUsedCount + 1,
        error: null,
        loginType: GLOBAL_CONST.LOGIN_TYPE.APPLE_LOGIN,
      };
    }
    case SET_APPLE_SSO_FAILED:
      return {
        ...state,
        appleSSOResponse: action.payload,
        error: null,
      };
    case SET_APPLE_SSO_CLEAR:
      return {
        ...state,
        appleSSOResponse: null,
        error: null,
      };

    case DO_GOOGLE_LOGIN_START:
      return {
        ...state,
        googleLoginResponse: null,
        googleSSOCredentials: null,
        appleSSOCredentials: null,
        error: null,
      };
    case DO_GOOGLE_LOGIN_SUCCESS: {
      return {
        ...state,
        googleLoginResponse: action.payload,
        userLoginInfo: action.payload.Data,
        error: null,
        loginType: GLOBAL_CONST.LOGIN_TYPE.GOOGLE_LOGIN,
      };
    }
    case DO_GOOGLE_LOGIN_FAILED: {
      const { serverResponse, googleInfo } = action.payload;
      return {
        ...state,
        googleLoginResponse: serverResponse,
        googleSSOCredentials: googleInfo,
        error: null,
      };
    }
    case DO_GOOGLE_LOGIN_CLEAR:
      return {
        ...state,
        googleLoginResponse: null,
        error: null,
      };

    case SET_GOOGLE_SSO_START:
      return {
        ...state,
        googleSSOResponse: null,
        error: null,
      };
    case SET_GOOGLE_SSO_SUCCESS: {
      const serverResponse = { ...action.payload };
      const userLoginInfo = { ...state.userLoginInfo };
      const Data = { ...serverResponse.Data };
      if (Data) {
        userLoginInfo.IsEmailVerified = Data.IsEmailVerified;
        userLoginInfo.IsPhoneVerified = Data.IsPhoneVerified;
        userLoginInfo.Email = Data.Email;
      }

      return {
        ...state,
        googleSSOResponse: serverResponse,
        userLoginInfo,
        error: null,
        loginType: GLOBAL_CONST.LOGIN_TYPE.GOOGLE_LOGIN,
      };
    }
    case SET_GOOGLE_SSO_FAILED:
      return {
        ...state,
        googleSSOResponse: action.payload,
        error: null,
      };
    case SET_GOOGLE_SSO_CLEAR:
      return {
        ...state,
        googleSSOResponse: null,
        error: null,
      };
    case SET_CROPPED_IMAGE: {
      const croppedImageData = action.payload.croppedImageData;
      const showImageCrop = action.payload.showImageCrop;
      return {
        ...state,
        croppedImageData,
        showImageCrop,
      };
    }
    case SET_CROPPED_IMAGE_CLEAR:
      return {
        ...state,
        croppedImageData: null,
        showImageCrop: false,
      };

    case SET_LOGIN_TYPE:
      if (action.payload === GLOBAL_CONST.LOGIN_TYPE.GOOGLE_LOGIN) {
        return {
          ...state,
          loginType: action.payload,
        };
      } else {
        return {
          ...state,
          googleSSOCredentials: null,
          appleSSOCredentials: null,
          loginType: action.payload,
        };
      }

    case SSO_USER_SEND_OTP_START:
      return {
        ...state,
        error: null,
        ssoUserSendOTPResponse: null,
      };
    case SSO_USER_SEND_OTP_SUCCESS:
      return {
        ...state,
        ssoUserSendOTPResponse: action.payload,
        error: null,
      };
    case SSO_USER_SEND_OTP_FAILED:
      return {
        ...state,
        ssoUserSendOTPResponse: null,
        error: action.payload,
      };
    case SSO_USER_SEND_OTP_CLEAR:
      return {
        ...state,
        ssoUserSendOTPResponse: null,
        error: null,
      };
    case SSO_USER_DELETE_START:
      return {
        ...state,
        error: null,
        ssoUserAccountDeleteResponse: null,
      };
    case SSO_USER_DELETE_SUCCESS:
      return {
        ...state,
        ssoUserAccountDeleteResponse: action.payload,
        error: null,
      };
    case SSO_USER_DELETE_FAILED:
      return {
        ...state,
        ssoUserAccountDeleteResponse: null,
        error: action.payload,
      };
    case SSO_USER_DELETE_CLEAR:
      return {
        ...state,
        ssoUserAccountDeleteResponse: null,
        error: null,
      };
    case CHECK_EMAIL_START:
      return { ...state, checkEmailResponse: null };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        checkEmailResponse: action.payload,
      };
    case CHECK_EMAIL_FAILED:
      return {
        ...state,
        checkEmailResponse: action.payload,
      };
    case CHECK_EMAIL_CLEAR:
      return {
        ...state,
        checkEmailResponse: null,
      };
    case SSO_USER_SEND_OTP_TRANSACTION_START:
      return {
        ...state,
        error: null,
        ssoUserGenerateOTPResponse: null,
      };
    case SSO_USER_SEND_OTP_TRANSACTION_SUCCESS:
      return {
        ...state,
        ssoUserGenerateOTPResponse: action.payload,
        error: null,
      };
    case SSO_USER_SEND_OTP_TRANSACTION_FAILED:
      return {
        ...state,
        ssoUserGenerateOTPResponse: null,
        error: action.payload,
      };
    case SSO_USER_SEND_OTP_TRANSACTION_CLEAR:
      return {
        ...state,
        ssoUserGenerateOTPResponse: null,
        error: null,
      };

    case SET_GOOGLE_LOGIN_CREDENTIALS: {
      return {
        ...state,
        googleLoginCredentials: action.payload.googleLoginCredentials,
      };
    }
    case SET_GOOGLE_LOGIN_CREDENTIALS_CLEAR:
      return {
        ...state,
        googleLoginCredentials: null,
      };

    case SET_APPLE_LOGIN_CREDENTIALS: {
      return {
        ...state,
        appleLoginCredentials: action.payload.appleLoginCredentials,
      };
    }
    case SET_APPLE_LOGIN_CREDENTIALS_CLEAR:
      return {
        ...state,
        appleLoginCredentials: null,
      };

    case SAVE_LOGIN_CREDENTIALS: {
      return {
        ...state,
        loginCredentials: action.payload.loginCredentials,
      };
    }

    case SAVE_LOGIN_CREDENTIALS_CLEAR:
      return {
        ...state,
        loginCredentials: null,
      };

    case GET_GEOINFO_START:
      return {
        ...state,
        geoInfoData: null,
      };
    case GET_GEOINFO_SUCCESS: {
      return {
        ...state,
        geoInfoData: action.payload,
      };
    }

    case GET_GEOINFO_FAILED:
      return {
        ...state,
        geoInfoData: null,
      };

    case SAVE_HELP_MESSAGE1:
      return {
        ...state,
        helpMessage1:
          state.helpMessage1 === undefined ? 1 : state.helpMessage1 + 1,
      };
    case SAVE_HELP_MESSAGE2:
      return {
        ...state,
        helpMessage2:
          state.helpMessage2 === undefined ? 1 : state.helpMessage2 + 1,
      };
    case SAVE_HELP_MESSAGE3:
      return {
        ...state,
        helpMessage3:
          state.helpMessage3 === undefined ? 1 : state.helpMessage3 + 1,
      };

    case SAVE_THEME: {
      const { selectedTheme, isAuto } = action.payload;
      return {
        ...state,
        selectedTheme: selectedTheme,
        isAutoTheme: isAuto === undefined || isAuto === null ? true : isAuto,
        error: null,
      };
    }

    default:
      return state;
  }
};
