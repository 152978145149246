// Get school pickup time
export const GET_SCHOOL_PICKUP_TIME_START = "get_school_pickup_time_start";
export const GET_SCHOOL_PICKUP_TIME_SUCCESS = "get_school_pickup_time_success";
export const GET_SCHOOL_PICKUP_TIME_FAILED = "get_school_pickup_time_failed";
export const GET_SCHOOL_PICKUP_TIME_CLEAR = "get_school_pickup_time_clear";

export const GET_SCHOOL_PICKUP_MODE_START = "get_school_pickup_mode_start";
export const GET_SCHOOL_PICKUP_MODE_SUCCESS = "get_school_pickup_mode_success";
export const GET_SCHOOL_PICKUP_MODE_FAILED = "get_school_pickup_mode_failed";

export const GET_ABSENT_REASON_START = "get_absent_reason_start";
export const GET_ABSENT_REASON_SUCCESS = "get_absent_reason_success";
export const GET_ABSENT_REASON_FAILED = "get_absent_reason_failed";

export const GET_SCHOOL_AFTER_SCHOOL_START = "get_school_after_school_start";
export const GET_SCHOOL_AFTER_SCHOOL_SUCCESS =
  "get_school_after_school_success";
export const GET_SCHOOL_AFTER_SCHOOL_FAILED = "get_school_after_school_failed";

// export const GET_SELECTED_SCHOOL_INFO_START = 'get_selected_school_info_start';
// export const GET_SELECTED_SCHOOL_INFO_SUCCESS = 'get_selected_info_success';
// export const GET_SELECTED_SCHOOL_INFO_FAILED = 'get_selected_info_failed';
// export const GET_SELECTED_SCHOOL_INFO_CLEAR = 'get_selected_info_clear';

export const GET_HELP_START = "get_help_start";
export const GET_HELP_SUCCESS = "get_help_success";
export const GET_HELP_FAILED = "get_help_failed";
export const GET_HELP_CLAER = "get_help_clear";

export const GET_SCHOOLCALENDAR_START = 'get_schoolcalendar_start';
export const GET_SCHOOLCALENDAR_SUCCESS = 'get_schoolcalendar_success';
export const GET_SCHOOLCALENDAR_FAILED = 'get_schoolcalendar_failed';
export const GET_SCHOOLCALENDAR_CLEAR = 'get_schoolcalendar_clear';

export const GET_SCHOOLCALENDAR_EVENT_START = 'get_schoolcalendar_event_start';
export const GET_SCHOOLCALENDAR_EVENT_SUCCESS = 'get_schoolcalendar_event_success';
export const GET_SCHOOLCALENDAR_EVENT_FAILED = 'get_schoolcalendar_event_failed';
export const GET_SCHOOLCALENDAR_EVENT_CLAER = 'get_schoolcalendar_event_clear';
