import {
  GET_ADVERT_LIST_START,
  GET_ADVERT_LIST_SUCCESS,
  GET_ADVERT_LIST_FAILED,
  GET_ADVERT_LIST_CLEAR,
  GET_ADVERT_SERVE_START,
  GET_ADVERT_SERVE_SUCCESS,
  GET_ADVERT_SERVE_FAILED,
  GET_ADVERT_SERVE_CLEAR,
  GET_ADVERT_CLICK_START,
  GET_ADVERT_CLICK_SUCCESS,
  GET_ADVERT_CLICK_FAILED,
  GET_ADVERT_CLICK_CLEAR,
  GET_ADVERT_PHOTO_START,
  GET_ADVERT_PHOTO_SUCCESS,
  GET_ADVERT_PHOTO_FAILED,
  GET_ADVERT_PHOTO_CLEAR,
  ADVERT_HIDE
} from "../actions/ads/adsActionType";
import { LOGOUT_USER } from "../actions/userAccount/userActionType";

export const INITIAL_STATE = {
  adsList: null,
  logAdvertServe: null,
  logAdvertClick: null,
  parentAdvertPhoto: null,
  error: null,
  isShowAdvert: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...INITIAL_STATE,
      };
    case GET_ADVERT_LIST_START:
      return { ...state, error: null, adsList: null };
    case GET_ADVERT_LIST_SUCCESS:{
      let haveAds = state.isShowAdvert;
      const ads = action.payload;
      if (!ads || !ads.Ads || ads.Ads.length === 0) {
        haveAds = false;
      }
      return {
        ...state,
        adsList: action.payload,
        error: null,
        isShowAdvert: haveAds,
      };

    }
      
    case GET_ADVERT_LIST_FAILED:
      return {
        ...state,
        adsList: null,
        error: action.payload,
      };
    case GET_ADVERT_LIST_CLEAR:
      return {
        ...state,
        adsList: null,
        error: null,
      };

    case GET_ADVERT_SERVE_START:
      return { ...state, error: null, logAdvertServe: null };
    case GET_ADVERT_SERVE_SUCCESS:
      return {
        ...state,
        logAdvertServe: action.payload,
        error: null,
      };
    case GET_ADVERT_SERVE_FAILED:
      return {
        ...state,
        logAdvertServe: null,
        error: action.payload,
      };
    case GET_ADVERT_SERVE_CLEAR:
      return {
        ...state,
        logAdvertServe: null,
        error: null,
      };
    case GET_ADVERT_CLICK_START:
      return { ...state, error: null, logAdvertClick: null };
    case GET_ADVERT_CLICK_SUCCESS:
      return {
        ...state,
        logAdvertClick: action.payload,
        error: null,
      };
    case GET_ADVERT_CLICK_FAILED:
      return {
        ...state,
        logAdvertClick: null,
        error: action.payload,
      };
    case GET_ADVERT_CLICK_CLEAR:
      return {
        ...state,
        logAdvertClick: null,
        error: null,
      };
    case GET_ADVERT_PHOTO_START:
      return { ...state, error: null, parentAdvertPhoto: null };
    case GET_ADVERT_PHOTO_SUCCESS:
      return {
        ...state,
        parentAdvertPhoto: action.payload,
        error: null,
      };
    case GET_ADVERT_PHOTO_FAILED:
      return {
        ...state,
        parentAdvertPhoto: null,
        error: action.payload,
      };
    case GET_ADVERT_PHOTO_CLEAR:
      return {
        ...state,
        parentAdvertPhoto: null,
        error: null,
      };

    case ADVERT_HIDE:
      return {
        ...state,
        isShowAdvert: false,
        error: null,
      };

    default:
      return state;
  }
};
