import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "../CommonComponents/withRouter";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import successIllustration from "../assets/commonImages/successIllustration.svg";
import rejectedIcon from "../assets/commonImages/rejectedIcon.svg";
import Resizer from "react-image-file-resizer";
import {
  createImageFileURL,
  isSSOLogin,
  getCurrentThemeColor,
} from "../../helper/utility";
import OtpInput from "react-otp-input";

import {
  showModal,
  hideModal,
  nameUpdate,
  nameUpdateClear,
  logoutUser,
  saveAvatar,
  setCroppedImage,
  setCroppedImageClear,
} from "../../common/actions/userAccount";
import {
  accountDelete,
  accountDeleteClear,
  getUserProfileInfo,
  UserImageUpload,
  userImageUploadErrorClear,
  userManualRequestClear,
  userManualReviewCancel,
  userManualReviewCancelClear,
  userManualReviewRequest,
  ssoUser_deleteAccount,
  ssoUser_deleteAccountClear,
  ssoUser_sendOTP,
  ssoUser_sendOTPClear,
  saveLoginCredentialsClear,
  setAppleLoginCredentialsClear,
  setGoogleLoginCredentialsClear,
  sendUserWelcomeEmail,
} from "../../common/actions/userAccount/userAccountAction";

import { createLoadingSelector } from "../../common/reducers/selectors";
import { faBedPulse } from "@fortawesome/free-solid-svg-icons";
import { getMyStyle } from "../../theme/ThemeStyle";
const loadingSelector = createLoadingSelector([
  "update_name",
  "user_cancel_manual_review",
]);
const loadingProfileSelector = createLoadingSelector(["get_user_profile"]);
const loadingPhotoSelector = createLoadingSelector(["upload_user_image"]);

class ViewProfile extends React.Component {
  constructor(props) {
    super(props);
    const { userLoginInfo, userProfile } = this.props;
    this.state = {
      enableEditName: true,
      userName: userLoginInfo.firstName + " " + userLoginInfo.lastName,
      userInitials:
        userLoginInfo.firstName.charAt(0) + userLoginInfo.lastName.charAt(0),
      userPhone: userLoginInfo
        ? userLoginInfo.CountryCode + userLoginInfo.mobile
        : "",
      userEmail: userLoginInfo.Email,

      firstNameInvalid: "",
      lastNameInvalid: "",
      firstName: userLoginInfo.firstName,
      lastName: userLoginInfo.lastName,
      firstNameError: "",
      lastNameError: "",
      password: "",
      email: "",
      askVerification: false,
      isValidEmail: false,
      isValidPassword: false,
      emailInvalid: "",
      passwordInvalid: "",

      isModalVisible: false,
      isPreviewVisible: false,
      isRejectedVisible: false,
      userImageUploadError: null,
      isManualReviewSubmitSuccess: false,
      isCancelManualReviewSubmitSuccess: false,
      isUserProfileImageUploaded: false,
      showViewProfile: true,
      isGuidelinesVisible: false,
      isCameraLaunch: false,
      captureImage: null,
      showSelectPhotoTapped: false,
      isPhotoChanged: false,
      otp_deleteAccount: "",
      isShowOTPTxt: false,
      otp: "",
    };
    //this.props.doGetUserProfileInfo();

    this.webCamera = React.createRef();
    this.ImageCrop = React.createRef();
    this.props.doGetUserProfileInfo();
  }
  componentDidMount() {
    const { doGetUserProfileInfo, userProfile, userLoginInfo } = this.props;
    doGetUserProfileInfo((info) => {
      this.setState({ userEmail: info.Email });
    });
  }
  loadUpdates = () => {
    const { userLoginInfo } = this.props;

    this.setState({
      userName: userLoginInfo.firstName + " " + userLoginInfo.lastName,
      userInitials:
        userLoginInfo.firstName.charAt(0) + userLoginInfo.lastName.charAt(0),
    });
  };

  secondTextInput(event) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    if (event.key === "Enter") {
      form.elements[index + 1].focus();
    }
  }

  handleFirstNameChange = ({ target: { value: firstName } }) =>
    this.setState({ firstName, firstNameError: "" });
  handleLastNameChange = ({ target: { value: lastName } }) =>
    this.setState({ lastName, lastNameError: "" });

  isNameChanged = () => {
    const { firstName, lastName } = this.state;
    const { userProfile } = this.props;
    if (!userProfile) {
      return false;
    }
    if (firstName !== userProfile.FirstName) {
      return true;
    }
    if (lastName !== userProfile.LastName) {
      return true;
    }
    return false;
  };
  submitForm = () => {
    const { firstName, lastName } = this.state;
    const { doNameUpdate, doSendWelcomeEmail } = this.props;
    const oldUserLoginInfo = { ...this.props.userLoginInfo };
    // if (this.isNameChanged()) {
    if (firstName.trim() === "") {
      this.setState({ firstNameError: this.props.t("First_Name_Alert") });
      return;
    } else if (lastName.trim() === "") {
      this.setState({ lastNameError: this.props.t("Last_Name_Alert") });
      return;
    } else {
      doNameUpdate({
        firstName,
        lastName,
        callback: () => {
          //console.log('userLoginInfo ' + oldUserLoginInfo.firstName);
          if (
            oldUserLoginInfo &&
            (!oldUserLoginInfo.firstName ||
              oldUserLoginInfo.firstName.trim() === "")
          ) {
            doSendWelcomeEmail();
          }
        },
      });
    }
    // }
  };

  submitDeleteAccount = () => {
    const { userEmail, password, otp } = this.state;
    const { doDeleteAccount, doSsoUser_deleteAccount } = this.props;
    if (isSSOLogin()) {
      if (otp === "") {
        this.setState({
          otpError: `${this.props.t("confirmPhoneScr-valid-otpError")}`,
        });
        return false;
      }
      if (otp.length < 7) {
        this.setState({
          otpError: `${this.props.t("confirmPhoneScr-valid-otpError")}`,
        });
        return false;
      }
    }

    if (!isSSOLogin() && (password === "" || password === null)) {
      this.setState({ passwordInvalid: this.props.t("Password_Alert") });
      return;
    }
    let email = userEmail;
    email = email.replace(".staging", "");
    email = email.replace(".develop", "");
    if (!isSSOLogin()) {
      doDeleteAccount({
        email,
        password,
      });
    } else if (isSSOLogin()) {
      doSsoUser_deleteAccount({
        email,
        OtpCode: otp,
      });
    }
  };

  handlePasswordChange = ({ target: { value: password } }) =>
    this.setState({ password, passwordInvalid: "" });

  handleChange = (otp) => {
    this.setState({ otp, otpError: "" });
  };

  showPasswordOptions = () => {
    const {
      userEmail,
      email,
      password,
      isValidEmail,
      isValidPassword,
      emailInvalid,
      passwordInvalid,
      isShowOTPTxt,
      otpError,
    } = this.state;
    const styles = getMyStyle(this.props.selectedTheme);

    return (
      <div className="delete-account-section">
        <div className="form-card mt-30">
          <form className="form-style1">
            <div className="title-sect mb-24">
              <h3 className="title-3">
                {isSSOLogin()
                  ? this.props.t("Verification")
                  : this.props.t("delete_account_alert")}
              </h3>
              {isSSOLogin() && (
                <p className="help-text">
                  {this.props.t("delete_account_OTP")}
                </p>
              )}
            </div>
            {isSSOLogin() ? (
              <div className="form-holder">
                <div className="form-group phone-otp">
                  <a
                    onClick={() => {
                      this.props.doSsoUser_sendOTP({ deviceType: 6 });
                      this.setState({ isShowOTPTxt: true });
                    }}
                    className="btn needhelp-label mb-10"
                  >
                    {isShowOTPTxt
                      ? this.props.t("regConPhoneScr-resendCode")
                      : this.props.t("generate_OTP")}
                  </a>
                  <div
                    className="sso-otp"
                    style={{ pointerEvents: isShowOTPTxt ? "auto" : "none" }}
                  >
                    <OtpInput
                      inputType="tel"
                      inputStyle={`${
                        isShowOTPTxt
                          ? "inputStyle form-control input-sm"
                          : "inputStyle form-control input-sm otp-filled"
                      }`}
                      value={this.state.otp}
                      onChange={this.handleChange}
                      numInputs={7}
                      shouldAutoFocus={isShowOTPTxt ? true : false}
                      isInputNum
                      renderInput={(props) => <input {...props} />}
                    />
                    <span className="error" id="message">
                      {" "}
                      {otpError}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="form-holder">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t("loginScreen-email")}
                    onKeyDown={(event) => this.secondTextInput(event)}
                    value={userEmail}
                    readOnly={true}
                  />
                  <span className="error"> {emailInvalid}</span>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    name="PasswordRequiredForChange"
                    placeholder={this.props.t("loginScreen-password")}
                    value={password}
                    onChange={this.handlePasswordChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") this.submitDeleteAccount();
                    }}
                  />
                  <span className="error"> {passwordInvalid}</span>
                </div>
              </div>
            )}

            <div className="btn-sect">
              <button
                type="button"
                className="btn  btn-default"
                onClick={(event) => {
                  event.preventDefault();
                  this.submitDeleteAccount();
                }}
                style={styles.buttonDefault}
              >
                {this.props.t("DELETE")}
              </button>
              <button
                className="btn btn-default btn-red"
                onClick={(event) => {
                  event.preventDefault();
                  //onClose();
                  this.setState({
                    askVerification: false,
                    isPreviewVisible: false,
                    showViewProfile: true,
                    isRejectedVisible: false,
                    isUserProfileImageUploaded: false,
                    isPhotoChanged: false,
                  });
                }}
              >
                {this.props.t("regNameScr-cancel")}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  deleteAccountConfirm = () => {
    confirmAlert({
      title: this.props.t("delete_account"),
      message: this.props.t("deleteaccount-areYouSuretext"),
      buttons: [
        {
          label: this.props.t("settingMainScr-ok"),
          onClick: this.deleteAccount,
        },
        {
          label: this.props.t("Cancel"),
          className: "cancel-btn",
        },
      ],
    });
  };
  deleteAccountTapped = () => {
    this.setState({
      password: "",
      askVerification: true,
      isPreviewVisible: false,
      showViewProfile: false,
      isRejectedVisible: false,
      isUserProfileImageUploaded: false,
      isPhotoChanged: false,
    });
  };

  deleteCredentials = async () => {
    this.props.doSaveLoginCredentialsClear();
    this.props.doSetAppleLoginCredentialsClear();
    this.props.doSetGoogleLoginCredentialsClear();
  };

  componentDidUpdate() {
    const {
      updateNameResponse,
      error,
      doNameUpdateClear,
      deleteAccount,
      doDeleteAccountClear,
      userImageUploadResponse,
      userManualReviewRequestResponse,
      userManualReviewCancelResponse,
      doManualReviewCancelClear,
      doGetUserProfileInfo,
      ssoUserSendOTPResponse,
      doSsoUser_sendOTPClear,
      ssoUserAccountDeleteResponse,
      doSsoUser_deleteAccountClear,
    } = this.props;
    const {
      isRejectedVisible,
      showViewProfile,
      isManualReviewSubmitSuccess,
      isCancelManualReviewSubmitSuccess,
      isPhotoChanged,
    } = this.state;

    const {
      userImageUploadErrorResponse,
      doUserImageUploadErrorClear,
      doManualRequestClear,
      croppedImageData,
      showImageCrop,
    } = this.props;

    if (
      croppedImageData !== null &&
      showImageCrop == false &&
      isPhotoChanged == false
    ) {
      this.processImage(croppedImageData, () => {
        this.setState({
          isModalVisible: true,
          isGuidelinesVisible: false,
          isPreviewVisible: true,
          isRejectedVisible: false,
          isPhotoChanged: false,
        });
      });
    }

    if (userImageUploadErrorResponse && !isRejectedVisible) {
      this.setState(
        {
          isPreviewVisible: false,
          showViewProfile: false,
          isRejectedVisible: true,
          userImageUploadError: userImageUploadErrorResponse,
          isUserProfileImageUploaded: true,
          isPhotoChanged: false,
        },
        doUserImageUploadErrorClear
      );

      toast.error(userImageUploadErrorResponse.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
    }

    if (userImageUploadResponse && !showViewProfile) {
      this.setState(
        {
          isPreviewVisible: false,
          showViewProfile: true,
          isRejectedVisible: false,
          isPhotoChanged: false,
        },
        doUserImageUploadErrorClear
      );
      toast.success(userImageUploadResponse.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doGetUserProfileInfo((info) => {
        this.setState({ userEmail: info.Email });
      });
    }

    if (userManualReviewRequestResponse && !isManualReviewSubmitSuccess) {
      this.setState(
        { isManualReviewSubmitSuccess: true, isPhotoChanged: false },
        () => {
          doManualRequestClear();
          doGetUserProfileInfo();
        }
      );
      toast.info(this.props.t("manual_request_submit"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
      });

      this.setState({ isManualReviewSubmitSuccess: true }, () => {
        doManualRequestClear();
        doGetUserProfileInfo((info) => {
          this.setState({ userEmail: info.Email });
        });
      });
    }

    if (userManualReviewCancelResponse && !isCancelManualReviewSubmitSuccess) {
      this.setState({
        isUserProfileImageUploaded: false,
        isPhotoChanged: false,
      });

      this.setState(
        { isCancelManualReviewSubmitSuccess: true, avatarSource: null },
        () => {
          doManualReviewCancelClear();
          doGetUserProfileInfo((info) => {
            this.setState({ userEmail: info.Email });
          });
        }
      );
      toast.info(this.props.t("manual_request_cancel"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
      });
    }

    if (error) {
      toast.error(error.Message || error.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      this.setState({
        // askVerification: false,
        // isShowOTPTxt: false,
        // otp: '',
        isOtpFilled: false,
      });

      doNameUpdateClear();
    } else if (updateNameResponse) {
      toast.success(updateNameResponse.Message || updateNameResponse.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doNameUpdateClear();
      this.setState({ enableEditName: !this.state.enableEditName });
      this.loadUpdates();
    }

    if (deleteAccount != null) {
      if (deleteAccount.Success) {
        toast.success(deleteAccount.Data, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
        this.setState({ askVerification: false }, () => {
          this.props.doLogoutUser();
          this.deleteCredentials();
          this.props.navigate("/");
        });
      } else {
        toast.error(deleteAccount.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
      }
      doDeleteAccountClear();
    }

    if (ssoUserSendOTPResponse) {
      toast.success(ssoUserSendOTPResponse.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doSsoUser_sendOTPClear();
    }
    if (ssoUserAccountDeleteResponse != null) {
      if (ssoUserAccountDeleteResponse.Success) {
        toast.success(ssoUserAccountDeleteResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        this.setState({ askVerification: false }, () => {
          this.props.doLogoutUser();
          this.deleteCredentials();
          this.props.navigate("/");
        });
      } else {
        toast.warn(ssoUserAccountDeleteResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          //icon: false,
        });
      }
      doSsoUser_deleteAccountClear();
    }
  }

  uploadDelegatePhoto = () => {
    const { doUploadUserPhoto } = this.props;
    const { avatarSource } = this.state;
    doUploadUserPhoto({
      image: avatarSource,
    });
  };

  hideUploadPhotoWizard = () => {
    this.setState({
      showViewProfile: true,
      isPreviewVisible: false,
      isRejectedVisible: false,
      isGuidelinesVisible: false,
      showSelectPhotoTapped: false,
    });
  };

  getDelegateImage = () => {
    const { isModalVisible, avatarSource, showViewProfile } = this.state;
    const { userProfile } = this.props;
    if (!showViewProfile && avatarSource) {
      return URL.createObjectURL(avatarSource);
    } else if (userProfile && userProfile.PhotoUrl) {
      return userProfile.PhotoUrl;
    }
    return null;
  };

  processImage = async (captureResponse, callback) => {
    let processImageFile = captureResponse;
    if (
      typeof captureResponse === "string" ||
      captureResponse instanceof String
    ) {
      const resultFile = await this.dataUrlToFile(captureResponse);
      processImageFile = resultFile;
    }

    let asset;

    if (processImageFile) asset = processImageFile;
    if (!asset || asset === undefined) {
      alert("Unable to capture image, please try again");
      return;
    }

    const compressSizer = (size) => {
      const MB = size / Math.pow(1024, 2);
      if (Math.round(MB) === 0) return 100;
      if (Math.round(MB) === 1) return 95;
      if (Math.round(MB) === 2) return 90;
      if (Math.round(MB) === 3) return 85;
      if (Math.round(MB) === 4) return 80;
      if (Math.round(MB) === 5) return 75;
      if (Math.round(MB) === 10) return 70;
      if (Math.round(MB) === 15) return 65;
      if (Math.round(MB) === 20) return 60;
      if (Math.round(MB) >= 25) return 50;
    };
    const compress = compressSizer(asset.size);
    let height = asset.height;
    let width = asset.width;
    let resize;
    if (height === width) resize = { height: 480, width: 480 };
    else if (height > width) resize = { height: 480 };
    else resize = { width: 720 };

    try {
      Resizer.imageFileResizer(
        processImageFile,
        resize.width ? resize.width : resize.height,
        height,
        "JPEG",
        compress,
        0,
        (resizeResponse) => {
          this.props.doSetCroppedImageClear();
          this.setState(
            {
              isPhotoChanged: true,
              avatarSource: resizeResponse,
              showSelectPhotoTapped: false,
            },
            callback
          );
        },
        "file"
      );
    } catch (err) {
      console.log("Resizer errror: ", err);
    }
  };

  openImageCropperModal = () => {
    const { captureImage } = this.state;
    this.props.doShowModal({
      modalScreenName: "imageCropper",
      modalClassName: "imagecrop-modal",
      params: {
        imageFile: captureImage,
        onRef: (ref) => (this.ImageCrop = ref),
      },
    });
  };

  openCameraModal = () => {
    const { captureImage } = this.state;
    this.props.doShowModal({
      modalScreenName: "cameraLauncher",
      modalClassName: "cameralauncher-modal",
      params: {
        doCallbackRefresh: this.setCapuredImageByCamera,
      },
    });
  };
  setCapuredImageByCamera = (resultFile) => {
    this.setState(
      {
        captureImage: resultFile,
      },
      () => {
        this.openImageCropperModal();
      }
    );
  };

  selectPhotoTapped = () => {
    const { croppedImageData, doSetCroppedImage, showImageCrop } = this.props;
    const styles = getMyStyle(this.props.selectedTheme);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui selectphoto-tapped">
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <div className="uploadphoto-wrapper text-center">
                  <div className="title-sect mb-24">
                    <h3 className="title-3">
                      {this.props.t("editChildScr-selectPicture")}
                    </h3>
                  </div>
                </div>
                <div className="react-confirm-alert-button-group">
                  <label
                    htmlFor="upload_delegate_photo"
                    className="custom-file-upload btn btn-default"
                    style={styles.buttonDefault}
                  >
                    {this.props.t("editChildScr-chooseLibrary")}
                  </label>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    id="upload_delegate_photo"
                    name="upload_delegate_photo"
                    onChange={(event) => {
                      onClose();
                      this.setState(
                        {
                          captureImage: event.target.files[0],
                        },
                        () => {
                          doSetCroppedImage({
                            croppedImageData: null,
                            showImageCrop: true,
                          });
                          this.openImageCropperModal();
                        }
                      );
                    }}
                  />

                  <button
                    type="button"
                    onClick={(event) => {
                      onClose();
                      this.openCameraModal();
                    }}
                  >
                    {this.props.t("editChildScr-takePhoto")}
                  </button>
                  <button
                    className="cancel-btn"
                    type="button"
                    onClick={() => {
                      this.setState(
                        {
                          isGuidelinesVisible: true,
                          showViewProfile: false,
                          isPreviewVisible: false,
                          isRejectedVisible: false,
                          isCameraLaunch: false,
                          showSelectPhotoTapped: false,
                        },
                        () => {
                          onClose();
                        }
                      );
                    }}
                  >
                    {this.props.t("Close_Button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  dataUrlToFile = async (dataUrl) => {
    var filename = new Date().getTime();
    try {
      const result = await fetch(dataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const captureResponse = new File([blob], filename, {
            type: "image/jpeg",
          });

          return captureResponse;
        });

      if (result) return result;
      else return {};
    } catch (error) {
      console.error(error);
    }
    return {};
  };

  renderViewProfile = () => {
    const {
      userName,
      userPhone,
      firstNameError,
      lastNameError,
      firstName,
      lastName,
      enableEditName,
      isManualReviewSubmitSuccess,
      isUserProfileImageUploaded,
    } = this.state;

    const { isFetching, registeredEmail, userProfile } = this.props;
    const styles = getMyStyle(this.props.selectedTheme);

    return (
      <div className="viewprofile-section">
        <div className="profileDetails">
          <div className="profile-image">
            <figure
              className="photo-frame photo-frame-style2"
              onClick={() => {
                this.setState({
                  isGuidelinesVisible: true,
                  showViewProfile: false,
                  isPreviewVisible: false,
                  isRejectedVisible: false,
                });
              }}
            >
              {this.getDelegateImage() !== null ? (
                <span>
                  <img className="profile-pic" src={this.getDelegateImage()} />
                  <small
                    className="alert-icon"
                    style={{
                      bottom:
                        userProfile &&
                        userProfile.PhotoUrl &&
                        !userProfile.ImageInReview
                          ? "-18px"
                          : "-9px",
                    }}
                  >
                    {userProfile &&
                      userProfile.PhotoUrl &&
                      !userProfile.ImageInReview && (
                        <i className="fas fa-check-circle success-icon"></i>
                      )}
                    {userProfile && userProfile.ImageInReview && (
                      <img src={rejectedIcon} className="rejected-icon" />
                    )}
                  </small>
                </span>
              ) : (
                <span className="no-pic">
                  <i className="fas fa-child"></i>
                  <small
                    className="alert-icon"
                    style={{ bottom: "-57px", color: "#3a65c7" }}
                  >
                    <i
                      className="fas fa-plus-circle"
                      style={{
                        color: getCurrentThemeColor(this.props.selectedTheme)
                          .blue,
                      }}
                    ></i>
                  </small>
                </span>
              )}
            </figure>

            <div className="inappropriate-content">
              {(isManualReviewSubmitSuccess && isUserProfileImageUploaded) ||
              (userProfile && userProfile.ImageInReview) ? (
                <div className="alert alert-warning">
                  <em>{this.props.t("DP_review")}</em>
                </div>
              ) : null}

              {userProfile && userProfile.ImageInReview && (
                <button
                  type="button"
                  className="btn action-btn red-text"
                  onClick={() => {
                    this.props.doManualReviewCancel();
                  }}
                >
                  {this.props.t("cancelReview")}
                </button>
              )}
            </div>
          </div>

          <div className="username">
            <h4>
              <span>{userName}</span>
              {!enableEditName && (
                <button
                  type="button"
                  className="btn profile-edit"
                  onClick={() => {
                    this.setState({ enableEditName: true });
                  }}
                >
                  <i className="fas fa-user-edit"></i>
                </button>
              )}
            </h4>
          </div>
          <div className="user-otherinfo">
            <p>{userPhone}</p>
            <p>
              <a href={"mailto:" + this.state.userEmail}>
                {this.state.userEmail}
              </a>
            </p>

            {userProfile && userProfile.HasVerificationInProgress && (
              <div className="emailverification-status">
                <span className="emailverification-pendingtxt">
                  <em>{this.props.t("emailverification")}</em>
                  <br />
                  <em>{" (" + userProfile.NewEmailAddress + ")"}</em>
                </span>
                <button
                  type="button"
                  className="btn verifyemail-btn"
                  onClick={() => {
                    this.props.navigate("/newconfirmEmail", {
                      state: {
                        userEmail: userProfile.NewEmailAddress,
                        screenFrom: "viewprofile",
                      },
                    });
                    this.closeModal();
                  }}
                >
                  {this.props.t("regCardScr-verifyEmail")}
                </button>
              </div>
            )}
          </div>
        </div>
        {enableEditName === true && (
          <div className="form-card">
            <form className="form-style1">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    firstNameError !== "" ? "input-error" : null
                  }`}
                  placeholder={this.props.t("regNameScr-firstName")}
                  value={firstName}
                  name="firstName"
                  maxLength={30}
                  onChange={this.handleFirstNameChange}
                  onKeyDown={(event) => this.secondTextInput(event)}
                />
                <span className="error"> {firstNameError}</span>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className={`form-control ${
                    lastNameError !== "" ? "input-error" : null
                  }`}
                  placeholder={this.props.t("regNameScr-lastName")}
                  value={lastName}
                  name="lastName"
                  onChange={this.handleLastNameChange}
                  maxLength={30}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") this.submitForm();
                  }}
                />
                <span className="error"> {lastNameError}</span>
              </div>
              <div className="form-group">
                <input
                  readOnly={true}
                  type="text"
                  className="form-control input-disabled"
                  placeholder={this.props.t("regPhoneScr-phoneNumberPlace")}
                  value={userPhone}
                  name="phone"
                />
                <i className="fas fa-phone userIcon"></i>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="form-control updateEmail-control"
                  onClick={() => {
                    this.closeModal();
                    this.props.navigate("/sso", {
                      state: { screenFrom: "account" },
                    });
                  }}
                >
                  <div className="formcontrol-info">
                    <small>{this.props.t("updateEmail")}</small>
                    <span>{this.state.userEmail}</span>
                  </div>
                  <i className="fas fa-chevron-right userIcon"></i>
                </button>
              </div>
              <div className="btn-sect">
                <button
                  type="button"
                  className="btn  btn-default"
                  onClick={this.submitForm}
                  style={styles.buttonDefault}
                >
                  {isFetching ? (
                    <i className="fas fa-sync-alt fa-spin"></i>
                  ) : null}{" "}
                  {this.props.t("changePickupScr-save")}
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-red"
                  onClick={() => {
                    this.setState({ enableEditName: false });
                  }}
                >
                  {this.props.t("editChildScr-cancel")}
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="delete-account-sect pdt-20">
          <button
            type="button"
            className="btn btn-action red-text delete-acount-btn"
            onClick={() => {
              this.deleteAccountTapped();
              //this.showPasswordOptions();
            }}
          >
            <i className="fas fa-trash-alt mr-5px"></i>
            <span className="text">{this.props.t("deleteAccount")}</span>
          </button>
        </div>
      </div>
    );
  };

  showGuidelinesView = () => {
    const styles = getMyStyle(this.props.selectedTheme);
    return (
      <div className="form-card mt-30">
        <div className="guidelines-view">
          <div className="header-sect text-center">
            <figure className="mb-24">
              <img
                src={successIllustration}
                alt="Edit Contact"
                title="Edit Contact"
              />
            </figure>
            <div className="title-sect mb-24">
              <h3 className="title-3">{this.props.t("guidelines_title")}</h3>
            </div>
          </div>
          <div className="content-sect">
            <ul className="list text-left">
              <li>{this.props.t("photoupload_guideline1")}</li>
              <li>{this.props.t("photoupload_guideline2")}</li>
              <li>{this.props.t("photoupload_guideline3")}</li>
            </ul>
          </div>
          <div className="button-sect pdt-48">
            <button
              className="btn btn-default btn-block"
              type="button"
              style={styles.buttonDefault}
              onClick={() => {
                this.selectPhotoTapped();
              }}
            >
              {this.props.t("choose_photo")}
            </button>
          </div>
          <div className="bottom-sect text-center">
            <button
              className="btn close-button"
              type="button"
              onClick={() => {
                this.hideUploadPhotoWizard();
              }}
              aria-label={this.props.t("Close_Button")}
            >
              {this.props.t("Close_Button")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  showPreviewView = () => {
    const { avatarSource } = this.state;
    const { isProcessingVisible, doSetCroppedImage } = this.props;
    const styles = getMyStyle(this.props.selectedTheme);
    return (
      <div className="form-card mt-40">
        <div className="photo-preview">
          <form encType="multipart/form-data">
            <div className="header-sect text-center">
              <div className="title-sect mb-24">
                <h3 className="title-3">{this.props.t("preview_label")}</h3>
              </div>
            </div>
            <div className="content-sect">
              <div className="photo-frame frame-lg">
                {avatarSource ? (
                  <img
                    src={createImageFileURL(avatarSource)}
                    alt={this.props.t("preview_label")}
                  />
                ) : (
                  <i className="fas fa-child"></i>
                )}
              </div>
            </div>
            <div className="button-sect">
              {!isProcessingVisible && (
                <button
                  type="button"
                  className="btn btn-default btn-block"
                  style={styles.buttonDefault}
                  onClick={() => {
                    this.props.doSetCroppedImageClear();
                    this.setState(
                      {
                        isManualReviewSubmitSuccess: false,
                        isPhotoChanged: false,
                        isCancelManualReviewSubmitSuccess: false,
                        delegateImageUploadError: null,
                      },
                      this.uploadDelegatePhoto
                    );
                  }}
                >
                  {this.props.t("Confirm_Button")}
                </button>
              )}
              {isProcessingVisible && (
                <div className="processing-loader">
                  <i className="loader-icon"></i> &nbsp;
                  <span className="processing-text">
                    {this.props.t("processing_text")}
                  </span>
                </div>
              )}
            </div>
            {!isProcessingVisible && (
              <div className="bottom-sect text-center">
                <button
                  className="btn close-button"
                  type="button"
                  onClick={() => {
                    this.props.doSetCroppedImageClear();
                    this.setState(
                      { avatarSource: null, isPhotoChanged: false },
                      this.hideUploadPhotoWizard
                    );
                  }}
                >
                  {this.props.t("Close_Button")}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  };
  renderRejectedImagePreview = () => {
    const { avatarSource, userImageUploadError } = this.state;
    const styles = getMyStyle(this.props.selectedTheme);
    return (
      <div className="form-card mt-30">
        <div className="rejected-preview text-center">
          <div className="content-sect">
            <div className="photo-frame frame-lg">
              {avatarSource ? (
                <span>
                  <img
                    src={createImageFileURL(avatarSource)}
                    alt={this.props.t("preview_label")}
                  />
                  <i className="alert-icon">
                    <img
                      src={rejectedIcon}
                      className="rejected-icon"
                      alt={this.props.t("preview_label")}
                    />
                  </i>
                </span>
              ) : (
                <i className="fas fa-child"></i>
              )}
            </div>
            <div className="alert alert-warning">
              {userImageUploadError && userImageUploadError.Message}
            </div>
          </div>
          <div className="button-sect">
            <button
              type="button"
              className="btn btn-default btn-block"
              style={styles.buttonDefault}
              onClick={() => {
                this.setState({
                  isGuidelinesVisible: true,
                  showViewProfile: false,
                  isPreviewVisible: false,
                  isRejectedVisible: false,
                });
              }}
            >
              {this.props.t("upload_anotherphoto_text")}
            </button>
            <div className="sep-hr"></div>
            <div className="incorrect-label">
              {this.props.t("doyouthink_incorrect_text")}
            </div>
            <div className="mb-16">
              <button
                type="button"
                className="btn btn-style2"
                onClick={() => {
                  const { userImageUploadError } = this.state;
                  if (userImageUploadError && userImageUploadError.Data) {
                    this.props.doManualReviewRequest(
                      userImageUploadError.Data.RejectedImageId
                    );
                  }
                  this.hideUploadPhotoWizard();
                }}
              >
                {this.props.t("request_manual_review")}
              </button>
            </div>
            <p className="mb-36">
              <span className="highlighted-text">
                {this.props.t("mighttakeabout_1businessday")}
              </span>
            </p>
          </div>
          <div className="bottom-sect">
            <button
              className="btn close-button"
              type="button"
              onClick={() => {
                this.hideUploadPhotoWizard();
              }}
            >
              {this.props.t("Close_Button")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  closeModal = () => {
    this.props.doHideModal();
  };

  render() {
    const {
      userName,
      userPhone,
      userInitials,
      firstNameInvalid,
      lastNameInvalid,
      firstNameError,
      lastNameError,
      firstName,
      lastName,
      enableEditName,
      askVerification,
      isPreviewVisible,
      isRejectedVisible,
      isRemovePhotoPromptVisible,
      showViewProfile,
      isGuidelinesVisible,
    } = this.state;

    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="popup-header">
            <button
              type="button"
              className="close-btn pull-right"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>
          <div className="modal-body">
            {showViewProfile && this.renderViewProfile()}
            {askVerification && this.showPasswordOptions()}
            {isGuidelinesVisible && this.showGuidelinesView()}
            {isPreviewVisible && this.showPreviewView()}
            {isRejectedVisible && this.renderRejectedImagePreview()}
            {isRemovePhotoPromptVisible && this.removePhotoView()}
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    registeredEmail: state.userAccount.registeredEmail,
    deleteAccount: state.userAccount.deleteAccount,
    isFetching: loadingSelector(state),
    updateNameResponse: state.userAccount.updateNameResponse,
    error: state.userAccount.error,
    isProfileLoading: loadingProfileSelector(state),
    userImageUploadErrorResponse: state.userAccount.userImageUploadError,
    userImageUploadResponse: state.userAccount.userImageUploadResponse,
    isProcessingVisible: loadingPhotoSelector(state),
    userManualReviewRequestResponse: state.userAccount.userManualReviewRequest,
    userManualReviewCancelResponse:
      state.userAccount.userManualReviewCancelResponse,
    userProfile: state.userAccount.userProfile,
    croppedImageData: state.userAccount.croppedImageData,
    showImageCrop: state.userAccount.showImageCrop,

    loginType: state.userAccount.loginType,
    ssoUserSendOTPResponse: state.userAccount.ssoUserSendOTPResponse,
    ssoUserAccountDeleteResponse:
      state.userAccount.ssoUserAccountDeleteResponse,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doNameUpdate: (data) => dispatch(nameUpdate(data)),
    doNameUpdateClear: () => dispatch(nameUpdateClear()),
    doLogoutUser: () => dispatch(logoutUser()),
    doDeleteAccount: (data) => dispatch(accountDelete(data)),
    doDeleteAccountClear: () => dispatch(accountDeleteClear()),

    doSsoUser_sendOTP: (data) => dispatch(ssoUser_sendOTP(data)),
    doSsoUser_sendOTPClear: () => dispatch(ssoUser_sendOTPClear()),
    doSsoUser_deleteAccount: (data) => dispatch(ssoUser_deleteAccount(data)),
    doSsoUser_deleteAccountClear: () => dispatch(ssoUser_deleteAccountClear()),

    doSaveAvatar: (data) => dispatch(saveAvatar(data)),
    doUploadUserPhoto: (data) => dispatch(UserImageUpload(data)),
    doUserImageUploadErrorClear: (data) =>
      dispatch(userImageUploadErrorClear(data)),
    doManualReviewRequest: (data) => dispatch(userManualReviewRequest(data)),
    doManualRequestClear: () => dispatch(userManualRequestClear()),
    doManualReviewCancel: () => dispatch(userManualReviewCancel()),
    doManualReviewCancelClear: () => dispatch(userManualReviewCancelClear()),
    doGetUserProfileInfo: (callback) => dispatch(getUserProfileInfo(callback)),

    doSetCroppedImage: (data) => dispatch(setCroppedImage(data)),
    doSetCroppedImageClear: () => dispatch(setCroppedImageClear()),
    doSaveLoginCredentialsClear: () => dispatch(saveLoginCredentialsClear()),
    doSetAppleLoginCredentialsClear: () =>
      dispatch(setAppleLoginCredentialsClear()),
    doSetGoogleLoginCredentialsClear: () =>
      dispatch(setGoogleLoginCredentialsClear()),
    doSendWelcomeEmail: () => dispatch(sendUserWelcomeEmail()),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewProfile))
);
