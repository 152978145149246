import { getEnvironment, getAxios } from "./config";
import { validateResponse } from "../../helper/utility";

export const parentAdvertList = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}advert/GetParentAdvertList`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    SchoolIDs: params.SchoolIDs,
  });

  return validateResponse(response);
};

export const logAdvertServe = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}advert/LogAdServe?SchoolID=14&ParentAdvertisementID=18`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl, {
    SchoolID: params.schoolID,
    ParentAdvertisementID: params.ParentAdvertisementID,
  });

  return validateResponse(response);
};

export const parentAdvertPhoto = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}advert/GetParentAdvertisementPhoto`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl, {
    SchoolID: params.schoolID,
    ParentAdvertisementID: params.ParentAdvertisementID,
  });

  return validateResponse(response);
};

export const logAdClick = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}advert/LogAdClick?SchoolID=
  ${params.SchoolID}&ParentAdvertisementID=${params.ParentAdvertisementID}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};
