import React from "react";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/app.css";
import "./Styles/style.css";
import "./Styles/theme-responsive.css";
import { connect } from "react-redux";
import {
  RegisterFooter,
  RegisterHeader,
  NewRegisterWizard,
  withRouter,
} from "../../CommonComponents";
import {
  showModal,
  hideModal,
  setHelpList,
} from "../../../common/actions/userAccount";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import verifyEmailImage from "../../assets/registerImages/verify-email.svg";
import { createLoadingSelector } from "../../../common/reducers/selectors";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";

import {
  verifiedUserEmail,
  verifiedUserEmailClear,
  reSendEmailUser,
  reSendEmailClear,
} from "../../../common/actions/userAccount";
import { markReadToDashboard } from "../../../common/actions/userAccount/userAccountAction";
import { Line } from "rc-progress";
import GLOBAL_CONST from "../../../helper/AppConstants";
const loadingSelector = createLoadingSelector([
  "verified_email",
  "re_send_email",
]);

class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerified: false,
    };
  }

  componentDidMount() {
    const { doReSendEmailUser } = this.props;
    this.dosetHelpList();
    let { sendEmail } = this.props.location.state;
    if (sendEmail === true) {
      doReSendEmailUser();
    }
  }
  componentWillUnmount() {
    // this.handlerAppState.remove();
  }

  dosetHelpList = () => {
    let screenFrom = null;
    const { location } = this.props;
    if (location && location.state) {
      screenFrom = location.state.screenFrom;
    }
    this.props.dosetHelpList({
      stackType:
        screenFrom === "dashboard" ||
        screenFrom === "childManagement" ||
        screenFrom === "checkin" ||
        screenFrom === "account"
          ? "Account"
          : "Registration",
      screen: `${
        screenFrom ===
        (screenFrom === "dashboard" ||
          screenFrom === "childManagement" ||
          screenFrom === "checkin" ||
          screenFrom === "account")
          ? GLOBAL_CONST.SCREEN_NAMES.ACCOUNT_VERIFY_EMAIL
          : GLOBAL_CONST.SCREEN_NAMES.REGISTRATION_VERIFY_EMAIL
      }`,
      title: this.props.t("regCardScr-regAccount"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  resendEmail = () => {
    const { doReSendEmailUser } = this.props;
    let { registeredEmail } = this.props;
    if (registeredEmail.includes(".staging")) {
      registeredEmail = registeredEmail.replace(".staging", "");
    } else if (registeredEmail.includes(".develop")) {
      registeredEmail = registeredEmail.replace(".develop", "");
    } else if (registeredEmail.includes(".localhost")) {
      registeredEmail = registeredEmail.replace(".localhost", "");
    }

    doReSendEmailUser({ Email: registeredEmail });
  };

  openViewProfileModal = () => {
    this.props.doShowModal({
      modalScreenName: "ViewProfile",
      modalClassName: "viewProfileModal sidebar-modal  right",
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      verifiedEmailResponse,
      error,
      doVerifiedUserEmailClear,
      reSendEmailResponse,
      doReSendEmailUserClear,
    } = this.props;
    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
    } else if (reSendEmailResponse) {
      if (reSendEmailResponse.Success === true) {
        toast.success(reSendEmailResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        doVerifiedUserEmailClear();
      } else {
        toast.error(reSendEmailResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
      }
      doReSendEmailUserClear();
    } else if (verifiedEmailResponse) {
      if (verifiedEmailResponse.Success === true) {
        toast.success(verifiedEmailResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
        this.processNext();
      } else {
        toast.error(verifiedEmailResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
      }
      doVerifiedUserEmailClear();
    }
  }

  processNext = () => {
    // If no kids then take user to onboarding else on app dashboard.
    const { getUserInfoResponse, userLoginInfo, doMarkReadToDashboard, route } =
      this.props;
    let screenFrom = null;
    const { location } = this.props;
    if (location && location.state) {
      screenFrom = location.state.screenFrom;
    }
    if (
      screenFrom === "dashboard" ||
      screenFrom === "childManagement" ||
      screenFrom === "checkin"
    ) {
      this.props.navigate(-1);
      this.props.navigate(-1);
      return;
    } else if (screenFrom === "account") {
      this.props.navigate(-1);
      this.props.navigate(-1);

      setTimeout(() => {
        this.openViewProfileModal();
      }, 200);

      return;
    } else if (screenFrom === "viewprofile") {
      this.props.navigate(-1);
      setTimeout(() => {
        this.openViewProfileModal();
      }, 200);

      return;
    }
    if (screenFrom === "landing") {
      this.props.navigate("/childManagement");
      return;
    }

    if (
      userLoginInfo &&
      (!userLoginInfo.firstName || userLoginInfo.firstName.trim() === "")
    ) {
      this.props.navigate("/newregisterName");
    } else if (
      getUserInfoResponse &&
      getUserInfoResponse.Data &&
      (getUserInfoResponse.Data.KidsAtSchools > 0 ||
        getUserInfoResponse.Data.KidsDelegatedCurrently)
    ) {
      this.props.navigate("/childManagement");
    } else {
      this.props.navigate("/newchooseRole", {
        state: { screenFrom: "registration_chooserole" },
      });
    }
  };

  previousScreen = () => {
    const { location } = this.props;
    let screenFrom = null;
    if (location && location.state) {
      screenFrom = location.state.screenFrom;
    }
    if (
      screenFrom === "dashboard" ||
      screenFrom === "childManagement" ||
      screenFrom === "checkin" ||
      screenFrom === "account"
    ) {
      this.props.navigate(-1);
      this.props.navigate(-1);
      return;
    } else if (screenFrom === "viewprofile") {
      this.props.navigate(-1);
      setTimeout(() => {
        this.openViewProfileModal();
      }, 200);

      return;
    } else {
      this.props.navigate(-1);
    }
  };
  render() {
    const { isFetching, doVerifiedUserEmail, doMarkRegisterationComplete } =
      this.props;
    let userEmail = this.props.location.state.userEmail;

    return (
      <section className="registration accountexists-sect">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  {/* <NewRegisterWizard
                    webPage={this.props.location.pathname}
                    isBackBtnVisible={false}
                  /> */}
                  <div className="probressbar-holder">
                    <Line percent={70} strokeWidth="1" strokeColor="#7AC943" />
                  </div>
                  <div className="container-sect">
                    <form className="form-style1">
                      <div className="verify-sect">
                        <div className="mb-40 text-center">
                          <img
                            src={verifyEmailImage}
                            alt="PikMyKid"
                            title="PikMyKid"
                          />
                        </div>
                        <div className="title-sect mb-40">
                          <h3 className="title-3">
                            {this.props.t("regConEmailScr-verify-emailLabel")}
                          </h3>
                          <p className="helptext text-center">
                            {this.props.t("regConEmailScr-sentEmail")}{" "}
                            <a
                              className="orange-text"
                              href={`mailto:${userEmail}`}
                            >
                              {userEmail}
                            </a>{" "}
                            {this.props.t("regConEmailScr-EmailLink")}{" "}
                            <a
                              onClick={() => this.props.doReSendEmailUser()}
                              className="needhelp-label"
                            >
                              {this.props.t("regConEmailScr-clickhere")}
                            </a>{" "}
                            {this.props.t("regConEmailScr-resend-link")}
                          </p>
                        </div>
                        <div className="form-group mb-40 text-center">
                          <a
                            onClick={() => {
                              this.props.navigate("/newregisterEmail");
                            }}
                            className="needhelp-label"
                          >
                            {this.props.t("regConEmailScr-changeEmail")}
                          </a>
                        </div>
                        <div className="form-group mb-40 text-left">
                          <button
                            type="button"
                            className="btn needhelp-label"
                            onClick={() => {
                              this.openHelpModal();
                            }}
                          >
                            {this.props.t("regNameScr-needHelp")}
                          </button>
                        </div>
                        <div className="form-group text-center">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              doVerifiedUserEmail();
                            }}
                          >
                            {" "}
                            {isFetching ? (
                              <i className="fas fa-sync-alt fa-spin"></i>
                            ) : null}{" "}
                            &nbsp; {this.props.t("regCardScr-verifyEmail")}
                          </button>
                          <div className="bottom-sect text-center">
                            <button
                              className="btn close-button"
                              type="button"
                              onClick={() => {
                                this.previousScreen();
                              }}
                            >
                              {this.props.t("regNameScr-cancel")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <ToastContainer />
                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: loadingSelector(state),
    setHelpListData: state.userAccount.setHelpListData,
    error: state.userAccount.error,
    verifiedEmailResponse: state.userAccount.verifiedEmailResponse,
    reSendEmailResponse: state.userAccount.reSendEmailResponse,
    getUserInfoResponse: state.userAccount.getUserInfoResponse,
    userLoginInfo: state.userAccount.userLoginInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doVerifiedUserEmail: () => dispatch(verifiedUserEmail()),
    doVerifiedUserEmailClear: () => dispatch(verifiedUserEmailClear()),
    doReSendEmailUser: () => dispatch(reSendEmailUser()),
    doReSendEmailUserClear: () => dispatch(reSendEmailClear()),
    doMarkReadToDashboard: () => dispatch(markReadToDashboard()),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    dosetHelpList: (data) => dispatch(setHelpList(data)),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail))
);
