import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getDelegationToParent,
  getDelegationToParentClear,
} from "../../common/actions/students/studentAction";
import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";
import moment from "moment";

class FutureDelegation extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    this.getRecords(false);
    this.dosetHelpList();
  };

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "ChildManagement",
      screen: GLOBAL_CONST.SCREEN_NAMES.FUTURE_DELEGATIONS,
      title: this.props.t("Future_Delegation"),
    });
  };

  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  getRecords = (reload) => {
    let ChildInfo = this.props.child;
    const { doGetDelegationToParent, getDelegationsRecord } = this.props;
    let studentId = ChildInfo.StudentDBID;
    const today = new Date();
    let startDate = moment(today).format("MM/DD/YYYY");

    let lastRecord = 0;
    if (reload === false && getDelegationsRecord.length > 0) {
      lastRecord =
        getDelegationsRecord[getDelegationsRecord.length - 1].CalendarID;
    }
    doGetDelegationToParent({
      studentId,
      startDate,
      lastRecord,
      pageSize: 20,
    });
  };
  componentWillUnmount() {
    const { doGetDelegationToParentClear } = this.props;
    doGetDelegationToParentClear();
  }

  getFutureDelegationView = () => {
    const { getDelegationsRecord } = this.props;
    let ChildInfo = this.props.ChildInfo;
    return (
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">
                {this.props.t("Future_Delegation")}
              </h4>
              <button
                type="button"
                className="btn help-icon-btn"
                onClick={() => {
                  this.openHelpModal();
                }}
              >
                {this.props.t("regNameScr-needHelp")}
              </button>
            </div>

            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>
          <div className="modal-body">
            {getDelegationsRecord.length === 0 && (
              <p className="mt-30 text-center">
                {this.props.t("nopickup_scheduled")}
              </p>
            )}
            {getDelegationsRecord.length > 0 && (
              <ul className="pickupschedule-plan-list">
                {getDelegationsRecord.map((item) => (
                  <li key={item.CalendarID}>
                    <div className="dateHolder">
                      <span>{moment(item.CalendarDate).format("D-MMM")} </span>
                      <span>{moment(item.CalendarDate).format("ddd")}</span>
                    </div>
                    <div className="pickupType">
                      {this.props.t("futureDelegationScr_will_pickup_by")}-{" "}
                      {item.PickupMode}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.getFutureDelegationView();
  }
  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };
}

function mapStateToProps(state) {
  return {
    getDelegationsRecord: state.student.getDelegationsRecord,
    setHelpListData: state.userAccount.setHelpListData,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doGetDelegationToParent: (data) => dispatch(getDelegationToParent(data)),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doGetDelegationToParentClear: () => dispatch(getDelegationToParentClear()),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(FutureDelegation)
);
