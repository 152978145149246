import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { createLoadingSelector } from "../../common/reducers/selectors";
import { Colors } from "../../theme";
import { showModal, hideModal } from "../../common/actions/userAccount";
import RingLoader from "react-spinners/ClipLoader";
import {
  getChildStatus,
  getDismissalInfo,
  isActionAllowed,
  getMarkStyle,
  isTodayAndAnnounced,
  getEarlyDismissalTime,
  isEarlyDismissalAvailableForParent,
  getCurrentThemeColor,
} from "./../../helper/utility";

import {
  getPickupEntries,
  getPickupEntriesClear,
  getKidDefaultDismissal,
  getKidDefaultDismissalClear,
  absenteeCancel,
  absenteeCancelClear,
  delegationCancel,
  delegationCancelClear,
  removeMultipleDelegations,
  removeMultipleDelegationsClear,
  relatedDelegationsDetails,
  getEarlyDismissalsForDateRange,
  getEarlyDismissalsForDateRangeClear,
  earlyDismissalCancel,
  cancelEarlyDismissalClear,
} from "../../common/actions/students";

import { saveHelpMessage2 } from "../../common/actions/userAccount";
import DismissalHistory from "./DismissalHistory";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Collapse } from "bootstrap";
import { Button, Collapse } from "react-bootstrap";
import GLOBAL_CONST from "../../helper/AppConstants";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronDown,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const pickupEntriesLoadingSelector = createLoadingSelector([
  "get_pickup_entries",
  "get_early_dismissal",
]);

const loadingSelector = createLoadingSelector([
  "get_pickup_entries",
  "get_default_dismissal",
  "cancel_absentee",
  "cancel_delegation",
  "cancel_multiple_delegations",
  "cancel_early_dismissal",
  "get_early_dismissal",
]);

class MyKidView extends React.Component {
  constructor(props) {
    super(props);

    const { child, school } = this.props;

    let monthEnd = new Date(school.Dates.LastDayOfSchool);
    const isLastDayOfSchoolPast = moment(monthEnd).isBefore(moment());

    const firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);

    this.state = {
      markedDates: {},

      selectedDate: new Date(),
      currentSelectedMonth: new Date(),
      currentList: [],
      displayMenu: false,
      displayConfirmation: false,
      cancelpickups: {},
      isLastDayOfSchoolPast,
      firstDayOfSchool,
      kidsCalendarVisible: false,
      isActionPerformed: false,
      isSelectMultiplekids: false,
      selectedKids: this.props.selectedKids,
      isShowDetails: false,
      selectedSchool: null,
      openRow: this.props.openRow,
      isSetMarkedDates: false
    };
    this.RowCollapse = React.createRef();
  }

  componentDidMount() {
    this.setState({ selectedKids: this.props.selectedKids });
    if (this.props.openRow === this.props.child.StudentID) {
      this.getDismissalsList();
    }
  }
  getDismissalsList = () => {
    const {
      doGetPickupEntries,
      school,
      child,
      doGetEarlyDismissalsForDateRange,
    } = this.props;
    const { isLastDayOfSchoolPast } = this.state;
    let ChildInfo = child;
    let firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);

    let monthStart = new Date();
    const diffFirstDayofSchool = moment(firstDayOfSchool).diff(
      moment(),
      "months"
    );

    if (diffFirstDayofSchool < -12) {
      endDate = moment().add(12, "M").format("MM-DD-YYYY");
      monthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    } else {
      monthStart = firstDayOfSchool;
    }

    let monthEnd = new Date(school.Dates.LastDayOfSchool);

    var startDate = moment(monthStart).format("MM-DD-YYYY");
    var endDate = moment(monthEnd).format("MM-DD-YYYY");

    if (isLastDayOfSchoolPast) {
      endDate = moment().add(12, "M").format("MM-DD-YYYY");
    }

    doGetPickupEntries({
      studentID: ChildInfo.StudentID,
      startDate,
      endDate,
    });

    var eStartDate = moment(monthStart).format("YYYY-MM-DDTHH:mm:ss");
    var eEndDate = moment(monthEnd).format("YYYY-MM-DDTHH:mm:ss");

    doGetEarlyDismissalsForDateRange({
      StudentID: ChildInfo.StudentID,
      StartDate: eStartDate,
      EndDate: eEndDate,
    });

    const { doGetKidDefaultDismissal } = this.props;
    let selectedKidsIds = [ChildInfo.StudentID];
    doGetKidDefaultDismissal({ selectedKidsIds });
  };

  doRefreshListing = () => {
    this.setState({ markedDates: {}, isSetMarkedDates: false });
    this.getDismissalsList();
  };
  openCancelPickupModal = (cancelpickups) => {
    this.props.doShowModal({
      modalScreenName: "cancelThisPickup",
      modalClassName: "cancelThisPickupModal",
      params: {
        cancelpickups: cancelpickups,
        doCallbackRefresh: this.doRefreshListing,
      },
    });
  };
  openChangeDefualtPickupWindow = () => {
    const { selectedKids } = this.state;
    this.props.doShowModal({
      modalScreenName: "multikidsChangeDefaultPickup",
      modalClassName: "changeDefaultPickupModal",
      params: {
        selectedKids: [...selectedKids],
        school: this.props.school,
        doCallbackRefresh: this.doRefreshListing,
      },
    });
  };

  getDayRecord() {
    const { selectedDate } = this.state;

    const markedDates = { ...this.state.markedDates };

    // console.log("marked Dates--> ",JSON.stringify(markedDates))
    let currentList = [];
    var selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");

    for (const key in markedDates) {
      if (key === selectDateStr) {
        // console.log(
        //   `****************** getDayRecord ${selectDateStr} key ${key} CalendarDate ${markedDates[key].record.CalendarDate}`
        // );
        var selectedMarked = {
          selected: true,
          selectedColor: Colors.blue,
          className: [markedDates[key].className + " " + "selectedDate"],
          record: markedDates[key].record,
        };
        //alert(selectedMarked.record.CalendarDate);
        markedDates[key] = selectedMarked;
        if (
          markedDates[key] &&
          markedDates[key] !== undefined &&
          markedDates[key].record &&
          markedDates[key].record !== undefined
        ) {
          this.getRelatedDelegationsDetails(markedDates[key].record);
        }
      } else {
        if (markedDates[key] && markedDates[key].record) {
          markedDates[key] = getMarkStyle(markedDates[key].record);
        }
      }
    }

    if (markedDates[selectDateStr]) {
      currentList.push(markedDates[selectDateStr]);
    }

    this.setState({ markedDates, currentList });
  }

  getRelatedDelegationsDetails = (item) => {
    const { doRelatedDelegationsDetails } = this.props;
    let calendarId = item.CalendarID;

    if (calendarId != null) {
      doRelatedDelegationsDetails({ calendarId });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      pickupEntries,
      doGetPickupEntriesClear,
      cancelAbsentResponse,
      doAbsenteeCancelClear,
      delegationCancelResponse,
      doCancelDelegationClear,
      doRemoveMultipleDelegationsClear,
      deleteMultipleDelegations,
      error,
      child,
      doGetEarlyDismissalsForDateRangeClear,
      earlyDismissalDateRange,
      cancelEarlyDismissalResponse,
      doCancelEarlyDismissalClear,
    } = this.props;

    const { isActionPerformed } =
      this.state;

    if (!_.isEqual(this.state.selectedKids, this.props.selectedKids)) {
      this.setState({ selectedKids: this.props.selectedKids });
    }

    if (!_.isEqual(this.state.selectedSchool, this.props.selectedSchool)) {
      this.setState({ selectedSchool: this.props.selectedSchool });
    }
    let newMarkedDates = {};

    if (
      this.props.openRow === child.StudentID &&
      pickupEntries !== null &&
      earlyDismissalDateRange !== null
    ) {
      newMarkedDates = {};
      for (var i = 0; i < pickupEntries.CalendarEntries.length; i++) {
        const record = { ...pickupEntries.CalendarEntries[i] };
        var day = moment(record.CalendarDate).format("YYYY-MM-DD");
        let earlyDismissalrecord = [];
        if (earlyDismissalDateRange) {
          //console.log("earlyDismissalrecord records ", earlyDismissalDateRange)
          earlyDismissalrecord = earlyDismissalDateRange.filter(
            (item) => moment(item.Date).format("YYYY-MM-DD") === day
          );
        }
        //console.log(`bulding marked dates day ${day}  record.CalendarDate ${record.CalendarDate} `);
        if (record.PM_Name === GLOBAL_CONST.PICKMODE_NAME.EARLY_DEPARTURE) {
          record.isEarlyDepart = true;
          newMarkedDates[day] = getMarkStyle(record);
        } else if (earlyDismissalrecord.length > 0) {
          const edRecord = {
            ...earlyDismissalrecord[0],
            ...record,
          };

          //console.log('earlyDismissalrecord for ', earlyDismissalrecord[0]);
          edRecord.isEarlyDismissal = true;
          edRecord.IsApproved = earlyDismissalrecord[0].IsApproved;
          if (edRecord.DelegateImageUrl === undefined) {
            edRecord.DelegateImageUrl = null;
          }

          newMarkedDates[day] = getMarkStyle(edRecord);
        } else {
          record.isEarlyDismissal = false;
          newMarkedDates[day] = getMarkStyle(record);
          // console.log("pickup for " + day)
        }
      }

      this.setState(
        {
          markedDates: newMarkedDates,
        },
        () => {
          this.setState({ isSetMarkedDates: true })
          this.getDayRecord();
          doGetPickupEntriesClear();
          doGetEarlyDismissalsForDateRangeClear();
        }
      );
    }

    if (error) {
      doAbsenteeCancelClear();
    } else if (cancelAbsentResponse && isActionPerformed) {
      this.setState({ isActionPerformed: false });
      toast.success(
        cancelAbsentResponse.Message || cancelAbsentResponse.Message,
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        }
      );
      doAbsenteeCancelClear();
      this.doRefreshListing();
    } else if (delegationCancelResponse && isActionPerformed) {
      this.setState({ isActionPerformed: false });
      toast.success(
        delegationCancelResponse.Message || delegationCancelResponse.Message,
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        }
      );
      doCancelDelegationClear();
      this.doRefreshListing();
      this.setState({ displayConfirmation: false });
    } else if (deleteMultipleDelegations && isActionPerformed) {
      this.setState({ isActionPerformed: false });
      toast.success(
        deleteMultipleDelegations.Message || deleteMultipleDelegations.Message,
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        }
      );
      doRemoveMultipleDelegationsClear();
      this.doRefreshListing();
      this.setState({ displayConfirmation: false });
    } else if (cancelEarlyDismissalResponse && isActionPerformed) {
      toast.success(this.props.t('ed_cancelled'), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doCancelEarlyDismissalClear();
      this.doRefreshListing();
    }

  }

  openEarlyDismissalmodal = () => {
    const { selectedKids, selectedSchool, selectedDate } = this.state;
    this.setState({ displayMenu: false });
    let setDateForActon = selectedDate;
    if (moment(selectedDate).isBefore(moment(), "date")) {
      setDateForActon = new Date();
    }
    this.props.doShowModal({
      modalScreenName: "multikdsEarlyDismissal",
      modalClassName: "createModifyPickupModal",
      params: {
        selectDate: setDateForActon,
        selectedKids: [...selectedKids],

        school: this.props.school,

        doCallbackRefresh: this.doRefreshListing,
      },
    });
  };
  doCancelEarlyDismissal = (item) => {
    confirmAlert({
      title: this.props.t("childDetailScr-earlyDismissalCancel"),
      message: this.props.t("childDetailScr-earlyDismissalMessage"),
      buttons: [
        {
          label: this.props.t("Yes"),
          onClick: () => {
            this.setState({ isActionPerformed: true }, () => {
              this.cancelEarlyDismissal(item);
            });
          },
        },
        {
          label: this.props.t("No"),
          className: "cancel-btn",
        },
      ],
      cancelable: false,
    });
  };

  showDismissalAdd = () => {
    const { selectedKids, selectedSchool, markedDates, selectedDate } =
      this.state;
    const { userLoginInfo } = this.props;
    this.setState({ displayMenu: false });
    let setDateForActon = selectedDate;
    if (moment(selectedDate).isBefore(moment())) {
      setDateForActon = new Date();
    }

    this.props.doShowModal({
      modalScreenName: "multikidsSelectWhen",
      modalClassName: "createModifyPickupModal",
      params: {
        selectDate: setDateForActon,
        selectedKids: [...selectedKids],

        school: this.props.school,

        markedDates,
        doCallbackRefresh: this.doRefreshListing,
      },
    });
  };

  onDayPress = (day) => {
    this.setState(
      {
        selectedDate: day,
      },
      this.getDayRecord
    );
  };

  getRedButton = (item, type, btnCount) => {
    const { selectedKids, selectedSchool } = this.state;
    let buttonText = "";
    if (type === 1) {
      buttonText = `${this.props.t("childDetailScr-mark")}`;
    } else if (type === 2) {
      buttonText = `${this.props.t("childDetailScr-absentCancel")}`;
    }
    return (
      <button
        type="button"
        className="btn btn-style2 btn-danger"
        key={"markAbsentBtn-" + item.CalendarDate}
        style={{ width: btnCount === 1 ? "100%" : "48%" }}
        onClick={() => {
          if (type === 2) this.doCancelAbsent(item);
          else if (type === 1) {
            const { selectedDate } = this.state;
            const { child, school } = this.props;
            let setDateForActon = selectedDate;
            if (moment(selectedDate).isBefore(moment()))
              setDateForActon = new Date();

            this.props.doShowModal({
              modalScreenName: "multikidsCreateMarkAbsent",
              modalClassName: "multikidsCreateMarkAbsent",
              params: {
                selectedDate: setDateForActon,
                selectedKids: [...selectedKids],

                school: this.props.school,

                doCallbackRefresh: this.doRefreshListing,
              },
            });
          }
        }}
      >
        {buttonText}
      </button>
    );
  };

  getButton = (item) => {
    if (this.state.isLastDayOfSchoolPast) {
      return [];
    }
    const ChildInfo = this.props.child;
    const school = this.props.school;
    const { defaultKidsDismissalResponse } = this.props;
    // console.log("ChildInfo ", JSON.stringify(ChildInfo));
    // console.log("getButton item ", JSON.stringify(item));
    const { selectedDate } = this.state;
    const isAbseetAllowed = isActionAllowed(
      ChildInfo.DismissalTime,
      selectedDate
    );

    const isPickupModeFuture = isActionAllowed(
      ChildInfo.PickupModeCutOffTime,
      selectedDate
    );

    const isDelegateFuture = isActionAllowed(
      ChildInfo.DelegationCutOffTime,
      selectedDate
    );

    const buttons = [];

    const isReunification =
      school.ReunificationEnabled &&
      moment(selectedDate).isSame(moment(), "date");
    if (isReunification) {
      buttons.push(
        <p className="red-text reunification-text">
          {this.props.t("emergency_reunification_pickup_msg")}
        </p>
      );
      return buttons;
    }
    if (!isAbseetAllowed && !isPickupModeFuture && !isDelegateFuture) {
      return [];
    }
    if (isTodayAndAnnounced(new Date(selectedDate), ChildInfo)) {
      return [];
    }

    const isPickupButton =
      isPickupModeFuture === true && isDelegateFuture === true;
    const isEarlyButton = isEarlyDismissalAvailableForParent(school);
    const isAbsentButton =
      ChildInfo.Permissions.MarkAbsent === true && isAbseetAllowed === true;
    const isAbsentCancel =
      item.MiscellaneousID !== null && item.MiscellaneousID !== undefined;
    let btnCount = 0;
    // console.log(
    //   `isDefault ${isDefault} isPickupButton ${isPickupButton} isEarlyButton ${isEarlyButton} isAbsentButton ${isAbsentButton} isAbsentCancel ${isAbsentCancel}`
    // );
    // console.log(`item.MiscellaneousID = ${item.MiscellaneousID}`);
    if (!isAbsentCancel) {
      if (isPickupButton) {
        btnCount++;
      }
      if (isEarlyButton && !item.isEarlyDismissal) {
        btnCount++;
      }
    }
    if (isAbsentButton) {
      btnCount++;
    }

    if (item.isEarlyDismissal) {
      if (isPickupButton) {
        buttons.push(this.getChangeDismissalbutton(item, false, btnCount));
      }

      if (isAbsentButton) {
        buttons.push(this.getRedButton(item, 1, btnCount));
      }

      return buttons;
    }
    if (item.MiscellaneousID !== null) {
      if (isEarlyButton && !isAbsentCancel) {
        buttons.push(this.getEarlyDismissalbutton(item, btnCount));
      }

      //EF: 11/04 There is an absent record but can't cancel because of permission
      if (isAbsentButton) {
        buttons.push(this.getRedButton(item, 2, btnCount));
      }

      return buttons;
    }

    let isDefault = false;
    if (
      defaultKidsDismissalResponse &&
      defaultKidsDismissalResponse.length > 0 &&
      parseInt(defaultKidsDismissalResponse[0].PickupModeID) ===
      parseInt(item.PM_ID) &&
      item.Guardian_First === null
    ) {
      isDefault = true;
    }

    if (isPickupButton) {
      buttons.push(this.getChangeDismissalbutton(item, isDefault, btnCount));
    }

    if (isEarlyButton) {
      buttons.push(this.getEarlyDismissalbutton(item, btnCount));
    }

    if (isAbsentButton) {
      buttons.push(this.getRedButton(item, 1, btnCount));
    }

    return buttons;
  };

  getChangeDismissalbutton = (item, isDefault, btnCount) => {
    const { child } = this.props;
    let buttonText = "";
    if (!isDefault) {
      buttonText = `${this.props.t("Cancel_Pickup_Label")}`;
    } else {
      buttonText = `${this.props.t("Change_Pickup_Button")}`;
    }

    return (
      <button
        type="button"
        className="btn btn-style3"
        style={{
          width: btnCount === 1 || btnCount === 3 ? "100%" : "48%",
          backgroundColor: getCurrentThemeColor(this.props.selectedTheme)
            .buttonColor,
        }}
        key={"BtnD-" + item.CalendarDate}
        onClick={() => {
          if (item.isEarlyDismissal) {
            this.doCancelEarlyDismissal(item);
          } else if (isDefault) this.showDismissalAdd(item);
          else {
            this.setState({ isActionPerformed: true }, () => {
              this.openCancelPickupModal(item);
            });
          }
        }}
      >
        {buttonText}
      </button>
    );
  };

  getEarlyDismissalbutton = (item) => {
    let buttonText = "";
    buttonText = `${this.props.t("Early_Dismissal_Text")}`;

    return (
      <button
        type="button"
        key={"BtnED-" + item.CalendarDate}
        className="btn btn-style2"
        style={{ width: "48%" }}
        onClick={() => {
          this.openEarlyDismissalmodal();
        }}
      >
        {buttonText}
      </button>
    );
  };

  doCancelAbsent = (item) => {
    confirmAlert({
      title: this.props.t("childDetailScr-absentCancel"),
      message: this.props.t("childDetailScr-absentMessage"),
      buttons: [
        {
          label: this.props.t("Yes"),
          onClick: () => {
            this.setState({ isActionPerformed: true }, () => {
              this.cancelAbsentee(item.CalendarDate);
              this.props.doHideModal();
            });
          },
        },
        {
          label: this.props.t("No"),
          className: "cancel-btn",
        },
      ],
    });
  };
  cancelAbsentee = (canceldate) => {
    const { doAbsenteeCancel, child } = this.props;
    let ChildInfo = child;

    let absenteeDate = moment(canceldate).format("MM/DD/YYYY");
    let studentId = ChildInfo.StudentID;
    doAbsenteeCancel({ studentId, absenteeDate });
  };

  cancelEarlyDismissal = (item) => {
    const { doEarlyDismissalCancel } = this.props;

    doEarlyDismissalCancel({ id: item.Id });
  };

  renderItem = () => {
    const { selectedDate, markedDates } = this.state;
    const selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");
    const school = this.props.school;
    const dayRecord = markedDates[selectDateStr];
    if (dayRecord) {
      const item = dayRecord.record;
      const buttons = this.getButton(item);

      return (
        <div className="todays-pkp-schedule">
          {item.isEarlyDepart === true
            ? this.renderEarlyDepart(item)
            : item.isEarlyDismissal
              ? this.renderEarlyDismissalItem(item)
              : item.Guardian_First !== "" && item.Guardian_First !== null
                ? this.renderContactItem(item)
                : this.renderPickupItem(item)}

          {buttons && buttons.length > 0 && (
            <div className="schedule-btns">
              <div className="action-btn">{buttons}</div>
            </div>
          )}
        </div>
      );
    } else {
      const firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);
      const LastDayOfSchool = new Date(school.Dates.LastDayOfSchool);
      if (
        moment(selectedDate).isBefore(firstDayOfSchool) ||
        moment(selectedDate).isAfter(LastDayOfSchool)
      ) {
        return (
          <div className="todays-pkp-schedule">
            {this.renderSchoolNotInSession(selectedDate)}
          </div>
        );
      } else {
        <div></div>;
      }
    }
  };

  renderSchoolNotInSession = (selectedDate) => {
    const { markedDates } = this.state;
    const selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");
    const dayRecord = markedDates[selectDateStr];
    if (!dayRecord) return null;
    const dayValue = dayRecord.record;

    return (
      <div className="schedule-desc">
        <div
          className="schedule-header"
          style={{
            backgroundColor: getCurrentThemeColor(this.props.selectedTheme)
              .themebackground_graysubtle,
            color: getCurrentThemeColor(this.props.selectedTheme).title2,
          }}
        >
          <span className="schedule-date">{`${this.props.t(
            "Schedule_For"
          )} ${moment(selectedDate).format(" MMM Do YYYY")}`}</span>
        </div>
        <div className="schedule-body">
          <p>{this.props.t("School_not_Session_Text")}</p>
        </div>
      </div>
    );
  };

  renderEarlyDismissalItem = () => {
    const { selectedDate, markedDates, isLastDayOfSchoolPast } = this.state;
    const selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");
    const dayRecord = markedDates[selectDateStr];
    if (!dayRecord) return null;
    const item = dayRecord.record;
    return this.renderEarlyItem(item);
  };
  renderEarlyItem = (item) => {
    const { selectedDate, markedDates } = this.state;
    const selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");
    const dismissalInfo = this.getEarlyDismissalInfo(item);
    const isGroup = false;
    const dayRecord = markedDates[selectDateStr];
    if (!dayRecord) return null;
    const dayValue = dayRecord.record;
    let markStyle = getMarkStyle(dayValue, true);
    const buttons = this.getButton(item);
    const school = this.props.school;
    return (
      <div className="schedule-desc">
        <div
          className="schedule-header"
          style={{
            background: markStyle.selectedColor,
            color: markStyle.foreGroundColor,
          }}
        >
          <span className="schedule-date">
            {moment(selectedDate).format(" MMM D, ").toLocaleUpperCase() +
              getEarlyDismissalTime(dayValue) +
              " (" +
              this.props.t("EARLY_FOR") +
              ")"}
          </span>
          {!dayValue.IsApproved && this.renderPending(dayValue)}
        </div>
        <div className="schedule-body">
          <div className="contact-item">
            <div className="contact-figure">
              {school.ImageBasedDelegationFeatureFlag &&
                dayValue.DelegateImageUrl &&
                dayValue.DelegateImageUrl !== undefined ? (
                <span className="contact-img border-outline">
                  <img
                    src={dayValue.DelegateImageUrl}
                    alt={dismissalInfo.dismissalText}
                    title={dismissalInfo.dismissalText}
                    width={54}
                    height={54}
                  />
                </span>
              ) : (
                <span className="noImage border-outline">
                  <i className="fa-solid fa-user"></i>
                </span>
              )}
            </div>
            <h4 className="schedule-title">
              {dayValue.DelegateName ? (
                <div className="contact-info">
                  <span className="contact-name">
                    {dismissalInfo.dismissalText}
                  </span>
                  <span className="contact-no">{dayValue.DelegateContact}</span>
                </div>
              ) : (
                <span className="schedule-subtitle">
                  {` ${dismissalInfo.dismissalText} ${getEarlyDismissalTime(
                    dayValue
                  )}`}
                </span>
              )}
            </h4>
          </div>
        </div>
      </div>
    );
  };

  renderEarlyDepart = (item) => {
    const { selectedTheme } = this.props;
    const { selectedDate, markedDates } = this.state;
    const selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");
    const dayRecord = markedDates[selectDateStr];
    if (!dayRecord) {
      return null;
    }
    const dayValue = dayRecord.record;
    let markStyle = getMarkStyle(dayValue, true);
    const school = this.props.school;
    const ChildInfo = this.props.child;
    const dismissalInfo = getDismissalInfo(item, ChildInfo);
    return (
      <div className="schedule-desc">
        <div
          className="schedule-header"
          style={{
            backgroundColor: markStyle.selectedColor,
            color: markStyle.foreGroundColor,
          }}
        >
          <span className="schedule-date">
            {moment(dayValue.CalendarDate)
              .format(" MMM D, YYYY")
              .toLocaleUpperCase() +
              " (" +
              this.props.t("Picked_Up_Early") +
              ")"}
          </span>
          {!dayValue.IsApproved && this.renderPending(dayValue)}
        </div>
        <div className="schedule-body">
          <div className="contact-item">
            <div className="contact-figure">
              {school.ImageBasedDelegationFeatureFlag &&
                dayValue.DelegateImageUrl &&
                dayValue.DelegateImageUrl !== undefined ? (
                <span className="contact-img border-outline">
                  <img
                    src={dayValue.DelegateImageUrl}
                    alt={dismissalInfo.dismissalText}
                    title={dismissalInfo.dismissalText}
                    width={54}
                    height={54}
                  />
                </span>
              ) : (
                <span className="noImage border-outline">
                  <i className="fa-solid fa-user"></i>
                </span>
              )}
            </div>
            <h4 className="schedule-title">
              {dayValue.DelegateName ? (
                <div className="contact-info">
                  <span className="contact-name">
                    {`${dismissalInfo.dismissalText}`}
                  </span>
                  <span className="contact-no">{dayValue.DelegateContact}</span>
                </div>
              ) : (
                <span className="schedule-subtitle">
                  {` ${dismissalInfo.dismissalText}`}
                </span>
              )}
            </h4>
          </div>
        </div>
      </div>
    );
  };

  renderContactItem = (item) => {
    const { selectedDate, markedDates, isLastDayOfSchoolPast } = this.state;
    const selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");
    const dismissalInfo = getDismissalInfo(item, this.props);
    const isGroup = false;
    const dayRecord = markedDates[selectDateStr];
    if (!dayRecord) return null;
    const dayValue = dayRecord.record;
    const buttons = this.getButton(item);
    let markStyle = getMarkStyle(dayValue, true);
    const school = this.props.school;
    return (
      <div className="schedule-desc">
        <div
          className="schedule-header"
          style={{
            background: markStyle.selectedColor,
            color: markStyle.foreGroundColor,
          }}
        >
          <span className="schedule-date">
            {this.props.t("Schedule_For")}
            {moment(selectedDate).format(" MMM D, YYYY").toLocaleUpperCase()}
          </span>
          {!dayValue.IsApproved && this.renderPending(dayValue)}
        </div>
        <div className="schedule-body">
          <div className="schedulefor-content">
            <h4 className="schedule-title">{dayValue.PM_Name}</h4>
            <div className="contact-item">
              <div className="contact-figure">
                {school.ImageBasedDelegationFeatureFlag &&
                  dayValue.DelegateImageUrl &&
                  dayValue.DelegateImageUrl !== undefined ? (
                  <span className="contact-img border-outline">
                    <img
                      src={dayValue.DelegateImageUrl}
                      alt={dismissalInfo.dismissalText}
                      title={dismissalInfo.dismissalText}
                      width={54}
                      height={54}
                    />
                  </span>
                ) : (
                  <span className="noImage border-outline">
                    <i className="fa-solid fa-user"></i>
                  </span>
                )}
              </div>
              <div className="contact-info">
                <span className="contact-name">
                  {`${dayValue.Guardian_First} ${dayValue.Guardian_Last}`}
                </span>
                <span className="contact-no">{dayValue.Guardian_Phone}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderPickupItem = (item) => {
    const { selectedDate, markedDates, isLastDayOfSchoolPast } = this.state;
    const selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");
    const dismissalInfo = getDismissalInfo(item, this.props);
    const isGroup = false;
    const dayRecord = markedDates[selectDateStr];
    if (!dayRecord) return null;
    const dayValue = dayRecord.record;
    //let markStyle = getMarkStyle(dayValue, true);
    let markStyle = getMarkStyle(dayValue, true, this.props.selectedTheme);
    const buttons = this.getButton(item);
    return (
      <div className="schedule-desc">
        <div
          className="schedule-header"
          style={{
            background: markStyle.selectedColor,
            color: markStyle.foreGroundColor,
          }}
        >
          <span className="schedule-date">{`${this.props.t(
            "Schedule_For"
          )} ${moment(item.CalendarDate).format(" MMM Do YYYY")}`}</span>
          {!dayValue.IsApproved && this.renderPending(dayValue)}
        </div>
        <div className="schedule-body">
          {!isLastDayOfSchoolPast ? (
            <h4 className="schedule-title">
              {dismissalInfo.dismissalText}{" "}
              {` ${dismissalInfo.dismissalTextSubText}`}
            </h4>
          ) : (
            <p>{this.props.t("School_not_Session_Text")}</p>
          )}
        </div>
      </div>
    );
  };

  renderPending = (record) => {
    const school = this.props.school;
    let str = `${this.props.t("Pending_Approval_Text3")} ${school.Name} `;
    return (
      <span
        className="marquee-wrap"
        style={{ color: record.isEarlyDismissal ? "#000" : "#fff" }}
      >
        <marquee direction="left">{str}</marquee>
      </span>
    );
  };

  getEarlyDismissalInfo = (item) => {
    const currentDate = new Date();

    let dismissalText = "";
    const dismissalTextSubText = "";

    const isFuture = moment(item.Date).isAfter(moment(currentDate));

    if (item.DelegateName) {
      if (isFuture) {
        dismissalText = `${item.DelegateName}`;
      } else {
        dismissalText = `${item.DelegateName}`;
      }
    } else if (isFuture) {
      dismissalText = `${this.props.t("Pickup_Early_By_Text")}`;
    } else {
      dismissalText = `${this.props.t("Pickup_Early_Was_By_Text")}`;
    }

    return {
      dismissalText,
      dismissalTextSubText:
        dismissalTextSubText === null ? "" : `-${dismissalTextSubText}`,
    };
  };

  setSelectedKids = (child) => {
    const { isSelectMultiplekids } = this.props;

    if (!isSelectMultiplekids) {
      let { selectedKids } = this.state;
      selectedKids = [];
      selectedKids.push(child);

      this.props.callback(child.StudentID);
      this.setState(
        {
          selectedKids,
          kidsCalendarVisible: !this.state.kidsCalendarVisible,
        },
        () => {
          if (this.state.kidsCalendarVisible) {
            this.getDismissalsList();
          }
          this.props.doSetSelectedKids(this.state.selectedKids);
        }
      );
    } else {
      let selectedKids = [...this.state.selectedKids];

      const index = selectedKids.findIndex(
        (item) => item.StudentID === child.StudentID
      );
      if (index >= 0) {
        selectedKids.splice(index, 1);
      } else {
        selectedKids.push(child);
      }

      this.setState({ selectedKids }, () => {
        this.props.doSetSelectedKids(this.state.selectedKids);
      });
    }
  };

  isChecked = (child) => {
    const { selectedKids } = this.state;
    const element =
      selectedKids &&
      selectedKids.find((item) => item.StudentID === child.StudentID);
    return element !== null && element !== undefined;
  };

  showHelpMessage2 = () => {
    return (
      <div
        className="notification-popup"
        onClick={() => {
          this.props.doSaveHelpMessage2();
        }}
      >
        <label className="notificationTxt">
          {" "}
          {this.props.t("notification")}
        </label>
        <span className="notification-helptxt">{this.props.t("helpMsg2")}</span>
      </div>
    );
  };

  render() {
    const {
      child,
      school,
      isFetching,
      defaultDismissalResponse,
      isSelectMultiplekids,
      selectedTheme,
    } = this.props;
    const { markedDates, kidsCalendarVisible } = this.state;
    const childDetailView = "childDetails-" + child.StudentID;

    let kidDefaultDismissalResponse = {};
    if (defaultDismissalResponse && defaultDismissalResponse[child.StudentID]) {
      kidDefaultDismissalResponse = defaultDismissalResponse[child.StudentID];
    }
    const isSelected = isSelectMultiplekids && this.isChecked(child);

    const defaultPickupMode = this.props.defaultKidsDismissalResponse;

    let { selectedSchool } = this.state;

    return (
      <tr
        className={`mng-child-row row-clickable ${isSelected ? "selectedMngChildRow" : ""
          }`}
        key={`kid-${child.StudentID}`}
      >
        <td colSpan="4" className="nopadding rowColSpan">
          <table width="100%">
            <tbody>
              <tr
                onClick={() => {
                  if (isSelectMultiplekids) {
                    if (
                      this.state.selectedKids.length === 0 ||
                      selectedSchool === null
                    ) {
                      const firstDayOfSchool = new Date(
                        school.Dates.FirstDayOfSchool
                      );
                      const LastDayOfSchool = new Date(
                        school.Dates.LastDayOfSchool
                      );
                      if (
                        moment().isBefore(firstDayOfSchool) ||
                        moment().isAfter(LastDayOfSchool)
                      ) {
                        toast.error(this.props.t("School_not_Session_Text"), {
                          autoClose: 2000,
                          transition: Slide,
                          hideProgressBar: true,
                          icon: false,
                        });
                        return false;
                      } else {
                        this.setState({ selectedSchool: school }, () => {
                          this.props.doSetSelectSchool(
                            this.state.selectedSchool
                          );
                          this.setSelectedKids(child);
                        });
                      }
                    } else if (
                      selectedSchool &&
                      selectedSchool.SchoolID !== school.SchoolID
                    ) {
                      toast.error(this.props.t("muiltkidsSelectSchoolError"), {
                        autoClose: 2000,
                        transition: Slide,
                        hideProgressBar: true,
                        icon: false,
                      });
                    } else {
                      this.setSelectedKids(child);
                    }
                  } else {
                    this.setSelectedKids(child);
                  }
                }}
                className={`expandcollapse-row ${isSelected ? "selectedKidRow" : "uncheckedKidRow"
                  }`}
                //href={"#" + childDetailView}
                aria-controls={childDetailView}
                aria-expanded={this.props.openRow === child.StudentID}
                data-open={this.props.openRow === child.StudentID}
              >
                <td width="30%" valign="center">
                  <div className="td-data">
                    {isSelectMultiplekids && (
                      <span className="selectkid-btn">
                        {isSelected ? (
                          <i className="fas fa-check-circle lightBlueTxt"></i>
                        ) : (
                          <i className="far fa-circle"></i>
                        )}
                      </span>
                    )}
                    <div className="childInfo">
                      <span className="child-name">
                        {" "}
                        {`${child.Firstname} ${child.Lastname}`}
                      </span>
                      <br />
                      <span className="font-sz14">{`(${child.SchoolStudentId}) - ${child.GradeName}`}</span>
                    </div>
                  </div>
                </td>
                <td width="20%" valign="center" className="hidden-xs">
                  <div className="td-data">{child.PickupModeName}</div>
                </td>
                <td width="20%" valign="center" className="last-td">
                  <div className="td-data">
                    <span className="dismissed-status-btn">{`${child.StatusID != 0
                      ? getChildStatus(child)
                      : this.props.t("Not_Dismissed")
                      }`}</span>
                    <span className="childCardRight hidden-lg">
                      {this.props.openRow === child.StudentID ? (
                        <FontAwesomeIcon
                          icon={faCircleChevronDown}
                          color={
                            getCurrentThemeColor(this.props.selectedTheme)
                              .mediumBlack_BlueishGray
                          }
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircleChevronRight}
                          color={
                            getCurrentThemeColor(this.props.selectedTheme)
                              .mediumBlack_BlueishGray
                          }
                        />
                      )}
                    </span>
                  </div>
                </td>
                <td width="30%" valign="center" className="hidden-xs">
                  <div className="td-data">
                    <span className="text pd-right">{school.Name}</span>
                  </div>
                  <span className="childCardRight">
                    {this.props.openRow === child.StudentID ? (
                      <FontAwesomeIcon
                        icon={faCircleChevronDown}
                        color={
                          getCurrentThemeColor(this.props.selectedTheme)
                            .mediumBlack_BlueishGray
                        }
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCircleChevronRight}
                        color={
                          getCurrentThemeColor(this.props.selectedTheme)
                            .mediumBlack_BlueishGray
                        }
                      />
                    )}
                  </span>
                </td>
              </tr>

              <tr className="hide-table-padding">
                <td colSpan="4">
                  <Collapse
                    key={`collapse-${child.StudentID}`}
                    in={this.props.openRow === child.StudentID}>
                    <div
                      ref={this.RowCollapse}
                      id={childDetailView}>
                      <div className="your-child-data">
                        <div className="child-data-wrapper">
                          <div className="row">
                            <div className="child-data-col col-xl-4 col-lg-6 col-md-6 col-sm-12">
                              <div className="calendar-col">
                                {isFetching || this.state.isSetMarkedDates === false
                                  || (this.state.markedDates &&
                                    this.state.markedDates === null ||
                                    this.state.markedDates === undefined) ? (
                                  <div className="loaderHolder">
                                    <RingLoader
                                      color={
                                        getCurrentThemeColor(
                                          this.props.selectedTheme
                                        ).loader
                                      }
                                      loading={
                                        isFetching || this.state.isSetMarkedDates === false
                                        || (this.state.markedDates &&
                                          this.state.markedDates === null ||
                                          this.state.markedDates === undefined)
                                      }
                                      size={60}
                                    />
                                  </div>
                                ) : (
                                  <Calendar
                                    value={this.state.selectedDate}
                                    key={`calendar-${child.StudentID}`}
                                    className={["customCalendar"]}
                                    calendarType={"US"}
                                    prev2Label=""
                                    next2Label=""
                                    showFixedNumberOfWeeks={false}
                                    showNeighboringMonth={false}
                                    onClickDay={this.onDayPress}
                                    tileClassName={({ date, view }) => {
                                      if (markedDates) {
                                        const item =
                                          markedDates[
                                          moment(date).format("YYYY-MM-DD")
                                          ];
                                        if (item) {
                                          return [item.className];
                                        }
                                      }
                                    }}
                                  ></Calendar>
                                )}
                                {this.renderItem()}
                              </div>
                            </div>
                            <div className="child-data-col col-xl-4 col-lg-6 col-md-6 col-sm-12">
                              <DismissalHistory
                                child={child}
                                isVisible={kidsCalendarVisible}
                              />
                            </div>

                            <div className="child-data-col col-xl-4 col-lg-6 col-md-12 col-sm-12">
                              <div className="additional-info-col">
                                <h3 className="child-data-title">
                                  {this.props.t(
                                    "otherChildrenScr-additionalInfo"
                                  )}
                                </h3>
                                <ul className="list-style1 additional-info-list">
                                  <li>
                                    <div className="change-pickup">
                                      <h4 className="pickup-mode-type">
                                        {defaultPickupMode &&
                                          defaultPickupMode.length > 0 && (
                                            <span>
                                              {
                                                defaultPickupMode[0]
                                                  .PickupModeName
                                              }{" "}
                                              {defaultPickupMode[0]
                                                .PickupModeSubName != null
                                                ? defaultPickupMode[0]
                                                  .PickupModeSubName
                                                : null}
                                            </span>
                                          )}
                                      </h4>
                                      <span>
                                        {this.props.t("Default_PickupLabel")}
                                      </span>
                                    </div>
                                    <div className="edit-action-btn">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          if (
                                            !school.AllowDefaultPickupModeChange
                                          ) {
                                            alert(
                                              this.props.t(
                                                "default_pickup_disable"
                                              )
                                            );
                                            return;
                                          }
                                          this.openChangeDefualtPickupWindow();
                                        }}
                                        className="btn btn-style2 btn-sm"
                                      >
                                        {this.props.t("editChildScr_Label")}
                                      </button>
                                    </div>
                                  </li>
                                </ul>

                                <div className="more-actions">
                                  <h3 className="child-data-title">
                                    {this.props.t("More_Actions")}
                                  </h3>
                                  <div className="actions-btn">
                                    <button
                                      type="button"
                                      className="btn btn-style3"
                                      onClick={() => {
                                        this.showDismissalAdd();
                                      }}
                                      style={{
                                        backgroundColor:
                                          getCurrentThemeColor(selectedTheme)
                                            .buttonColor,
                                        color:
                                          getCurrentThemeColor(selectedTheme)
                                            .textcolor,
                                      }}
                                    >
                                      {this.props.t("Create_Pickup")}
                                    </button>
                                    {child.Permissions.MarkAbsent === true && (
                                      <button
                                        type="button"
                                        className="btn btn-style3 btn-red"
                                        onClick={() => {
                                          const {
                                            selectedDate,
                                            selectedKids,
                                            selectedSchool,
                                          } = this.state;
                                          const { child, school } = this.props;
                                          let setDateForActon = selectedDate;
                                          if (
                                            moment(selectedDate).isBefore(
                                              moment()
                                            )
                                          )
                                            setDateForActon = new Date();

                                          this.props.doShowModal({
                                            modalScreenName:
                                              "multikidsCreateMarkAbsent",
                                            modalClassName:
                                              "multikidsCreateMarkAbsent",
                                            params: {
                                              selectedDate: setDateForActon,
                                              selectedKids: [...selectedKids],

                                              school: this.props.school,

                                              doCallbackRefresh:
                                                this.doRefreshListing,
                                            },
                                          });
                                        }}
                                      >
                                        {this.props.t("childDetailScr-mark")}
                                      </button>
                                    )}
                                    {isEarlyDismissalAvailableForParent(
                                      school
                                    ) && (
                                        <button
                                          type="button"
                                          className="btn btn-style3"
                                          onClick={() => {
                                            this.openEarlyDismissalmodal();
                                          }}
                                          style={{
                                            backgroundColor:
                                              getCurrentThemeColor(selectedTheme)
                                                .buttonColor,
                                            color:
                                              getCurrentThemeColor(selectedTheme)
                                                .textcolor,
                                          }}
                                        >
                                          {this.props.t("Early_Dismissal_Text")}
                                        </button>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  return {
    pickupEntries: state.student.pickupEntries,
    defaultDismissalResponse: state.student.defaultDismissalResponse,
    isFetching: loadingSelector(state),
    cancelAbsentResponse: state.student.cancelAbsentResponse,
    error: state.student.error,
    delegationCancelResponse: state.student.delegationCancelResponse,
    relatedDelegations: state.student.relatedDelegations,
    deleteMultipleDelegations: state.student.deleteMultipleDelegations,

    earlyDismissalDateRange: state.student.earlyDismissalDateRange,
    cancelEarlyDismissalResponse: state.student.cancelEarlyDismissalResponse,

    defaultKidsDismissalResponse: state.student.defaultKidsDismissalResponse,
    userLoginInfo: state.userAccount.userLoginInfo,
    pickupEntriesFetching: pickupEntriesLoadingSelector(state),
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doGetPickupEntries: (data) => dispatch(getPickupEntries(data)),
    doGetPickupEntriesClear: (data) => dispatch(getPickupEntriesClear(data)),
    doGetKidDefaultDismissal: (data) => dispatch(getKidDefaultDismissal(data)),
    doGetKidDefaultDismissalClear: (params) =>
      dispatch(getKidDefaultDismissalClear(params)),
    doAbsenteeCancel: (data) => dispatch(absenteeCancel(data)),
    doAbsenteeCancelClear: () => dispatch(absenteeCancelClear()),
    doRelatedDelegationsDetails: (data) =>
      dispatch(relatedDelegationsDetails(data)),
    doRemoveMultipleDelegations: (data) =>
      dispatch(removeMultipleDelegations(data)),
    doRemoveMultipleDelegationsClear: () =>
      dispatch(removeMultipleDelegationsClear()),
    docancelDelegation: (data) => dispatch(delegationCancel(data)),
    doCancelDelegationClear: () => dispatch(delegationCancelClear()),

    doGetEarlyDismissalsForDateRange: (data) =>
      dispatch(getEarlyDismissalsForDateRange(data)),
    doGetEarlyDismissalsForDateRangeClear: (data) =>
      dispatch(getEarlyDismissalsForDateRangeClear(data)),
    doEarlyDismissalCancel: (data) => dispatch(earlyDismissalCancel(data)),
    doCancelEarlyDismissalClear: (data) =>
      dispatch(cancelEarlyDismissalClear(data)),

    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doSaveHelpMessage2: () => dispatch(saveHelpMessage2()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MyKidView)
);
