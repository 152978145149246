import React from "react";
import { withRouter } from "../CommonComponents/withRouter";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  distance,
  getCurrentThemeColor,
  isAnnounceAllowed,
  isKidAnnounceable,
  showLocationError,
} from "../../helper/utility";
import GLOBAL_CONST from "../../helper/AppConstants";

import { Slide, toast, ToastContainer } from "react-toastify";
import ChildCard from "./ChildCard";
import { positionInLineGet } from "../../common/actions/students";
import {
  showModal,
  hideModal,
  logoutUser,
  setHelpList,
} from "../../common/actions/userAccount";
import { Colors } from "../../theme";
class SchoolCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: null,
      longitude: null,
      error: null,
      IsLocationOnCampus: false,
      permissionResult: "denied",
      isFirstGPSInProgress: true,
    };
  }

  componentDidMount() {
    const { school, kidsAnnouncedToday } = this.props;

    this.enableGeo();
    const schoolkidsAnnouncedToday = kidsAnnouncedToday.filter(
      (item) => item.SchoolID == school.schoolId
    );

    if (schoolkidsAnnouncedToday.length > 0) {
      this.getCarLineQueue();
      this.myInterval = setInterval(() => {
        this.getCarLineQueue();
      }, 60 * 1000);
    } else {
      if (this.myInterval) {
        clearInterval(this.myInterval);
        this.myInterval = null;
      }
    }
  }

  componentWillUnmount() {
    if (this.myInterval) {
      clearInterval(this.myInterval);
      this.myInterval = null;
    }
    if (this.watchId) navigator.geolocation.clearWatch(this.watchId);
  }

  savePermission = (result) => {
    this.setState({
      permissionResult: result.state,
    });
  };

  enableGeo = () => {
    const { userLoginInfo } = this.props;
    const mythis = this;
    if (userLoginInfo) {
      const { AnnounceInRangeRequired } = userLoginInfo;
      if (AnnounceInRangeRequired) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.setState({ isFirstGPSInProgress: false });
            mythis.processGeoLocation(position);
            mythis.watchLocation();
          },
          (error) => {
            console.log(error);
          },
          {
            enableHighAccuracy: false,
            maximumAge: 0,
          }
        );
      }
    }
  };

  watchLocation = () => {
    const mythis = this;
    this.watchId = navigator.geolocation.watchPosition(
      (position) => {
        mythis.processGeoLocation(position);
      },
      (error) => {
        showLocationError(this.props.t("Location_Error_Text"));
      },
      {
        enableHighAccuracy: true,
        timeout: 90000,
        maximumAge: 1000,
        distanceFilter: 100,
      }
    );
  };

  processGeoLocation = (position) => {
    if (!position || !position.coords) return;
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });

    const { school } = this.props;
    const schoolLat = school.schoolLat;
    const schoolLong = school.schoolLong;
    const AnnounceDistance = school.AnnounceDistance;
    const MyLat = this.state.latitude;
    const MyLong = this.state.longitude;
    const Unit = "K";
    const dist = distance(MyLat, MyLong, schoolLat, schoolLong, Unit);

    if (dist <= AnnounceDistance) {
      this.setState({ IsLocationOnCampus: true });
    } else {
      this.setState({ IsLocationOnCampus: false });
    }
  };

  getCarLineQueue = () => {
    const { school, doPositionInLineGet, kidsAnnouncedToday } = this.props;

    const schoolkidsAnnouncedToday = kidsAnnouncedToday.filter(
      (item) =>
        item.SchoolID == school.schoolId && item.PickupModeIcon === "Car"
    );

    if (schoolkidsAnnouncedToday.length > 0) {
      const lastIndex = schoolkidsAnnouncedToday.length - 1;
      if (schoolkidsAnnouncedToday[lastIndex].PickupModeIcon === "Car") {
        doPositionInLineGet({
          pickupModeId: schoolkidsAnnouncedToday[lastIndex].PickupModeID,
          studentId: schoolkidsAnnouncedToday[lastIndex].StudentID,
        });
      }
    }
  };

  openSchoolDetails = (schoolStudentDetials) => {
    this.props.doShowModal({
      modalScreenName: "schoolDetails",
      params: { school: schoolStudentDetials },
      modalClassName: "schoolDetails sidebar-modal right",
    });
  };

  canAnnounceAllowed = () => {
    const { IsLocationOnCampus } = this.state;
    const { userLoginInfo } = this.props;
    if (userLoginInfo === null) return false;
    const { school, isAnyKidSelected } = this.props;
    let enableAnnounce = false;
    let isAnnounceForAnyKid = false;
    let { AnnounceInRangeRequired } = userLoginInfo;
    if (!AnnounceInRangeRequired) AnnounceInRangeRequired = false;
    if (school.isLastDayOfSchoolPast) return false;
    for (var i = 0; i < school.data.length; i++) {
      if (
        isAnnounceAllowed(
          school.data[i].DismissalTime,
          school.data[i].PickupModeAllowsAnnounce,
          school.data[i].StatusID,
          school.isLastDayOfSchoolPast
        )
      ) {
        isAnnounceForAnyKid = true;
        break;
      }
    }
    // Check if any other conition:
    //isSchoolNeedAnnounce = true;
    const anyKidCanAnnounce = isAnyKidSelected(school);

    enableAnnounce =
      (IsLocationOnCampus || AnnounceInRangeRequired === false) &&
      isAnnounceForAnyKid &&
      anyKidCanAnnounce;

    return enableAnnounce;
  };

  anyKidCanAnnounced = () => {
    const { school } = this.props;
    for (let i = 0; i < school.data.length; i++) {
      if (school.data[i].PickupModeAllowsAnnounce === true) return true;
    }
    return false;
  };

  isAllAnnounceKidAnnounced = () => {
    const { school } = this.props;
    for (var i = 0; i < school.data.length; i++) {
      if (school.data[i].PickupModeAllowsAnnounce) {
        if (
          school.data[i].StatusID == GLOBAL_CONST.PICKUP_STATUS.ANNOUNCED ||
          school.data[i].StatusID == GLOBAL_CONST.PICKUP_STATUS.DISMISSED ||
          school.data[i].StatusID == GLOBAL_CONST.PICKUP_STATUS.ABSENT
        ) {
          // Kid has been announced
        } else {
          return false;
        }
      }
    }

    return true;
  };

  isAnyKidAnnounceTimeStarted = () => {
    const { school } = this.props;
    for (let i = 0; i < school.data.length; i++) {
      if (
        isKidAnnounceable(
          school.data[i].DismissalTime,
          school.data[i].PickupModeAllowsAnnounce,
          school.isLastDayOfSchoolPast
        )
      ) {
        return true;
      }
    }
    return false;
  };

  AnnounceTapped = () => {
    if (!this.anyKidCanAnnounced()) {
      toast.error(this.props.t("Announce_Kids_Error"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      return;
    }
    if (this.isAllAnnounceKidAnnounced()) {
      toast.error(this.props.t("Announce_Kids_Error"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      return;
    }

    const isAnyKidAnnounceTimeStartedFlag = this.isAnyKidAnnounceTimeStarted();
    const { school, isAnyKidSelected } = this.props;

    if (isAnyKidAnnounceTimeStartedFlag && !isAnyKidSelected(school)) {
      //You are trying to announce but there is no child selected.
      toast.error(this.props.t("Announce_error_1"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      return;
    }
    if (!isAnyKidAnnounceTimeStartedFlag) {
      toast.error(
        this.props.t("Label_Befor_Announce_1") +
          " " +
          school.data[0].DismissalTime +
          this.props.t("Label_Befor_Announce_2"),
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        }
      );
      return;
    }

    const { latitude, longitude, IsLocationOnCampus } = this.state;
    const { userLoginInfo } = this.props;
    let { AnnounceInRangeRequired } = userLoginInfo;
    if (IsLocationOnCampus === false && AnnounceInRangeRequired === true) {
      toast.error(this.props.t("Location_Fetch_Error"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      return;
    }
    this.props.showAnnounce(school.schoolId, latitude, longitude);
  };

  openDirectionMap = () => {
    const { school } = this.props;
    const { latitude, longitude } = this.state;
    window.open(
      "https://www.google.com/maps/dir/?api=1&origin=" +
        latitude +
        "," +
        longitude +
        "&destination=" +
        school.schoolLat +
        "," +
        school.schoolLong
    );
  };

  displayCarLineQueue = (schoolCarLine) => {
    let carLineString = "";
    if (schoolCarLine.LineLength >= 0 && schoolCarLine.Position >= 1) {
      if (schoolCarLine.LineLength == 1) {
        carLineString = this.props.t("CARLINELENGTHWITHPOSITION");
      } else {
        carLineString = this.props.t("CARLINELENGTHWITHPOSITION_PLURAL");
      }
      carLineString = carLineString.replace(
        "{OrderNumber}",
        schoolCarLine.Position
      );
      carLineString = carLineString.replace(
        "{NumberOfCars}",
        schoolCarLine.LineLength
      );
    } else if (schoolCarLine.LineLength >= 0 && schoolCarLine.Position < 1) {
      //Line has length, position is not known
      if (schoolCarLine.LineLength == 1)
        carLineString = this.props.t("CARLINELENGTHNOPOSITION");
      else {
        carLineString = this.props.t("CARLINELENGTHNOPOSITION_PLURAL");
        carLineString = carLineString.replace(
          "{NumberOfCars}",
          schoolCarLine.LineLength
        );
      }
    } else {
      carLineString = "";
    }

    return (
      <div className="space-between">
        <span className="eteatext">{carLineString}</span>
        <button
          type="button"
          className="btn-location sm-icon"
          onClick={() => {
            this.openDirectionMap();
          }}
        >
          {" "}
          <i className="fas fa-location-arrow"></i>
        </button>
      </div>
    );
  };
  render() {
    const {
      school,
      userLoginInfo,
      schoolStudentDetials,
      getCarLinePositionResponse,
    } = this.props;
    const { IsLocationOnCampus, latitude, longitude, isFirstGPSInProgress } =
      this.state;

    let schoolCarLine = getCarLinePositionResponse[school.schoolId];
    let locationText = "";
    if (
      userLoginInfo &&
      userLoginInfo.AnnounceInRangeRequired &&
      latitude == null &&
      longitude == null
    ) {
      if (isFirstGPSInProgress) locationText = "Fetching location";
      else locationText = this.props.t("schoolCardScr-PERMISSION_REQUIRED");
    } else if (IsLocationOnCampus === true) {
      locationText = this.props.t("schoolCardScr-onCampus");
    } else {
      locationText = this.props.t("schoolCardScr-notInCampus");
    }
    return (
      <div className="card-bx citem" key={schoolStudentDetials.schoolId}>
        <div className="citem-title white-bg">
          <h3
            onClick={() => {
              this.openSchoolDetails(schoolStudentDetials);
            }}
          >
            {schoolStudentDetials.title}
          </h3>
          {schoolCarLine && this.displayCarLineQueue(schoolCarLine)}
        </div>

        <div className="citem-contents white-bg">
          {schoolStudentDetials.data.map((childDetials) => {
            const { StudentID, ...other } = childDetials;
            return (
              <ChildCard
                key={"childDetials" + childDetials.StudentID}
                {...other}
                childDetials={childDetials}
                selectedSwitchCallback={this.props.selectedSwitchCallback}
              ></ChildCard>
            );
          })}
        </div>

        {!schoolStudentDetials.isLastDayOfSchoolPast ? (
          <div className="citem-bottom white-bg">
            <div className="citem-row">
              <div
                className="space-between school-location"
                onClick={() => {
                  if (
                    locationText ==
                    this.props.t("schoolCardScr-PERMISSION_REQUIRED")
                  ) {
                    this.enableGeo();
                  }
                }}
              >
                <label className="label-3">
                  {this.props.t("schoolCardScr-location")}
                </label>
                <span
                  className="citem-val green-text"
                  style={{
                    color:
                      userLoginInfo &&
                      userLoginInfo.AnnounceInRangeRequired &&
                      latitude == null &&
                      longitude == null
                        ? getCurrentThemeColor(this.props.selectedTheme).blue
                        : IsLocationOnCampus
                        ? Colors.green
                        : Colors.gray,
                  }}
                >
                  {locationText}
                </span>
              </div>
              {/*classname announced is for green background, no need remove it*/}
              <div className="text-center">
                <button
                  type="button"
                  className={
                    this.canAnnounceAllowed()
                      ? "announce-btn announced"
                      : "announce-btn"
                  }
                  onClick={() => {
                    this.AnnounceTapped();
                  }}
                >
                  {this.props.t("schoolCardScr-announce")}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="citem-bottom white-bg text-center schoolnot-session">
            <span className="citem-title3">
              {this.props.t("School_not_Session_Text")}
            </span>
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    getCarLinePositionResponse: state.student.getCarLinePositionResponse,
    currentLocationPermission: state.userAccount.currentLocationPermission,
    kidsAnnouncedToday: state.student.kidsAnnouncedToday,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doPositionInLineGet: (data) => dispatch(positionInLineGet(data)),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolCard))
);
