// start Registartion
export const START_REGISTARTION_START = "start_registartion_start";
export const START_REGISTARTION_SUCCESS = "start_registartion_success";
export const START_REGISTARTION_FAILED = "start_registartion_failed";
export const START_REGISTARTION_CLEAR = "start_registartion_clear";

// Check Email
export const CHECK_EMAIL_START = "check_email_start";
export const CHECK_EMAIL_SUCCESS = "check_email_success";
export const CHECK_EMAIL_FAILED = "check_email_failed";
export const CHECK_EMAIL_CLEAR = "check_email_clear";

export const SAVE_REGISTRATION_INFO = "save_registration_info";
export const SAVE_REGISTRATION_CLEAR = "save_registration_info_clear";

// Verification Email

export const VERIFIED_EMAIL_START = "verified_email_start";
export const VERIFIED_EMAIL_SUCCESS = "verified_email_success";
export const VERIFIED_EMAIL_FAILED = "verified_email_failed";
export const VERIFIED_EMAIL_CLEAR = "verified_email_clear";

// Add Phone number

export const ADD_PHONE_NUMBER_USER_START = "add_phone_number_user_start";
export const ADD_PHONE_NUMBER_USER_SUCCESS = "add_phone_number_user_success";
export const ADD_PHONE_NUMBER_USER_FAILED = "add_phone_number_user_failed";
export const ADD_PHONE_NUMBER_USER_CLEAR = "add_phone_number_user_clear";

// Resend Email
export const RE_SEND_EMAIL_START = "re_send_email_start";
export const RE_SEND_EMAIL_SUCCESS = "re_send_email_success";
export const RE_SEND_EMAIL_FAILED = "re_send_email_failed";
export const RE_SEND_EMAIL_CLEAR = "re_send_email_clear";

// verify otp

export const VERIFY_OTP_START = "verify_otp_start";
export const VERIFY_OTP_SUCCESS = "verify_otp_success";
export const VERIFY_OTP_FAILED = "verify_otp_failed";
export const VERIFY_OTP_CLEAR = "verify_otp_clear";

// resend otp

export const RE_SEND_OTP_START = "re_send_otp_start";
export const RE_SEND_OTP_SUCCESS = "re_send_otp_success";
export const RE_SEND_OTP_FAILED = "re_send_otp_failed";
export const RE_SEND_OTP_CLEAR = "re_send_otp_clear";

// user information

export const GET_USER_INFO_START = "get_user_info_start";
export const GET_USER_INFO_SUCCESS = "get_user_info_success";
export const GET_USER_INFO_FAILED = "get_user_info_failed";
export const GET_USER_INFO_CLEAR = "get_user_info_clear";
// Register user
export const COMPLETE_REGISTRATION_USER_START =
  "complete_registration_user_start";
export const COMPLETE_REGISTRATION_USER_SUCCESS =
  "complete_registration_user_success";
export const COMPLETE_REGISTRATION_USER_FAILED =
  "complete_registration_user_failed";
export const COMPLETE_REGISTRATION_USER_CLEAR =
  "complete_registration_user_clear";

// login user
export const LOGIN_USER_START = "login_user_start";
export const LOGIN_USER_SUCCESS = "login_user_success";
export const LOGIN_USER_FAILED = "login_user_failed";
export const LOGIN_USER_CLEAR = "login_user_clear";

// login user
export const LOGIN_REGISTERED_USER = "login_registered_user";

// countryCode
export const COUNTRY_CODE_LIST_START = "country_code_list_start";
export const COUNTRY_CODE_LIST_SUCCESS = "country_code_list_success";
export const COUNTRY_CODE_LIST_FAILED = "country_code_list_failed";
export const COUNTRY_CODE_LIST_CLEAR = "country_code_list_clear";

// logoutUser
export const LOGOUT_USER = "logout_user";

// change language
export const CHANGE_LOCALE_START = "change_locale_start";
export const CHANGE_LOCALE_SUCCESS = "change_locale_succes";
export const CHANGE_LOCALE_FAILED = "change_locale_failed";

// update Parent Name

export const UPDATE_NAME_START = "update_name_start";
export const UPDATE_NAME_SUCCESS = "update_name_success";
export const UPDATE_NAME_FAILED = "update_name_failed";
export const UPDATE_NAME_CLEAR = "update_name_clear";

// forgotpassword

export const FORGOT_PASSWORD_START = "forgot_password_start";
export const FORGOT_PASSWORD_SUCCESS = "forgot_password_success";
export const FORGOT_PASSWORD_FAILED = "forgot_password_failed";
export const FORGOT_PASSWORD_CLEAR = "forgot_password_clear";

// Regsitartion Valid

export const RAGISTRATION_VALID_START = "registration_valid_start";
export const RAGISTRATION_VALID_SUCCESS = "registration_valid_success";
export const RAGISTRATION_VALID_FAILED = "registration_valid_failed";

// update Password

export const UPDATE_PASSWORD_START = "update_password_start";
export const UPDATE_PASSWORD_SUCCESS = "update_password_success";
export const UPDATE_PASSWORD_FAILED = "update_password_failed";
export const UPDATE_PASSWORD_CLEAR = "update_password_clear";

// Get Notifications
export const GET_NOTIFICATIONS_START = "get_notifications_start";
export const GET_NOTIFICATIONS_SUCCESS = "get_notifications_success";
export const GET_NOTIFICATIONS_FAILED = "get_notifications_failed";
export const GET_NOTIFICATIONS_CLEAR = "get_notifications_clear";

// submit Feedback

export const SUBMIT_FEEDBACK_START = "submit_feedback_start";
export const SUBMIT_FEEDBACK_SUCCESS = "submit_feedback_success";
export const SUBMIT_FEEDBACK_FAILED = "submit_feedback_failed";
export const SUBMIT_FEEDBACK_CLEAR = "submit_feedback_clear";

// update token

export const UPDATE_DEVICE_TOKEN_START = "update_device_token_start";
export const UPDATE_DEVICE_TOKEN_SUCCESS = "update_device_token_success";
export const UPDATE_DEVICE_TOKEN_FAILED = "update_device_token_failed";
export const UPDATE_DEVICE_TOKEN_CLEAR = "update_device_token_clear";

// get delegates

export const GET_DELEGATES_START = "get_delegates_start";
export const GET_DELEGATES_SUCCESS = "get_delegates_success";
export const GET_DELEGATES_FAILED = "get_delegates_failed";
export const GET_DELEGATES_CLEAR = "get_delegates_clear";

// approved delegates

export const APPROVED_DELEGATES_START = "approved_delegates_start";
export const APPROVED_DELEGATES_SUCCESS = "approved_delegates_success";
export const APPROVED_DELEGATES_FAILED = "approved_delegates_failed";
export const APPROVED_DELEGATES_CLEAR = "approved_delegates_clear";

// approved delegates change

export const APPROVED_DELEGATES_CHANGE_START =
  "approved_delegates_change_start";
export const APPROVED_DELEGATES_CHANGE_SUCCESS =
  "approved_delegates_change_success";
export const APPROVED_DELEGATES_CHANGE_FAILED =
  "approved_delegates_change_failed";
export const APPROVED_DELEGATES_CHANGE_CLEAR =
  "approved_delegates_change_clear";

// approved delegates set deleted

export const DELEGATES_SET_DELETED_START = "delegates_set_deleted_start";
export const DELEGATES_SET_DELETED_SUCCESS = "delegates_set_deleted_success";
export const DELEGATES_SET_DELETED_FAILED = "delegates_set_deleted_failed";
export const DELEGATES_SET_DELETED_CLEAR = "delegates_set_deleted_clear";

// istokenValid

export const GET_TOKEN_VALID_START = "get_token_valid_start";
export const GET_TOKEN_VALID_SUCCESS = "get_token_valid_success";
export const GET_TOKEN_VALID_FAILED = "get_token_valid_failed";
export const GET_TOKEN_VALID_CLEAR = "get_token_valid_clear";

export const GET_HELP_TOPICS_START = "get_help_topics_start";
export const GET_HELP_TOPICS_SUCCESS = "get_help_topics_success";
export const GET_HELP_TOPICS_FAILED = "get_help_topics_failed";

export const SEND_SUPPORT_REQUEST_START = "send_support_request_start";
export const SEND_SUPPORT_REQUEST_SUCCESS = "send_support_request_success";
export const SEND_SUPPORT_REQUEST_FAILED = "send_support_request_failed";
export const SEND_SUPPORT_REQUEST_CLEAR = "send_support_request_clear";

export const GET_NOTIFICATION_SETTING_START = "get_notification_setting_start";
export const GET_NOTIFICATION_SETTING_SUCCESS =
  "get_notification_setting_success";
export const GET_NOTIFICATION_SETTING_FAILED =
  "get_notification_setting_failed";
export const GET_NOTIFICATION_SETTING_CLEAR = "get_notification_setting_clear";

export const UPDATE_NOTIFICATION_SETTING_START =
  "update_notification_setting_start";
export const UPDATE_NOTIFICATION_SETTING_SUCCESS =
  "update_notification_setting_success";
export const UPDATE_NOTIFICATION_SETTING_FAILED =
  "update_notification_setting_failed";
export const UPDATE_NOTIFICATION_SETTING_CLEAR =
  "update_notification_setting_clear";

export const LOG_ERRORS_BROWSER_START = "log_errors_browser_start";
export const LOG_ERRORS_BROWSER_SUCCESS = "log_errors_browser_success";
export const LOG_ERRORS_BROWSER_FAILED = "log_errors_browser_failed";
export const LOG_ERRORS_BROWSER_CLEAR = "log_errors_browser_clear";

export const LOG_ERRORS_FILE_START = "log_errors_file_start";
export const LOG_ERRORS_FILE_SUCCESS = "log_errors_file_success";
export const LOG_ERRORS_FILE_FAILED = "log_errors_file_failed";
export const LOG_ERRORS_FILE_CLEAR = "log_errors_file_clear";

export const CONNECT_STUDENT_START = "connect_student_start";
export const CONNECT_STUDENT_SUCCESS = "connect_student_success";
export const CONNECT_STUDENT_FAILED = "connect_student_failed";
export const CONNECT_STUDENT_CLEAR = "connect_student_clear";

export const SET_ENVIRONMENT = "set_environment";

export const SAVE_LAST_USED_TIMESTAMP = "save_last_used_timestamp";

export const SAVE_LOCATION_PERMISSION = "save_location_permission";

export const GET_FUTURE_ENTRIES_START = "get_future_entries_start";
export const GET_FUTURE_ENTRIES_SUCCESS = "get_future_entries_success";
export const GET_FUTURE_ENTRIES_FAILURE = "get_future_entries_failure";
export const GET_FUTURE_ENTRIES_CLEAR = "get_future_entries_clear";

export const TOGGLE_MODAL = "toggle_modal";
export const HIDE_MODAL = "hide_modal";

export const TOGGLE_CHECKIN_QUEST_ANS = "toggle_quest_ans";

export const SET_HELPLIST = "set_helplist";
export const SET_OLD_HELPLIST = "set_old_helplist";

export const SAVE_PHOTO = "save_photo";
export const SAVE_ALL_PHOTOS = "save_all_photos";

// Upload delegate Image
export const UPLOAD_DELEGATE_IMAGE_START = "upload_delegate_image_start";
export const UPLOAD_DELEGATE_IMAGE_SUCCESS = "upload_delegate_image_success";
export const UPLOAD_DELEGATE_IMAGE_FAILED = "upload_delegate_image_failed";
export const UPLOAD_DELEGATE_IMAGE_CLEAR = "upload_delegate_image_clear";

// Request Manual Review
export const REQUEST_MANUAL_REVIEW_START = "request_manual_review_start";
export const REQUEST_MANUAL_REVIEW_SUCCESS = "request_manual_review_success";
export const REQUEST_MANUAL_REVIEW_FAILED = "request_manual_review_failed";
export const REQUEST_MANUAL_REVIEW_CLEAR = "request_manual_review_clear";

// Cancel Manual Review
export const CANCEL_MANUAL_REVIEW_START = "cancel_manual_review_start";
export const CANCEL_MANUAL_REVIEW_SUCCESS = "cancel_manual_review_success";
export const CANCEL_MANUAL_REVIEW_FAILED = "cancel_manual_review_failed";
export const CANCEL_MANUAL_REVIEW_CLEAR = "cancel_manual_review_clear";

// Get Delegate
export const GET_DELEGATE_START = "get_delegate_start";
export const GET_DELEGATE_SUCCESS = "get_delegate_success";
export const GET_DELEGATE_FAILED = "get_delegate_failed";
export const GET_DELEGATE_CLEAR = "get_delegate_clear";

// User Account Delete
export const ACCOUNT_DELETE_START = "account_delete_start";
export const ACCOUNT_DELETE_SUCCESS = "account_delete_success";
export const ACCOUNT_DELETE_FAILED = "account_delete_failed";
export const ACCOUNT_DELETE_CLEAR = "account_delete_clear";

export const SAVE_TERMS_ACCEPTED = "save_terms_accepted";

// Ask me later and Don't ask me data save
export const ASK_ME_LATER_SAVE = "ask_me_later_save";
export const ASK_ME_LATER_GET = "ask_me_later_get";
export const ASK_ME_LATER_CLEAR = "ask_me_later_clear";
export const DONT_ASK_ME_SAVE = "dont_ask_me_save";
export const DONT_ASK_ME_CLEAR = "dont_ask_me_clear";

export const BACKTOSCHOOL_DATA_FILLED = "backtoschool_data_filled";

// Upload delegate Image
export const UPLOAD_USER_IMAGE_START = "upload_user_image_start";
export const UPLOAD_USER_IMAGE_SUCCESS = "upload_user_image_success";
export const UPLOAD_USER_IMAGE_FAILED = "upload_user_image_failed";
export const UPLOAD_USER_IMAGE_CLEAR = "upload_user_image_clear";

// Request Manual Review
export const USER_REQUEST_MANUAL_REVIEW_START =
  "user_request_manual_review_start";
export const USER_REQUEST_MANUAL_REVIEW_SUCCESS =
  "user_request_manual_review_success";
export const USER_REQUEST_MANUAL_REVIEW_FAILED =
  "user_request_manual_review_failed";
export const USER_REQUEST_MANUAL_REVIEW_CLEAR =
  "user_request_manual_review_clear";

//  Cancel Manual Review
export const USER_CANCEL_MANUAL_REVIEW_START =
  "user_cancel_manual_review_start";
export const USER_CANCEL_MANUAL_REVIEW_SUCCESS =
  "user_cancel_manual_review_success";
export const USER_CANCEL_MANUAL_REVIEW_FAILED =
  "user_cancel_manual_review_failed";
export const USER_CANCEL_MANUAL_REVIEW_CLEAR =
  "user_cancel_manual_review_clear";

// Get user profile
export const GET_USER_PROFILE_START = "get_user_profile_start";
export const GET_USER_PROFILE_SUCCESS = "get_user_profile_success";
export const GET_USER_PROFILE_FAILED = "get_user_profile_failed";
export const GET_USER_PROFILE_CLEAR = "get_user_profile_clear";

// Mark ready to dashboard Complete
export const MARK_READY_TO_DASHBOARD = "mark_ready_to_dashboard";
//Set cropped Image file data
export const SET_CROPPED_IMAGE = "set_cropped_image";
export const SET_CROPPED_IMAGE_CLEAR = "set_cropped_image_clear";

export const SET_LOGIN_TYPE = "set_login_type";

// SSO user send otp for delete account
export const SSO_USER_SEND_OTP_START = "delete_user_send_otp_start";
export const SSO_USER_SEND_OTP_SUCCESS = "delete_user_send_otp_success";
export const SSO_USER_SEND_OTP_FAILED = "delete_user_send_otp_failed";
export const SSO_USER_SEND_OTP_CLEAR = "delete_user_send_otp_clear";

// SSO user delete account
export const SSO_USER_DELETE_START = "sso_user_delete_start";
export const SSO_USER_DELETE_SUCCESS = "sso_user_delete_success";
export const SSO_USER_DELETE_FAILED = "sso_user_delete_failed";
export const SSO_USER_DELETE_CLEAR = "sso_user_delete_clear";

export const SSO_USER_SEND_OTP_TRANSACTION_START =
  "sso_user_send_otp_transaction_start";
export const SSO_USER_SEND_OTP_TRANSACTION_SUCCESS =
  "sso_user_send_otp_transaction_success";
export const SSO_USER_SEND_OTP_TRANSACTION_FAILED =
  "sso_user_send_otp_transaction_failed";
export const SSO_USER_SEND_OTP_TRANSACTION_CLEAR =
  "sso_user_send_otp_transaction_clear";

export const SEND_WELCOME = "send_welcome";

export const SET_GOOGLE_LOGIN_CREDENTIALS = "set_google_login_credentials";
export const SET_GOOGLE_LOGIN_CREDENTIALS_CLEAR =
  "set_google_login_credentials_clear";
export const SET_APPLE_LOGIN_CREDENTIALS = "set_apple_login_credentials";
export const SET_APPLE_LOGIN_CREDENTIALS_CLEAR =
  "set_apple_login_credentials_clear";

export const SAVE_LOGIN_CREDENTIALS = "save_login_credentials";
export const SAVE_LOGIN_CREDENTIALS_CLEAR = "save_login_credentials_clear";

export const GET_GEOINFO_START = "get_geoinfo_start";
export const GET_GEOINFO_SUCCESS = "get_geoinfo_success";
export const GET_GEOINFO_FAILED = "get_geoinfo_failed";

export const SAVE_HELP_MESSAGE1 = "save_help_message1";
export const SAVE_HELP_MESSAGE2 = "save_help_message2";
export const SAVE_HELP_MESSAGE3 = "save_help_message3";

export const SAVE_THEME = "save_theme";
