import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "react-calendar/dist/Calendar.css";
import "react-calendar/dist/Calendar.css";
import { showModal, hideModal } from "../../common/actions/userAccount";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { getCurrentThemeColor } from "./../../helper/utility";

class OtherKidView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
    };
  }

  openFutureDelegationWindow = (params) => {
    this.props.doShowModal({
      modalScreenName: params.modalScreenName,
      modalClassName: "future-delegation-modal sidebar-modal right",
      params: { child: params.child },
    });
  };
  render() {
    const { child, title } = this.props;
    return (
      <tr
        id="child-1"
        className="mng-child-row row-clickable expandcollapse-row"
        onClick={() => {
          this.openFutureDelegationWindow({
            modalScreenName: "futureDelegation",
            child: child,
          });
        }}
      >
        <td width="30%" valign="center">
          <div className="td-data">
            <div className="childInfo">
              <span className="child-name">
                {`${child.StudentName.FirstName}`}
                {""} {`${child.StudentName.LastName}`}
              </span>
              <br />
              <span className="font-sz14">{`${child.Grade}`}</span>
            </div>

            {/* <span className="child-name">
              {`${child.StudentName.FirstName}`}
              {""} {`${child.StudentName.LastName}`}
            </span>
            <br />
            <span className="font-sz14">{`${child.Grade}`}</span> */}
          </div>
        </td>
        <td width="20%" valign="center" className="last-td">
          <div className="td-data">
            {`${child.ParentName.FirstName}`}
            {""} {`${child.ParentName.LastName}`}
          </div>
        </td>
        <td width="20%" valign="center" className="hidden-xs">
          <div className="td-data">
            {moment(child.PickupDate).format("DD MMM YYYY")}
          </div>
        </td>
        <td width="30%" valign="center" className="hidden-xs">
          <div className="td-data pd-right">
            <span className="text">{title}</span>
          </div>
          <span className="childCardRight">
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              color={
                getCurrentThemeColor(this.props.selectedTheme)
                  .mediumBlack_BlueishGray
              }
            />
          </span>
        </td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(OtherKidView)
);
