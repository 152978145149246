import React from "react";
import logo from "../assets/registerImages/pmk-logo.svg";
import { withTranslation } from "react-i18next";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { connect } from "react-redux";

import { showModal, hideModal } from "../../common/actions/userAccount";

import appStoreIcon from "../assets/commonImages/appStoreIcon.png";
import googlePlayIcon from "../assets/commonImages/googlePlayIcon.png";

import _ from "lodash";

import { getCurrentThemeColor } from "../../helper/utility";

class RegisterHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickCount: 0,
      envPassword: "",
      envToastTxt: "",
      isShowEnvToast: false,
    };
  }

  setThemeBody = () => {
    document.body.style.backgroundColor = getCurrentThemeColor(
      this.props.selectedTheme
    ).body;

    document.body.className = `theme-${this.props.selectedTheme}`;
  };

  componentDidMount() {
    this.setThemeBody();
  }

  doCallback = () => {
    this.setState(
      {
        clickCount: 0,
        isShowEnvToast: false,
      },
      () => {
        this.props.doHideModal();
      }
    );
  };
  openSelectServerModal = () => {
    const { clickCount } = this.state;
    this.setState({ isShowEnvToast: false });
    this.props.doShowModal({
      modalScreenName: "selectServer",
      modalClassName: "passwordOtpEntryModal",
      params: {
        clickCount,
        doCallbackRefresh: this.doCallback,
      },
    });
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.selectedTheme, prevProps.selectedTheme)) {
      this.setThemeBody();
    }
  }

  render() {
    const { envToastTxt, isShowEnvToast } = this.state;
    return (
      <div className="col-sm-12 col-md-7 col-lg-7 col-xl-8 main-col">
        <div className="pmk-landing">
          <div className="logo-wrapper">
            <a
              className="navbar-brand"
              //href="/"
              onClick={() => {
                let { clickCount } = this.state;
                this.setState({ clickCount: clickCount + 1 }, () => {
                  if (this.state.clickCount >= 15) {
                    this.openSelectServerModal();
                  }

                  if (clickCount > 3 && clickCount < 15) {
                    let remaining = 15 - clickCount;
                    this.setState({
                      isShowEnvToast: true,
                      envToastTxt:
                        "You are " +
                        remaining +
                        " step away from changing environment",
                    });
                  }
                });
              }}
            >
              <img src={logo} alt="PikMyKid" title="PikMyKid" />
            </a>
          </div>

          <div className="welcome-sect">
            <div className="title-sect">
              <h1 className="title-1">
                {this.props.t("landingScreen-welcome-to")} Pikmykid!
              </h1>
            </div>
          </div>
          <div className="appdownload-sect">
            <div className="appdownload-wrapper">
              <p>{this.props.t("download_message")}</p>
              <div className="appdownload-icons">
                <a
                  className="store-icon google-play"
                  href="https://play.google.com/store/apps/details?id=com.pikmykid.parentapp&hl=en_IN&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={googlePlayIcon}
                    alt="Google play"
                    title="Google play"
                  />
                </a>
                <a
                  className="store-icon appstore"
                  href="https://apps.apple.com/us/app/pikmykid-parent/id1559538995"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appStoreIcon} alt="App Store" title="App Store" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {isShowEnvToast && (
          <div className="envToastContainer">
            <span>{envToastTxt}</span>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    environment: state.userAccount.environment,
    selectedTheme: state.userAccount.selectedTheme,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RegisterHeader)
);
