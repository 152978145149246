import {
  GET_SCHOOL_PICKUP_TIME_START,
  GET_SCHOOL_PICKUP_TIME_SUCCESS,
  GET_SCHOOL_PICKUP_TIME_FAILED,
  GET_SCHOOL_PICKUP_MODE_START,
  GET_SCHOOL_PICKUP_MODE_SUCCESS,
  GET_SCHOOL_PICKUP_MODE_FAILED,
  GET_ABSENT_REASON_START,
  GET_ABSENT_REASON_SUCCESS,
  GET_ABSENT_REASON_FAILED,
  GET_SCHOOL_AFTER_SCHOOL_START,
  GET_SCHOOL_AFTER_SCHOOL_SUCCESS,
  GET_SCHOOL_AFTER_SCHOOL_FAILED,
  GET_SCHOOL_PICKUP_TIME_CLEAR,
  GET_HELP_START,
  GET_HELP_SUCCESS,
  GET_HELP_FAILED,
  GET_HELP_CLAER,
  GET_SCHOOLCALENDAR_START,
  GET_SCHOOLCALENDAR_SUCCESS,
  GET_SCHOOLCALENDAR_FAILED,
  GET_SCHOOLCALENDAR_EVENT_START,
  GET_SCHOOLCALENDAR_EVENT_SUCCESS,
  GET_SCHOOLCALENDAR_EVENT_FAILED,
  GET_SCHOOLCALENDAR_CLEAR,
} from "./schoolActionType";

import {
  GetSchoolPickupTimes,
  GetSchoolPickupModes,
  GetReasonsForAbsence,
  GetSchoolAfterSchools,
  getSchoolInfo2,
  getHelp,
  getSchoolCalendarData,
  getSchoolCalendarEventData,
} from "../../services";

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/getSchoolPickupTimeDetails
 * Purpose: Get getSchoolPickupTimeDetails
 */
export const getSchoolPickupTimeDetails = ({ SchoolID }) => (dispatch) => {
  dispatch({ type: GET_SCHOOL_PICKUP_TIME_START });
  return GetSchoolPickupTimes({ SchoolID })
    .then((response) => {
      // console.log(`School pickup time response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({
          type: GET_SCHOOL_PICKUP_TIME_SUCCESS,
          payload: response.Data,
        });
      else {
        dispatch({ type: GET_SCHOOL_PICKUP_TIME_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`School pickup time error ${JSON.stringify(error)}`);
      dispatch({ type: GET_SCHOOL_PICKUP_TIME_FAILED, payload: error });
    });
};
export const getSchoolPickupTimeDetailsClear = () => (dispatch) => {
  dispatch({ type: GET_SCHOOL_PICKUP_TIME_CLEAR });
};

export const getSchoolPickupModeDetails = ({ SchoolID }) => (dispatch) => {
  dispatch({ type: GET_SCHOOL_PICKUP_MODE_START });
  return GetSchoolPickupModes({ SchoolID })
    .then((response) => {
      const pickupupModes = [];
      const pickupModesRecord = response.Data;
      pickupupModes.push({
        label: "Select",
        value: 0,
      });
      for (let i = 0; i < pickupModesRecord.length; i++) {
        pickupupModes.push({
          label: `${pickupModesRecord[i].PickupMode}`,
          value: `${pickupModesRecord[i].Id}`,
        });
      }
      // console.log(`School pickup mode response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({
          type: GET_SCHOOL_PICKUP_MODE_SUCCESS,
          payload: pickupupModes,
        });
      else {
        dispatch({ type: GET_SCHOOL_PICKUP_MODE_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`School pickup mode error ${JSON.stringify(error)}`);
      dispatch({ type: GET_SCHOOL_PICKUP_MODE_FAILED, payload: error });
    });
};

export const getAbsentReason = ({ SchoolID }) => (dispatch) => {
  dispatch({ type: GET_ABSENT_REASON_START });
  return GetReasonsForAbsence({ SchoolID })
    .then((response) => {
      // console.log(`Get absent reason response${JSON.stringify(response.Data)}`);
      if (response.Success)
        dispatch({ type: GET_ABSENT_REASON_SUCCESS, payload: response.Data });
      else {
        dispatch({ type: GET_ABSENT_REASON_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`Get absent reason  error ${JSON.stringify(error)}`);
      dispatch({ type: GET_ABSENT_REASON_FAILED, payload: error });
    });
};
export const getSchoolAfter = ({ SchoolID }) => (dispatch) => {
  dispatch({ type: GET_SCHOOL_AFTER_SCHOOL_START });

  return GetSchoolAfterSchools({ SchoolID })
    .then((response) => {
      const afterSchool = [];
      const afterSchoolRecord = response.Data;
      afterSchool.push({
        label: "Select",
        value: 0,
      });
      for (let i = 0; i < afterSchoolRecord.length; i++) {
        afterSchool.push({
          label: `${afterSchoolRecord[i].Name}`,
          value: `${afterSchoolRecord[i].AfterSchoolID}`,
        });
      }
      // console.log(`Get school after school response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({
          type: GET_SCHOOL_AFTER_SCHOOL_SUCCESS,
          payload: afterSchool,
        });
      else {
        dispatch({ type: GET_SCHOOL_AFTER_SCHOOL_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`Get school after school  error ${JSON.stringify(error)}`);
      dispatch({ type: GET_SCHOOL_AFTER_SCHOOL_FAILED, payload: error });
    });
};

export const getHelpDetails = ({ schoolId, pageName, stackType }) => (
  dispatch
) => {
  dispatch({ type: GET_HELP_START });
  return getHelp({ schoolId, pageName, stackType })
    .then((response) => {
      // console.log(`Get Help response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: GET_HELP_SUCCESS, payload: response.Data });
      else {
        dispatch({ type: GET_HELP_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`Get Help error ${JSON.stringify(error)}`);
      dispatch({ type: GET_HELP_FAILED, payload: error });
    });
};
export const getHelpDetailsClear = () => (dispatch) => {
  dispatch({ type: GET_HELP_CLAER });
};

export const getSchoolCalendar = ({ schoolId }) => (dispatch) => {
  dispatch({ type: GET_SCHOOLCALENDAR_START });
  return getSchoolCalendarData({ schoolId })
    .then((response) => {
      //console.log(`Get School Calendar response${JSON.stringify(response)}`);
      if (response.Success) dispatch({ type: GET_SCHOOLCALENDAR_SUCCESS, payload: response.Data.Result });
      else { dispatch({ type: GET_SCHOOLCALENDAR_FAILED, payload: response }); }
    }).catch((error) => {
      //console.log(`Get School Calendar error ${JSON.stringify(error)}`);
      dispatch({ type: GET_SCHOOLCALENDAR_FAILED, payload: (error) });
    });
};

export const getSchoolCalendarClear = () => (dispatch) => {
  dispatch({ type: GET_SCHOOLCALENDAR_CLEAR });
};

export const getSchoolCalendarEventType = ({ schoolId }) => (dispatch) => {
  dispatch({ type: GET_SCHOOLCALENDAR_EVENT_START });
  return getSchoolCalendarEventData({ schoolId })
    .then((response) => {
      //console.log(`Get School Calendar Event response${JSON.stringify(response)}`);
      if (response.Success) dispatch({ type: GET_SCHOOLCALENDAR_EVENT_SUCCESS, payload: response.Data.EventTypes });
      else { dispatch({ type: GET_SCHOOLCALENDAR_EVENT_FAILED, payload: response }); }
    }).catch((error) => {
      //console.log(`Get School Calendar error ${JSON.stringify(error)}`);
      dispatch({ type: GET_SCHOOLCALENDAR_EVENT_FAILED, payload: (error) });
    });
};