import React from "react";
import "./styles/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSchool } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { connect } from "react-redux";
import { MainHeader, MainFooter } from "../CommonComponents";
import {
  distance,
  getCurrentThemeColor,
  showLocationError,
} from "../../helper/utility";

import settingsIcon from "../assets/dashboard/settingsIcon.svg";

import moment from "moment";
import CheckinQuestions from "./CheckinQuestions";
import {
  getSchoolCheckIn,
  getSchoolGeoFenceInformation,
} from "../../common/actions/checkin";

import { createLoadingSelector } from "../../common/reducers/selectors";
import RingLoader from "react-spinners/ClipLoader";

import {
  showModal,
  hideModal,
  showCheckinQuestAns,
  setHelpList,
  getIsTokenValid,
  getIsTokenValidClear,
  logoutUser,
} from "../../common/actions/userAccount";

import {
  getStudentsList,
  getSchoolDetails,
  schoolAndKidsFetchDone,
} from "../../common/actions/students";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GLOBAL_CONST from "../../helper/AppConstants";
import SelectCheckInFor from "./SelectCheckInFor";
import { getMyStyle } from "../../theme/ThemeStyle";
import { useNavigate, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import AdsView from "../Ads/AdsView";
const loadingSelector = createLoadingSelector([
  "get_school_student_checkin",
  "submit_checkin_parent",
  "get_school_checkin",
  "get_school_information",
  "get_students",
  "get_school_info",
  "get_school_student_checkin",
]);
function withRouterHook(Component) {
  return function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component navigate={navigate} location={location} {...props} />;
  };
}
class Checkin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCheckinView: false,
      showSchoolList: true,
      isFirstGPSInProgress: true,
      latitude: null,
      longitude: null,
      IsSchoolLocationInCheckIn: [],
      schoolGeoInfo: {},
      enabelGeoCount: 0,
    };
  }

  setThemeBody = () => {
    document.body.style.backgroundColor = getCurrentThemeColor(
      this.props.selectedTheme
    ).body;

    document.body.className = `theme-${this.props.selectedTheme}`;
  };
  componentDidMount() {
    this.setThemeBody();
    const { modalLevelInfo } = this.props;

    this.checkTokenValid();
    this.enableGeo();
    this.getSchool(this.getStudents);
    this.hideQuestionAnswerView();
    this.dosetHelpList();

    if (
      modalLevelInfo &&
      modalLevelInfo.length > 0 &&
      (modalLevelInfo[modalLevelInfo.length - 1].modalScreenName ===
        "AddContact" ||
        modalLevelInfo[modalLevelInfo.length - 1].modalScreenName ===
          "EditContact")
    ) {
      this.props.doHideModal();
    }
  }

  checkTokenValid = () => {
    const { doGetIsTokenValid } = this.props;
    doGetIsTokenValid();
  };

  componentWillUnmount() {
    if (this.watchId) navigator.geolocation.clearWatch(this.watchId);
  }

  showGeolocationError = (error) => {
    switch (error && error.code) {
      case error.PERMISSION_DENIED:
        {
          toast.error(error.message, {
            autoClose: 2000,
            transition: Slide,
            hideProgressBar: true,
            icon: false,
          });
          this.enableGeo();
        }

        break;
      case error.POSITION_UNAVAILABLE:
        toast.error("Location information is unavailable.", {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        break;
      case error.TIMEOUT:
        toast.error("The request to get user location timed out.", {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        break;
      case error.UNKNOWN_ERROR:
        toast.error("An unknown error occurred.", {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        break;
    }
  };

  enableGeo = () => {
    const mythis = this;
    let { enabelGeoCount } = this.state;
    enabelGeoCount++;
    this.setState({ enabelGeoCount });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({ isFirstGPSInProgress: false });
        mythis.processGeoLocation(position);
        mythis.watchLocation();
      },
      (error) => {
        this.setState({ isFirstGPSInProgress: false, geoError: error.message });
        if (enabelGeoCount === 1) {
          this.showGeolocationError(error);
        }
      },
      {
        enableHighAccuracy: false,
        maximumAge: 0,
      }
    );
  };

  watchLocation = () => {
    const mythis = this;
    this.watchId = navigator.geolocation.watchPosition(
      (position) => {
        mythis.processGeoLocation(position);
      },
      (error) => {
        console.error("watchLocation error error ", error);

        if (this.state.enabelGeoCount === 1) {
          this.showGeolocationError(error);
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 90000,
        maximumAge: 1000,
        distanceFilter: 100,
      }
    );
  };

  processGeoLocation = (position) => {
    if (!position || !position.coords) return;
    this.setState(
      {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      },
      this.checkSchoolInGeo
    );
  };

  checkSchoolInGeo = () => {
    // Check for CheckIn Distance
    const { latitude, longitude } = this.state;
    const { schoolGeoFenceInformation } = this.props;
    const IsSchoolLocationInCheckIn = [];
    for (var key in schoolGeoFenceInformation) {
      const schoolGeoInfo = schoolGeoFenceInformation[key];
      if (
        schoolGeoInfo &&
        schoolGeoInfo.GeoFenceForCheckIn &&
        schoolGeoInfo.GeoFenceData.length > 0
      ) {
        const checkInLat = schoolGeoInfo.GeoFenceData[0].Latitude;
        const checkInLong = schoolGeoInfo.GeoFenceData[0].Longitude;
        const checkInDistance = schoolGeoInfo.GeoFenceData[0].RadiusYards;
        const Unit = "K";
        const distCheckin = distance(
          latitude,
          longitude,
          checkInLat,
          checkInLong,
          Unit
        );

        if (distCheckin <= checkInDistance) {
          IsSchoolLocationInCheckIn.push(key);
        }
      }
    }
    this.setState({ IsSchoolLocationInCheckIn });
  };
  getSchool = (callback) => {
    const { doGetSchoolDetails } = this.props;
    doGetSchoolDetails().then(() => {
      callback();
    });
  };

  getStudents = () => {
    const { doGetStudentsList } = this.props;
    let currenDate = moment().format("MM-DD-YYYY");
    doGetStudentsList({ currenDate }).then(() => {
      this.checkInForSchool();
    });
  };

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "CheckIn",
      screen: GLOBAL_CONST.SCREEN_NAMES.CHECKIN_FOR,
      title: this.props.t("selectCheckScr-schoolCheck"),
    });
  };

  getGeofenceDetails = (schoolID) => {
    const { doSchoolGeoFenceInformation } = this.props;
    doSchoolGeoFenceInformation({ schoolId: schoolID });
  };

  checkInForSchool = () => {
    const { doGetSchoolCheckIn } = this.props;

    doGetSchoolCheckIn({
      callback: (data) => {
        const schools = data.Schools;
        if (schools) {
          for (var i = 0; i < schools.length; i++) {
            this.getGeofenceDetails(schools[i]);
          }
        }
      },
    });
  };

  isSchoolCheckIn = (school) => {
    const { schoolParentCheckIn, schoolGeoFenceInformation } = this.props;
    let checkInPermission = false;
    let checkInAllowedForParent = false;
    let isGeoConditionPass = false;

    if (
      schoolParentCheckIn &&
      schoolParentCheckIn.Schools &&
      schoolParentCheckIn !== null &&
      schoolParentCheckIn.Schools !== null &&
      schoolParentCheckIn.Schools.includes(school.SchoolID)
    ) {
      checkInAllowedForParent = true;
    }

    const schoolGeoInfo = schoolGeoFenceInformation[school.SchoolID];
    if (schoolGeoInfo && schoolGeoInfo.GeoFenceForCheckIn === false) {
      isGeoConditionPass = true;
    } else if (schoolGeoInfo && schoolGeoInfo.GeoFenceForCheckIn === true) {
      isGeoConditionPass = this.state.IsSchoolLocationInCheckIn.includes(
        "" + school.SchoolID
      );
    }
    checkInPermission = checkInAllowedForParent && isGeoConditionPass;

    return checkInPermission;
  };

  viewSchoolDetails = (school) => {
    this.openSchoolDetails(school);
  };
  openContactSupport = () => {
    this.props.doShowModal({
      modalScreenName: "ContactSupport",
      modalClassName: "contactSupportModal sidebar-modal right",
    });
  };
  getEmptyView = () => {
    return (
      <div>
        <p>
          <strong>{this.props.t("CheckInNoRecord_Title")}</strong>
        </p>
        <p>
          {this.props.t("CheckInNoRecord_SubTitle")}
          <a
            className="needhelp-label"
            onClick={() => {
              this.openContactSupport();
            }}
          >
            {this.props.t("CheckInNoRecord_Link")}
          </a>
        </p>
      </div>
    );
  };
  getCheckViews = () => {
    const { studentsList, schoolInfo, isFetching } = this.props;
    const schools = schoolInfo;
    const students = studentsList && studentsList.TodaysStudents;
    const { isFirstGPSInProgress } = this.state;

    const kids = [];

    for (let i = 0; i < schools.length; i++) {
      let schoolstudents = [];
      let schoolStudentIDs = [];

      if (!this.isSchoolCheckIn(schools[i])) {
        continue;
      }

      for (let j = 0; j < students.length; j++) {
        if (schools[i].SchoolID === students[j].SchoolID) {
          schoolstudents.push(students[j]);
          schoolStudentIDs.push(students[j].StudentID);
        }
      }

      schoolstudents = schoolstudents.sort((a, b) =>
        a.Firstname.toLowerCase() > b.Firstname.toLowerCase()
          ? 1
          : b.Firstname.toLowerCase() > a.Firstname.toLowerCase()
          ? -1
          : 0
      );

      if (schoolstudents.length > 0) {
        let sectiondata = {
          schoolId: schools[i].SchoolID,
          address: schools[i].Address,
          title: schools[i].Name,
          ReunificationEnabled: schools[i].ReunificationEnabled,
          schoolLat: schools[i].Coordinates.latitude,
          schoolLong: schools[i].Coordinates.longitude,
          school: schools[i],
          data: schoolstudents,
          schoolStudentIDs,
        };
        kids.push(sectiondata);
      }
    }
    const sortedSchoolStudentList = kids.sort((a, b) =>
      a.title.toLowerCase() > b.title.toLowerCase()
        ? 1
        : b.title.toLowerCase() > a.title.toLowerCase()
        ? -1
        : 0
    );

    const views = [];
    for (var i = 0; i < sortedSchoolStudentList.length; i++) {
      const school = sortedSchoolStudentList[i];
      views.push(
        <div className="schoolBox" key={"school-" + i}>
          <div className="schoolDetails">
            <div
              className="schoolname-header"
              onClick={() => {
                this.viewSchoolDetails(school);
              }}
            >
              <h4 className="school-title">
                <span>{school.title}</span>
              </h4>

              <FontAwesomeIcon icon={faSchool} className="school-icon" />
            </div>
            <SelectCheckInFor school={school}></SelectCheckInFor>
          </div>
        </div>
      );
    }

    if (views.length === 0 && isFetching) {
      return this.getLoaderView();
    }
    if (!isFetching && views.length === 0) {
      return this.getEmptyView();
    }

    return views;
  };

  getLoaderView = () => {
    const { isFetching } = this.props;
    return (
      <div className="loaderHolder mt-30">
        <RingLoader
          color={getCurrentThemeColor(this.props.selectedTheme).loader}
          loading={isFetching}
          size={60}
        />
      </div>
    );
  };

  getCheckinView = () => {
    return <CheckinQuestions />;
  };
  openCheckinWindow = () => {
    this.props.doShowModal({
      modalScreenName: "CheckInQuestions",
      modalClassName: "checkInQuestionsModal",
    });
  };
  openSchoolDetails = (schoolStudentDetials) => {
    this.props.doShowModal({
      modalScreenName: "schoolDetails",
      params: { school: schoolStudentDetials },
      modalClassName: "schoolDetails sidebar-modal right",
    });
  };

  hideQuestionAnswerView = () => {
    this.props.doShowCheckinQuestAnsView({
      status: false,
      showSchoolChildrenList: true,
      params: {},
    });
  };

  back = () => {
    this.hideQuestionAnswerView();
    this.checkInForSchool();
  };

  componentDidUpdate(prevProps) {
    const {
      fetchStudentListDone,
      fetchSchoolInfoDone,
      doSchoolAndKidsFetchDone,
      schoolGeoFenceInformation,
      isTokenValidResponse,
      doGetIsTokenValidClear,
    } = this.props;

    if (isTokenValidResponse) {
      if (isTokenValidResponse.Success === false) {
        const { doLogoutUser } = this.props;
        doLogoutUser();
        this.props.navigate("/");
      }
    }
    doGetIsTokenValidClear();

    if (!_.isEqual(this.props.selectedTheme, prevProps.selectedTheme)) {
      this.setThemeBody();
    }

    if (fetchStudentListDone === true && fetchSchoolInfoDone === true) {
      doSchoolAndKidsFetchDone();
    }
    if (!_.isEqual(schoolGeoFenceInformation, this.state.schoolGeoInfo)) {
      this.setState(
        { schoolGeoInfo: schoolGeoFenceInformation },
        this.checkSchoolInGeo
      );
    }
  }

  render() {
    const { isFirstGPSInProgress } = this.state;
    const { isFetching, selectedTheme } = this.props;
    const styles = getMyStyle(selectedTheme);
    return (
      <main className="pmk-body graySubtle-bg">
        <MainHeader />
        {isFetching && (
          <div className="loaderHolder mt-60">
            <RingLoader
              color={getCurrentThemeColor(this.props.selectedTheme).loader}
              loading={isFirstGPSInProgress}
              size={60}
            />
          </div>
        )}

        <section className="pmk-welcome-sect">
          <div className="container-fluid">
            <div className="row flex-middle">
              <div className="col-xs-12 col-sm-8">
                <div className="title-sect">
                  <h1 className="title-style2" style={styles.titleStyle2}>
                    {this.props.t("selectCheckScr-schoolCheck")}
                  </h1>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className="date-text">
                  {moment().format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pmk-child-schedule">
          <div className="container-fluid">
            <div className="checkin-content">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-sep">
                  <div className="mandatory-checkin-sect">
                    <div className="notes-icon">
                      <img src={settingsIcon} alt="Mandatory check-in" />
                    </div>
                    <h2 className="notes-title">
                      {this.props.t("checkInScr_mandatory_checkin")}
                    </h2>
                    <p className="notes-text">
                      {this.props.t("selectCheckScr-mandatory")}
                    </p>
                  </div>
                </div>
                {/* {isFetching && (
                  <div className="loaderHolder mt-60">
                    <RingLoader
                      color="#2d3d56"
                      loading={isFetching}
                      size={60}
                    />
                  </div>
                )} */}
                <div className="col-sm-12 col-md-6 col-sep heightScroll-box">
                  {this.props.checkInQuestAnsView.status === true && (
                    <p>
                      <button
                        onClick={this.back}
                        className="previous-steps btn"
                        type="button"
                      >
                        <i className="fas fa-arrow-left"></i>
                        {this.props.t("supportNoScr-back")}
                      </button>
                    </p>
                  )}
                  {isFirstGPSInProgress === false && (
                    <div className="form-card">
                      {this.props.checkInQuestAnsView.showSchoolChildrenList ===
                        true && (
                        <div className="select-bx-list selectSchoolList">
                          <div className="form-card-header">
                            <h3 className="form-card-title2">
                              {this.props.t("checkInScr_selectwhos_visiting")}:
                            </h3>
                          </div>
                          <div className="schoolsList">
                            {this.getCheckViews()}
                          </div>
                        </div>
                      )}
                      {this.props.checkInQuestAnsView.status === true && (
                        <CheckinQuestions
                          {...this.props.checkInQuestAnsView.params}
                        />
                      )}
                    </div>
                  )}
                  {isFirstGPSInProgress && (
                    <div className="mt-30 text-center">
                      <p>{this.props.t("fetching_location")}</p>
                      <div className="loaderHolder ">
                        <RingLoader
                          color={
                            getCurrentThemeColor(this.props.selectedTheme)
                              .loader
                          }
                          loading={isFirstGPSInProgress}
                          size={60}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.props.isShowAdvert && <AdsView />}
        <ToastContainer />
        <MainFooter />
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    fetchStudentListDone: state.student.fetchStudentListDone,
    fetchSchoolInfoDone: state.student.fetchSchoolInfoDone,
    checkInQuestAnsView: state.userAccount.checkInQuestAnsView,
    error: state.checkin.error,
    studentsList: state.student.studentsList,
    schoolInfo: state.student.schoolInfo,
    schoolParentCheckIn: state.checkin.schoolParentCheckIn,
    isFetching: loadingSelector(state),
    setHelpListData: state.userAccount.setHelpListData,
    schoolGeoFenceInformation: state.checkin.schoolGeoFenceInformation,

    modalLevelInfo: state.userAccount.modalLevelInfo,
    selectedTheme: state.userAccount.selectedTheme,

    isTokenValidResponse: state.userAccount.isTokenValidResponse,
    isShowAdvert: state.ads.isShowAdvert,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doShowCheckinQuestAnsView: (data) => dispatch(showCheckinQuestAns(data)),
    doGetSchoolCheckIn: (data) => dispatch(getSchoolCheckIn(data)),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doGetStudentsList: (data) => dispatch(getStudentsList(data)),
    doGetSchoolDetails: () => dispatch(getSchoolDetails()),
    doSchoolAndKidsFetchDone: () => dispatch(schoolAndKidsFetchDone()),
    doSchoolGeoFenceInformation: (data) =>
      dispatch(getSchoolGeoFenceInformation(data)),

    doGetIsTokenValid: (data) => dispatch(getIsTokenValid(data)),
    doGetIsTokenValidClear: () => dispatch(getIsTokenValidClear()),
    doLogoutUser: () => dispatch(logoutUser()),
  };
}
export default withTranslation()(
  withRouterHook(connect(mapStateToProps, mapDispatchToProps)(Checkin))
);
