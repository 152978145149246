import { store } from "../common/store/configureStore";
import { Colors } from "../theme";
import moment from "moment";
import GLOBAL_CONST from "./AppConstants";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Slide, toast } from "react-toastify";

import Theming from "../theme/Theming";

export const isAnnounceTimeStarted = (DismissalTime) => {
  let AnnounceTimeStarted = false;
  const dtime = getDismissalTime(DismissalTime);
  const AnnounceStartTime = new Date(`2023-01-01T${dtime}`);
  const todaysDate = new Date();
  todaysDate.setDate(1);
  todaysDate.setMonth(0);
  todaysDate.setFullYear(2023);
  const LocalDateTime = todaysDate;
  // console.log(
  //   `AnnounceStartTime ${AnnounceStartTime} LocalDateTime ${LocalDateTime}}`
  // );
  // console.log(
  //   `dtime ${dtime} AnnounceStartTime ${AnnounceStartTime} LocalDateTime ${LocalDateTime}`
  // );
  if (AnnounceStartTime <= LocalDateTime) {
    AnnounceTimeStarted = true;
  }
  // console.log(
  //   "******** AnnounceTimeStarted && canAnnounce ",
  //   AnnounceTimeStarted
  // );
  return AnnounceTimeStarted;
};

export const getDismissalTime = (DismissalTime) => {
  const time12h = DismissalTime;
  // Extract hours, minutes, and AM/PM from the input string
  const match = time12h && time12h.match(/(\d+):(\d+)\s*([APMapm]{2})/);

  if (!match) {
    // Invalid input format
    return DismissalTime;
  }

  let hours = parseInt(match[1], 10);
  const minutes = parseInt(match[2], 10);
  const ampm = match[3].toUpperCase();

  if (hours === 12 && ampm === "AM") {
    // Midnight (12:00 AM) should be converted to 00:00 in 24-hour format
    hours = 0;
  } else if (hours !== 12 && ampm === "PM") {
    // Add 12 hours to afternoon/evening times
    hours += 12;
  }

  // Format the result with leading zeros if necessary
  const hours24h = hours.toString().padStart(2, "0");
  const minutes24h = minutes.toString().padStart(2, "0");

  // console.log(`getDismissalTime ${hours24h}:${minutes24h}`);
  return `${hours24h}:${minutes24h}`;
};

export const isAnnounceAllowed = (
  DismissalTime,
  PickupModeAllowsAnnounce,
  Status,
  isLastDayOfSchoolPast
) => {
  if (isLastDayOfSchoolPast && isLastDayOfSchoolPast === true) return false;
  const AnnounceTimeStarted = isAnnounceTimeStarted(DismissalTime);
  let canAnnounce = false;
  let isNotDismissed = true;

  isNotDismissed = Status == 0 || Status == 5;
  canAnnounce = PickupModeAllowsAnnounce;

  return AnnounceTimeStarted && canAnnounce && isNotDismissed;
};
export const isKidAnnounceable = (
  DismissalTime,
  PickupModeAllowsAnnounce,
  isLastDayOfSchoolPast
) => {
  if (isLastDayOfSchoolPast && isLastDayOfSchoolPast === true) return false;
  const AnnounceTimeStarted = isAnnounceTimeStarted(DismissalTime);
  let canAnnounce = false;
  canAnnounce = PickupModeAllowsAnnounce;

  return AnnounceTimeStarted && canAnnounce;
};

export const getRegistrationToken = () => {
  const state = store.getState();
  let RegistrationTokenID = "";

  const { userAccount } = state;
  // console.log(
  //   ` getRegistrationToken userAccount ${JSON.stringify(userAccount)}`
  // );
  if (userAccount) {
    const { registeredResultCache } = userAccount;
    // RegistrationTokenID = registeredResultCache.RegistrationTokenID;
    RegistrationTokenID = registeredResultCache;
  }
  //console.log(` getRegistrationToken ${RegistrationTokenID}`);
  return RegistrationTokenID;
};

export const validateResponse = async (response) => {
  if (response.status === 200) {
    return response.data;
  }

  let msg = "Unable to process request";
  if (typeof response.data === "string") {
    msg = response.data.toString();
  } else if (response.Message) {
    msg = response.Message.toString();
  } else if (response.message) {
    msg = response.message.toString();
  } else if (response.error) {
    msg = response.error.toString();
  } else if (response.data && response.data.error) {
    msg = response.data.error.toString();
  }
  return {
    status: response.status,
    message: msg,
    error: msg,
  };
};

export const isLoggedIn = () => {
  const state = store.getState();
  let AuthTokenID;

  const { userAccount } = state;
  if (userAccount) {
    const { userLoginInfo } = userAccount;
    if (userLoginInfo) {
      AuthTokenID = userLoginInfo.AuthTokenID;
    }
  }
  return AuthTokenID !== null && AuthTokenID !== undefined && AuthTokenID != "";
};

export const isSSOVersion = () => {
  const state = store.getState();
  let isNewSSOVersion = false;

  const { userAccount } = state;
  if (userAccount) {
    const { userLoginInfo } = userAccount;
    if (userLoginInfo) {
      isNewSSOVersion = !userLoginInfo.loginType;
    }
  }
  return isNewSSOVersion;
};

export const getAuthToken = () => {
  const state = store.getState();
  let AuthTokenID = "";

  const { userAccount } = state;
  if (userAccount) {
    const { userLoginInfo } = userAccount;
    if (userLoginInfo) {
      AuthTokenID = userLoginInfo.AuthTokenID;
    }
  }
  return AuthTokenID;
};

export const getMarkStyle = (record, needColor, selectedTheme) => {
  if (
    record.isEarlyDismissal ||
    record.PM_Name === GLOBAL_CONST.PICKMODE_NAME.EARLY_DEPARTURE
  ) {
    return {
      selected: true,
      selectedColor: record.IsApproved === true ? Colors.black : Colors.gray,
      foreGroundColor: record.IsApproved === true ? Colors.white : Colors.black,
      className: record.IsApproved === true ? "pickupBlack" : "pickupGray",
      record,
    };
  }
  if (record.MiscellaneousID !== null) {
    return {
      selected: true,
      selectedColor:
        record.IsApproved === true ? Colors.red : Colors.lightOrange,
      foreGroundColor: record.IsApproved === true ? Colors.white : Colors.black,
      className: record.IsApproved === true ? "pickupRed" : "pickupLightOrange",
      record,
    };
  }
  if (record.Guardian_First !== "" && record.Guardian_First !== null) {
    return {
      selected: true,
      selectedColor:
        record.IsApproved === true ? Colors.green : Colors.lightOrange,
      foreGroundColor: record.IsApproved === true ? Colors.white : Colors.black,
      className:
        record.IsApproved === true ? "pickupGreen" : "pickupLightOrange",
      record,
    };
  }
  if (record.RecordSource !== "DefaultPickupEntry") {
    return {
      selected: true,
      selectedColor:
        record.IsApproved === true ? Colors.green : Colors.lightOrange, // "#7AC943"
      foreGroundColor: record.IsApproved === true ? Colors.white : Colors.black,
      className:
        record.IsApproved === true ? "pickupGreen" : "pickupLightOrange", // "#7AC943"
      record,
    };
  }

  return {
    record,
    // selectedColor: needColor ? Colors.graySubtle : Colors.white,
    selectedColor: needColor
      ? selectedTheme === GLOBAL_CONST.THEME.DARK
        ? Colors.theme_LightDark
        : Colors.graySubtle
      : Colors.white,
    foreGroundColor: Colors.grayLabel,
    className: "pickupPlain",
  };
};

export const isTodayAndAnnounced = (selectedDate, ChildInfo) =>
  moment(selectedDate).isSame(moment(), "date") &&
  (ChildInfo.StatusID === GLOBAL_CONST.PICKUP_STATUS.ANNOUNCED ||
    ChildInfo.StatusID === GLOBAL_CONST.PICKUP_STATUS.DISMISSED);

export const isActionAllowed = (DismissalTime, calendarDate) => {
  let isFuture = moment(calendarDate).isAfter(moment());
  if (isFuture === true) return true;

  let caldt = new Date(calendarDate);
  caldt = moment(caldt).format("MM/DD/YYYY");
  caldt = new Date(`${caldt} ${DismissalTime}`);
  isFuture = moment(caldt).isAfter(moment());
  return isFuture;
};

export const getDismissalInfo = (item, props) => {
  const currentDate = new Date();

  let dismissalText = "";
  let dismissalTextSubText = "";

  const isFuture = moment(item.CalendarDate).isAfter(moment(currentDate));

  if (item.MiscellaneousID !== null) {
    dismissalText = item.PM_Name;
    dismissalTextSubText = item.PM_SubName;
  } else if (item.PM_Name === "After School") {
    dismissalText = item.PM_Name;
    dismissalTextSubText = item.PM_SubName;
    if (item.Guardian_First || item.Guardian_Last) {
      dismissalTextSubText += ` ${item.Guardian_First} ${item.Guardian_Last} (${item.Guardian_Phone
        }) ${props.t("Will_Pickup_Text")}`;
    }
  } else if (item.Guardian_First !== "" && item.Guardian_First !== null) {
    if (isFuture) {
      dismissalText = `${item.Guardian_First} ${item.Guardian_Last} (${item.Guardian_Phone
        }) ${props.t("Will_Pickup_Text")} - ${item.PM_Name}`;
    } else {
      dismissalText = `${item.Guardian_First} ${item.Guardian_Last} (${item.Guardian_Phone
        })  ${props.t("Picked_Up_Text")} - ${item.PM_Name}`;
    }

    dismissalTextSubText = item.PM_SubName;
  } else {
    if (isFuture) {
      if (item.PM_Name === "Bus") {
        dismissalText = `${props.t("Pickup_By_Text")} - ${item.PM_Name}`;
      } else if (item.StatusID > 0) {
        dismissalText = `${props.t("Picked_Up_Text")} - ${item.PM_Name}`;
      } else {
        dismissalText = `${props.t("Pickup_By_Text")} - ${item.PM_Name}`;
      }
    } else if (item.PM_Name === "Bus") {
      dismissalText = `${props.t("Pickup_By_Text")} - ${item.PM_Name}`;
    } else if (item.StatusID > 0) {
      dismissalText = `${props.t("Picked_Up_Text")} - ${item.PM_Name}`;
    } else {
      dismissalText = `${props.t("Pickup_Was_By_Text")} - ${item.PM_Name}`;
    }
    dismissalTextSubText = item.PM_SubName;
  }

  return {
    dismissalText,
    dismissalTextSubText:
      dismissalTextSubText === null ? "" : `-${dismissalTextSubText}`,
  };
};

export const getEarlyDismissalInfo = (item) => {
  const currentDate = new Date();

  let dismissalText = "";
  const dismissalTextSubText = "";

  const isFuture = moment(item.Date).isAfter(moment(currentDate));

  if (item.DelegateName) {
    if (isFuture) {
      dismissalText = `${item.DelegateName}`;
    } else {
      dismissalText = `${item.DelegateName}`;
    }
  } else if (isFuture) {
    dismissalText = `${this.props.t("Pickup_Early_By_Text")}`;
  } else {
    dismissalText = `${this.props.t("Pickup_Early_Was_By_Text")}`;
  }

  return {
    dismissalText,
    dismissalTextSubText:
      dismissalTextSubText === null ? "" : `-${dismissalTextSubText}`,
  };
};

export const filterByTypes = (markedDates) => {
  const pickupRed = [];
  const pickupLightOrange = [];
  const pickupGreen = [];
  const pickupGray = [];
  const pickupBlack = [];

  for (var key in markedDates) {
    if (markedDates[key].className == "pickupRed") {
      pickupRed.push(new Date(key));
    } else if (markedDates[key].className == "pickupLightOrange") {
      pickupLightOrange.push(new Date(key));
    } else if (markedDates[key].className == "pickupGreen") {
      pickupGreen.push(new Date(key));
    } else if (markedDates[key].className == "pickupGray") {
      pickupGray.push(new Date(key));
    } else if (markedDates[key].className == "pickupBlack") {
      pickupBlack.push(new Date(key));
    }
  }
  return {
    pickupRed,
    pickupLightOrange,
    pickupGreen,
    pickupGray,
    pickupBlack,
  };
};

export const getChildStatus = (childInfo) => {
  const lang = getLanguageCode().toUpperCase();
  let status = childInfo[`Status_${lang}`];
  if (!status) status = childInfo.Status_EN;
  return status;
};

export const getLanguageCode = () => {
  let locale = i18next.use(LanguageDetector).language;
  if (locale.indexOf("-") >= 0) {
    locale = locale.split("-");
    if (locale.length > 0) return locale[0];
  }
  return locale;
};

export const validateError = (response) => {
  let msg = "Unable to process request";
  if (response.Message) {
    msg = response.Message.toString();
  } else if (response.message) {
    msg = response.message.toString();
  } else if (response.error) {
    msg = response.error.toString();
  } else if (response.data && response.data.error) {
    msg = response.data.error.toString();
  }

  if (msg === "Network Error") {
    msg = "Please check your network connection.";
  }
  const retRes = {
    message: msg,
    error: msg,
  };

  return retRes;
};

export const getPickupMode = (availableDispatch, selectedItem) => {
  let pickupString = "";
  let pickupMode = [];
  let selectedId = 0;

  if (availableDispatch.StudentDismissalMethod) {
    const student = availableDispatch.StudentDismissalMethod.filter(
      (item) => item.StudentID === selectedItem.StudentID
    );
    if (student && student.length > 0)
      pickupMode = student[0].Modes.filter(
        (item) => item.PickupModeID === selectedItem.DefaultPickupModeID
      );
  }
  if (availableDispatch.MethodsAvailableToEveryone) {
    if (pickupMode.length === 0)
      pickupMode = availableDispatch.MethodsAvailableToEveryone.filter(
        (item) => item.PickupModeID === selectedItem.DefaultPickupModeID
      );
  }

  if (pickupMode.length > 0) {
    const mode = pickupMode[0];
    if (mode.PickupModeID == 4) {
      pickupString = mode.Name + " " + selectedItem.DefaultInstituteName;
    } else if (mode.PickupModeID == 1) {
      pickupString = mode.Name + " " + selectedItem.DefaultBusRoute;
    } else {
      pickupString = mode.Name;
    }
  }
  return pickupString;
};

export const getModifiedPickupMode = (
  availableDispatch,
  selectedItem,
  props
) => {
  let pickupString = "";
  let pickupMode = [];

  if (availableDispatch.StudentDismissalMethod) {
    const student = availableDispatch.StudentDismissalMethod.filter(
      (item) => item.StudentID === selectedItem.StudentID
    );
    if (student && student.length > 0)
      pickupMode = student[0].Modes.filter(
        (item) => item.PickupModeID === selectedItem.fk_pickmodeid
      );
  }
  if (availableDispatch.MethodsAvailableToEveryone) {
    if (pickupMode.length === 0)
      pickupMode = availableDispatch.MethodsAvailableToEveryone.filter(
        (item) => item.PickupModeID === selectedItem.fk_pickmodeid
      );
  }

  if (pickupMode.length > 0) {
    const mode = pickupMode[0];
    let subPickupMode;
    if (mode.SubOptions)
      subPickupMode = mode.SubOptions.filter(
        (item) => item.ID === selectedItem.fk_institutionid
      );

    let subString = "";
    if (subPickupMode && subPickupMode.length > 0)
      subString = subPickupMode[0].Name;
    else if (selectedItem.ChangedToInstitutionName)
      subString = selectedItem.ChangedToInstitutionName;
    if (mode.PickupModeID == 4) {
      pickupString = mode.Name + " " + subString;
    } else if (mode.PickupModeID == 1) {
      pickupString = mode.Name + " " + subString;
    } else {
      if (selectedItem.guardianfirstname) {
        pickupString = `${selectedItem.guardianfirstname} ${selectedItem.guardianlastname
          } (${selectedItem.guardianphonenumber}) ${props.t(
            "Will_Pickup_Text"
          )} - ${mode.Name}`;
        // pickupString = mode.Name
      } else {
        pickupString = mode.Name;
      }
    }
  }
  return pickupString;
};

export const getSiteAccessPermission = async () => {
  try {
    const result = await navigator.permissions.query({ name: "geolocation" });
    if (result && result.state) return result.state;
    else return "prompt";
  } catch (ex) {
    console.error(ex);
  }
  return "prompt";
};

export const showLocationError = (msg) => {
  toast.error(msg, {
    autoClose: 2000,
    transition: Slide,
    hideProgressBar: true,
    icon: false,
  });
};

export const deg2rad = (deg) => deg * (Math.PI / 180);

export const distance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  const df = parseFloat(d) * 1093.61;
  return df;
};

export const nextField = (event) => {
  const form = event.target.form;
  const index = Array.prototype.indexOf.call(form, event.target);
  if (event.key === "Enter") {
    form.elements[index + 1].focus();
  }
};

export const formatPhone = function (phone) {
  const AllowedCharacters = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "(",
    ")",
    "-",
    "+",
  ];
  const tempStr = phone.trim().split(""); // Creates array
  for (let i = phone.length; i >= 0; i--) {
    if (AllowedCharacters.indexOf(tempStr[i]) < 0) {
      // Plus Symbol not included in allowed characters,  but it is allowed in position 0
      if (i > 0 || (i == 0 && tempStr[i] != "+")) {
        tempStr.splice(i, 1);
      }
    }
  }
  return tempStr.join("");
};

export const parseDaytime = (time) => {
  let [hours, minutes] = time
    .substr(0, time.length - 2)
    .split(":")
    .map(Number);
  if (time.toLowerCase().includes("pm") && hours !== 12) hours += 12;
  return [hours, minutes];
};

export const isEarlyDismissalAvailable = (
  school,
  selectedDate,
  pickedTime,
  props
) => {
  const response = {
    success: false,
    message: "",
  };
  const dayNo = new Date(selectedDate).getDay();

  const { EarlyDismissalSettings } = school;
  if (
    EarlyDismissalSettings &&
    EarlyDismissalSettings.AllowParentSameDayScheduling === false &&
    moment(selectedDate).isSame(moment(), "date")
  ) {
    response.message = `${props.t("early_dismissal_allow_today_message")}`;
    return response;
  }
  const records =
    EarlyDismissalSettings &&
    EarlyDismissalSettings.EarlyDismissalTimeTable.filter(
      (item) =>
        item.Day.toLowerCase() ===
        moment(selectedDate).format("dddd").toLowerCase()
    );

  if (records && records.length === 0) {
    response.success = true;
    return response;
  }
  const scheduleRecord = records && records[0];

  if (
    scheduleRecord &&
    scheduleRecord.Active === false &&
    moment(selectedDate).isSame(moment(), "date")
  ) {
    response.message = `${props.t("early_dismissal_allow_today_message")}`;
    return response;
  }
  const dayText =
    getLanguageCode() === "es"
      ? GLOBAL_CONST.DAYS_FULL_ES[dayNo]
      : GLOBAL_CONST.DAYS_FULL_EN[dayNo];
  if (scheduleRecord && scheduleRecord.Active === false) {
    let msg = `${props.t("early_dismissal_allow_inActive_message")}`;
    msg = msg.replace("#Day#", dayText);
    response.message = msg;
    return response;
  }
  let StartTime = new Date(`1/1/2015 ${scheduleRecord.StartTime}`);
  let EndTime = new Date(`1/1/2015 ${scheduleRecord.EndTime}`);
  StartTime = moment(StartTime).format("hh:mm A");
  EndTime = moment(EndTime).format("hh:mm A");
  let pickTime = moment(pickedTime).format("hh:mm A");
  const compareDate = moment(pickTime, "hh:mm A");
  const startDate = moment(StartTime, "hh:mm A");
  const endDate = moment(EndTime, "hh:mm A");

  if (
    compareDate.isBetween(startDate, endDate) ||
    compareDate.isSame(startDate) ||
    compareDate.isSame(endDate)
  ) {
    response.success = true;
    return response;
  }

  let msg = `${props.t("early_dismissal_schedule_warning")}`;
  msg = msg.replace("#Day#", dayText);
  msg = msg.replace("#starttime#", moment(startDate).format("hh:mm A"));
  msg = msg.replace("#endtime#", moment(endDate).format("hh:mm A"));
  response.message = msg;
  return response;
};

export const canEarlyDismissToday = (school) => {
  const { EarlyDismissalSettings } = school;
  if (school.ReunificationEnabled) return false;

  if (!EarlyDismissalSettings) return false;
  if (EarlyDismissalSettings.AllowParentEarlyDismissals === false) return false;
  const timeTable = school.EarlyDismissalSettings.EarlyDismissalTimeTable;
  const daySelectedIndex = moment(new Date()).format("d");
  const todayRecord = timeTable[daySelectedIndex];
  if (!todayRecord || !todayRecord.Active) return false;
  return EarlyDismissalSettings.AllowParentSameDayScheduling;
};

export const earlyDismissNeedPassword = (school) => {
  const { EarlyDismissalSettings } = school;
  if (!EarlyDismissalSettings) return false;
  if (EarlyDismissalSettings.RequireParentPasswordForEarlyDismissal === false)
    return false;
  return EarlyDismissalSettings.RequireParentPasswordForEarlyDismissal;
};

export const getEarlyDismissalTime = (item) => {
  let caldt = new Date(item.Date);
  caldt = moment(caldt).format("MM/DD/YYYY");
  caldt = new Date(`${caldt} ${item.Time}`);
  return moment(caldt).format("hh:mm A");
};
export const toTitleCase = (value) => {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
};
export const createImageFileURL = (imageFile) => {
  return URL.createObjectURL(imageFile);
};

export const checkIsEmergency = (delegate) => {
  if (
    delegate !== null &&
    delegate !== undefined &&
    delegate.Students !== null &&
    delegate.Students !== undefined &&
    delegate.Students.length > 0
  ) {
    return delegate.Students.find((item) => item.IsEmergency);
  }

  return false;
};

export const isEarlyDismissalAvailableForParent = (school) => {
  const { EarlyDismissalSettings } = school;
  if (!EarlyDismissalSettings) return false;
  if (EarlyDismissalSettings.AllowParentEarlyDismissals === false) return false;
  return true;
};

export const getCalendarStyle = (record) => ({
  selected: true,
  selectedColor: record.EventColor,
  foreGroundColor: record.EventColor,
  record,
  dots: [],
  className: record.EventColor,
});

export const createPassword = () => {
  var passAt =
    "abcdefghijklmnopqrstuvwxyz$@!^ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var passArray = Array.from({ length: 15 });

  return passArray
    .map(function (_, index) {
      return index % 4 === 3
        ? "-"
        : passAt.charAt(Math.random() * passAt.length);
    })
    .join("");
};

export const isSSOLogin = () => {
  const state = store.getState();
  const { userAccount } = state;
  return (
    userAccount.loginType === GLOBAL_CONST.LOGIN_TYPE.APPLE_LOGIN ||
    userAccount.loginType === GLOBAL_CONST.LOGIN_TYPE.GOOGLE_LOGIN
  );
};
export const getKidsName = (selectedKids) => {
  return selectedKids
    .map((item) => item.Firstname + " " + item.Lastname)
    .join(", ");
};

export const getCurrentThemeColor = (selectedTheme1) => {
  if (selectedTheme1 === GLOBAL_CONST.THEME.DARK) {
    return Theming.dark.colors;
  } else if (selectedTheme1 === GLOBAL_CONST.THEME.LIGHT) {
    return Theming.light.colors;
  } else {
    return Theming.light.colors;
  }
};

export const isVisibleSelectMultikids = (record) => {
  let iskidsList = false;

  for (var i = 0; i < record.length; i++) {
    const { data, school } = record[i];

    if (data.length > 1) {
      iskidsList = true;
    }
  }
  return iskidsList;
};

export const getAppleRedirectURL = (window) => {
  if (typeof window !== 'undefined' && window.location) {
    const currentUrl = window.location.href;
    const parsedUrl = new URL(currentUrl);
    const domain = parsedUrl.hostname;
    const redirectURI = `https://${domain}/appleLogin`;
    //console.log('redirectURI ',redirectURI)
    return redirectURI;
  }else{
    return process.env.PUBLIC_URL+ '/appleLogin'
  }
};
