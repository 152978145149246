// get parent advert list
export const GET_ADVERT_LIST_START = 'get_advert_list_start';
export const GET_ADVERT_LIST_SUCCESS = 'get_advert_list_success';
export const GET_ADVERT_LIST_FAILED  = 'get_advert_list_failed';
export const GET_ADVERT_LIST_CLEAR  = 'get_advert_list_clear';

// get log advert serve
export const GET_ADVERT_SERVE_START = 'get_advert_serve_start';
export const GET_ADVERT_SERVE_SUCCESS = 'get_advert_serve_success';
export const GET_ADVERT_SERVE_FAILED  = 'get_advert_serve_failed';
export const GET_ADVERT_SERVE_CLEAR  = 'get_advert_serve_clear';

// get log advert click
export const GET_ADVERT_CLICK_START = 'get_advert_click_start';
export const GET_ADVERT_CLICK_SUCCESS = 'get_advert_click_success';
export const GET_ADVERT_CLICK_FAILED  = 'get_advert_click_failed';
export const GET_ADVERT_CLICK_CLEAR  = 'get_advert_click_clear';

//  get parent advert photo
export const GET_ADVERT_PHOTO_START = 'get_advert_photo_start';
export const GET_ADVERT_PHOTO_SUCCESS = 'get_advert_photo_success';
export const GET_ADVERT_PHOTO_FAILED  = 'get_advert_photo_failed';
export const GET_ADVERT_PHOTO_CLEAR  = 'get_advert_photo_clear';

//  get parent advert photo
export const ADVERT_HIDE = 'advert_hide';

