import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  setCroppedImage,
  setCroppedImageClear,
  showModal,
  hideModal,
} from "../../common/actions/userAccount";
import Webcam from "react-webcam";
const videoConstraints = {
  //   width: 514,
  //   height: 514,
  facingMode: "user",
};
class CameraLauncher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.webCamera = React.createRef();
  }

  dataUrlToFile = async (dataUrl) => {
    var filename = new Date().getTime();
    try {
      const result = await fetch(dataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const captureResponse = new File([blob], filename, {
            type: "image/jpeg",
          });
          return captureResponse;
        });
      if (result) return result;
      else return {};
    } catch (error) {
      console.error(error);
    }
    return {};
  };

  render() {
    const { doSetCroppedImage, doCallbackRefresh, doHideModal } = this.props;
    return (
      <div className="modal-dialog ">
        <div className="modal-content">
          <div className="webcamera-holder">
            <button
              className="close-camera-btn"
              type="button"
              onClick={() => doHideModal()}
              aria-label={this.props.t("Close_Button")}
            >
              <i className="fas fa-times"></i>
            </button>
            <Webcam
              audio={false}
              screenshotFormat="image/jpeg"
              //   width={514}
              //   height={514}
              videoConstraints={videoConstraints}
            >
              {({ getScreenshot }) => (
                <button
                  className="takephoto btn btn-default btn-block"
                  onClick={async () => {
                    const response = getScreenshot();
                    let resultFile = null;
                    if (
                      typeof response === "string" ||
                      response instanceof String
                    ) {
                      resultFile = await this.dataUrlToFile(response);
                    }
                    doSetCroppedImage({
                      croppedImageData: null,
                      showImageCrop: true,
                    });
                    doHideModal();
                    doCallbackRefresh(resultFile);
                  }}
                >
                  {this.props.t("editChildScr-takePhoto")}
                </button>
              )}
            </Webcam>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doSetCroppedImage: (data) => dispatch(setCroppedImage(data)),
    doSetCroppedImageClear: () => dispatch(setCroppedImageClear()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CameraLauncher)
);
