import {
  CHECK_EMAIL_START,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILED,
  CHECK_EMAIL_CLEAR,
  VERIFIED_EMAIL_START,
  VERIFIED_EMAIL_SUCCESS,
  VERIFIED_EMAIL_FAILED,
  VERIFIED_EMAIL_CLEAR,
  ADD_PHONE_NUMBER_USER_START,
  ADD_PHONE_NUMBER_USER_SUCCESS,
  ADD_PHONE_NUMBER_USER_FAILED,
  ADD_PHONE_NUMBER_USER_CLEAR,
  RE_SEND_EMAIL_START,
  RE_SEND_EMAIL_SUCCESS,
  RE_SEND_EMAIL_FAILED,
  RE_SEND_EMAIL_CLEAR,
  SAVE_REGISTRATION_INFO,
  SAVE_REGISTRATION_CLEAR,
  VERIFY_OTP_START,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_CLEAR,
  RE_SEND_OTP_START,
  RE_SEND_OTP_SUCCESS,
  RE_SEND_OTP_FAILED,
  RE_SEND_OTP_CLEAR,
  GET_USER_INFO_START,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILED,
  GET_USER_INFO_CLEAR,
  COMPLETE_REGISTRATION_USER_START,
  COMPLETE_REGISTRATION_USER_SUCCESS,
  COMPLETE_REGISTRATION_USER_FAILED,
  COMPLETE_REGISTRATION_USER_CLEAR,
  START_REGISTARTION_START,
  START_REGISTARTION_SUCCESS,
  START_REGISTARTION_FAILED,
  START_REGISTARTION_CLEAR,
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGIN_USER_CLEAR,
  COUNTRY_CODE_LIST_START,
  COUNTRY_CODE_LIST_SUCCESS,
  COUNTRY_CODE_LIST_FAILED,
  LOGIN_REGISTERED_USER,
  LOGOUT_USER,
  CHANGE_LOCALE_START,
  CHANGE_LOCALE_SUCCESS,
  CHANGE_LOCALE_FAILED,
  UPDATE_NAME_START,
  UPDATE_NAME_SUCCESS,
  UPDATE_NAME_FAILED,
  UPDATE_NAME_CLEAR,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_CLEAR,
  RAGISTRATION_VALID_START,
  RAGISTRATION_VALID_SUCCESS,
  RAGISTRATION_VALID_FAILED,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_CLEAR,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILED,
  GET_NOTIFICATIONS_CLEAR,
  SUBMIT_FEEDBACK_START,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILED,
  SUBMIT_FEEDBACK_CLEAR,
  GET_DELEGATES_START,
  GET_DELEGATES_SUCCESS,
  GET_DELEGATES_FAILED,
  GET_DELEGATES_CLEAR,
  APPROVED_DELEGATES_START,
  APPROVED_DELEGATES_SUCCESS,
  APPROVED_DELEGATES_FAILED,
  APPROVED_DELEGATES_CLEAR,
  APPROVED_DELEGATES_CHANGE_START,
  APPROVED_DELEGATES_CHANGE_SUCCESS,
  APPROVED_DELEGATES_CHANGE_FAILED,
  APPROVED_DELEGATES_CHANGE_CLEAR,
  DELEGATES_SET_DELETED_START,
  DELEGATES_SET_DELETED_SUCCESS,
  DELEGATES_SET_DELETED_FAILED,
  DELEGATES_SET_DELETED_CLEAR,
  UPDATE_DEVICE_TOKEN_START,
  UPDATE_DEVICE_TOKEN_SUCCESS,
  UPDATE_DEVICE_TOKEN_FAILED,
  UPDATE_DEVICE_TOKEN_CLEAR,
  GET_TOKEN_VALID_START,
  GET_TOKEN_VALID_SUCCESS,
  GET_TOKEN_VALID_FAILED,
  GET_TOKEN_VALID_CLEAR,
  GET_HELP_TOPICS_START,
  GET_HELP_TOPICS_SUCCESS,
  GET_HELP_TOPICS_FAILED,
  SEND_SUPPORT_REQUEST_START,
  SEND_SUPPORT_REQUEST_SUCCESS,
  SEND_SUPPORT_REQUEST_FAILED,
  SEND_SUPPORT_REQUEST_CLEAR,
  GET_NOTIFICATION_SETTING_START,
  GET_NOTIFICATION_SETTING_SUCCESS,
  GET_NOTIFICATION_SETTING_FAILED,
  GET_NOTIFICATION_SETTING_CLEAR,
  UPDATE_NOTIFICATION_SETTING_START,
  UPDATE_NOTIFICATION_SETTING_SUCCESS,
  UPDATE_NOTIFICATION_SETTING_FAILED,
  UPDATE_NOTIFICATION_SETTING_CLEAR,
  LOG_ERRORS_BROWSER_START,
  LOG_ERRORS_BROWSER_SUCCESS,
  LOG_ERRORS_BROWSER_FAILED,
  LOG_ERRORS_BROWSER_CLEAR,
  LOG_ERRORS_FILE_START,
  LOG_ERRORS_FILE_SUCCESS,
  LOG_ERRORS_FILE_FAILED,
  LOG_ERRORS_FILE_CLEAR,
  CONNECT_STUDENT_START,
  CONNECT_STUDENT_SUCCESS,
  CONNECT_STUDENT_FAILED,
  CONNECT_STUDENT_CLEAR,
  SET_ENVIRONMENT,
  SAVE_LAST_USED_TIMESTAMP,
  SAVE_LOCATION_PERMISSION,
  GET_FUTURE_ENTRIES_START,
  GET_FUTURE_ENTRIES_SUCCESS,
  GET_FUTURE_ENTRIES_FAILURE,
  TOGGLE_MODAL,
  HIDE_MODAL,
  TOGGLE_CHECKIN_QUEST_ANS,
  SET_HELPLIST,
  SET_OLD_HELPLIST,
  GET_FUTURE_ENTRIES_CLEAR,
  SAVE_PHOTO,
  SAVE_ALL_PHOTOS,
  UPLOAD_DELEGATE_IMAGE_START,
  UPLOAD_DELEGATE_IMAGE_SUCCESS,
  UPLOAD_DELEGATE_IMAGE_FAILED,
  REQUEST_MANUAL_REVIEW_START,
  REQUEST_MANUAL_REVIEW_SUCCESS,
  REQUEST_MANUAL_REVIEW_FAILED,
  CANCEL_MANUAL_REVIEW_START,
  CANCEL_MANUAL_REVIEW_SUCCESS,
  CANCEL_MANUAL_REVIEW_FAILED,
  UPLOAD_DELEGATE_IMAGE_CLEAR,
  REQUEST_MANUAL_REVIEW_CLEAR,
  CANCEL_MANUAL_REVIEW_CLEAR,
  GET_DELEGATE_START,
  GET_DELEGATE_SUCCESS,
  GET_DELEGATE_FAILED,
  GET_DELEGATE_CLEAR,
  ACCOUNT_DELETE_START,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_DELETE_FAILED,
  ACCOUNT_DELETE_CLEAR,
  SAVE_TERMS_ACCEPTED,
  ASK_ME_LATER_SAVE,
  RESET_USER_LOCATION,
  BACKTOSCHOOL_DATA_FILLED,
  UPLOAD_USER_IMAGE_SUCCESS,
  UPLOAD_USER_IMAGE_FAILED,
  UPLOAD_USER_IMAGE_CLEAR,
  UPLOAD_USER_IMAGE_START,
  USER_REQUEST_MANUAL_REVIEW_START,
  USER_REQUEST_MANUAL_REVIEW_SUCCESS,
  USER_REQUEST_MANUAL_REVIEW_FAILED,
  USER_REQUEST_MANUAL_REVIEW_CLEAR,
  USER_CANCEL_MANUAL_REVIEW_START,
  USER_CANCEL_MANUAL_REVIEW_SUCCESS,
  USER_CANCEL_MANUAL_REVIEW_FAILED,
  USER_CANCEL_MANUAL_REVIEW_CLEAR,
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILED,
  MARK_READY_TO_DASHBOARD,
  SET_CROPPED_IMAGE,
  SET_CROPPED_IMAGE_CLEAR,
  SET_LOGIN_TYPE,
  SSO_USER_SEND_OTP_START,
  SSO_USER_SEND_OTP_SUCCESS,
  SSO_USER_SEND_OTP_FAILED,
  SSO_USER_SEND_OTP_CLEAR,
  SSO_USER_DELETE_START,
  SSO_USER_DELETE_SUCCESS,
  SSO_USER_DELETE_FAILED,
  SSO_USER_DELETE_CLEAR,
  SSO_USER_SEND_OTP_TRANSACTION_FAILED,
  SSO_USER_SEND_OTP_TRANSACTION_START,
  SSO_USER_SEND_OTP_TRANSACTION_SUCCESS,
  SSO_USER_SEND_OTP_TRANSACTION_CLEAR,
  SEND_WELCOME,
  SET_GOOGLE_LOGIN_CREDENTIALS,
  SET_GOOGLE_LOGIN_CREDENTIALS_CLEAR,
  SET_APPLE_LOGIN_CREDENTIALS,
  SET_APPLE_LOGIN_CREDENTIALS_CLEAR,
  SAVE_LOGIN_CREDENTIALS,
  SAVE_LOGIN_CREDENTIALS_CLEAR,
  GET_GEOINFO_START,
  GET_GEOINFO_SUCCESS,
  GET_GEOINFO_FAILED,
  SAVE_HELP_MESSAGE1,
  SAVE_HELP_MESSAGE2,
  SAVE_HELP_MESSAGE3,
  SAVE_THEME,
} from "./userActionType";

import {
  fetchEta,
  startRegistration,
  checkEmail,
  getIsEmailVerified,
  addPhoneNumber,
  ResendEmailVerificationMail,
  verifyOtp,
  getUserInfo,
  registerUser,
  userLogin,
  getCountryCodesListing,
  ChangeLocale,
  updateName,
  forgotPassword,
  registrationValid,
  updatePassword,
  getDelagates,
  upsertApprovedDelegate,
  approvedDelegateChange,
  delegateSetDeleted,
  getNotifications,
  submitFeedback,
  updateDeviceToken,
  isTokenValid,
  getHelpTopics,
  sendFeedbackWithChild,
  getNotificationSettings,
  updateParentNotificationSettings,
  logErrorsBrowser,
  fileLogErrors,
  connectStudent,
  getFutureCalendarEntriesByParent,
  uploadDelegateImage,
  RequestManualReviewImage,
  CancelManualReviewImage,
  getDelegate,
  deleteAccount,
  uploadUserImage,
  userRequestManualReviewImage,
  getUserProfile,
  userCancelManualReviewImage,
  deleteSSO_UserSendOTP,
  ssoUserDelete,
  SSO_User_generateOTPForTransaction,
  sendWelcomeEmail,
  getGeoInfo,
} from "../../services";
import { validateError } from "../../../helper/utility";

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/registraton/StartRegistration
 * Purpose: start registration
 */
export const startUserRegistration = (params) => (dispatch) => {
  dispatch({ type: START_REGISTARTION_START });
  // console.log(`check registration parameters ${JSON.stringify(params)}`);
  return startRegistration(params)
    .then((response) => {
      // console.log(`check registration response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: START_REGISTARTION_SUCCESS, payload: response.Data });
      else {
        dispatch({ type: START_REGISTARTION_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`check registration error ${JSON.stringify(error)}`);
      dispatch({ type: START_REGISTARTION_FAILED, payload: error });
    });
};

/**
 * Purpose: start registartion clear
 */
export const startRegistrationClear = () => (dispatch) => {
  dispatch({ type: START_REGISTARTION_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/registraton/checkEmail
 * Purpose: check User Email Address
 */
export const checkUserEmail =
  ({ Email, locale }) =>
  (dispatch) => {
    dispatch({ type: CHECK_EMAIL_START });
    // console.log(`check Email parameters ${Email}  language ${locale} `);
    return checkEmail({ Email, locale })
      .then((response) => {
        // console.log(`check Email${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: CHECK_EMAIL_SUCCESS, payload: response });
        else {
          dispatch({ type: CHECK_EMAIL_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`check Email ${JSON.stringify(error)}`);
        dispatch({ type: CHECK_EMAIL_FAILED, payload: error });
      });
  };

/**
 * Purpose: Clear user Check email
 */
export const checkUserEmailClear = () => (dispatch) => {
  dispatch({ type: CHECK_EMAIL_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/Registration/is_email_confirmed
 * Purpose: Verification User Email Address
 */
export const verifiedUserEmail =
  ({ Email }) =>
  (dispatch) => {
    dispatch({ type: VERIFIED_EMAIL_START });

    return getIsEmailVerified({ Email })
      .then((response) => {
        // console.log(`verfied Email response ${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: VERIFIED_EMAIL_SUCCESS, payload: response });
        else {
          dispatch({ type: VERIFIED_EMAIL_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`verfied Email error ${JSON.stringify(error)}`);
        dispatch({ type: VERIFIED_EMAIL_FAILED, payload: error });
      });
  };

/**
 * Purpose: Clear user verification email
 */
export const verifiedUserEmailClear = () => (dispatch) => {
  dispatch({ type: VERIFIED_EMAIL_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/Registration/UpdatePhone
 * Purpose: add phone number
 */
export const addUserPhoneNumber = (params) => (dispatch) => {
  dispatch({ type: ADD_PHONE_NUMBER_USER_START });
  // console.log(`phone number parameters new ${JSON.stringify(params)} `);
  return addPhoneNumber(params)
    .then((response) => {
      // console.log(`phone number response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: ADD_PHONE_NUMBER_USER_SUCCESS, payload: response });
      else {
        dispatch({ type: ADD_PHONE_NUMBER_USER_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`phone number error ${JSON.stringify(error)}`);
      dispatch({ type: ADD_PHONE_NUMBER_USER_FAILED, payload: error });
    });
};

/**
 * Purpose: add user phone number
 */
export const userPhoneNumberClear = () => (dispatch) => {
  dispatch({ type: ADD_PHONE_NUMBER_USER_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/resend_email
 * Purpose: re send link to email address
 */
export const reSendEmailUser =
  ({ Email }) =>
  (dispatch) => {
    dispatch({ type: RE_SEND_EMAIL_START });
    return ResendEmailVerificationMail({ Email })
      .then((response) => {
        // console.log(`resend Email response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: RE_SEND_EMAIL_SUCCESS, payload: response });
        else {
          dispatch({ type: RE_SEND_EMAIL_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`resend Email error ${JSON.stringify(error)}`);
        dispatch({ type: RE_SEND_EMAIL_FAILED, payload: error });
      });
  };

/**
 * Purpose: clear resend email
 */
export const reSendEmailClear = () => (dispatch) => {
  dispatch({ type: RE_SEND_EMAIL_CLEAR });
};

export const saveRegistrationInfo =
  ({
    registeredEmail,
    registeredPassword,
    registeredFirstname,
    registeredLastname,
    registeredPhone,
    registeredCountryCode,
    registeredCountryName,
    registartionResponse,
    verifiedEmail,
    isSSO,
  }) =>
  (dispatch) => {
    dispatch({
      type: SAVE_REGISTRATION_INFO,
      payload: {
        registeredEmail,
        registeredPassword,
        registeredFirstname,
        registeredLastname,
        registeredPhone,
        registeredCountryCode,
        registeredCountryName,
        registartionResponse,
        verifiedEmail,
        isSSO,
      },
    });
  };

export const clearRegistrationInfo = () => (dispatch) => {
  dispatch({ type: SAVE_REGISTRATION_CLEAR });

  dispatch({ type: SAVE_REGISTRATION_CLEAR, payload: "" });
  setAppleLoginCredentialsClear();
  setGoogleLoginCredentialsClear();
  saveLoginCredentialsClear();
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/verify_otp
 * Purpose: verify otp
 */
export const verifyUserOtp =
  ({ OTPCode }) =>
  (dispatch) => {
    dispatch({ type: VERIFY_OTP_START });
    // console.log(`OTP code ${OTPCode} `);
    return verifyOtp({ OTPCode })
      .then((response) => {
        // console.log(`verify OTP response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: VERIFY_OTP_SUCCESS, payload: response });
        else {
          dispatch({ type: VERIFY_OTP_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`verify OTP error ${JSON.stringify(error)}`);
        dispatch({ type: VERIFY_OTP_FAILED, payload: error });
      });
  };

/**
 * Purpose: clear verify user otp
 */
export const verifyUserOtpClear = () => (dispatch) => {
  dispatch({ type: VERIFY_OTP_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/resend_otp
 * Purpose: Resend Otp
 */
export const resendOTP = (params) => (dispatch) => {
  dispatch({ type: RE_SEND_OTP_START });

  return addPhoneNumber(params)
    .then((response) => {
      // console.log(`resend otp response${JSON.stringify(response)}`);
      if (response) dispatch({ type: RE_SEND_OTP_SUCCESS, payload: response });
      else {
        dispatch({ type: RE_SEND_OTP_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`resend otp error ${JSON.stringify(error)}`);
      dispatch({ type: RE_SEND_OTP_FAILED, payload: error });
    });
};

/**
 * Purpose: Resend otp clear
 */
export const resendOTPClear = () => (dispatch) => {
  dispatch({ type: RE_SEND_OTP_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/getUserInfo
 * Purpose: get user information
 */
export const getUserInformation = () => (dispatch) => {
  dispatch({ type: GET_USER_INFO_START });

  return getUserInfo()
    .then((response) => {
      // console.log(`user information response new ${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: GET_USER_INFO_SUCCESS, payload: response });
      else {
        dispatch({ type: GET_USER_INFO_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`user information error ${JSON.stringify(error)}`);
      dispatch({ type: GET_USER_INFO_FAILED, payload: error });
    });
};

/**
 * Purpose: get user information clear
 */
export const getUserInfoClear = () => (dispatch) => {
  dispatch({ type: GET_USER_INFO_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/registerUser
 * Purpose: Get register user details
 */
export const completeRegisterUser =
  ({ Password }) =>
  (dispatch) => {
    dispatch({ type: COMPLETE_REGISTRATION_USER_START });

    return registerUser({ Password })
      .then((response) => {
        // console.log(`get register user response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: COMPLETE_REGISTRATION_USER_SUCCESS,
            payload: response.Data,
          });
        else {
          dispatch({
            type: COMPLETE_REGISTRATION_USER_FAILED,
            payload: response,
          });
        }
      })
      .catch((error) => {
        // console.log(`get register user error ${JSON.stringify(error)}`);
        dispatch({ type: COMPLETE_REGISTRATION_USER_FAILED, payload: error });
      });
  };

/**
 * Purpose: get register user clear
 */
export const completeRegisterUserClear = () => (dispatch) => {
  dispatch({ type: COMPLETE_REGISTRATION_USER_CLEAR });
};

export const loginRegisteredUser = (payload) => (dispatch) => {
  dispatch({ type: LOGIN_REGISTERED_USER, payload });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/loginUser
 * Purpose:loginUser
 */
export const loginUser =
  ({ email, phoneNumber, useEmail, password, locale, callback }) =>
  (dispatch) => {
    dispatch({ type: LOGIN_USER_START });
    // console.log(
    //   `get user login email ${email} password ${password} locale ${locale}`
    // );
    return userLogin({
      email,
      password,
      locale,
      phoneNumber,
      useEmail,
    })
      .then((response) => {
        //console.log(`get user login response${JSON.stringify(response)}`);
        if (response.Success) {
          dispatch({ type: LOGIN_USER_SUCCESS, payload: response.Data });
        } else {
          dispatch({ type: LOGIN_USER_FAILED, payload: response });
        }
        if (callback) callback(response);
      })
      .catch((error) => {
        //console.log(`get user login error ${error}`);
        dispatch({ type: LOGIN_USER_FAILED, payload: validateError(error) });
        if (callback) callback({ Success: false });
      });
  };

/**
 * Purpose: get register user clear
 */
export const loginUserClear = () => (dispatch) => {
  dispatch({ type: LOGIN_USER_CLEAR });
};

export const getCountryCodes = (callback) => (dispatch) => {
  dispatch({ type: COUNTRY_CODE_LIST_START });

  return getCountryCodesListing()
    .then((response) => {
      // console.log(`getCountryCodesListing response ${JSON.stringify(response)}`);
      if (response.Success) {
        const countryList = [];
        for (var i = 0; i < response.Data.length; i++) {
          const element = response.Data[i];
          countryList.push({
            CountryId: element.CountryId,
            CountryName: element.CountryName,
            CountryCode: element.CountryCode,
            label: element.CountryName + "(" + element.CountryCode + ")",
          });
        }
        dispatch({ type: COUNTRY_CODE_LIST_SUCCESS, payload: countryList });
        if (callback) callback();
      } else {
        dispatch({ type: COUNTRY_CODE_LIST_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`getCountryCodesListing error ${JSON.stringify(error)}`);
      dispatch({ type: COUNTRY_CODE_LIST_FAILED, payload: error });
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch({ type: LOGOUT_USER });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/ChangeLocale
 * Purpose:ChangeLocale
 */
export const localeChange =
  ({ locale }) =>
  (dispatch) => {
    dispatch({ type: CHANGE_LOCALE_START });

    return ChangeLocale({ locale })
      .then((response) => {
        // console.log(`change language response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: CHANGE_LOCALE_SUCCESS, payload: response.Data });
        else {
          dispatch({ type: CHANGE_LOCALE_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`change language  error ${JSON.stringify(error)}`);
        dispatch({ type: CHANGE_LOCALE_FAILED, payload: error });
      });
  };

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/registraton/updateName
 * Purpose: updateName
 */
export const nameUpdate =
  ({ firstName, lastName }) =>
  (dispatch) => {
    dispatch({ type: UPDATE_NAME_START });
    return updateName({ firstName, lastName })
      .then((response) => {
        // console.log(`update name response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: UPDATE_NAME_SUCCESS,
            payload: { response, firstName, lastName },
          });
        else {
          dispatch({ type: UPDATE_NAME_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`update name error ${JSON.stringify(error)}`);
        dispatch({ type: UPDATE_NAME_FAILED, payload: error });
      });
  };

export const nameUpdateClear = () => (dispatch) => {
  dispatch({ type: UPDATE_NAME_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/registraton/forgotPassword
 * Purpose: forgotPassword
 */
export const userForgotPassword =
  ({ email, locale }) =>
  (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_START });
    return forgotPassword({ email, locale })
      .then((response) => {
        // console.log(`forgotPassword response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: response });
        else {
          dispatch({ type: FORGOT_PASSWORD_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`forgotPassword error ${JSON.stringify(error)}`);
        dispatch({ type: FORGOT_PASSWORD_FAILED, payload: error });
      });
  };

export const userForgotPasswordClear = () => (dispatch) => {
  dispatch({ type: FORGOT_PASSWORD_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/getRegistrationValid
 * Purpose: getRegistrationValid
 */
export const getRegistrationValid = () => (dispatch) => {
  dispatch({ type: RAGISTRATION_VALID_START });

  return registrationValid()
    .then((response) => {
      // console.log(`registration valid response ${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: RAGISTRATION_VALID_SUCCESS, payload: response });
      else {
        dispatch({ type: RAGISTRATION_VALID_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`registration valid error ${JSON.stringify(error)}`);
      dispatch({ type: RAGISTRATION_VALID_FAILED, payload: error });
    });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/registraton/passwordUpdate
 * Purpose: passwordUpdate
 */
export const passwordUpdate =
  ({ oldPassword, newPassword }) =>
  (dispatch) => {
    dispatch({ type: UPDATE_PASSWORD_START });
    return updatePassword({ oldPassword, newPassword })
      .then((response) => {
        // console.log(`update password response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: response });
        else {
          dispatch({ type: UPDATE_PASSWORD_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`update password error ${JSON.stringify(error)}`);
        dispatch({ type: UPDATE_PASSWORD_FAILED, payload: error });
      });
  };

export const passwordUpdateClear = () => (dispatch) => {
  dispatch({ type: UPDATE_PASSWORD_CLEAR });
};

export const feedbackSubmit =
  ({ feedback }) =>
  (dispatch) => {
    dispatch({ type: SUBMIT_FEEDBACK_START });
    //console.log(` feedbackSubmit param  ${JSON.stringify({ feedback })}`);
    return submitFeedback({ feedback })
      .then((response) => {
        //console.log(` Submit Feedback  response${JSON.stringify(response)}`);
        if (response.Success) {
          dispatch({ type: SUBMIT_FEEDBACK_SUCCESS, payload: response });
        } else {
          dispatch({ type: SUBMIT_FEEDBACK_FAILED, payload: response });
        }
      })
      .catch((error) => {
        //console.log(`Submit Feedback error ${JSON.stringify(error)}`);
        dispatch({
          type: SUBMIT_FEEDBACK_FAILED,
          payload: validateError(error),
        });
      });
  };

export const feedbackSubmitClear = () => (dispatch) => {
  dispatch({ type: SUBMIT_FEEDBACK_CLEAR });
};

export const deviceTokenUpdate =
  ({ deviceType, deviceToken }) =>
  (dispatch) => {
    dispatch({ type: UPDATE_DEVICE_TOKEN_START });
    // console.log(`Update Device token  deviceType ${deviceType}  deviceToken${deviceToken}`);
    return updateDeviceToken({ deviceType, deviceToken })
      .then((response) => {
        // console.log(`Update Device token  response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: UPDATE_DEVICE_TOKEN_SUCCESS, payload: response });
        else {
          dispatch({ type: UPDATE_DEVICE_TOKEN_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`Update Device toke error ${JSON.stringify(error)}`);
        dispatch({ type: UPDATE_DEVICE_TOKEN_FAILED, payload: error });
      });
  };

export const deviceTokenUpdateClear = () => (dispatch) => {
  dispatch({ type: UPDATE_DEVICE_TOKEN_CLEAR });
};

export const getDelegates =
  ({ fetchAll }) =>
  (dispatch) => {
    dispatch({ type: GET_DELEGATES_START });
    return getDelagates({ fetchAll })
      .then((response) => {
        // console.log(`Get delegates response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: GET_DELEGATES_SUCCESS, payload: response.Data });
        else {
          dispatch({ type: GET_DELEGATES_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`Get delegates error ${JSON.stringify(error)}`);
        dispatch({ type: GET_DELEGATES_FAILED, payload: error });
      });
  };

export const getDelegatesClear = () => (dispatch) => {
  dispatch({ type: GET_DELEGATES_CLEAR });
};

export const addApprovedDelegates = (params) => (dispatch) => {
  dispatch({ type: APPROVED_DELEGATES_START });
  return upsertApprovedDelegate(params)
    .then((response) => {
      // console.log(`addApprovedDelegates response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: APPROVED_DELEGATES_SUCCESS, payload: response.Data });
      else {
        dispatch({ type: APPROVED_DELEGATES_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`addApprovedDelegates error ${JSON.stringify(error)}`);
      dispatch({ type: APPROVED_DELEGATES_FAILED, payload: error });
    });
};

export const addApprovedDelegatesClear = () => (dispatch) => {
  dispatch({ type: APPROVED_DELEGATES_CLEAR });
};

export const updateApprovedDelegatesChange =
  ({ list_parentDelegateID, isActive }) =>
  (dispatch) => {
    dispatch({ type: APPROVED_DELEGATES_CHANGE_START });
    return approvedDelegateChange({ list_parentDelegateID, isActive })
      .then((response) => {
        // console.log(`updateApprovedDelegatesChange response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: APPROVED_DELEGATES_CHANGE_SUCCESS,
            payload: response,
          });
        else {
          dispatch({
            type: APPROVED_DELEGATES_CHANGE_FAILED,
            payload: response,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: APPROVED_DELEGATES_CHANGE_FAILED, payload: error });
      });
  };

export const updateApprovedDelegatesChangeClear = () => (dispatch) => {
  dispatch({ type: APPROVED_DELEGATES_CHANGE_CLEAR });
};

export const deleteDelegate =
  ({ list_ParentDelegateId }) =>
  (dispatch) => {
    dispatch({ type: DELEGATES_SET_DELETED_START });
    return delegateSetDeleted({ list_ParentDelegateId })
      .then((response) => {
        // console.log(`Delegates deleted response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: DELEGATES_SET_DELETED_SUCCESS,
            payload: response.Data,
          });
        else {
          dispatch({ type: DELEGATES_SET_DELETED_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`Delegates deleted error ${JSON.stringify(error)}`);
        dispatch({ type: DELEGATES_SET_DELETED_FAILED, payload: error });
      });
  };

export const deleteDelegateClear = () => (dispatch) => {
  dispatch({ type: DELEGATES_SET_DELETED_CLEAR });
};

export const getIsTokenValid = () => (dispatch) => {
  dispatch({ type: GET_TOKEN_VALID_START });
  return isTokenValid()
    .then((response) => {
      // console.log(`Get is token valid  response${JSON.stringify(response)}`);
      dispatch({ type: GET_TOKEN_VALID_SUCCESS, payload: response });
    })
    .catch((error) => {
      // console.log(`Get is token valid error ${JSON.stringify(error)}`);
      dispatch({ type: GET_TOKEN_VALID_FAILED, payload: error });
    });
};

export const getIsTokenValidClear = () => (dispatch) => {
  dispatch({ type: GET_TOKEN_VALID_CLEAR });
};

export const getNotificationsList =
  ({ LastNotificationID, notificationTypeId }) =>
  (dispatch) => {
    dispatch({ type: GET_NOTIFICATIONS_START });
    return getNotifications({ LastNotificationID, notificationTypeId })
      .then((response) => {
        if (response.Success)
          dispatch({ type: GET_NOTIFICATIONS_SUCCESS, payload: response.Data });
        else {
          dispatch({ type: GET_NOTIFICATIONS_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`Get Notifications paging error ${JSON.stringify(error)}`);
        dispatch({ type: GET_NOTIFICATIONS_FAILED, payload: error });
      });
  };

export const notificationsListClear = () => (dispatch) => {
  dispatch({ type: GET_NOTIFICATIONS_CLEAR });
};

export const getTopics =
  ({ schoolIds }) =>
  (dispatch) => {
    dispatch({ type: GET_HELP_TOPICS_START });
    return getHelpTopics({ schoolIds })
      .then((response) => {
        const topics = [];
        const allTopics = response.Data;
        topics.push({
          label: "Select",
          value: 0,
        });
        for (let i = 0; i < allTopics.length; i++) {
          topics.push({
            label: `${allTopics[i].Description}`,
            value: `${allTopics[i].ListHelpTopicID}`,
          });
        }
        // console.log("Get help topics response", JSON.stringify(response));

        if (response.Success)
          dispatch({ type: GET_HELP_TOPICS_SUCCESS, payload: topics });
        else {
          dispatch({ type: GET_HELP_TOPICS_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log("Get help topics error", JSON.stringify(error));
        dispatch({ type: GET_HELP_TOPICS_FAILED, payload: error });
      });
  };

export const sendSupportRequest = (params) => (dispatch) => {
  dispatch({ type: SEND_SUPPORT_REQUEST_START });
  return sendFeedbackWithChild(params)
    .then((response) => {
      // console.log(`SendSupport request response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({
          type: SEND_SUPPORT_REQUEST_SUCCESS,
          payload: response.Data,
        });
      else {
        dispatch({ type: SEND_SUPPORT_REQUEST_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`SendSupport request error ${JSON.stringify(error)}`);
      dispatch({ type: SEND_SUPPORT_REQUEST_FAILED, payload: error });
    });
};

export const sendSupportRequestClear = () => (dispatch) => {
  dispatch({ type: SEND_SUPPORT_REQUEST_CLEAR });
};

export const notificationSetting = () => (dispatch) => {
  dispatch({ type: GET_NOTIFICATION_SETTING_START });
  return getNotificationSettings()
    .then((response) => {
      // console.log("Get Notifications setting response",JSON.stringify(response));

      if (response.Success)
        dispatch({
          type: GET_NOTIFICATION_SETTING_SUCCESS,
          payload: response.Data,
        });
      else {
        dispatch({ type: GET_NOTIFICATION_SETTING_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log("Get Notifications setting error", JSON.stringify(error));
      dispatch({ type: GET_NOTIFICATION_SETTING_FAILED, payload: error });
    });
};
export const notificationSettingClear = () => (dispatch) => {
  dispatch({ type: GET_NOTIFICATION_SETTING_CLEAR });
};
export const notificationSettingUpdate = (params) => (dispatch) => {
  dispatch({ type: UPDATE_NOTIFICATION_SETTING_START });
  return updateParentNotificationSettings(params)
    .then((response) => {
      if (response)
        dispatch({
          type: UPDATE_NOTIFICATION_SETTING_SUCCESS,
          payload: response,
        });
      else {
        dispatch({
          type: UPDATE_NOTIFICATION_SETTING_FAILED,
          payload: response,
        });
      }
    })
    .catch((error) => {
      // console.log(`update notification setting error ${JSON.stringify(error)}`);
      dispatch({ type: UPDATE_NOTIFICATION_SETTING_FAILED, payload: error });
    });
};
export const notificationSettingUpdateClear = () => (dispatch) => {
  dispatch({ type: UPDATE_NOTIFICATION_SETTING_CLEAR });
};

export const setEnvironment =
  ({ env, callback }) =>
  (dispatch) => {
    dispatch({ type: SET_ENVIRONMENT, payload: env });
    if (callback) {
      callback();
    }
  };

export const saveLastUsedTimeStamp = () => (dispatch) => {
  dispatch({ type: SAVE_LAST_USED_TIMESTAMP, payload: {} });
};

export const saveLocationPermission = (permission) => (dispatch) => {
  dispatch({ type: SAVE_LOCATION_PERMISSION, payload: permission });
};

export const fetchDistance =
  ({ deviceLat, deviceLon, schoolLatitude, schoolLongitude, callback }) =>
  (dispatch) => {
    return fetchEta(deviceLat, deviceLon, schoolLatitude, schoolLongitude)
      .then((response) => {
        if (callback) callback(response);
      })
      .catch((error) => {
        if (callback) callback({ code: "Failed" });
      });
  };
/**
 * API:   // http://35.167.52.234/api/account/logErrrors
 * Purpose: logErrors
 */
export const errorsLogBrowser = (params) => (dispatch) => {
  dispatch({ type: LOG_ERRORS_BROWSER_START });
  return logErrorsBrowser(params)
    .then((response) => {
      // console.log(`logErrorsBrowser response ${JSON.stringify(response)}`);
      if (response)
        dispatch({ type: LOG_ERRORS_BROWSER_SUCCESS, payload: response });
      else {
        dispatch({ type: LOG_ERRORS_BROWSER_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`logErrorsBrowser error ${JSON.stringify(error)}`);
      dispatch({ type: LOG_ERRORS_BROWSER_FAILED, payload: error });
    });
};

export const errorsLogBrowserClear = () => (dispatch) => {
  dispatch({ type: LOG_ERRORS_BROWSER_CLEAR });
};

/**
 * API:   // http://35.167.52.234/api/account/logErrrorsFile
 * Purpose: logErrorsFile
 */
export const errorsLogBrowserFile = (params) => (dispatch) => {
  dispatch({ type: LOG_ERRORS_FILE_START });
  return fileLogErrors(params)
    .then((response) => {
      // console.log(`logErrorsBrowserFile response ${JSON.stringify(response)}`);
      if (response)
        dispatch({ type: LOG_ERRORS_FILE_SUCCESS, payload: response });
      else {
        dispatch({ type: LOG_ERRORS_FILE_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`logErrorsBrowserFile error ${JSON.stringify(error)}`);
      dispatch({ type: LOG_ERRORS_FILE_FAILED, payload: error });
    });
};

export const errorsLogBrowserFileClear = () => (dispatch) => {
  dispatch({ type: LOG_ERRORS_FILE_CLEAR });
};

/**
 * API:   // http://35.167.52.234/api/account/ConnectStudent
 * Purpose: connectStudent
 */
export const studentWithConnect = (params) => (dispatch) => {
  // console.log(`studentWithConnect params ${JSON.stringify(params)}`);
  dispatch({ type: CONNECT_STUDENT_START });
  return connectStudent(params)
    .then((response) => {
      // console.log(`studentWithConnect response ${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: CONNECT_STUDENT_SUCCESS, payload: response });
      else {
        dispatch({ type: CONNECT_STUDENT_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`studentWithConnect error ${JSON.stringify(error)}`);
      dispatch({ type: CONNECT_STUDENT_FAILED, payload: error });
    });
};

export const studentWithConnectClear = () => (dispatch) => {
  dispatch({ type: CONNECT_STUDENT_CLEAR });
};

export const getFutureEntries = (params) => (dispatch) => {
  // console.log(`getFutureEntries params ${JSON.stringify(params)}`);
  dispatch({ type: GET_FUTURE_ENTRIES_START });
  return getFutureCalendarEntriesByParent(params)
    .then((response) => {
      //console.log(`getFutureEntries response ${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({
          type: GET_FUTURE_ENTRIES_SUCCESS,
          payload: response.Data,
        });
      else {
        dispatch({ type: GET_FUTURE_ENTRIES_FAILURE, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`getFutureEntries error ${JSON.stringify(error)}`);
      dispatch({ type: GET_FUTURE_ENTRIES_FAILURE, payload: error });
    });
};
export const getFutureEntriesClear = () => (dispatch) => {
  dispatch({ type: GET_FUTURE_ENTRIES_CLEAR });
};

export const showModal =
  ({ status, modalLevel, modalScreenName, modalClassName, params }) =>
  (dispatch) => {
    dispatch({
      type: TOGGLE_MODAL,
      payload: { status, modalLevel, modalScreenName, modalClassName, params },
    });
  };

export const hideModal = () => (dispatch) => {
  dispatch({ type: HIDE_MODAL });
};

export const showCheckinQuestAns =
  ({ status, showSchoolChildrenList, params }) =>
  (dispatch) => {
    dispatch({
      type: TOGGLE_CHECKIN_QUEST_ANS,
      payload: { status, showSchoolChildrenList, params },
    });
  };

export const setHelpList =
  ({ stackType, screen, title, modalLevel }) =>
  (dispatch) => {
    dispatch({
      type: SET_HELPLIST,
      payload: { stackType, screen, title, modalLevel },
    });
  };

export const setOldHelpSetting = () => (dispatch) => {
  // dispatch({ type: SET_OLD_HELPLIST });
};

export const saveAvatar =
  ({ id, filename }) =>
  (dispatch) => {
    dispatch({ type: SAVE_PHOTO, payload: { id, filename } });
  };

export const saveAllAvatar = (avatars) => (dispatch) => {
  dispatch({ type: SAVE_ALL_PHOTOS, payload: avatars });
};

// Upload delegate image
export const delegateImageUpload =
  ({ image, parentDelegateID, file }) =>
  (dispatch) => {
    dispatch({ type: UPLOAD_DELEGATE_IMAGE_START });
    return uploadDelegateImage(image, parentDelegateID)
      .then((response) => {
        if (response.Success)
          dispatch({ type: UPLOAD_DELEGATE_IMAGE_SUCCESS, payload: response });
        else {
          dispatch({ type: UPLOAD_DELEGATE_IMAGE_FAILED, payload: response });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_DELEGATE_IMAGE_FAILED,
          payload: validateError(error),
        });
      });
  };
export const delegateImageUploadErrorClear = () => (dispatch) => {
  dispatch({ type: UPLOAD_DELEGATE_IMAGE_CLEAR });
};

// Request manual review image
export const manualReviewRequest = (delegateImageId) => (dispatch) => {
  dispatch({ type: REQUEST_MANUAL_REVIEW_START });
  return RequestManualReviewImage(delegateImageId)
    .then((response) => {
      if (response.Success)
        dispatch({ type: REQUEST_MANUAL_REVIEW_SUCCESS, payload: response });
      else {
        dispatch({ type: REQUEST_MANUAL_REVIEW_FAILED, payload: response });
      }
    })
    .catch((error) => {
      dispatch({
        type: REQUEST_MANUAL_REVIEW_FAILED,
        payload: validateError(error),
      });
    });
};

export const manualRequestClear = () => (dispatch) => {
  dispatch({ type: REQUEST_MANUAL_REVIEW_CLEAR });
};

// Cancel manual review image
export const manualReviewCancel = (parentDelegateID) => (dispatch) => {
  dispatch({ type: CANCEL_MANUAL_REVIEW_START });
  return CancelManualReviewImage(parentDelegateID)
    .then((response) => {
      if (response.Success)
        dispatch({ type: CANCEL_MANUAL_REVIEW_SUCCESS, payload: response });
      else {
        dispatch({ type: CANCEL_MANUAL_REVIEW_FAILED, payload: response });
      }
    })
    .catch((error) => {
      dispatch({
        type: CANCEL_MANUAL_REVIEW_FAILED,
        payload: validateError(error),
      });
    });
};

export const manualReviewCancelClear = () => (dispatch) => {
  dispatch({ type: CANCEL_MANUAL_REVIEW_CLEAR });
};

// Get Delegate Action
export const getDelegateByID = (delegateId) => (dispatch) => {
  dispatch({ type: GET_DELEGATE_START });
  return getDelegate(delegateId)
    .then((response) => {
      if (response.Success)
        dispatch({ type: GET_DELEGATE_SUCCESS, payload: response });
      else {
        dispatch({ type: GET_DELEGATE_FAILED, payload: response });
      }
    })
    .catch((error) => {
      dispatch({ type: GET_DELEGATE_FAILED, payload: validateError(error) });
    });
};

// Account Delete Action
export const accountDelete = (email, password) => (dispatch) => {
  dispatch({ type: ACCOUNT_DELETE_START });
  return deleteAccount(email, password)
    .then((response) => {
      if (response.Success)
        dispatch({ type: ACCOUNT_DELETE_SUCCESS, payload: response });
      else {
        dispatch({ type: ACCOUNT_DELETE_FAILED, payload: response });
      }
    })
    .catch((error) => {
      dispatch({ type: ACCOUNT_DELETE_FAILED, payload: validateError(error) });
    });
};

export const accountDeleteClear = () => (dispatch) => {
  dispatch({ type: ACCOUNT_DELETE_CLEAR });
};

export const saveTermsAccepted = (params) => (dispatch) => {
  dispatch({
    type: SAVE_TERMS_ACCEPTED,
    payload: params,
  });
};

// Ask me later save and clear
export const saveNextAskMeDate = (type) => (dispatch) => {
  const nextAskMeLater = new Date();
  if (type === 1) {
    nextAskMeLater.setDate(nextAskMeLater.getDate() + 1);
  } else if (type === 2) {
    nextAskMeLater.setFullYear(nextAskMeLater.getFullYear() + 1);
  }

  dispatch({ type: ASK_ME_LATER_SAVE, payload: nextAskMeLater });
};

export const backtoschoolDataInfo = () => (dispatch) => {
  dispatch({
    type: BACKTOSCHOOL_DATA_FILLED,
  });
};

// Upload User Profile image
export const UserImageUpload =
  ({ image, parentDelegateID }) =>
  (dispatch) => {
    dispatch({ type: UPLOAD_USER_IMAGE_START });
    return uploadUserImage(image, parentDelegateID)
      .then((response) => {
        if (response.Success)
          dispatch({ type: UPLOAD_USER_IMAGE_SUCCESS, payload: response });
        else {
          dispatch({ type: UPLOAD_USER_IMAGE_FAILED, payload: response });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_USER_IMAGE_FAILED,
          payload: validateError(error),
        });
      });
  };
export const userImageUploadErrorClear = () => (dispatch) => {
  dispatch({ type: UPLOAD_USER_IMAGE_CLEAR });
};

// User Profile Request manual review image
export const userManualReviewRequest = (userImageId) => (dispatch) => {
  dispatch({ type: USER_REQUEST_MANUAL_REVIEW_START });
  return userRequestManualReviewImage(userImageId)
    .then((response) => {
      if (response.Success)
        dispatch({
          type: USER_REQUEST_MANUAL_REVIEW_SUCCESS,
          payload: response,
        });
      else {
        dispatch({
          type: USER_REQUEST_MANUAL_REVIEW_FAILED,
          payload: response,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: USER_REQUEST_MANUAL_REVIEW_FAILED,
        payload: validateError(error),
      });
    });
};

export const userManualRequestClear = () => (dispatch) => {
  dispatch({ type: USER_REQUEST_MANUAL_REVIEW_CLEAR });
};

// // User Profile Cancel manual review image
export const userManualReviewCancel = () => (dispatch) => {
  dispatch({ type: USER_CANCEL_MANUAL_REVIEW_START });
  return userCancelManualReviewImage()
    .then((response) => {
      if (response.Success)
        dispatch({
          type: USER_CANCEL_MANUAL_REVIEW_SUCCESS,
          payload: response,
        });
      else {
        dispatch({ type: USER_CANCEL_MANUAL_REVIEW_FAILED, payload: response });
      }
    })
    .catch((error) => {
      dispatch({
        type: USER_CANCEL_MANUAL_REVIEW_FAILED,
        payload: validateError(error),
      });
    });
};

export const userManualReviewCancelClear = () => (dispatch) => {
  dispatch({ type: USER_CANCEL_MANUAL_REVIEW_CLEAR });
};

// Get User Profile
export const getUserProfileInfo = (callback) => (dispatch) => {
  dispatch({ type: GET_USER_PROFILE_START });
  return getUserProfile()
    .then((response) => {
      //console.log(` Get User Profile response ${JSON.stringify(response)}`);
      if (response.Success && response.Data && response.Data.UserInfo) {
        dispatch({
          type: GET_USER_PROFILE_SUCCESS,
          payload: response.Data.UserInfo,
        });
        if (callback) {
          callback(response.Data.UserInfo);
        }
      } else {
        dispatch({ type: GET_USER_PROFILE_FAILED, payload: response });
      }
    })
    .catch((error) => {
      //console.log(` Get User Profile error ${JSON.stringify(error)}`);
      dispatch({
        type: GET_USER_PROFILE_FAILED,
        payload: validateError(error),
      });
    });
};

export const markReadToDashboard = () => (dispatch) => {
  dispatch({ type: MARK_READY_TO_DASHBOARD });
};

export const setCroppedImage =
  ({ croppedImageData, showImageCrop }) =>
  (dispatch) => {
    dispatch({
      type: SET_CROPPED_IMAGE,
      payload: { croppedImageData, showImageCrop },
    });
  };
export const setCroppedImageClear = () => (dispatch) => {
  dispatch({ type: SET_CROPPED_IMAGE_CLEAR });
};
export const setLoginType = (loginType) => (dispatch) => {
  dispatch({ type: SET_LOGIN_TYPE, payload: loginType });
};

export const ssoUser_sendOTP =
  ({ deviceType }) =>
  (dispatch) => {
    dispatch({ type: SSO_USER_SEND_OTP_START });
    return deleteSSO_UserSendOTP({ deviceType })
      .then((response) => {
        // console.log(
        //   ` sso delete user send otp response-> ${JSON.stringify(response)}`
        // );
        if (response.Success) {
          dispatch({ type: SSO_USER_SEND_OTP_SUCCESS, payload: response });
        } else {
          dispatch({ type: SSO_USER_SEND_OTP_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(
        //   ` sso delete user send otp error response-> ${JSON.stringify(error)}`
        // );
        dispatch({
          type: SSO_USER_SEND_OTP_FAILED,
          payload: validateError(error),
        });
      });
  };

export const ssoUser_sendOTPClear = () => (dispatch) => {
  dispatch({ type: SSO_USER_SEND_OTP_CLEAR });
};

export const ssoUser_deleteAccount =
  ({ email, OtpCode }) =>
  (dispatch) => {
    dispatch({ type: SSO_USER_DELETE_START });
    return ssoUserDelete({ email, OtpCode })
      .then((response) => {
        // console.log(
        //   ` sso user delete account response-> ${JSON.stringify(response)}`
        // );
        if (response.Success) {
          dispatch({ type: SSO_USER_DELETE_SUCCESS, payload: response });
        } else {
          dispatch({ type: SSO_USER_DELETE_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(
        //   ` sso user delete account error response-> ${JSON.stringify(error)}`
        // );
        dispatch({
          type: SSO_USER_DELETE_FAILED,
          payload: validateError(error),
        });
      });
  };

export const ssoUser_deleteAccountClear = () => (dispatch) => {
  dispatch({ type: SSO_USER_DELETE_CLEAR });
};

export const ssoUser_generateOTPForTransaction =
  ({ SchoolId }) =>
  (dispatch) => {
    dispatch({ type: SSO_USER_SEND_OTP_TRANSACTION_START });
    return SSO_User_generateOTPForTransaction({ SchoolId })
      .then((response) => {
        // console.log(
        //   ` sso user send otp for ED and Mark Absent response-> ${JSON.stringify(
        //     response
        //   )}`
        // );
        if (response.Success) {
          dispatch({
            type: SSO_USER_SEND_OTP_TRANSACTION_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: SSO_USER_SEND_OTP_TRANSACTION_FAILED,
            payload: response,
          });
        }
      })
      .catch((error) => {
        // console.log(
        //   ` sso delete user send otp for ED and Mark Absent error response-> ${JSON.stringify(
        //     error
        //   )}`
        // );
        dispatch({
          type: SSO_USER_SEND_OTP_TRANSACTION_FAILED,
          payload: validateError(error),
        });
      });
  };

export const ssoUser_generateOTPForTransactionClear = () => (dispatch) => {
  dispatch({ type: SSO_USER_SEND_OTP_TRANSACTION_CLEAR });
};

export const sendUserWelcomeEmail = () => (dispatch) => {
  dispatch({ type: SEND_WELCOME });
  return sendWelcomeEmail()
    .then((response) => {
      // console.log(`sendUserWelcomeEmail response ${JSON.stringify(response)}`);
    })
    .catch((error) => {});
};

export const setGoogleLoginCredentials =
  ({ googleLoginCredentials }) =>
  (dispatch) => {
    dispatch({
      type: SET_GOOGLE_LOGIN_CREDENTIALS,
      payload: { googleLoginCredentials: googleLoginCredentials },
    });
  };

export const setGoogleLoginCredentialsClear = () => (dispatch) => {
  dispatch({ type: SET_GOOGLE_LOGIN_CREDENTIALS_CLEAR });
};

export const setAppleLoginCredentials =
  ({ appleLoginCredentials }) =>
  (dispatch) => {
    dispatch({
      type: SET_APPLE_LOGIN_CREDENTIALS,
      payload: { appleLoginCredentials },
    });
  };

export const setAppleLoginCredentialsClear = () => (dispatch) => {
  dispatch({ type: SET_APPLE_LOGIN_CREDENTIALS_CLEAR });
};

export const saveLoginCredentials =
  ({ loginCredentials }) =>
  (dispatch) => {
    dispatch({
      type: SAVE_LOGIN_CREDENTIALS,
      payload: { loginCredentials: loginCredentials },
    });
  };
export const saveLoginCredentialsClear = () => (dispatch) => {
  dispatch({ type: SAVE_LOGIN_CREDENTIALS_CLEAR });
};

export const getGeoInformation = (callback) => (dispatch) => {
  dispatch({ type: GET_GEOINFO_START });
  return getGeoInfo()
    .then((response) => {
      //console.log(`getGeoInformation response ${JSON.stringify(response)}`);
      dispatch({
        type: GET_GEOINFO_SUCCESS,
        payload: response,
      });
      if (callback) callback(response);
    })
    .catch((error) => {
      dispatch({
        type: GET_GEOINFO_FAILED,
        payload: validateError(error),
      });
    });
};

export const saveHelpMessage1 = () => (dispatch) => {
  dispatch({ type: SAVE_HELP_MESSAGE1 });
};
export const saveHelpMessage2 = () => (dispatch) => {
  dispatch({ type: SAVE_HELP_MESSAGE2 });
};
export const saveHelpMessage3 = () => (dispatch) => {
  dispatch({ type: SAVE_HELP_MESSAGE3 });
};
export const saveSelectedTheme =
  ({ selectedTheme, isAuto }) =>
  (dispatch) => {
    dispatch({ type: SAVE_THEME, payload: { selectedTheme, isAuto } });
  };
