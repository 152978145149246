import React from "react";
import { withRouter } from "../CommonComponents/withRouter";
import { Link } from "react-router-dom";
import NokidsImage from "./../assets/dashboard/empty-state.svg";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { showModal, hideModal } from "../../common/actions/userAccount";
class Nokids extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  openContactSupportModal = () => {
    this.props.doShowModal({
      modalScreenName: "ContactSupport",
      modalClassName: "contactSupportModal sidebar-modal right",
    });
  };
  render() {
    const { noKidsTitle, noKidsDetail } = this.props;
    return (
      <section className="pmk-child-schedule noKidsSect">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 text-center">
              <div className="noKidsLayout">
                <figure className="noKidsImage">
                  <img
                    src={NokidsImage}
                    title={this.props.t("dashBoardScr-noKidsTitle")}
                    alt={this.props.t("dashBoardScr-noKidsTitle")}
                  />
                  <br />
                </figure>
                <div className="noKidsDetails">
                  <h2 className="title">{noKidsTitle}</h2>
                  <div className="nokids-content">
                    <p>{noKidsDetail}</p>
                  </div>
                  <div className="btn-sect">
                    <button
                      type="button"
                      className="btn needhelp-label"
                      onClick={() => {
                        this.props.navigate("/newchooseRole", {
                          state: { screenFrom: this.props.screenName },
                        });
                      }}
                    >
                      {this.props.t("dashBoardScr-needHelp")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Nokids))
);
