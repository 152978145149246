import React from "react";
import { withTranslation } from "react-i18next";

class SubmitButton extends React.Component {
  render() {
    const { nextAction, buttonText, loading, validateForm } = this.props;
    return (
      <div className="form-group">
        <button type="button" className="btn btn-primary" onClick={nextAction}>
          {loading ? (
            <i className="fa-solid fa-arrows-rotate fa-spin"></i>
          ) : null}{" "}
          &nbsp;
          {buttonText
            ? `${this.props.t("regCardScr-regAccount")}`
            : `${this.props.t("regNameScr-next")}`}
        </button>
      </div>
    );
  }
}

export default withTranslation()(SubmitButton);
