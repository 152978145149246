const GLOBAL_CONST = {
  PICKUP_STATUS: {
    NOT_DISMISSED: 5,
    ANNOUNCED: 3,
    DISMISSED: 6,
    ABSENT: 7,
  },
  SCREEN_NAMES: {
    LOGIN: "Login",
    FORGOT_PASSWORD: "Forgot_Password",
    DASHBOARD: "Dashboard",
    PICKUP: "Pickup",
    REGISTRATION_NAME: "Registration_Name",
    REGISTRATION_EMAIL: "Registration_Email",
    REGISTRATION_CONFIRM_EMAIL: "Registration_Confirm_Email",
    REGISTRATION_PHONE: "Registration_Phone",
    REGISTRATION_CONFIRM_PHONE: "Registration_Confirm_Phone",
    REGISTRATION_PASSWORD: "Registration_Password",
    REGISTRATION_SUCCESS: "Registration_Success",
    REGISTRATION_CHOOSE_ROLE: "Registration_Choose_Role",
    REGISTRATION_SUBMIT_FEEDBACK: "Registration_Submit_Feedback",
    SCHOOL_DETAILS: "School_Details",
    SCHOOL_DISMISSAL_TIME: "School_Dismissal_Time",
    CHECKIN_FOR: "CheckIn_For",
    CHECKIN_QUESTIONS: "CheckIn_Questions",
    NOTIFICATIONS_SCREEN: "Notifications_Screen",
    MANAGE_CHILDREN_TAB: "Manage_Children_Tab",
    CHILD_DETAILS: "Child_Details",
    DEFAULT_PICKUP_MODE: "Default_Pickup_Mode",
    MARK_ABSENT: "Mark_Absent",
    SET_CHILD_PHOTO: "Set_Child_Photo",
    SELECT_WHEN: "Select_When",
    DISMISSAL_HOW: "Dismissal_How",
    CONTACT_SCREEN: "Contact_Screen",
    CHANGE_PASSWORD: "Change_Password",
    SETTING_NOTIFICATION: "Setting_Notification",
    ADD_CONTACT: "Add_Contact",
    FUTURE_DELEGATIONS: "Future_Delegations",
    CONTACT_SUPPORT: "Contact_Support",
    FAQ: "Faq",
    TERM_CONDITIONS: "Term_Conditions",
    PROFILE_SCREEN: "Profile_Screen",
    DISMISSAL_HISTORY: "Dismissal_history",
    REGISTRATION_ACCOUNT_STATUS: "Registration_Account_Status",
    AUTH_STACK_HELP_LISTING: "AUTH_STACK_HELP_LISTING",
    ACCOUNT_HELP_LISTING: "ACCOUNT_HELP_LISTING",
    ACCOUNT_VERIFY_EMAIL: "Account_Verify_Email",
    REGISTRATION_VERIFY_EMAIL: "Registration_Verify_Email",
  },
  DAYS_EN: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  DAYS_FULL_EN: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  DAYS_ES: ["D", "L", "M", "X", "J", "V", "S"],
  DAYS_FULL_ES: [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ],

  VERSION: "4.0.49",

  GOOGLE_SSO_CONFIG: {
    webClientId:
      "931186290880-d6pdrp3f4bhqakl6ketp2ojfq85et7u8.apps.googleusercontent.com", // client ID of type WEB for your server. Required to get the `idToken` on the user object, and for offline access.
    scopes: ["https://www.googleapis.com/auth/drive.readonly"], // what API you want to access on behalf of the user, default is email and profile
    offlineAccess: true, // if you want to access Google API on behalf of the user FROM YOUR SERVER
    hostedDomain: "", // specifies a hosted domain restriction
    forceCodeForRefreshToken: true, // [Android] related to `serverAuthCode`, read the docs link below *.
    accountName: "", // [Android] specifies an account name on the device that should be used
    iosClientId:
      "931186290880-jeb0pilbleqd8ph8tlebelvvp4aa796c.apps.googleusercontent.com", // [iOS] if you want to specify the client ID of type iOS (otherwise, it is taken from GoogleService-Info.plist)
    googleServicePlistPath: "", // [iOS] if you renamed your GoogleService-Info file, new name here, e.g. GoogleService-Info-Staging
    openIdRealm: "", // [iOS] The OpenID2 realm of the home web server. This allows Google to include the user's OpenID Identifier in the OpenID Connect ID token.
    profileImageSize: 120, // [iOS] The desired height (and width) of the profile image. Defaults to 120px
  },
  APPLE_SSO_CONFIG: {
    clientId: "com.pikmykid.parents"
  },
  LOGIN_TYPE: {
    EMAIL_OR_PHONE: 1,
    GOOGLE_LOGIN: 2,
    APPLE_LOGIN: 3,
  },
  PICKMODE: {
    AFTER_SCHOOL: 4,
    BUS: 1,
  },
  PICKMODE_NAME: {
    EARLY_DEPARTURE: "Early Departure",
  },
  THEME: {
    DARK: "dark",
    LIGHT: "light",
    HIGH_CONTRAST: "high_contrast",
  },
};

export default GLOBAL_CONST;
