import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dom } from "@fortawesome/fontawesome-svg-core";
import { faSmileBeam } from "@fortawesome/free-regular-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import logo from "../assets/registerImages/pmk-logo.svg";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { withRouter } from "./withRouter";
import { logoutUser } from "../../common/actions/userAccount";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  showModal,
  hideModal,
  setHelpList,
  saveSelectedTheme,
} from "../../common/actions/userAccount";
import { withTranslation } from "react-i18next";
import GLOBAL_CONST from "../../helper/AppConstants";
import { getBackToSchoolInformation } from "../../common/actions/students";
import { isSSOLogin, getCurrentThemeColor } from "../../helper/utility";
import { getMyStyle } from "../../theme/ThemeStyle";

dom.watch();
class MainHeader extends React.Component {
  openNotification = () => {
    this.props.doShowModal({
      modalScreenName: "NotificationsList",
      modalClassName: "notificationList sidebar-modal  right",
    });
  };
  openViewProfileModal = () => {
    this.props.doShowModal({
      modalScreenName: "ViewProfile",
      modalClassName: "viewProfileModal sidebar-modal  right",
    });
  };
  openChangePasswordModal = () => {
    this.props.doShowModal({
      modalScreenName: "ChangePassword",
      modalClassName: "changePasswordModal sidebar-modal right",
    });
  };
  openSettingsModal = () => {
    this.props.doShowModal({
      modalScreenName: "Settings",
      modalClassName: "settings-modal sidebar-modal right",
    });
  };
  openContactsModal = () => {
    this.props.doShowModal({
      modalScreenName: "SelectContact",
      modalClassName: "contact-list-modal sidebar-modal right",
      params: {
        manageDelegate: true,
        setSelectedDelegate: null,
      },
    });
  };
  openContactSupportModal = () => {
    this.props.doShowModal({
      modalScreenName: "ContactSupport",
      modalClassName: "contactSupportModal sidebar-modal right",
    });
  };

  openHelpModal = () => {
    let helpParam;
    if (this.props.location.pathname === "/checkin") {
      helpParam = {
        stackType: "CheckIn",
        screen: GLOBAL_CONST.SCREEN_NAMES.CHECKIN_FOR,
        title: this.props.t("selectCheckScr-schoolCheck"),
      };
    } else if (this.props.location.pathname === "/childManagement") {
      helpParam = {
        stackType: "ChildManagement",
        screen: GLOBAL_CONST.SCREEN_NAMES.MANAGE_CHILDREN_TAB,
        title: this.props.t("manageChildrenScr-manageChildren"),
      };
    } else if (this.props.location.pathname === "/dashboard") {
      helpParam = {
        stackType: "Dashboard",
        screen: GLOBAL_CONST.SCREEN_NAMES.PICKUP,
        title: this.props.t("pickup_title"),
      };
    }
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: helpParam,
    });
  };

  submit = () => {
    confirmAlert({
      title: this.props.t("settingMainScr-signOut"),
      message: this.props.t("settingMainScr-areYouSure"),
      buttons: [
        {
          label: this.props.t("settingMainScr-ok"),
          onClick: this.logout,
        },
        {
          label: this.props.t("Cancel"),
          className: "cancel-btn",
        },
      ],
    });
  };

  logout = () => {
    const { doLogoutUser } = this.props;
    doLogoutUser();
    this.props.navigate("/");
  };

  openBackToSchoolKidsModal = () => {
    const { schoolInfo } = this.props;
    this.props.doShowModal({
      modalScreenName: "backToSchoolKidList",
      modalClassName: "backto-school-modal",
      params: {
        stackType: "Setting",
        isReadOnly: false,
        schoolInfo,
      },
    });
  };

  openThemeSelectionModal = () => {
    this.props.doShowModal({
      modalScreenName: "ThemeSelection",
      modalClassName: "ThemeSelectionModal sidebar-modal2 right ",
    });
  };

  componentDidMount() {
    document.body.style.backgroundColor = getCurrentThemeColor(
      this.props.selectedTheme
    ).body;
    const { doGetbacktoschoolInfo } = this.props;
    doGetbacktoschoolInfo({});
  }

  componentDidUpdate() {
    this.isBacktoschoolVisible();
  }

  isBacktoschoolVisible = () => {
    const { studentsList, schoolInfo } = this.props;
    const students = studentsList && studentsList.TodaysStudents;
    if (students) {
      const studentRecords = [...students];
      for (let i = 0; i < schoolInfo.length; i += 1) {
        for (let j = 0; j < studentRecords.length; j += 1) {
          if (
            schoolInfo[i].BackToSchoolEnabled &&
            studentRecords[j].IsDelegation === false
          )
            return true;
        }
      }
    }
    return false;
  };

  render() {
    const {
      userLoginInfo,
      studentsList,
      getBackSchoolInfoData,
      selectedTheme,
    } = this.props;
    const students = studentsList && studentsList.TodaysStudents;
    const webPage = this.props.location.pathname;
    let userInitials = "";
    if (userLoginInfo)
      userInitials = userLoginInfo.firstName
        ? userLoginInfo.firstName.charAt(0) + userLoginInfo.lastName.charAt(0)
        : null;

    const styles = getMyStyle(selectedTheme);
    return (
      <header
        className="pmk-header"
        style={
          selectedTheme === GLOBAL_CONST.THEME.DARK ? styles.noBoxShadow : {}
        }
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-3 logo-col">
              <div className="logo-wrapper">
                <Link
                  to="/childManagement"
                  className="navbar-brand"
                  aria-label="PikMyKid Logo"
                >
                  <img
                    src={logo}
                    alt="PikMyKid"
                    title="PikMyKid"
                    width="110"
                    height="40"
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-12 col-md-9">
              <ul className="nav top-menu">
                <li className="nav-item notifications-menu radius-circle">
                  <Link
                    to={"#"}
                    className="btn nav-link "
                    onClick={() => {
                      this.openNotification();
                    }}
                    aria-label={this.props.t("notificationScr-enablePush")}
                  >
                    <FontAwesomeIcon icon={faBell} />
                  </Link>
                  <em className=" dot"></em>
                </li>
                <li className="nav-item help-menu radius-circle">
                  <Link
                    to={"#"}
                    className="btn nav-link "
                    onClick={() => {
                      this.openHelpModal();
                    }}
                    aria-label={this.props.t("helpScr-help")}
                  >
                    <i className="far fa-question-circle"></i>
                  </Link>
                  <em className=" dot"></em>
                </li>
                <li className="nav-item dropdown user-profile radius-circle">
                  <Link
                    to={"#"}
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-label={this.props.t("user_profile")}
                  >
                    {userInitials || <i className="fas fa-user"></i>}
                  </Link>
                  <em className="dot"></em>
                  <i className="fas fa-chevron-down arrow"></i>
                  <div className="dropdown-menu">
                    <Link
                      to={"#"}
                      className="dropdown-item"
                      onClick={() => {
                        this.openViewProfileModal();
                      }}
                      aria-label={this.props.t("view_profile")}
                    >
                      <i className="icon fas fa-user"></i>
                      <span className="text">
                        {this.props.t("view_profile")}
                      </span>
                    </Link>
                    {!isSSOLogin() && (
                      <Link
                        to={"#"}
                        className="dropdown-item"
                        onClick={this.openChangePasswordModal}
                        aria-label={this.props.t("settingMainScr-changePwd")}
                      >
                        <i className="icon fas fa-lock"></i>
                        <span className="text">
                          {this.props.t("settingMainScr-changePwd")}
                        </span>
                      </Link>
                    )}
                    <Link
                      to={"#"}
                      className="dropdown-item"
                      onClick={this.openSettingsModal}
                      aria-label={this.props.t("settingsScr-settings")}
                    >
                      <i className="icon fas fa-cog"></i>
                      <span className="text">
                        {this.props.t("settingsScr-settings")}
                      </span>
                    </Link>
                    <Link
                      to={"#"}
                      className="dropdown-item"
                      onClick={this.openContactsModal}
                      aria-label={this.props.t("Contact")}
                    >
                      <i className="icon far fa-user-circle"></i>
                      <span className="text">{this.props.t("Contact")}</span>
                    </Link>
                    {this.isBacktoschoolVisible() === true ? (
                      <Link
                        to={"#"}
                        className="dropdown-item"
                        onClick={() => {
                          if (students.length > 1) {
                            this.openBackToSchoolKidsModal();
                          } else if (students.length === 1) {
                            this.props.doShowModal({
                              modalScreenName: "backToSchool",
                              modalClassName: "backto-school-modal",
                              params: {
                                studentData: students[0],
                                backToSchoolInfoData: getBackSchoolInfoData[0],
                                isSingleKid: true,
                                isReadOnly: true,
                                stackType: "Setting",
                              },
                            });
                          }
                        }}
                        aria-label={this.props.t("Back_to_school")}
                      >
                        <i className="icon fa-solid fa-school-flag"></i>
                        <span className="text">
                          {this.props.t("Back_to_school")}
                        </span>
                      </Link>
                    ) : null}
                    <Link
                      to={"#"}
                      className="dropdown-item"
                      onClick={this.openContactSupportModal}
                      aria-label={this.props.t("helpScr-contact")}
                    >
                      <i className="icon far fa-question-circle"></i>
                      <span className="text">
                        {this.props.t("helpScr-contact")}
                      </span>
                    </Link>
                    <a
                      className="dropdown-item"
                      href="https://www.pikmykid.com/support/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon fas fa-envelope-open-text"></i>
                      <span className="text">
                        {this.props.t("helpScr-FAQ")}
                      </span>
                    </a>
                    <a
                      className="dropdown-item"
                      href="https://schools.pikmykid.com/pikmykid/account/termsconditions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon far fa-file-alt"></i>
                      <span className="text">
                        {this.props.t("settingMainScr-terms")}
                      </span>
                    </a>
                    <Link
                      to={"#"}
                      className="dropdown-item theme-selection"
                      onClick={this.openThemeSelectionModal}
                      aria-label={this.props.t("ThemeSelection")}
                    >
                      <i className="menu-icon icon"></i>

                      <span className="text">
                        {this.props.t("ThemeSelection")}
                      </span>
                    </Link>
                    <Link
                      to={"#"}
                      className="dropdown-item"
                      onClick={this.submit}
                    >
                      <i className="icon fas fa-sign-out-alt"></i>
                      <span className="text">
                        {this.props.t("settingMainScr-signOut")}
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
              <ul className="nav bottom-menu">
                <li
                  className={
                    webPage === "/childManagement"
                      ? "nav-item children-menu active"
                      : "nav-item children-menu"
                  }
                >
                  <Link to="/childManagement">
                    <FontAwesomeIcon icon={faSmileBeam} className="menu-icon" />
                    <span className="text">{this.props.t("Children_Tab")}</span>
                  </Link>
                  <em className=" dot"></em>
                </li>
                <li
                  className={
                    webPage === "/checkin"
                      ? "nav-item checkin-menu active"
                      : "nav-item checkin-menu"
                  }
                >
                  <Link className="nav-link " to="/checkin">
                    <i className="menu-icon"></i>
                    <span className="text">
                      {this.props.t("schoolCardScr-checkIn")}
                    </span>
                  </Link>
                  <em className=" dot"></em>
                </li>
                <li
                  className={
                    webPage === "/dashboard"
                      ? "nav-item pickup-menu active"
                      : "nav-item pickup-menu"
                  }
                >
                  <Link className="nav-link" to="/dashboard">
                    <i className="menu-icon"></i>
                    <span className="text">{this.props.t("pickup_title")}</span>
                  </Link>
                  <em className=" dot"></em>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    error: state.userAccount.error,
    backToSchoolFilled: state.userAccount.backToSchoolFilled,
    studentsList: state.student.studentsList,
    schoolInfo: state.student.schoolInfo,
    getBackSchoolInfoData: state.student.getBackSchoolInfo,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doLogoutUser: () => dispatch(logoutUser()),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doGetbacktoschoolInfo: (data) => dispatch(getBackToSchoolInformation(data)),
    saveSelectedTheme: (data) => dispatch(saveSelectedTheme(data)),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MainHeader))
);
