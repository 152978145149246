import React from "react";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/app.css";
import "./Styles/style.css";
import "./Styles/theme-responsive.css";
import { connect } from "react-redux";
import {
  RegisterFooter,
  RegisterHeader,
  NewRegisterWizard,
  withRouter,
} from "../../CommonComponents";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import noAccountExits from "../../assets/registerImages/noAccountExits.svg";
import successImage from "../../assets/registerImages/successImage.svg";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  showModal,
  hideModal,
  setHelpList,
} from "../../../common/actions/userAccount";

import {
  completeRegisterUser,
  completeRegisterUserClear,
  setAppleSSOEmail,
  setAppleSSOEmailClear,
  setGoogleSSOEmail,
  setGoogleSSOEmailClear,
} from "../../../common/actions/userAccount/RegistrationAction";
import { createPassword } from "../../../helper/utility";
import GLOBAL_CONST from "../../../helper/AppConstants";
import { withTranslation } from "react-i18next";
import { createLoadingSelector } from "../../../common/reducers/selectors";
import { Line } from "rc-progress";
import {
  markReadToDashboard,
  nameUpdate,
  nameUpdateClear,
  saveRegistrationInfo,
  sendUserWelcomeEmail,
} from "../../../common/actions/userAccount/userAccountAction";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";

const loadingSelector = createLoadingSelector([
  "set_apple_sso",
  "set_google_sso",
  "complete_registration_user",
  "update_name",
]);

class AccountStatusInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Registration",
      screen: GLOBAL_CONST.SCREEN_NAMES.REGISTRATION_ACCOUNT_STATUS,
      title: this.props.t("regCardScr-regAccount"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  SSOProcess = () => {
    // If no kids then take user to onboarding else on app dashboard.
    const {
      getUserInfoResponse,
      doMarkReadToDashboard,
      userLoginInfo,
      registeredFirstname,
      registeredLastname,
      doSendWelcomeEmail,
    } = this.props;

    if (
      userLoginInfo &&
      (!userLoginInfo.firstName || userLoginInfo.firstName.trim() === "") &&
      registeredFirstname &&
      registeredFirstname !== ""
    ) {
      this.props.doNameUpdate({
        firstName: registeredFirstname,
        lastName: registeredLastname,
        callback: () => {
          doSendWelcomeEmail();
        },
      });
    } else {
      doSendWelcomeEmail();
    }
    if (
      getUserInfoResponse &&
      getUserInfoResponse.Data &&
      (getUserInfoResponse.Data.KidsAtSchools > 0 ||
        getUserInfoResponse.Data.KidsDelegatedCurrently)
    ) {
      this.props.navigate("/childManagement");
      //doMarkReadToDashboard();
    } else {
      this.props.navigate("/newchooseRole", {
        state: { screenFrom: "registration_chooserole" },
      });
    }
  };
  componentDidUpdate() {
    const {
      error,
      appleSSOResponse,
      doSetAppleSSOEmailClear,
      googleSSOResponse,
      doSetGoogleSSOEmailClear,
      completeRegisterUserResponse,
      docompleteRegisterUserClear,
    } = this.props;

    if (completeRegisterUserResponse) {
      docompleteRegisterUserClear();
    }
    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
    }
    if (appleSSOResponse) {
      if (appleSSOResponse.Success === false) {
        toast.error(appleSSOResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        doSetAppleSSOEmailClear();
      } else {
        toast.success(appleSSOResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        this.SSOProcess();
        doSetAppleSSOEmailClear();
      }
    }
    if (googleSSOResponse) {
      if (googleSSOResponse.Success === false) {
        toast.error(googleSSOResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
        doSetGoogleSSOEmailClear();
      } else {
        toast.success(googleSSOResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
        this.SSOProcess();
        doSetGoogleSSOEmailClear();
      }
    }
  }

  saveSSOUserName = (givenName, familyName) => {
    const { userLoginInfo, doSaveRegistrationInfo } = this.props;
    if (
      givenName &&
      familyName &&
      userLoginInfo &&
      (!userLoginInfo.firstName || userLoginInfo.firstName.trim() === "")
    ) {
      doSaveRegistrationInfo({
        registeredFirstname: givenName,
        registeredLastname: familyName,
      });
    }
  };

  doNavigation = async () => {
    const { googleLoginCredentials, appleLoginCredentials, userLoginInfo } =
      this.props;

    if (googleLoginCredentials && googleLoginCredentials.credential) {
      const googleToken = googleLoginCredentials;
      const userInfo = jwtDecode(googleLoginCredentials.credential);
      const googleIdToken = googleLoginCredentials.credential;
      if (!userLoginInfo || !userLoginInfo.userId) {
        const generatedPassword = createPassword();
        this.props.doCompleteRegisterUser({
          Password: generatedPassword,
          Firstname:
            userInfo && userInfo.given_name ? userInfo.given_name : " ",
          Lastname:
            userInfo && userInfo.family_name ? userInfo.family_name : " ",
          callback: (response) => {
            if (
              response &&
              response.LoggedInParentInfo &&
              response.LoggedInParentInfo.AuthTokenID
            ) {
              this.saveSSOUserName(userInfo.given_name, userInfo.family_name);
              this.props.doSetGoogleSSOEmail({
                idToken: googleIdToken,
              });
            }
          },
        });
      } else {
        this.props.doSetGoogleSSOEmail({
          idToken: googleIdToken,
        });
      }
    } else if (appleLoginCredentials && appleLoginCredentials.authorization) {
      if (!userLoginInfo || !userLoginInfo.userId) {
        const appleToken = appleLoginCredentials;

        const generatedPassword = createPassword();
        const fullName =
          appleToken && appleToken.user && appleToken.user.name
            ? appleToken.user.name
            : null;
        this.props.doCompleteRegisterUser({
          Password: generatedPassword,
          Firstname: fullName && fullName.firstName ? fullName.firstName : "",
          Lastname: fullName && fullName.lastName ? fullName.lastName : "",
          callback: (res) => {
            if (
              res &&
              res.LoggedInParentInfo &&
              res.LoggedInParentInfo.AuthTokenID
            ) {
              if (appleToken && appleToken.user && appleToken.user.name) {
                this.saveSSOUserName(
                  appleToken.user.name.firstName,
                  appleToken.user.name.lastName
                );
              } else {
                this.props.doSaveRegistrationInfo({
                  registeredFirstname: "",
                  registeredLastname: "",
                });
              }
              this.props.doSetAppleSSOEmail({
                identityToken: appleToken.authorization.id_token,
              });
            }
          },
        });
      } else {
        const appleToken = appleLoginCredentials;
        this.props.doSetAppleSSOEmail({
          identityToken: appleToken.authorization.id_token,
        });
      }
    } else {
      this.props.navigate("/newregisterEmail", {
        state: { screenFrom: "registration" },
      });
    }
  };

  render() {
    const KidsDelegatedCurrently =
      this.props.location.state.KidsDelegatedCurrently;

    return (
      <section className="registration accountexists-sect">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  <NewRegisterWizard isBackBtnVisible={false} />
                  <div className="probressbar-holder">
                    <Line percent={42} strokeWidth="1" strokeColor="#7AC943" />
                  </div>
                  <div className="container-sect text-center">
                    <div className="content-style1 accountexists-sect">
                      <div className="image-sect text-center mb-40">
                        {this.props.location.state.isExistAccount ? (
                          <img
                            src={successImage}
                            alt="PikMyKid"
                            title="PikMyKid"
                            className="center"
                          />
                        ) : (
                          <img
                            src={noAccountExits}
                            alt="PikMyKid"
                            title="PikMyKid"
                            className="center"
                          />
                        )}
                      </div>
                      <div className="title-sect text-center">
                        <h3 className="congratulations-text">
                          {this.props.location.state.isExistAccount
                            ? this.props.t("Congratulations")
                            : this.props.t("Thankyou")}
                        </h3>

                        <p className="helptext">
                          {this.props.location.state.isExistAccount
                            ? KidsDelegatedCurrently
                              ? this.props.t("regSuccessScr-messageDelegate")
                              : this.props.t("account_exist_message")
                            : this.props.t("noAccountMessage")}
                        </p>
                      </div>

                      <div className="form-group mb-40">
                        <button
                          type="button"
                          onClick={() => {
                            this.props.navigate("/newregisterPhone");
                          }}
                          className="btn needhelp-label"
                        >
                          {this.props.location.state.isExistAccount
                            ? ""
                            : this.props.t("regConPhoneScr-changeNumber")}
                        </button>
                      </div>

                      <div className="form-group text-left">
                        <button
                          type="button"
                          className="btn needhelp-label mb-10"
                          onClick={() => {
                            this.openHelpModal();
                          }}
                        >
                          {this.props.t("regNameScr-needHelp")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            this.doNavigation();
                          }}
                        >
                          {this.props.t("Continue")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <ToastContainer />
                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: loadingSelector(state),
    setHelpListData: state.userAccount.setHelpListData,
    error: state.userAccount.error,

    getUserInfoResponse: state.userAccount.getUserInfoResponse,
    selectedTheme: state.userAccount.selectedTheme,
    appleSSOCredentials: state.userAccount.appleSSOCredentials,
    googleSSOCredentials: state.userAccount.googleSSOCredentials,
    appleSSOResponse: state.userAccount.appleSSOResponse,
    googleSSOResponse: state.userAccount.googleSSOResponse,
    completeRegisterUserResponse:
      state.userAccount.completeRegisterUserResponse,
    registeredFirstname: state.userAccount.registeredFirstname,
    registeredLastname: state.userAccount.registeredLastname,
    registeredPassword: state.userAccount.registeredPassword,
    registeredPhone: state.userAccount.registeredPhone,
    registeredCountryCode: state.userAccount.registeredCountryCode,
    registeredCountryName: state.userAccount.registeredCountryName,

    googleLoginCredentials: state.userAccount.googleLoginCredentials,
    appleLoginCredentials: state.userAccount.appleLoginCredentials,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doSetAppleSSOEmail: (data) => dispatch(setAppleSSOEmail(data)),
    doSetAppleSSOEmailClear: () => dispatch(setAppleSSOEmailClear()),
    doSetGoogleSSOEmail: (data) => dispatch(setGoogleSSOEmail(data)),
    doSetGoogleSSOEmailClear: () => dispatch(setGoogleSSOEmailClear()),
    doMarkReadToDashboard: () => dispatch(markReadToDashboard()),
    doSaveRegistrationInfo: (data) => dispatch(saveRegistrationInfo(data)),
    doCompleteRegisterUser: (data) => dispatch(completeRegisterUser(data)),
    docompleteRegisterUserClear: () => dispatch(completeRegisterUserClear()),
    doNameUpdate: (data) => dispatch(nameUpdate(data)),
    doNameUpdateClear: () => dispatch(nameUpdateClear()),
    doSendWelcomeEmail: () => dispatch(sendUserWelcomeEmail()),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountStatusInfo))
);
