import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "../common/store/configureStore";

export const getDismissedInfo = (item,props) => {
  let dismissalText = "";
  let dismissalTextSubText = "";

  if (item.PM_Name === "After School") {
    dismissalText = item.PM_Name;
    dismissalTextSubText = item.PM_SubName;
  } else if (item.Guardian_First !== "" && item.Guardian_First !== null) {
    dismissalText = `${item.Guardian_First} ${props.t('Picked_Up_Text')} - ${item.PM_Name}`;
    dismissalTextSubText = item.PM_SubName;
  } else {
    if (item.PM_Name === "Bus") {
      dismissalText = `${props.t('Pickup_By_Text')} - ${item.PM_Name}`;
    } else {
      dismissalText = `${props.t('Pickup_Was_By_Text')} - ${item.PM_Name}`;
    }
    dismissalTextSubText = item.PM_SubName;
  }
  return {
    dismissalText,
    dismissalTextSubText:
      dismissalTextSubText === null ? "" : `-${dismissalTextSubText}`,
  };
};
