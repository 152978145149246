import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { showModal, hideModal } from "../../common/actions/userAccount";
import { createLoadingSelector } from "../../common/reducers/selectors";
const loadingSelector = createLoadingSelector(["get_help"]);

class HelpDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { title, record } = this.props;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button
              type="button"
              className="close-btn"
              onClick={this.backToHelptList}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>
          <div className="modal-body">
            <div className="helpDetails">
              <div className="form-card">
                <div className="title-sect">
                  <h5 className="title-3">{record.Title}</h5>
                </div>
                <div className="help-content">
                  <p>{record.HelpText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  closeModal = () => {
    this.props.doHideModal();
  };

  backToHelptList = () => {
    this.props.doHideModal();
  };
}

function mapStateToProps(state) {
  return {
    isFetching: loadingSelector(state),
    setHelpListData: state.userAccount.setHelpListData,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(HelpDetails)
);
