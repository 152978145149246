import React from "react";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "../../Styles/app.css";
import "./Styles/style.css";
import "./Styles/theme-responsive.css";
import { connect } from "react-redux";

import {
  getUserInformation,
  resendOTP,
  resendOTPClear,
  verifyUserOtp,
  verifyUserOtpClear,
} from "../../../common/actions/userAccount/RegistrationAction";
import {
  RegisterFooter,
  RegisterHeader,
  SubmitButton,
  NewRegisterWizard,
  withRouter,
} from "../../CommonComponents";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { createLoadingSelector } from "../../../common/reducers/selectors";
import { Slide, toast, ToastContainer } from "react-toastify";
import { getLanguageCode } from "../../../helper/utility";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import { Line } from "rc-progress";
const loadingSelector = createLoadingSelector([
  "verify_otp",
  "add_phone_number_user",
]);

class ConfirmPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      otpError: "",
    };
  }

  handleChange = (otp) => {
    this.setState({ otp, otpError: "" });
    // if (otp.length == 7) {
    //   setTimeout(() => {
    //     this.submitForm();
    //   }, 100);
    // }
  };

  submitForm = () => {
    const { otp } = this.state;
    const { doVerifyUserOtp } = this.props;
    if (otp === "") {
      this.setState({
        otpError: `${this.props.t("confirmPhoneScr-valid-otpError")}`,
      });
      return;
    }

    doVerifyUserOtp({ OtpCode: otp });
  };

  resendCode = () => {
    const { doResendOTP, registeredPhone, registeredCountryCode } = this.props;

    doResendOTP({
      PhoneNumber: registeredPhone,
      CountryCode: registeredCountryCode,
      ResendOTP: true,
      DeviceType: 6,
      Locale: getLanguageCode(),
    });
  };

  buildTitle = () => {
    const { registeredPhone, registeredCountryCode } = this.props;
    let showPhoneNumber = registeredPhone;

    if (showPhoneNumber !== null && showPhoneNumber !== undefined) {
      showPhoneNumber = showPhoneNumber
        .replace(/\D+/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    } else {
      showPhoneNumber = "";
    }
    return (
      <div className="title-sect mb-40">
        <h3 className="title-3">
          {this.props.t("regConPhoneScr-verifyNumber")}
        </h3>
        <p className="helptext font-sm">
          {this.props.t("regConPhoneScr-confirmCode")} <br />
          <span className="orange-text">
            {" "}
            {registeredCountryCode + " " + showPhoneNumber}
          </span>
        </p>
        <p>
          <Link to="/newregisterPhone">
            {this.props.t("regConPhoneScr-changeNumber")}
          </Link>
        </p>
      </div>
    );
  };

  nextScreen = (response) => {
    if (
      response &&
      response.Data &&
      (response.Data.KidsAtSchools > 0 ||
        response.Data.KidsDelegatedCurrently > 0)
    ) {
      this.props.navigate("/accountstatusinfo", {
        state: {
          isExistAccount: true,
          KidsDelegatedCurrently:
            response &&
            response.Data &&
            response.Data.KidsDelegatedCurrently > 0
              ? true
              : false,
        },
      });
    } else {
      this.props.navigate("/accountstatusinfo", {
        state: {
          isExistAccount: false,
          KidsDelegatedCurrently: false,
        },
      });
    }
  };

  componentDidUpdate() {
    const {
      verifyOtpResponse,
      otpError,
      doVerifyUserOtpClear,
      reSendOtpResponse,
      doResendOTPClear,
      doGetUserInformation,
    } = this.props;

    if (otpError) {
      toast.error(otpError.Message || otpError.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doVerifyUserOtpClear();
    } else if (verifyOtpResponse) {
      doGetUserInformation({
        callback: this.nextScreen,
      });
      doVerifyUserOtpClear();
    } else if (reSendOtpResponse) {
      if (reSendOtpResponse.Success === true) {
        toast.success(reSendOtpResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
      } else {
        toast.info(reSendOtpResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
        });
      }
      doResendOTPClear();
    }
  }

  render() {
    const { otpError } = this.state;
    const { isFetching } = this.props;

    return (
      <section className="registration confirmphone-sect">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  <NewRegisterWizard
                    webPage={this.props.location.pathname}
                    isBackBtnVisible={true}
                  />
                  <div className="probressbar-holder">
                    <Line percent={28} strokeWidth="1" strokeColor="#7AC943" />
                  </div>
                  <div className="container-sect">
                    <form className="form-style1">
                      {this.buildTitle()}
                      <div className="form-group phone-otp">
                        <OtpInput
                          inputStyle="inputStyle form-control input-sm"
                          inputType="tel"
                          value={this.state.otp}
                          onChange={this.handleChange}
                          numInputs={7}
                          shouldAutoFocus
                          isInputNum
                          renderInput={(props) => <input {...props} />}
                        />
                        <span className="error" id="message">
                          {" "}
                          {otpError}
                        </span>
                      </div>
                      <div className="form-group mb-40">
                        <a
                          onClick={() => {
                            this.resendCode();
                          }}
                          className="btn needhelp-label"
                        >
                          {this.props.t("regConPhoneScr-resendCode")}
                        </a>
                      </div>
                      <SubmitButton
                        nextAction={this.submitForm}
                        loading={isFetching}
                      />
                    </form>
                  </div>
                  <ToastContainer />
                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    registeredPhone: state.userAccount.registeredPhone,
    registeredCountryCode: state.userAccount.registeredCountryCode,
    verifyOtpResponse: state.userAccount.verifyOtpResponse,
    registeredToken: state.userAccount.registeredToken,
    isFetching: loadingSelector(state),
    error: state.userAccount.error,
    otpError: state.userAccount.otpError,
    reSendOtpResponse: state.userAccount.reSendOtpResponse,
    getUserInfoResponse: state.userAccount.getUserInfoResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doVerifyUserOtp: (data) => dispatch(verifyUserOtp(data)),
    doVerifyUserOtpClear: () => dispatch(verifyUserOtpClear()),
    doResendOTP: (data) => dispatch(resendOTP(data)),
    doResendOTPClear: () => dispatch(resendOTPClear()),
    doGetUserInformation: (data) => dispatch(getUserInformation(data)),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmPhone))
);
