import React from "react";

import {
  logoutUser,
  accountDelete,
  accountDeleteClear,
} from "../../../common/actions/userAccount";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "../../../../node_modules//bootstrap/dist/css/bootstrap.min.css";

import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import "../../Styles/app.css";
import "../NewRegistration/Styles/style.css";
import "../LandingPage/landing.css";
import "../NewRegistration/Styles/theme-responsive.css";
import { createLoadingSelector } from "../../../common/reducers/selectors";

import { Slide, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import {
  showModal,
  hideModal,
} from "../../../common/actions/userAccount/userAccountAction";

const loadingSelector = createLoadingSelector(["account_delete"]);

function withRouterHook(Component) {
  return function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component navigate={navigate} location={location} {...props} />;
  };
}

class DeleteAccountModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { error, deleteAccount, doDeleteAccountClear } = this.props;

    if (error) {
      this.props.doHideModal();
      doDeleteAccountClear();
    } else if (deleteAccount != null) {
      if (deleteAccount.Success) {
        toast.success(deleteAccount.Data, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        this.props.doHideModal();
        this.props.doLogoutUser();
        this.props.navigate("/");
      } else {
        toast.error(deleteAccount.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
      }
      doDeleteAccountClear();
    }
  }

  submitDeleteAccount = () => {
    const { userLoginInfo, password } = this.props;

    const { doDeleteAccount } = this.props;

    let email = userLoginInfo.Email;
    email = email.replace(".staging", "");
    email = email.replace(".develop", "");

    doDeleteAccount({
      email,
      password,
    });
  };

  cancelDeleteAccount = () => {
    const { doHideModal, doDeleteAccountClear } = this.props;
    doDeleteAccountClear();
    doHideModal();
  };

  render() {
    const { isFetching } = this.props;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content react-confirm-alert-body">
          <div className="modal-body">
            <div className="custom-ui">
              <h1 className="confrimalert-title">
                {this.props.t("deleteAccount")}
              </h1>
              <p>
                <label className="control-label">
                  Are you sure you want to delete your Pikmykid account? Please
                  note that this action will permanently delete your account.
                </label>
              </p>

              <div className="react-confirm-alert-button-group mt-30">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => {
                    this.submitDeleteAccount();
                  }}
                >
                  {" "}
                  {isFetching ? (
                    <i className="fas fa-sync-alt fa-spin"></i>
                  ) : null}{" "}
                  {this.props.t("DELETE")}
                </button>
                <button
                  className="cancel-btn"
                  onClick={() => {
                    this.cancelDeleteAccount();
                  }}
                >
                  {this.props.t("regNameScr-cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    isFetching: loadingSelector(state),
    error: state.userAccount.error,

    deleteAccount: state.userAccount.deleteAccount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doDeleteAccount: (data) => dispatch(accountDelete(data)),
    doDeleteAccountClear: () => dispatch(accountDeleteClear()),
    doLogoutUser: () => dispatch(logoutUser()),

    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
  };
}

export default withTranslation()(
  withRouterHook(
    connect(mapStateToProps, mapDispatchToProps)(DeleteAccountModal)
  )
);
