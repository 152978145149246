import axios from "axios";

import { store } from "../store/configureStore";

const STAGE = "https://stagingparent.pikmykid.com/api/";
const PROD = "https://parentv3api.pikmykid.com/api/";
const DEVELOP = 'https://developparent.pikmykid.com/api/';
const LOCALHOST = 'http://localhost:54947/api/';


export const getHTTPHeader = () => {
	const state = store.getState();
	let AuthTokenID;
	// console.log("getHTTPHeader state ", state);
	const { userAccount } = state;
	if (userAccount) {
		const { userLoginInfo } = userAccount;
		if (userLoginInfo) {
			AuthTokenID = userLoginInfo.AuthTokenID;
		}
	}
	const headers = {
		"Content-Type": "application/json",
		AuthTokenID: `${AuthTokenID}`,
	};
	// console.log(`headers ${JSON.stringify(headers)}`);
	return headers;
};

export const getEnvironment = () => {
	const state = store.getState();
	const { userAccount } = state;
	const { environment } = userAccount;
	// console.log("getEnvironment ", environment);
	if (environment === "staging") {
		return STAGE;
	} if (environment === "develop") {
		return DEVELOP;
	} if (environment === "localhost") {
		return LOCALHOST;
	}
	return PROD;
};

export const getEnvironmentURL = (environment) => {
	if (environment === "staging") {
		return STAGE;
	} if (environment === "develop") {
		return DEVELOP;
	} if (environment === "localhost") {
		return LOCALHOST;
	}
	return PROD;
};

export const getAxios = () => {
	const defaults = {
		timeout: 10000,
		timeoutErrorMessage: "Please check your internet connection.",
		validateStatus: function (status) {
			return status < 500; // Resolve only if the status code is less than 500
		},
		headers: getHTTPHeader(),
	};
	return axios.create(defaults);
};
