import React from "react";
import { isLoggedIn, isSSOLogin, isSSOVersion } from "../../helper/utility";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  history,
  store,
  persistor,
} from "../../../src/common/store/configureStore";

function PrivateRoute({ children }) {
  if (!isLoggedIn() || !isSSOVersion()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" state={{ from: history.location }} />;
  }
  // authorized so return child components
  return children;
}

export default PrivateRoute;
