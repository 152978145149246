import React from "react";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "../../Styles/app.css";
import "./Styles/style.css";
import "./Styles/theme-responsive.css";
import { connect } from "react-redux";
import {
  showModal,
  hideModal,
  setHelpList,
  saveRegistrationInfo,
  passwordUpdate,
  passwordUpdateClear,
} from "../../../common/actions/userAccount";
import GLOBAL_CONST from "../../../helper/AppConstants";
import { nextField } from "../../../helper/utility";
import { Line } from "rc-progress";
import { Slide, toast } from "react-toastify";

import {
  setLoginType,
  sendUserWelcomeEmail,
} from "../../../common/actions/userAccount/userAccountAction";

import {
  RegisterFooter,
  RegisterHeader,
  SubmitButton,
  NewRegisterWizard,
  withRouter,
} from "../../CommonComponents";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import { withTranslation } from "react-i18next";
import { createLoadingSelector } from "../../../common/reducers/selectors";

const loadingSelector = createLoadingSelector([
  "complete_registration_user",
  "update_password",
]);

class RegisterPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      repeatPassword: "",
      passwordStrength: "",
      passwordInvalid: "",
      confirmError: "",
      termsAccepted: false,
      hidePassword: true,
      hideConfirmPassword: true,
      termAcceptError: "",
      isRegistered: false,
    };
    this.dosetHelpList();
  }

  componentDidMount() {
    this.dosetHelpList();
  }
  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Registration",
      screen: GLOBAL_CONST.SCREEN_NAMES.REGISTRATION_PASSWORD,
      title: this.props.t("regCardScr-regAccount"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  acceptTermAndCondition = () => {
    this.setState({ termsAccepted: !this.state.termsAccepted });
  };
  setPasswordVisibility = () => {
    this.setState({ hidePassword: !this.state.hidePassword });
  };

  setConfirmPasswordVisibility = () => {
    this.setState({ hideConfirmPassword: !this.state.hideConfirmPassword });
  };
  handlePassword = (event) => {
    const rating = [
      0,
      "Weak",
      25,
      "Medium",
      50,
      "Good",
      75,
      "Strong",
      100,
      "Very Strong",
    ];

    let score = 0;
    let pass = "";
    if (event.target.value.length > 8 && event.target.value.match(/[0-9]/)) {
      score += 25;
    }
    if (
      event.target.value.length > 4 &&
      event.target.value.match(/[a-z]/) &&
      event.target.value.match(/[A-Z]/)
    ) {
      score += 25;
    }
    if (event.target.value.match(/[\!\@\#\$\%\^\&\*\?\_\~\-\(\)]+/)) {
      score += 25;
    }
    for (let i = rating.length - 1; i >= 0; i -= 1) {
      if (score >= rating[i]) {
        pass = rating[i + 1];
        break;
      }
    }

    this.setState({
      passwordStrength: pass,
      password: event.target.value,
      passwordInvalid: "",
    });
  };
  secondTextInput(event) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    if (event.key === "Enter") {
      form.elements[index + 1].focus();
    }
  }
  handleConfirmPassword = (e) => {
    this.setState({
      repeatPassword: e.target.value,
      confirmError: "",
    });
  };
  handletermAccespted = (e) => {
    this.setState({ termsAccepted: e.target.checked, termAcceptError: "" });
  };
  renderPasswordString = () => {
    const { password, passwordStrength } = this.state;
    if (password !== "") {
      if (passwordStrength === "Weak") {
        return <span className="error">{this.props.t("regPwdScr-weak")}</span>;
      }
      if (passwordStrength === "Good") {
        return (
          <span className="orange-text">{this.props.t("regPwdScr-good")}</span>
        );
      }
      if (passwordStrength === "Medium") {
        return (
          <span className="orange-text">
            {this.props.t("regPwdScr-medium")}
          </span>
        );
      }
      if (passwordStrength === "Strong" || passwordStrength === "Strong") {
        return (
          <span className="green-text">{this.props.t("regPwdScr-strong")}</span>
        );
      }
    }
    return <span>-</span>;
  };

  SSOProcess = () => {
    // If no kids then take user to onboarding else on app dashboard.
    const { getUserInfoResponse } = this.props;

    let screenFrom = null;
    const { location } = this.props;
    if (location && location.state) {
      screenFrom = location.state.screenFrom;
    }
    if (
      screenFrom === "dashboard" ||
      screenFrom === "childManagement" ||
      screenFrom === "checkin" ||
      screenFrom === "account"
    ) {
      this.props.navigate(-1);
      this.props.navigate(-1);
    }
    if (
      getUserInfoResponse &&
      getUserInfoResponse.Data &&
      (getUserInfoResponse.Data.KidsAtSchools > 0 ||
        getUserInfoResponse.Data.KidsDelegatedCurrently)
    ) {
      this.props.navigate("/childManagement");
    } else {
      this.props.navigate("/newchooseRole", {
        state: { screenFrom: "registration_chooserole" },
      });
    }
  };

  async saveNewCredentials() {
    try {
      const credentials = this.props.loginCredentials;

      if (credentials && credentials.password) {
        this.props.doPasswordUpdate({
          oldPassword: credentials.password,
          newPassword: this.state.password,
        });
        // this.saveCredentials();
        this.props.doSetLoginType(GLOBAL_CONST.LOGIN_TYPE.EMAIL_OR_PHONE);
        this.props.doPasswordUpdateClear();
      }
    } catch (err) {
      console.log("credentials loadCredentials error ", err);
    }
  }

  doNavigation = () => {
    const { getUserInfoResponse, doSendWelcomeEmail, doPasswordUpdateClear } =
      this.props;
    this.saveNewCredentials();
    doSendWelcomeEmail();
    if (
      getUserInfoResponse &&
      getUserInfoResponse.Data &&
      (getUserInfoResponse.Data.KidsAtSchools > 0 ||
        getUserInfoResponse.Data.KidsDelegatedCurrently)
    ) {
      doPasswordUpdateClear();

      this.props.navigate("/childManagement");
    } else {
      doPasswordUpdateClear();

      this.props.navigate("/newchooseRole", {
        state: { screenFrom: "registration_chooserole" },
      });
    }
  };

  componentDidUpdate() {
    const {
      completeRegisterUserResponse,
      error,
      appleSSOResponse,
      googleSSOResponse,
      updatePasswordResponse,
      doPasswordUpdateClear,
    } = this.props;

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
    }
    if (updatePasswordResponse) {
      doPasswordUpdateClear();
    }
  }

  submitForm = () => {
    const { password, repeatPassword } = this.state;
    const { doSaveRegistrationInfo } = this.props;
    if (
      password === null ||
      password === "" ||
      repeatPassword === null ||
      repeatPassword === "" ||
      password.length <= 5
    ) {
      this.setState({
        passwordInvalid: this.props.t("regPwdScr-pwdInvalid"),
      });
      return;
    }
    if (password !== repeatPassword) {
      this.setState({
        passwordInvalid: this.props.t("regPwdScr-pwdNotMatch"),
      });
      return;
    }
    if (password === repeatPassword) {
      doSaveRegistrationInfo({ registeredPassword: password });
      this.doNavigation();
    }
  };

  render() {
    const {
      hidePassword,
      hideConfirmPassword,
      password,
      repeatPassword,
      passwordInvalid,
      confirmError,
      termsAccepted,
      termAcceptError,
    } = this.state;
    const { isFetching } = this.props;

    return (
      <section className="registration accountexists-sect">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  <NewRegisterWizard isBackBtnVisible={true} />
                  <div className="probressbar-holder">
                    <Line percent={100} strokeWidth="1" strokeColor="#7AC943" />
                  </div>
                  <div className="container-sect">
                    <form className="form-style1">
                      <div className="title-sect mb-40">
                        <h3 className="title-3">
                          {this.props.t("regPwdScr-passwordTitle")}
                        </h3>
                        <p className="helptext">
                          {this.props.t("regPwdScr-passwordDescript")}
                        </p>
                      </div>
                      <div className="form-group">
                        <input
                          type={hidePassword ? "password" : "text"}
                          className={`form-control ${
                            passwordInvalid !== "" ? "input-error" : null
                          }`}
                          placeholder={this.props.t("regPwdScr-enterPassword")}
                          value={password}
                          onChange={this.handlePassword}
                          name="password"
                          maxLength={16}
                          onKeyDown={(event) => nextField(event)}
                        />
                        <i
                          onClick={this.setPasswordVisibility}
                          className={
                            hidePassword
                              ? "far fa-eye togglePassword"
                              : "far fa-eye-slash togglePassword1"
                          }
                        ></i>
                        <span className="error" id="message">
                          {" "}
                          {passwordInvalid}
                        </span>
                      </div>

                      <div className="form-group">
                        <input
                          type={hideConfirmPassword ? "password" : "text"}
                          className={`form-control ${
                            confirmError !== "" ? "input-error" : null
                          }`}
                          placeholder={this.props.t(
                            "regPwdScr-confirmPassword"
                          )}
                          value={repeatPassword}
                          onChange={this.handleConfirmPassword}
                          maxLength={16}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") this.submitForm();
                          }}
                        />
                        <i
                          onClick={this.setConfirmPasswordVisibility}
                          className={
                            hideConfirmPassword
                              ? "far fa-eye togglePassword"
                              : "far fa-eye-slash togglePassword1"
                          }
                        ></i>

                        <span className="error" id="message">
                          {confirmError != ""
                            ? confirmError
                            : termAcceptError != ""
                            ? termAcceptError
                            : null}
                        </span>
                      </div>
                      <div className="form-group">
                        <p className="font-sm pass-check">
                          {this.props.t("regPwdScr-strength")}:
                          {this.renderPasswordString()}{" "}
                        </p>
                      </div>

                      <div className="form-group">
                        <button
                          type="button"
                          className="btn needhelp-label"
                          onClick={() => {
                            this.openHelpModal();
                          }}
                        >
                          {this.props.t("regNameScr-needHelp")}
                        </button>
                      </div>
                      <SubmitButton
                        nextAction={this.submitForm}
                        loading={isFetching}
                      />
                    </form>
                  </div>

                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    setHelpListData: state.userAccount.setHelpListData,
    error: state.userAccount.error,
    isFetching: loadingSelector(state),

    registeredPassword: state.userAccount.registeredPassword,
    registeredFirstname: state.userAccount.registeredFirstname,
    registeredLastname: state.userAccount.registeredLastname,
    registeredPhone: state.userAccount.registeredPhone,
    registeredCountryCode: state.userAccount.registeredCountryCode,
    registeredCountryName: state.userAccount.registeredCountryName,
    completeRegisterUserResponse:
      state.userAccount.completeRegisterUserResponse,
    getUserInfoResponse: state.userAccount.getUserInfoResponse,
    updatePasswordResponse: state.userAccount.updatePasswordResponse,

    loginCredentials: state.userAccount.loginCredentials,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doSaveRegistrationInfo: (data) => dispatch(saveRegistrationInfo(data)),
    doPasswordUpdate: (data) => dispatch(passwordUpdate(data)),
    doPasswordUpdateClear: () => dispatch(passwordUpdateClear()),
    doSetLoginType: (data) => dispatch(setLoginType(data)),
    doSendWelcomeEmail: () => dispatch(sendUserWelcomeEmail()),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPassword))
);
