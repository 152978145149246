// start Registartion
export const START_REGISTARTION_START = "start_registartion_start";
export const START_REGISTARTION_SUCCESS = "start_registartion_success";
export const START_REGISTARTION_FAILED = "start_registartion_failed";
export const START_REGISTARTION_CLEAR = "start_registartion_clear";

// resend otp

export const RE_SEND_OTP_START = "re_send_otp_start";
export const RE_SEND_OTP_SUCCESS = "re_send_otp_success";
export const RE_SEND_OTP_FAILED = "re_send_otp_failed";
export const RE_SEND_OTP_CLEAR = "re_send_otp_clear";

// verify otp

export const VERIFY_OTP_START = "verify_otp_start";
export const VERIFY_OTP_SUCCESS = "verify_otp_success";
export const VERIFY_OTP_FAILED = "verify_otp_failed";
export const VERIFY_OTP_CLEAR = "verify_otp_clear";

// user information

export const GET_USER_INFO_START = "get_user_info_start";
export const GET_USER_INFO_SUCCESS = "get_user_info_success";
export const GET_USER_INFO_FAILED = "get_user_info_failed";
export const GET_USER_INFO_CLEAR = "get_user_info_clear";

// Register user
export const COMPLETE_REGISTRATION_USER_START =
  "complete_registration_user_start";
export const COMPLETE_REGISTRATION_USER_SUCCESS =
  "complete_registration_user_success";
export const COMPLETE_REGISTRATION_USER_FAILED =
  "complete_registration_user_failed";
export const COMPLETE_REGISTRATION_USER_CLEAR =
  "complete_registration_user_clear";

// Check Email
export const CHECK_EMAIL_START = "check_email_start";
export const CHECK_EMAIL_SUCCESS = "check_email_success";
export const CHECK_EMAIL_FAILED = "check_email_failed";
export const CHECK_EMAIL_CLEAR = "check_email_clear";

// set Email
export const SET_EMAIL_START = "set_email_start";
export const SET_EMAIL_SUCCESS = "set_email_success";
export const SET_EMAIL_FAILED = "set_email_failed";
export const SET_EMAIL_CLEAR = "set_email_clear";

// Verification Email

export const VERIFIED_EMAIL_START = "verified_email_start";
export const VERIFIED_EMAIL_SUCCESS = "verified_email_success";
export const VERIFIED_EMAIL_FAILED = "verified_email_failed";
export const VERIFIED_EMAIL_CLEAR = "verified_email_clear";

// Resend Email
export const RE_SEND_EMAIL_START = "re_send_email_start";
export const RE_SEND_EMAIL_SUCCESS = "re_send_email_success";
export const RE_SEND_EMAIL_FAILED = "re_send_email_failed";
export const RE_SEND_EMAIL_CLEAR = "re_send_email_clear";

// Check SSO account
export const CHECK_EMAIL_EXIST_START = "check_email_exist_start";
export const CHECK_EMAIL_EXIST_SUCCESS = "check_email_exist_success";
export const CHECK_EMAIL_EXIST_FAILED = "check_email_exist_failed";
export const CHECK_EMAIL_EXIST_CLEAR = "check_email_exist_clear";

// Check SSO account
export const SSO_EXIST_START = "sso_exist_start";
export const SSO_EXIST_SUCCESS = "sso_exist_success";
export const SSO_EXIST_FAILED = "sso_exist_failed";
export const SSO_EXIST_CLEAR = "sso_exist_clear";

// Mark Registration Complete
export const MARK_REGISTRATION_COMPLETE = "mark_registration_complete";

// Google SSO
export const GOOGLE_SSO_RESPONSE_SAVE = "google_sso_response_save";
export const GOOGLE_SSO_RESPONSE_CLEAR = "google_sso_response_clear";

// Apple SSO
export const APPLE_SSO_RESPONSE_SAVE = "google_sso_response_save";
export const APPLE_SSO_RESPONSE_CLEAR = "google_sso_response_clear";

export const DO_APPLE_LOGIN_START = "do_apple_login_start";
export const DO_APPLE_LOGIN_SUCCESS = "do_apple_login_success";
export const DO_APPLE_LOGIN_FAILED = "do_apple_login_failed";
export const DO_APPLE_LOGIN_CLEAR = "do_apple_login_clear";

export const SET_APPLE_SSO_START = "set_apple_sso_start";
export const SET_APPLE_SSO_SUCCESS = "set_apple_sso_success";
export const SET_APPLE_SSO_FAILED = "set_apple_sso_failed";
export const SET_APPLE_SSO_CLEAR = "set_apple_sso_clear";

export const DO_GOOGLE_LOGIN_START = "do_google_login_start";
export const DO_GOOGLE_LOGIN_SUCCESS = "do_google_login_success";
export const DO_GOOGLE_LOGIN_FAILED = "do_google_login_failed";
export const DO_GOOGLE_LOGIN_CLEAR = "do_google_login_clear";

export const SET_GOOGLE_SSO_START = "set_google_sso_start";
export const SET_GOOGLE_SSO_SUCCESS = "set_google_sso_success";
export const SET_GOOGLE_SSO_FAILED = "set_google_sso_failed";
export const SET_GOOGLE_SSO_CLEAR = "set_google_sso_clear";


