import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEng from "./locales/en/translation.json";
import translationSpanish from "./locales/es/translation.json";
i18next.use(LanguageDetector).init({
  debug: true,
  fallbackLng: "en", // use en if detected lng is not available
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  resources: {
    en: {
      translations: translationEng,
    },
    es: {
      translations: translationSpanish,
    },
  },
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
});

export default i18next;
