import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { showModal, hideModal } from "../../common/actions/userAccount";

import RingLoader from "react-spinners/ClipLoader";

import { setEnvironment } from "../../common/actions/userAccount/userAccountAction";

import { Dropdown } from "react-bootstrap";

const select_server = [
  { key: 0, section: true, label: "Set Environment", value: 0 },
  { key: 1, label: "Staging", value: 1 },
  { key: 2, label: "Develop", value: 2 },
  { key: 3, label: "Live", value: 3 },
  { key: 4, label: "localhost", value: 4 },
];

class ServerSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      env: "prod",
      clickCount: 0,
      isShowModal: false,
      envPassword: "",
      isShowServerSelect: false,
      passwordError: "",
    };
  }

  getServerNameByKey = (value) => {
    for (let i = 0; i < select_server.length; i++) {
      if (parseInt(value) === parseInt(select_server[i].value)) {
       // console.log("select_server[i]", select_server[i].label);
        return select_server[i].label;
      }
    }
  };

  enableEnv = () => {
    const { envPassword } = this.state;
    const valid = envPassword === "1@910Ab";
    this.setState({ clickCount: 0, envPassword: "" }, () => {
      setTimeout(() => {
        if (valid) {
          this.setState({ isShowServerSelect: true });
        } else {
          this.setState({
            passwordError: this.props.t("loginScreen-passwordError"),
          });
        }
      }, 200);
    });
  };

  handlePasswordChange = ({ target: { value: envPassword } }) => {
    this.setState({ envPassword: envPassword, passwordError: "" });
  };

  renderPasswordView = () => {
    const { envPassword, passwordError } = this.state;
    return (
      <div className="selectserver-form">
        <div className="form-card-title">
          {this.props.t("regPwdScr-enterPassword")}
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder={this.props.t("loginScreen-password")}
            value={envPassword}
            onChange={this.handlePasswordChange}
            name="password"
          />
          <span className="error">{passwordError}</span>
        </div>

        <div className="react-confirm-alert-button-group">
          <button
            type="button"
            onClick={() => {
              this.enableEnv();
            }}
          >
            {this.props.t("SUBMIT")}
          </button>
          <button
            type="button"
            className="cancel-btn"
            onClick={() => {
              this.closeModal();
            }}
          >
            {this.props.t("regNameScr-cancel")}
          </button>
        </div>
      </div>
    );
  };

  renderServerSelectView = () => {
    const { doSetEnvironment, doCallbackRefresh } = this.props;
    let selectedServerKey = 0;
    //this.setState({ isShowModal: true });
    // console.log("selected server key", selectedServerKey);
    // console.log("selected server", select_server);
    return (
      <div className="selectserver-form">
        <div className="form-card-title">
          {this.props.t("select_server_text")}
        </div>
        <div className="form-group">
          <Dropdown
            className="custom-dropdown"
            onSelect={(eventKey) => {
              selectedServerKey = eventKey;

              doSetEnvironment({
                env: this.getServerNameByKey(selectedServerKey).toLowerCase(),
              });
              if (doCallbackRefresh) doCallbackRefresh();
            }}
          >
            <Dropdown.Toggle>
              {this.getServerNameByKey(selectedServerKey)}
              {select_server.length === 0 && (
                <RingLoader color="#2d3d56" loading={true} size={20} />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {select_server.map((data) => {
                return (
                  <Dropdown.Item
                    className={
                      parseInt(selectedServerKey) === parseInt(data.value)
                        ? "active"
                        : ""
                    }
                    eventKey={data.value}
                    key={data.key}
                  >
                    <span className="dropdownitem-label">{data.label}</span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  render() {
    const { isShowServerSelect } = this.state;

    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="form form-style2">
              {isShowServerSelect
                ? this.renderServerSelectView()
                : this.renderPasswordView()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  closeModal = () => {
    this.props.doHideModal();
  };
}

function mapStateToProps(state) {
  return {
    environment: state.userAccount.environment,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doSetEnvironment: (data) => dispatch(setEnvironment(data)),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ServerSelect)
);
