import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import createRootReducer from "../reducers";
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose } from "redux";

import {
  configureStore,
  createImmutableStateInvariantMiddleware,
} from "@reduxjs/toolkit";

import { createReduxHistoryContext } from "redux-first-history";
import { legacy_createStore as createStore } from "redux";
import { encryptTransform } from "redux-persist-transform-encrypt";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

let history = createBrowserHistory();

const expireReducer = require("redux-persist-expire");

const persistConfig = {
  key: "pmk",
  storage,
  whitelist: ["userAccount"],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_SECRET_KEY,
      onError: function (error) {
        window.location.reload();
      },
    }),
    expireReducer("preference", {
      expireSeconds: 3600,
    }),
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
  ignoredPaths: ["ignoredPath", "ignoredNested.one", "ignoredNested.two"],
});
const preloadedState = {};
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //   serializableCheck: {
      //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //   },
      serializableCheck: false,
      immutableCheck: false,
    }),
  preloadedState,
});

const persistor = persistStore(store);

export { history, store, persistor };
