import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "../CommonComponents/withRouter";

import {
  showModal,
  hideModal,
  saveNextAskMeDate,
} from "../../common/actions/userAccount";
import {
  getBackToSchoolInformation,
  getStudentsList,
} from "../../common/actions/students";

import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { createLoadingSelector } from "../../common/reducers/selectors";

const loadingSelector = createLoadingSelector([
  "get_back_school",
  "get_students",
]);

class BackToSchoolKidList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  openBackSchoolEnrollment = (studentData) => {
    const { stackType } = this.props;
    this.props.doShowModal({
      modalScreenName: "backToSchool",
      modalClassName: "backto-school-modal",
      params: {
        studentData: studentData,
        backToSchoolInfoData: studentData.backToSchoolData,
        isSingleKid: false,
        stackType,
        doCallbackRefresh: this.doRefresh,
      },
    });
  };

  openContactsModal = () => {
    this.props.doShowModal({
      modalScreenName: "SelectContact",
      modalClassName: "contact-list-modal sidebar-modal right",
      params: {
        manageDelegate: true,
        showEmergencyContactAlert: true,
        setSelectedDelegate: () => {},
      },
    });
  };

  buildList = () => {
    const { studentsList } = this.props;
    const students = studentsList && studentsList.TodaysStudents;
    if (students && students) {
      const { serverBackToSchoolData } = this.props;
      const studentRecords = [...students];
      const kidsList = [];
      for (let j = 0; j < studentRecords.length; j++) {
        if (studentRecords[j].IsDelegation === false) {
          const matchRecord = serverBackToSchoolData.find(
            (item) => studentRecords[j].StudentID === item.StudentId
          );
          if (matchRecord) studentRecords[j].backToSchoolData = matchRecord;

          kidsList.push(studentRecords[j]);
        }
      }

      return this.renderKidsList(kidsList);
    }
  };

  doRefresh = () => {
    const { doGetbacktoschoolInfo, doGetStudentsList } = this.props;
    this.setState({
      isPullRefresh: true,
    });
    doGetbacktoschoolInfo({});
    doGetStudentsList({
      currenDate: moment().format("MM-DD-YYYY"),
      callback: (data) => {
        const found =
          data && data.TodaysStudents.find((item) => item.AskForBackToSchool);
        if (!found) {
          this.props.doHideModal();
          this.props.navigate("/childManagement");
        }
      },
    });
  };

  renderEmergencyContactView = () => {
    return (
      <div
        className="card form-card form-collapse emergency-contact"
        onClick={() => {
          this.openContactsModal();
        }}
      >
        <div className="form-card-title  expand-collapse-row">
          <div className="fc-titletext-icon">
            <span className="text">{this.props.t("AddemergencyContact")}</span>
          </div>
        </div>
      </div>
    );
  };

  renderKidsList = (studentRecords) => {
    return (
      <div className="backtoschool-studentlist">
        {studentRecords &&
          studentRecords.map((item) => (
            <div
              className={
                !item.AskForBackToSchool
                  ? this.props.stackType === "Setting"
                    ? "child-card"
                    : "child-card added-askforbacktoschool"
                  : "child-card"
              }
              key={"student-" + item.StudentID}
              onClick={() => {
                if (item.AskForBackToSchool) {
                  this.openBackSchoolEnrollment(item);
                } else if (this.props.stackType === "Setting") {
                  this.openBackSchoolEnrollment(item);
                } else {
                  toast.error(
                    item.Firstname +
                      " " +
                      item.Lastname +
                      this.props.t("backToSchool_dataSubmitted"),
                    {
                      autoClose: 2000,
                      transition: Slide,
                      hideProgressBar: true,
                      icon: false,
                    }
                  );
                }
              }}
            >
              <div className="card form-card">
                <div className="form-card-title expand-collapse-row">
                  <div className="fc-titletext-icon">
                    <div className="fc-info">
                      <div className="text mb-4px">
                        {item.Firstname} {""} {item.Lastname}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  closeModal = () => {
    this.props.doHideModal();
  };

  askMeAlerts = () => {
    const { doSaveNextAskMeDate, doCallback } = this.props;
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui askmeAlert">
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <h1>{this.props.t("Back_to_school")}</h1>
                <div className="react-confirm-alert-button-group">
                  <button
                    type="button"
                    onClick={() => {
                      doSaveNextAskMeDate(1);
                      onClose();
                      this.closeModal();
                      if (doCallback) doCallback();
                    }}
                  >
                    {this.props.t("askMelater")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      doSaveNextAskMeDate(2);
                      onClose();
                      this.closeModal();
                      if (doCallback) doCallback();
                    }}
                  >
                    {this.props.t("dontAskMe")}
                  </button>
                  <button className="cancel-btn" onClick={onClose}>
                    {this.props.t("regNameScr-cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  render() {
    const { stackType } = this.props;

    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">{this.props.t("Back_to_school")}</h4>
            </div>

            <button
              type="button"
              className="close-btn"
              onClick={() => {
                stackType === "Setting"
                  ? this.closeModal()
                  : this.askMeAlerts();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>
          <ToastContainer />

          <div className="modal-body">
            <p className="mb-20">{this.props.t("Back_to_school_info")}</p>
            {this.buildList()}
            {this.renderEmergencyContactView()}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    error: state.userAccount.error,

    studentsList: state.student.studentsList,
    serverBackToSchoolData: state.student.getBackSchoolInfo,
    isFetching: loadingSelector(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doGetbacktoschoolInfo: (data) => dispatch(getBackToSchoolInformation(data)),
    doGetStudentsList: (data) => dispatch(getStudentsList(data)),
    doSaveNextAskMeDate: (data) => dispatch(saveNextAskMeDate(data)),
  };
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(BackToSchoolKidList))
);
