import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getDismissalHistoryList,
  getDismissalHistoryListClear,
} from "../../common/actions/students/studentAction";
import { getDismissedInfo } from "../../helper/uiUtility";
import moment from "moment";

class DismissalHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  getRecords = () => {
    let ChildInfo = this.props.child;
    const { doGetDismissalHistoryList } = this.props;

    doGetDismissalHistoryList({
      StudentID: ChildInfo.StudentID,
    });
  };

  componentWillUnmount() {
    const { doGetDismissalHistoryListClear } = this.props;
    let ChildInfo = this.props.child;
    doGetDismissalHistoryListClear({
      StudentID: ChildInfo.StudentID,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible) {
      this.getRecords();
    }
  }
  loadMoreRecords = () => {
    const { isFetching } = this.props;
    if (isFetching) return;
    this.getNotifications();
  };

  render() {
    const { dismissalHistory } = this.props;
    let ChildInfo = this.props.child;
    let records = [];
    if (dismissalHistory) {
      records = dismissalHistory[ChildInfo.StudentID];
    }
    if (!records) records = [];

    return (
      <div className="dismissal-history-col">
        <h3 className="child-data-title">
          {this.props.t("Dismissal_History_Header")}
        </h3>
        <ul
          className="dismissal-history-list list-style1"
          key={`dismissalHistory-${ChildInfo.StudentID}`}
        >
          {records &&
            records.map((item, index) => (
              <li key={`${index}-${ChildInfo.StudentID}-${item.CalendarDate}`}>
                <div className="dism-history-text">
                  {" "}
                  {getDismissedInfo(item, this.props).dismissalText}{" "}
                  <span className="blueSecondary-text">
                    {" "}
                    &nbsp;
                    {getDismissedInfo(item, this.props).dismissalTextSubText}
                  </span>
                </div>
                <span className="dism-history-date">
                  {moment(item.CalendarDate).format("D-MMM")}
                </span>
              </li>
            ))}
        </ul>
        {records.length === 0 && (
          <p className="helptxt">
            <small>{this.props.t("No_Data_Text")}</small>
          </p>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dismissalHistory: state.student.dismissalHistory,
    error: state.student.error,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doGetDismissalHistoryList: (data) =>
      dispatch(getDismissalHistoryList(data)),
    doGetDismissalHistoryListClear: (data) =>
      dispatch(getDismissalHistoryListClear(data)),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DismissalHistory)
);
