import {
  GET_SCHOOL_CHECKIN_START,
  GET_SCHOOL_CHECKIN_SUCCESS,
  GET_SCHOOL_CHECKIN_FAILED,
  GET_SCHOOL_CHECKIN_CLEAR,
  GET_SCHOOL_INFORMATION_START,
  GET_SCHOOL_INFORMATION_SUCCESS,
  GET_SCHOOL_INFORMATION_FAILED,
  SUBMIT_CHECKIN_PARENT_START,
  SUBMIT_CHECKIN_PARENT_SUCCESS,
  SUBMIT_CHECKIN_PARENT_FAILED,
  SUBMIT_CHECKIN_PARENT_CLEAR,
  GET_CHECKIN_QUESTIONS_START,
  GET_CHECKIN_QUESTIONS_SUCCESS,
  GET_CHECKIN_QUESTIONS_FAILED,
  GET_CHECKIN_QUESTIONS_CLEAR,
  GET_CHECKIN_PARENT_QUESTIONS_START,
  GET_CHECKIN_PARENT_QUESTIONS_SUCCESS,
  GET_CHECKIN_PARENT_QUESTIONS_FAILED,
  GET_CHECKIN_PARENT_QUESTIONS_CLEAR,
  GET_SCHOOL_ALLOW_CHECKIN_START,
  GET_SCHOOL_ALLOW_CHECKIN_SUCCESS,
  GET_SCHOOL_ALLOW_CHECKIN_FAILED,
  GET_SCHOOL_ALLOW_CHECKIN_CLEAR,
  GET_SCHOOL_STUDENT_CHECKIN_START,
  GET_SCHOOL_STUDENT_CHECKIN_SUCCESS,
  GET_SCHOOL_STUDENT_CHECKIN_FAILED,
  GET_SCHOOL_STUDENT_CHECKIN_CLEAR,
  WHO_CAN_CHECKIN_TODAY_START,
  WHO_CAN_CHECKIN_TODAY_SUCCESS,
  WHO_CAN_CHECKIN_TODAY_FAILED,
  WHO_CAN_CHECKIN_TODAY_CLEAR,
} from "./checkinActionType";

import {
  schoolCheckin,
  schoolGeoFenceInformation,
  CheckInByParent,
  checkInGetQuestions,
  CheckInParentQuestions,
  DoesSchoolAllowCheckIn,
  schoolStudentCanCheckIn,
  whoCanCheckInToday,
} from "../../services";
import { validateError } from "../../../helper/utility";

export const getSchoolCheckIn =
  ({ callback }) =>
  (dispatch) => {
    dispatch({ type: GET_SCHOOL_CHECKIN_START });
    return schoolCheckin()
      .then((response) => {
        // console.log(`Get school checkin  response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: GET_SCHOOL_CHECKIN_SUCCESS,
            payload: response.Data,
          });
        if (callback) callback(response.Data);
        else {
          dispatch({ type: GET_SCHOOL_CHECKIN_FAILED, payload: response });
          if (callback) callback([]);
        }
      })
      .catch((error) => {
        if (callback) callback([]);
        // console.log(`getSchoolCheckIn  ${JSON.stringify(error)}`);
        dispatch({ type: GET_SCHOOL_CHECKIN_FAILED, payload: error });
      });
  };

export const getSchoolCheckInClear = () => (dispatch) => {
  dispatch({ type: GET_SCHOOL_CHECKIN_CLEAR });
};

export const getSchoolGeoFenceInformation =
  ({ schoolId }) =>
  (dispatch) => {
    dispatch({ type: GET_SCHOOL_INFORMATION_START });
    return schoolGeoFenceInformation({ schoolId })
      .then((response) => {
        //console.log(`getSchoolGeoFenceInformation response${JSON.stringify(response)}`);
        if (response.Success) {
          // console.log(`getSchoolGeoFenceInformation inside success`);
          dispatch({
            type: GET_SCHOOL_INFORMATION_SUCCESS,
            payload: { data: response.Data, schoolId },
          });
        } else {
          dispatch({ type: GET_SCHOOL_INFORMATION_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`getSchoolGeoFenceInformation ${JSON.stringify(error)}`);
        dispatch({ type: GET_SCHOOL_INFORMATION_FAILED, payload: error });
      });
  };

export const submitCheckInByParent = (params) => (dispatch) => {
  dispatch({ type: SUBMIT_CHECKIN_PARENT_START });
  return CheckInByParent(params)
    .then((response) => {
      // console.log(`submit checkin by parent response${JSON.stringify(response)}`);
      if (response)
        dispatch({ type: SUBMIT_CHECKIN_PARENT_SUCCESS, payload: response });
      else {
        dispatch({ type: SUBMIT_CHECKIN_PARENT_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`submit checkin by parent error ${JSON.stringify(error)}`);
      dispatch({ type: SUBMIT_CHECKIN_PARENT_FAILED, payload: error });
    });
};

export const submitCheckInByParentClear = () => (dispatch) => {
  dispatch({ type: SUBMIT_CHECKIN_PARENT_CLEAR });
};

export const getcheckInQuestions =
  ({ schoolID, studentID }) =>
  (dispatch) => {
    dispatch({ type: GET_CHECKIN_QUESTIONS_START });
    // console.log(`Get checkin questions request ${JSON.stringify({ schoolID, studentID })}`);
    return checkInGetQuestions({ schoolID, studentID })
      .then((response) => {
        // console.log(`Get checkin questions response ${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: GET_CHECKIN_QUESTIONS_SUCCESS,
            payload: response.Data,
          });
        else {
          dispatch({ type: GET_CHECKIN_QUESTIONS_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`Get checkin questions error ${JSON.stringify(error)}`);
        dispatch({ type: GET_CHECKIN_QUESTIONS_FAILED, payload: error });
      });
  };

export const getcheckInQuestionsClear = () => (dispatch) => {
  dispatch({ type: GET_CHECKIN_QUESTIONS_CLEAR });
};

export const getCheckInParentQuestions =
  ({ schoolID }) =>
  (dispatch) => {
    dispatch({ type: GET_CHECKIN_PARENT_QUESTIONS_START });
    return CheckInParentQuestions({ schoolID })
      .then((response) => {
        // console.log(`Get checkin parent questions response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: GET_CHECKIN_PARENT_QUESTIONS_SUCCESS,
            payload: response.Data,
          });
        else {
          dispatch({
            type: GET_CHECKIN_PARENT_QUESTIONS_FAILED,
            payload: response,
          });
        }
      })
      .catch((error) => {
        // console.log(`Get checkin parent questions error ${JSON.stringify(error)}`);
        dispatch({ type: GET_CHECKIN_PARENT_QUESTIONS_FAILED, payload: error });
      });
  };

export const getCheckInParentQuestionsClear = () => (dispatch) => {
  dispatch({ type: GET_CHECKIN_PARENT_QUESTIONS_CLEAR });
};

export const getSchoolAllowCheckIn =
  ({ schoolIDs }) =>
  (dispatch) => {
    dispatch({ type: GET_SCHOOL_ALLOW_CHECKIN_START });
    return DoesSchoolAllowCheckIn({ schoolIDs })
      .then((response) => {
        // console.log(`Get school allow checkin presponse${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: GET_SCHOOL_ALLOW_CHECKIN_SUCCESS,
            payload: response.Data,
          });
        else {
          dispatch({
            type: GET_SCHOOL_ALLOW_CHECKIN_FAILED,
            payload: response,
          });
        }
      })
      .catch((error) => {
        // console.log(`Get school allow checkin error ${JSON.stringify(error)}`);
        dispatch({ type: GET_SCHOOL_ALLOW_CHECKIN_FAILED, payload: error });
      });
  };

export const getSchoolAllowCheckInClear = () => (dispatch) => {
  dispatch({ type: GET_SCHOOL_ALLOW_CHECKIN_CLEAR });
};

export const getSchoolStudentCheckIn =
  ({ schoolID, studentIDs }) =>
  (dispatch) => {
    dispatch({ type: GET_SCHOOL_STUDENT_CHECKIN_START });
    // console.log(
    //   `getSchoolStudentCheckIn called for  ${JSON.stringify({
    //     schoolID,
    //     studentIDs,
    //   })}`
    // );
    return schoolStudentCanCheckIn({ schoolID, studentIDs })
      .then((response) => {
        // console.log(
        //   `getSchoolStudentCheckIn response ${JSON.stringify(response)}`
        // );
        if (response.Success) {
          dispatch({
            type: GET_SCHOOL_STUDENT_CHECKIN_SUCCESS,
            payload: { data: response.Data, schoolID },
          });
        } else {
          dispatch({
            type: GET_SCHOOL_STUDENT_CHECKIN_FAILED,
            payload: response,
          });
        }
      })
      .catch((error) => {
        // console.log(
        //   `Get school student checkin error ${JSON.stringify(error)}`
        // );
        dispatch({
          type: GET_SCHOOL_STUDENT_CHECKIN_FAILED,
          payload: validateError(error),
        });
      });
  };

export const getSchoolStudentCheckInClear = () => (dispatch) => {
  dispatch({ type: GET_SCHOOL_STUDENT_CHECKIN_CLEAR });
};

export const canTodayCheckIn =
  ({ schoolID, studentIDs }) =>
  (dispatch) => {
    dispatch({ type: WHO_CAN_CHECKIN_TODAY_START });
    return whoCanCheckInToday({ schoolID, studentIDs })
      .then((response) => {
        // console.log(`Who can today checkin response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: WHO_CAN_CHECKIN_TODAY_SUCCESS,
            payload: response.Data,
          });
        else {
          dispatch({ type: WHO_CAN_CHECKIN_TODAY_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`Who can today  checkin error ${JSON.stringify(error)}`);
        dispatch({ type: WHO_CAN_CHECKIN_TODAY_FAILED, payload: error });
      });
  };

export const canTodayCheckInClear = () => (dispatch) => {
  dispatch({ type: WHO_CAN_CHECKIN_TODAY_CLEAR });
};
