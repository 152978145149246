import React from "react";
import { connect } from "react-redux";
import {
  getDelegates,
  getDelegatesClear,
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";
import { checkIsEmergency, getCurrentThemeColor } from "../../helper/utility";
import { createLoadingSelector } from "../../common/reducers/selectors";
import RingLoader from "react-spinners/ClipLoader";
import { withTranslation } from "react-i18next";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMyStyle } from "../../theme/ThemeStyle";
const loadingSelector = createLoadingSelector(["get_delegates"]);

class SelectContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      allRecords: [],
      searchText: "",
    };
  }
  componentDidMount() {
    this.getGaurdianList();
    this.dosetHelpList();
    if (this.props.showEmergencyContactAlert) {
      toast.info(this.props.t("select_Contact_message"), {
        autoClose: 2000,
        hideProgressBar: true,
        transition: Slide,
        closeOnClick: true,
      });
    }
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "ChildManagement",
      screen: GLOBAL_CONST.SCREEN_NAMES.ADD_CONTACT,
      title:
        this.props.manageDelegate === true
          ? this.props.t("Contact")
          : this.props.t("Select_Contact"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  getGaurdianList = () => {
    const { doGetDelegates, manageDelegate } = this.props;
    let fetchAll;
    if (manageDelegate === true) {
      fetchAll = true;
    } else {
      fetchAll = false;
    }
    doGetDelegates({ fetchAll });
  };

  searchAction = (text) => {
    let allDelegates = [...this.state.allRecords];
    allDelegates = allDelegates.filter((item) => {
      const delNames = [];
      item.delegates &&
        item.delegates.forEach(function (delegate) {
          delNames.push(delegate.Firstname);
        });
      const itemData = `${item.Firstname.toUpperCase()} ${item.Lastname.toUpperCase()}`;
      const textData = text.toUpperCase();
      const delegateString = delNames.toString().toLowerCase();
      return (
        itemData.indexOf(textData) > -1 ||
        delegateString.indexOf(textData.toLowerCase()) > -1
      );
    });
    this.setState({
      records: allDelegates,
      searchText: text,
    });
  };
  handleSearchTextChange = ({ target: { value: searchText } }) => {
    this.setState({ searchText }, () => {
      this.searchAction(searchText);
    });
  };

  showSearchView = () => {
    const { searchText } = this.state;

    return (
      <form className="searchcontact-form">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder={this.props.t("SEARCH")}
            name="searchText"
            value={searchText}
            onChange={this.handleSearchTextChange}
          />
          <button
            type="button"
            className="btn search-btn"
            onClick={() => {
              this.setState({
                searchText: "",
                records: this.state.allRecords,
              });
            }}
            aria-label={this.props.t("SEARCH")}
          >
            <i
              className={searchText != "" ? "fas fa-times" : "fas fa-search"}
            ></i>
          </button>
        </div>
      </form>
    );
  };
  renderContactListView = () => {
    const styles = getMyStyle(this.props.selectedTheme);
    const { records } = this.state;
    const { manageDelegate, isFetching, selectedTheme } = this.props;

    let sortByActive = [];
    if (records != null && records != undefined && records.length > 0) {
      const sortByFirstName =
        records &&
        records.sort((a, b) =>
          a.Firstname.localeCompare(b.Firstname, "es", { sensitivity: "base" })
        );
      sortByActive =
        sortByFirstName &&
        sortByFirstName.sort(function (a, b) {
          return b.IsActive - a.IsActive;
        });

      if (!sortByActive) {
        sortByActive = [];
      }
    }
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">
                {manageDelegate === true
                  ? this.props.t("Contact")
                  : this.props.t("Select_Contact")}
              </h4>
              <button
                type="button"
                className="btn help-icon-btn"
                onClick={() => {
                  this.openHelpModal();
                }}
              >
                {this.props.t("regNameScr-needHelp")}
              </button>
            </div>
            <button
              type="button"
              className="btn action-btn add-contact-btn"
              onClick={() => {
                this.openNewModal({ modalScreenName: "AddContact" });
              }}
              aria-label={this.props.t("add_contact")}
              style={{ color: getCurrentThemeColor(selectedTheme).icon }}
            >
              <i className="fa-solid fa-user-plus"></i>
            </button>
            <button
              type="button"
              className="close-btn"
              onClick={this.closeModal}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>
          <div className="modal-body">
            <ToastContainer />
            {records.length === 0 && isFetching && (
              <div className="loaderHolder mt-30">
                <RingLoader color="#2d3d56" loading={isFetching} size={60} />
              </div>
            )}
            {records.length === 0 && !isFetching && (
              <div className="loaderHolder mt-30">
                {this.props.t("noDelegateContact")}
              </div>
            )}
            <div className="searchContacts">{this.showSearchView()}</div>

            <form className="form-style1">
              <div className="form-group">
                <ul className="contact-list">
                  {records &&
                    records.map((item) => (
                      <li
                        className="contact-item"
                        key={item.List_ParentDelegateID}
                        onClick={() => {
                          if (!manageDelegate) {
                            this.props.setSelectedDelegate(item, false);
                            this.closeModal();
                          } else {
                            this.openNewModal({
                              modalScreenName: "EditContact",
                              delegate: item,
                            });
                          }
                        }}
                      >
                        <div className="contact-figure">
                          {item.PhotoUrl ? (
                            <span className="contact-img border-outline">
                              <img
                                src={item.PhotoUrl}
                                alt={item.Firstname + "" + item.Lastname}
                                title={item.Firstname + "" + item.Lastname}
                                width={54}
                                height={54}
                              />
                            </span>
                          ) : (
                            <span className="noImage border-outline">
                              <i className="fa-solid fa-user"></i>
                            </span>
                          )}
                        </div>

                        <div
                          className={
                            item.IsActive === true
                              ? "contact-info"
                              : "contact-info disabled"
                          }
                        >
                          <span className="contact-name">
                            {item.Firstname} {""} {item.Lastname}
                          </span>
                          <span className="contact-no">
                            {" "}
                            {item.PhoneNumber}
                          </span>
                        </div>
                        {checkIsEmergency(item) ? (
                          <i className="fas fa-asterisk red-text"></i>
                        ) : null}
                      </li>
                    ))}
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  openNewModal = (params) => {
    const { manageDelegate, setSelectedDelegate } = this.props;
    this.props.doShowModal({
      modalScreenName: params.modalScreenName,
      modalClassName: "create-contact-modal sidebar-modal right",
      params: {
        delegate: params.delegate,
        manageDelegate: manageDelegate,
        setSelectedDelegate: setSelectedDelegate,
        callback: this.getGaurdianList,
      },
    });
  };
  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };

  componentDidUpdate() {
    const { getDelegatesResponse, doGetDelegatesClear, isFetching } =
      this.props;
    if (getDelegatesResponse.length > 0) {
      this.setState(
        { allRecords: getDelegatesResponse, records: getDelegatesResponse },
        doGetDelegatesClear
      );
    }
  }

  render() {
    return this.renderContactListView();
  }
}

function mapStateToProps(state) {
  return {
    getDelegatesResponse: state.userAccount.getDelegatesResponse,
    isFetching: loadingSelector(state),
    setHelpListData: state.userAccount.setHelpListData,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doGetDelegates: (data) => dispatch(getDelegates(data)),
    doGetDelegatesClear: () => dispatch(getDelegatesClear()),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SelectContact)
);
