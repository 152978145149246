import React from "react";
import { BootstrapModal } from "../CommonComponents";
import { connect } from "react-redux";
import {
  passwordUpdate,
  passwordUpdateClear,
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";
import { Colors } from "../../theme";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";

import { createLoadingSelector } from "../../common/reducers/selectors";
import { getMyStyle } from "../../theme/ThemeStyle";
const loadingSelector = createLoadingSelector(["update_password"]);

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      newPassword: "",
      repeatPassword: "",
      passwordStrength: "",
      disabledButton: true,
      hideCurrentPassword: true,
      hideNewPassword: true,
      hideRepeatPassword: true,
    };
  }

  componentDidMount() {
    this.setButtonState();
    this.dosetHelpList();
    this.props.doPasswordUpdateClear();
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Settings",
      screen: GLOBAL_CONST.SCREEN_NAMES.CHANGE_PASSWORD,
      title: this.props.t("changePasswordScr-password"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  validateForm = () => {
    const { currentPassword, newPassword, repeatPassword } = this.state;

    if (currentPassword === null || currentPassword === "") {
      toast.error(this.props.t("changePasswordScr-currentPasswordError"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      return false;
    } else if (newPassword !== repeatPassword) {
      toast.error(this.props.t("changePasswordScr-newPassowrdMatchError"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      return false;
    } else if (newPassword.length < 6) {
      toast.error(this.props.t("changePasswordScr-newPassowrdInvalid"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      return false;
    }
    return true;
  };

  submitChange = () => {
    const { currentPassword, newPassword } = this.state;
    const { doPasswordUpdate } = this.props;

    if (this.validateForm()) {
      doPasswordUpdate({
        oldPassword: currentPassword,
        newPassword: newPassword,
      });
    }
  };

  setButtonState = () => {
    const { currentPassword, newPassword, repeatPassword } = this.state;

    if (currentPassword != "" && newPassword != "" && repeatPassword != "") {
      this.setState({ disabledButton: false });
    } else {
      this.setState({ disabledButton: true });
    }
  };

  handleNewPassword = (event) => {
    const rating = [
      0,
      "Weak",
      25,
      "Good",
      50,
      "Medium",
      75,
      "Strong",
      100,
      "Very Strong",
    ];
    let score = 0;
    let pass = "";
    if (event.target.value.length > 8) {
      score += 25;
    }
    if (
      event.target.value.match(/[a-z]/) &&
      event.target.value.match(/[A-Z]/)
    ) {
      score += 25;
    }
    if (event.target.value.match(/[\!\@\#\$\%\^\&\*\?\_\~\-\(\)]+/)) {
      score += 25;
    }
    if (event.target.value.match(/[0-9]/)) {
      score += 25;
    }
    for (let i = rating.length - 1; i >= 0; i -= 1) {
      if (score >= rating[i]) {
        pass = rating[i + 1];
        break;
      }
    }
    this.setState(
      {
        passwordStrength: pass,
        newPassword: event.target.value,
      },
      () => this.setButtonState()
    );
  };

  secondTextInput(event) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    if (event.key === "Enter") {
      form.elements[index + 1].focus();
    }
  }

  handleCurrentPassword = (event) => {
    this.setState(
      {
        currentPassword: event.target.value,
      },
      () => this.setButtonState()
    );
  };

  handleRepeatPassword = (event) => {
    this.setState(
      {
        repeatPassword: event.target.value,
      },
      () => this.setButtonState()
    );
  };

  renderPasswordString = () => {
    const { password, passwordStrength } = this.state;
    if (password !== "") {
      if (passwordStrength === "Weak") {
        return (
          <span style={{ color: Colors.errorRed }}>
            {this.props.t("regPwdScr-weak")}
          </span>
        );
      }
      if (passwordStrength === "Good") {
        return (
          <span style={{ color: Colors.warningOrange }}>
            {this.props.t("regPwdScr-good")}
          </span>
        );
      }
      if (passwordStrength === "Medium") {
        return (
          <span style={{ color: Colors.warningOrange }}>
            {this.props.t("regPwdScr-medium")}
          </span>
        );
      }
      if (passwordStrength === "Strong" || passwordStrength === "Strong") {
        return (
          <span style={{ color: Colors.successGreen }}>
            {this.props.t("regPwdScr-strong")}
          </span>
        );
      }
    }
    return <span>--</span>;
  };
  setCurrentPasswordVisibility = () => {
    this.setState({ hideCurrentPassword: !this.state.hideCurrentPassword });
  };

  setNewPasswordVisibility = () => {
    this.setState({ hideNewPassword: !this.state.hideNewPassword });
  };

  setRepeatPasswordVisibility = () => {
    this.setState({ hideRepeatPassword: !this.state.hideRepeatPassword });
  };

  componentDidUpdate() {
    const { doPasswordUpdateClear, updatePasswordResponse, error } = this.props;

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doPasswordUpdateClear();
    } else if (updatePasswordResponse && updatePasswordResponse !== null) {
      toast.success(this.props.t("Change_Password_Message"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doPasswordUpdateClear();
      this.closeModal();
    }
  }
  renderChangePasswordView = () => {
    const {
      currentPassword,
      repeatPassword,
      newPassword,
      disabledButton,
      hideCurrentPassword,
      hideNewPassword,
      hideRepeatPassword,
    } = this.state;
    const { isFetching } = this.props;
    const styles = getMyStyle(this.props.selectedTheme);
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">
                {this.props.t("settingMainScr-changePwd")}
              </h4>
              <button
                type="button"
                className="btn help-icon-btn"
                onClick={() => {
                  this.openHelpModal();
                }}
              >
                {this.props.t("regNameScr-needHelp")}
              </button>
            </div>
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-card">
              <form className="form-style1">
                <div className="form-group">
                  <input
                    type={hideCurrentPassword ? "password" : "text"}
                    className="form-control"
                    placeholder={this.props.t("changePasswordScr-currentPwd")}
                    name="currentpassword"
                    value={currentPassword}
                    onChange={this.handleCurrentPassword}
                    maxLength={16}
                    onKeyDown={(event) => this.secondTextInput(event)}
                  />
                  <i
                    onClick={this.setCurrentPasswordVisibility}
                    className={
                      hideCurrentPassword
                        ? "far fa-eye togglePassword"
                        : "far fa-eye-slash togglePassword1"
                    }
                  ></i>
                </div>
                <div className="form-group">
                  <input
                    type={hideNewPassword ? "password" : "text"}
                    className="form-control"
                    placeholder={this.props.t("changePasswordScr-newPwd")}
                    name="newpassword"
                    value={newPassword}
                    onChange={this.handleNewPassword}
                    maxLength={16}
                    onKeyDown={(event) => this.secondTextInput(event)}
                  />
                  <i
                    onClick={this.setNewPasswordVisibility}
                    className={
                      hideNewPassword
                        ? "far fa-eye togglePassword"
                        : "far fa-eye-slash togglePassword1"
                    }
                  ></i>
                </div>
                <div className="form-group">
                  <input
                    type={hideRepeatPassword ? "password" : "text"}
                    className="form-control"
                    placeholder={this.props.t("changePasswordScr-repeat")}
                    name="confirmpassword"
                    value={repeatPassword}
                    onChange={this.handleRepeatPassword}
                    maxLength={16}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") this.submitChange();
                    }}
                  />
                  <i
                    onClick={this.setRepeatPasswordVisibility}
                    className={
                      hideRepeatPassword
                        ? "far fa-eye togglePassword"
                        : "far fa-eye-slash togglePassword1"
                    }
                  ></i>
                </div>
                <div className="form-group">
                  <span>
                    <strong>{this.props.t("regPwdScr-strength")} </strong>
                  </span>
                  {this.renderPasswordString()}
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    style={styles.buttonDefault}
                    onClick={() => {
                      this.submitChange();
                    }}
                    className="btn btn-primary"
                  >
                    {isFetching ? (
                      <i className="fas fa-sync-alt fa-spin"></i>
                    ) : null}{" "}
                    {this.props.t("settingsScr-submit")}
                  </button>
                </div>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  };
  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };

  render() {
    return this.renderChangePasswordView();
  }
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    updatePasswordResponse: state.userAccount.updatePasswordResponse,
    error: state.userAccount.error,
    isFetching: loadingSelector(state),

    setHelpListData: state.userAccount.setHelpListData,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doPasswordUpdate: (data) => dispatch(passwordUpdate(data)),
    doPasswordUpdateClear: () => dispatch(passwordUpdateClear()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);
