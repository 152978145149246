import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarAlt } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import RingLoader from "react-spinners/ClipLoader";
import { Line } from "rc-progress";
import moment from "moment";
import { isActionAllowed } from "../../../../helper/utility";
import { Slide, toast } from "react-toastify";

import {
  changeDelegation,
  changeDelegationClear,
  getAvailableDispatch,
  getAvailableDispatchClear,
  getKidDefaultDismissal,
  getKidDefaultDismissalClear,
} from "../../../../common/actions/students";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import {
  showModal,
  hideModal,
  setHelpList,
} from "../../../../common/actions/userAccount";
import GLOBAL_CONST from "../../../../helper/AppConstants";

class MultikidsDismissalSelectHow extends React.Component {
  constructor(props) {
    super(props);

    const selectedDate = this.props.selectedDate;
    const selectedTillDate = this.props.selectedTillDate;
    const currentPageIndex = this.props.currentPageIndex;
    const pageIndexes = this.props.pageIndexes;
    const selectedRepeatOption = this.props.selectedOption;
    const selectRecurringOption = this.props.selectRecurringOption;
    const recurringDates = this.props.recurringDates;
    const markedDates = this.props.markedDates;

    const howWillPickupOptions = [
      {
        label: `${this.props.t("Select_Contact_Dismissal_Label")}`,
        value: `${this.props.t("Select_Contact_Dismissal_Label")}`,
      },

      {
        label: `${this.props.t("Select_Me_Dismissal_Label")}`,
        value: `${this.props.t("Select_Me_Dismissal_Label")}`,
      },
    ];
    this.state = {
      selectedOptionPickupMode: 0,
      selectedOptionPickupBy: this.props.t("Select_Me_Dismissal_Label"),
      previousSelectedOptionsPickupMode: null,
      previousSelectedOptionsPickupBy: null,
      selectedSubPickupModeOption: null,
      previousSelectedSubpickupModeOption: null,

      selectedBusRouteOption: null,
      previousSelecteBusRouteOption: null,
      gaurdianDetails: null,
      pickupModeList: [],
      schoolSubOptions: [],
      busRouteSubOptions: [],
      selectChild: false,
      selectedDate,
      selectedTillDate,
      recurringDates,
      selectedRepeatOption,
      selectRecurringOption,
      currentPageIndex,
      pageIndexes,
      markedDates,
      howWillPickupOptions,
      isDisable: false,
    };
  }
  componentDidMount() {
    this.props.onRef(this);
    this.getDispatchList();
    this.dosetHelpList();
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "ChildManagement",
      screen: GLOBAL_CONST.SCREEN_NAMES.DISMISSAL_HOW,
      title: this.props.t("dismSelectTypeScr-newDism"),
    });
  };
  componentWillUnmount() {
    this.props.onRef(undefined);
    this.props.doGetAvailableDispatchClear();
    //this.props.doGetKidDefaultDismissalClear();
  }

  getDispatchList = () => {
    const { doGetAvailableDispatch, selectedKids } = this.props;
    const selectedKidsIds = selectedKids.map((child) => {
      return child.StudentID;
    });
    doGetAvailableDispatch({ studentIDs: selectedKidsIds });
    const { doGetKidDefaultDismissal } = this.props;
    doGetKidDefaultDismissal({
      selectedKidsIds: selectedKidsIds,
    });
  };

  setSelectedDelegate = (item, contactFlag, selectedPhone) => {
    let { gaurdianDetails, selectedOptionPickupBy } = this.state;
    const howWillPickupOptions = [...this.state.howWillPickupOptions];
    if (!item) {
      this.setState({ selectedOptionPickupBy: "Select dismissal" });
      return;
    }
    if (contactFlag === true) {
      selectedOptionPickupBy = `${item.givenName} ${""} ${
        item.familyName ? item.familyName : ""
      }`;

      gaurdianDetails = {
        Firstname: item.givenName,
        Lastname: item.familyName ? item.familyName : "",
        PhoneNumber: selectedPhone.number ? selectedPhone.number : "",
      };
    } else {
      selectedOptionPickupBy = `${item.Firstname} ${""} ${item.Lastname}`;
      gaurdianDetails = item;
    }

    const contactRecord = {
      label: selectedOptionPickupBy,
      value: selectedOptionPickupBy,
    };

    const exists = howWillPickupOptions.filter(
      (item) => item.value === contactRecord.value
    );

    if (exists.length === 0) howWillPickupOptions.push(contactRecord);

    this.setState({
      gaurdianDetails,
      selectedOptionPickupBy,
      howWillPickupOptions,
    });
  };

  selectedChild = () => {
    this.setState({ selectChild: !this.state.selectChild });
  };

  canAssignParentAllowed = () => {
    const {
      selectedOptionPickupMode,
      selectedBusRouteOption,
      selectedSubPickupModeOption,
    } = this.state;

    if (
      selectedOptionPickupMode == 4 &&
      selectedSubPickupModeOption &&
      this.getAfterSchoolProgramById(selectedSubPickupModeOption)
        .canAssignParent
    ) {
      return true;
    } else if (
      selectedOptionPickupMode == 1 &&
      selectedBusRouteOption &&
      this.getBusRouteById(selectedBusRouteOption).canAssignParent
    ) {
      return true;
    } else if (selectedOptionPickupMode > 1 && selectedOptionPickupMode != 4) {
      return true;
    }
    return false;
  };

  validateForm = () => {
    const {
      selectedDate,
      selectedTillDate,
      selectedOption,
      isRepeat,
      recurringDates,
      school,
    } = this.props;

    if (selectedDate === "Select Date") {
      alert(this.props.t("selectWhenScr-selectDate"));
      return false;
    } else if (isRepeat === true && selectedTillDate == "Select Date") {
      alert(this.props.t("Select_Till_Date"));
    } else if (
      isRepeat === true &&
      recurringDates &&
      recurringDates.length === 0
    ) {
      alert(this.props.t("Recurring_Days_Error"));
      return false;
    } else if (selectedTillDate < selectedDate) {
      alert(this.props.t("Select_proper_Date"));
    } else if (
      isRepeat === false &&
      school.ReunificationEnabled &&
      moment(selectedDate).isSame(moment(), "date")
    ) {
      alert(this.props.t("emergency_reunification_pickup_msg"));
      return false;
    } else if (
      isRepeat === true &&
      selectedOption == this.props.t("selectWhenScr-select")
    ) {
      alert(this.props.t("Select_Recurring_Option"));
      return false;
    } else {
      return true;
    }
  };

  submitForm = () => {
    const {
      gaurdianDetails,
      selectedOptionPickupMode,
      selectedSubPickupModeOption,
      selectedBusRouteOption,
      selectedOptionPickupBy,
    } = this.state;
    const {
      doChangeDelegation,
      defaultKidsDismissalResponse,
      selectedKids,
      manuallExcludedDates,
    } = this.props;

    let selectData = this.props.selectData;

    if (this.validateForm()) {
      const manuallExcludedDatesArr = [];
      for (const key in manuallExcludedDates) {
        const item = moment(key).format("MM/DD/YYYY");
        manuallExcludedDatesArr.push(item);
      }
      const finalDates = [];
      for (var i = 0; i < selectData.selectedDate.length; i++) {
        const item = selectData.selectedDate[i];
        if (!manuallExcludedDatesArr.includes(item)) {
          finalDates.push(item);
        }
      }

      const isPickupModeFuture = isActionAllowed(
        selectedKids[0].PickupModeCutOffTime,
        selectData.showDate
      );

      const isDelegateFuture = isActionAllowed(
        selectedKids[0].DelegationCutOffTime,
        selectData.showDate
      );

      // console.log(
      //   `isDelegateFuture ${isDelegateFuture} isPickupModeFuture ${isPickupModeFuture}  selectedOptionPickupMode ${selectedOptionPickupMode}`,
      // );

      if (
        (selectedOptionPickupMode == 1 || selectedOptionPickupMode == 4) &&
        isPickupModeFuture === false
      ) {
        let msg = this.props.t("Past_Dismissal1");
        msg = msg.replace(
          "#time#",
          selectedKids[0].PickupModeCutOffTime.toUpperCase()
        );
        msg = msg.replace("#days#", moment().format("dddd") + "s");
        toast.error(msg, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        return;
      } else if (isDelegateFuture === false) {
        let msg = this.props.t("Past_Dismissal1");
        msg = msg.replace(
          "#time#",
          selectedKids[0].DelegationCutOffTime.toUpperCase()
        );
        msg = msg.replace("#days#", moment().format("dddd") + "s");

        toast.error(msg, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        return;
      }

      if (
        selectedOptionPickupMode == 0 ||
        selectedOptionPickupMode == undefined
      ) {
        alert(this.props.t("Select_PickupMode_Alert"));
        return;
      } else if (finalDates.length === 0) {
        alert(this.props.t("Dismissal_Date_Error"));
        return;
      } else if (
        selectedOptionPickupBy ===
          this.props.t("Select_Contact_Dismissal_Label") &&
        this.canAssignParentAllowed()
      ) {
        this.openContact(this.props.t("Select_Contact_Dismissal_Label"));
        return;
      }

      let params = {};
      let selectedkidArr = [];
      //console.log('selectedOptionPickupBy ' +selectedOptionPickupBy + gaurdianDetails +" " + JSON.stringify(gaurdianDetails))
      if (selectedKids) {
        selectedKids.filter((kid) => {
          selectedkidArr.push(kid.StudentID);
        });
      }
      params = {
        studentID: selectedkidArr,
        schoolID: selectedKids[0].SchoolID,
        recurringTypeID: selectData.selectedOption,
        fromDate: finalDates,
        pickUpModeId: selectedOptionPickupMode,
        institutionId: null,
        busRouteId: null,
      };

      let delegatedPerson = null;
      if (
        selectedOptionPickupBy !== this.props.t("Select_Me_Dismissal_Label") &&
        gaurdianDetails
      ) {
        delegatedPerson = gaurdianDetails;
      }
      if (
        this.canAssignParentAllowed() &&
        delegatedPerson !== null &&
        delegatedPerson !== undefined
      ) {
        params.guardianFirstName = delegatedPerson.Firstname;
        params.guardianLastName = delegatedPerson.Lastname;
        params.guardianPhone = delegatedPerson.PhoneNumber;
      }

      if (selectedOptionPickupMode == 4) {
        params.institutionId = selectedSubPickupModeOption;
        params.isDelegated = false;
        params.isDelegatedToGuardian = delegatedPerson ? true : false;
      } else if (selectedOptionPickupMode == 1) {
        params.isDelegated = false;
        params.isDelegatedToGuardian = false;
        params.busRouteId = selectedBusRouteOption;
      } else {
        params.isDelegated = delegatedPerson ? true : false;
        params.isDelegatedToGuardian = delegatedPerson ? true : false;
      }

      let isSameAsDefault = false;
      const canAssignGuardian = this.canAssignParentAllowed();
      let name = [];
      if (defaultKidsDismissalResponse && selectedKids) {
        for (let i = 0; i < defaultKidsDismissalResponse.length; i += 1) {
          if (
            defaultKidsDismissalResponse &&
            parseInt(defaultKidsDismissalResponse[i].PickupModeID, 10) ===
              parseInt(selectedOptionPickupMode, 10)
          ) {
            // First check for after school
            // Second check for Bus
            // Then check others i.e carlines or walkers
            if (
              selectedOptionPickupMode == 4 &&
              parseInt(defaultKidsDismissalResponse[i].AfterSchoolID, 10) ===
                parseInt(params.institutionId, 10)
            ) {
              if (canAssignGuardian && !params.isDelegatedToGuardian) {
                isSameAsDefault = true;
              } else if (!canAssignGuardian) {
                isSameAsDefault = true;
              }
              name.push(
                selectedKids[i].Firstname + " " + selectedKids[i].Lastname
              );
            } else if (
              selectedOptionPickupMode == 1 &&
              parseInt(defaultKidsDismissalResponse[i].BusRouteID, 10) ===
                parseInt(params.busRouteId, 10)
            ) {
              if (canAssignGuardian && !params.isDelegatedToGuardian) {
                isSameAsDefault = true;
              } else if (!canAssignGuardian) {
                isSameAsDefault = true;
              }
              name.push(
                selectedKids[i].Firstname + " " + selectedKids[i].Lastname
              );
            } else if (
              selectedOptionPickupMode !== 4 &&
              selectedOptionPickupMode !== 1
            ) {
              if (canAssignGuardian && !params.isDelegatedToGuardian) {
                isSameAsDefault = true;
              } else if (!canAssignGuardian) {
                isSameAsDefault = true;
              }
              name.push(
                selectedKids[i].Firstname + " " + selectedKids[i].Lastname
              );
            }
          }
        }
      }
      if (isSameAsDefault) {
        alert(
          this.props.t("pickup_default_with_Kidname1") +
            name +
            this.props.t("pickup_default_with_Kidname2")
        );
        return;
      }

      //console.log('doChangeDelegation ', JSON.stringify(params));
      this.setState({ isDisable: true }, () => {
        doChangeDelegation(params);
      });
      setTimeout(() => {
        this.setState({ isDisable: false });
      }, 1000); // Reset after 1 second, for example
    }
  };

  openContact = (value) => {
    if (value === this.props.t("Select_Contact_Dismissal_Label")) {
      this.props.doShowModal({
        modalScreenName: "SelectContact",
        modalClassName: "contact-list-modal sidebar-modal right",
        params: {
          setSelectedDelegate: this.setSelectedDelegate,
          manageDelegate: false,
        },
      });
    }
  };

  getPickupModeById = (value) => {
    const { pickupModeList } = this.state;
    for (let i = 0; i < pickupModeList.length; i++) {
      if (value == pickupModeList[i].value) return pickupModeList[i].label;
    }
  };

  getAfterSchoolProgramById = (value) => {
    const { schoolSubOptions } = this.state;

    for (let i = 0; i < schoolSubOptions.length; i++) {
      if (value === schoolSubOptions[i].value) return schoolSubOptions[i];
    }
    return {
      label: "",
      canAssignParent: false,
    };
  };

  getBusRouteById = (value) => {
    const { busRouteSubOptions } = this.state;

    for (let i = 0; i < busRouteSubOptions.length; i++) {
      if (value === busRouteSubOptions[i].value) return busRouteSubOptions[i];
    }
    return {
      label: "",
      canAssignParent: false,
    };
  };

  componentDidUpdate() {
    const {
      changeDelegationResponse,
      doChangeDelegationClear,
      error,
      doGetAvailableDispatchClear,
      availableDispatch,
      doHideModal,
      doCallbackRefresh,
    } = this.props;

    const { pickupModeList, schoolSubOptions, busRouteSubOptions } = this.state;

    let studentPickupModeList;
    let pickupModes = [];
    let schoolOptions = [];
    let busOptions = [];
    let afterSchoolOptions,
      busCompletesOptions,
      subOptions,
      subBus = [];
    let modes = [];

    if (availableDispatch) {
      studentPickupModeList = availableDispatch.StudentDismissalMethod;
      pickupModes.push({
        label: `${this.props.t("Select_Dismissal_Label")}`,
        value: 0,
        canAssignParent: false,
      });
      for (let i = 0; i < studentPickupModeList.length; i++) {
        modes = studentPickupModeList[i].Modes;
        afterSchoolOptions = modes.filter((item) => item.PickupModeID === 4);
        busCompletesOptions = modes.filter((item) => item.PickupModeID === 1);
      }
      for (let i = 0; i < modes.length; i++) {
        pickupModes.push({
          label: `${modes[i].Name}`,
          value: modes[i].PickupModeID,
        });
      }

      if (pickupModeList.length === 0 && pickupModes.length > 0) {
        this.setState({ pickupModeList: pickupModes });
      }

      for (let i = 0; i < afterSchoolOptions.length; i++) {
        subOptions = afterSchoolOptions[i].SubOptions;
      }
      if (subOptions) {
        subOptions = subOptions.sort((a, b) =>
          a.Name.toLowerCase() > b.Name.toLowerCase()
            ? 1
            : b.Name.toLowerCase() > a.Name.toLowerCase()
            ? -1
            : 0
        );
        for (let i = 0; i < subOptions.length; i++) {
          schoolOptions.push({
            label: `${subOptions[i].Name}`,
            value: `${subOptions[i].ID}`,
            canAssignParent: subOptions[i].CanAssignParent,
          });
        }
      }

      if (schoolSubOptions.length === 0 && schoolOptions.length > 0) {
        this.setState({
          schoolSubOptions: schoolOptions,
          selectedSubPickupModeOption: schoolOptions[0].value,
        });
      }

      for (let i = 0; i < busCompletesOptions.length; i++) {
        subBus = busCompletesOptions[i].SubOptions;
      }
      for (let i = 0; i < subBus.length; i++) {
        busOptions.push({
          label: `${subBus[i].Name}`,
          value: `${subBus[i].ID}`,
          canAssignParent: subBus[i].CanAssignParent,
        });
      }

      if (busRouteSubOptions.length === 0 && busOptions.length > 0) {
        busOptions = busOptions.sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase()
            ? 1
            : b.label.toLowerCase() > a.label.toLowerCase()
            ? -1
            : 0
        );
        this.setState({
          busRouteSubOptions: busOptions,
          selectedBusRouteOption: busOptions[0].value,
        });
      }
    }

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doChangeDelegationClear();
    } else if (changeDelegationResponse) {
      toast.success(this.props.t("changePickupScr_Success"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doHideModal();
      doChangeDelegationClear();
      doGetAvailableDispatchClear();
      if (doCallbackRefresh) doCallbackRefresh();
    }
  }

  render() {
    const {
      selectedOptionPickupMode,
      selectedBusRouteOption,
      selectedSubPickupModeOption,
      pickupModeList,
      schoolSubOptions,
      busRouteSubOptions,
      gaurdianDetails,
    } = this.state;

    let selectedOptionPickupModeText = "";
    if (selectedOptionPickupMode == 4)
      selectedOptionPickupModeText = this.props.t("After_School_Program");
    else if (selectedOptionPickupMode == 1)
      selectedOptionPickupModeText = this.props.t("Bus_Route");
    else if (selectedOptionPickupMode != 0 && selectedOptionPickupMode != null)
      selectedOptionPickupModeText = this.props.t("DismSelectHowScr-whoWill");

    return (
      <div className="card form-card">
        <div className="form-card-title">
          <div className="fc-titletext-icon">
            <FontAwesomeIcon className="title-icon" icon={faCarAlt} />
            <span className="text">{this.props.t("Select_How_Label")}</span>
          </div>
          <span className="probressbar-holder">
            <Line percent={100} strokeWidth="1" strokeColor="#7ac943" />
          </span>
        </div>
        <form className="form form-style2">
          <div className="form-group">
            <label className="control-label">
              {this.props.t("Select_PickupMode_Label")}
            </label>

            <Dropdown
              className="custom-dropdown"
              onSelect={(eventKey) => {
                this.setState({
                  selectedOptionPickupMode: eventKey,
                });
              }}
              onClick={() => {
                this.setState({
                  previousSelectedOptionsPickupMode: selectedOptionPickupMode,
                });
              }}
            >
              <Dropdown.Toggle>
                {this.getPickupModeById(selectedOptionPickupMode)}
                {pickupModeList.length === 0 && (
                  <RingLoader color="#2d3d56" loading={true} size={20} />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {pickupModeList.map((data) => {
                  return (
                    <Dropdown.Item
                      className={
                        parseInt(selectedOptionPickupMode) ===
                        parseInt(data.value)
                          ? "active"
                          : ""
                      }
                      eventKey={data.value}
                      key={data.value}
                    >
                      <span className="dropdownitem-label">{data.label}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="form-group">
            {selectedOptionPickupMode == 4 ? (
              <label className="control-label">
                {selectedOptionPickupModeText}
              </label>
            ) : null}

            {selectedOptionPickupMode == 1 && (
              <Dropdown
                className="custom-dropdown"
                onSelect={(eventKey) => {
                  this.setState({
                    selectedBusRouteOption: eventKey,
                  });
                }}
              >
                <Dropdown.Toggle>
                  {this.getBusRouteById(selectedBusRouteOption).label}
                  {busRouteSubOptions.length === 0 && (
                    <RingLoader color="#2d3d56" loading={true} size={20} />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {busRouteSubOptions.map((data) => {
                    return (
                      <Dropdown.Item
                        eventKey={data.value}
                        key={data.value}
                        className={
                          parseInt(selectedBusRouteOption) ===
                          parseInt(data.value)
                            ? "active"
                            : ""
                        }
                      >
                        <span className="dropdownitem-label">{data.label}</span>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}

            {selectedOptionPickupMode == 4 && (
              <Dropdown
                className="custom-dropdown"
                onSelect={(eventKey) => {
                  this.setState({
                    selectedSubPickupModeOption: eventKey,
                  });
                }}
              >
                <Dropdown.Toggle>
                  {
                    this.getAfterSchoolProgramById(selectedSubPickupModeOption)
                      .label
                  }
                  {schoolSubOptions.length === 0 && (
                    <RingLoader color="#2d3d56" loading={true} size={20} />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {schoolSubOptions.map((data) => {
                    return (
                      <Dropdown.Item
                        eventKey={data.value}
                        key={data.value}
                        className={
                          parseInt(selectedSubPickupModeOption) ===
                          parseInt(data.value)
                            ? "active"
                            : ""
                        }
                      >
                        <span className="dropdownitem-label">{data.label}</span>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          {this.canAssignParentAllowed() && (
            <div className="form-group">
              <label className="control-label">
                {this.props.t("whowillpickup_text")}
              </label>
              <div
                onClick={() => {
                  this.setState({
                    selectedOptionPickupBy: this.props.t(
                      "Select_Me_Dismissal_Label"
                    ),
                  });
                }}
                className={
                  this.state.selectedOptionPickupBy ===
                  this.props.t("Select_Me_Dismissal_Label")
                    ? "custom-radio  mb-20 checked"
                    : "custom-radio  mb-20"
                }
              >
                <input type="radio" id="iWillPickupBy" name="whoWillPickupBy" />
                <label htmlFor="iWillPickupBy"></label>
                <span className="radio-label-text">
                  {this.props.t("IWillPickup")}
                </span>
              </div>

              <div
                onClick={() => {
                  if (
                    gaurdianDetails !== null &&
                    gaurdianDetails !== undefined
                  ) {
                    this.setState({
                      selectedOptionPickupBy: `${
                        gaurdianDetails.Firstname
                      } ${""} ${gaurdianDetails.Lastname}`,
                    });
                  }
                  this.openContact(
                    this.props.t("Select_Contact_Dismissal_Label")
                  );
                }}
                className={
                  this.state.selectedOptionPickupBy !==
                  this.props.t("Select_Me_Dismissal_Label")
                    ? "custom-radio  rightArrowLabel mb-20 checked"
                    : "custom-radio rightArrowLabel mb-20"
                }
              >
                <div className="flex-center">
                  <input
                    type="radio"
                    id="selectFromContact"
                    name="whoWillPickupBy"
                  />
                  <label htmlFor="selectFromContact"></label>
                  <span className="radio-label-text">
                    {this.props.t("change_selection_text")}
                  </span>
                </div>

                {this.state.selectedOptionPickupBy ===
                this.props.t("Select_Me_Dismissal_Label") ? null : (
                  <div className="contact-item">
                    {gaurdianDetails.PhotoUrl ? (
                      <div className="contact-figure">
                        <span className="contact-img border-outline">
                          <img
                            src={gaurdianDetails.PhotoUrl}
                            alt={`${gaurdianDetails.Firstname} ${""} ${
                              gaurdianDetails.Lastname
                            }`}
                            title={`${gaurdianDetails.Firstname} ${""} ${
                              gaurdianDetails.Lastname
                            }`}
                            width={54}
                            height={54}
                          />
                        </span>
                      </div>
                    ) : null}
                    <div className="contact-info">
                      <span className="contact-name">
                        {`${gaurdianDetails.Firstname} ${""} ${
                          gaurdianDetails.Lastname
                        }`}
                      </span>
                      <span className="contact-no">
                        {gaurdianDetails.PhoneNumber}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingForPickupMode: state.school.loadingForPickupMode,
    changeDelegationResponse: state.student.changeDelegationResponse,
    userLoginInfo: state.userAccount.userLoginInfo,
    availableDispatch: state.student.availableDispatch,
    error: state.student.error,

    setCreatePickupButtonsUI: state.userAccount.setCreatePickupButtonsUI,
    setHelpListData: state.userAccount.setHelpListData,

    defaultKidsDismissalResponse: state.student.defaultKidsDismissalResponse,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doChangeDelegation: (data) => dispatch(changeDelegation(data)),
    doChangeDelegationClear: () => dispatch(changeDelegationClear()),
    doGetAvailableDispatch: (data) => dispatch(getAvailableDispatch(data)),
    doGetAvailableDispatchClear: () => dispatch(getAvailableDispatchClear()),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    dosetHelpList: (data) => dispatch(setHelpList(data)),

    doGetKidDefaultDismissal: (data) => dispatch(getKidDefaultDismissal(data)),
    doGetKidDefaultDismissalClear: () =>
      dispatch(getKidDefaultDismissalClear()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(MultikidsDismissalSelectHow)
);
