import {
  GET_ADVERT_LIST_START,
  GET_ADVERT_LIST_SUCCESS,
  GET_ADVERT_LIST_FAILED,
  GET_ADVERT_LIST_CLEAR,
  GET_ADVERT_SERVE_START,
  GET_ADVERT_SERVE_SUCCESS,
  GET_ADVERT_SERVE_FAILED,
  GET_ADVERT_SERVE_CLEAR,
  GET_ADVERT_CLICK_START,
  GET_ADVERT_CLICK_SUCCESS,
  GET_ADVERT_CLICK_FAILED,
  GET_ADVERT_CLICK_CLEAR,
  GET_ADVERT_PHOTO_START,
  GET_ADVERT_PHOTO_SUCCESS,
  GET_ADVERT_PHOTO_FAILED,
  GET_ADVERT_PHOTO_CLEAR,
  ADVERT_HIDE,
} from "./adsActionType";

import {
  parentAdvertList,
  logAdvertServe,
  logAdClick,
  parentAdvertPhoto,
} from "../../services";
import { validateError } from "../../../helper/utility";

export const getParentAdvertList =
  ({ SchoolIDs }) =>
  (dispatch) => {
    dispatch({ type: GET_ADVERT_LIST_START });
    // console.log(`Get parent advert list param ${SchoolIDs}`);
    return parentAdvertList({ SchoolIDs })
      .then((response) => {
        // console.log(`Get parent advert list response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: GET_ADVERT_LIST_SUCCESS, payload: response.Data });
        else {
          dispatch({ type: GET_ADVERT_LIST_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`Get parent advert list error ${JSON.stringify(error)}`);
        dispatch({
          type: GET_ADVERT_LIST_FAILED,
          payload: validateError(error),
        });
      });
  };

export const getParentAdvertListClear = () => (dispatch) => {
  dispatch({ type: GET_ADVERT_LIST_CLEAR });
};

export const getLogAdvertServe =
  ({ params }) =>
  (dispatch) => {
    dispatch({ type: GET_ADVERT_SERVE_START });
    return logAdvertServe({ params })
      .then((response) => {
        //console.log(`Get log advert serve response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: GET_ADVERT_SERVE_SUCCESS, payload: response.Data });
        else {
          dispatch({ type: GET_ADVERT_SERVE_FAILED, payload: response });
        }
      })
      .catch((error) => {
        //console.log(`Get log advert serve error ${JSON.stringify(error)}`);
        dispatch({
          type: GET_ADVERT_SERVE_FAILED,
          payload: validateError(error),
        });
      });
  };

export const getLogAdvertServeClear = () => (dispatch) => {
  dispatch({ type: GET_ADVERT_SERVE_CLEAR });
};

export const logAdvertClick = (params) => (dispatch) => {
  dispatch({ type: GET_ADVERT_CLICK_START });
  return logAdClick(params)
    .then((response) => {
      //console.log(`Get log advert click response${JSON.stringify(response)}`);
      if (response.Success) {
        dispatch({ type: GET_ADVERT_CLICK_SUCCESS, payload: response.Data });
      } else {
        dispatch({ type: GET_ADVERT_CLICK_FAILED, payload: response });
      }
    })
    .catch((error) => {
      //console.log(`Get log advert click error ${JSON.stringify(error)}`);
      dispatch({
        type: GET_ADVERT_CLICK_FAILED,
        payload: validateError(error),
      });
    });
};

export const logAdvertClickClear = () => (dispatch) => {
  dispatch({ type: GET_ADVERT_CLICK_CLEAR });
};

export const getAdvertPhoto =
  ({ params }) =>
  (dispatch) => {
    dispatch({ type: GET_ADVERT_PHOTO_START });
    return parentAdvertPhoto({ params })
      .then((response) => {
        //console.log(`Get advert photo response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: GET_ADVERT_PHOTO_SUCCESS, payload: response.Data });
        else {
          dispatch({ type: GET_ADVERT_PHOTO_FAILED, payload: response });
        }
      })
      .catch((error) => {
        //console.log(`Get advert photo error ${JSON.stringify(error)}`);
        dispatch({
          type: GET_ADVERT_PHOTO_FAILED,
          payload: validateError(error),
        });
      });
  };

export const getAdvertPhotoClear = () => (dispatch) => {
  dispatch({ type: GET_ADVERT_PHOTO_CLEAR });
};

export const hideAdvert = () => (dispatch) => {
  dispatch({ type: ADVERT_HIDE });
};
