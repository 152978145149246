/* eslint-disable linebreak-style */
export default (state = {}, action) => {
  if (!action) return state;
  const { type } = action;
  //// console.log('loading reducer', state);
  const matches = /(.*)_(start|success|failed)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them

  if (!matches) return state;
  // console.log("loading reducer " + JSON.stringify(state));

  const [, requestName, requestState] = matches;
  //// console.log(`requestName ${requestName}    requestState   ${requestState}`);
  //// console.log(`loading state ${JSON.stringify(state)} `);
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === "start",
  };
};
