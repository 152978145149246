import React from "react";
import { connect } from "react-redux";
import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
} from "../../common/actions/userAccount";
import { withTranslation } from "react-i18next";

//Owl Carousel Libraries and Module
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Owl Carousel Settings
const OwlCarouselOptions = {
  margin: 28,
  responsiveClass: true,
  nav: true,
  autoplay: false,
  navText: [
    "<i class='fas fa-chevron-left'></i>",
    "<i class='fas fa-chevron-right'></i>",
  ],
  smartSpeed: 1300,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  },
};
class ChildCardAnnounce extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };
  renderKidsForAnnounce = () => {
    const { kidsAnnouncedToday } = this.props;
    const views = [];
    for (var i = 0; i < kidsAnnouncedToday.length; i++) {
      const item = kidsAnnouncedToday[i];
      views.push(
        <div className="item" key={item.StudentID}>
          <div className="card child-bx">
            <div className="child-bx-row">
              <h5 className="ch-name">
                {item.Firstname} {item.Lastname}
              </h5>
              <p className="ch-grade">{item.GradeName}</p>
              <p className="ch-school-name">{item.SchoolName}</p>
            </div>
            <div className="child-bx-row">
              <p className="dismissal-id-text">
                {this.props.t("childCardScr-dismID")}
              </p>
              <p className="dismissal-id-val">{item.StudentID}</p>
            </div>
            <div className="child-bx-row">
              <p className="ch-current-status">{item.PickupModeName}</p>
            </div>
          </div>
        </div>
      );
    }
    if (kidsAnnouncedToday.length === 1) {
      return <div className="justify-center single-kid-announce">{views}</div>;
    } else {
      return (
        <div className="kidsforAnnouceSlider">
          <OwlCarousel
            className="slider-items owl-carousel"
            {...OwlCarouselOptions}
          >
            {views}
          </OwlCarousel>
        </div>
      );
    }
  };
  render() {
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">
                {this.props.t("childCardScr-announceFor")}
              </h4>
            </div>

            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>

          <div className="modal-body  modal-popup">
            {this.renderKidsForAnnounce()}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.student.error,
    studentsList: state.student.studentsList,
    currentLocationPermission: state.userAccount.currentLocationPermission,
    setHelpListData: state.userAccount.setHelpListData,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ChildCardAnnounce)
);
