import { getEnvironment, getAxios } from "./config";
import {
  validateResponse,
  getRegistrationToken,
  getAppVersion,
  getDeviceInfo,
  getAuthToken,
} from "../../helper/utility";

// Registration Number
export const startRegistration = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/Registration/StartRegistration`;
  const FullUrl = PostURL + param;

  // console.log(` startRegistration ${FullUrl}`);
  const response = await getAxios().post(FullUrl, {
    PhoneNumber: params.PhoneNumber,
    CountryCode: params.CountryCode,
    ResendOtp: true,
    DeviceType: params.deviceType,
    Locale: params.Locale,
    RegistrationTokenId: params.RegistrationTokenId,
  });

  return validateResponse(response);
};

export const resendUserOTP = async (params) => {
  // alert(registration_token);
  const param = "";
  const PostURL = `${getEnvironment()}v2/registration/startregistration`;
  const FullUrl = PostURL + param;
  // console.log(` resendUserOTP ${FullUrl}`);
  const body = {
    RegistrationTokenId: getRegistrationToken(),
    PhoneNumber: params.PhoneNumber,
    CountryCode: params.CountryCode,
    ResendOtp: true,
    DeviceType: params.DeviceType,
    Locale: params.Locale,
  };

  // console.log(` resendUserOTP update ${FullUrl} body ${JSON.stringify(body)}`);
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

// User OTP verify
export const verifyOtp = async ({ OtpCode }) => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/Registration/VerifyPhoneOTP`;
  const FullUrl = PostURL + param;
  // console.log(` verifyOtp ${FullUrl}`);
  const response = await getAxios().post(FullUrl, {
    RegistrationTokenID: getRegistrationToken(),
    OtpCode,
  });

  return validateResponse(response);
};

// Get user info
export const getUserInfo = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/registration/getUserInfo?RegistrationTokenId=${getRegistrationToken()}`;
  const FullUrl = PostURL + param;
  // console.log(`getUserInfo ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

// Complete user registration
export const registerUser = async (params) => {
  // console.log(`registerUser Params = ${JSON.stringify(params)}`);
  const param = "";
  const PostURL = `${getEnvironment()}v2/registration/completeregistration`;
  const FullUrl = PostURL + param;
  const body = {
    RegistrationTokenID: getRegistrationToken(),
    Password: params.Password,
    Firstname: params.Firstname,
    Lastname: params.Lastname,
  };
  //console.log(`registerUser ${FullUrl} Params = ${JSON.stringify(body)}`);
  const response = await getAxios().post(FullUrl, body);
  return validateResponse(response);
};

// Check email
export const setEmail = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/account/setemail`;
  const FullUrl = PostURL + param;
  const body = {
    Email: params.Email,
  };
  // console.log(
  //   ` checkEmail Full url ${FullUrl}  Params-> ${JSON.stringify(params)}`
  // );
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

// Verified email
export const getIsEmailVerified = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/account/isemailverified`;
  const FullUrl = PostURL + param;
  //console.log(` getIsEmailVerified ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

// Resend Email
export const ResendEmailVerificationMail = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/account/resendverificationmail`;
  const FullUrl = PostURL + param;
  //console.log(`ResendEmailVerificationMail  ${FullUrl}`);
  const response = await getAxios().post(FullUrl);

  return validateResponse(response);
};

// SSO user account exist
export const ssoUserAccountExist = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/checkaccountexists`;
  const FullUrl = PostURL + param;
  // console.log(`ssoUserAccountExist  ${FullUrl}`);
  const response = await getAxios().post(FullUrl, {
    Locale: params.Locale,
    DeviceType: params.DeviceType,
    SsoType: params.SsoType,
    ExternalUserId: params.ExternalUserId,
  });

  return validateResponse(response);
};

// Check Email exist
export const checkEmailAccountExist = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}registration/checkEmail?Email=${
    params.Email
  }&locale=${params.Locale}`;
  const FullUrl = PostURL + param;
  //console.log(`Check Email exist  ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

//SSO Login

export const appleLogin = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/AppleLogin`;
  const FullUrl = PostURL + param;
  const body = params;
  // console.log(
  //   ` appleLogin Full url ${FullUrl}  Params-> ${JSON.stringify(params)}`
  // );
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const requestNewEmailAddressApple = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/RequestNewEmailAddressApple`;
  const FullUrl = PostURL + param;
  const body = params;
  // console.log(
  //   ` requestNewEmailAddressApple Full url ${FullUrl}  Params-> ${JSON.stringify(
  //     body
  //   )}`
  // );
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const googleLogin = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/GoogleLogin`;
  const FullUrl = PostURL + param;
  const body = params;
  // console.log(
  //   ` googleLogin Full url ${FullUrl}  Params-> ${JSON.stringify(params)}`
  // );
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const requestNewEmailAddressGoogle = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}account/RequestNewEmailAddressGoogle`;
  const FullUrl = PostURL + param;
  const body = params;
  // console.log(
  //   ` requestNewEmailAddressGoogle Full url ${FullUrl}  Params-> ${JSON.stringify(
  //     body
  //   )}`
  // );
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const checkEmail = async ({ Email, locale }) => {
  const param = "";
  const PostURL = `${getEnvironment()}registration/checkEmail?Email=${Email}&locale=${locale}`;
  const FullUrl = PostURL + param;
  //console.log(` checkEmail ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};
