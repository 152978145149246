import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { BootstrapModal } from "../CommonComponents";
import {
  showModal,
  hideModal,
  setOldHelpSetting,
} from "../../common/actions/userAccount";
import {
  getHelpDetails,
  getHelpDetailsClear,
} from "../../common/actions/school";
import { createLoadingSelector } from "../../common/reducers/selectors";
import RingLoader from "react-spinners/ClipLoader";
import { getLanguageCode } from "../../helper/utility";

const loadingSelector = createLoadingSelector(["get_help"]);
class HelpListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getHelp = () => {
    const { doGetHelpDetails, stackType, screen } = this.props;
    let schoolId;
    schoolId = 14;
    const param = { schoolId: 14, pageName: screen, stackType };
    doGetHelpDetails(param);
  };
  componentDidMount() {
    this.getHelp();
  }
  componentWillUnmount() {
    this.props.doGetHelpDetailsClear();
  }

  openHelpDetails = (item) => {
    const { stackType, title, screen, modalLevel } = this.props;
    const goto = stackType + "HelpDetails";
    this.openNewModal({
      goto: goto,
      screen: screen,
      stackType: stackType,
      title: title,
      record: item,
    });
  };

  openNewModal = (params) => {
    this.props.doShowModal({
      modalScreenName: "HelpDetails",
      modalClassName: "help-details-modal sidebar-modal right",
      params: {
        stackType: params.stackType,
        screen: params.screen,
        goto: params.goto,
        title: params.title,
        record: params.record,
      },
    });
  };

  listItem = (item, index) => {
    return (
      <li
        className="list-item"
        key={"row-" + index}
        onClick={() => {
          this.openHelpDetails(item);
        }}
      >
        <div className="list-info">
          <span className="list-item-title">{item.Title}</span>
        </div>
      </li>
    );
  };
  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };

  render() {
    const { helpRecord, isFetching, title } = this.props;
    let help_En,
      help_Es = [];
    if (helpRecord && helpRecord != null) {
      help_En = helpRecord.Help_EN;
      help_Es = helpRecord.Help_ES;
    }
    let userLanguageRecord = getLanguageCode() === "en" ? help_En : help_Es;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button
              type="button"
              className="close-btn"
              onClick={this.closeModal}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>
          <div className="modal-body">
            {helpRecord == null && isFetching ? (
              <div className="loaderHolder mt-30">
                <RingLoader color="#2d3d56" loading={isFetching} size={60} />
              </div>
            ) : null}

            <ul className="helpSupport-list arrow-list">
              {userLanguageRecord &&
                userLanguageRecord.map((item, index) =>
                  this.listItem(item, index)
                )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: loadingSelector(state),
    helpRecord: state.school.helpRecord,
    error: state.school.error,
    schoolInfo: state.student.schoolInfo,
    setHelpListData: state.userAccount.setHelpListData,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),
    doGetHelpDetails: (data) => dispatch(getHelpDetails(data)),
    doGetHelpDetailsClear: () => dispatch(getHelpDetailsClear()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(HelpListing)
);
