import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "../CommonComponents/withRouter";
import { connect } from "react-redux";
import moment from "moment";

import {
  addApprovedDelegates,
  addApprovedDelegatesClear,
  updateApprovedDelegatesChange,
  updateApprovedDelegatesChangeClear,
  deleteDelegate,
  deleteDelegateClear,
  delegateImageUpload,
  manualReviewRequest,
  manualReviewCancel,
  delegateImageUploadErrorClear,
  manualRequestClear,
  manualReviewCancelClear,
  getIsTokenValid,
  getIsTokenValidClear,
  logoutUser,
  setCroppedImageClear,
} from "../../common/actions/userAccount";
import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
  setCroppedImage,
} from "../../common/actions/userAccount";
import {
  getStudentsList,
  getRelationship,
} from "../../common/actions/students";
import GLOBAL_CONST from "../../helper/AppConstants";
import {
  nextField,
  formatPhone,
  createImageFileURL,
  checkIsEmergency,
} from "../../helper/utility";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-image-upload/dist/index.css";
import { createLoadingSelector } from "../../common/reducers/selectors";
import successIllustration from "../assets/commonImages/successIllustration.svg";
import rejectedIcon from "../assets/commonImages/rejectedIcon.svg";
import successChecked from "../assets/commonImages/successChecked.svg";

import Resizer from "react-image-file-resizer";

import "react-image-crop/dist/ReactCrop.css";
import { SelectRelationship } from "../ChildManagement";
import { getMyStyle } from "../../theme/ThemeStyle";

const loadingSelector = createLoadingSelector([
  "approved_delegates",
  "approved_delegates_change",
  "delegates_set_deleted",
  "request_manual_review",
  "cancel_manual_review",
]);

const loadingPhotoSelector = createLoadingSelector(["upload_delegate_image"]);

const NOT_SPECIFIED = "Not specified";
class AddContact extends React.Component {
  constructor(props) {
    super(props);
    const { delegate } = this.props;
    this.getStudents();
    this.checkTokenValid();

    this.state = {
      firstNameInvalid: "",
      lastNameInvalid: "",
      mobileNumberInvalid: "",
      nameInvalid: "",
      firstName: delegate ? delegate.Firstname : "",
      lastName: delegate ? delegate.Lastname : "",
      mobileNumber: delegate ? delegate.PhoneNumber : "",
      actionName: "addContact",
      avatarSource: null,
      isModalVisible: false,
      isImageWizardVisible: false,
      isPreviewVisible: false,
      isRejectedVisible: false,
      isRemovePhotoPromptVisible: false,
      delegateImageUploadError: null,
      isManualReviewSubmitSuccess: false,
      isCancelManualReviewSubmitSuccess: false,
      isAddContactSuccess: false,
      contactRecord: delegate,
      tempNewDelegate: null,
      captureImage: null,
      isContactFormVisible: true,
      selectedChildren: [],
      isShowKidForEmergency: true,
      selectedStudents: this.getStudentsInfo(),
      isChangeRelation: true,
      crop: { aspect: 16 / 9 },
      image: null,
      croppedImageDataVal: null,
      isPhotoChanged: false,
    };
  }

  async componentDidMount() {
    this.dosetHelpList();
    this.getStudents();
    this.checkTokenValid();
    this.props.doSetCroppedImageClear();
  }
  checkTokenValid = () => {
    const { doGetIsTokenValid } = this.props;
    doGetIsTokenValid();
  };
  getStudents = () => {
    const { doGetStudentsList } = this.props;
    let currenDate = moment().format("MM-DD-YYYY");
    doGetStudentsList({ currenDate });
  };
  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "ChildManagement",
      screen: GLOBAL_CONST.SCREEN_NAMES.ADD_CONTACT,
      title: this.props.t("Create_Contact"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };
  getStudentsInfo = () => {
    const { studentsList, delegate } = this.props;
    const students = studentsList && studentsList.TodaysStudents;
    let selectedStudents = {};

    if (students && students) {
      for (var i = 0; i < students.length; i++) {
        if (students[i].IsDelegation) continue;
        let matchRecord = null;
        if (
          delegate !== null &&
          delegate !== undefined &&
          delegate.Students !== null &&
          delegate.Students !== undefined &&
          delegate.Students.length > 0
        ) {
          matchRecord = delegate.Students.find(
            (item) => students[i].StudentID === item.StudentId
          );
        }
        if (matchRecord) {
          matchRecord.Id = students[i].StudentID;
          selectedStudents[students[i].StudentID] = matchRecord;
          // 07/27/22 Add not specified on request of backend as backend won't be removing mandatory condition for relationship
          if (
            matchRecord.RelationshipTypeOther === "" ||
            matchRecord.RelationshipTypeOther === NOT_SPECIFIED
          )
            matchRecord.RelationshipTypeOther = null;
        } else {
          selectedStudents[students[i].StudentID] = {
            Id: students[i].StudentID,
            IsEmergency: false,
            StudentId: students[i].StudentID,
          };
        }
      }
    }

    return selectedStudents;
  };
  submitForm = () => {
    const { firstName, lastName, mobileNumber, contactRecord } = this.state;
    const { doAddApprovedDelegates, userLoginInfo } = this.props;
    const { delegate } = this.props;
    const regPhone = /[0-9]/;
    this.setState({
      actionName: "addContact",
    });

    const selectedStudents = { ...this.state.selectedStudents };

    const Students = [];
    for (var key in selectedStudents) {
      const studentInfo = { ...selectedStudents[key] };
      // 07/27/22 Add not specified on request of backend as backend won't be removing mandatory condition for relationship
      if (
        studentInfo.IsEmergency === true &&
        (studentInfo.RelationshipTypeId === null ||
          studentInfo.RelationshipTypeId === undefined) &&
        (studentInfo.RelationshipTypeOther === null ||
          studentInfo.RelationshipTypeOther === undefined)
      ) {
        studentInfo.RelationshipTypeOther = NOT_SPECIFIED;
      }
      Students.push(studentInfo);
    }

    if (firstName == null || firstName == "") {
      this.setState({ firstNameInvalid: this.props.t("First_Name_Alert") });
      return;
    }
    if (lastName === null || lastName == "") {
      this.setState({ lastNameInvalid: this.props.t("Last_Name_Alert") });
      return;
    }
    if (
      mobileNumber === "" ||
      regPhone.test(mobileNumber) === false ||
      mobileNumber.length <= 7
    ) {
      this.setState({
        mobileNumberInvalid: this.props.t("regPhoneScr-phoneInvalid"),
      });
      return;
    } else {
      if (contactRecord) {
        doAddApprovedDelegates({
          list_parentDelegateID: contactRecord.List_ParentDelegateID,
          phoneNumber: mobileNumber,
          firstName,
          lastName,
          Students,
        });
      } else if (userLoginInfo && userLoginInfo.userId) {
        doAddApprovedDelegates({
          list_parentDelegateID: userLoginInfo.userId,
          phoneNumber: mobileNumber,
          firstName,
          lastName,
        });
      }
    }
  };
  updateDelegateStatus = () => {
    const { doUpdateApprovedDelegatesChange } = this.props;
    const { contactRecord } = this.state;
    this.setState({
      actionName: "updateStatus",
    });
    let activeFlag;
    if (contactRecord.IsActive == true) {
      activeFlag = false;
    } else if (contactRecord.IsActive == false) {
      activeFlag = true;
    }

    doUpdateApprovedDelegatesChange({
      list_parentDelegateID: contactRecord.List_ParentDelegateID,
      isActive: activeFlag,
    });
  };

  deleteDelegate = () => {
    this.setState({
      actionName: "deleteContact",
    });
    confirmAlert({
      title: this.props.t("Delete_Delegate"),
      message: this.props.t("delete_Confirm"),
      buttons: [
        {
          label: this.props.t("DELETE"),
          onClick: () => {
            const { doDeleteDelegate } = this.props;
            const { contactRecord } = this.state;

            doDeleteDelegate({
              list_ParentDelegateId: contactRecord.List_ParentDelegateID,
            });
          },
        },
        {
          label: this.props.t("Cancel"),
          className: "cancel-btn",
        },
      ],
    });
  };

  addEmergencyContactForSingleKid = () => {
    alert("Delegate has been Added to emergency contacts");
  };

  removeEmergencyContactForSingleKid = () => {
    alert("Remove from emergency contact");
  };

  uploadDelegatePhoto = () => {
    const { doUploadDelegatePhoto } = this.props;
    const { avatarSource, contactRecord } = this.state;
    doUploadDelegatePhoto({
      parentDelegateID: contactRecord.List_ParentDelegateID,
      image: avatarSource,
    });
  };

  componentDidUpdate() {
    const {
      isRejectedVisible,
      isManualReviewSubmitSuccess,
      isCancelManualReviewSubmitSuccess,
      isModalVisible,
      isPhotoChanged,
    } = this.state;
    const {
      doAddApprovedDelegatesClear,
      approvedDelegatesResponse,
      error,
      doUpdateApprovedDelegatesChangeClear,
      approvedDelegatesChangeResponse,
      delegatesSetDeletedResponse,
      doDeleteDelegateClear,
      delegateImageUploadErrorResponse,
      doDelegateImageUploadErrorClear,
      manualReviewRequestResponse,
      doManualRequestClear,
      delegateImageUploadResponse,
      manualReviewCancelResponse,
      doManualReviewCancelClear,
      croppedImageData,
      showImageCrop,

      isTokenValidResponse,
      doGetIsTokenValidClear,
      doLogoutUser,
    } = this.props;

    if (isTokenValidResponse) {
      if (isTokenValidResponse.Success === false) {
        doLogoutUser();
        this.props.navigate("/");
      } else {
        this.getStudents();
      }
      doGetIsTokenValidClear();
    }

    if (
      croppedImageData !== null &&
      showImageCrop == false &&
      isPhotoChanged == false
    ) {
      this.processImage(croppedImageData, () => {
        this.setState({
          isModalVisible: true,
          isImageWizardVisible: false,
          isPreviewVisible: true,
          isRejectedVisible: false,
          isPhotoChanged: false,
        });
      });
    }

    if (delegateImageUploadErrorResponse && !isRejectedVisible) {
      this.setState(
        {
          delegateImageUploadError: delegateImageUploadErrorResponse,
          isPreviewVisible: false,
          isRejectedVisible: true,
          isPhotoChanged: false,
        },
        doDelegateImageUploadErrorClear
      );
      toast.error(
        delegateImageUploadErrorResponse.Message ||
          delegateImageUploadErrorResponse.Message,
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        }
      );
    }
    if (delegateImageUploadResponse && isModalVisible) {
      this.setState(
        {
          isContactFormVisible: true,
          isModalVisible: false,
          isImageWizardVisible: false,
          isPreviewVisible: false,
          isRejectedVisible: false,
          isRemovePhotoPromptVisible: false,
          isPhotoChanged: false,
        },
        doDelegateImageUploadErrorClear
      );
      toast.success(
        delegateImageUploadResponse.Message ||
          delegateImageUploadResponse.Message,
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        }
      );
    }
    if (manualReviewRequestResponse && !isManualReviewSubmitSuccess) {
      toast.info(this.props.t("manual_request_submit"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
      });

      this.setState(
        { isManualReviewSubmitSuccess: true, isPhotoChanged: false },
        doManualRequestClear
      );
    }
    if (manualReviewCancelResponse && !isCancelManualReviewSubmitSuccess) {
      toast.info(this.props.t("manual_request_cancel"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
      });
      this.setState(
        {
          isCancelManualReviewSubmitSuccess: true,
          avatarSource: null,
          isPhotoChanged: false,
        },
        doManualReviewCancelClear
      );
    }

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doAddApprovedDelegatesClear();
    } else if (approvedDelegatesResponse.length > 0) {
      const { contactRecord } = this.state;

      if (contactRecord) {
        toast.success(this.props.t("saved_delegate"), {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
        doAddApprovedDelegatesClear();
        this.backToContactList();
      } else {
        this.setState(
          {
            isAddContactSuccess: true,
            tempNewDelegate: approvedDelegatesResponse[0],
          },
          doAddApprovedDelegatesClear
        );
      }
    } else if (approvedDelegatesChangeResponse) {
      const { contactRecord } = this.state;
      if (contactRecord.IsActive == true) {
        toast.success(this.props.t("deactivated_delegate"), {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
      } else if (contactRecord.IsActive == false) {
        toast.success(this.props.t("activated_delegate"), {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
      }

      doUpdateApprovedDelegatesChangeClear();
      this.backToContactList();
    } else if (delegatesSetDeletedResponse) {
      toast.success(this.props.t("deleted_delegate"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doDeleteDelegateClear();
      this.backToContactList();
    }
  }

  handleFirstNameChange = ({ target: { value: firstName } }) =>
    this.setState({ firstName, firstNameInvalid: "" });

  handleLastNameChange = ({ target: { value: lastName } }) =>
    this.setState({ lastName, lastNameInvalid: "" });

  handleMobileNumberChange = ({ target: { value: mobileNumber } }) => {
    this.setState({
      mobileNumber: formatPhone(mobileNumber),
      mobileNumberInvalid: "",
    });
  };
  getDelegateImage = () => {
    const { isModalVisible, avatarSource, contactRecord } = this.state;
    if (!isModalVisible && avatarSource) {
      return URL.createObjectURL(avatarSource);
    } else if (contactRecord && contactRecord.PhotoUrl) {
      return contactRecord.PhotoUrl;
    }
    return null;
  };

  removePhotoView = () => {
    const { avatarSource, contactRecord } = this.state;

    return (
      <div className="remove-photo">
        <div className="content-sect text-center ">
          <div className="photo-frame frame-lg">
            <img
              src={
                (avatarSource && createImageFileURL(avatarSource)) ||
                contactRecord.PhotoUrl
              }
            />
          </div>
          <div className="title-sect mb-24">
            <h3 className="title-3">{this.props.t("remove_photo_text")}</h3>
            <p className="helptext">
              {this.props.t("confirm_removephoto_contact")}
            </p>
          </div>
        </div>

        <div className="button-sect">
          <button
            className="btn btn-default red-btn btn-block"
            type="button"
            onClick={() => {
              this.hideUploadPhotoWizard();
              this.props.doManualReviewCancel(
                contactRecord.List_ParentDelegateID
              );
            }}
          >
            {this.props.t("yes_remove")}
          </button>
        </div>
        <div className="bottom-sect text-center">
          <button
            className="btn close-button"
            type="button"
            onClick={() => {
              this.hideUploadPhotoWizard();
            }}
            aria-label={this.props.t("Close_Button")}
          >
            {this.props.t("Close_Button")}
          </button>
        </div>
      </div>
    );
  };

  showPreviewView = () => {
    const { avatarSource } = this.state;
    const { isProcessingVisible, doSetCroppedImage } = this.props;
    return (
      <div>
        <div className="photo-preview">
          <div className="header-sect text-center">
            <div className="title-sect mb-24">
              <h3 className="title-3">{this.props.t("preview_label")}</h3>
            </div>
          </div>
          <div className="content-sect">
            <div className="photo-frame frame-lg">
              {avatarSource ? (
                <img
                  src={createImageFileURL(avatarSource)}
                  alt={this.props.t("preview_label")}
                />
              ) : (
                <i className="fas fa-child"></i>
              )}
            </div>
          </div>
          <div className="button-sect">
            {!isProcessingVisible && (
              <button
                type="button"
                className="btn btn-default btn-block"
                onClick={() => {
                  this.props.doSetCroppedImageClear();
                  this.setState(
                    {
                      isManualReviewSubmitSuccess: false,
                      isPhotoChanged: false,
                      isCancelManualReviewSubmitSuccess: false,
                      delegateImageUploadError: null,
                    },
                    this.uploadDelegatePhoto
                  );
                }}
              >
                {this.props.t("Confirm_Button")}
              </button>
            )}
            {isProcessingVisible && (
              <div className="processing-loader">
                <i className="loader-icon"></i> &nbsp;
                <span className="processing-text">
                  {this.props.t("processing_text")}
                </span>
              </div>
            )}
          </div>
          {!isProcessingVisible && (
            <div className="bottom-sect text-center">
              <button
                className="btn close-button"
                type="button"
                onClick={() => {
                  this.props.doSetCroppedImageClear();
                  this.setState(
                    { avatarSource: null, isPhotoChanged: false },
                    this.hideUploadPhotoWizard
                  );
                }}
              >
                {this.props.t("Close_Button")}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  renderRejectedImagePreview = () => {
    const { avatarSource, delegateImageUploadError } = this.state;
    return (
      <div className="rejected-preview text-center">
        <div className="header-sect text-center">
          <div className="title-sect mb-24">
            <h3 className="title-3">{this.props.t("preview_label")}</h3>
          </div>
        </div>
        <div className="content-sect">
          <div className="photo-frame frame-lg">
            {avatarSource ? (
              <span>
                <img
                  src={createImageFileURL(avatarSource)}
                  alt={this.props.t("preview_label")}
                />
                <i className="alert-icon">
                  <img
                    src={rejectedIcon}
                    className="rejected-icon"
                    alt={this.props.t("preview_label")}
                  />
                </i>
              </span>
            ) : (
              <i className="fas fa-child"></i>
            )}
          </div>
          <div className="alert alert-warning">
            {delegateImageUploadError && delegateImageUploadError.Message}
          </div>
        </div>
        <div className="button-sect">
          <button
            type="button"
            className="btn btn-default btn-block"
            onClick={() => {
              this.setState({
                isModalVisible: true,
                isImageWizardVisible: true,
                isPreviewVisible: false,
                isRejectedVisible: false,
              });
            }}
          >
            {this.props.t("upload_anotherphoto_text")}
          </button>
          <div className="sep-hr"></div>
          <div className="incorrect-label">
            {this.props.t("doyouthink_incorrect_text")}
          </div>
          <div className="mb-16">
            <button
              type="button"
              className="btn btn-style2"
              onClick={() => {
                const { delegateImageUploadError } = this.state;
                if (delegateImageUploadError && delegateImageUploadError.Data) {
                  this.props.doManualReviewRequest(
                    delegateImageUploadError.Data.RejectedImageId
                  );
                }
                this.hideUploadPhotoWizard();
              }}
            >
              {this.props.t("request_manual_review")}
            </button>
          </div>
          <p className="mb-36">
            <span className="highlighted-text">
              {this.props.t("mighttakeabout_1businessday")}
            </span>
          </p>
        </div>
        <div className="bottom-sect">
          <button
            className="btn close-button"
            type="button"
            onClick={() => {
              this.hideUploadPhotoWizard();
            }}
          >
            {this.props.t("Close_Button")}
          </button>
        </div>
      </div>
    );
  };

  processImage = async (captureResponse, callback) => {
    let processImageFile = captureResponse;
    if (
      typeof captureResponse === "string" ||
      captureResponse instanceof String
    ) {
      const resultFile = await this.dataUrlToFile(captureResponse);
      processImageFile = resultFile;
    }

    let asset;

    if (processImageFile) asset = processImageFile;
    if (!asset || asset === undefined) {
      alert("Unable to capture image, please try again");
      return;
    }

    const compressSizer = (size) => {
      const MB = size / Math.pow(1024, 2);
      if (Math.round(MB) === 0) return 100;
      if (Math.round(MB) === 1) return 95;
      if (Math.round(MB) === 2) return 90;
      if (Math.round(MB) === 3) return 85;
      if (Math.round(MB) === 4) return 80;
      if (Math.round(MB) === 5) return 75;
      if (Math.round(MB) === 10) return 70;
      if (Math.round(MB) === 15) return 65;
      if (Math.round(MB) === 20) return 60;
      if (Math.round(MB) >= 25) return 50;
    };
    const compress = compressSizer(asset.size);
    let height = asset.height;
    let width = asset.width;
    let resize;
    if (height === width) resize = { height: 480, width: 480 };
    else if (height > width) resize = { height: 480 };
    else resize = { width: 720 };

    try {
      Resizer.imageFileResizer(
        processImageFile,
        resize.width ? resize.width : resize.height,
        height,
        "JPEG",
        compress,
        0,
        (resizeResponse) => {
          this.props.doSetCroppedImageClear();
          this.setState(
            {
              isPhotoChanged: true,
              avatarSource: resizeResponse,
            },
            callback
          );
        },
        "file"
      );
    } catch (err) {
      console.log("Resizer err: ", err);
    }
  };

  openImageCropperModal = () => {
    const { captureImage } = this.state;
    this.props.doShowModal({
      modalScreenName: "imageCropper",
      modalClassName: "imagecrop-modal",
      params: {
        imageFile: captureImage,
        onRef: (ref) => (this.ImageCrop = ref),
      },
    });
  };

  openCameraModal = () => {
    const { captureImage } = this.state;
    this.props.doShowModal({
      modalScreenName: "cameraLauncher",
      modalClassName: "cameralauncher-modal",
      params: {
        doCallbackRefresh: this.setCapuredImageByCamera,
      },
    });
  };
  setCapuredImageByCamera = (resultFile) => {
    this.setState(
      {
        captureImage: resultFile,
      },
      () => {
        this.openImageCropperModal();
      }
    );
  };

  selectPhotoTapped = () => {
    const { croppedImageData, doSetCroppedImage } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui selectphoto-tapped">
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <div className="uploadphoto-wrapper text-center">
                  <div className="title-sect mb-24">
                    <h3 className="title-3">
                      {this.props.t("editChildScr-selectPicture")}
                    </h3>
                  </div>
                </div>
                <div className="react-confirm-alert-button-group">
                  <label
                    htmlFor="upload_delegate_photo"
                    className="custom-file-upload btn btn-default"
                  >
                    {this.props.t("editChildScr-chooseLibrary")}
                  </label>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    id="upload_delegate_photo"
                    name="upload_delegate_photo"
                    onChange={(event) => {
                      onClose();
                      this.setState(
                        {
                          captureImage: event.target.files[0],
                        },
                        () => {
                          doSetCroppedImage({
                            croppedImageData: null,
                            showImageCrop: true,
                          });
                          this.openImageCropperModal();
                        }
                      );
                    }}
                  />

                  <button
                    type="button"
                    onClick={(event) => {
                      onClose();
                      this.openCameraModal();
                    }}
                  >
                    {this.props.t("editChildScr-takePhoto")}
                  </button>
                  <button
                    className="cancel-btn"
                    type="button"
                    onClick={onClose}
                  >
                    {this.props.t("Close_Button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  dataUrlToFile = async (dataUrl) => {
    var filename = new Date().getTime();
    try {
      const result = await fetch(dataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const captureResponse = new File([blob], filename, {
            type: "image/jpeg",
          });
          return captureResponse;
        });

      if (result) return result;
      else return {};
    } catch (error) {
      console.error(error);
    }
    return {};
  };

  showGuidelinesView = () => {
    return (
      <div className="guidelines-view">
        <div className="header-sect text-center">
          <figure className="mb-24">
            <img
              src={successIllustration}
              alt="Edit Contact"
              title="Edit Contact"
            />
          </figure>
          <div className="title-sect mb-24">
            <h3 className="title-3">{this.props.t("guidelines_title")}</h3>
          </div>
        </div>
        <div className="content-sect">
          <ul className="list text-left">
            <li>{this.props.t("photoupload_guideline1")}</li>
            <li>{this.props.t("photoupload_guideline2")}</li>
            <li>{this.props.t("photoupload_guideline3")}</li>
          </ul>
        </div>
        <div className="button-sect pdt-48">
          <button
            className="btn btn-default btn-block"
            type="button"
            onClick={() => {
              this.selectPhotoTapped();
            }}
          >
            {this.props.t("choose_photo")}
          </button>
        </div>
        <div className="bottom-sect text-center">
          <button
            className="btn close-button"
            type="button"
            onClick={() => {
              this.hideUploadPhotoWizard();
            }}
          >
            {this.props.t("Close_Button")}
          </button>
        </div>
      </div>
    );
  };

  showUploadPhotoWizard = () => {
    this.setState({
      isContactFormVisible: false,
      isAddContactSuccess: false,
      isModalVisible: true,
      isImageWizardVisible: true,
      isPreviewVisible: false,
      isRejectedVisible: false,
    });
  };
  hideUploadPhotoWizard = () => {
    this.setState({
      isContactFormVisible: true,
      isModalVisible: false,
      isImageWizardVisible: false,
      isPreviewVisible: false,
      isRejectedVisible: false,
      isRemovePhotoPromptVisible: false,
    });
  };
  setSelectedKids = (item) => {
    const { selectedChildren } = this.state;

    if (selectedChildren.includes(item.StudentID)) {
      selectedChildren.splice(selectedChildren.indexOf(item.StudentID), 1);
    } else {
      selectedChildren.push(item.StudentID);
    }

    this.setState({ selectedChildren });
  };

  setRelationWithStudent = (StudentID, relation) => {
    let { selectedStudentRelations } = this.state;

    if (
      selectedStudentRelations.filter((student) => {
        if (parseInt(student.StudentID) === parseInt(StudentID)) return true;
      }).length > 0
    ) {
      selectedStudentRelations.map((item) => {
        if (item.StudentID === StudentID) item.relation = relation;
      });
    } else {
      selectedStudentRelations.push({ StudentID, relation });
    }

    this.setState({
      selectedStudentRelations,
    });
  };

  updateShowKid = () => {
    const { isShowKidForEmergency } = this.state;
    this.setState({ isShowKidForEmergency: !isShowKidForEmergency });
  };

  kidAddEmergency = () => {
    const { selectedStudents } = this.state;
    const { studentsList } = this.props;
    const students = studentsList && studentsList.TodaysStudents;
    const studentRecords = students && [...students];
    const kidsList =
      studentRecords && studentRecords.filter((item) => !item.IsDelegation);
    if (kidsList && kidsList.length === 0) return <div></div>;
    return (
      <div className="card form-card form-collapse emergency-contact">
        <div
          className="form-card-title  expand-collapse-row"
          data-bs-toggle="collapse"
          data-bs-target="#emergencycontactsfor-list"
          aria-controls="#emergencycontactsfor-list"
          aria-expanded="true"
        >
          <div className="fc-titletext-icon">
            <span className="text fontsize-14">
              {this.props.t("AddemergencyContactFor")}
            </span>
          </div>
        </div>
        <div className="collapse show" id="emergencycontactsfor-list">
          <div className="emergencycontactsfor-list checkboxlist">
            {kidsList &&
              kidsList.map((item) => (
                <div
                  className="box-checkbox"
                  key={"student-" + item.StudentID}
                  onClick={() => {
                    let selectedStudents = { ...this.state.selectedStudents };
                    selectedStudents[item.StudentID].IsEmergency =
                      !selectedStudents[item.StudentID].IsEmergency;
                    this.setState({ selectedStudents });
                  }}
                >
                  <span className="checkbox-icon">
                    {selectedStudents &&
                    selectedStudents[item.StudentID]?.IsEmergency ? (
                      <i className="fas fa-check-square checked"></i>
                    ) : (
                      <i className="far fa-square"></i>
                    )}
                  </span>
                  <span className="checkbox-label-text">
                    {item.Firstname} {""} {item.Lastname}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  setSelectedRelation = (selectedRelationData) => {
    const selectedStudents = { ...this.state.selectedStudents };
    selectedRelationData.IsEmergency =
      selectedStudents[selectedRelationData.StudentId].IsEmergency;
    selectedStudents[selectedRelationData.StudentId] = selectedRelationData;
    this.setState({ selectedStudents });
  };

  getStudentRelationship = (studentID) => {
    const { relationshipList } = this.props;
    const { selectedStudents } = this.state;
    const rec = selectedStudents[studentID];
    let relationshipText = null;
    if (rec && rec !== undefined) {
      if (rec.RelationshipTypeId) {
        if (
          relationshipList &&
          relationshipList !== undefined &&
          relationshipList.length > 0
        )
          relationshipText = relationshipList.find(
            (item) => item.Id == rec.RelationshipTypeId
          ).Id;
      } else if (rec.RelationshipTypeOther) {
        relationshipText = rec.RelationshipTypeOther;
      }
    }
    return relationshipText;
  };
  kidWithRelationship = () => {
    const { studentsList } = this.props;
    const { selectedChildren } = this.state;
    const students = studentsList && studentsList.TodaysStudents;

    const studentRecords = students && [...students];
    const kidsList =
      studentRecords && studentRecords.filter((item) => !item.IsDelegation);
    if (kidsList && kidsList.length === 0) return <div></div>;

    return (
      <div className="relationshipwith-view">
        <p className="custombox-label">{this.props.t("Relationship")}</p>
        <div className="custom-box">
          {kidsList &&
            kidsList.map((item) => (
              <div
                className="form-group"
                key={"student-" + item.StudentID}
                onClick={() => this.setSelectedKids(item)}
              >
                <span className="control-label"></span>
                <SelectRelationship
                  key={"student-relation" + item.StudentID}
                  StudentID={item.StudentID}
                  selectedRelationshipOption={this.getStudentRelationship(
                    item.StudentID
                  )}
                  studentName={item.Firstname + " " + item.Lastname}
                  doCallback={this.setSelectedRelation}
                />
              </div>
            ))}
        </div>
      </div>
    );
  };

  renderAddEditContactForm = () => {
    const { isFetching, studentsList } = this.props;
    const {
      firstNameInvalid,
      lastNameInvalid,
      mobileNumberInvalid,
      firstName,
      lastName,
      mobileNumber,
      actionName,
      avatarSource,
      isManualReviewSubmitSuccess,
      isCancelManualReviewSubmitSuccess,

      delegateImageUploadError,
      isAddContactSuccess,
      contactRecord,
    } = this.state;
    const styles = getMyStyle(this.props.selectedTheme);

    const submitButtonText = contactRecord
      ? this.props.t("settingsScr-submit")
      : this.props.t("settingsScr-submit");

    return (
      <div className="addEditContactForm">
        {contactRecord && (
          <div className="form-group text-center">
            <figure
              className="photo-frame"
              onClick={() => {
                this.showUploadPhotoWizard();
              }}
            >
              {this.getDelegateImage() ? (
                <span>
                  <img src={this.getDelegateImage()} />
                  <small
                    className="alert-icon"
                    style={{
                      bottom:
                        contactRecord.ImageInReview || delegateImageUploadError
                          ? "-9px"
                          : "-18px",
                    }}
                  >
                    {contactRecord.ImageInReview || delegateImageUploadError ? (
                      <img src={rejectedIcon} className="rejected-icon" />
                    ) : (
                      <i className="fas fa-check-circle success-icon"></i>
                    )}
                  </small>
                </span>
              ) : (
                <i className="fas fa-child"></i>
              )}
            </figure>

            {this.getDelegateImage() || contactRecord.ImageInReview ? (
              <div className="upload-actions-btn">
                <button
                  type="button"
                  onClick={() => {
                    this.setState({
                      isContactFormVisible: false,
                      isModalVisible: true,
                      isImageWizardVisible: true,
                      isPreviewVisible: false,
                      isRejectedVisible: false,
                    });
                  }}
                  className="btn action-btn blue-text"
                >
                  {this.props.t("change_text")}
                </button>
                {contactRecord &&
                  (contactRecord.ImageInReview ||
                    isManualReviewSubmitSuccess) &&
                  !isCancelManualReviewSubmitSuccess && (
                    <button
                      type="button"
                      className="btn action-btn red-text"
                      onClick={() => {
                        this.setState({
                          isRemovePhotoPromptVisible: true,
                          isModalVisible: true,
                          isContactFormVisible: false,
                        });
                      }}
                    >
                      {this.props.t("remove_text")}
                    </button>
                  )}
              </div>
            ) : (
              <label
                className="addphoto-label"
                onClick={() => {
                  this.showUploadPhotoWizard();
                }}
              >
                {this.props.t("upload_photo")}
              </label>
            )}

            {checkIsEmergency(contactRecord) ? (
              <div className="emergencycontact-label">
                <i className="fas fa-asterisk"></i>{" "}
                <span className="text">
                  {this.props.t("Emergency_Contact")}
                </span>
              </div>
            ) : null}

            {avatarSource &&
            delegateImageUploadError &&
            !isManualReviewSubmitSuccess ? (
              <div className="inappropriate-content">
                <div className="alert alert-warning">
                  {delegateImageUploadError && delegateImageUploadError.Message}
                </div>
                <button
                  type="button"
                  className="btn action-btn blue-text"
                  onClick={() => {
                    const { delegateImageUploadError } = this.state;
                    if (
                      delegateImageUploadError &&
                      delegateImageUploadError.Data
                    ) {
                      this.props.doManualReviewRequest(
                        delegateImageUploadError.Data.RejectedImageId
                      );
                    }
                  }}
                >
                  {this.props.t("request_manual_review")}
                </button>
              </div>
            ) : null}

            {contactRecord &&
            (contactRecord.ImageInReview || isManualReviewSubmitSuccess) &&
            !isCancelManualReviewSubmitSuccess ? (
              <div className="inappropriate-content">
                <div className="alert alert-info">
                  {this.props.t("manual_review_alert")}
                </div>
                <button
                  type="button"
                  className="btn action-btn red-text"
                  onClick={() => {
                    this.setState({
                      isRemovePhotoPromptVisible: true,
                      isModalVisible: true,
                      isContactFormVisible: false,
                    });
                  }}
                >
                  {this.props.t("cancel_manualreview")}
                </button>
              </div>
            ) : null}
          </div>
        )}
        <div className="form-group">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder={this.props.t("editChildScr-firstName")}
              name="firstName"
              value={firstName}
              onChange={this.handleFirstNameChange}
              onKeyDown={(event) => nextField(event)}
            />
            <i className="fa-solid fa-user-circle input-icon"></i>
          </div>
          <span className="error">{firstNameInvalid}</span>
        </div>
        <div className="form-group">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder={this.props.t("editChildScr-lastName")}
              name="lastName"
              value={lastName}
              onChange={this.handleLastNameChange}
              onKeyDown={(event) => nextField(event)}
            />
            <i className="fa-solid fa-user-circle input-icon"></i>
          </div>
          <span className="error">{lastNameInvalid}</span>
        </div>
        <div className="form-group">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder={this.props.t("regPhoneScr-phoneInvalid")}
              name="mobileNumber"
              value={mobileNumber}
              onChange={this.handleMobileNumberChange}
              maxLength={15}
              onKeyDown={(event) => {
                if (event.key === "Enter") this.submitForm();
              }}
            />
            <i className="fa-solid fa-phone input-icon"></i>
          </div>
          <span className="error">{mobileNumberInvalid}</span>
        </div>

        <div className="form-group buttons-section">
          {contactRecord &&
            studentsList &&
            studentsList.TodaysStudents &&
            this.kidWithRelationship()}
          {contactRecord &&
            studentsList &&
            studentsList.TodaysStudents &&
            this.kidAddEmergency()}
          <button
            type="button"
            className="btn btn-default btn-block"
            style={styles.buttonDefault}
            onClick={this.submitForm}
          >
            {actionName === "addContact" && isFetching ? (
              <i className="fas fa-sync-alt fa-spin"></i>
            ) : null}{" "}
            {submitButtonText}
          </button>

          {contactRecord && (
            <div className="other-actions-btn">
              <button
                type="button"
                className="btn btn-default btn-block"
                style={styles.buttonDefault}
                onClick={() => {
                  this.updateDelegateStatus();
                }}
              >
                {actionName === "updateStatus" && isFetching ? (
                  <i className="fas fa-sync-alt fa-spin"></i>
                ) : null}{" "}
                {contactRecord.IsActive === true
                  ? this.props.t("Inactive_Button")
                  : this.props.t("Active_Button")}
              </button>

              <button
                type="button"
                className="btn btn-default btn-block btn-red"
                onClick={() => {
                  this.deleteDelegate();
                }}
              >
                {actionName === "deleteContact" && isFetching ? (
                  <i className="fas fa-sync-alt fa-spin"></i>
                ) : null}{" "}
                {this.props.t("DELETE")}
              </button>
            </div>
          )}
        </div>
        {isAddContactSuccess && !contactRecord
          ? this.addContactSuccessView(firstName + " " + lastName)
          : null}
      </div>
    );
  };

  addContactSuccessView = (contactName) => {
    const { tempNewDelegate } = this.state;
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui success-view">
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <div className="uploadphoto-wrapper text-center">
                  <div className="title-sect mb-24">
                    <figure className="mb-24">
                      <img
                        src={successChecked}
                        alt="Contact created successfully"
                        title="Contact created successfully"
                      />
                    </figure>
                    <h3 className="title-3">
                      {this.props.t("Contact_Created")}
                    </h3>
                  </div>
                  <div className="content-sect">
                    {this.props
                      .t("addContactSuccess")
                      .replace("{contactName}", contactName)}
                  </div>
                </div>
                <div className="react-confirm-alert-button-group">
                  <button
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();

                      this.setState(
                        {
                          isAddContactSuccess: false,
                          contactRecord: tempNewDelegate,
                          isModalVisible: true,
                          isImageWizardVisible: true,
                          isPreviewVisible: false,
                          isRejectedVisible: false,
                          isContactFormVisible: false,
                        },
                        onClose
                      );
                    }}
                  >
                    {this.props.t("Yes")}
                  </button>
                  <button
                    className="cancel-btn"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      onClose();
                      this.setState({ isAddContactSuccess: false }, () => {
                        this.props.doAddApprovedDelegatesClear();
                        this.backToContactList();
                      });
                    }}
                  >
                    {this.props.t("notNow")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  renderCreateContactView = () => {
    const {
      isImageWizardVisible,
      isModalVisible,
      isPreviewVisible,
      isRejectedVisible,
      isContactFormVisible,
      isRemovePhotoPromptVisible,
      contactRecord,
      isPhotoChanged,
    } = this.state;
    const modalTitle = contactRecord
      ? this.props.t("Update_Contact")
      : this.props.t("Create_Contact");

    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">{modalTitle}</h4>
              <button
                type="button"
                className="btn help-icon-btn"
                onClick={() => {
                  this.openHelpModal();
                }}
              >
                {this.props.t("regNameScr-needHelp")}
              </button>
            </div>

            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.backToContactList();
              }}
            ></button>
          </div>

          <div className="modal-body">
            <div className="form-card">
              {!contactRecord && (
                <strong>{this.props.t("addContact_msg")}</strong>
              )}
              <form className="form-style1" encType="multipart/form-data">
                {isContactFormVisible && this.renderAddEditContactForm()}
                {isModalVisible &&
                  isImageWizardVisible &&
                  this.showGuidelinesView()}

                {isModalVisible && isPreviewVisible && this.showPreviewView()}
                {isModalVisible &&
                  isRejectedVisible &&
                  this.renderRejectedImagePreview()}
                {isModalVisible &&
                  isRemovePhotoPromptVisible &&
                  this.removePhotoView()}
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  };

  backToContactList = () => {
    this.props.doHideModal();
    const { callback } = this.props;
    if (callback) callback();
  };

  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };

  render() {
    return this.renderCreateContactView();
  }
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    approvedDelegatesResponse: state.userAccount.approvedDelegatesResponse,
    approvedDelegatesChangeResponse:
      state.userAccount.approvedDelegatesChangeResponse,
    delegatesSetDeletedResponse: state.userAccount.delegatesSetDeletedResponse,
    error: state.userAccount.error,
    isFetching: loadingSelector(state),
    setHelpListData: state.userAccount.setHelpListData,

    isProcessingVisible: loadingPhotoSelector(state),
    delegateImageUploadErrorResponse:
      state.userAccount.delegateImageUploadError,
    delegateImageUploadResponse: state.userAccount.delegateImageUploadResponse,
    manualReviewRequestResponse: state.userAccount.manualReviewRequest,
    manualReviewCancelResponse: state.userAccount.manualReviewCancelResponse,
    relationshipList: state.student.relationshipList,
    studentsList: state.student.studentsList,

    isTokenValidResponse: state.userAccount.isTokenValidResponse,

    croppedImageData: state.userAccount.croppedImageData,
    showImageCrop: state.userAccount.showImageCrop,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doAddApprovedDelegates: (data) => dispatch(addApprovedDelegates(data)),
    doAddApprovedDelegatesClear: () => dispatch(addApprovedDelegatesClear()),
    doUpdateApprovedDelegatesChange: (data) =>
      dispatch(updateApprovedDelegatesChange(data)),
    doUpdateApprovedDelegatesChangeClear: () =>
      dispatch(updateApprovedDelegatesChangeClear()),
    doDeleteDelegate: (data) => dispatch(deleteDelegate(data)),
    doDeleteDelegateClear: () => dispatch(deleteDelegateClear()),

    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),

    doUploadDelegatePhoto: (data) => dispatch(delegateImageUpload(data)),
    doManualReviewRequest: (data) => dispatch(manualReviewRequest(data)),
    doDelegateImageUploadErrorClear: (data) =>
      dispatch(delegateImageUploadErrorClear(data)),
    doManualRequestClear: () => dispatch(manualRequestClear()),
    doManualReviewCancel: (data) => dispatch(manualReviewCancel(data)),
    doManualReviewCancelClear: () => dispatch(manualReviewCancelClear()),
    doGetRelationship: (data) => dispatch(getRelationship(data)),
    doGetStudentsList: (data) => dispatch(getStudentsList(data)),

    doSetCroppedImage: (data) => dispatch(setCroppedImage(data)),
    doSetCroppedImageClear: () => dispatch(setCroppedImageClear()),

    doGetIsTokenValid: (data) => dispatch(getIsTokenValid(data)),
    doGetIsTokenValidClear: () => dispatch(getIsTokenValidClear()),
    doLogoutUser: () => dispatch(logoutUser()),
  };
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddContact))
);
