// Get Students list
export const GET_STUDENTS_START = "get_students_start";
export const GET_STUDENTS_SUCCESS = "get_students_success";
export const GET_STUDENTS_FAILED = "get_students_failed";

// Get Students list
export const GET_SCHOOL_INFO_START = "get_school_info_start";
export const GET_SCHOOL_INFO_SUCCESS = "get_school_info_success";
export const GET_SCHOOL_INFO_FAILED = "get_school_info_failed";

export const GET_SCHOOL_AND_KIDS_DONE = "get_school_and_kids_done";
export const GET_SCHOOL_STUDENT_INFO_CLEAR = "get_school_student_info_clear";

export const GET_OTHER_KIDS_DONE = "get_other_kids_done";
export const GET_OTHER_KIDS_CLEAR = "get_other_kids_clear";

// Get busRoute

export const GET_BUS_ROUTE_START = "get_bus_route_start";
export const GET_BUS_ROUTE_SUCCESS = "get_bus_route_success";
export const GET_BUS_ROUTE_FAILED = "get_bus_route_failed";

//After School

export const GET_STUDENT_AFTER_SCHOOL_START = "get_student_after_school_start";
export const GET_STUDENT_AFTER_SCHOOL_SUCCESS =
  "get_student_after_school_success";
export const GET_STUDENT_AFTER_SCHOOL_FAILED =
  "get_student_after_school_failed";

//Announce

export const IS_ANNOUNCE_START = "is_announce_start";
export const IS_ANNOUNCE_SUCCESS = "is_announce_success";
export const IS_ANNOUNCE_FAILED = "is_announce_failed";
export const IS_ANNOUNCE_CLEAR = "is_announce_clear";

// GetDelegation

export const GET_DELEGATIONS_START = "get_delegation_start";
export const GET_DELEGATIONS_SUCCESS = "get_delegation_success";
export const GET_DELEGATIONS_FAILED = "get_delegation_failed";
export const GET_DELEGATIONS_CLEAR = "get_delegation_clear";
// change default pickupmode
export const CHANGE_DEFAULT_PICKUP_MODE_START =
  "change_default_pickup_mode_start";
export const CHANGE_DEFAULT_PICKUP_MODE_SUCCESS =
  "change_default_pickup_mode_success";
export const CHANGE_DEFAULT_PICKUP_MODE_FAILED =
  "change_default_pickup_mode_failed";
export const CHANGE_DEFAULT_PICKUP_MODE_CLEAR =
  "change_default_pickup_mode_clear";
// cancel delegation
export const CANCEL_DELEGATION_START = "cancel_delegation_start";
export const CANCEL_DELEGATION_SUCCESS = "cancel_delegation_success";
export const CANCEL_DELEGATION_FAILED = "cancel_delegation_failed";
export const CANCEL_DELEGATION_CLEAR = "cancel_delegation_clear";

//get other Children

export const GET_OTHER_CHILDREN_START = "get_other_children_start";
export const GET_OTHER_CHILDREN_SUCCESS = "get_other_children_success";
export const GET_OTHER_CHILDREN_FAILED = "get_other_children_failed";

// Get absentee Date Range

export const GET_ABSENT_DATE_RANGE_START = "get_absent_date_range_start";
export const GET_ABSENT_DATE_RANGE_SUCCESS = "get_absent_date_range_success";
export const GET_ABSENT_DATE_RANGE_FAILED = "get_absent_date_range_failed";

// Get calendar Date Range

export const GET_CALENDAR_DATE_RANGE_START = "get_calendar_date_range_start";
export const GET_CALENDAR_DATE_RANGE_SUCCESS =
  "get_calendar_date_range_success";
export const GET_CALENDAR_DATE_RANGE_FAILED = "get_calendar_date_range_failed";

// Sabmit absentee
export const SAVE_ABSENTEE_START = "save_absentee_start";
export const SAVE_ABSENTEE_SUCCESS = "save_absentee_success";
export const SAVE_ABSENTEE_FAILED = "save_absentee_failed";
export const SAVE_ABSENTEE_CLEAR = "save_absentee_clear";

export const GET_PICKUP_ENTRIES_START = "get_pickup_enrties_start";
export const GET_PICKUP_ENTRIES_SUCCESS = "get_pickup_enrties_success";
export const GET_PICKUP_ENTRIES_FAILED = "get_pickup_enrties_failed";
export const GET_PICKUP_ENTRIES_CLEAR = "get_pickup_enrties_clear";

export const GET_ABSENT_CALENDAR_ENTRIES_RANGE_START =
  "get_absent_calendar_entries_range_start";
export const GET_ABSENT_CALENDAR_ENTRIES_RANGE_SUCCESS =
  "get_absent_calendar_entries_range_success";
export const GET_ABSENT_CALENDAR_ENTRIES_RANGE_FAILED =
  "get_absent_calendar_entries_range_failed";
export const GET_ABSENT_CALENDAR_ENTRIES_RANGE_CLEAR =
  "get_absent_calendar_entries_range_clear";

export const CHANGE_DELEGATION_START = "change_delegation_start";
export const CHANGE_DELEGATION_SUCCESS = "change_delegation_success";
export const CHANGE_DELEGATION_FAILED = "change_delegation_failed";
export const CHANGE_DELEGATION_CLEAR = "change_delegation_clear";

export const GET_PICKUP_ENTRIES_MONTH_START = "get_pickup_entries_month_start";
export const GET_PICKUP_ENTRIES_MONTH_SUCCESS =
  "get_pickup_entries_month_success";
export const GET_PICKUP_ENTRIES_MONTH_FAILED =
  "get_pickup_entries_month_failed";

export const GET_DELEGATE_LIST_START = "get_delegate_list_start";
export const GET_DELEGATE_LIST_SUCCESS = "get_delegate_list_success";
export const GET_DELEGATE_LIST_FAILED = "get_delegate_list_failed";

export const GET_DEFAULT_DISMISSAL_START = "get_default_dismissal_start";
export const GET_DEFAULT_DISMISSAL_SUCCESS = "get_default_dismissal_success";
export const GET_DEFAULT_DISMISSAL_FAILED = "get_default_dismissal_failed";
export const GET_DEFAULT_DISMISSAL_CLEAR = "get_default_dismissal_clear";

//GET_DELEGATE_LIST_START, GET_DELEGATE_LIST_SUCCESS, GET_DELEGATE_LIST_FAILED

export const CANCEL_ABSENTEE_START = "cancel_absentee_start";
export const CANCEL_ABSENTEE_SUCCESS = "cancel_absentee_success";
export const CANCEL_ABSENTEE_FAILED = "cancel_absentee_failed";
export const CANCEL_ABSENTEE_CLEAR = "cancel_absentee_clear";

export const GET_RELATED_DELEGATIONS_START = "get_related_delegations_start";
export const GET_RELATED_DELEGATIONS_SUCCESS =
  "get_related_delegations_success";
export const GET_RELATED_DELEGATIONS_FAILED = "get_related_delegations_failed";
export const GET_RELATED_DELEGATIONS_CLEAR = "get_related_delegations_clear";

export const CANCEL_MULTIPLE_DELEGATIONS_START =
  "cancel_multiple_delegations_start";
export const CANCEL_MULTIPLE_DELEGATIONS_SUCCESS =
  "cancel_multiple_delegations_success";
export const CANCEL_MULTIPLE_DELEGATIONS_FAILED =
  "cancel_multiple_delegations_failed";
export const CANCEL_MULTIPLE_DELEGATIONS_CLEAR =
  "cancel_multiple_delegations_clear";

export const GET_AVAILABLE_DISPATCH_START = "get_available_dispatch_start";
export const GET_AVAILABLE_DISPATCH_SUCCESS = "get_available_dispatch_success";
export const GET_AVAILABLE_DISPATCH_FAILED = "get_available_dispatch_failed";
export const GET_AVAILABLE_DISPATCH_CLEAR = "GET_AVAILABLE_DISPATCH_CLEAR";

export const SAVE_SELECTED_GAURDIAN_DETAILS_START =
  "save_selected_gaurdian_details_start";

export const GET_DISMISSAL_HISTORY_START = "get_dismissal_history_start";
export const GET_DISMISSAL_HISTORY_SUCCESS = "get_dismissal_history_success";
export const GET_DISMISSAL_HISTORY_FAILED = "get_dismissal_history_failed";
export const GET_DISMISSAL_HISTORY_CLEAR = "get_dismissal_history_clear";

export const GET_POSITION_IN_LINE_START = "get_postion_in_line_start";
export const GET_POSITION_IN_LINE_SUCCESS = "get_postion_in_line_success";
export const GET_POSITION_IN_LINE_FAILED = "get_postion_in_line_failed";
export const GET_POSITION_IN_LINE_CLEAR = "get_postion_in_line_clear";

export const GET_DELEGATIONS_ENTRIES_START = "get_delegations_entries_start";
export const GET_DELEGATIONS_ENTRIES_SUCCESS =
  "get_delegations_entries_success";
export const GET_DELEGATIONS_ENTRIES_FAILED = "get_delegations_entries_failed";
export const GET_DELEGATIONS_ENTRIES_CLEAR = "get_delegations_entries_clear";

export const TODAYS_KIDS_ANNOUNCED_LIST = "todays_kids_announced_list";

//Early Dismissal
export const EARLY_DISMISSAL_START = 'early_dismissal_start';
export const EARLY_DISMISSAL_SUCCESS = 'early_dismissal_success';
export const EARLY_DISMISSAL_FAILED = 'early_dismissal_failed';
export const EARLY_DISMISSAL_CLEAR = 'early_dismissal_clear';

export const CANCEL_EARLY_DISMISSAL_START = 'cancel_early_dismissal_start';
export const CANCEL_EARLY_DISMISSAL_SUCCESS = 'cancel_early_dismissal_success';
export const CANCEL_EARLY_DISMISSAL_FAILED = 'cancel_early_dismissal_failed';
export const CANCEL_EARLY_DISMISSAL_CLEAR = 'cancel_early_dismissal_clear';

export const GET_EARLY_DISMISSAL_DATE_RANGE_START = 'get_early_dismissal_start';
export const GET_EARLY_DISMISSAL_DATE_RANGE_SUCCESS = 'get_early_dismissal_success';
export const GET_EARLY_DISMISSAL_DATE_RANGE_FAILED = 'get_early_dismissal_failed';
export const GET_EARLY_DISMISSAL_DATE_RANGE_CLEAR = 'get_early_dismissal_clear';

// Get back to school info
export const GET_BACK_SCHOOL_START = 'get_back_school_start';
export const GET_BACK_SCHOOL_SUCCESS = 'get_back_school_success';
export const GET_BACK_SCHOOL_FAILED = 'get_back_school_failed';
export const GET_BACK_SCHOOL_CLEAR = 'get_back_school_clear';

// Save back to school info
export const SAVE_BACK_SCHOOL_START = 'save_back_school_start';
export const SAVE_BACK_SCHOOL_SUCCESS = 'save_back_school_success';
export const SAVE_BACK_SCHOOL_FAILED = 'save_back_school_failed';
export const SAVE_BACK_SCHOOL_CLEAR = 'save_back_school_clear';

// Get Relationship Type
export const GET_RELATIONSHIP_START = 'get_relationship_start';
export const GET_RELATIONSHIP_SUCCESS = 'get_relationship_success';
export const GET_RELATIONSHIP_FAILED = 'get_relationship_failed';
export const GET_RELATIONSHIP_CLEAR = 'get_relationship_clear';


// Get Fill back to school info
export const GET_FILL_BACKTOSCHOOL_START = 'get_fill_backtoschool_start';
export const GET_FILL_BACKTOSCHOOL_SUCCESS = 'get_fill_backtoschool_success';
export const GET_FILL_BACKTOSCHOOL_FAILED = 'get_fill_backtoschool_failed';
export const GET_FILL_BACKTOSCHOOL_CLEAR = 'get_fill_backtoschool_clear';

// GetSchoolDispatchMethods
export const GET_SCHOOL_DISPATCH_START = 'get_school_dispatch_start';
export const GET_SCHOOL_DISPATCH_SUCCESS = 'get_school_dispatch_success';
export const GET_SCHOOL_DISPATCH_FAILED = 'get_school_dispatch_failed';
export const GET_SCHOOL_DISPATCH_CLEAR = 'get_school_dispatch_clear';

// Set calendar view
export const SET_CALENDAR_VIEW = 'set_calendar_View'
export const SET_CALENDAR_VIEW_CLEAR = 'set_calendar_View_clear'
