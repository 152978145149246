import React from "react";
import { connect } from "react-redux";
import { withRouter } from "./withRouter";
import { withTranslation } from "react-i18next";
import { logoutUser } from "../../common/actions/userAccount";
import { saveTermsAccepted } from "../../common/actions/userAccount/userAccountAction";
class TermsConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAccepted: false,
    };
  }

  render() {
    const { termsAccepted } = this.state;
    return (
      <section className="termsandconditions">
        <div className="content-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-sect mb-30">
                  <h2 className="title">
                  TERMS OF USE PIKMYKID
                  </h2>
                  <hr className="hr-gradient" />
                </div>
               

                <section className="slide slide-center" id="privacySection">
                  <div className="slide__content">
                    <div className="slide__copy slide__copy--left typography">
                     
                      <p>Pikmykid is a Comprehensive Safety Platform designed to manage the Daily Dismissal Safety and Emergency operations that keeps students safe every day, from arrival through student dismissal (“Services”). Pikmykid is both an app based and software solution. The app is available for parents on iTunes and the Google Play store. Organizations can manage requests and communicate with parents and others through the Pikmykid website. To assist you in using Pikmykid and to ensure a clear understanding of the relationship arising from your use of our services, we have created these Terms of Use (the “Terms”).</p>
                      <br />
                      <br />
                      <h3>TERMS OF USE</h3>
                      <br />

                      <h4>Application of Terms; Your Agreement</h4>
                      <p>These Terms are a binding contract between you, Sachi Tech, Inc. (the owner of Pikmykid.com) its affiliates, subsidiaries and related companies and persons (collectively, “Pikmykid”). These terms impose legal obligations on you. Please read them carefully. By using the Services or accessing Pikmykid.com or the Pikmykid portal (collectively the “Site”) you are acknowledging that (1) you are at least 18 years old, (2) YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS OF USE (“AGREEMENT”), (3) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH PIKMYKID, AND (4) YOU HAVE THE AUTHORITY TO ENTER INTO THE TERMS ON BEHALF OF YOURSELF OR THE ORGANIZATION YOU WORK FOR, AND TO BIND THAT ORGANIZATION TO THESE TERMS (in which case, the references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or entity). You also certify that you are legally permitted to use and access the Services and take full responsibility for the selection and use of and access to the Services. This Agreement is void where prohibited by law, and the right to access the Services is revoked in such jurisdictions. IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT, YOU MAY NOT BROWSE THE WEBSITE OR DOWNLOAD, INSTALL, OR USE THE APPLICATION, SOFTWARE, OR ANY OTHER SERVICES.</p>
                      <p>These Terms apply to (i) schools, school districts and related entities, companies, clubs, teams, and other organizations that use the Services or access the Site (each an “Organization”), (ii) educational professionals, school administrators, school/organizational employees, authorized users and agents who access the Site or use the Services (“Educational Professionals”), (iii) all Site visitors and users of the Services including parents, legal guardians and anyone authorized to use the Services or access the site by any of the foregoing (“Parents”), (iv) and any third party or agent performing work related to the Services, such as suppliers and developers (“Developers”). For purposes of these Terms, the words “user”, “you” and “your” refer to Organizations, Educational Professionals, Parents and Developers. The words “we,” “our,” or “us” refer to Pikmykid.</p>
                      <p>You must be at least eighteen (18) years old to use our Services. By agreeing to the Terms, you represent and warrant to us that you are at least eighteen (18) years old and that your registration and your use of the Services shall at all times comply with applicable laws and regulations. The Services and the Site are provided only in the English language.</p>
                      <h3>Acceptable Use of the Services, Applications or Site</h3>
                      <p>By using the Services or accessing the Site, you agree not to do any of the following:</p>
                      <ul>
                        <li>Rent, lease, loan, sell, resell, sublicense, distribute or otherwise transfer the licenses granted in connection with using the Services or accessing the Site.</li>
                        <li>Impersonate any person or entity, falsely claim an affiliation with any person or entity, or misrepresent the source, identity, or content of information transmitted to or via the Services or Site, or perform any other similar or fraudulent activity;</li>
                        <li>Use the Service or access the Site for any illegal purpose, or in violation of any local, state, national, or international law, including, without limitation COPPA, the PPRA, FERPA, laws related to the protection of children, laws governing intellectual property and other proprietary rights, and data protection and privacy;</li>
                        <li>Remove, circumvent, disable, damage or otherwise interfere with (i) security-related features of the Services or Site, (ii) features that prevent or restrict use or copying of any content accessible through the Service, or (iii) features that enforce limitations on the use of the Services or Site;</li>
                        <li>Modify, adapt, translate or create derivative works based upon the Services or Site;</li>
                        <li>Interfere with or damage operation of the Services or Site or any user’s enjoyment of them, by any means, including uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code or disabling, overburdening or impairing the Services or Site. Seek to gain access to the Services or Site through “hacking” or any other means not expressly authorized in writing by PikMykid.</li>
                      </ul>
                      <h3>Application License</h3>
                      <p>Subject to your compliance with this Agreement, during the term of this Agreement, Pikmykid grants you a limited non-exclusive, non-transferable, non-sublicensable, revocable license to download, install and use a copy of the Application on a single mobile device that you own or control and to run such copy of the Application solely for your own personal or internal business purposes in accordance with any documentation which may be provided by Pikmykid (“Documentation”). Furthermore, with respect to any Application accessed through or downloaded from the Apple App Store (an “App Store Sourced Application”), you will only use the App Store Sourced Application (i) on an Apple-branded product that runs the iOS (Apple’s proprietary operating system) and (ii) as permitted by the “Usage Rules” set forth in the Apple App Store Terms of Service.</p>


                      <p>If at any time, you suspect or are concerned that:</p>
                      <ul>
                        <li>The Services or Site are not working properly;</li>
                        <li>You do not understand how to use the Services or Site and/or are not sure you have transmitted or received the proper information;</li>
                        <li>For Parents: Information regarding your child’s pick-up information has not been properly transmitted to the school;</li>
                        <li>For Educational Professionals: The student dismissal information generated by the Services or Site does not appear to be consistent with what you have typically seen and/or may have errors–</li>
                      </ul>
                      <p>IT IS YOUR RESPONSIBILITY TO CONFIRM THE CORRECT CONTACT DETAILS AND PICK UP INFORMATION WITH THE PARENTS/SCHOOL AS THE CASE MAY BE.</p>

                      <h3>Accounts and Security</h3>
                      <p>You will be required to establish an account to access the Services. You are entirely responsible for maintaining the confidentiality of your account information, including your username and password, and for any and all activity that occurs under your account as a result of your failing to keep this information secure and confidential. You agree to notify PikMyKid immediately of any unauthorized use of your account or password, or any other breach of security. You may be held liable for losses due to someone else using your username, password or account as a result of your failing to keep your account information secure and confidential. PikMyKid cannot and will not be liable for any loss or damage arising from your failure to comply with these obligations. PikMyKid may terminate, suspend or restrict your access to your account at any time and for any reason, including for violation of these Terms. PikMyKid has the sole right to decide whether you are in violation of these Terms. Account termination may result in destruction of any content associated with your account.</p>
           <strong><p>Acceptable Use</p></strong>
            <p>By using the Services or accessing the Site, you agree not to do any of the following:
Rent, lease, loan, sell, resell, sublicense, distribute or otherwise transfer the licenses granted in connection with using the Services or accessing the Site.
Impersonate any person or entity, falsely claim an affiliation with any person or entity, or misrepresent the source, identity, or content of information transmitted to or via the Services or Site, or perform any other similar or fraudulent activity;
Use the Service or access the Site for any illegal purpose, or in violation of any local, state, national, or international law, including, without limitation COPPA, the PPRA, FERPA, laws related to the protection of children, laws governing intellectual property and other proprietary rights, and data protection and privacy;
Remove, circumvent, disable, damage or otherwise interfere with (i) security-related features of the Services or Site, (ii) features that prevent or restrict use or copying of any content accessible through the Service, or (iii) features that enforce limitations on the use of the Services or Site;
Modify, adapt, translate or create derivative works based upon the Services or Site; or
Interfere with or damage operation of the Services or Site or any user’s enjoyment of them, by any means, including uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code or disabling, overburdening or impairing the Services or Site.
Seek to gain access to the Services or Site through “hacking” or any other means not expressly authorized in writing by PikMyKid.</p>
              
                      <h3>Family Educational Rights and Privacy Act</h3>
                      <p>Certain information users provide to PikMyKid about students may be considered an education record (“Education Record”) under the Family Educational Rights and Privacy Act (“FERPA”). Additionally, certain information users provide to PikMyKid about a student, such as student name and grade level, may be considered directory information under FERPA (“Directory Information”) and thus not an Education Record. A School may not generally disclose personally identifiable information from a student’s records without written consent of the parent and/or eligible student or without meeting one of the exemptions set forth in FERPA such as the Directory Information exemption (as defined by FERPA).</p>
                      <p>To the extent you provide information subject to FERPA, you represent, warrant and covenant to PikMyKid that you have fully complied with all FERPA requirements including, without limitation, obtaining all necessary written consents to share the information with PikMyKid for use by PikMyKid to the fullest extent necessary to provide the Services;</p>
                      <p>Unless permitted by FERPA or an applicable exemption, PikMyKid will never share an Education Record with third parties except as directed by a user or to our service providers that are necessary for us to provide the Service.</p>
                      <p>PikMyKid may use de-identified Education Records for product development, research or other purposes (“De-Identified Data”). PikMyKid agrees not to attempt to re-identify the De-Identified Data and not to transfer the De-Identified Data to a third party unless that party agrees not to attempt re-identification.</p>

                      <h3>Children’s Online Privacy and Protection Act</h3>
                      <p>The Children’s Online Privacy and Protection Act (“COPPA”) requires that online service providers obtain clear and verifiable parental consent before collecting personal information from children under 13. PikMyKid does not and will not knowingly collect any information from children under the age of 13. To the extent you provide information related to a child under the age of 13, you are solely responsible for complying with COPPA and you represent and warrant that you have fully complied with COPPA by, among other things, receiving the appropriate consent from parents and/or having the requisite authority to provide such information to PikMyKid, and for us to collect such information.</p>

                      <h3>Intellectual Property Rights</h3>
                      <p>The information and content of the Services and the Site, including but not limited to: software, artwork, text, video, audio, copy, graphics, images, logos and other information, other than content provided by Users and advertisers, is owned or licensed by PikMyKid and is protected by copyright and other intellectual property laws under both United States and foreign laws. Any use of the information and the content of the Services or Site not expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other laws. All rights not expressly granted herein are reserved to PikMyKid. Users and any other content providers are responsible for obtaining the appropriate permissions and rights for posting any content to the Site and agree to indemnify, hold harmless and defend PikMyKid for any claims, demands and judgment that arise out of a violation of this paragraph.</p>

                      <h3>Links to Third Party Sites</h3>
                      <p>We may provide links to third-party websites. PikMyKid is not responsible for the content of linked third-party sites or third-party advertisements, and does not make any representations regarding their content or accuracy. We provide these links as a convenience only, and a link does not imply our endorsement of, sponsorship of, or affiliation with the linked site. Your use of third-party websites is at your own risk and subject to the terms and conditions of use for such sites.</p>
                      <h3>Assumption of Risk</h3>
                      <p>While using the Services or accessing the Site from a mobile device, please be aware of your surroundings and exercise reasonable judgment. You agree that your use of the Service and accessing the site is at your own risk, and it is your responsibility to maintain such health, liability, hazard, personal injury, medical, life, and other insurance policies as you deem reasonably necessary for any injuries that you may incur while using the Services or accessing the Site. It is strictly forbidden to use the Services or access the Site while driving. While using a vehicle, you may only access the Site or use the Services after you have stopped your vehicle in an appropriate location permitted by law. If you are accessing the Site or using the Services in an area where there is traffic or other potentially dangerous conditions, stop and stand in a safe place and remain stationary until you are no longer accessing the Site or using the Services.</p>
 <p>PikMyKid may, but shall not be obligated to, provide an emergency notification platform as part of the Services (“ENP”). You must not rely on the ENP as a means to communicate information to emergency service providers, such as the fire department, emergency medical personnel and law enforcement. ENP is not a substitute for using 911 or any other emergency communication service. In the event of an emergency, you should dial 911 or directly contact emergency services.</p>
                  
                      <p><strong>IMPORTANT— THE SERVICES ARE PROVIDED FOR INFORMATIONAL PURPOSES ONLY AND ARE NOT INTENDED TO SUBSTITUTE FOR PUBLIC EMERGENCY RESPONSE SYSTEMS. IN THE EVENT OF AN EMERGENCY, PLEASE CALL 911.</strong></p>

                      <h3>Additional Terms</h3>
                      <p>If you are a Parent setting up an account to communicate with a Pikmykid Organization about your student (including friends or family members who download the app with an invitation from a Parent):</p>
                      <ul>
                        <li>You will only use the mobile application with your own registered account and communicate with your Organization upon your arrival or scheduled changes for your students.</li>
                        <li>The Services allow you to register and create an account and invite friends and family via text messages and emails. If you invite other users to join your emergency contacts, you will obtain the prior express written consent of each person before sending them an invitation and/or adding them to receive messages from Pikmykid on behalf of the Organization or from you.</li>
                     
                        <li>If you are an individual who sets up the account to communicate with a Pikmykid school and invite your family or friends to join the daily pickup or emergency pickup schedule for your student then you are a “Parent”. If you are an individual who downloaded the app with the invite from a Parent, then you are a “Delegate”.</li>
             
                        <li>If you are a Parent or Delegate: You will only use the mobile application with your own registered account and communicate with your school upon your arrival or scheduled changes for your students, act on emergency communication from your schools.</li>
                        <li>If you are accessing the Services as Parent: The Services allow you to register and create an account and invite delegates via text messages, emails. IF YOU INVITE OTHER USERS TO JOIN YOUR EMERGENCY CONTACTS /DELEGATES, YOU REPRESENT AND WARRANT TO US THAT YOU WILL OBTAIN THE PRIOR EXPRESS WRITTEN CONSENT OF EACH PERSON THAT YOU INVITE AND/OR ADD TO RECEIVE SUCH INVITATIONS AND TO RECEIVE EMAIL, TEXT, SMS AUDIO AND OTHER MESSAGES FROM PIKMYKID ON BEHALF OF THE ORGANIZATION OR, FROM YOU</li>
                      </ul>

                      <h2>Consent to Receive Messages from Pikmykid</h2>
                      <p>By giving your mobile phone number to the Services (or allowing your Organization to provide it to the Services), you give us permission to use it to call or send you SMS audio and text messages in connection with the Services, including through the use of automatic telephone dialing systems or other automated communication technology. Pikmykid will not charge you for these calls or texts, but your wireless carrier may charge you standard message charges or other charges.</p>
                      <p>To stop receiving SMS audio and text messages from the Services, you can remove your mobile phone number from your account or reply “STOP” at any time.</p>

                      <h3>SMS Terms and Conditions</h3>
                      <p>By subscribing to text marketing notifications, you agree to receive recurring messages at the phone number provided. Message and data rates may apply. Message frequency varies.</p>
                      <p>Please help contact 813649-8028 or support@pikmykid.com.</p>

                      <h2>Content</h2>
                      <p>In the course of using the Services, you and other users may provide information which may be used by Pikmykid in connection with the Services and which may be visible to certain other users. All materials, information, photos, videos, or other content (“Content”) that a user posts, transmits, or otherwise makes available on the Services is such user’s “User Submission.”</p>
                      <p>Pikmykid does not claim ownership of your User Submissions. However, when you post or publish Content on or in the Services, you represent that you own and/or have a royalty-free, perpetual, irrevocable, worldwide, non-exclusive right (including any moral rights) and license to use, license, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, derive revenue or other remuneration from, and communicate to the public, perform and display your User Submission (in whole or in part) worldwide and/or to incorporate it in other works in any form, media or technology now known or later developed, for the full term of any worldwide intellectual property right that may exist in your User Submission.</p>
                      <p>Subject to any applicable account settings that you select, you grant Pikmykid a fully paid, royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and fully sublicensable right (including any moral rights) and license to use, license, distribute, reproduce, modify, adapt, publicly perform, and publicly display, your User Submission (in whole or in part) for the purposes of operating and providing the Services to you and to our other users. Please remember that other users may search for, see, use, modify and reproduce any of your User Submissions that you submit to any “public” area of the Services. You warrant that the holder of any worldwide intellectual property right, including moral rights, in your User Submission, has completely and effectively waived all such rights and validly and irrevocably granted to you the right to grant the license stated above. You agree that you, not Pikmykid, are responsible for all of your User Submissions that you make available on or in the Services.</p>

                      <h2>Interactions with Other Users</h2>
                      <p>Unless you have obtained prior written consent from Pikmykid, you may not use the Services to transmit any content or messages, including without limitation email, text or audio SMS messages, for any advertising purpose or to solicit others to purchase, lease, rent or invest in any property, goods or services.</p>
                      <p>You are solely responsible for your interactions with other users of the Services and any other parties with whom you interact through the Services; provided, however, that Pikmykid reserves the right, but has no obligation, to intercede in disputes between users. You agree that Pikmykid will not be responsible for any liability incurred as the result of such interactions.</p>
                      <p>The Services contain Content provided by other users. Pikmykid is not responsible for and does not control User Submissions. Pikmykid has no obligation to review or monitor, and does not approve, endorse or make any representations or warranties with respect to User Submissions. You use all User Submissions and interact with other users at your own risk.</p>
                      <p>Pikmykid cannot guarantee the identity of any other users with whom you may interact in the course of using the Services. You should make whatever investigation you believe is necessary or appropriate before proceeding with any online or offline transaction with any other user. You agree that Pikmykid will not be responsible or liable for any loss or damage of any sort incurred as the result of any interactions you may have with other users, whether online or offline.</p>
                      <p> finished accessing your account.
                      </p>
                      <p>
                        YOU ACKNOWLEDGE AND AGREE THAT PIKMYKID, ITS PARENTS, SUBSIDIARIES AND AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, RESELLERS AND LICENSORS (COLLECTIVELY, THE “PIKMYKID PARTIES”) ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD PIKMYKID PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, AND THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU. YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SERVICES. YOU UNDERSTAND THAT PIKMYKID DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF THE SERVICES.
                      </p>

                      <h1>User Conduct</h1>
                      <p>You warrant, represent, and agree that you will not contribute any Content or otherwise use (or encourage any third party to use) the Services in a manner that:</p>
                      <ul>
                        <li>infringes or violates the intellectual property rights or proprietary rights, rights of publicity or privacy, or other rights of any third party;</li>
                        <li>violates any law, statute, ordinance or regulation;</li>
                        <li>is harmful, fraudulent, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, or otherwise objectionable as determined by Pikmykid in its sole discretion;</li>
                        <li>jeopardizes the security of your account in any way, such as allowing someone else access to your account or password;</li>
                        <li>attempts, in any manner, to obtain the password, account, or other security information from any other user;</li>
                        <li>violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
                        <li>runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);</li>
                        <li>constitutes advertising or solicitation of others to purchase, rent or invest in property, goods or services, or otherwise involves commercial activities and/or sales without Pikmykid’s prior written consent, such as contests, sweepstakes, barter, or pyramid schemes;</li>
                        <li>impersonates any other person or entity, including any employee or representative of Pikmykid;</li>
                        <li>interferes with or attempts to interfere with the proper functioning of the Services or uses the Services in a way that is not expressly permitted by this Agreement; or</li>
                        <li>otherwise violates this Agreement.</li>
                      </ul>

                      <h3>Indemnity</h3>
                      <p>You agree to defend, indemnify, and hold harmless PikMyKid and its officers, directors, employees, and agents from and against any and all claims, damages, obligations losses, demands, liabilities, costs and expenses (including but not limited to attorney’s fees) resulting from, related to, or arising out of (i) your violation of these Terms and (ii) your use of the Services.</p>

                      <h3>Warranty Disclaimer</h3>
                      <p>TTHE SERVICES AND SITE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED. PIKMYKID AND ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS SPECIFICALLY (BUT WITHOUT LIMITATION) DISCLAIM (i) ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT; (ii) ANY WARRANTIES ARISING OUT OF COURSE-OF-DEALING, USAGE, OR TRADE; (iii) ANY WARRANTIES THAT THE INFORMATION OR RESULTS PROVIDED IN, OR THAT MAY BE OBTAINED FROM USE OF THE SERVICES OR SITE WILL MEET YOUR REQUIREMENTS OR BE ACCURATE, RELIABLE, COMPLETE, OR UP-TO-DATE; AND (iv) ANY WARRANTIES THAT THE SERVICES OR SITE WILL BE UNINTERRUPTED OR ERROR-FREE. YOUR USE OF OR ACCESS TO THE SERVICES OR SITE IS DONE AT YOUR OWN DISCRETION AND RISK.

                        PIKMYKID TAKES REASONABLE MEASURES TO ENSURE THAT PERSONALLY IDENTIFIABLE INFORMATION OF PARENTS AND STUDENTS IS NOT DISCLOSED, EXCEPT TO SCHOOLS AND EDUCATIONAL PROFESSIONALS, SO THAT THEY MAY USE THE SERVICES. PIKMYKID CANNOT AND DOES NOT, HOWEVER, GUARANTEE THAT THE PERSONAL INFORMATION ENTERED BY PARENTS WILL NOT BE MISAPPROPRIATED, INTERCEPTED, DELETED, DESTROYED OR USED BY OTHERS. PARENTS AGREE NOT TO HOLD PIKMYKID/LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT INCURRED AS A RESULT OF ANY SUCH MISAPPROPRIATION, INTERCEPTION, DELETION, DESTRUCTION OR USE OF INFORMATION PROVIDED THROUGH PIKMYKID.
                        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES IN SOME CIRCUMSTANCES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
                      <h3>Limitation of Liability</h3>
                      <p>
                        <p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL PIKMYKID OR ITS EMPLOYEES, OFFICERS, DIRECTORS OR AGENTS BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR ANY INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, EVEN IF WE HAVE BEEN ADVISED OF, KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF DIRECT DAMAGES DO NOT SATISFY A REMEDY. OUR AGGREGATE TOTAL CUMULATIVE LIABILITY TO YOU OR ANYONE ELSE FOR ANY LOSS OR DAMAGES (INCLUDING, WITHOUT LIMITATION, DIRECT DAMAGES) RESULTING FOR CLAIMS, DEMANDS OR ACTIONS ARISING OUT OF OR RELATING TO THIS AGREEMENT WILL NOT EXCEED THE GREATER OF (I) $100 OR (II) THE AMOUNTS ACTUALLY PAID BY YOU TO PIKMYKID, IN CONNECTION WITH YOUR USE OF THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, PRORATED FOR ANY PARTIAL PERIOD YOU USE THE SERVICES AND/OR FOR ACTUAL SERVICES USED BY THE PLAINTIFF OR DEFENDANT IN THE CLAIM, AS APPROPRIATE, AND NOT THEIR PARENT, AFFILIATES, OR SUBSIDIARIES. THIS LIMITATION OF LIABILITY REFLECTS AN ALLOCATION OF RISK BETWEEN YOU AND PIKMYKID</p>
                        <p>SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY IN SOME CIRCUMSTANCES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
                      </p>
                      <strong><p>Modifications</p></strong>
               
                      <p>We may modify these Terms from time to time and such modifications shall immediately take effect upon posting to the Site. If you do not agree with any changes to these Terms, you may terminate your account and stop using the Services. Your continued use of the Site or Services indicates that you have read, understood and agreed to the current version of these Terms.</p>
                      <p>PikMyKid reserves the right in its sole discretion to review, improve, modify, shut down, or discontinue, temporarily or permanently, the Services or Site.</p>

              <strong><p>General</p></strong>
                      <p>The rights, obligations and duties contained herein shall survive the termination or modification of these Terms and shall survive even after you no longer continue to use PIKMYKID.</p>
                      <p>PikMyKid may assign its rights and obligations pursuant to these Terms without prior notice to or consent from you. You, however, may not assign your rights or obligations pursuant to these Terms.</p>
                      <p>These Terms are governed by the laws of the State of Florida without respect to its conflict of laws principals. All disputes or actions related to or arising out of these Terms or your use of the Services shall be brought exclusively in the courts of Hillsborough County in the State of Florida and you further agree and expressly consent to the exercise of personal jurisdiction in the courts of Hillsborough County in the State of Florida.</p>
                      <p>If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect.</p>
                      <p>No waiver of any of these Terms shall be deemed a further or continuing waiver of such term or condition or any other term or condition.</p>
                    
                      <strong><p>
                      YOUR PRIVACY</p></strong>
                      <p>PikMyKid understands how important privacy is to Parents and their students, the Schools and Educational Professionals who use our Services, and our Site visitors. We provide a platform to assist you in the school dismissal process. As we describe below, Schools and Parents determine the data that gets shared and used by PikMyKid to provide our Services. This Privacy Policy applies to our Site and our Services and describes the actions we take related to your data.
                        Capitalized terms that are not defined in this Privacy Policy have the meaning given them in our Terms of Use.</p>
                      <strong><p>
                        Information we collect </p></strong>
                      <p>Information Schools give us –When Schools register for our Services, they provide certain information about the School, Parents, and students, such as Parent’s name, email address and phone number, student ID, classroom and other information maintained in the School’s information systems such as bus route number, teacher name and information about student absences. We also receive information from Schools and Parents when they send us a message, for example by email or by submitting a help request through the Site. The School may also provide us information about Educational Professionals such as name, email address and cell phone number to take advantage of some of our Services.
                      </p>

                      <strong><p>Information you give us.</p></strong>
                      <p>
                        When you register for our Services you provide certain information such as School name, student’s name, point of contact name, physical address, email address and phone number. In addition, to use the Services you are required to provide your pick-up method and student’s name. If you should discover incorrect information while using our Services, please contact support@pikmykid.com and we will assist in correcting the information.</p>
                      <p>Information we get when you use the Services. Like most websites and online services, certain information is automatically collected when you use our Services, visit our Site, read our emails, or otherwise communicate with us. For example, information about your device and its software, such as your IP address, browser type, Internet service provider, platform type, device type, operating system, date and time stamp, a unique ID that allows us to identify your browser, mobile device or your account, and other similar information. Information about the way you use our Service is also collected, for example, the site from which you came and the site to which you are going when you leave our website, the pages you visit, the links you click, how frequently you access the Site and use our Services, whether you open emails or click the links contained in emails, whether you access the Services or Site from multiple devices, and other actions you take on the Service or Site. When you access our Site or use our Services from a mobile device, we may collect unique identification numbers associated with your device or our mobile application, mobile carrier, device type, model and manufacturer, mobile device operating system brand and model. Your location may also be collected. We may also collect analytics data, or use third-party analytics tools, to help us measure traffic and usage information for our Services. Although we do our best to honor the privacy preferences of our visitors, we are not able to respond to Do Not Track signals from your browser at this time.
                        We and your School may also place advertisements on the Site. We collect information related to these advertisements such as who clicks on them, the School they are affiliated with and the time and date the advertisement was clicked.
                        Student Data. Through the course of providing Services, we may have access to personally identifiable information about students (“Student Data”) that is provided by you. We have access to Student Data only as requested by the School and Parents and only for the purposes of performing Services on the School’s behalf. The type of Student Data we collect will depend on how the School uses the Services.
                        We receive Student Data only from the School and Parents. We never interact with the student directly. Student Data is confidential. We do not use Student Data for any purpose other than to provide the Services on the School’s behalf, in accordance with contractual agreements with the School. Our collection, use, and disclosure of Student Data is governed by our Terms of Use, our agreement(s) with the School, the provision of the Family Educational Rights and Privacy Act (FERPA), the Children’s Online Privacy Protection Act (COPPA), and all applicable laws which relate to the collection of Student Data. Please contact your School if you have questions about the information they provide us and their use of technology service providers like PikMyKid.
                      </p>
                      <strong><p>How We Use Information</p></strong>
                      <p>
                        We use the information we collect to operate, maintain, and improve our Site, Services and their functionality, and to communicate with our Schools and Parents. To the extent we share your information, we will only share it with those who adhere to standards that are consistent with our Privacy Policy and practices. We may disclose your information in the following ways:
                        Any information provided by Parents may be shared with Schools and any information provided by Schools may be shared with Parents to the extent authorized by the School.
                        Except to the extent it contains or comprises Student Data, whether alone or when combined, we may share non-identifying and aggregated information with third parties, including advertisers and sponsors.  We will never target advertisements to students or sell students’ personally identifiable information to third parties for any purpose.
                        In response to subpoenas, court orders, or legal process, from law enforcement agencies or state and federal regulators, or as otherwise required by law;
                        In connection with an actual or proposed corporate merger, acquisition, asset purchase, or other transaction or proceeding involving all or part of the business or assets to which the information pertains.
                        To other companies to: comply with various reporting obligations; for business or marketing purposes; or to assist in understanding your interests, habits, and usage patterns for certain programs, content, services, advertisements, promotions, and functionality available through the Site or as part of the Services.
                        From time to time, we may use your information to contact you via e-mail to determine whether you are happy with our services and send you customer satisfaction surveys.  We may also contact you by e-mail to advise you of offers, promotions or other information from our sponsor companies or advertisers. You retain the ability to “opt out” of such e-mail communications by following the opt-out procedures in the email.  You will not be able to opt out, however, of viewing sponsor information that appears on the Site.
                        We disclose your information to companies we use as contractors or agents to perform services for us, such as administering our websites, systems and software, hosting maintenance, and other such services. These service providers may have access to or process your information to the extent it is necessary for them to perform services for us.
                        To protect rights, property, or safety.
                        In connection with an actual or proposed corporate merger, acquisition, asset purchase, or other transaction or proceeding involving all or part of the business or assets to which the information pertains.
                        We rely on Schools to provide us direction about how we can collect, use and share your information. You should contact your School if you have questions about their direction to us or the School’s use of technology service providers like PikMyKid.
                      </p>
                      <strong><p>How we store and secure your information</p></strong>
                      <p>
                        We maintain reasonable administrative, technological and physical safeguards designed to improve the integrity, privacy, confidentiality, and security of your information. Like other online services, we cannot guarantee the security of any information you transmit to us or store on the Site and you do so at your own risk. We also cannot guarantee that such information may not be accessed, disclosed, altered, or destroyed by unauthorized persons. If we learn of a security systems breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. We may post a notice through the Site if a security breach occurs. Once your information is removed from the Site, copies of your information, other than Student Data, may remain stored in backup systems or cached and archived pages. We are not responsible for the practices employed by websites, applications or services linked to or from our Site.</p>

                      <strong><p>Children’s privacy</p></strong>


                      <p>In addition to the guidelines outlined in our Terms, we do not knowingly collect any information from children under the age of 13 and we rely on the School to provide notice and obtain parental consent and any other required authorizations for all student information we collect. Please contact us at support@pikmykid.com if you believe we have inadvertently collected personal information of a child under 13 without proper consent so that we may delete it as soon as possible. We will never target advertisements to students or sell students’ personally identifiable information to third parties for any purpose.</p>
                      <strong><p>Changes to Our Privacy Policy</p></strong>

                      <p>We may modify or update this Privacy Policy from time to time. You should review this page periodically. If we make significant changes that affect your rights, we will take reasonable efforts to notify you of those changes via the email address provided by you or a prominent notification when you access the Site or use the Services. Your continued use of the Site or Services indicates that you have read, understood and agreed to the current version of our Privacy Policy. If you do not agree with our Privacy Policy, you can always opt-out by deleting your account and ceasing to use our Services. </p>
                      <p>Links to Third Party Sites or Apps</p>

                      <p>The Site may contain links to websites & apps operated and maintained by third parties, over which we have no control. Privacy policies on linked sites may be different from our Privacy Policy. You access such linked sites at your own risk. You should always read the privacy policy of a linked site before disclosing any information to such site.</p>
                      <strong><p>How to contact us</p></strong>
                      <p>If you have questions about this statement or need additional information regarding our privacy practices, please contact success@pikmykid.com.</p>
                      <p>  All Rights Reserved </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="terms-bottom">
                  <div className="accept-terms-label">
                    <div className="box-checkbox mb-20">
                      <div
                        className="checkbox-wrap"
                        onClick={() => {
                          this.setState({ termsAccepted: !termsAccepted });
                        }}
                      >
                        {termsAccepted ? (
                          <i className="fa-icon fa-solid fa-square-check"></i>
                        ) : (
                          <i className="fa-icon fa-regular fa-square"></i>
                        )}

                        <span className="Remember-me">
                          <strong>I agree with the Terms and Conditions</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="button-sect accept-terms-btns">
                    <button
                      type="button"
                      className="btn btn-default"
                      disabled={termsAccepted === false}
                      onClick={() => {
                        if (termsAccepted) {
                          const { doSaveTermsAccepted } = this.props;
                          doSaveTermsAccepted(true);
                          this.props.navigate("/childManagement");
                        }
                      }}
                    >
                      Accept
                    </button>
                    <button
                      type="button"
                      className="btn btn-default red-btn"
                      onClick={() => {
                        this.props.doLogoutUser();
                        this.props.navigate("/");
                      }}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    doLogoutUser: () => dispatch(logoutUser()),
    doSaveTermsAccepted: (data) => dispatch(saveTermsAccepted(data)),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsConditions))
);
