import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSchool } from "@fortawesome/free-solid-svg-icons";
import { CustomListGridButton } from "../CommonComponents";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import _ from "lodash";
import {
  showModal,
  hideModal,
  setHelpList,
} from "../../common/actions/userAccount";
import { getCalendarStyle } from "../../helper/utility";
import {
  getSchoolCalendar,
  getSchoolCalendarEventType,
  getSchoolCalendarClear,
} from "../../common/actions/school";
import { setCalendarViewClear } from "../../common/actions/students";
import { createLoadingSelector } from "../../common/reducers/selectors";
import RingLoader from "react-spinners/ClipLoader";
import { withTranslation } from "react-i18next";
import { geolocated } from "react-geolocated";
import Calendar from "react-calendar";
import moment from "moment";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const loadingSelector = createLoadingSelector(["get_school_pickup_time"]);

class SchoolCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGrid: true,
      currentSelectedMonth: new Date(),
      selectedDate: new Date(),
      isExpand: false,
      collpasData: null,
      markdCalendar: {},
      isRefreshing: false,
      currentMonthRecords: [],
      schoolCalendarRecords: [],
      saveMonthPositions: {},
    };
  }

  componentDidMount() {
    this.loadCalendar();
  }

  componentWillUnmount() {
    this.props.dosetCalendarViewClear();
  }

  openEventDetails = (schoolCalendarRecord) => {
    this.props.doShowModal({
      modalScreenName: "eventDetails",
      params: { Event: schoolCalendarRecord },
      modalClassName: "eventDetailsModal sidebar-modal right",
    });
  };

  setMonthPositions = () => {
    const { school } = this.props;
    let firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);
    let lastDayOfSchool = new Date(school.Dates.LastDayOfSchool);
    let newsaveMonthPositions = {};
    for (
      var d = firstDayOfSchool;
      d <= lastDayOfSchool;
      d.setMonth(d.getMonth() + 1)
    ) {
      newsaveMonthPositions[moment(d).format("MM-YYYY")] = 0;
    }
    this.setState({ saveMonthPositions: newsaveMonthPositions });
  };

  loadCalendar = () => {
    const { doSchoolCalendarEventsType, doGetSchoolCalendar } = this.props;
    const { school } = this.props;
    doSchoolCalendarEventsType({ schoolId: school.schoolId });
    doGetSchoolCalendar({ schoolId: school.schoolId });
  };
  componentDidUpdate(prevProps, prevState) {
    const { schoolCalendar, doGetSchoolCalendarClear, calendarView } =
      this.props;
    let currentMonth = "";
    const { saveMonthPositions } = this.state;

    if (schoolCalendar !== null) {
      let newMarkdCalendar = {};
      let newsaveMonthPositions = { ...saveMonthPositions };
      for (var i = 0; i < schoolCalendar.length; i++) {
        const record = { ...schoolCalendar[i] };
        var day = moment(record.Date).format("YYYY-MM-DD");
        let dayRecord = getCalendarStyle(record);
        if (newMarkdCalendar[day]) {
          dayRecord = newMarkdCalendar[day];
          dayRecord.foreGroundColor = "#2d3d56";
          dayRecord.selectedColor = "#2d3d56";
        }
        dayRecord.dots.push({
          key: day + "_" + dayRecord.dots.length,
          color: record.EventColor,
          selectedDotColor: record.EventColor,
        });
        newMarkdCalendar[day] = dayRecord;

        if (moment(record.Date).format("MM-YYYY") !== currentMonth) {
          currentMonth = moment(record.Date).format("MM-YYYY");
          newsaveMonthPositions[currentMonth] = i;
        }
      }

      this.setState(
        {
          markdCalendar: newMarkdCalendar,
          schoolCalendarRecords: schoolCalendar,
          saveMonthPositions: newsaveMonthPositions,
          isRefreshing: false,
        },
        () => {
          this.filterCurrentMonth();
          doGetSchoolCalendarClear();
        }
      );
    }

    if (
      !_.isEqual(
        prevProps.schoolCalendarEventResponse,
        this.props.schoolCalendarEventResponse
      )
    ) {
      this.legendDisplay();
    }
    if (!_.isEqual(prevProps.calendarView, calendarView)) {
      setTimeout(this.setCalendarFocus, 100);
    }
  }

  setCalendarFocus = () => {
    const { calendarView } = this.props;
    const { saveMonthPositions } = this.state;
    let newY = 0;
    if (!calendarView) {
      const deviceCurrentMonth = moment(new Date()).format("MM-YYYY");
      const monthPos = saveMonthPositions[deviceCurrentMonth];

      if (monthPos && monthPos > 0) newY = 70 + 100 * monthPos;
    }
  };

  legendDisplay = () => {
    let schoolCalendarEventResponse = [
      ...this.props.schoolCalendarEventResponse,
    ];
    if (schoolCalendarEventResponse) {
      for (let i = 0; i < schoolCalendarEventResponse.length; i += 1) {
        schoolCalendarEventResponse = schoolCalendarEventResponse.splice(i, 3);
        this.setState({ collpasData: schoolCalendarEventResponse });
      }
    }
  };

  changeMonth = (value) => {
    this.calendar.addMonth(value);
    const { currentSelectedMonth } = this.state;
    const currMonth = moment(currentSelectedMonth).add(value, "M");
    this.setState(
      {
        currentSelectedMonth: currMonth,
        selectedDate: currMonth.toDate(),
      },
      this.filterCurrentMonth
    );
  };
  filterCurrentMonth = () => {
    const { currentSelectedMonth } = this.state;
    const currentMonth = moment(currentSelectedMonth).format("MM YYYY");
    let schoolCalendarRecords = [...this.state.schoolCalendarRecords];
    schoolCalendarRecords = schoolCalendarRecords.filter((item) => {
      return currentMonth === moment(item.Date).format("MM YYYY");
    });
    this.setState({
      currentMonthRecords: schoolCalendarRecords,
    });
  };

  renderEventType = (eventTypes) => {
    let eventView = [];

    if (eventTypes) {
      for (let i = 0; i < eventTypes.length; i += 1) {
        eventView.push(
          <li key={"event-" + i}>
            <i
              className="fas fa-circle icon-style"
              style={{ color: eventTypes[i].HexCode }}
            ></i>
            <span className="list-text">{eventTypes[i].Name}</span>
          </li>
        );
      }
    }
    return eventView;
  };
  renderHeader = () => {
    return <p className="currentmonth">{"August"}</p>;
  };
  onDayPress = (day) => {
    const { markdCalendar } = this.state;
    const selectedDay = moment(day).format("YYYY-MM-DD");

    if (markdCalendar[selectedDay]) {
      const record = markdCalendar[selectedDay].record;
      let eventName = record.Name;
      this.showDayDetail((eventName = record.Name));
    }
  };

  showDayDetail = (eventName) => {
    toast.info(eventName, {
      autoClose: 2000,
      transition: Slide,
      hideProgressBar: true,
    });
  };

  buildCalendarRecordView = (schoolCalendarRecord, index) => {
    return (
      <div className="schedule-list" key={"schedule-item" + index}>
        <div
          className="schoolname-header dismissal-schedule-header"
          onClick={() => {
            this.openEventDetails(schoolCalendarRecord);
          }}
        >
          <div className="icon-title">
            <i
              className="fas fa-calendar-day schedular-icon"
              style={{ backgroundColor: schoolCalendarRecord.EventColor }}
            ></i>

            <div className="title-info">
              <h3 className="school-title gray-title">
                {moment(schoolCalendarRecord.Date).format("ddd DD MMM YYYY")}
              </h3>
              <div className="blue-text">{schoolCalendarRecord.Name}</div>
              <div className="helptext description">
                {schoolCalendarRecord.Description}
              </div>
            </div>
          </div>
          <i className="fas fa-chevron-right"></i>
        </div>
      </div>
    );
  };
  renderSchoolCalendar = () => {
    const { schoolCalendarRecords, currentMonthRecords } = this.state;
    const { calendarView } = this.props;
    let schoolCalendarView = [];
    let records = calendarView ? currentMonthRecords : schoolCalendarRecords;
    if (records) {
      records.forEach((element, index) => {
        schoolCalendarView.push(this.buildCalendarRecordView(element, index));
      });
    }
    return schoolCalendarView;
  };

  onRefresh = () => {
    this.setState({ isRefreshing: true }, () => {
      this.loadCalendar();
    });
  };

  render() {
    const { isExpand, markdCalendar, collpasData } = this.state;
    const { isFetching, schoolCalendarEventResponse } = this.props;
    const { school } = this.props;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">{this.props.t("school_Calendar")}</h4>
            </div>

            <CustomListGridButton isGrid={true} />
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>

          <div className="modal-body  modal-popup">
            <div className="schoolDetails">
              <div className="schoolname-header">
                <div>
                  <div
                    onClick={() => {
                      this.openMapWithoutSource();
                    }}
                  >
                    <h3 className="school-title">{school.title}</h3>
                  </div>
                </div>
                <FontAwesomeIcon icon={faSchool} className="school-icon" />
              </div>
              {this.props.calendarView ? (
                <div className="schoolcalendar-warpper">
                  <div className="calendar-col card-style2 ">
                    <Calendar
                      value={this.state.selectedDate}
                      className={["customCalendar"]}
                      calendarType={"US"}
                      prev2Label=""
                      next2Label=""
                      showFixedNumberOfWeeks={false}
                      showNeighboringMonth={false}
                      onClickDay={this.onDayPress}
                      tileContent={({ activeStartDate, date, view }) => {
                        if (markdCalendar) {
                          const item =
                            markdCalendar[moment(date).format("YYYY-MM-DD")];
                          if (item) {
                            return [
                              <div
                                className="updated-event-tile"
                                key={item.className}
                                style={{
                                  background: item.foreGroundColor,
                                  borderColor: item.foreGroundColor,
                                }}
                              >
                                {moment(date).format("D")}
                              </div>,
                            ];
                          }
                        }
                      }}
                      tileClassName={({ date, view }) => {
                        if (markdCalendar) {
                          const item =
                            markdCalendar[moment(date).format("YYYY-MM-DD")];
                          if (item) {
                            return ["event-date", item.className];
                          }
                        }
                      }}
                    ></Calendar>
                    <div className="schoolcalendar-legends-sect">
                      <h5
                        className="legend-title"
                        onClick={() => {
                          this.setState({ isExpand: !isExpand });
                        }}
                      >
                        <span className="text">
                          {this.props.t("Colour_Legends")}
                        </span>
                        {!isExpand ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </h5>
                      <div className="legends-holder">
                        <ul className="unstyle-list legend-list schoolcalendar-legends">
                          {!isExpand
                            ? this.renderEventType(collpasData)
                            : this.renderEventType(schoolCalendarEventResponse)}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="schoolcalendar-sect">
                    <h3 className="title-4">
                      {this.props.t("detail_Currnent_month")}
                    </h3>
                    {this.renderSchoolCalendar()}
                  </div>
                </div>
              ) : (
                <div className="schoolcalendar-warpper">
                  {this.renderSchoolCalendar()}
                </div>
              )}
            </div>
          </div>
          <RingLoader color="#2d3d56" loading={isFetching} size={60} />
        </div>
      </div>
    );
  }

  closeModal = () => {
    this.props.doHideModal();
  };
}

function mapStateToProps(state) {
  return {
    error: state.student.error,
    setHelpListData: state.userAccount.setHelpListData,
    isFetching: loadingSelector(state),
    schoolInfo: state.student.schoolInfo,
    calendarView: state.student.calendarView,
    schoolCalendarEventResponse: state.school.schoolCalendarEvent,
    schoolCalendar: state.school.schoolCalendar,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    dosetHelpList: (data) => dispatch(setHelpList(data)),
    dosetCalendarViewClear: () => dispatch(setCalendarViewClear()),
    doSchoolCalendarEventsType: (data) =>
      dispatch(getSchoolCalendarEventType(data)),
    doGetSchoolCalendar: (data) => dispatch(getSchoolCalendar(data)),
    doGetSchoolCalendarClear: (data) => dispatch(getSchoolCalendarClear(data)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SchoolCalendar)
);
