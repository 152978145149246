import { getEnvironment, getAxios } from "./config";
import { validateResponse } from "../../helper/utility";

export const schoolCheckin = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}checkin/GetSchoolIDsWhichParentCanCheckIn`;
  const FullUrl = PostURL + param;
  // console.log("schoolCheckin " + FullUrl);
  const response = await getAxios().post(FullUrl);
  return validateResponse(response);
};

export const schoolGeoFenceInformation = async ({ schoolId }) => {
  const param = "";
  const PostURL = `${getEnvironment()}checkin/GetSchoolGeoFenceInformation?SchoolID=${schoolId}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const CheckInByParent = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}checkin/CheckInByParent`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    SchoolID: params.schoolID,
    CheckParentIn: params.checkInParent,
    StudentIDs: params.studentIDs,
    QuestionsAndAnswers: params.questionsAndAnswers,
  });

  return validateResponse(response);
};

export const checkInGetQuestions = async ({ schoolID, studentID }) => {
  const param = "";
  const PostURL = `${getEnvironment()}checkin/GetQuestions?SchoolID=${schoolID}&StudentID=${studentID}`;
  const FullUrl = PostURL + param;

  // console.log("checkInGetQuestions " + FullUrl);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const CheckInParentQuestions = async ({ schoolID }) => {
  const param = "";
  const PostURL = `${getEnvironment()}checkin/GetParentQuestions`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    SchoolID: schoolID,
  });

  return validateResponse(response);
};

export const DoesSchoolAllowCheckIn = async ({ schoolIDs }) => {
  const param = "";
  const PostURL = `${getEnvironment()}checkin/DoesSchoolAllowCheckIn`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    SchoolIDs: schoolIDs,
  });

  return validateResponse(response);
};

export const schoolStudentCanCheckIn = async ({ schoolID, studentIDs }) => {
  const param = "";
  const PostURL = `${getEnvironment()}checkin/CanCheckIn`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    SchoolID: schoolID,
    StudentIDs: studentIDs,
  });

  return validateResponse(response);
};

export const whoCanCheckInToday = async ({ schoolID, studentIDs }) => {
  const param = "";
  const PostURL = `${getEnvironment()}checkin/WhoCanCheckInToday`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    SchoolID: schoolID,
    StudentIDs: studentIDs,
  });

  return validateResponse(response);
};
