import React from "react";
import { withRouter } from "../CommonComponents/withRouter";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  isAnnounceAllowed,
  isKidAnnounceable,
  isAnnounceTimeStarted,
  getChildStatus,
  getDismissalTime,
  getCurrentThemeColor
} from "../../helper/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faCarAlt } from "@fortawesome/free-solid-svg-icons";
import { faBusAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import GLOBAL_CONST from "../../helper/AppConstants";

class ChildCard extends React.Component {
  constructor(props) {
    super(props);
    
    const {
      isPickingUpToday,
      DismissalTime,
      PickupModeAllowsAnnounce,
      StatusID,
      isLastDayOfSchoolPast,
    } = this.props;
    this.state = {
      isPickingUpToday: isPickingUpToday,
      announceTimeDifference: this.getAnnounceTimeDifference(),
      enableAnnounce: isAnnounceAllowed(
        DismissalTime,
        PickupModeAllowsAnnounce,
        StatusID,
        isLastDayOfSchoolPast
      ),
      canKidAnnounce: isKidAnnounceable(
        DismissalTime,
        PickupModeAllowsAnnounce,
        isLastDayOfSchoolPast
      ),
    };
  }
  componentDidMount() {
    this.createTimer();
  }

  createTimer = () => {
    //console.log('createTimer');
    this.myInterval = setInterval(() => {
      const {
        DismissalTime,
        PickupModeAllowsAnnounce,
        StatusID,
        isLastDayOfSchoolPast,
        Firstname,
        doRefresh,
      } = this.props;
      const announceTimeDifference = this.getAnnounceTimeDifference();
      // console.log(
      //   'createTimer announceTimeDifference ' + announceTimeDifference,
      // );
      if (announceTimeDifference === this.state.announceTimeDifference) {
        return;
      }
      // console.log(
      //   'announceTimeDifference ' +
      //     this.state.announceTimeDifference +
      //     ' Firstname ' +
      //     Firstname,
      // );

      this.setState({
        announceTimeDifference: this.getAnnounceTimeDifference(),
        enableAnnounce: isAnnounceAllowed(
          DismissalTime,
          PickupModeAllowsAnnounce,
          StatusID,
          isLastDayOfSchoolPast
        ),
        canKidAnnounce: isKidAnnounceable(
          DismissalTime,
          PickupModeAllowsAnnounce,
          isLastDayOfSchoolPast
        ),
      });
      if (doRefresh) {
        doRefresh();
      }
    }, 50 * 1000);
  };

  componentWillUnmount() {
    if (this.myInterval) {
      clearInterval(this.myInterval);
      this.myInterval = null;
    }
    //NumOfKid = 0;
  }

  getAnnounceTimeDifference = () => {
    const {
      DismissalTime,
      PickupModeAllowsAnnounce,
      StatusID,
      isLastDayOfSchoolPast,
    } = this.props;
    if (
      StatusID === GLOBAL_CONST.PICKUP_STATUS.ANNOUNCED ||
      StatusID === GLOBAL_CONST.PICKUP_STATUS.DISMISSED ||
      PickupModeAllowsAnnounce === false ||
      isLastDayOfSchoolPast === true
    ) {
      return "";
    }
    //console.warn("DismissalTime ", DismissalTime)
    const dtime = getDismissalTime(DismissalTime);
    const AnnounceStartTime = new Date(`2023-01-01T${dtime}`);
    const todaysDate = new Date();
    todaysDate.setDate(1);
    todaysDate.setMonth(0);
    todaysDate.setFullYear(2023);
    const LocalDateTime = todaysDate; //console.warn("DismissalTime dtime ", dtime)
    var hours = 0;
    var start = moment(AnnounceStartTime); //announce start time
    var end = moment(LocalDateTime); // current  time
    var duration = moment.duration(start.diff(end));
    let mins = duration.asMinutes();
    mins = parseInt(mins, 10);
    const secs = duration.asSeconds();
    hours = duration.asHours();
    hours = parseInt(hours, 10);
    // console.log(` hours ${hours} mins ${mins} secs ${secs}`);
    if (mins < 60 && secs > 30) {
      if (mins === 0) {
        mins = 1;
      }
      return mins + ` ${this.props.t("Min_to_announce")}`;
    } else if (hours > 0) {
      return (
        hours +
        ` ${this.props.t("Hour")}${hours > 1 ? "s" : ""} ${this.props.t(
          "To_Announce"
        )}`
      );
    } else if (mins === 0) {
      return this.props.t("Less_Min_to_announce");
    }
    return "";
  };

  isPickingUpTapped = (obj) => {
    const { childDetials, selectedSwitchCallback } = this.props;
    this.setState({ isPickingUpToday: obj.target.checked });
    selectedSwitchCallback(childDetials, obj.target.checked);
  };

  render() {
    const {
      isPickingUpToday,
      enableAnnounce,
      announceTimeDifference,
      canKidAnnounce,
    } = this.state;

    const { DismissalTime, childDetials,selectedTheme } = this.props;
    return (
      <div
        className="citem-row citem-icon-row"
        key={"student" + childDetials.StudentID}
      >
        <span
          className={
            childDetials.IsDelegation === true ? "delegate-icon" : "base-icon"
          }
          style={{ backgroundColor: getCurrentThemeColor(selectedTheme).navyblue}}
        >
          {childDetials.PickupModeIcon === "Car" && (
            <FontAwesomeIcon icon={faCarAlt} />
          )}
          {childDetials.PickupModeIcon === "Bus" && (
            <FontAwesomeIcon icon={faBusAlt} />
          )}
          {childDetials.PickupModeIcon === "AfterSchool" && (
            <FontAwesomeIcon icon={faHome} />
          )}
          {childDetials.PickupModeIcon === "Walker" && (
            <i className="fas fa-walking"></i>
          )}
        </span>
        <div className="citem-wrapper">
          <h4 className="citem-title2">{`${childDetials.Firstname} ${childDetials.Lastname}`}</h4>

          <div className="space-between">
            <span className="sm-label gary-text">
              {childDetials.PickupModeName}
            </span>
            {!isAnnounceTimeStarted(DismissalTime) && (
              <span className="announce-time sm-label blue-text">
                {announceTimeDifference}
              </span>
            )}
          </div>
          {canKidAnnounce && (
            <div className="readyToAnnounce">
              <span
                className={
                  enableAnnounce ? "readytoannounceText" : "announcedText"
                }
              >
                {enableAnnounce && isPickingUpToday
                  ? this.props.t("Select_To_Announce")
                  : enableAnnounce && isPickingUpToday === false
                  ? this.props.t("Announce_To_Select")
                  : getChildStatus(childDetials)}
              </span>
              {enableAnnounce && (
                <div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isPickingUpToday}
                      value={isPickingUpToday}
                      onChange={this.isPickingUpTapped}
                      aria-label={this.props.t("Select_To_Announce")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ChildCard))
);
