import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

class PikMyKid extends React.Component {
  componentDidMount = () => {
    this.clearCacheData();
  };
  // Function to clear complete cache data
  clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  render() {
    return <App />;
  }
}

const reactRoot = ReactDOM.createRoot(document.getElementById("root"));
reactRoot.render(<PikMyKid />);
