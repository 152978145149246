const light = {
  colors: {
    blue: "#2d3d56", // latest blue color change
    navyblue: "#2d3d56",
    icon: "#2d3d56",
    textcolor: "#f0f4fa",
    buttonColor: "#2d3d56",
    headerblue: "#63c5ff",
    iconColors: "63c5ff",
    body: "#eff1fc",

    title2: "#5b6781",
    helpTxt: "#2d3d56",
    navTabs: "#fdfefe",
    navTabSep: "rgba(219, 214, 224, 0.99)",
    tabHolder: "rgba(219, 214, 224, 0.99)",
    darkBlack_white: "#FDFEFE",
    mediumBlack_BlueishGray: "#A2A2A2",
    buttonDefault: "#2d3d56",
    grayDark_lightGray: "#575F72",
    loader: "#2d3d56",

    buttonPrimary: "#2d3d56",
    cardbackground: "#f4f4f4",
    border: "1px solid #37383b",
    profile: "#f4f4f4",
    landingbackground: "#f4f4f4",
    buttonDefaultlanding: "#2d3d56",
    textcolorLandingPage: "#5b6781",
    mediumGray_DarkGray: "#5D6074",
    themebackground_graysubtle: "#F0F4FA",
    black_white: "#000",
  },
};

const dark = {
  colors: {
    blue: "#62C5FF", // latest blue color change
    navyblue: "#63c5ff",
    icon: "#63c5ff",
    textcolor: "#f0f4fa",
    buttonColor: "#63c5ff",
    headerblue: "#2d3d56",
    iconColors: "63c5ff",
    body: "#23252d",
    title2: "#F4F4F4",
    helpTxt: "#D7DCE7",
    navTabs: "#434652",
    navTabSep: "#23252d",
    tabHolder: "#434652",
    darkBlack_white: "#17181D",
    mediumBlack_BlueishGray: "#B2C0D3",
    buttonDefault: "#63c5ff",
    grayDark_lightGray: "#D7DCE7",
    loader: "#63c5ff",

    buttonPrimary: "#63c5ff",
    cardbackground: "#17181d",
    border: "2px solid #f4f4f4",
    profile: "#2d3d56",
    landingbackground: "#2d3d56",
    buttonDefaultlanding: "#63c5ff",
    textcolorLandingPage: "#f4f4f4",
    mediumGray_DarkGray: "#878AA1",
    themebackground_graysubtle: "#23252d",
    black_white: "#fff",
  },
};

export default { light, dark };
