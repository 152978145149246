import React from "react";
import { connect } from "react-redux";
import AdsView from "../Ads/AdsView";

class LoadAds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adsVisible: false,
    };
  }

  render() {
    const { adsVisible } = this.state;
    return (
      <AdsView
        showAds={(adsVisible) => {
          this.setState({ adsVisible });
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(LoadAds);
