import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "../../CommonComponents";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../../../node_modules//bootstrap/dist/css/bootstrap.min.css";
import { RegisterFooter, RegisterHeader } from "../../CommonComponents";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import "../../Styles/app.css";
import "../NewRegistration/Styles/style.css";
import "../NewRegistration/Styles/theme-responsive.css";
import {
  userForgotPassword,
  userForgotPasswordClear,
  setEnvironment,
  showModal,
  hideModal,
  setHelpList,
} from "../../../common/actions/userAccount";
import GLOBAL_CONST from "../../../helper/AppConstants";

import { createLoadingSelector } from "../../../common/reducers/selectors";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLanguageCode } from "../../../helper/utility";
const loadingSelector = createLoadingSelector(["forgot_password"]);

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailInvalid: "",
      env: "prod",

      formSubmitLoading: false,
    };
  }

  componentDidMount() {
    this.dosetHelpList();
  }
  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "AuthStack",
      screen: GLOBAL_CONST.SCREEN_NAMES.FORGOT_PASSWORD,
      title: this.props.t("Forgot_Password"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  submitForm = () => {
    let { email, env } = this.state;
    const { doSetEnvironment } = this.props;

    email = "" + email;
    const reg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Email validation
    if (reg.test(email.trim()) === false) {
      this.setState({ emailInvalid: this.props.t("regAcctScr-emailError") });
    } else {
      if (email.includes(".staging")) {
        env = "staging";
      } else if (email.includes(".develop")) {
        env = "develop";
      } else if (email.includes(".localhost")) {
        env = "localhost";
      }else {
        env = "prod";
      }
      this.setState({ env }, () => {
        if (env === "staging") {
          doSetEnvironment({
            env: "staging",
            callback: this.processForgotPassword,
          });
        } else if (env === "develop") {
          doSetEnvironment({
            env: "develop",
            callback: this.processForgotPassword,
          });
        }else if (env === "localhost") {
          doSetEnvironment({
            env: "localhost",
            callback: this.processForgotPassword,
          });
        } else {
          doSetEnvironment({
            env: "prod",
            callback: this.processForgotPassword,
          });
        }
      });
    }
  };

  processForgotPassword = () => {
    const { doUserForgotPassword } = this.props;
    let { email } = this.state;

    if (email !== null && email !== undefined) {
      email = email.replace(".staging", "");
      email = email.replace(".develop", "");
      email = email.replace(".localhost", "");
    }

    doUserForgotPassword({ email: email, locale: getLanguageCode() });
  };

  handleEmailChange = ({ target: { value: email } }) =>
    this.setState({ email, emailInvalid: "" });

  componentDidUpdate() {
    const {
      forgotPasswordResponse,
      error,
      doUserForgotPasswordClear,
      forgotPasswordSuccess,
    } = this.props;

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doUserForgotPasswordClear();
    } else if (forgotPasswordSuccess) {
      toast.success(
        forgotPasswordResponse.Message +
        ". " +
        this.props.t("forgot_password_message"),
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        }
      );
      this.props.navigate("/");
      doUserForgotPasswordClear();
    }
  }

  render() {
    const { email, emailInvalid } = this.state;
    const { isFetching } = this.props;

    return (
      <section className="forgotPassword registration">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  <div className="container-sect">
                    <form className="form-style1">
                      <div className="nav-buttons">
                        <h2 className="title-3 forgot-password-title">
                          {this.props.t("loginScreen-ForgotPassword")}
                        </h2>
                        <button
                          onClick={() => {
                            this.props.navigate("/");
                          }}
                          className="btn btn-action close-btn2"
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                      <div className="main">
                        <div className="title-sect mb-40">
                          <h2 className="title-3">
                            {this.props.t("TrubleInLogin_Text")}
                          </h2>
                          <p className="helptext">
                            {this.props.t("ForgotPassword_Text")}
                          </p>
                        </div>

                        <div className="form-group">
                          <input
                            type="email"
                            className={`form-control ${emailInvalid !== "" ? "input-error" : null
                              }`}
                            placeholder={this.props.t("regAcctScr-emailAddrs")}
                            value={email}
                            onChange={this.handleEmailChange}
                            name="email"
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                this.submitForm();
                              }
                            }}
                          />
                          <i className="fa-solid fa-user-circle-o userIcon"></i>
                          <span className="error"> {emailInvalid}</span>
                        </div>

                        <div className="form-group">
                          <button
                            type="button"
                            className="btn needhelp-label mb-20"
                            onClick={() => {
                              this.openHelpModal();
                            }}
                          >
                            {this.props.t("regNameScr-needHelp")}
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              this.submitForm();
                            }}
                          >
                            {isFetching ? (
                              <i className="fas fa-sync-alt fa-spin"></i>
                            ) : null}{" "}
                            &nbsp; {this.props.t("ForgotPassword_Button")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <ToastContainer />
                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    forgotPasswordSuccess: state.userAccount.forgotPasswordSuccess,
    forgotPasswordResponse: state.userAccount.forgotPasswordResponse,
    isFetching: loadingSelector(state),
    error: state.userAccount.error,
    setHelpListData: state.userAccount.setHelpListData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doUserForgotPassword: (data) => dispatch(userForgotPassword(data)),
    doUserForgotPasswordClear: () => dispatch(userForgotPasswordClear()),
    doSetEnvironment: (data) => dispatch(setEnvironment(data)),

    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
  };
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword))
);
