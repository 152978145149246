import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";

class MultikidsPickupSummaryView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDays: [],
    };
  }

  buildRecurringMainView = () => {
    const {
      selectedDate,
      selectedTillDate,
      selectRecurringOption,
      recurringDates,
    } = this.props;
    let typeText = selectRecurringOption;
    return (
      <div className="pickup-summary-sect">
        <div className="pkp-summary-header">
          <span className="bd-r-col">
            <small className="summary-th">
              {this.props.t("Repeat_Type_Label")}
            </small>
            <em className="summary-td">{typeText}</em>
          </span>
          <span className="bd-r-col">
            <small className="summary-th">
              {this.props.t("Date_Range_Label")}
            </small>
            <em className="summary-td">
              {`${moment(selectedDate).format("MMM-DD")} ${
                typeText === this.props.t("selectWhenScr-none")
                  ? ""
                  : moment(selectedTillDate).format("MMM-DD")
              }`}
            </em>
          </span>
        </div>

        <div className="selected-days">
          {recurringDates.length > 0 && (
            <div>
              <div className="selected-days-title">
                <span>{this.props.t("Selected_Days_Label")}</span>
              </div>
              <div className="selected-days-holder">
                {this.buildRecurringDatesView()}
              </div>
            </div>
          )}

          <div className="note-section">
            <h6 className="please-note-title">
              {this.props.t("Please_Notes_Label")}
            </h6>
            <ul className="note-list">
              <li>
                {this.props.t("Dates_Marked_Label")}{" "}
                {this.props.t("Orange_Label")} {this.props.t("Indecates_Text")}{" "}
                {this.props.t("Pickup_Already_Schedule_Text")}
              </li>
              <li>{this.props.t("Tap_Select_Text")}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  buildRecurringDatesView = () => {
    const {
      recurringDates,
      markedDates,
      currentPageIndex,
      pageIndexes,
      saveChangesManuallExcludedDates,
    } = this.props;
    const { selectedDays } = this.state;

    const manuallExcludedDates = { ...this.props.manuallExcludedDates };

    let days = [];
    const startIndex = 0;

    let endIndex = recurringDates.length;
    for (var i = startIndex; i < endIndex; i++) {
      const item = recurringDates[i];

      const rec = markedDates[moment(item).format("YYYY-MM-DD")];
      let doesDismissalExists = false;
      const doesExcluded = item in manuallExcludedDates;
      doesDismissalExists =
        rec && rec.record && rec.record.RecordSource !== "DefaultPickupEntry";
      let dateStyle = "select-days-btn";
      if (doesExcluded) {
        dateStyle = "select-days-btn excluded";
      } else if (doesDismissalExists) {
        dateStyle = "select-days-btn exists";
      }

      days.push(
        <div
          className="item"
          key={"TO-" + item}
          onClick={() => {
            if (item in manuallExcludedDates) delete manuallExcludedDates[item];
            else manuallExcludedDates[item] = item;

            saveChangesManuallExcludedDates(manuallExcludedDates);
          }}
        >
          <button type="button" className={dateStyle}>
            <label key={"Text-" + item}>{`${moment(item).format(
              "MMM-D"
            )}`}</label>
          </button>
        </div>
      );
    }
    return days;
  };

  render() {
    return this.buildRecurringMainView();
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MultikidsPickupSummaryView)
);
