import React from "react";
import Moment from "moment";
import { withTranslation } from "react-i18next";
import NokidsImage from "./../assets/dashboard/empty-state.svg";
import "@fortawesome/fontawesome-free/css/all.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { connect } from "react-redux";
import { MainHeader, MainFooter } from "../CommonComponents";
import RingLoader from "react-spinners/ClipLoader";
import "./styles/style.css";

import {
  showModal,
  hideModal,
  logoutUser,
  setHelpList,
} from "../../common/actions/userAccount";
import {
  getIsTokenValid,
  getIsTokenValidClear,
  getFutureEntries,
  getFutureEntriesClear,
} from "../../common/actions/userAccount";
import { getSchoolCheckIn } from "../../common/actions/checkin";
import GLOBAL_CONST from "../../helper/AppConstants";
import {
  isAnnounceAllowed,
  getPickupMode,
  getModifiedPickupMode,
  getCurrentThemeColor,
} from "../../helper/utility";
import moment from "moment";
import {
  getStudentsList,
  getSchoolDetails,
  schoolAndKidsFetchDone,
  studentsSchoolListClear,
  getOtherChildrenDetails,
  saveTodaysKidsAnnouncedList,
  delegationCancel,
  delegationCancelClear,
  removeMultipleDelegations,
  removeMultipleDelegationsClear,
  relatedDelegationsDetails,
  getAvailableDispatch,
  getAvailableDispatchClear,
  announceSubmit,
  announceSubmitClear,
} from "../../common/actions/students";

import { createLoadingSelector } from "../../common/reducers/selectors";
import Nokids from "./NoKids";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SchoolCard from "./SchoolCard";
import _ from "lodash";
import { getMyStyle } from "../../theme/ThemeStyle";
import { useNavigate, useLocation } from "react-router-dom";
import AdsView from "../Ads/AdsView";
const loadingSelector = createLoadingSelector([
  "is_announce",
  "get_students",
  "get_school_info",
  "get_token_valid",
  "get_available_dispatch"
]);

function withRouterHook(Component) {
  return function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component navigate={navigate} location={location} {...props} />;
  };
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SchoolStudentList: [],
      previousDelegations: [],
      futureDelegations: [],
      modalVisible: false,
      modalVisibleButton: false,
      kidsAnnouncedToday: [],
      loadDataDone: false,
      noKidsTitle: "",
      noKidsDetail: "",
      selectedKidsSwitches: {},
      studentId: 0,
      pickupModeId: 0,
      needRefresh: false,
      doRefresh: false,
      isUpcomingSelected: true,
      isPreviousSelected: false,
      adsVisible: false,
      lastLoadedStudentList: new Date(),
      username: "",
      password: "",
      openAnnounceCard: false,
      isActionPerformed: false,
      allAvailableDispatch: {},
    };
  }

  setThemeBody = () => {
    document.body.style.backgroundColor = getCurrentThemeColor(
      this.props.selectedTheme
    ).body;

    document.body.className = `theme-${this.props.selectedTheme}`;
  };

  componentDidMount() {
    const { modalLevelInfo } = this.props;
    this.setThemeBody();
    this.checkTokenValid();
    this.dosetHelpList();

    if (
      modalLevelInfo &&
      modalLevelInfo.length > 0 &&
      (modalLevelInfo[modalLevelInfo.length - 1].modalScreenName ===
        "AddContact" ||
        modalLevelInfo[modalLevelInfo.length - 1].modalScreenName ===
        "EditContact")
    ) {
      this.props.doHideModal();
    }
  }
  componentWillUnmount() {
    this.props.doGetFutureEntriesClear();
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Dashboard",
      screen: GLOBAL_CONST.SCREEN_NAMES.PICKUP,
      title: this.props.t("pickup_title"),
    });
  };

  doRefreshListing = () => {
    this.getSchool(this.getStudents);
    this.checkInForSchool();
    this.fetchOtherChildrenList();
    this.getFutureListing();
  };
  getDispatchList = () => {
    const { studentsList, schoolInfo, doGetAvailableDispatch } = this.props;
    const schools = schoolInfo;
    const students = studentsList.TodaysStudents;

    for (let i = 0; i < schools.length; i++) {
      let schoolstudents = [];
      for (let j = 0; j < students.length; j++) {
        if (
          schools[i].SchoolID === students[j].SchoolID &&
          students[j].IsDelegation === false
        ) {
          schoolstudents.push(students[j].StudentID);
        }
      }
      if (schoolstudents.length > 0) {
        doGetAvailableDispatch({ studentIDs: schoolstudents });
      }
    }
  };

  openCheckinWindow = () => {
    this.props.doShowModal({
      modalScreenName: "CheckInQuestions",
      modalClassName: "checkInQuestionsModal",
    });
  };

  openChildAnnounceFor = () => {
    this.props.doShowModal({
      modalScreenName: "childCardAnnounceFor",
      params: {
        kidsAnnouncedToday: this.state.kidsAnnouncedToday,
      },
      modalClassName: "childCardAnnounceForModal",
    });
  };

  openCancelThisPickup = (item) => {
    this.props.doShowModal({
      modalScreenName: "cancelThisPickup",
      modalClassName: "cancelThisPickupModal",
      params: {
        cancelpickups: item,
        doCallbackRefresh: this.doRefreshListing,
      },
    });
  };

  getStudents = () => {
    const { doGetStudentsList } = this.props;
    let currenDate = moment().format("MM-DD-YYYY");
    doGetStudentsList({ currenDate }).then(() => {
      this.getDispatchList();
    });
  };

  getFutureListing = () => {
    const { doGetFutureEntries } = this.props;
    doGetFutureEntries().then(() => { });
  };

  getSchool = (callback) => {
    const { doGetSchoolDetails } = this.props;
    doGetSchoolDetails().then(() => {
      callback();
    });
  };

  checkTokenValid = () => {
    const { doGetIsTokenValid } = this.props;
    doGetIsTokenValid();
  };

  fetchOtherChildrenList = () => {
    const { doGetOtherChildrenList } = this.props;
    doGetOtherChildrenList();
  };

  checkInForSchool = () => {
    const { doGetSchoolCheckIn } = this.props;
    doGetSchoolCheckIn({
      callback: () => { },
    });
  };

  initialLoad = () => {
    this.getSchool(this.getStudents);
    this.checkInForSchool();
    this.fetchOtherChildrenList();
    this.getFutureListing();
  };

  doReload = () => {
    this.setState(
      {
        lastLoadedStudentList: new Date(),
      },
      () => {
        this.getSchool(this.getStudents);
      }
    );
  };
  componentDidUpdate(prevProps) {
    const {
      error,
      fetchSchoolInfoDone,
      fetchStudentListDone,
      doSchoolAndKidsFetchDone,
      isTokenValidResponse,
      doGetIsTokenValidClear,
      delegationCancelResponse,
      doCancelDelegationClear,
      doRemoveMultipleDelegationsClear,
      deleteMultipleDelegations,
      availableDispatch,
      doGetAvailableDispatchClear,
      submitAnnounceResponse,
      doAnnounceSubmitClear,
      doStudentsSchoolListClear,
    } = this.props;
    const { isActionPerformed, allAvailableDispatch } = this.state;

    if (!_.isEqual(this.props.selectedTheme, prevProps.selectedTheme)) {
      this.setThemeBody();
    }

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doAnnounceSubmitClear();
      this.props.doGetFutureEntriesClear()
    }

    if (submitAnnounceResponse && submitAnnounceResponse.Success) {
      toast.success(submitAnnounceResponse.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doAnnounceSubmitClear();
      doStudentsSchoolListClear();
      this.setState({ loadDataDone: false, openAnnounceCard: true }, () => {
        this.open;
        this.doReload(true);
      });
    }

    if (availableDispatch) {
      const newAllAvailableDispatch = { ...allAvailableDispatch };
      if (newAllAvailableDispatch.StudentDismissalMethod)
        newAllAvailableDispatch.StudentDismissalMethod =
          newAllAvailableDispatch.StudentDismissalMethod.concat(
            availableDispatch.StudentDismissalMethod
          );
      else
        newAllAvailableDispatch.StudentDismissalMethod =
          availableDispatch.StudentDismissalMethod;

      if (newAllAvailableDispatch.MethodsAvailableToEveryone)
        newAllAvailableDispatch.MethodsAvailableToEveryone =
          newAllAvailableDispatch.MethodsAvailableToEveryone.concat(
            availableDispatch.MethodsAvailableToEveryone
          );
      else
        newAllAvailableDispatch.MethodsAvailableToEveryone =
          availableDispatch.MethodsAvailableToEveryone;

      this.setState({ allAvailableDispatch: newAllAvailableDispatch });
      doGetAvailableDispatchClear();
    }

    if (fetchStudentListDone === true && fetchSchoolInfoDone === true) {
      this.setState(
        { loadDataDone: true, isPullRefresh: false, SchoolStudentList: [] },
        this.buildList
      );
      doSchoolAndKidsFetchDone();
    }

    if (isTokenValidResponse) {
      if (isTokenValidResponse.Success === false) {
        const { doLogoutUser } = this.props;
        doLogoutUser();
        this.props.navigate("/");
      } else {
        this.initialLoad();
      }
    }
    doGetIsTokenValidClear();
    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
    } else if (delegationCancelResponse && isActionPerformed) {
      this.setState({ isActionPerformed: false });
      toast.success(
        delegationCancelResponse.Message || delegationCancelResponse.Message,
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        }
      );
      doCancelDelegationClear();
      this.doRefreshListing();
      this.setState({ displayConfirmation: false });
    } else if (deleteMultipleDelegations && isActionPerformed) {
      this.setState({ isActionPerformed: false });
      toast.success(
        deleteMultipleDelegations.Message || deleteMultipleDelegations.Message,
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        }
      );
      doRemoveMultipleDelegationsClear();
      this.doRefreshListing();
      this.setState({ displayConfirmation: false });
    }
  }

  selectedSwitchCallback = (chidInfo, isSelected) => {
    let selectedKidsSwitches = { ...this.state.selectedKidsSwitches };
    let selectedKidsSwitchesSchool = selectedKidsSwitches[chidInfo.SchoolID];
    if (!selectedKidsSwitchesSchool) selectedKidsSwitchesSchool = {};
    if (isSelected === true) {
      selectedKidsSwitchesSchool[chidInfo.StudentID] = chidInfo;
      /**WQ:04/11/2020
       * Show message if parent select child for announce
       */
      toast.info(
        `${this.props.t("Announce_For_Text")} ${chidInfo.Firstname} ${chidInfo.Lastname
        }`,
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: true,
        }
      );
    } else {
      delete selectedKidsSwitchesSchool[chidInfo.StudentID];
      /**WQ:04/11/2020
       * Show message if parent uncheck child for announce
       */
      toast.error(
        `${this.props.t("Are_Not_Announcing_For")} ${chidInfo.Firstname} ${chidInfo.Lastname
        }`,
        {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        }
      );
    }

    selectedKidsSwitches[chidInfo.SchoolID] = selectedKidsSwitchesSchool;
    this.setState({ selectedKidsSwitches });
  };

  isAnyKidSelected = (school) => {
    let { selectedKidsSwitches } = this.state;
    let selectedKidsSwitchesSchool = selectedKidsSwitches[school.schoolId];
    if (!selectedKidsSwitchesSchool) selectedKidsSwitchesSchool = {};

    return Object.keys(selectedKidsSwitchesSchool).length > 0;
  };

  buildList = () => {
    let { selectedKidsSwitches } = this.state;
    const { studentsList, schoolInfo } = this.props;
    const schools = schoolInfo;
    const students = studentsList.TodaysStudents;
    const kidsAnnouncedToday = [];
    const SchoolStudentList = [];
    for (let i = 0; i < schools.length; i++) {
      let schoolstudents = [];
      let selectedKidsSwitchesSchool = {};
      const LastDayOfSchool = new Date(schools[i].Dates.LastDayOfSchool);
      const isLastDayOfSchoolPast = moment(LastDayOfSchool).isBefore(moment());

      for (let j = 0; j < students.length; j++) {
        if (schools[i].SchoolID === students[j].SchoolID) {
          students[j].isPickingUpToday = students[j].PickupModeAllowsAnnounce;
          students[j].isLastDayOfSchoolPast = isLastDayOfSchoolPast;
          schoolstudents.push(students[j]);
          if (
            isAnnounceAllowed(
              students[j].DismissalTime,
              students[j].PickupModeAllowsAnnounce,
              students[j].StatusID,
              isLastDayOfSchoolPast
            )
          )
            selectedKidsSwitchesSchool[students[j].StudentID] = students[j];

          if (students[j].StatusID === GLOBAL_CONST.PICKUP_STATUS.ANNOUNCED) {
            kidsAnnouncedToday.push(students[j]);
          }
        }
      }

      schoolstudents = schoolstudents.sort((a, b) =>
        a.Firstname.toLowerCase() > b.Firstname.toLowerCase()
          ? 1
          : b.Firstname.toLowerCase() > a.Firstname.toLowerCase()
            ? -1
            : 0
      );

      selectedKidsSwitches[schools[i].SchoolID] = selectedKidsSwitchesSchool;
      if (schoolstudents.length > 0) {
        let sectiondata = {
          schoolId: schools[i].SchoolID,
          address: schools[i].Address,
          schoolzip: schools[i].State + " " + schools[i].Zip,
          title: schools[i].Name,
          ReunificationEnabled: schools[i].ReunificationEnabled,
          schoolLat: schools[i].Coordinates.latitude,
          schoolLong: schools[i].Coordinates.longitude,
          AnnounceDistance: schools[i].AnnounceDistance,
          data: schoolstudents,
          isLastDayOfSchoolPast,
          Dates: schools[i].Dates,
        };
        SchoolStudentList.push(sectiondata);
      }
    }

    const fulldata = [];
    let previousDelegations = [],
      futureDelegations = [];
    const sortedSchoolStudentList = SchoolStudentList.sort((a, b) =>
      a.title.toLowerCase() > b.title.toLowerCase()
        ? 1
        : b.title.toLowerCase() > a.title.toLowerCase()
          ? -1
          : 0
    );
    if (sortedSchoolStudentList.length > 0) {
      fulldata.push(sortedSchoolStudentList);
    } else {
      if (studentsList && studentsList.PriorAndFutureDelegations) {
        const PriorAndFutureDelegations =
          studentsList.PriorAndFutureDelegations;
        previousDelegations = PriorAndFutureDelegations.filter(
          (item) => item.Last != null && item.NextDelegation === null
        );
        futureDelegations = PriorAndFutureDelegations.filter(
          (item) => item.NextDelegation != null
        );
      }
    }
    this.setState(
      {
        SchoolStudentList: sortedSchoolStudentList,
        previousDelegations,
        futureDelegations,
        selectedKidsSwitches,
        kidsAnnouncedToday,
        modalVisibleButton: kidsAnnouncedToday.length > 0,
      },
      this.checkToShowAnnounceCards
    );
    this.props.doSaveTodaysKidsAnnouncedList(kidsAnnouncedToday);
    this.setMessage();
  };
  checkToShowAnnounceCards = () => {
    const { openAnnounceCard } = this.state;
    if (openAnnounceCard === true) {
      this.openChildAnnounceFor();
      this.setState({ openAnnounceCard: false }, this.toggleAnnounceButton);
    }
  };

  toggleAnnounceButton() {
    this.setState({
      modalVisible: true,
      modalVisibleButton: true,
    });
  }

  toggleModal(visible) {
    this.setState({ modalVisible: visible });
  }

  doAnnounce = (schoolId, latitude, longitude) => {
    let { selectedKidsSwitches } = this.state;
    let selectedKidsSwitchesSchool = selectedKidsSwitches[schoolId];
    if (!selectedKidsSwitchesSchool) selectedKidsSwitchesSchool = {};

    const { doAnnounceSubmit } = this.props;
    if (Object.keys(selectedKidsSwitchesSchool).length === 0) {
      alert(this.props.t("Select_kid_Announce"));
      return;
    }

    let params = {
      SchoolId: schoolId,
      Latitude: latitude,
      Longitude: longitude,
    };
    let Students = [];
    for (var key in selectedKidsSwitchesSchool) {
      const student = selectedKidsSwitchesSchool[key];
      if (student.SchoolID === schoolId) {
        Students.push({
          StudentId: student.StudentID,
          IsDelegated: student.IsDelegation,
          PickupModeId: student.PickupModeID,
        });
      }
    }
    params.Students = Students;
    doAnnounceSubmit(params);
  };

  setMessage = () => {
    let isDelegate = false;
    let { noKidsTitle, noKidsDetail } = this.state;
    if (isDelegate) {
      noKidsTitle = this.props.t("dashBoardScr-kidsTitle");
      noKidsDetail = this.props.t("dashBoardScr-kidsDetail");
    } else {
      noKidsTitle = this.props.t("dashBoardScr-noKidsTitle");
      noKidsDetail = this.props.t("dashBoardScr-noKidsDetail");
    }

    this.setState({ noKidsTitle, noKidsDetail });
  };

  showEmpty = () => {
    const {
      SchoolStudentList,
      previousDelegations,
      futureDelegations,
      loadDataDone,
      noKidsTitle,
      noKidsDetail,
    } = this.state;
    let noKidsData = { noKidsTitle, noKidsDetail };

    if (
      loadDataDone &&
      SchoolStudentList.length === 0 &&
      previousDelegations.length === 0 &&
      futureDelegations.length === 0
    )
      return <Nokids {...noKidsData} screenName={"dashboard"} />;
  };
  openContactSupportModal = () => {
    this.props.doShowModal({
      modalScreenName: "ContactSupport",
      modalClassName: "contactSupportModal sidebar-modal right",
    });
  };
  showListing = () => {
    const { isFetching } = this.props;
    const {
      SchoolStudentList,
      previousDelegations,
      futureDelegations,
      loadDataDone,
    } = this.state;

    if (
      !isFetching &&
      loadDataDone &&
      (SchoolStudentList.length > 0 ||
        previousDelegations.length > 0 ||
        futureDelegations.length > 0)
    ) {
      return (
        <section className="pmk-child-schedule">
          <div className="container-fluid">
            {(previousDelegations.length > 0 || futureDelegations.length > 0) &&
              SchoolStudentList.length === 0 ? (
              <div className="row child-sch-list delegationsList">
                <div className="col-sm-12 mb-30">
                  <div className="noKidsLayout">
                    <figure className="noKidsImage">
                      <img
                        src={NokidsImage}
                        title={this.props.t("No_Delegation")}
                        alt={this.props.t("No_Delegation")}
                      />
                      <br />
                    </figure>
                    <div className="noKidsDetails">
                      <h5>{this.props.t("No_Delegation")}</h5>

                      <div className="btn-sect">
                        <button
                          className="btn needhelp-label"
                          onClick={() => {
                            this.openContactSupportModal();
                          }}
                        >
                          {this.props.t("Need_Help")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-sep delegations-prev">
                  <div className="card-caption">
                    <h2>{this.props.t("Previous_label")}</h2>
                  </div>

                  {this.renderPriorAndFutureListing(
                    previousDelegations,
                    "previousDelegations"
                  )}
                  {previousDelegations.length === 0 && (
                    <p>{this.props.t("NoRecord")}</p>
                  )}
                </div>

                <div className="col-sm-12 col-md-6 delegations-upcoming col-sep">
                  <div className="card-caption">
                    <h2>{this.props.t("DasboardScr_upcoming_changes")}</h2>
                  </div>

                  {this.renderPriorAndFutureListing(
                    futureDelegations,
                    "futureDelegations"
                  )}
                  {futureDelegations.length === 0 && (
                    <div>
                      <br />
                      <br />
                      {this.props.t("noUpcomingChangesText")}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="row child-sch-list">
                <div className="col-sm-12 col-md-6 col-sep ">
                  <div className="card-caption">
                    <h2>{this.props.t("DasboardScr_TodaysDismissal")}</h2>
                  </div>

                  {this.displayStudentList()}
                </div>

                <div className="col-sm-12 col-md-6 c-upcoming-changes col-sep">
                  <div className="card-caption">
                    <h2>{this.props.t("DasboardScr_upcoming_changes")}</h2>
                  </div>
                  {this.renderFutureEntries()}
                </div>
              </div>
            )}
          </div>
        </section>
      );
    } else if (isFetching) {
      return (
        <div className="loaderHolder">
          <RingLoader color={getCurrentThemeColor(this.props.selectedTheme).loader} loading={isFetching} size={60} />
        </div>
      );
    }
  };

  displayStudentList = () => {
    const { SchoolStudentList, selectedKidsSwitches } = this.state;
    if (!SchoolStudentList || SchoolStudentList.length === 0) return;
    const studentList = [];

    for (let i = 0; i < SchoolStudentList.length; i++) {
      studentList.push(
        <SchoolCard
          schoolStudentDetials={SchoolStudentList[i]}
          key={"schoolCard" + SchoolStudentList[i].schoolId}
          school={SchoolStudentList[i]}
          showAnnounce={this.doAnnounce}
          isAnyKidSelected={this.isAnyKidSelected}
          selectedSwitchCallback={this.selectedSwitchCallback}
          selectedKidsSwitches={selectedKidsSwitches}
        ></SchoolCard>
      );
    }
    return <div className="todays-dismissals">{studentList}</div>;
  };

  isParent = (studentid) => {
    const { studentsList } = this.props;
    const match = studentsList.TodaysStudents.filter((item) => {
      return item.StudentID === studentid && item.IsDelegation === false;
    });
    return match.length > 0;
  };

  renderFutureEntries = () => {
    const { futureEntries } = this.props;
    const { allAvailableDispatch } = this.state;
    const views = [];
    for (var i = 0; i < futureEntries.length; i++) {
      const item = futureEntries[i];
      let defaultPickup, newPickup;
      if (Object.keys(allAvailableDispatch).length > 0) {
        defaultPickup = getPickupMode(allAvailableDispatch, item);
        newPickup = getModifiedPickupMode(
          allAvailableDispatch,
          item,
          this.props
        );
      }
      if (this.isParent(item.StudentID)) {
        views.push(
          <div className="card-bx citem" key={"FE" + i + "_" + item.StudentID}>
            <div className="citem-contents">
              <div className="citem-row">
                <div className="row mb-12">
                  <div className="col-6 ">
                    <h4 className="citem-title2">{item.Name}</h4>
                  </div>
                  <div className="col-6 text-right">
                    <span className="text-right sm-label blue-text showdate">
                      {Moment(item.CalendarDate).format("MMMM DD, YYYY")}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="citem-info">
                      <span>
                        {this.props.t("DasboardScr_defaultPickupMode")}:{" "}
                        {defaultPickup}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="citem-row citem-updated-pickup">
                <div className="row mb-12 ">
                  <div className="col-xs-12 col-sm-12 dflex">
                    <span className="citem-title2 updatedPickupModeTitle">
                      <em className="updatedPickupModeLabel">
                        {this.props.t("DasboardScr_updatedPickupMode")}:
                      </em>{" "}
                      <em className="updatedPickupModeVal">{newPickup}</em>
                    </span>
                  </div>
                </div>
                <div className="row  changed-by-text mb-20">
                  <div className="col-sm-12">
                    <div className="citem-info ">
                      <em>
                        {this.props.t("DasboardScr_changedBy")}:{" "}
                        {item.ChangedByName}
                      </em>
                    </div>
                  </div>
                </div>
                <div className="row  button-row">
                  <div className="col-sm-12">
                    <button
                      type="button"
                      className="btn btn-style2 "
                      onClick={() => {
                        this.setState({ isActionPerformed: true }, () => {
                          this.openCancelThisPickup(item);
                        });
                      }}
                    >
                      {this.props.t("modifyDismissalScr-cancelThis")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    if (futureEntries.length === 0 || views.length === 0) {
      return <p>{this.props.t("noUpcomingChangesText")}</p>;
    } else {
      return views;
    }
  };

  renderPriorAndFutureListing = (items, listTitle) => {
    const views = [];
    let subKey = listTitle == "previousDelegations" ? "PD" : "FD";
    for (var i = 0; i < items.length; i++) {
      const item = items[i];
      views.push(
        <div className="card-bx citem" key={subKey + "_" + item.fk_studentid}>
          <div className="citem-contents">
            <div className="row mb-12">
              <div className="col-6 ">
                <h4 className="citem-title2">
                  {item.firstname} {item.lastname}
                </h4>
              </div>
              <div className="col-6 text-right">
                <span className="text-right sm-label blue-text showdate">
                  {item.NextDelegation != null
                    ? moment(item.NextDelegation).format("DD MMM YYYY")
                    : moment(item.Last).format("DD MMM YYYY")}
                </span>
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-sm-12">
                <div className="citem-info">
                  <span>{item.SchoolName}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return views;
  };
  render() {
    const { userLoginInfo, displayAds, selectedTheme, futureEntries } = this.props;
    const styles = getMyStyle(selectedTheme);
    return (
      <div className="pmk-body graySubtle-bg">
        <MainHeader logoutAction={this.logout} />
        <section className="pmk-welcome-sect">
          <div className="container-fluid">
            <div className="row flex-middle">
              <div className="col-xs-12 col-sm-8">
                <div className="title-sect">
                  <h1 className="title-style2" style={styles.titleStyle2}>
                    {this.props.t("dashBoardScr-hello")}{" "}
                    {userLoginInfo ? userLoginInfo.firstName : ""}
                  </h1>
                  <p className="welcome-text helptext">
                    {this.props.t("DasboardScr_welcomeBackTo")} Pikmykid
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className="date-text">
                  {moment().format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.showListing()}
        {this.state.modalVisibleButton === true && (
          <div
            className="announcefor-btn"
            onClick={() => {
              this.openChildAnnounceFor();
            }}
            style={{
              bottom: displayAds === true ? "72px" : "5px",
            }}
          >
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        {this.showEmpty()}
        {this.props.isShowAdvert && <AdsView />}
        <ToastContainer />
        <MainFooter />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    studentsList: state.student.studentsList,
    schoolInfo: state.student.schoolInfo,
    error: state.student.error,
    fetchStudentListDone: state.student.fetchStudentListDone,
    fetchSchoolInfoDone: state.student.fetchSchoolInfoDone,
    userLoginInfo: state.userAccount.userLoginInfo,
    isTokenValidResponse: state.userAccount.isTokenValidResponse,
    schoolParentCheckIn: state.checkin.schoolParentCheckIn,
    environment: state.userAccount.environment,
    isFetching: loadingSelector(state),
    futureEntries: state.userAccount.futureEntries,
    delegationCancelResponse: state.student.delegationCancelResponse,
    relatedDelegations: state.student.relatedDelegations,
    deleteMultipleDelegations: state.student.deleteMultipleDelegations,
    submitAnnounceResponse: state.student.submitAnnounceResponse,
    setHelpListData: state.userAccount.setHelpListData,

    availableDispatch: state.student.availableDispatch,
    displayAds: state.userAccount.displayAds,

    modalLevelInfo: state.userAccount.modalLevelInfo,
    selectedTheme: state.userAccount.selectedTheme,
    isShowAdvert: state.ads.isShowAdvert
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doGetStudentsList: (data) => dispatch(getStudentsList(data)),
    doGetSchoolDetails: () => dispatch(getSchoolDetails()),
    doSchoolAndKidsFetchDone: () => dispatch(schoolAndKidsFetchDone()),
    doStudentsSchoolListClear: () => dispatch(studentsSchoolListClear()),
    doGetIsTokenValid: (data) => dispatch(getIsTokenValid(data)),
    doGetIsTokenValidClear: () => dispatch(getIsTokenValidClear()),
    doGetOtherChildrenList: () => dispatch(getOtherChildrenDetails()),
    doSaveTodaysKidsAnnouncedList: (data) =>
      dispatch(saveTodaysKidsAnnouncedList(data)),
    doGetSchoolCheckIn: (data) => dispatch(getSchoolCheckIn(data)),
    doGetFutureEntries: (data) => dispatch(getFutureEntries(data)),
    doGetFutureEntriesClear: () => dispatch(getFutureEntriesClear()),
    doRelatedDelegationsDetails: (data) =>
      dispatch(relatedDelegationsDetails(data)),
    doRemoveMultipleDelegations: (data) =>
      dispatch(removeMultipleDelegations(data)),
    doRemoveMultipleDelegationsClear: () =>
      dispatch(removeMultipleDelegationsClear()),
    docancelDelegation: (data) => dispatch(delegationCancel(data)),
    doCancelDelegationClear: () => dispatch(delegationCancelClear()),
    doLogoutUser: () => dispatch(logoutUser()),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doGetAvailableDispatch: (data) => dispatch(getAvailableDispatch(data)),
    doGetAvailableDispatchClear: () => dispatch(getAvailableDispatchClear()),
    doAnnounceSubmit: (data) => dispatch(announceSubmit(data)),
    doAnnounceSubmitClear: () => dispatch(announceSubmitClear()),
  };
}
export default withTranslation()(
  withRouterHook(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
);
