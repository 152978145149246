// get school checkin
export const GET_SCHOOL_CHECKIN_START = "get_school_checkin_start";
export const GET_SCHOOL_CHECKIN_SUCCESS = "get_school_checkin_success";
export const GET_SCHOOL_CHECKIN_FAILED = "get_school_checkin_failed";
export const GET_SCHOOL_CHECKIN_CLEAR = "get_school_checkin_clear";

// get school geo fence information

export const GET_SCHOOL_INFORMATION_START = "get_school_information_start";
export const GET_SCHOOL_INFORMATION_SUCCESS = "get_school_information_success";
export const GET_SCHOOL_INFORMATION_FAILED = "get_school_information_failed";

// checkin by parent

export const SUBMIT_CHECKIN_PARENT_START = "submit_checkin_parent_start";
export const SUBMIT_CHECKIN_PARENT_SUCCESS = "submit_checkin_parent_success";
export const SUBMIT_CHECKIN_PARENT_FAILED = "submit_checkin_parent_failed";
export const SUBMIT_CHECKIN_PARENT_CLEAR = "submit_checkin_parent_clear";

// checkin get questions

export const GET_CHECKIN_QUESTIONS_START = "get_checkin_questions_start";
export const GET_CHECKIN_QUESTIONS_SUCCESS = "get_checkin_questions_success";
export const GET_CHECKIN_QUESTIONS_FAILED = "get_checkin_questions_failed";
export const GET_CHECKIN_QUESTIONS_CLEAR = "get_checkin_questions_clear";

// checkin get parent questions

export const GET_CHECKIN_PARENT_QUESTIONS_START =
  "get_checkin_parent_questions_start";
export const GET_CHECKIN_PARENT_QUESTIONS_SUCCESS =
  "get_checkin_parent_questions_success";
export const GET_CHECKIN_PARENT_QUESTIONS_FAILED =
  "get_checkin_parent_questions_failed";
export const GET_CHECKIN_PARENT_QUESTIONS_CLEAR =
  "get_checkin_parent_questions_clear";

// SchoolAllowCheckIn

export const GET_SCHOOL_ALLOW_CHECKIN_START = "get_school_allow_checkin_start";
export const GET_SCHOOL_ALLOW_CHECKIN_SUCCESS =
  "get_school_allow_checkin_success";
export const GET_SCHOOL_ALLOW_CHECKIN_FAILED =
  "get_school_allow_checkin_failed";
export const GET_SCHOOL_ALLOW_CHECKIN_CLEAR = "get_school_allow_checkin_clear";

// can checkIn

export const GET_SCHOOL_STUDENT_CHECKIN_START =
  "get_school_student_checkin_start";
export const GET_SCHOOL_STUDENT_CHECKIN_SUCCESS =
  "get_school_student_checkin_success";
export const GET_SCHOOL_STUDENT_CHECKIN_FAILED =
  "get_school_student_checkin_failed";
export const GET_SCHOOL_STUDENT_CHECKIN_CLEAR =
  "get_school_student_checkin_clear";

// who can check-in today

export const WHO_CAN_CHECKIN_TODAY_START = "who_can_checkin_today_start";
export const WHO_CAN_CHECKIN_TODAY_SUCCESS = "who_can_checkin_today_success";
export const WHO_CAN_CHECKIN_TODAY_FAILED = "who_can_checkin_today_failed";
export const WHO_CAN_CHECKIN_TODAY_CLEAR = "who_can_checkin_today_clear";
