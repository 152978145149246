import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./AdsViewStyle.css";
import {
  getParentAdvertList,
  hideAdvert,
  logAdvertClick,
} from "../../common/actions/ads";
import { getCurrentThemeColor } from "../../helper/utility";
class AdsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAdIndex: 0,
    };
  }

  componentDidMount() {
    setTimeout(this.getAds, 5000);
  }

  componentWillUnmount() {
    if (this.adsLoop) {
      clearInterval(this.adsLoop);
      this.adsLoop = null;
    }
  }

  getAds = () => {
    const { doGetParentAdvertList, schoolInfo } = this.props;
    const SchoolIDs = [];
    schoolInfo &&
      schoolInfo.forEach((element) => {
        SchoolIDs.push(element.SchoolID);
      });
    doGetParentAdvertList({ SchoolIDs });
  };

  showAds = () => {
    let { currentAdIndex } = this.state;
    const { adsList } = this.props;
    if (adsList && adsList.Ads.length > 0) {
      if (currentAdIndex < adsList.Ads.length - 1) {
        currentAdIndex++;
      } else {
        currentAdIndex = 0;
      }
      this.setState({ currentAdIndex });
    }
  };

  componentDidUpdate() {
    const { adsList } = this.props;

    if (!this.adsLoop && adsList && adsList.Ads.length > 0) {
      this.adsLoop = setInterval(this.showAds, adsList.AdIntervalMS);
    }
  }

  render() {
    const { adsList, isShowAdvert, doLogAdvertClick } = this.props;
    let { currentAdIndex } = this.state;

    return isShowAdvert && adsList && adsList.Ads.length > 0 ? (
      <div className="pmkAdsSection">
        <figure className="AdsImages">
          <button
            type="button"
            className="adclose-btn"
            style={{
              color: getCurrentThemeColor(this.props.selectedTheme).black_white,
            }}
            onClick={() => {
              this.props.doHideAdvert();
            }}
            aria-label={this.props.t("Close_Button")}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
          <a
            className="ads_link"
            onClick={() => {
              let url = adsList.Ads[currentAdIndex].ClickTargetURL;
              if (url && !url.startsWith("http")) {
                url = "http://" + url;
              }
              if (url) {
                window.open(url, "_blank", "noreferrer");

                let params = {};
                params = {
                  SchoolID: adsList.Ads[currentAdIndex].SchoolID,
                  ParentAdvertisementID:
                    adsList.Ads[currentAdIndex].ParentAdvertID,
                };
                doLogAdvertClick(params);
              }
            }}
          >
            <img
              src={adsList.Ads[currentAdIndex].ImageUrl}
              alt="Ads"
              title="Ads"
            />
          </a>
        </figure>
      </div>
    ) : (
      <></>
    );
  }
}

function mapStateToProps(state) {
  return {
    schoolInfo: state.student.schoolInfo,
    adsList: state.ads.adsList,
    isShowAdvert: state.ads.isShowAdvert,
    selectedTheme: state.userAccount.selectedTheme,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doGetParentAdvertList: (data) => dispatch(getParentAdvertList(data)),
    doLogAdvertClick: (data) => dispatch(logAdvertClick(data)),
    doHideAdvert: () => dispatch(hideAdvert()),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AdsView)
);
