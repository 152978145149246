/* -----------------------------------------------------------------------------
  Theme Colors
----------------------------------------------------------------------------- */

export default {
  blue: "#2d3d56",
  blueSecondary: "#3A65C7",
  blueMild: "#53a5e0",
  blueShadow: "#86B6D8",
  blueMild2: "#77A0E0",
  green: "#7AC943",
  orange: "#EFAF1E",
  orangeSecondary: "#EF861E",
  black: "#333E48",
  gray: "#BEC8DE",
  graySubtle: "#F0F4FA",
  theme_LightDark: "#23252D",
  graySoft: "#dbd6e0",
  grayDark: "#525c72",
  grayLabel: "#5B6781",
  white: "#FDFEFE",
  errorRed: "#A82323",
  warningOrange: "#EFAF1E",
  successGreen: "#7AC943",
  grayShadowColor: "rgba(51, 62, 77, 0.10)",
  brown: "#6f2f2f",
  red: "#d44a4a",
  lightOrange: "#F1AD86",
};
