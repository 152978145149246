import React from "react";
import { withRouter } from "./withRouter";
import { withTranslation } from "react-i18next";
import moment from "moment";
import GLOBAL_CONST from "../../helper/AppConstants";

class RegisterFooter extends React.Component {
  render() {
    const copyRightfromYear = "2015";
    return (
      <div className="footer">
        <div className="ft-bottom need-help">
          <div className="container-sect">
            <p>{this.props.t("privacyPolicy_Msg")}</p>
            <p>
              <a
                className="blue-text"
                href="https://schools.pikmykid.com/pikmykid/account/termsconditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.t("termsCondition")}{" "}
              </a>
              <span>&nbsp; {this.props.t("AND")}&nbsp; </span>
              <a
                className="blue-text"
                href="https://schools.pikmykid.com/pikmykid/account/termsconditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.t("privacyStatement")}
              </a>
            </p>

            <p>
              {this.props.t("footer-copyright")} {copyRightfromYear} -{" "}
              {moment().format("YYYY")}
            </p>
            <p>{"Ver. " + GLOBAL_CONST.VERSION}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(RegisterFooter));
