import {
  GET_SCHOOL_CHECKIN_START,
  GET_SCHOOL_CHECKIN_SUCCESS,
  GET_SCHOOL_CHECKIN_FAILED,
  GET_SCHOOL_CHECKIN_CLEAR,
  GET_SCHOOL_INFORMATION_START,
  GET_SCHOOL_INFORMATION_SUCCESS,
  GET_SCHOOL_INFORMATION_FAILED,
  SUBMIT_CHECKIN_PARENT_START,
  SUBMIT_CHECKIN_PARENT_SUCCESS,
  SUBMIT_CHECKIN_PARENT_FAILED,
  SUBMIT_CHECKIN_PARENT_CLEAR,
  GET_CHECKIN_QUESTIONS_START,
  GET_CHECKIN_QUESTIONS_SUCCESS,
  GET_CHECKIN_QUESTIONS_FAILED,
  GET_CHECKIN_QUESTIONS_CLEAR,
  GET_CHECKIN_PARENT_QUESTIONS_START,
  GET_CHECKIN_PARENT_QUESTIONS_SUCCESS,
  GET_CHECKIN_PARENT_QUESTIONS_FAILED,
  GET_CHECKIN_PARENT_QUESTIONS_CLEAR,
  GET_SCHOOL_ALLOW_CHECKIN_START,
  GET_SCHOOL_ALLOW_CHECKIN_SUCCESS,
  GET_SCHOOL_ALLOW_CHECKIN_FAILED,
  GET_SCHOOL_ALLOW_CHECKIN_CLEAR,
  GET_SCHOOL_STUDENT_CHECKIN_START,
  GET_SCHOOL_STUDENT_CHECKIN_SUCCESS,
  GET_SCHOOL_STUDENT_CHECKIN_FAILED,
  GET_SCHOOL_STUDENT_CHECKIN_CLEAR,
  WHO_CAN_CHECKIN_TODAY_START,
  WHO_CAN_CHECKIN_TODAY_SUCCESS,
  WHO_CAN_CHECKIN_TODAY_FAILED,
  WHO_CAN_CHECKIN_TODAY_CLEAR,
} from "../actions/checkin/checkinActionType";

import { LOGOUT_USER } from "../actions/userAccount/userActionType";

export const INITIAL_STATE = {
  schoolParentCheckIn: null,
  schoolCheckInResponse: null,
  schoolGeoFenceInformation: {},
  CheckInByParentResponse: null,
  getCheckInQuestionsResponse: null,
  canCheckin: null,
  whoCanCheckInToday: null,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...INITIAL_STATE,
      };
    case GET_SCHOOL_CHECKIN_START:
      return { ...state, error: null };
    case GET_SCHOOL_CHECKIN_SUCCESS:
      return {
        ...state,
        schoolParentCheckIn: action.payload,
        error: null,
      };
    case GET_SCHOOL_CHECKIN_FAILED:
      return {
        ...state,
        schoolParentCheckIn: null,
        error: action.payload,
      };
    case GET_SCHOOL_CHECKIN_CLEAR:
      return {
        ...state,
        schoolParentCheckIn: null,
        error: null,
      };
    case GET_SCHOOL_INFORMATION_START:
      return {
        ...state,
        error: null,
      };
    case GET_SCHOOL_INFORMATION_SUCCESS: {
      // console.log("GET_SCHOOL_INFORMATION_SUCCESS");
      const schoolGeoFenceInformation = { ...state.schoolGeoFenceInformation };
      // console.log("GET_SCHOOL_INFORMATION_SUCCESS ", schoolGeoFenceInformation);
      schoolGeoFenceInformation[action.payload.schoolId] = action.payload.data;
      return {
        ...state,
        schoolGeoFenceInformation,
        error: null,
      };
    }
    case GET_SCHOOL_INFORMATION_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    case SUBMIT_CHECKIN_PARENT_START:
      return {
        ...state,
        error: null,
        CheckInByParentResponse: null,
      };
    case SUBMIT_CHECKIN_PARENT_SUCCESS:
      return {
        ...state,
        CheckInByParentResponse: action.payload,
        error: null,
      };
    case SUBMIT_CHECKIN_PARENT_FAILED:
      return {
        ...state,
        CheckInByParentResponse: null,
        error: action.payload,
      };
    case SUBMIT_CHECKIN_PARENT_CLEAR:
      return {
        ...state,
        CheckInByParentResponse: null,
        error: action.payload,
      };
    case GET_CHECKIN_QUESTIONS_START:
      return {
        ...state,
        error: null,
        getCheckInQuestionsResponse: null,
      };
    case GET_CHECKIN_QUESTIONS_SUCCESS:
      return {
        ...state,
        getCheckInQuestionsResponse: action.payload,
        error: null,
      };
    case GET_CHECKIN_QUESTIONS_FAILED:
      return {
        ...state,
        getCheckInQuestionsResponse: null,
        error: action.payload,
      };
    case GET_CHECKIN_QUESTIONS_CLEAR:
      return {
        ...state,
        getCheckInQuestionsResponse: null,
        error: action.payload,
      };
    case GET_CHECKIN_PARENT_QUESTIONS_START:
      return {
        ...state,
        error: null,
        getCheckInQuestionsResponse: null,
      };
    case GET_CHECKIN_PARENT_QUESTIONS_SUCCESS:
      return {
        ...state,
        getCheckInQuestionsResponse: action.payload,
        error: null,
      };
    case GET_CHECKIN_PARENT_QUESTIONS_FAILED:
      return {
        ...state,
        getCheckInQuestionsResponse: null,
        error: action.payload,
      };
    case GET_CHECKIN_PARENT_QUESTIONS_CLEAR:
      return {
        ...state,
        getCheckInQuestionsResponse: null,
        error: action.payload,
      };
    case GET_SCHOOL_ALLOW_CHECKIN_START:
      return {
        ...state,
        error: null,
        schoolAllowcheckInResponse: null,
      };
    case GET_SCHOOL_ALLOW_CHECKIN_SUCCESS:
      return {
        ...state,
        schoolAllowcheckInResponse: action.payload,
        error: null,
      };
    case GET_SCHOOL_ALLOW_CHECKIN_FAILED:
      return {
        ...state,
        schoolAllowcheckInResponse: null,
        error: action.payload,
      };
    case GET_SCHOOL_ALLOW_CHECKIN_CLEAR:
      return {
        ...state,
        schoolAllowcheckInResponse: null,
        error: action.payload,
      };
    case GET_SCHOOL_STUDENT_CHECKIN_START:
      return {
        ...state,
        error: null,
      };
    case GET_SCHOOL_STUDENT_CHECKIN_SUCCESS: {
      const canCheckin = { ...state.canCheckin };

      canCheckin[action.payload.schoolID] = action.payload.data;

      return {
        ...state,
        canCheckin,
        error: null,
      };
    }
    case GET_SCHOOL_STUDENT_CHECKIN_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case GET_SCHOOL_STUDENT_CHECKIN_CLEAR:
      return {
        ...state,
        canCheckin: null,
        error: action.payload,
      };
    case WHO_CAN_CHECKIN_TODAY_START:
      return {
        ...state,
        error: null,
        whoCanCheckInToday: null,
      };
    case WHO_CAN_CHECKIN_TODAY_SUCCESS:
      return {
        ...state,
        whoCanCheckInToday: action.payload,
        error: null,
      };
    case WHO_CAN_CHECKIN_TODAY_FAILED:
      return {
        ...state,
        whoCanCheckInToday: null,
        error: action.payload,
      };
    case WHO_CAN_CHECKIN_TODAY_CLEAR:
      return {
        ...state,
        whoCanCheckInToday: null,
        error: action.payload,
      };

    default:
      return state;
  }
};
