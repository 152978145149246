import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { history, store, persistor } from "./common/store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Notifications } from "react-push-notification";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getGoogleAPIClientID } from "./common/services/config";
import "./App.css";
import WebRoutes from "./WebRoutes";
import GLOBAL_CONST from "./helper/AppConstants";
const { unstable_HistoryRouter: HistoryRouter } = require("react-router-dom");
function App() {
  return (
    <Provider store={store}>
      <Notifications />
      <PersistGate loading={null} persistor={persistor}>
        <HistoryRouter history={history}>
          <GoogleOAuthProvider clientId={GLOBAL_CONST.GOOGLE_SSO_CONFIG.webClientId}>
            <I18nextProvider i18n={i18n}>
              <WebRoutes />
            </I18nextProvider>
          </GoogleOAuthProvider>
        </HistoryRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
