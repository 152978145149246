import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dom } from "@fortawesome/fontawesome-svg-core";
import { faSchool } from "@fortawesome/free-solid-svg-icons";
import { BootstrapModal } from "../CommonComponents";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
  saveLocationPermission,
  fetchDistance,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";
import { getSiteAccessPermission } from "../../helper/utility";
import {
  getSchoolPickupTimeDetails,
  getSchoolPickupTimeDetailsClear,
} from "../../common/actions/school";
import { createLoadingSelector } from "../../common/reducers/selectors";
import RingLoader from "react-spinners/ClipLoader";
import { withTranslation } from "react-i18next";
import { geolocated } from "react-geolocated";

const loadingSelector = createLoadingSelector(["get_school_pickup_time"]);

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

class DismissalTimes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      latitude: null,
      longitude: null,
      etaInfo: "Loading...",
    };

    this.getSchoolDismisal();
  }

  async componentDidMount() {
    this.getUserLocation();
    this.fetchETA();
    this.myETAInterval = setInterval(() => this.fetchETA, 80000);

    this.dosetHelpList();
  }
  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Dashboard",
      screen: GLOBAL_CONST.SCREEN_NAMES.SCHOOL_DETAILS,
      title: this.props.t("schoolDetailsScr-schoolDetail"),
    });
  };
  getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {}
    );
  };

  componentWillUnmount() {
    if (this.myETAInterval) {
      clearInterval(this.myETAInterval);
      this.myETAInterval = null;
    }
  }

  fetchETA = () => {
    const { doFetchDistance } = this.props;
    const { school } = this.props;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        doFetchDistance({
          deviceLat: latitude,
          deviceLon: longitude,
          schoolLatitude: school.schoolLat,
          schoolLongitude: school.schoolLong,
          callback: this.processETA,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
  setSchoolDetailsHelp = () => {
    this.openHelpModal();
  };

  setDismissalsTimesHelp = () => {
    this.props.dosetHelpList({
      stackType: "Dashboard",
      screen: GLOBAL_CONST.SCREEN_NAMES.SCHOOL_DISMISSAL_TIME,
      title: this.props.t("dismissalTimeScr-schoolTime"),
    });
    setTimeout(this.openHelpModal, 200);
  };

  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  getSchoolDismisal = () => {
    const { doGetSchoolPickupTimeDetails } = this.props;
    const { school } = this.props;
    doGetSchoolPickupTimeDetails({
      SchoolID: school.schoolId,
      IncludeDismissalTimes: true,
    });
  };

  getGradeViews = (gradeData, dayId) => {
    var views = [];
    for (var j = 0; j < gradeData.length; j++) {
      views.push(
        <div
          className="grade-time-details"
          key={dayId + "_" + gradeData[j].grade}
        >
          <p className="grade"> {gradeData[j].grade}</p>
          <p className="dismissalTime">{gradeData[j].pickupTime}</p>
        </div>
      );
    }
    return views;
  };

  buildDayList = () => {
    const { data } = this.state;
    const views = [];
    for (var i = 0; i < data.length; i++) {
      const item = data[i];
      views.push(
        <div className="weekDay-bx" key={item.id}>
          <div className="weekDay-title">{item.day}</div>
          {this.getGradeViews(item.gradeData, item.id)}
        </div>
      );
    }
    return views;
  };

  processETA = (response) => {
    const { school } = this.props;
    let output;
    let etaRoute;

    if (response && response.routes) etaRoute = response.routes[0];
    if (etaRoute) {
      const eta = Math.ceil(parseInt(etaRoute.duration) / 60);
      let etaHours = "";
      let etaMinutes = "";
      if (eta > 60) {
        etaMinutes = eta % 60;
        etaHours = Math.floor(eta / 60);

        output = "";
        if (etaHours !== 0) {
          output = etaHours;
          output += " ";
          output +=
            etaHours === 1
              ? this.props.t("ETA_HOUR")
              : this.props.t("ETA_HOURS");
          output += " ";
        }
        if (etaHours !== 0 && etaMinutes !== 0) {
          output += this.props.t("AND") + " ";
        }
        if (etaMinutes !== 0) {
          output += etaMinutes;
          output += " ";
          output +=
            etaMinutes === 1
              ? this.props.t("ETA_MINUTE")
              : this.props.t("ETA_MINUTES");
          output += " ";
        }
      } else {
        etaMinutes = eta % 60;

        if (eta > 0 && etaMinutes !== 0) {
          output = eta;
          output += " ";
          output +=
            etaMinutes === 1
              ? this.props.t("ETA_MINUTE")
              : this.props.t("ETA_MINUTES");
          output += " ";
        } else if (eta === 0) {
          output = this.props.t("schoolCardScr-onCampus");
        }
      }
    } else {
      output = "No Routes";
    }

    let message;
    if (!output) {
      message = "Loading...";
    } else if (output === this.props.t("schoolCardScr-onCampus")) {
      message = output;
    } else if (output === "No Routes") {
      message = this.props.t("ETA_NO_ROUTES_FOUND");
    } else {
      message = this.props.t("ETA_PREFIX") + " ";
      message += output + " ";
      message += this.props.t("ETA_SUFFIX");
    }

    this.setState({ etaInfo: message });
  };
  goToMap = () => {
    const { school } = this.props;
    const { latitude, longitude } = this.state;
    window.open(
      "https://www.google.com/maps/dir/?api=1&origin=" +
        latitude +
        "," +
        longitude +
        "&destination=" +
        school.schoolLat +
        "," +
        school.schoolLong
    );
  };

  openMapWithoutSource = () => {
    const { school } = this.props;
    const { latitude, longitude } = this.state;
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
        school.schoolLat +
        "," +
        school.schoolLong
    );
  };

  directionMessage = () => {
    const { school } = this.props;
    const { etaInfo, latitude, longitude } = this.state;
    if (latitude != null && longitude != null) {
      return (
        <div className="distanceLocationHolder">
          <span className="etatext">{etaInfo}</span>
          {etaInfo !== "Loading..." && (
            <button
              type="button"
              className="btn-location"
              onClick={() => {
                this.goToMap();
              }}
            >
              <i className="fas fa-map-marker-alt"></i>
            </button>
          )}
        </div>
      );
    } else {
      <div></div>;
    }
  };

  renderDismissalTimes = () => {
    const { school, isFetching } = this.props;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">
                {this.props.t("schoolDetailsScr-dismSchedule")}
              </h4>
              <button
                type="button"
                className="btn help-icon-btn"
                onClick={() => {
                  this.setSchoolDetailsHelp();
                }}
              >
                {this.props.t("regNameScr-needHelp")}
              </button>
            </div>

            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>

          <div className="modal-body  modal-popup">
            <div className="schoolDetails">
              <div className="schoolname-header">
                <div>
                  <div
                    onClick={() => {
                      this.openMapWithoutSource();
                    }}
                  >
                    <h3 className="school-title">{school.title}</h3>
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faSchool}
                  className="school-icon"
                  onClick={() => {
                    this.openMapWithoutSource();
                  }}
                />
              </div>

              {this.buildDayList()}
            </div>
            <div className="bottom-stick dism-announce-notice">
              <div className="notice-text">
                {this.props.t("dismissalTimeScr-pickerTime")}
              </div>
            </div>
          </div>
          <RingLoader color="#2d3d56" loading={isFetching} size={60} />
        </div>
      </div>
    );
  };

  buildDismissal = () => {
    const { schoolDismissalDetails } = this.props;
    const { studentsList } = this.props;
    const { school } = this.props;
    const todayStudentsList = studentsList.TodaysStudents;
    const gradeToShows = [];
    for (let j = 0; j < todayStudentsList.length; j++) {
      if (school.schoolId === todayStudentsList[j].SchoolID) {
        if (!gradeToShows.includes(todayStudentsList[j].GradeName))
          gradeToShows.push(todayStudentsList[j].GradeName);
      }
    }

    const data = [];
    for (var i = 0; i < schoolDismissalDetails.length; i++) {
      const record = schoolDismissalDetails[i];
      const item = {
        id: i,
        day: DAYS[record.DayOfWeek],
      };
      const gradeData = [];
      for (var j = 0; j < record.GradeNames.length; j++) {
        if (gradeToShows.includes(record.GradeNames[j])) {
          gradeData.push({
            grade: record.GradeNames[j],
            pickupTime: record.DisplayedToUserTime_Local,
          });
        }
      }
      if (gradeData.length > 0) {
        item.gradeData = gradeData;
        data.push(item);
      }
    }
    this.setState({ data });
  };

  componentDidUpdate() {
    const { schoolDismissalDetails, doGetSchoolPickupTimeDetailsClear } =
      this.props;

    if (schoolDismissalDetails != null) {
      this.buildDismissal();
      doGetSchoolPickupTimeDetailsClear();
    }
  }

  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };

  render() {
    return this.renderDismissalTimes();
  }
}

function mapStateToProps(state) {
  return {
    schoolDismissalDetails: state.school.schoolDismissalDetails,
    isFetching: loadingSelector(state),
    error: state.student.error,
    studentsList: state.student.studentsList,
    currentLocationPermission: state.userAccount.currentLocationPermission,
    setHelpListData: state.userAccount.setHelpListData,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doGetSchoolPickupTimeDetails: (data) =>
      dispatch(getSchoolPickupTimeDetails(data)),
    doGetSchoolPickupTimeDetailsClear: () =>
      dispatch(getSchoolPickupTimeDetailsClear()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doFetchDistance: (data) => dispatch(fetchDistance(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),
    doSaveLocationPermission: (data) => dispatch(saveLocationPermission(data)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DismissalTimes)
);
