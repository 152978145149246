import React from "react";
import {
  clearRegistrationInfo,
  loginUser,
  loginUserClear,
  setEnvironment,
  logoutUser,
  getCountryCodes,
  getGeoInformation,
  accountDelete,
  accountDeleteClear,
  showModal,
  hideModal,
} from "../../../common/actions/userAccount";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "../../../../node_modules//bootstrap/dist/css/bootstrap.min.css";
import { RegisterFooter } from "../../CommonComponents";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import "../../Styles/app.css";
import "../NewRegistration/Styles/style.css";
import "../LandingPage/landing.css";
import "../NewRegistration/Styles/theme-responsive.css";
import { createLoadingSelector } from "../../../common/reducers/selectors";
import { getLanguageCode, formatPhone } from "../../../helper/utility";
import Select from "react-dropdown-select";

import { Slide, toast, ToastContainer } from "react-toastify";

import logo from "../../assets/registerImages/pmk-logo.svg";
import "react-confirm-alert/src/react-confirm-alert.css";

import { useNavigate, useLocation } from "react-router-dom";

const loadingSelector = createLoadingSelector(["login_user"]);
const countryCodesLoadingSelector = createLoadingSelector([
  "country_code_list",
]);

// country_calling_code
const defaultCountry = {
  CountryId: 1,
  CountryName: "USA",
  CountryCode: "+1",
  label: "USA(+1)",
};

function withRouterHook(Component) {
  return function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component navigate={navigate} location={location} {...props} />;
  };
}
class DeleteAccount extends React.Component {
  constructor(props) {
    super(props);

    let { registeredCountryCode, registeredCountryName } = this.props;
    if (registeredCountryCode === null) registeredCountryCode = "+1";
    if (registeredCountryName === null) registeredCountryName = "USA";

    this.state = {
      userEmail: "",
      password: "",
      env: "prod",
      userEmailError: "",
      userPassError: "",
      hidePassword: true,
      ServerError: "",
      countryCode: defaultCountry.CountryCode,
      countryName: defaultCountry.CountryName,

      phoneNumber: "",
      phoneInvalid: "",
      selectedCountryCode: defaultCountry.CountryCode,
      selectedCountry: this.getCountry(),
      firstLoadDone: false,
      useEmail: true,
      isActionPerformed: false,
    };
    this.selectedCountry = React.createRef();
    this.phone = React.createRef();
    this.userEmail = React.createRef();
  }

  componentDidMount() {
    this.props.doLogoutUser();
    this.props.doDeleteAccountClear();

    this.props.doGetCountryCodes(() => {
      this.props.doGetGeoInformation((info) => {
        this.setState({
          selectedCountry: this.setCurrentCountry(info.country_calling_code),
          countryCode: info.country_calling_code,
        });
      });
    });

    this.userEmail.current.focus();
  }

  setPasswordVisibility = () => {
    this.setState({ hidePassword: !this.state.hidePassword });
  };

  getCountry() {
    const { registeredCountryName, countryCodeListing } = this.props;
    if (!countryCodeListing) return;
    const items = countryCodeListing.filter((item) => {
      return item.CountryName === registeredCountryName;
    });
    if (items && items.length > 0) {
      return items;
    }

    return [defaultCountry];
  }

  setCurrentCountry = (currentCountry) => {
    const { countryCodeListing, geoInfoData } = this.props;
    if (!countryCodeListing && !geoInfoData) return;
    const items =
      countryCodeListing &&
      countryCodeListing.filter((item) => {
        return item.CountryCode === currentCountry;
      });

    if (items && items.length > 0) {
      return items;
    }

    return [defaultCountry];
  };

  saveCountry = (value) => {
    if (value && value.length > 0) {
      this.setState({
        selectedCountry: value,
        countryCode: value[0].CountryCode,
        countryName: value[0].CountryName,
      });
      this.phone.current.focus();
    }
  };

  submitForm = () => {
    const { doSetEnvironment } = this.props;
    let { env } = this.state;
    let { userEmail, useEmail, password } = this.state;

    if (useEmail) {
      var reg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let isValid = reg.test(userEmail);
      if (reg.test(userEmail) === false) {
        this.setState({
          userEmailError: `${this.props.t("regAcctScr-emailError")}`,
        });
        return;
      }
      if (password === null || password === "") {
        this.setState({
          userPassError: `${this.props.t("loginScreen-passwordError")}`,
        });
        return;
      }

      if (userEmail.includes(".staging")) {
        env = "staging";
      } else if (userEmail.includes(".develop")) {
        env = "develop";
      } else {
        env = "prod";
      }
      this.setState({ env }, () => {
        if (env === "staging") {
          doSetEnvironment({ env: "staging", callback: this.processLogin });
        } else if (env === "develop") {
          doSetEnvironment({ env: "develop", callback: this.processLogin });
        } else {
          doSetEnvironment({ env: "prod", callback: this.processLogin });
        }
      });
    } else {
      this.processLogin();
    }
  };

  processLogin = () => {
    const { doclearRegistrationInfo, doLogin } = this.props;
    let { userEmail, phoneNumber, useEmail, password, env, countryCode } =
      this.state;

    if (userEmail !== null && userEmail !== undefined) {
      userEmail = userEmail.replace(".staging", "");
      userEmail = userEmail.replace(".develop", "");
    }
    doclearRegistrationInfo();
    doLogin({
      email: userEmail,
      phoneNumber: countryCode + phoneNumber,
      useEmail,
      password: password,
      locale: getLanguageCode(),
      env,
    });
    this.setState({ isActionPerformed: true });
  };

  handleUserChange = ({ target: { value: userEmail } }) =>
    this.setState({ userEmail, userEmailError: "", ServerError: "" });
  handlePasswordChange = ({ target: { value: password } }) =>
    this.setState({ password, userPassError: "", ServerError: "" });

  handlePhoneChange = ({ target: { value: val } }) => {
    this.setState({ phoneNumber: formatPhone(val), phoneInvalid: "" });
  };
  goToPasswordField = (event) => {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);

    if (event.key === "Enter") {
      form.elements["password"].focus();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { userLoginInfo, error, doLoginClear } = this.props;
    const { userEmail, isActionPerformed } = this.state;

    var re = /^\d{8,15}$/;

    if (prevState.userEmail !== userEmail && !this.state.firstLoadDone) {
      // Check if this.state.userEmail does not contain @. can use indexof
      if (userEmail.indexOf("@") === -1 && re.test(userEmail)) {
        this.setState({
          firstLoadDone: true,
          useEmail: false,
          phoneNumber: userEmail,
        });
      }
    }
    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doLoginClear();

      this.props.doGetCountryCodes(() => {
        this.props.doGetGeoInformation((info) => {
          this.setState({
            selectedCountry: this.setCurrentCountry(info.country_calling_code),
            countryCode: info.country_calling_code,
          });
        });
      });
    } else if (isActionPerformed && userLoginInfo != null) {
      this.checkIfVerifiedAccount();
    }
  }

  openDeleteAccountConfirmationModal = () => {
    const { password } = this.state;
    const { isFetching } = this.props;
    this.props.doShowModal({
      modalScreenName: "deleteAccountModal",
      modalClassName: "passwordOtpEntryModal",
      params: {
        password,
      },
    });
    this.setState({ isActionPerformed: false });
  };

  //   confirmAlert({
  //     title: this.props.t("settingMainScr-signOut"),
  //     message: this.props.t("settingMainScr-areYouSure"),
  //     buttons: [
  //       {
  //         label: this.props.t("DELETE"),
  //         onClick: () => {
  //           this.submitDeleteAccount();
  //         },
  //       },
  //       {
  //         label: this.props.t("Cancel"),
  //         className: "cancel-btn",
  //       },
  //     ],
  //   });
  // };

  checkIfVerifiedAccount = () => {
    const { userLoginInfo } = this.props;
    const isPhoneVerified =
      userLoginInfo.isOTPVerified ||
      userLoginInfo.isOTPVerified === "True" ||
      userLoginInfo.IsPhoneVerified;
    if (isPhoneVerified && userLoginInfo.IsEmailVerified) {
      if (
        userLoginInfo !== null &&
        userLoginInfo !== undefined &&
        userLoginInfo.AuthTokenID &&
        isPhoneVerified &&
        userLoginInfo.IsEmailVerified
      ) {
        this.openDeleteAccountConfirmationModal();
      }
    }
  };

  getEmailView = () => {
    const { userEmail, userEmailError } = this.state;
    return (
      <div className="input-wrapper">
        <input
          autoComplete="off"
          type="email"
          ref={this.userEmail}
          id="userEmail"
          className={`form-control ${
            userEmailError !== "" ? "input-error" : null
          }`}
          placeholder={this.props.t("loginScreen-email")}
          name={"userEmail"}
          value={userEmail}
          onChange={this.handleUserChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") this.goToPasswordField(event);
          }}
        />
        <i className="far fa-user-circle userIcon"></i>
        <span className="error" id="error">
          {" "}
          {userEmailError}
        </span>
      </div>
    );
  };

  getPhoneView = () => {
    const { phoneNumber, countryCode, phoneInvalid, selectedCountry } =
      this.state;

    const { countryCodeListing } = this.props;

    return (
      <div className="input-wrapper">
        <div className="input-row countryselect-list">
          <Select
            key={`key-${this.state.useEmail}`}
            values={selectedCountry}
            multi={false}
            searchable={true}
            options={countryCodeListing}
            labelField={"label"}
            valueField={"CountryId"}
            onChange={(value) => this.saveCountry(value)}
            searchBy={"label"}
            clearOnSelect={true}
            placeholder={this.props.t("Select_Country")}
            name="selectcountry"
            className="form-control"
            ref={this.selectedCountry}
            createNewLabel={true}
            clearOnBlur={true}
            handleKeyDownFn={() => {
              this.setState({ selectedCountry: [] });
            }}
            loading={this.props.isFetchingCountryCodes}
            loadingRenderer={({ props }) => (
              <div className="text-center loader-wrap">
                <i className="fas fa-sync-alt fa-spin"></i>
              </div>
            )}
          />
        </div>

        <div className="input-group">
          <input
            type="text"
            className="form-control input-sm input-noflex"
            readOnly={true}
            value={countryCode}
            name="countrycodename"
          />
          <input
            autoComplete="tel"
            type="text"
            id="phoneNumber"
            className={`form-control ${
              phoneInvalid !== "" ? "input-error" : null
            }`}
            placeholder={this.props.t("regPhoneScr-phoneNumberPlace")}
            value={phoneNumber}
            onChange={this.handlePhoneChange}
            maxLength={15}
            name="phoneNumber"
            ref={this.phone}
            onKeyDown={(event) => {
              this.goToPasswordField(event);
            }}
          />
          <i className="fas fa-phone userIcon"></i>
        </div>
        <span className="error" id="message">
          {" "}
          {phoneInvalid}
        </span>
      </div>
    );
  };

  render() {
    const { password, userPassError, hidePassword, ServerError, useEmail } =
      this.state;
    const { isFetching } = this.props;

    return (
      <section className="landingPage deleteAccountPg">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <div className="col-sm-12 col-md-7 col-lg-7 col-xl-8 main-col">
                <div className="pmk-landing">
                  <div className="logo-wrapper">
                    <a className="navbar-brand" href="/">
                      <img src={logo} alt="PikMyKid" title="PikMyKid" />
                    </a>
                  </div>

                  <div className="welcome-sect">
                    <div className="title-sect">
                      <h1 className="title-1">
                        {this.props.t("landingScreen-welcome-to")} Pikmykid!
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  <div className="container-sect">
                    <form className="form-style1" autoComplete="off">
                      <div className="title-sect mb-40">
                        <h3 className="title-3">
                          {this.props.t("delete_account_alert")}
                        </h3>
                      </div>
                      <div className="form-group">
                        {useEmail && this.getEmailView()}
                        {!useEmail && this.getPhoneView()}
                        <button
                          type="button"
                          className="usephonenumber-label blue-text btn"
                          onClick={() => {
                            this.setState({ useEmail: !useEmail });
                          }}
                        >
                          {useEmail
                            ? this.props.t("usePhoneNumberInstead")
                            : this.props.t("useEmailInstead")}
                        </button>
                      </div>

                      <div className="form-group mb-40">
                        <input
                          type={hidePassword ? "password" : "text"}
                          className={`form-control ${
                            userPassError !== "" ? "input-error" : null
                          }`}
                          placeholder={this.props.t("loginScreen-password")}
                          value={password}
                          onChange={this.handlePasswordChange}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              this.submitForm();
                            }
                          }}
                          name="password"
                        />
                        <i
                          onClick={() => {
                            this.setPasswordVisibility();
                          }}
                          className={
                            hidePassword
                              ? "far fa-eye togglePassword"
                              : "far fa-eye-slash togglePassword1"
                          }
                        ></i>
                        <span className="error" id="error">
                          {" "}
                          {userPassError}
                        </span>
                      </div>

                      <div className="error" id="error">
                        {" "}
                        {ServerError}
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            this.submitForm();
                          }}
                        >
                          {" "}
                          {isFetching ? (
                            <i className="fas fa-sync-alt fa-spin"></i>
                          ) : null}{" "}
                          &nbsp; {this.props.t("loginScreen-login")}
                        </button>
                      </div>
                    </form>
                  </div>
                  <ToastContainer />
                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    isFetching: loadingSelector(state),
    isFetchingCountryCodes: countryCodesLoadingSelector(state),
    error: state.userAccount.error,
    registeredCountryCode: state.userAccount.registeredCountryCode,
    countryCodeListing: state.userAccount.countryCodeListing,
    environment: state.userAccount.environment,
    geoInfoData: state.userAccount.geoInfoData,
    deleteAccount: state.userAccount.deleteAccount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doclearRegistrationInfo: () => dispatch(clearRegistrationInfo()),
    doLogin: (data) => dispatch(loginUser(data)),
    doLoginClear: () => dispatch(loginUserClear()),
    doSetEnvironment: (data) => dispatch(setEnvironment(data)),
    doGetCountryCodes: (callback) => dispatch(getCountryCodes(callback)),
    doLogoutUser: () => dispatch(logoutUser()),
    doGetGeoInformation: (callback) => dispatch(getGeoInformation(callback)),
    doDeleteAccount: (data) => dispatch(accountDelete(data)),
    doDeleteAccountClear: () => dispatch(accountDeleteClear()),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
  };
}

export default withTranslation()(
  withRouterHook(connect(mapStateToProps, mapDispatchToProps)(DeleteAccount))
);
