import React from "react";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./../../../../node_modules/jquery/src/jquery.js";
import "./../../../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import "../../Styles/app.css";
import "./Styles/style.css";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-dropdown-select";
import { formatPhone, getLanguageCode } from "../../../helper/utility";
import GLOBAL_CONST from "../../../helper/AppConstants";
import { Line } from "rc-progress";

import {
  saveRegistrationInfo,
  addUserPhoneNumber,
  userPhoneNumberClear,
  getCountryCodes,
  setHelpList,
  showModal,
  hideModal,
  getGeoInformation,
} from "../../../common/actions/userAccount";
import {
  NewRegisterWizard,
  RegisterFooter,
  RegisterHeader,
  SubmitButton,
  withRouter,
} from "../../CommonComponents";

import {
  startRegistrationClear,
  startUserRegistration,
} from "../../../common/actions/userAccount/RegistrationAction";
import { Slide, toast, ToastContainer } from "react-toastify";

import { createLoadingSelector } from "../../../common/reducers/selectors";
const loadingSelector = createLoadingSelector([
  "add_phone_number_user",
  "start_registartion",
]);
const countryCodesLoadingSelector = createLoadingSelector([
  "country_code_list",
]);

const defaultCountry = {
  CountryId: 1,
  CountryName: "USA",
  CountryCode: "+1",
  label: "USA(+1)",
};

class RegisterPhone extends React.Component {
  constructor(props) {
    super(props);
    let { registeredCountryCode, registeredCountryName } = this.props;
    if (registeredCountryCode === null) registeredCountryCode = "+1";
    if (registeredCountryName === null) registeredCountryName = "USA";
    const { registeredPhone } = this.props;
    this.state = {
      countryCode: registeredCountryCode,
      countryName: registeredCountryName,
      phone: registeredPhone ? registeredPhone : "",
      phoneInvalid: "",
      selectedCountryCode:
        registeredCountryCode !== null ? registeredCountryCode : "",
      selectedCountry: this.getCountry(),
    };
    this.selectedCountry = React.createRef();
    this.phone = React.createRef();
  }

  componentDidMount() {
    this.dosetHelpList();
    this.props.doGetCountryCodes(() => {
      this.props.doGetGeoInformation((info) => {
        this.setState({
          selectedCountry: this.setCurrentCountry(info.country_calling_code),
          countryCode: info.country_calling_code,
        });
      });
    });
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Registration",
      screen: GLOBAL_CONST.SCREEN_NAMES.REGISTRATION_PHONE,
      title: this.props.t("regCardScr-regAccount"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  getCountry() {
    const { registeredCountryName, countryCodeListing } = this.props;
    if (!countryCodeListing) return;
    const items = countryCodeListing.filter((item) => {
      return item.CountryName === registeredCountryName;
    });
    if (items && items.length > 0) {
      return items;
    }
    return [defaultCountry];
  }
  setCurrentCountry = (currentCountry) => {
    const { countryCodeListing, geoInfoData } = this.props;
    if (!countryCodeListing && !geoInfoData) return;
    const items =
      countryCodeListing &&
      countryCodeListing.filter((item) => {
        return item.CountryCode === currentCountry;
      });

    if (items && items.length > 0) {
      return items;
    }

    return [defaultCountry];
  };

  saveCountry = (value) => {
    if (value && value.length > 0) {
      this.setState({
        selectedCountry: value,
        countryCode: value[0].CountryCode,
        countryName: value[0].CountryName,
      });
      this.phone.current.focus();
    }
  };

  submitForm = () => {
    const { phone, countryCode, countryName } = this.state;
    const { doSaveRegistrationInfo, doStartRegistration } = this.props;
    if (phone.length <= 7) {
      this.setState({ phoneInvalid: this.props.t("regPhoneScr-phoneInvalid") });
      return;
    }

    let finalPhone = phone.replace(/[^0-9]/g, "");
    let countryCodeOnly = countryCode.replace("+", "");

    if (countryName === "USA" && finalPhone.charAt(0) == countryCodeOnly) {
      finalPhone = finalPhone.substring(1);
      // console.log("finalPhone " + finalPhone);
    }
    if (
      countryName === "USA" &&
      (finalPhone.length < 9 || finalPhone.length > 11)
    ) {
      this.setState({ phoneInvalid: this.props.t("regPhoneScr-phoneInvalid") });
      return;
    }

    this.setState({ phone: finalPhone }, () => {
      doSaveRegistrationInfo({
        registeredPhone: this.state.phone,
        registeredCountryCode: countryCode,
        registeredCountryName: countryName,
      });
      doStartRegistration({
        PhoneNumber: this.state.phone,
        CountryCode: countryCode,
        ResendOTP: true,
        Locale: getLanguageCode(),
        RegistrationTokenId: null,
      });
    });
  };

  handlePhoneChange = ({ target: { value: phone } }) => {
    this.setState({ phone: formatPhone(phone), phoneInvalid: "" });
  };

  componentDidUpdate() {
    const { registartionResponse, error, doStartRegistrationClear } =
      this.props;
    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doStartRegistrationClear();
      this.props.doGetCountryCodes(() => {
        this.props.doGetGeoInformation((info) => {
          this.setState({
            selectedCountry: this.setCurrentCountry(info.country_calling_code),
            countryCode: info.country_calling_code,
          });
        });
      });
    } else if (registartionResponse) {
      this.props.navigate("/newconfirmPhone");
      doStartRegistrationClear();
    }
  }

  render() {
    const { phone, phoneInvalid, selectedCountry, countryCode } = this.state;
    const { isFetching, countryCodeListing } = this.props;

    return (
      <section className="registration registrationPage">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  <NewRegisterWizard
                    webPage={this.props.location.pathname}
                    isBackBtnVisible={false}
                  />
                  <div className="probressbar-holder">
                    <Line percent={14} strokeWidth="1" strokeColor="#7AC943" />
                  </div>
                  <div className="container-sect">
                    <form className="form-style1">
                      <div className="title-sect mb-40">
                        <h3 className="title-3">
                          {this.props.t("regPhoneScr-phoneNumber")}
                        </h3>
                        <p className="helptext">
                          {this.props.t("regPhoneScr-phoneDescription")}
                        </p>
                      </div>
                      <div className="form-group">
                        <Select
                          values={selectedCountry}
                          multi={false}
                          searchable={true}
                          options={countryCodeListing}
                          labelField={"label"}
                          valueField={"CountryId"}
                          onChange={(value) => this.saveCountry(value)}
                          searchBy={"label"}
                          clearOnSelect={true}
                          placeholder={this.props.t("Select_Country")}
                          name="selectcountry"
                          className="form-control"
                          ref={this.selectedCountry}
                          createNewLabel={true}
                          clearOnBlur={true}
                          handleKeyDownFn={() => {
                            this.setState({ selectedCountry: [] });
                          }}
                          loading={this.props.isFetchingCountryCodes}
                          loadingRenderer={({ props }) => (
                            <div className="text-center loader-wrap">
                              <i className="fas fa-sync-alt fa-spin"></i>
                            </div>
                          )}
                        />
                      </div>
                      <div className="form-group ">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control input-sm input-noflex"
                            readOnly={true}
                            value={countryCode}
                          />
                          <input
                            type="text"
                            className={`form-control ${
                              phoneInvalid !== "" ? "input-error" : null
                            }`}
                            placeholder={this.props.t(
                              "regPhoneScr-phoneNumberPlace"
                            )}
                            value={phone}
                            onChange={this.handlePhoneChange}
                            maxLength={15}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") this.submitForm();
                            }}
                            name="phone"
                            ref={this.phone}
                          />
                        </div>

                        <span className="error" id="message">
                          {" "}
                          {phoneInvalid}
                        </span>
                      </div>

                      <div className="form-group mb-40">
                        <button
                          type="button"
                          className="btn needhelp-label"
                          onClick={() => {
                            this.openHelpModal();
                          }}
                        >
                          {this.props.t("regNameScr-needHelp")}
                        </button>
                      </div>
                      <SubmitButton
                        nextAction={this.submitForm}
                        loading={isFetching}
                      />
                    </form>
                  </div>
                  <ToastContainer />
                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    registeredPhone: state.userAccount.registeredPhone,
    registeredCountryCode: state.userAccount.registeredCountryCode,
    registeredCountryName: state.userAccount.registeredCountryName,
    isFetching: loadingSelector(state),
    isFetchingCountryCodes: countryCodesLoadingSelector(state),
    error: state.userAccount.error,
    countryCodeListing: state.userAccount.countryCodeListing,
    registartionResponse: state.userAccount.registartionResponse,
    setHelpListData: state.userAccount.setHelpListData,
    geoInfoData: state.userAccount.geoInfoData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doSaveRegistrationInfo: (data) => dispatch(saveRegistrationInfo(data)),
    doAddUserPhoneNumber: (data) => dispatch(addUserPhoneNumber(data)),
    userPhoneNumberClear: () => dispatch(userPhoneNumberClear()),
    doGetCountryCodes: (callback) => dispatch(getCountryCodes(callback)),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doStartRegistration: (data) => dispatch(startUserRegistration(data)),
    doStartRegistrationClear: () => dispatch(startRegistrationClear()),

    doGetGeoInformation: (callback) => dispatch(getGeoInformation(callback)),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPhone))
);
