import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

function withRouterHook(Component) {
  return function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component navigate={navigate} location={location} {...props} />;
  };
}
class NewRegisterWizard extends React.Component {
  back = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.navigate(-1);
  };

  render() {
    const { isBackBtnVisible } = this.props;
    return (
      <div className="register-wizard">
        <div className="container-sect">
          <div className="nav-buttons">
            {isBackBtnVisible ? (
              <Link
                to={"#"}
                onClick={(event) => {
                  this.back(event);
                }}
                className="previous-steps"
              >
                <i className="fas fa-arrow-left"></i>
                {this.props.t("regCardScr-preStep")}
              </Link>
            ) : (
              <div></div>
            )}
            <button
              onClick={() => {
                this.props.navigate("/");
              }}
              className="btn btn-action close-btn2"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouterHook(NewRegisterWizard));
