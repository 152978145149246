import moment from "moment";
import {
  GET_SCHOOL_STUDENT_INFO_CLEAR,
  GET_STUDENTS_START,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAILED,
  GET_SCHOOL_INFO_START,
  GET_SCHOOL_INFO_SUCCESS,
  GET_SCHOOL_INFO_FAILED,
  GET_SCHOOL_AND_KIDS_DONE,
  GET_BUS_ROUTE_START,
  GET_BUS_ROUTE_SUCCESS,
  GET_BUS_ROUTE_FAILED,
  GET_STUDENT_AFTER_SCHOOL_START,
  GET_STUDENT_AFTER_SCHOOL_SUCCESS,
  GET_STUDENT_AFTER_SCHOOL_FAILED,
  IS_ANNOUNCE_START,
  IS_ANNOUNCE_SUCCESS,
  IS_ANNOUNCE_CLEAR,
  IS_ANNOUNCE_FAILED,
  GET_DELEGATIONS_START,
  GET_DELEGATIONS_SUCCESS,
  GET_DELEGATIONS_FAILED,
  GET_DELEGATIONS_CLEAR,
  CHANGE_DEFAULT_PICKUP_MODE_START,
  CHANGE_DEFAULT_PICKUP_MODE_SUCCESS,
  CHANGE_DEFAULT_PICKUP_MODE_FAILED,
  CHANGE_DEFAULT_PICKUP_MODE_CLEAR,
  CANCEL_DELEGATION_START,
  CANCEL_DELEGATION_SUCCESS,
  CANCEL_DELEGATION_FAILED,
  CANCEL_DELEGATION_CLEAR,
  GET_OTHER_CHILDREN_START,
  GET_OTHER_KIDS_DONE,
  GET_OTHER_CHILDREN_SUCCESS,
  GET_OTHER_CHILDREN_FAILED,
  GET_ABSENT_DATE_RANGE_START,
  GET_ABSENT_DATE_RANGE_SUCCESS,
  GET_ABSENT_DATE_RANGE_FAILED,
  GET_CALENDAR_DATE_RANGE_START,
  GET_CALENDAR_DATE_RANGE_SUCCESS,
  GET_CALENDAR_DATE_RANGE_FAILED,
  SAVE_ABSENTEE_START,
  SAVE_ABSENTEE_SUCCESS,
  SAVE_ABSENTEE_FAILED,
  SAVE_ABSENTEE_CLEAR,
  GET_PICKUP_ENTRIES_START,
  GET_PICKUP_ENTRIES_SUCCESS,
  GET_PICKUP_ENTRIES_FAILED,
  GET_PICKUP_ENTRIES_CLEAR,
  GET_ABSENT_CALENDAR_ENTRIES_RANGE_START,
  GET_ABSENT_CALENDAR_ENTRIES_RANGE_SUCCESS,
  GET_ABSENT_CALENDAR_ENTRIES_RANGE_FAILED,
  GET_ABSENT_CALENDAR_ENTRIES_RANGE_CLEAR,
  CHANGE_DELEGATION_START,
  CHANGE_DELEGATION_SUCCESS,
  CHANGE_DELEGATION_FAILED,
  CHANGE_DELEGATION_CLEAR,
  GET_PICKUP_ENTRIES_MONTH_START,
  GET_PICKUP_ENTRIES_MONTH_SUCCESS,
  GET_PICKUP_ENTRIES_MONTH_FAILED,
  GET_DELEGATE_LIST_START,
  GET_DELEGATE_LIST_SUCCESS,
  GET_DELEGATE_LIST_FAILED,
  GET_DEFAULT_DISMISSAL_START,
  GET_DEFAULT_DISMISSAL_SUCCESS,
  GET_DEFAULT_DISMISSAL_FAILED,
  GET_DEFAULT_DISMISSAL_CLEAR,
  CANCEL_ABSENTEE_START,
  CANCEL_ABSENTEE_SUCCESS,
  CANCEL_ABSENTEE_FAILED,
  CANCEL_ABSENTEE_CLEAR,
  GET_RELATED_DELEGATIONS_START,
  GET_RELATED_DELEGATIONS_SUCCESS,
  GET_RELATED_DELEGATIONS_FAILED,
  CANCEL_MULTIPLE_DELEGATIONS_START,
  CANCEL_MULTIPLE_DELEGATIONS_SUCCESS,
  CANCEL_MULTIPLE_DELEGATIONS_FAILED,
  CANCEL_MULTIPLE_DELEGATIONS_CLEAR,
  GET_AVAILABLE_DISPATCH_START,
  GET_AVAILABLE_DISPATCH_SUCCESS,
  GET_AVAILABLE_DISPATCH_FAILED,
  GET_AVAILABLE_DISPATCH_CLEAR,
  SAVE_SELECTED_GAURDIAN_DETAILS_START,
  GET_DISMISSAL_HISTORY_START,
  GET_DISMISSAL_HISTORY_SUCCESS,
  GET_DISMISSAL_HISTORY_FAILED,
  GET_DISMISSAL_HISTORY_CLEAR,
  GET_POSITION_IN_LINE_START,
  GET_POSITION_IN_LINE_SUCCESS,
  GET_POSITION_IN_LINE_FAILED,
  GET_POSITION_IN_LINE_CLEAR,
  GET_DELEGATIONS_ENTRIES_START,
  GET_DELEGATIONS_ENTRIES_SUCCESS,
  GET_DELEGATIONS_ENTRIES_FAILED,
  GET_DELEGATIONS_ENTRIES_CLEAR,
  TODAYS_KIDS_ANNOUNCED_LIST,
  GET_RELATED_DELEGATIONS_CLEAR,
  EARLY_DISMISSAL_START,
  EARLY_DISMISSAL_SUCCESS,
  EARLY_DISMISSAL_FAILED,
  EARLY_DISMISSAL_CLEAR,
  CANCEL_EARLY_DISMISSAL_START,
  CANCEL_EARLY_DISMISSAL_SUCCESS,
  CANCEL_EARLY_DISMISSAL_FAILED,
  CANCEL_EARLY_DISMISSAL_CLEAR,
  GET_EARLY_DISMISSAL_DATE_RANGE_START,
  GET_EARLY_DISMISSAL_DATE_RANGE_SUCCESS,
  GET_EARLY_DISMISSAL_DATE_RANGE_FAILED,
  GET_EARLY_DISMISSAL_DATE_RANGE_CLEAR,
  GET_BACK_SCHOOL_START,
  GET_BACK_SCHOOL_SUCCESS,
  GET_BACK_SCHOOL_FAILED,
  GET_BACK_SCHOOL_CLEAR,
  SAVE_BACK_SCHOOL_START,
  SAVE_BACK_SCHOOL_SUCCESS,
  SAVE_BACK_SCHOOL_FAILED,
  SAVE_BACK_SCHOOL_CLEAR,
  GET_RELATIONSHIP_START,
  GET_RELATIONSHIP_SUCCESS,
  GET_RELATIONSHIP_FAILED,
  GET_RELATIONSHIP_CLEAR,
  GET_FILL_BACKTOSCHOOL_START,
  GET_FILL_BACKTOSCHOOL_SUCCESS,
  GET_FILL_BACKTOSCHOOL_FAILED,
  GET_SCHOOL_DISPATCH_START,
  GET_SCHOOL_DISPATCH_SUCCESS,
  GET_SCHOOL_DISPATCH_FAILED,
  GET_SCHOOL_DISPATCH_CLEAR,
  SET_CALENDAR_VIEW,
  SET_CALENDAR_VIEW_CLEAR,
} from "../actions/students/studentActionType";

import { LOGOUT_USER } from "../actions/userAccount/userActionType";

export const INITIAL_STATE = {
  studentsList: [],
  schoolInfo: [],
  error: null,
  fetchStudentListDone: false,
  fetchSchoolInfoDone: false,
  busRouteResponse: [],
  studentAfterSchoolResponse: [],
  submitAnnounceResponse: null,
  getDelegationsRecord: [],
  changeDefaultPickupModeResponse: null,
  delegationCancelResponse: null,
  otherChildrens: [],
  fetchOtherChildrenDone: false,
  absentDateRange: [],
  calendarDateRange: [],
  submitAbsenteeResponse: null,
  pickupEntries: {},
  absentCalendarEntries: [],
  changeDelegationResponse: null,
  pickupEntriesMonth: null,
  delegateList: [],
  defaultDismissalResponse: {},
  errorBusroute: null,
  cancelAbsentResponse: null,
  relatedDelegations: null,
  deleteMultipleDelegations: null,
  availableDispatch: null,
  gaurdianInfo: [],
  dismissalHistory: {},
  getCarLinePositionResponse: {},
  getDelegationEntries: [],
  kidsAnnouncedToday: [],
  earlyDismissalResponse: null,
  cancelEarlyDismissalResponse: null,
  earlyDismissalDateRange: null,
  getBackSchoolInfo: [],
  saveBackSchoolInfo: null,
  relationshipList: [],
  fillbacktoschoolinfo: [],
  calendarView: false,
  getSchoolDispatch: null,
  defaultKidsDismissalResponse: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...INITIAL_STATE,
      };

    case GET_STUDENTS_START:
      return {
        ...state,
        fetchStudentListDone: false,
        error: null,
      };
    case GET_STUDENTS_SUCCESS:
      return {
        ...state,
        studentsList: action.payload,
        fetchStudentListDone: true,
        error: null,
      };
    case GET_STUDENTS_FAILED:
      return {
        ...state,
        fetchStudentListDone: true,
        error: action.payload,
      };

    case GET_SCHOOL_INFO_START:
      return {
        ...state,
        error: null,
        fetchSchoolInfoDone: false,
      };
    case GET_SCHOOL_INFO_SUCCESS:
      return {
        ...state,
        schoolInfo: action.payload,
        fetchSchoolInfoDone: true,
        error: null,
      };
    case GET_SCHOOL_INFO_FAILED:
      return {
        ...state,
        error: action.payload,
        fetchSchoolInfoDone: true,
      };
    case GET_SCHOOL_STUDENT_INFO_CLEAR: {
      return {
        ...state,
        studentsList: [],
        schoolInfo: [],
        fetchStudentListDone: false,
        fetchSchoolInfoDone: false,
      };
    }
    case GET_SCHOOL_AND_KIDS_DONE:
      return {
        ...state,
        fetchStudentListDone: false,
        fetchSchoolInfoDone: false,
      };

    case GET_BUS_ROUTE_START:
      return { ...state, errorBusroute: null, busRouteResponse: [] };
    case GET_BUS_ROUTE_SUCCESS:
      return {
        ...state,
        busRouteResponse: action.payload,
        errorBusroute: null,
      };
    case GET_BUS_ROUTE_FAILED:
      return {
        ...state,
        busRouteResponse: [],
        errorBusroute: action.payload,
      };
    case GET_STUDENT_AFTER_SCHOOL_START:
      return { ...state, error: null, studentAfterSchoolResponse: [] };
    case GET_STUDENT_AFTER_SCHOOL_SUCCESS:
      return {
        ...state,
        studentAfterSchoolResponse: action.payload,
        error: null,
      };
    case GET_STUDENT_AFTER_SCHOOL_FAILED:
      return {
        ...state,
        studentAfterSchoolResponse: [],
        error: action.payload,
      };
    case IS_ANNOUNCE_START:
      return { ...state, error: null, submitAnnounceResponse: null };
    case IS_ANNOUNCE_SUCCESS:
      return {
        ...state,
        submitAnnounceResponse: action.payload,
        error: null,
      };
    case IS_ANNOUNCE_CLEAR:
      return {
        ...state,
        submitAnnounceResponse: null,
        error: null,
      };
    case IS_ANNOUNCE_FAILED:
      return {
        ...state,
        submitAnnounceResponse: null,
        error: action.payload,
      };
    case GET_DELEGATIONS_START:
      return { ...state, error: null };
    case GET_DELEGATIONS_SUCCESS: {
      let getDelegationsRecord = [...state.getDelegationsRecord];
      getDelegationsRecord = getDelegationsRecord.concat(action.payload);
      return {
        ...state,
        getDelegationsRecord,
        error: null,
      };
    }
    case GET_DELEGATIONS_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case GET_DELEGATIONS_CLEAR:
      return {
        ...state,
        getDelegationsRecord: [],
        error: null,
      };
    case CHANGE_DEFAULT_PICKUP_MODE_START:
      return { ...state, error: null, changeDefaultPickupModeResponse: null };
    case CHANGE_DEFAULT_PICKUP_MODE_SUCCESS:
      return {
        ...state,
        changeDefaultPickupModeResponse: action.payload,
        error: null,
      };
    case CHANGE_DEFAULT_PICKUP_MODE_FAILED:
      return {
        ...state,
        changeDefaultPickupModeResponse: null,
        error: action.payload,
      };
    case CHANGE_DEFAULT_PICKUP_MODE_CLEAR:
      return {
        ...state,
        changeDefaultPickupModeResponse: null,
        error: null,
      };
    case CANCEL_DELEGATION_START:
      return { ...state, error: null, delegationCancelResponse: null };
    case CANCEL_DELEGATION_SUCCESS:
      return {
        ...state,
        delegationCancelResponse: action.payload,
        error: null,
      };
    case CANCEL_DELEGATION_FAILED:
      return {
        ...state,
        delegationCancelResponse: null,
        error: action.payload,
      };
    case CANCEL_DELEGATION_CLEAR:
      return {
        ...state,
        delegationCancelResponse: null,
        error: null,
      };

    case GET_OTHER_CHILDREN_START:
      return {
        ...state,
        error: null,
        fetchOtherChildrenDone: false,
      };
    case GET_OTHER_KIDS_DONE:
      return {
        ...state,
        fetchOtherChildrenDone: false,
      };
    case GET_OTHER_CHILDREN_SUCCESS:
      return {
        ...state,
        otherChildrens: action.payload,
        fetchOtherChildrenDone: true,

        error: null,
      };
    case GET_OTHER_CHILDREN_FAILED:
      return {
        ...state,
        fetchOtherChildrenDone: true,
        error: action.payload,
      };
    case GET_ABSENT_DATE_RANGE_START:
      return { ...state, error: null, absentDateRange: [] };
    case GET_ABSENT_DATE_RANGE_SUCCESS:
      return {
        ...state,
        absentDateRange: action.payload,
        error: null,
      };
    case GET_ABSENT_DATE_RANGE_FAILED:
      return {
        ...state,
        absentDateRange: [],
        error: action.payload,
      };

    case GET_CALENDAR_DATE_RANGE_START:
      return { ...state, error: null, calendarDateRange: [] };
    case GET_CALENDAR_DATE_RANGE_SUCCESS:
      return {
        ...state,
        calendarDateRange: action.payload,
        error: null,
      };
    case GET_CALENDAR_DATE_RANGE_FAILED:
      return {
        ...state,
        calendarDateRange: [],
        error: action.payload,
      };
    case SAVE_ABSENTEE_START:
      return { ...state, error: null, submitAbsenteeResponse: null };
    case SAVE_ABSENTEE_SUCCESS:
      return {
        ...state,
        submitAbsenteeResponse: action.payload,
        error: null,
      };
    case SAVE_ABSENTEE_FAILED:
      return {
        ...state,
        submitAbsenteeResponse: null,
        error: action.payload,
      };
    case SAVE_ABSENTEE_CLEAR:
      return {
        ...state,
        submitAbsenteeResponse: null,
        error: null,
      };

    case GET_PICKUP_ENTRIES_START:
      return { ...state, error: null, pickupEntries: null };
    case GET_PICKUP_ENTRIES_SUCCESS:
      return {
        ...state,
        pickupEntries: action.payload,
        error: null,
      };
    case GET_PICKUP_ENTRIES_FAILED:
      return {
        ...state,
        pickupEntries: null,
        error: action.payload,
      };
    case GET_PICKUP_ENTRIES_CLEAR:
      return { ...state, error: null, pickupEntries: null };
    case GET_ABSENT_CALENDAR_ENTRIES_RANGE_START:
      return { ...state, error: null, absentCalendarEntries: [] };
    case GET_ABSENT_CALENDAR_ENTRIES_RANGE_SUCCESS:
      return {
        ...state,
        absentCalendarEntries: action.payload,
        error: null,
      };
    case GET_ABSENT_CALENDAR_ENTRIES_RANGE_FAILED:
      return {
        ...state,
        absentCalendarEntries: [],
        error: action.payload,
      };
    case GET_ABSENT_CALENDAR_ENTRIES_RANGE_CLEAR:
      return {
        ...state,
        absentCalendarEntries: [],
        error: null,
      };

    case CHANGE_DELEGATION_START:
      return { ...state, error: null, changeDelegationResponse: null };
    case CHANGE_DELEGATION_SUCCESS:
      return {
        ...state,
        changeDelegationResponse: action.payload,
        error: null,
      };
    case CHANGE_DELEGATION_FAILED:
      return {
        ...state,
        changeDelegationResponse: null,
        error: action.payload,
      };
    case CHANGE_DELEGATION_CLEAR:
      return {
        ...state,
        changeDelegationResponse: null,
        error: null,
      };
    case GET_PICKUP_ENTRIES_MONTH_START:
      return { ...state, error: null, pickupEntriesMonth: null };
    case GET_PICKUP_ENTRIES_MONTH_SUCCESS:
      return {
        ...state,
        pickupEntriesMonth: action.payload,
        error: null,
      };
    case GET_PICKUP_ENTRIES_MONTH_FAILED:
      return {
        ...state,
        pickupEntriesMonth: null,
        error: action.payload,
      };

    case GET_DELEGATE_LIST_START:
      return { ...state, error: null, delegateList: [] };
    case GET_DELEGATE_LIST_SUCCESS:
      return {
        ...state,
        delegateList: action.payload,
        error: null,
      };
    case GET_DELEGATE_LIST_FAILED:
      return {
        ...state,
        delegateList: [],
        error: action.payload,
      };
    case GET_DEFAULT_DISMISSAL_START:
      return { ...state, error: null, defaultKidsDismissalResponse: [] };
    case GET_DEFAULT_DISMISSAL_SUCCESS:
      return {
        ...state,
        defaultKidsDismissalResponse: action.payload,
        error: null,
      };
    case GET_DEFAULT_DISMISSAL_FAILED:
      return {
        ...state,
        defaultKidsDismissalResponse: [],
        error: action.payload,
      };
    case GET_DEFAULT_DISMISSAL_CLEAR:
      return {
        ...state,
        defaultKidsDismissalResponse: [],
        error: null,
      };

    case CANCEL_ABSENTEE_START:
      return { ...state, error: null, cancelAbsentResponse: null };
    case CANCEL_ABSENTEE_SUCCESS:
      return {
        ...state,
        cancelAbsentResponse: action.payload,
        error: null,
      };
    case CANCEL_ABSENTEE_FAILED:
      return {
        ...state,
        cancelAbsentResponse: null,
        error: action.payload,
      };
    case CANCEL_ABSENTEE_CLEAR:
      return {
        ...state,
        cancelAbsentResponse: null,
        error: null,
      };
    case GET_RELATED_DELEGATIONS_START:
      return { ...state, error: null, relatedDelegations: null };
    case GET_RELATED_DELEGATIONS_SUCCESS:
      return {
        ...state,
        relatedDelegations: action.payload,
        error: null,
      };
    case GET_RELATED_DELEGATIONS_CLEAR:
      return { ...state, error: null, relatedDelegations: null };
    case GET_RELATED_DELEGATIONS_FAILED:
      return {
        ...state,
        relatedDelegations: null,
        error: action.payload,
      };
    case CANCEL_MULTIPLE_DELEGATIONS_START:
      return { ...state, error: null, deleteMultipleDelegations: null };
    case CANCEL_MULTIPLE_DELEGATIONS_SUCCESS:
      return {
        ...state,
        deleteMultipleDelegations: action.payload,
        error: null,
      };
    case CANCEL_MULTIPLE_DELEGATIONS_FAILED:
      return {
        ...state,
        deleteMultipleDelegations: null,
        error: action.payload,
      };
    case CANCEL_MULTIPLE_DELEGATIONS_CLEAR:
      return {
        ...state,
        deleteMultipleDelegations: null,
        error: null,
      };

    case GET_AVAILABLE_DISPATCH_START:
      return { ...state, error: null, availableDispatch: null };
    case GET_AVAILABLE_DISPATCH_SUCCESS:
      return {
        ...state,
        availableDispatch: action.payload,
        error: null,
      };
    case GET_AVAILABLE_DISPATCH_FAILED:
      return {
        ...state,
        availableDispatch: null,
        error: action.payload,
      };
    case GET_AVAILABLE_DISPATCH_CLEAR:
      return {
        ...state,
        availableDispatch: null,
        error: null,
      };
    case SAVE_SELECTED_GAURDIAN_DETAILS_START:
      return {
        ...state,
        gaurdianInfo: action.payload,
      };
    case GET_DISMISSAL_HISTORY_START:
      return { ...state, error: null };
    case GET_DISMISSAL_HISTORY_SUCCESS: {
      let dismissalHistoryAll = { ...state.dismissalHistory };
      const params = action.payload.params;
      let dismissalHistory = dismissalHistoryAll[params.StudentID];
      if (dismissalHistory) dismissalHistory = [...dismissalHistory];
      else dismissalHistory = [];

      dismissalHistory = dismissalHistory.concat(action.payload.response);
      dismissalHistoryAll[params.StudentID] = dismissalHistory;
      return {
        ...state,
        dismissalHistory: dismissalHistoryAll,
        error: null,
      };
    }
    case GET_DISMISSAL_HISTORY_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case GET_DISMISSAL_HISTORY_CLEAR: {
      let dismissalHistoryAll = { ...state.dismissalHistory };
      const params = action.payload.params;
      dismissalHistoryAll[params.StudentID] = [];
      return {
        ...state,
        dismissalHistory: dismissalHistoryAll,
        error: null,
      };
    }
    case GET_POSITION_IN_LINE_START:
      return { ...state, error: null };
    case GET_POSITION_IN_LINE_SUCCESS: {
      let getCarLinePositionResponse = { ...state.getCarLinePositionResponse };
      if (getCarLinePositionResponse === null) {
        getCarLinePositionResponse = {};
      }

      const schoolId = action.payload.SchoolID;

      getCarLinePositionResponse[schoolId] = action.payload;

      return {
        ...state,
        getCarLinePositionResponse,
        error: null,
      };
    }
    case GET_POSITION_IN_LINE_FAILED:
      return {
        ...state,
        getCarLinePositionResponse: {},
        error: action.payload,
      };
    case GET_POSITION_IN_LINE_CLEAR:
      return {
        ...state,
        getCarLinePositionResponse: {},
        error: null,
      };
    case GET_DELEGATIONS_ENTRIES_START:
      return { ...state, error: null, getDelegationEntries: [] };
    case GET_DELEGATIONS_ENTRIES_SUCCESS:
      return {
        ...state,
        getDelegationEntries: action.payload,
        error: null,
      };
    case GET_DELEGATIONS_ENTRIES_FAILED:
      return {
        ...state,
        getDelegationEntries: [],
        error: action.payload,
      };
    case GET_DELEGATIONS_ENTRIES_CLEAR:
      return {
        ...state,
        getDelegationEntries: [],
        error: null,
      };
    case TODAYS_KIDS_ANNOUNCED_LIST:
      return {
        ...state,
        kidsAnnouncedToday: action.payload,
      };
    case EARLY_DISMISSAL_START:
      return { ...state, error: null, earlyDismissalResponse: null };
    case EARLY_DISMISSAL_SUCCESS:
      return {
        ...state,
        earlyDismissalResponse: action.payload,
        error: null,
      };
    case EARLY_DISMISSAL_FAILED:
      return {
        ...state,
        earlyDismissalResponse: null,
        error: action.payload,
      };
    case EARLY_DISMISSAL_CLEAR:
      return {
        ...state,
        earlyDismissalResponse: null,
        error: null,
      };
    case CANCEL_EARLY_DISMISSAL_START:
      return { ...state, error: null, cancelEarlyDismissalResponse: null };
    case CANCEL_EARLY_DISMISSAL_SUCCESS:
      return {
        ...state,
        cancelEarlyDismissalResponse: action.payload,
        error: null,
      };
    case CANCEL_EARLY_DISMISSAL_FAILED:
      return {
        ...state,
        cancelEarlyDismissalResponse: null,
        error: action.payload,
      };
    case CANCEL_EARLY_DISMISSAL_CLEAR:
      return {
        ...state,
        cancelEarlyDismissalResponse: null,
        error: null,
      };
    case GET_EARLY_DISMISSAL_DATE_RANGE_START:
      return { ...state, error: null, earlyDismissalDateRange: null };
    case GET_EARLY_DISMISSAL_DATE_RANGE_SUCCESS:
      return {
        ...state,
        earlyDismissalDateRange: action.payload ? action.payload : [],
        error: null,
      };
    case GET_EARLY_DISMISSAL_DATE_RANGE_FAILED:
      return {
        ...state,
        earlyDismissalDateRange: [],
        error: action.payload,
      };
    case GET_EARLY_DISMISSAL_DATE_RANGE_CLEAR:
      return {
        ...state,
        earlyDismissalDateRange: null,
        error: null,
      };
    case GET_BACK_SCHOOL_START:
      return {
        ...state,
        getBackSchoolInfo: [],
        error: null,
      };
    case GET_BACK_SCHOOL_SUCCESS: {
      return {
        ...state,
        getBackSchoolInfo: action.payload,
        error: null,
      };
    }
    case GET_BACK_SCHOOL_FAILED:
      return {
        ...state,
        getBackSchoolInfo: [],
        error: action.payload,
      };
    case GET_BACK_SCHOOL_CLEAR:
      return {
        ...state,
        getBackSchoolInfo: [],
        error: null,
      };
    case SAVE_BACK_SCHOOL_START:
      return {
        ...state,
        error: null,
        saveBackSchoolInfo: null,
      };
    case SAVE_BACK_SCHOOL_SUCCESS:
      return {
        ...state,
        saveBackSchoolInfo: action.payload,
        error: null,
      };
    case SAVE_BACK_SCHOOL_FAILED:
      return {
        ...state,
        saveBackSchoolInfo: null,
        error: action.payload,
      };
    case SAVE_BACK_SCHOOL_CLEAR:
      return {
        ...state,
        saveBackSchoolInfo: null,
        error: null,
      };
    case GET_RELATIONSHIP_START:
      return {
        ...state,
        error: null,
      };
    case GET_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        relationshipList: action.payload,
        error: null,
      };
    case GET_RELATIONSHIP_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case GET_FILL_BACKTOSCHOOL_START:
      return {
        ...state,
        fillbacktoschoolinfo: [],
        error: null,
      };
    case GET_FILL_BACKTOSCHOOL_SUCCESS:
      return {
        ...state,
        fillbacktoschoolinfo: action.payload,
        error: null,
      };
    case GET_FILL_BACKTOSCHOOL_FAILED:
      return {
        ...state,
        fillbacktoschoolinfo: [],
        error: action.payload,
      };
    case GET_SCHOOL_DISPATCH_START:
      return {
        ...state,
        getSchoolDispatch: null,
        error: null,
      };
    case GET_SCHOOL_DISPATCH_SUCCESS:
      return {
        ...state,
        getSchoolDispatch: action.payload,
        error: null,
      };
    case GET_SCHOOL_DISPATCH_FAILED:
      return {
        ...state,
        getSchoolDispatch: null,
        error: action.payload,
      };
    case GET_SCHOOL_DISPATCH_CLEAR:
      return {
        ...state,
        getSchoolDispatch: null,
        error: action.payload,
      };
    case SET_CALENDAR_VIEW:
      return {
        ...state,
        calendarView: action.payload,
      };
    case SET_CALENDAR_VIEW_CLEAR:
      return {
        ...state,
        calendarView: null,
      };
    default:
      return state;
  }
};
