import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
function withRouterHook(Component) {
  return function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component navigate={navigate} location={location} {...props} />;
  };
}
class RegisterWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  back = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.webPage === "/registerPassword") {
      this.props.navigate("/confirmPhone");
    } else if (this.props.webPage === "/confirmPhone") {
      this.props.navigate("/registerPhone");
    } else if (this.props.webPage === "/registerPhone") {
      this.props.navigate("/confirmEmail");
    } else if (this.props.webPage === "/confirmEmail") {
      this.props.navigate("/registerEmail");
    } else if (this.props.webPage === "/registerEmail") {
      this.props.navigate("/registerName");
    } else {
      this.props.navigate(-1);
    }
  };

  render() {
    const { webPage } = this.props;
    return (
      <div className="register-wizard">
        <div className="container-sect">
          <div className="nav-buttons">
            <Link
              to={"#"}
              onClick={(event) => {
                this.back(event);
              }}
              className="previous-steps"
            >
              <i className="fas fa-arrow-left"></i>
              {this.props.t("regCardScr-preStep")}
            </Link>
            <button
              onClick={() => {
                this.props.navigate("/");
              }}
              className="btn btn-action close-btn2"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>

          {/* <nav className="form-nav">
          <ul className="pagination">
            <li
              className={`page-item  ${
                webPage === "/registerName" ? "active" : "previous"
              } name`}
            >
              <Link className="page-link" to={"#"}>
                <span className="icon">
                  <i className="far fa-user"></i>
                </span>
                <label>{this.props.t("regCardScr-name")}</label>
              </Link>
            </li>
            <li
              className={`page-item   ${
                webPage === "/registerName"
                  ? "next"
                  : webPage === "/registerEmail" || webPage === "/confirmEmail"
                  ? "active"
                  : "previous"
              }  email`}
            >
              <Link className="page-link" to={"#"}>
                <span className="icon">
                  <i className="far fa-envelope-open"></i>
                </span>
                <label>{this.props.t("regCardScr-email")}</label>
              </Link>
            </li>
            <li
              className={`page-item  ${
                webPage === "/registerName" ||
                webPage === "/registerEmail" ||
                webPage === "/confirmEmail"
                  ? "next"
                  : webPage === "/registerPhone" || webPage === "/confirmPhone"
                  ? "active"
                  : "previous"
              }  phone`}
            >
              <Link className="page-link" to={"#"}>
                <span className="icon">
                  <i className="fas fa-phone-alt"></i>
                </span>
                <label>{this.props.t("regCardScr-phone")}</label>
              </Link>
            </li>
            <li
              className={`page-item ${
                webPage === "/registerName" ||
                webPage === "/registerEmail" ||
                webPage === "/confirmEmail" ||
                webPage === "/registerPhone" ||
                webPage === "/confirmPhone"
                  ? "next"
                  : webPage === "/registerPassword"
                  ? "active"
                  : "previous"
              }  password`}
            >
              <Link className="page-link" to={"#"}>
                <span className="icon">
                  <i className="fas fa-lock"></i>
                </span>
                <label>{this.props.t("regCardScr-password")}</label>
              </Link>
            </li>
          </ul>
        </nav> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default withTranslation()(
  withRouterHook(connect(mapStateToProps, mapDispatchToProps)(RegisterWizard))
);
