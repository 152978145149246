import React from "react";
import { withTranslation } from "react-i18next";
import RingLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import { showModal, hideModal } from "../../common/actions/userAccount";
import {
  delegationCancel,
  delegationCancelClear,
  removeMultipleDelegations,
  removeMultipleDelegationsClear,
  relatedDelegationsDetails,
  relatedDelegationsClear,
} from "../../common/actions/students";
import moment from "moment";

import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";

class CancelPickup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedDelegations: null,
    };
  }
  componentDidMount() {
    this.getRelatedDelegationsDetails();
  }

  getRelatedDelegationsDetails = () => {
    const { cancelpickups } = this.props;
    const { doRelatedDelegationsDetails } = this.props;
    let calendarId = cancelpickups.CalendarID;

    if (calendarId != null) {
      doRelatedDelegationsDetails({ calendarId });
    }
  };
  cancelAllOcurence = () => {
    const { doRemoveMultipleDelegations } = this.props;
    const { relatedDelegations } = this.state;
    const { cancelpickups } = this.props;
    const calenderIds = [];
    let CalendarID;
    let result = [];
    let calendarId = cancelpickups.CalendarID;
    if (relatedDelegations) {
      const relatedCalendarIds = relatedDelegations.CalendarItems;
      result = relatedCalendarIds.filter(
        (item) => item.CalendarID >= calendarId
      );
    }
    for (let i = 0; i < result.length; i++) {
      CalendarID = result[i].CalendarID;
      calenderIds.push({ CalendarID });
    }
    doRemoveMultipleDelegations({
      calendarId: calenderIds,
    });
    this.props.doHideModal();
  };
  cancelSingleDelegation = () => {
    const { docancelDelegation } = this.props;
    const { cancelpickups } = this.props;

    let cancelDate = moment(cancelpickups.CalendarDate).format("MM-D-YYYY");

    docancelDelegation({
      calendarId: cancelpickups.CalendarID,
      cancelToDate: cancelDate,
    });
    this.props.doHideModal();
  };

  showConfirmation = () => {
    const { cancelpickups } = this.props;
    const { relatedDelegations } = this.state;
    const dates = [];
    let calendarDates;
    let result = [];
    let calendarId = cancelpickups.CalendarID;
    if (relatedDelegations) {
      const relatedCalendarIds = relatedDelegations.CalendarItems;
      result = relatedCalendarIds.filter(
        (item) => item.CalendarID >= calendarId
      );
    }
    for (let i = 0; i < result.length; i++) {
      calendarDates = result[i].CalendarDate;
      dates.push(calendarDates);
    }
    let lastDate = dates[dates.length - 1];

    const isSingleCancel =
      cancelpickups.IsRecurring === false ||
      cancelpickups.isrecurring === false ||
      ((cancelpickups.IsRecurring || cancelpickups.isrecurring) &&
        moment(lastDate).format("MMM-DD-YYYY") ===
          moment(cancelpickups.CalendarDate).format("MMM-DD-YYYY"));

    const calc = (
      <div className="modal-dialog modal-lg cancelPickup-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">
                {this.props.t("Cancel_Pickup_Change")}
              </h4>
              {relatedDelegations ? (
                <p>
                  {isSingleCancel
                    ? this.props.t("Change_Single_Pickup") +
                      " " +
                      moment(cancelpickups.CalendarDate).format("MMM-DD-YYYY")
                    : this.props.t("Change_Multiple_Pickup1") +
                      " " +
                      moment(cancelpickups.CalendarDate).format("MMM-DD-YYYY") +
                      " " +
                      this.props.t("Change_Multiple_Pickup2") +
                      " " +
                      moment(cancelpickups.CalendarDate).format("MMM-DD-YYYY") +
                      " " +
                      this.props.t("to") +
                      " " +
                      moment(lastDate).format("MMM-DD-YYYY")}
                </p>
              ) : (
                <RingLoader color="#2d3d56" loading={true} size={60} />
              )}
            </div>
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>
          <div className="cancel-actions">
            <button
              type="button"
              className="btn btn-default"
              onClick={this.cancelSingleDelegation}
            >
              {this.props.t("Cancel_Pickup_Label")}
            </button>
            {relatedDelegations && !isSingleCancel ? (
              <button
                type="button"
                className="btn btn-default"
                onClick={this.cancelAllOcurence}
              >
                {this.props.t("Cancel_All_Occurence_Label")}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );

    return calc;
  };

  closeModal = () => {
    this.props.doHideModal();
  };

  componentDidUpdate() {
    const { doRelatedDelegationsClear, relatedDelegations } = this.props;
    if (relatedDelegations) {
      this.setState({ relatedDelegations }, doRelatedDelegationsClear);
    }
  }
  render() {
    return this.showConfirmation();
  }
}

function mapStateToProps(state) {
  return {
    modalScreenName: state.userAccount.modalScreenName,
    modalClassName: state.userAccount.modalClassName,
    delegationCancelResponse: state.student.delegationCancelResponse,
    relatedDelegations: state.student.relatedDelegations,
    deleteMultipleDelegations: state.student.deleteMultipleDelegations,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doRelatedDelegationsDetails: (data) =>
      dispatch(relatedDelegationsDetails(data)),

    doRelatedDelegationsClear: () => dispatch(relatedDelegationsClear()),
    doRemoveMultipleDelegations: (data) =>
      dispatch(removeMultipleDelegations(data)),
    doRemoveMultipleDelegationsClear: () =>
      dispatch(removeMultipleDelegationsClear()),
    docancelDelegation: (data) => dispatch(delegationCancel(data)),
    doCancelDelegationClear: () => dispatch(delegationCancelClear()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CancelPickup)
);
