import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarAlt } from "@fortawesome/free-solid-svg-icons";

import { Line } from "rc-progress";
import moment from "moment";
import {
  earlyDismissNeedPassword,
  parseDaytime,
} from "../../../../helper/utility";

import {
  earlyDismissalSubmit,
  changeDelegation,
  changeDelegationClear,
  earlyDismissalClear,
} from "../../../../common/actions/students";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import {
  showModal,
  hideModal,
  setHelpList,
} from "../../../../common/actions/userAccount";
import GLOBAL_CONST from "../../../../helper/AppConstants";
import { createLoadingSelector } from "../../../../common/reducers/selectors";
const loadingSelector = createLoadingSelector(["early_dismissal"]);

class MultikidsEarlyDismissalPickup extends React.Component {
  constructor(props) {
    super(props);

    this.inputRefs = {
      selectedOptionPickupMode: null,
      selectedOptionPickupBy: this.props.t("Select_Me_Dismissal_Label"),
      selectedSubPickupModeOption: null,
      selectedBusRouteOption: null,
    };

    const howWillPickupOptions = [
      {
        label: `${this.props.t("Select_Contact_Dismissal_Label")}`,
        value: `${this.props.t("Select_Contact_Dismissal_Label")}`,
      },
    ];
    this.state = {
      selectedOptionPickupMode: null,
      selectedOptionPickupBy: this.props.t("Select_Me_Dismissal_Label"),
      previousSelectedOptionsPickupMode: null,
      previousSelectedOptionsPickupBy: null,
      selectedSubPickupModeOption: null,
      previousSelectedSubpickupModeOption: null,
      selectedBusRouteOption: null,
      previousSelecteBusRouteOption: null,
      gaurdianDetails: null,
      selectChild: false,

      howWillPickupOptions,
      isSuccessPrompt: false,
      password: "",
    };
  }
  componentDidMount() {
    this.props.onRef(this);
    this.dosetHelpList();
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "ChildManagement",
      screen: GLOBAL_CONST.SCREEN_NAMES.DISMISSAL_HOW,
      title: this.props.t("dismSelectTypeScr-newDism"),
    });
  };
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  setSelectedDelegate = (item, contactFlag, selectedPhone) => {
    let { gaurdianDetails, selectedOptionPickupBy } = this.state;
    const howWillPickupOptions = [...this.state.howWillPickupOptions];
    if (!item) {
      this.setState({ selectedOptionPickupBy: "Select dismissal" });
      return;
    }
    if (contactFlag === true) {
      selectedOptionPickupBy = `${item.givenName} ${""} ${
        item.familyName ? item.familyName : ""
      }`;

      gaurdianDetails = {
        Firstname: item.givenName,
        Lastname: item.familyName ? item.familyName : "",
        PhoneNumber: selectedPhone.number ? selectedPhone.number : "",
      };
    } else {
      selectedOptionPickupBy = `${item.Firstname} ${""} ${item.Lastname}`;
      gaurdianDetails = item;
    }

    const contactRecord = {
      label: selectedOptionPickupBy,
      value: selectedOptionPickupBy,
    };

    const exists = howWillPickupOptions.filter(
      (item) => item.value === contactRecord.value
    );

    if (exists.length === 0) howWillPickupOptions.push(contactRecord);

    this.setState({
      gaurdianDetails,
      selectedOptionPickupBy,
      howWillPickupOptions,
    });
  };

  selectedChild = () => {
    this.setState({ selectChild: !this.state.selectChild });
  };

  canAssignParentAllowed = () => {
    return true;
  };
  submitForm = () => {
    const { gaurdianDetails, selectedOptionPickupBy, password, otp } =
      this.state;

    const selectedDate = this.props.selectedDate;
    const pickedTime = this.props.pickedTime;
    const disTime = parseDaytime(moment(pickedTime).format("h:mm a"));
    const dismissalDate = new Date(selectedDate);
    dismissalDate.setHours(disTime[0]);
    dismissalDate.setMinutes(disTime[1]);
    dismissalDate.setSeconds(0);

    const selectedKids = this.props.selectedKids;
    let selectedkidArr = [];
    if (selectedKids) {
      selectedKids.filter((kid) => {
        selectedkidArr.push(kid.StudentID);
      });
    }

    let params = {};
    params = {
      StudentIds: selectedkidArr,
      SchoolId: selectedKids[0].SchoolID,
      DismissalTime: moment(dismissalDate).format("YYYY-MM-DD HH:mm:ss"),
      isDelegated: false,
      delegate: {
        firstName: "",
        lastName: "",
        contactNumber: "",
      },
    };

    if (
      this.canAssignParentAllowed() &&
      selectedOptionPickupBy !== this.props.t("Select_Me_Dismissal_Label") &&
      gaurdianDetails
    ) {
      params.isDelegated = true;
      params.delegate = {
        firstName: gaurdianDetails.Firstname,
        lastName: gaurdianDetails.Lastname,
        contactNumber: gaurdianDetails.PhoneNumber,
      };
    }

    const school = this.props.school;
    const isPasswordRequired = earlyDismissNeedPassword(school);
    if (isPasswordRequired) {
      params.password = password;
      params.OtpCode = otp;
    }
    // console.log("doEarlyDismissalSubmit " + JSON.stringify(params));
    const { doEarlyDismissalSubmit } = this.props;
    doEarlyDismissalSubmit(params);
  };

  openContact = (value) => {
    if (value === this.props.t("Select_Contact_Dismissal_Label")) {
      this.props.doShowModal({
        modalScreenName: "SelectContact",
        modalClassName: "contact-list-modal sidebar-modal right",
        params: {
          setSelectedDelegate: this.setSelectedDelegate,
          manageDelegate: false,
        },
      });
    }
  };

  componentDidUpdate() {
    const {
      error,
      earlyDismissalResponse,
      doearlyDismissalClear,
      doHideModal,
      doCallbackRefresh,
    } = this.props;

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doearlyDismissalClear();
      this.setState({ otp: "", password: "" });
    }
    if (earlyDismissalResponse) {
      toast.success(earlyDismissalResponse.Data[0].Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      this.setState({ otp: "", password: "" }, () => {
        this.props.doHideModal();
        doHideModal();
      });

      doearlyDismissalClear();
      if (doCallbackRefresh) doCallbackRefresh();
    }
  }

  render() {
    const { gaurdianDetails } = this.state;

    return (
      <div className="card form-card">
        <div className="form-card-title">
          <div className="fc-titletext-icon">
            <FontAwesomeIcon className="title-icon" icon={faCarAlt} />
            <span className="text">{this.props.t("Select_How_Label")}</span>
          </div>
          <span className="probressbar-holder">
            <Line percent={100} strokeWidth="1" strokeColor="#7ac943" />
          </span>
        </div>
        <form className="form form-style2">
          <div className="form-group">
            <label className="control-label">
              {this.props.t("whowillpickup_text")}
            </label>
            <div
              onClick={() => {
                this.setState({
                  selectedOptionPickupBy: this.props.t(
                    "Select_Me_Dismissal_Label"
                  ),
                });
              }}
              className={
                this.state.selectedOptionPickupBy ===
                this.props.t("Select_Me_Dismissal_Label")
                  ? "custom-radio  mb-20 checked"
                  : "custom-radio  mb-20"
              }
            >
              <input type="radio" id="iWillPickupBy" name="whoWillPickupBy" />
              <label htmlFor="iWillPickupBy"></label>
              <span className="radio-label-text">
                {this.props.t("IWillPickup")}
              </span>
            </div>

            <div
              onClick={() => {
                if (gaurdianDetails !== null && gaurdianDetails !== undefined) {
                  this.setState({
                    selectedOptionPickupBy: `${
                      gaurdianDetails.Firstname
                    } ${""} ${gaurdianDetails.Lastname}`,
                  });
                }
                this.openContact(
                  this.props.t("Select_Contact_Dismissal_Label")
                );
              }}
              className={
                this.state.selectedOptionPickupBy !==
                this.props.t("Select_Me_Dismissal_Label")
                  ? "custom-radio  rightArrowLabel mb-20 checked"
                  : "custom-radio rightArrowLabel mb-20"
              }
            >
              <div className="flex-center">
                <input
                  type="radio"
                  id="selectFromContact"
                  name="whoWillPickupBy"
                />
                <label htmlFor="selectFromContact"></label>
                <span className="radio-label-text">
                  {this.props.t("change_selection_text")}
                </span>
              </div>

              {this.state.selectedOptionPickupBy ===
              this.props.t("Select_Me_Dismissal_Label") ? null : (
                <div className="contact-item">
                  {gaurdianDetails.PhotoUrl ? (
                    <div className="contact-figure">
                      <span className="contact-img border-outline">
                        <img
                          src={gaurdianDetails.PhotoUrl}
                          alt={`${gaurdianDetails.Firstname} ${""} ${
                            gaurdianDetails.Lastname
                          }`}
                          title={`${gaurdianDetails.Firstname} ${""} ${
                            gaurdianDetails.Lastname
                          }`}
                          width={54}
                          height={54}
                        />
                      </span>
                    </div>
                  ) : null}
                  <div className="contact-info">
                    <span className="contact-name">
                      {`${gaurdianDetails.Firstname} ${""} ${
                        gaurdianDetails.Lastname
                      }`}
                    </span>
                    <span className="contact-no">
                      {gaurdianDetails.PhoneNumber}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    changeDelegationResponse: state.student.changeDelegationResponse,
    userLoginInfo: state.userAccount.userLoginInfo,
    error: state.student.error,
    setCreatePickupButtonsUI: state.userAccount.setCreatePickupButtonsUI,
    setHelpListData: state.userAccount.setHelpListData,
    isFetching: loadingSelector(state),
    earlyDismissalResponse: state.student.earlyDismissalResponse,
    ssoUserGenerateOTPResponse: state.userAccount.ssoUserGenerateOTPResponse,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doChangeDelegation: (data) => dispatch(changeDelegation(data)),
    doChangeDelegationClear: () => dispatch(changeDelegationClear()),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doEarlyDismissalSubmit: (data) => dispatch(earlyDismissalSubmit(data)),
    doearlyDismissalClear: () => dispatch(earlyDismissalClear()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(MultikidsEarlyDismissalPickup)
);
