import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import QrReader from "react-qr-reader";

import {
  studentWithConnect,
  studentWithConnectClear,
  showModal,
  hideModal,
  setHelpList,
} from "../../common/actions/userAccount";
import { Slide, ToastContainer, toast } from "react-toastify";

class ScanBarCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scanDone: false,
      scanResult: null,
      localError: "",
    };
    this.handleScan = this.handleScan.bind(this);
  }
 

  handleScan(data) {
    this.setState({
      result: data,
    });
    this.onBarCodeRead(data);
  }
  handleError(err) {
    console.error(err);
  }

  onBarCodeRead(scanResult) {
    const { scanDone } = this.state;
    if (scanDone) return;
    if (scanResult !== null) {
      if (
        scanResult.includes("com.pikmykid.parentapp") ||
        scanResult.includes("id1559538995")
      ) {
        this.setState({
          localError: this.props.t("ScanSrc-AppStoreScan"),
          scanDone: true,
        });
      } else if (scanResult.includes("http") || scanResult.includes("www")) {
        this.setState({
          localError: this.props.t("ScanSrc-WebPageScan"),
          scanDone: true,
        });
      } else if (this.isNumber(scanResult)) {
        this.setState({
          localError: this.props.t("ScanSrc-CarTagScan"),
          scanDone: true,
        });
      } else {
        this.setState(
          {
            scanDone: true,
            scanResult,
          },
          this.processScan
        );
      }
    }
  }
  isNumber(num) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(num);
  }
  processScan = () => {
    const { scanResult } = this.state;
    this.props.doStudentWithConnect({
      Code: scanResult,
    });
  };

  componentDidUpdate() {
    const {
      error,
      studentConnectResponse,
      doStudentWithConnectClear,
      doCallbackRefresh,
    } = this.props;

    const { localError } = this.state;
    if (error || localError !== "") {
      toast.error(localError !== "" ? localError : error.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      this.props.doHideModal();
      if (doCallbackRefresh) doCallbackRefresh();
      doStudentWithConnectClear();
    }
    if (studentConnectResponse !== null) {
      toast.success(studentConnectResponse.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doStudentWithConnectClear();
      this.props.doHideModal();
      if (doCallbackRefresh) doCallbackRefresh();
    }
  }

  render() {
    const previewStyle = {
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100vh",
    };
    return (
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="modal-header text-center">
              <p className="mb-20">{this.props.t("add_child_message")}</p>
              <button
                type="button"
                className="close-scanner-btn"
                onClick={() => {
                  this.closeModal();
                }}
                aria-label={this.props.t("Close_Button")}
              >
                <FontAwesomeIcon icon={faWindowClose} />
              </button>
            </div>

            <div className="scanbar-holder">
              <QrReader
                className="qr-image-wrapper"
                delay={300}
                style={previewStyle}
                onError={this.handleError}
                onScan={this.handleScan}
                facingMode="environment"
                showViewFinder={true}
              />

              <p>{this.state.result}</p>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  }

  closeModal = () => {
    this.props.doHideModal();
  };
}
function mapStateToProps(state) {
  return {
    error: state.userAccount.error,
    studentConnectResponse: state.userAccount.studentConnectResponse,
    setHelpListData: state.userAccount.setHelpListData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doStudentWithConnect: (data) => dispatch(studentWithConnect(data)),
    doStudentWithConnectClear: () => dispatch(studentWithConnectClear()),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ScanBarCode)
);
