import userAccountReducer from "./userAccountReducer";
import loadingReducer from "./loadingReducer";
import schoolReducer from "./schoolReducer";
import studentReducer from "./studentReducer";
import checkinReducer from "./checkinReducer";
import adsReducer from "./adsReducer";
import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

const createRootReducer = (history) =>
  combineReducers({
    userAccount: userAccountReducer,
    loading: loadingReducer,
    school: schoolReducer,
    student: studentReducer,
    checkin: checkinReducer,
    ads: adsReducer,
    router: connectRouter(history),
  });

export default createRootReducer;
