import GLOBAL_CONST from "../helper/AppConstants";
import { getCurrentThemeColor } from "../helper/utility";

export const getMyStyle = (selectedTheme) => {
  const selectedThemeColor = getCurrentThemeColor(selectedTheme);

  const mystyle = {
    noBoxShadow: {
      boxShadow: "none",
    },
    titleStyle2: {
      color: selectedThemeColor.title2,
    },
    helpTxt: {
      color: selectedThemeColor.helpTxt,
    },
    navTabs: {
      // paddingLeft: 0,
      background: selectedThemeColor.navTabs,
      color: selectedThemeColor.helpTxt,
      borderRightColor: selectedThemeColor.navTabSep,

      // borderRightColor: "red"
    },
    tabHolder: {
      borderBottomColor: selectedThemeColor.tabHolder,
    },
    buttonDefault: {
      backgroundColor: selectedThemeColor.buttonDefault,
      borderColor: selectedThemeColor.buttonDefault,
    },
  };
  return mystyle;
};
