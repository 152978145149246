import React from "react";
import Modal from "react-modal";
import { setIsOpen } from "react-modal";
import { CheckinQuestions } from "../Checkin";
import { DismissalTimes, ChildCardAnnounce, SchoolDetails } from "../Dashboard";
import { ScanBarCode } from "../Scanner";
import {
  SelectContact,
  AddContact,
  FutureDelegation,
  CancelPickup,
  BackToSchool,
  BackToSchoolKidList,
  PasswordOtpEntry,
} from "../ChildManagement";
import { SchoolCalendar, EventDetails } from "../SchoolCalendar";
import { connect } from "react-redux";

import { showModal, hideModal } from "../../common/actions/userAccount";
import {
  Setting,
  NotificationsList,
  ChangePassword,
  ContactSupport,
  ViewProfile,
} from "../Settings";
import { HelpListing, HelpDetails } from "../Help";
import ImageCrop from "./ImageCrop";
import CameraLauncher from "./CameraLauncher";
import {
  MultikidsSelectWhen,
  MultikidsCreateMarkAbsent,
  MultikidsChangeDefaultPickup,
  MultikidsCreateEarlyDismissal,
} from "../ChildManagement/MultiSelectKids";
import ServerSelect from "./ServerSelect";
import ThemeSelection from "../Settings/ThemeSelection";
import { DeleteAccountModal } from "../UserAccount/DeleteAccount";

Modal.setAppElement("#root");

class BootstrapModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ImageCrop = React.createRef();
  }

  openModal() {
    setIsOpen(true);
  }

  getModalChildren = (modelInfo) => {
    switch (modelInfo.modalScreenName) {
      case "NotificationsList":
        return <NotificationsList></NotificationsList>;
      case "ViewProfile":
        return <ViewProfile></ViewProfile>;
      case "ChangePassword":
        return <ChangePassword></ChangePassword>;
      case "Settings":
        return <Setting></Setting>;
      case "SelectContact":
        return <SelectContact {...modelInfo.params}></SelectContact>;
      case "AddContact":
        return <AddContact {...modelInfo.params}></AddContact>;
      case "EditContact":
        return <AddContact {...modelInfo.params}></AddContact>;
      case "ContactSupport":
        return <ContactSupport></ContactSupport>;
      case "HelpListing":
        return <HelpListing {...modelInfo.params}></HelpListing>;
      case "HelpDetails":
        return <HelpDetails {...modelInfo.params}></HelpDetails>;
      case "schoolDetails":
        return <SchoolDetails {...modelInfo.params}></SchoolDetails>;
      case "dismissalTimes":
        return <DismissalTimes {...modelInfo.params}></DismissalTimes>;
      case "CheckInQuestions":
        return <CheckinQuestions></CheckinQuestions>;
      case "cancelThisPickup":
        return <CancelPickup {...modelInfo.params}></CancelPickup>;
      case "futureDelegation":
        return <FutureDelegation {...modelInfo.params}></FutureDelegation>;

      case "childCardAnnounceFor":
        return <ChildCardAnnounce {...modelInfo.params}></ChildCardAnnounce>;
      case "scanBarCode":
        return <ScanBarCode {...modelInfo.params}></ScanBarCode>;
      case "backToSchoolKidList":
        return (
          <BackToSchoolKidList {...modelInfo.params}></BackToSchoolKidList>
        );
      case "backToSchool":
        return <BackToSchool {...modelInfo.params}></BackToSchool>;
      case "schoolCalendar":
        return <SchoolCalendar {...modelInfo.params}></SchoolCalendar>;
      case "eventDetails":
        return <EventDetails {...modelInfo.params}></EventDetails>;
      case "imageCropper":
        return (
          <ImageCrop
            onRef={(ref) => (this.ImageCrop = ref)}
            {...modelInfo.params}
          ></ImageCrop>
        );
      case "cameraLauncher":
        return <CameraLauncher {...modelInfo.params}></CameraLauncher>;
      case "passwordOtpEntry":
        return <PasswordOtpEntry {...modelInfo.params}></PasswordOtpEntry>;

      case "multikidsSelectWhen":
        return (
          <MultikidsSelectWhen {...modelInfo.params}></MultikidsSelectWhen>
        );
      case "multikidsCreateMarkAbsent":
        return (
          <MultikidsCreateMarkAbsent
            {...modelInfo.params}
          ></MultikidsCreateMarkAbsent>
        );

      case "multikidsChangeDefaultPickup":
        return (
          <MultikidsChangeDefaultPickup
            {...modelInfo.params}
          ></MultikidsChangeDefaultPickup>
        );
      case "multikdsEarlyDismissal":
        return (
          <MultikidsCreateEarlyDismissal
            {...modelInfo.params}
          ></MultikidsCreateEarlyDismissal>
        );
      case "selectServer":
        return <ServerSelect {...modelInfo.params}> </ServerSelect>;

      case "ThemeSelection":
        return <ThemeSelection></ThemeSelection>;

      case "deleteAccountModal":
        return <DeleteAccountModal {...modelInfo.params}></DeleteAccountModal>;

      default:
        <div></div>;
    }
  };
  render() {
    const { modalLevelInfo, myLevel } = this.props;
    let showModal = false;
    let modelInfo;
    if (modalLevelInfo && modalLevelInfo[myLevel]) {
      showModal = true;
      modelInfo = modalLevelInfo[myLevel];
    }

    return (
      <Modal
        isOpen={showModal}
        className={`react-modal ${
          modelInfo && modelInfo.modalClassName
        } modalLevel-${myLevel}`}
      >
        {modelInfo && this.getModalChildren(modelInfo)}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    modalLevelInfo: state.userAccount.modalLevelInfo,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BootstrapModal);
