import React from "react";
import { connect } from "react-redux";
import { setCalendarView } from "../../common/actions/students";

class CustomListGridButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGrid: this.props.isGrid,
    };
  }

  componentDidMount() {
    const { doSetCalendarView, isGrid } = this.props;
    doSetCalendarView({ value: isGrid });
  }

  renderContent = () => {
    const { isGrid } = this.state;
    const { doSetCalendarView } = this.props;

    return (
      <div className="nav-buttons">
        <button
          type="button"
          className={!isGrid ? "btn action-btn active" : "btn action-btn"}
          onClick={() => {
            if (isGrid) {
              this.setState({ isGrid: !isGrid }, () => {
                doSetCalendarView({ value: !isGrid });
              });
            }
          }}
        >
          <i className="fas fa-list"></i>
        </button>
        <button
          type="button"
          className={isGrid ? "btn action-btn active" : "btn action-btn"}
          onClick={() => {
            if (!isGrid) {
              this.setState({ isGrid: !isGrid }, () => {
                doSetCalendarView({ value: !isGrid });
              });
            }
          }}
        >
          <i className="fas fa-calendar-alt"></i>
        </button>
      </div>
    );
  };
  render() {
    const { isGrid } = this.state;
    return this.renderContent();
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    doSetCalendarView: (data) => dispatch(setCalendarView(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomListGridButton);
