import React from "react";
import { connect } from "react-redux";

import {
  getSchoolStudentCheckIn,
  submitCheckInByParent,
  submitCheckInByParentClear,
} from "../../common/actions/checkin";

import { withTranslation } from "react-i18next";
import {
  showCheckinQuestAns,
  setHelpList,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";

import "react-confirm-alert/src/react-confirm-alert.css";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckinQuestions from "./CheckinQuestions";
import moment from "moment";
import { getSchoolDetails } from "../../common/actions/students";

let params = {};
class SelectCheckInFor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCheckinView: false,
      showSchoolChildrenList: true,
    };
  }

  componentDidMount() {
    setTimeout(this.checkCanCheckin, 100);
    this.dosetHelpList();
  }

  getStudents = () => {
    const { doGetStudentsList } = this.props;
    let currenDate = moment().format("MM-DD-YYYY");
    doGetStudentsList({ currenDate });
  };
  getSchool = (callback) => {
    const { doGetSchoolDetails } = this.props;
    doGetSchoolDetails().then(() => {
      callback();
    });
  };

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "CheckIn",
      screen: GLOBAL_CONST.SCREEN_NAMES.CHECKIN_FOR,
      title: this.props.t("selectCheckScr-schoolCheck"),
    });
  };

  checkCanCheckin = () => {
    const { doeGetSchoolStudentCheckIn } = this.props;
    const childInfo = this.props.school;
    const children = childInfo.data;
    const studentIds = [];

    for (let i = 0; i < children.length; i++) {
      studentIds.push(children[i].StudentID);
    }
    doeGetSchoolStudentCheckIn({
      schoolID: childInfo.schoolId,
      studentIDs: studentIds,
    });
  };

  refreshFunction = () => {
    this.hideQuestionAnswerView();
    this.checkCanCheckin();
  };
  hideQuestionAnswerView = () => {
    this.props.doShowCheckinQuestAnsView({
      status: false,
      showSchoolChildrenList: true,
      params: {},
    });
  };
  showQuestionAnswerView = (params) => {
    this.props.doShowCheckinQuestAnsView({
      status: true,
      showSchoolChildrenList: false,
      params: params,
    });
  };

  renderChildren = (item, questionsForChildren, canCheckInSchoolChild) => {
    const checkInChild = [];
    let childrenList = [];

    if (!canCheckInSchoolChild || !canCheckInSchoolChild.CanCheckInStudentIDs) {
      return <div />;
    }

    let canCheckInStudentIDs = canCheckInSchoolChild.CanCheckInStudentIDs;

    childrenList = item.filter((item) => item.IsDelegation === false);

    for (let i = 0; i < childrenList.length; i++) {
      const kidCheckedIn = canCheckInStudentIDs.includes(
        parseInt(childrenList[i].StudentID, 10)
      );
      // console.log(
      //   "kidCheckedIn kidCheckedIn",
      //   kidCheckedIn,
      //   childrenList[i].StudentID
      // );

      checkInChild.push(
        <div
          key={
            "checkRec-" + this.props.school.schoolID + childrenList[i].StudentID
          }
          id={childrenList[i].StudentID + "-" + kidCheckedIn}
          className={`list-item child-title ${
            !kidCheckedIn ? "checkedIn" : " "
          }`}
          onClick={
            kidCheckedIn === true && questionsForChildren === true
              ? () => {
                  this.showQuestionAnswerView(
                    (params = {
                      record: childrenList[i],
                      parentCheckIn: false,
                      doCallbackRefresh: this.refreshFunction,
                    })
                  );
                }
              : kidCheckedIn === true && questionsForChildren === false
              ? () => {
                  this.submitForm(childrenList[i]);
                }
              : () => {
                  toast.error(
                    `${childrenList[i].Firstname} ${
                      childrenList[i].Lastname
                    } ${this.props.t("Already_Check-in_Alert")}`,
                    {
                      autoClose: 2000,
                      transition: Slide,
                      hideProgressBar: true,
                      icon: false,
                    }
                  );
                }
          }
        >
          <span className="label">{`${childrenList[i].Firstname}${" "}${
            childrenList[i].Lastname
          }`}</span>
        </div>
      );
    }
    return checkInChild;
  };

  submitForm = (record) => {
    const { doSubmitCheckInByParent } = this.props;
    let childInfo = this.props.school;
    let isParenFlag, schoolID, studentID;
    schoolID = childInfo.schoolId;

    if (record != undefined) {
      studentID = [record.StudentID];
      isParenFlag = false;
    } else {
      studentID = [];
      isParenFlag = true;
    }

    doSubmitCheckInByParent({
      schoolID,
      checkInParent: isParenFlag,
      studentIDs: studentID,
      questionsAndAnswers: [],
    });
  };

  componentDidUpdate() {
    const { CheckInByParentResponse, error, doSubmitCheckInByParentClear } =
      this.props;

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doSubmitCheckInByParentClear();
    } else if (CheckInByParentResponse) {
      toast.success(this.props.t("Submit_Checkin"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      this.refreshFunction();

      doSubmitCheckInByParentClear();
    }
  }

  render() {
    const { showCheckinView, showSchoolChildrenList } = this.state;
    const { canCheckin, userLoginInfo } = this.props;
    let childInfo = this.props.school;
    let parentCanCheckIn = false,
      studentCanCheckIn = false,
      parentCurrentlyCheckedIn = false;
    let questionsForParents = false,
      questionsForChildren = false;

    let canCheckInSchoolChild;

    if (canCheckin && canCheckin[childInfo && childInfo.schoolId]) {
      canCheckInSchoolChild = { ...canCheckin[childInfo.schoolId] };
      parentCanCheckIn = canCheckInSchoolChild.ParentsCanCheckIn;
      studentCanCheckIn = canCheckInSchoolChild.StudentsCanCheckIn;
      parentCurrentlyCheckedIn = canCheckInSchoolChild.ParentCurrentlyCheckedIn;
      questionsForParents = canCheckInSchoolChild.QuestionsForParents;
      questionsForChildren = canCheckInSchoolChild.QuestionsForChildren;
    }

    return (
      <div>
        {showSchoolChildrenList === true && (
          <div className="children-checkin-list">
            {parentCanCheckIn === true ? (
              <div
                className={
                  parentCurrentlyCheckedIn === true
                    ? "list-item self-checkin checkedIn"
                    : "list-item self-checkin"
                }
                onClick={
                  parentCurrentlyCheckedIn === true
                    ? () => {
                        toast.error(
                          ` ${userLoginInfo.firstName} ${
                            userLoginInfo.lastName
                          } ${this.props.t("Already_Check-in_Alert")}`,
                          {
                            autoClose: 2000,
                            transition: Slide,
                            hideProgressBar: true,
                            icon: false,
                          }
                        );
                      }
                    : questionsForParents === true
                    ? () => {
                        this.showQuestionAnswerView(
                          (params = {
                            schoolId: childInfo.schoolId,
                            parentCheckIn: true,
                            parentData: userLoginInfo,
                            doCallbackRefresh: this.refreshFunction,
                          })
                        );
                      }
                    : () => {
                        this.submitForm();
                      }
                }
              >
                <span className="label">
                  {this.props.t("selectCheckScr-visit")}
                </span>
              </div>
            ) : null}
            <div className="list-sep-title youchildren-title ">
              <span className="label">
                {this.props.t("selectCheckScr-children")}
              </span>
            </div>

            {studentCanCheckIn === true
              ? this.renderChildren(
                  childInfo.data,
                  questionsForChildren,
                  canCheckInSchoolChild
                )
              : null}
          </div>
        )}
        <div>
          {showCheckinView === true && <CheckinQuestions {...params} />}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    canCheckin: state.checkin.canCheckin,
    CheckInByParentResponse: state.checkin.CheckInByParentResponse,
    error: state.checkin.error,

    checkInQuestAnsView: state.userAccount.checkInQuestAnsView,
    setHelpListData: state.userAccount.setHelpListData,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doeGetSchoolStudentCheckIn: (data) =>
      dispatch(getSchoolStudentCheckIn(data)),
    doGetSchoolDetails: () => dispatch(getSchoolDetails()),
    doSubmitCheckInByParent: (data) => dispatch(submitCheckInByParent(data)),
    doSubmitCheckInByParentClear: () => dispatch(submitCheckInByParentClear()),

    doShowCheckinQuestAnsView: (data) => dispatch(showCheckinQuestAns(data)),

    dosetHelpList: (data) => dispatch(setHelpList(data)),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SelectCheckInFor)
);
