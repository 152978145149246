import React, { useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  setCroppedImage,
  setCroppedImageClear,
  showModal,
  hideModal,
} from "../../common/actions/userAccount";

//Image crop

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";
import "react-image-crop/dist/ReactCrop.css";
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth = Number,
  mediaHeight = Number,
  aspect = Number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
const ImageCrop = React.forwardRef((props, ref) => {
  const [imgSrc, setImgSrc] = useState(URL.createObjectURL(props.imageFile));
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  let [scale, setScale] = useState(1);
  let [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1);
  let cropResult = null;

  const onSelectFile = () => {
    setCrop(undefined); // Makes crop preview update between images.
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result?.toString() || "")
    );
    reader.readAsDataURL(props.imageFile);
  };

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  }
  function getCroppedImg() {
    var filename = new Date().getTime();

    // As a blob
    return new Promise((resolve, reject) => {
      previewCanvasRef.current.toBlob((file) => {
        file.name = filename;
        resolve(file);
      }, "image/jpeg");
    });
  }

  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="scrollY-sm">
          <div className="modal-header">
            <div></div>
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                props.doSetCroppedImageClear();
                props.doHideModal();
              }}
              aria-label={props.t("Close_Button")}
            ></button>
          </div>

          <div className="modal-body">
            <div className="App">
              <div className="Crop-Controls">
                <div className="imagecrop-form">
                  <div className="form-group form-row">
                    <div className="input-group">
                      <input
                        id="scale-input"
                        className="form-control"
                        type="number"
                        step="0.1"
                        value={scale}
                        disabled={!imgSrc}
                        onChange={(e) => setScale(Number(e.target.value))}
                      />

                      <i
                        className="fas fa-expand input-icon"
                        onClick={() => {
                          setScale(Number((scale += 0.1).toFixed(3)));
                        }}
                      ></i>
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control"
                        id="rotate-input"
                        type="number"
                        value={rotate}
                        disabled={!imgSrc}
                        onChange={(e) =>
                          setRotate(
                            Math.min(
                              360,
                              Math.max(-360, Number(e.target.value))
                            )
                          )
                        }
                      />
                      <i
                        className="fas fa-redo input-icon"
                        onClick={() => {
                          setRotate(
                            Number(rotate) === 360
                              ? 0
                              : Math.min(
                                  360,
                                  Math.max(-360, Number((rotate += 90)))
                                )
                          );
                        }}
                      ></i>
                    </div>
                    <div className="input-group">
                      <button
                        type="button"
                        className="btn btn-primary"
                        
                        ref={ref}
                        onClick={async () => {
                          cropResult = await getCroppedImg();

                          props.doSetCroppedImage({
                            croppedImageData: cropResult,
                            showImageCrop: false,
                          });
                          props.doHideModal();
                        }}
                      >
                        {props.t("done")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {!!imgSrc && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
              <div>
                {!!completedCrop && (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

ImageCrop.displayName = "ImageCrop";

function mapStateToProps(state) {
  return {
    croppedImageData: state.userAccount.croppedImageData,
    showImageCrop: state.userAccount.showImageCrop,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doSetCroppedImage: (data) => dispatch(setCroppedImage(data)),
    doSetCroppedImageClear: () => dispatch(setCroppedImageClear()),

    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(ImageCrop)
);
