import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "../CommonComponents/withRouter";

import {
  saveBackToSchoolInformation,
  saveBackToSchoolInformationClear,
} from "../../common/actions/students";
import {
  showModal,
  hideModal,
  saveNextAskMeDate,
  backtoschoolDataInfo,
} from "../../common/actions/userAccount";
import { nextField } from "../../helper/utility";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-image-upload/dist/index.css";
import { createLoadingSelector } from "../../common/reducers/selectors";
import { Dropdown } from "react-bootstrap";
import RingLoader from "react-spinners/ClipLoader";
import pickupKid from "../assets/commonImages/pickupKid.png";
import { SelectRelationship } from "../ChildManagement";
import {
  getfillBacktoschoolinfoData,
  getSchoolDispatch,
  getSchoolDispatchClear,
} from "../../common/actions/students/studentAction";
import { getMyStyle } from "../../theme/ThemeStyle";

const loadingSelector = createLoadingSelector([
  "save_back_school",
  "get_available_dispatch",
]);

const loadingPhotoSelector = createLoadingSelector(["upload_delegate_image"]);
class BackToSchool extends React.Component {
  constructor(props) {
    super(props);

    this.inputRefs = {
      selectedOptionPickupMode: 0,
      selectedSubPickupModeOption: null,
      selectedBusRouteOption: null,
    };

    let backToSchoolInfoData = this.props.backToSchoolInfoData;
    backToSchoolInfoData = { ...backToSchoolInfoData };
    if (
      backToSchoolInfoData === null ||
      backToSchoolInfoData === undefined ||
      Object.keys(backToSchoolInfoData).length === 0
    ) {
      backToSchoolInfoData = this.getBackToSchoolTemplate();
    } else {
      backToSchoolInfoData.StudentID = backToSchoolInfoData.StudentId;
      backToSchoolInfoData.PickupMode = {};
    }

    this.state = {
      backToSchoolInfoData,
      isExpandYourInfo: false,
      isExpandAdditionalParent: false,
      isExpandPickupMode: false,
      isExpandAddress: false,
      isCollapse: true,
      isEditable: false,
      selectedOptionPickupMode: 0,
      previousSelectedOptionsPickupMode: null,
      selectedSubPickupModeOption: null,
      previousSelectedSubpickupModeOption: null,
      selectedBusRouteOption: null,
      previousSelecteBusRouteOption: null,
      pickupModeList: [],
      schoolSubOptions: [],
      busRouteSubOptions: [],
      OtherPickupMode: null,
    };
  }
  componentDidMount() {
    this.getDispatchList();
    const { doGetFillbacktoschoolinfo } = this.props;
    const ChildInfo = this.props.studentData;
    if (ChildInfo != "") {
      doGetFillbacktoschoolinfo({
        studentId: ChildInfo.StudentID,
        callback: (fillbacktoschoolinfo) => {
          let OtherPickupMode = null,
            selectedOptionPickupMode = 0,
            selectedSubPickupModeOption = null,
            selectedBusRouteOption = null,
            previousSelectedOptionsPickupMode = null,
            previousSelectedSubpickupModeOption = null,
            previousSelecteBusRouteOption = null;
          if (fillbacktoschoolinfo) {
            this.setState({ backToSchoolInfoData: fillbacktoschoolinfo });

            if (
              fillbacktoschoolinfo.PickupMode &&
              fillbacktoschoolinfo.PickupMode.PickupModeId == null &&
              fillbacktoschoolinfo.PickupMode.PickupModeOther
            ) {
              OtherPickupMode = fillbacktoschoolinfo.PickupMode.PickupModeOther;
              selectedOptionPickupMode = previousSelectedOptionsPickupMode = -9;
            } else {
              selectedOptionPickupMode = previousSelectedOptionsPickupMode =
                fillbacktoschoolinfo.PickupMode.PickupModeId;
            }
            if (fillbacktoschoolinfo.PickupMode.PickupModeId === 4) {
              if (fillbacktoschoolinfo.PickupMode.PickupModeOther === null) {
                selectedSubPickupModeOption =
                  previousSelectedSubpickupModeOption =
                    fillbacktoschoolinfo.PickupMode.InstitutionId;
              } else {
                OtherPickupMode =
                  fillbacktoschoolinfo.PickupMode.PickupModeOther;
                selectedSubPickupModeOption =
                  previousSelectedSubpickupModeOption = -9;
              }
            } else if (fillbacktoschoolinfo.PickupMode.PickupModeId === 1) {
              if (fillbacktoschoolinfo.PickupMode.PickupModeOther === null) {
                selectedBusRouteOption = previousSelecteBusRouteOption =
                  fillbacktoschoolinfo.PickupMode.BusRouteId;
              } else {
                OtherPickupMode =
                  fillbacktoschoolinfo.PickupMode.PickupModeOther;
                selectedBusRouteOption = previousSelecteBusRouteOption = -9;
              }
            }

            this.setState({
              OtherPickupMode,
              selectedOptionPickupMode,
              selectedSubPickupModeOption,
              selectedBusRouteOption,
              previousSelectedOptionsPickupMode,
              previousSelectedSubpickupModeOption,
              previousSelecteBusRouteOption,
            });
          }
        },
      });
    }
  }
  componentWillUnmount() {
    this.props.doGetSchoolDispatchClear();
  }

  getBackToSchoolTemplate = () => {
    const ChildInfo = this.props.studentData;
    return {
      StudentId: ChildInfo.StudentID,
      Primary: {
        FirstName: "",
        LastName: "",
        Mobile: "",
        Email: "",
        Relationship: "",
      },
      Secondary: {
        FirstName: "",
        LastName: "",
        Mobile: "",
        Email: "",
        Relationship: "",
      },
      PickupMode: {},
      Carpooling: null,
      Address: "",
      StudentID: ChildInfo.StudentID,
    };
  };

  getDispatchList = () => {
    const { doGetSchoolDispatch } = this.props;
    const ChildInfo = this.props.studentData;
    doGetSchoolDispatch({ schoolid: ChildInfo.SchoolID });
  };

  getPickupModeById = (value) => {
    const { pickupModeList } = this.state;
    for (let i = 0; i < pickupModeList.length; i++) {
      if (parseInt(value) === parseInt(pickupModeList[i].value))
        return pickupModeList[i].label;
    }
  };

  getAfterSchoolProgramById = (value) => {
    const { schoolSubOptions } = this.state;
    for (let i = 0; i < schoolSubOptions.length; i++) {
      if (parseInt(value) === parseInt(schoolSubOptions[i].value))
        return schoolSubOptions[i];
    }
    return {
      label: "",
      canAssignParent: false,
    };
  };

  getBusRouteById = (value) => {
    const { busRouteSubOptions } = this.state;

    for (let i = 0; i < busRouteSubOptions.length; i++) {
      if (parseInt(value) === parseInt(busRouteSubOptions[i].value))
        return busRouteSubOptions[i];
    }
    return {
      label: "",
      canAssignParent: false,
    };
  };

  updateBackToSchoolData = (fieldType, fieldName, value) => {
    const backToSchoolInfoData = { ...this.state.backToSchoolInfoData };
    if (fieldType) {
      const fieldData = { ...backToSchoolInfoData[fieldType] };
      fieldData[fieldName] = value;
      backToSchoolInfoData[fieldType] = fieldData;
    } else {
      backToSchoolInfoData[fieldName] = value;
    }
    this.setState({ backToSchoolInfoData });
  };

  setupPickupModes = () => {
    const {
      pickupModeList,
      schoolSubOptions,
      busRouteSubOptions,
      selectedBusRouteOption,
      selectedSubPickupModeOption,
    } = this.state;
    const { getSchoolDispatch } = this.props;

    let studentPickupModeList;
    let pickupModes = [];
    let schoolOptions = [];
    let busOptions = [];
    let afterSchoolOptions,
      busCompletesOptions,
      subOptions,
      subBus = [];
    let modes = [];

    if (getSchoolDispatch) {
      studentPickupModeList = getSchoolDispatch.MethodsAtSchool;
      pickupModes.push({
        label: `${this.props.t("Select_Dismissal_Label")}`,
        value: 0,
        canAssignParent: false,
      });
      afterSchoolOptions = studentPickupModeList.filter(
        (item) => item.PickupModeId === 4
      );
      busCompletesOptions = studentPickupModeList.filter(
        (item) => item.PickupModeId === 1
      );

      for (let i = 0; i < studentPickupModeList.length; i++) {
        pickupModes.push({
          label: `${studentPickupModeList[i].Name}`,
          value: studentPickupModeList[i].PickupModeId,
        });
      }
      pickupModes.push({
        label: `${this.props.t("selectProblemScr-other")}`,
        value: -9,
        canAssignParent: false,
      });

      if (pickupModeList.length === 0 && pickupModes.length > 0) {
        this.setState({ pickupModeList: pickupModes });
      }

      for (let i = 0; i < afterSchoolOptions.length; i++) {
        subOptions = afterSchoolOptions[i].SubOptions;
      }
      if (subOptions) {
        subOptions = subOptions.sort((a, b) =>
          a.Name.toLowerCase() > b.Name.toLowerCase()
            ? 1
            : b.Name.toLowerCase() > a.Name.toLowerCase()
            ? -1
            : 0
        );
        for (let i = 0; i < subOptions.length; i++) {
          schoolOptions.push({
            label: `${subOptions[i].Name}`,
            value: `${subOptions[i].Id}`,
            canAssignParent: subOptions[i].CanAssignParent,
          });
        }
        if (schoolOptions.length > 0)
          schoolOptions.push({
            label: `${this.props.t("selectProblemScr-other")}`,
            value: -9,
            canAssignParent: false,
          });
      }

      if (schoolSubOptions.length === 0 && schoolOptions.length > 0) {
        this.setState({
          schoolSubOptions: schoolOptions,
          selectedSubPickupModeOption: selectedSubPickupModeOption
            ? selectedSubPickupModeOption
            : schoolOptions[0].value,
        });
      }

      for (let i = 0; i < busCompletesOptions.length; i++) {
        subBus = busCompletesOptions[i].SubOptions;
      }
      for (let i = 0; i < subBus.length; i++) {
        busOptions.push({
          label: `${subBus[i].Name}`,
          value: `${subBus[i].Id}`,
          canAssignParent: subBus[i].CanAssignParent,
        });
      }
      if (busOptions.length > 0)
        busOptions.push({
          label: `${this.props.t("selectProblemScr-other")}`,
          value: -9,
          canAssignParent: false,
        });
      if (busRouteSubOptions.length === 0 && busOptions.length > 0) {
        busOptions = busOptions.sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase()
            ? 1
            : b.label.toLowerCase() > a.label.toLowerCase()
            ? -1
            : 0
        );
        this.setState({
          busRouteSubOptions: busOptions,
          selectedBusRouteOption: selectedBusRouteOption
            ? selectedBusRouteOption
            : busOptions[0].value,
        });
      }
    }
  };

  componentDidUpdate = () => {
    const {
      saveBackSchoolInfoResponse,
      error,
      isSingleKid,
      doSaveBackToSchoolDataClear,
    } = this.props;
    const { doCallbackRefresh } = this.props;
    this.setupPickupModes();
    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
    } else if (saveBackSchoolInfoResponse != null) {
      toast.success(saveBackSchoolInfoResponse.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doSaveBackToSchoolDataClear();
      this.props.doHideModal();
      if (doCallbackRefresh) doCallbackRefresh();

      if (isSingleKid) this.props.navigate("/childManagement");
    }
  };

  setSelectedDelegate = () => {};

  getStudentRelationship = (studentID) => {
    const { relationshipList, studentData } = this.props;
    const rec = studentData.studentID;
    let relationshipText = null;
    if (rec && rec !== undefined) {
      if (rec.RelationshipTypeId) {
        if (
          relationshipList &&
          relationshipList !== undefined &&
          relationshipList.length > 0
        )
          relationshipText = relationshipList.find(
            (item) => parseInt(item.Id) === parseInt(rec.RelationshipTypeId)
          ).Id;
      } else if (rec.RelationshipTypeOther) {
        relationshipText = rec.RelationshipTypeOther;
      }
    }

    return relationshipText;
  };

  doSetuserRelation = (selectedRelationData) => {
    this.updateBackToSchoolData(
      "Primary",
      "Relationship",
      selectedRelationData.Relationship
    );
  };
  doSetSecRelation = (selectedRelationData) => {
    this.updateBackToSchoolData(
      "Secondary",
      "Relationship",
      selectedRelationData.Relationship
    );
  };
  saveBackToSchool = () => {
    const { doSaveBackToSchoolData, doBacktoschoolInfoFill } = this.props;
    const backToSchoolInfoData = { ...this.state.backToSchoolInfoData };
    const {
      selectedOptionPickupMode,
      selectedSubPickupModeOption,
      selectedBusRouteOption,
      OtherPickupMode,
    } = this.state;
    const PickupMode = {};

    if (selectedOptionPickupMode) {
      if (parseInt(selectedOptionPickupMode) === -9) {
        PickupMode.Other = OtherPickupMode;
      } else {
        PickupMode.PickupModeId = selectedOptionPickupMode;
        PickupMode.PickupModeName = this.getPickupModeById(
          selectedOptionPickupMode
        );
      }
      if (selectedOptionPickupMode == 4) {
        if (parseInt(selectedSubPickupModeOption) === -9) {
          PickupMode.Other = OtherPickupMode;
        } else {
          PickupMode.InstitutionId = selectedSubPickupModeOption;
          PickupMode.InstitutionName = this.getAfterSchoolProgramById(
            selectedSubPickupModeOption
          ).label;
        }
      } else if (selectedOptionPickupMode == 1) {
        if (parseInt(selectedBusRouteOption) === -9) {
          PickupMode.Other = OtherPickupMode;
        } else {
          PickupMode.BusRouteId = selectedBusRouteOption;
          PickupMode.BusName = this.getBusRouteById(
            selectedBusRouteOption
          ).label;
        }
      }
    }

    backToSchoolInfoData.PickupMode = PickupMode;

    doSaveBackToSchoolData(backToSchoolInfoData);
    doBacktoschoolInfoFill();
  };

  render() {
    const {
      selectedOptionPickupMode,
      selectedBusRouteOption,
      selectedSubPickupModeOption,
      pickupModeList,
      schoolSubOptions,
      busRouteSubOptions,
      isEditable,
      isCollapse,
      backToSchoolInfoData,
      OtherPickupMode,
    } = this.state;
    const { studentData, isSingleKid, stackType } = this.props;

    let selectedOptionPickupModeText = "";
    if (selectedOptionPickupMode == 4)
      selectedOptionPickupModeText = this.props.t("After_School_Program");
    else if (selectedOptionPickupMode == 1)
      selectedOptionPickupModeText = this.props.t("Bus_Route");
    const styles = getMyStyle(this.props.selectedTheme);
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <div>
                <h4 className="modal-title">
                  {this.props.t("Back_to_school")}
                </h4>
              </div>
            </div>

            <button
              type="button"
              className="close-btn"
              onClick={() => {
                if (isSingleKid && stackType === "Setting") {
                  this.closeModal();
                } else if (isSingleKid) {
                  this.askMeAlerts();
                } else {
                  this.closeModal();
                }
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>

          <div className="modal-body">
            <div className="backtoschool-form" id="backtoschool-enrollment">
              <div className="card form-card form-collapse">
                <div
                  className="form-card-title expand-collapse-row"
                  data-bs-toggle="collapse"
                  data-bs-target="#primaryparent-view"
                  aria-controls="#primaryparent-view"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (event.target === event.currentTarget) {
                      if (isCollapse === true) {
                        this.setState({ isEditable: false });
                      }
                      this.setState({ isCollapse: !isCollapse });
                    }
                  }}
                >
                  <div className="fc-titletext-icon">
                    <i className="title-icon fa-solid fa-user"></i>
                    <span className="text">{this.props.t("your_info")}</span>
                  </div>
                </div>
                {isCollapse === false ? (
                  <button
                    className="btn action-btn edit-btn"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      this.setState({ isEditable: !isEditable });
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                ) : null}
                <form
                  className="form form-style2 collapse"
                  id="primaryparent-view"
                  data-parent="#backtoschool-enrollment"
                >
                  <fieldset disabled={isEditable === false}>
                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("regNameScr-firstName")}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          value={backToSchoolInfoData.Primary.FirstName}
                          placeholder={this.props.t("editChildScr-firstName")}
                          onChange={(event) => {
                            this.updateBackToSchoolData(
                              "Primary",
                              "FirstName",
                              event.target.value
                            );
                          }}
                          maxLength={50}
                          onKeyDown={(event) => nextField(event)}
                        />
                        <i className="fa-solid fa-user-circle input-icon"></i>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("regNameScr-lastName")}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={this.props.t("editChildScr-lastName")}
                          name="LastName"
                          onKeyDown={(event) => nextField(event)}
                          value={backToSchoolInfoData.Primary.LastName}
                          onChange={(event) => {
                            this.updateBackToSchoolData(
                              "Primary",
                              "LastName",
                              event.target.value
                            );
                          }}
                          maxLength={50}
                        />
                        <i className="fa-solid fa-user-circle input-icon"></i>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("mobile_number")}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          name="Mobile"
                          onKeyDown={(event) => nextField(event)}
                          value={backToSchoolInfoData.Primary.Mobile}
                          placeholder={this.props.t("phone-format")}
                          onChange={(event) => {
                            this.updateBackToSchoolData(
                              "Primary",
                              "Mobile",
                              event.target.value
                            );
                          }}
                          maxLength={30}
                        />
                        <i className="fa-solid fa-phone input-icon"></i>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("loginScreen-email")}
                      </label>
                      <div className="input-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder={this.props.t("loginScreen-email")}
                          name="Email"
                          value={backToSchoolInfoData.Primary.Email}
                          onChange={(event) => {
                            this.updateBackToSchoolData(
                              "Primary",
                              "Email",
                              event.target.value
                            );
                          }}
                        />
                        <i className="fa-solid fa-user-circle input-icon"></i>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("Relationship_type")}
                      </label>
                      <SelectRelationship
                        key={"student-relation" + studentData.StudentID}
                        StudentID={studentData.StudentID}
                        selectedRelationshipOption={this.getStudentRelationship(
                          studentData.StudentID
                        )}
                        studentName={
                          studentData.Firstname + " " + studentData.Lastname
                        }
                        stackType={"ChildManagement"}
                        selectedRelationshipName={
                          backToSchoolInfoData.Primary.Relationship
                        }
                        doCallback={this.doSetuserRelation}
                      />
                      <input
                        type="hidden"
                        name="selectedRelationData"
                        value={
                          backToSchoolInfoData.Primary.Relationship === ""
                            ? this.props.t("selectWhenScr-select")
                            : backToSchoolInfoData.Primary.Relationship
                        }
                      />
                    </div>
                  </fieldset>
                </form>
              </div>

              <div className="card form-card form-collapse">
                <div
                  className="form-card-title expand-collapse-row"
                  data-bs-toggle="collapse"
                  data-bs-target="#secondaryparent-view"
                  aria-controls="#secondaryparent-view"
                >
                  <div className="fc-titletext-icon">
                    <i className="title-icon fas fa-user"></i>
                    <span className="text">
                      {this.props.t("Secondary_Parent")}
                    </span>
                  </div>
                </div>
                <form
                  className="form form-style2 collapse"
                  id="secondaryparent-view"
                  data-parent="#backtoschool-enrollment"
                >
                  <fieldset>
                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("regNameScr-firstName")}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          value={backToSchoolInfoData.Secondary.FirstName}
                          placeholder={this.props.t("editChildScr-firstName")}
                          onChange={(event) => {
                            this.updateBackToSchoolData(
                              "Secondary",
                              "FirstName",
                              event.target.value
                            );
                          }}
                          maxLength={50}
                          onKeyDown={(event) => nextField(event)}
                        />
                        <i className="fa-solid fa-user-circle input-icon"></i>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("regNameScr-lastName")}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={this.props.t("editChildScr-lastName")}
                          name="LastName"
                          onKeyDown={(event) => nextField(event)}
                          value={backToSchoolInfoData.Secondary.LastName}
                          onChange={(event) => {
                            this.updateBackToSchoolData(
                              "Secondary",
                              "LastName",
                              event.target.value
                            );
                          }}
                          maxLength={50}
                        />
                        <i className="fa-solid fa-user-circle input-icon"></i>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("mobile_number")}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          name="Mobile"
                          onKeyDown={(event) => nextField(event)}
                          value={backToSchoolInfoData.Secondary.Mobile}
                          placeholder={this.props.t("phone-format")}
                          onChange={(event) => {
                            this.updateBackToSchoolData(
                              "Secondary",
                              "Mobile",
                              event.target.value
                            );
                          }}
                          maxLength={30}
                        />
                        <i className="fa-solid fa-phone input-icon"></i>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("loginScreen-email")}
                      </label>
                      <div className="input-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder={this.props.t("loginScreen-email")}
                          name="Email"
                          value={backToSchoolInfoData.Secondary.Email}
                          onChange={(event) => {
                            this.updateBackToSchoolData(
                              "Secondary",
                              "Email",
                              event.target.value
                            );
                          }}
                        />
                        <i className="fas fa-user-circle input-icon"></i>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("Relationship_type")}
                      </label>
                      <SelectRelationship
                        StudentID={studentData.StudentID}
                        doCallback={this.doSetSecRelation}
                        selectedRelationshipOption={this.getStudentRelationship(
                          studentData.StudentID
                        )}
                        stackType={"ChildManagement"}
                        selectedRelationshipName={
                          backToSchoolInfoData.Secondary.Relationship
                        }
                        studentName={
                          studentData.Firstname + " " + studentData.Lastname
                        }
                      />

                      <input
                        type="hidden"
                        name="selectedRelationData"
                        value={
                          backToSchoolInfoData.Primary.Relationship === ""
                            ? this.props.t("selectWhenScr-select")
                            : backToSchoolInfoData.Primary.Relationship
                        }
                      />
                    </div>
                  </fieldset>
                </form>
              </div>

              <div className="card form-card form-collapse">
                <div
                  className="form-card-title expand-collapse-row"
                  data-bs-toggle="collapse"
                  data-bs-target="#pickupmode-view"
                  aria-controls="#pickupmode-view"
                >
                  <div className="fc-titletext-icon">
                    <img
                      className="title-icon pikcupmode-icon"
                      src={pickupKid}
                      alt={this.props.t("pickupMode")}
                      title={this.props.t("pickupMode")}
                    />
                    <span className="text">{this.props.t("pickupMode")}</span>
                  </div>
                </div>
                <form
                  className="form form-style2 collapse"
                  id="pickupmode-view"
                  data-parent="#backtoschool-enrollment"
                >
                  <fieldset>
                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("Primary_mode_Pickup")}
                      </label>

                      <Dropdown
                        className="custom-dropdown"
                        onSelect={(eventKey) => {
                          this.setState({
                            selectedOptionPickupMode: eventKey,
                            selectedSubPickupModeOption: null,
                          });
                        }}
                        onClick={() => {
                          this.setState({
                            previousSelectedOptionsPickupMode:
                              selectedOptionPickupMode,
                          });
                        }}
                      >
                        <Dropdown.Toggle>
                          {this.getPickupModeById(selectedOptionPickupMode)}
                          {pickupModeList.length === 0 && (
                            <RingLoader
                              color="#2d3d56"
                              loading={true}
                              size={20}
                            />
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {pickupModeList.map((data) => {
                            return (
                              <Dropdown.Item
                                className={
                                  parseInt(selectedOptionPickupMode) ===
                                  parseInt(data.value)
                                    ? "active"
                                    : ""
                                }
                                eventKey={data.value}
                                key={data.value}
                              >
                                <span className="dropdownitem-label">
                                  {data.label}
                                </span>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="form-group">
                      {selectedOptionPickupModeText !== "" && (
                        <label className="control-label">
                          {selectedOptionPickupModeText}
                        </label>
                      )}

                      {selectedOptionPickupMode == 1 && (
                        <Dropdown
                          className="custom-dropdown"
                          onSelect={(eventKey) => {
                            this.setState({
                              selectedBusRouteOption: eventKey,
                            });
                          }}
                        >
                          <Dropdown.Toggle>
                            {selectedBusRouteOption
                              ? this.getBusRouteById(selectedBusRouteOption)
                                  .label
                              : this.props.t("Select_Dismissal_Label")}
                            {busRouteSubOptions.length === 0 && (
                              <RingLoader
                                color="#2d3d56"
                                loading={true}
                                size={20}
                              />
                            )}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {busRouteSubOptions.map((data) => {
                              return (
                                <Dropdown.Item
                                  eventKey={data.value}
                                  key={data.value}
                                  className={
                                    parseInt(selectedBusRouteOption) ===
                                    parseInt(data.value)
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <span className="dropdownitem-label">
                                    {data.label}
                                  </span>
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}

                      {selectedOptionPickupMode == 4 && (
                        <Dropdown
                          className="custom-dropdown"
                          onSelect={(eventKey) => {
                            this.setState({
                              selectedSubPickupModeOption: eventKey,
                            });
                          }}
                        >
                          <Dropdown.Toggle>
                            {selectedSubPickupModeOption
                              ? this.getAfterSchoolProgramById(
                                  selectedSubPickupModeOption
                                ).label
                              : this.props.t("Select_Dismissal_Label")}
                            {schoolSubOptions.length === 0 && (
                              <RingLoader
                                color="#2d3d56"
                                loading={true}
                                size={20}
                              />
                            )}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {schoolSubOptions.map((data) => {
                              return (
                                <Dropdown.Item
                                  eventKey={data.value}
                                  key={data.value}
                                  className={
                                    parseInt(selectedSubPickupModeOption) ===
                                    parseInt(data.value)
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <span className="dropdownitem-label">
                                    {data.label}
                                  </span>
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                    <div className="form-group">
                      {(parseInt(selectedOptionPickupMode) === -9 ||
                        parseInt(selectedSubPickupModeOption) === -9 ||
                        parseInt(selectedBusRouteOption) === -9) && (
                        <div className="input-group other-pickupmode-box">
                          <input
                            type="text"
                            className="form-control"
                            value={OtherPickupMode}
                            placeholder={this.props.t("pickupMode-other")}
                            onChange={(event) => {
                              this.setState({
                                OtherPickupMode: event.target.value,
                              });
                            }}
                            maxLength={50}
                          />
                          <i className="fas fa-car-alt input-icon"></i>
                        </div>
                      )}
                    </div>
                  </fieldset>
                </form>
              </div>

              <div className="card form-card form-collapse">
                <div
                  className="form-card-title expand-collapse-row"
                  data-bs-toggle="collapse"
                  data-bs-target="#carpool-address-view"
                  aria-controls="#carpool-address-view"
                >
                  <div className="fc-titletext-icon">
                    <i className="title-icon fas fa-map-marker"></i>
                    <span className="text">
                      {this.props.t("Car_Pool_Address")}
                    </span>
                  </div>
                </div>
                <form
                  className="form form-style2 collapse"
                  id="carpool-address-view"
                  data-parent="#backtoschool-enrollment"
                >
                  <fieldset>
                    <div className="form-group">
                      <fieldset>
                        <div
                          className="custom-radio justify-space-between"
                          onClick={() => {
                            this.updateBackToSchoolData(
                              null,
                              "Carpooling",
                              backToSchoolInfoData.Carpooling ? false : true
                            );
                          }}
                        >
                          <span className="radio-label-text">
                            {this.props.t("Looking_Carpool")}
                          </span>
                          {backToSchoolInfoData.Carpooling ? (
                            <i className="fas fa-check-circle blue-text"></i>
                          ) : (
                            <i className="far fa-circle"></i>
                          )}
                        </div>
                      </fieldset>
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        {this.props.t("Address")}
                      </label>
                      <div className="input-group">
                        <textarea
                          className="form-control"
                          placeholder={this.props.t("Address")}
                          name="decribeComment"
                          value={backToSchoolInfoData.Address}
                          onChange={(event) => {
                            this.updateBackToSchoolData(
                              null,
                              "Address",
                              event.target.value
                            );
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>

              {isSingleKid && (
                <div
                  className="card form-card form-collapse emergency-contact"
                  onClick={() => {
                    this.openContactsModal(studentData);
                  }}
                >
                  <div className="form-card-title  expand-collapse-row">
                    <div className="fc-titletext-icon">
                      <span className="text">
                        {this.props.t("AddemergencyContact")}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="form-group buttons-section mt-30">
                <button
                  type="button"
                  className="btn btn-default btn-block"
                  style={styles.buttonDefault}
                  onClick={() => {
                    this.saveBackToSchool();
                  }}
                >
                  {this.props.t("SUBMIT")}
                </button>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    );
  }
  openContactsModal = (studentData) => {
    this.props.doShowModal({
      modalScreenName: "SelectContact",
      modalClassName: "contact-list-modal sidebar-modal right",
      params: {
        ChildInfo: studentData,
        showEmergencyContactAlert: true,
        setSelectedDelegate: () => {},
        manageDelegate: true,
      },
    });
  };
  closeModal = () => {
    this.props.doHideModal();
  };
  askMeAlerts = () => {
    const { doSaveNextAskMeDate, doCallback } = this.props;
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui askmeAlert">
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <h1>{this.props.t("Back_to_school")}</h1>
                <div className="react-confirm-alert-button-group">
                  <button
                    type="button"
                    onClick={() => {
                      doSaveNextAskMeDate(1);
                      onClose();
                      this.closeModal();
                      if (doCallback) doCallback();
                    }}
                  >
                    {this.props.t("askMelater")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      doSaveNextAskMeDate(2);
                      onClose();
                      this.closeModal();
                      if (doCallback) doCallback();
                    }}
                  >
                    {this.props.t("dontAskMe")}
                  </button>
                  <button className="cancel-btn" onClick={onClose}>
                    {this.props.t("regNameScr-cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    approvedDelegatesResponse: state.userAccount.approvedDelegatesResponse,
    approvedDelegatesChangeResponse:
      state.userAccount.approvedDelegatesChangeResponse,
    delegatesSetDeletedResponse: state.userAccount.delegatesSetDeletedResponse,
    error: state.userAccount.error,
    isFetching: loadingSelector(state),
    saveBackSchoolInfoResponse: state.student.saveBackSchoolInfo,
    fillbacktoschoolinfo: state.student.fillbacktoschoolinfo,
    getSchoolDispatch: state.student.getSchoolDispatch,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doSaveBackToSchoolData: (data) =>
      dispatch(saveBackToSchoolInformation(data)),
    doSaveBackToSchoolDataClear: () =>
      dispatch(saveBackToSchoolInformationClear()),

    doSaveNextAskMeDate: (data) => dispatch(saveNextAskMeDate(data)),
    doBacktoschoolInfoFill: () => dispatch(backtoschoolDataInfo()),
    doGetFillbacktoschoolinfo: (data) =>
      dispatch(getfillBacktoschoolinfoData(data)),

    doGetSchoolDispatch: (data) => dispatch(getSchoolDispatch(data)),
    doGetSchoolDispatchClear: () => dispatch(getSchoolDispatchClear()),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(BackToSchool))
);
