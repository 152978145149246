import React from "react";
import { withRouter } from "./withRouter";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import GLOBAL_CONST from "../../helper/AppConstants";
import { getCurrentThemeColor } from "../../helper/utility";
import { getMyStyle } from "../../theme/ThemeStyle";

class MainFooter extends React.Component {
  render() {
    const copyRightfromYear = "2015";
    const styles = getMyStyle(this.props.selectedTheme);
    return (
      <footer className="pmk-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="ft-bottom need-help text-center">
                <p>
                  {this.props.t("regNameScr-needHelp")}{" "}
                  {this.props.t("footer-message-us-at")}{" "}
                  <a
                    href="mailto:support@pikmykid.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support@pikmykid.com
                  </a>{" "}
                  {this.props.t("footer-view-our")}{" "}
                  <a
                    href="https://schools.pikmykid.com/pikmykid/account/termsconditions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.t("Terms_of_service")}
                  </a>
                </p>

                <p>
                  {this.props.t("footer-copyright")} {copyRightfromYear} -{" "}
                  {moment().format("YYYY")}
                </p>
                <p>5115 Memorial Highway, Tampa Florida 33634.</p>
                <p>{"Ver. " + GLOBAL_CONST.VERSION}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTheme: state.userAccount.selectedTheme,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MainFooter))
);
