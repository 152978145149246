import { validateError } from "../../../helper/utility";
import {
  startRegistration,
  verifyOtp,
  getUserInfo,
  registerUser,
  getIsEmailVerified,
  ResendEmailVerificationMail,
  ssoUserAccountExist,
  resendUserOTP,
  setEmail,
  checkEmailAccountExist,
  appleLogin,
  requestNewEmailAddressApple,
  googleLogin,
  requestNewEmailAddressGoogle,
  checkEmail,
} from "../../services/userRegistration";

import {
  START_REGISTARTION_START,
  START_REGISTARTION_SUCCESS,
  START_REGISTARTION_FAILED,
  START_REGISTARTION_CLEAR,
  RE_SEND_OTP_START,
  RE_SEND_OTP_SUCCESS,
  RE_SEND_OTP_FAILED,
  RE_SEND_OTP_CLEAR,
  VERIFY_OTP_START,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_CLEAR,
  GET_USER_INFO_START,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILED,
  GET_USER_INFO_CLEAR,
  COMPLETE_REGISTRATION_USER_START,
  COMPLETE_REGISTRATION_USER_SUCCESS,
  COMPLETE_REGISTRATION_USER_FAILED,
  COMPLETE_REGISTRATION_USER_CLEAR,
  SET_EMAIL_START,
  SET_EMAIL_SUCCESS,
  SET_EMAIL_FAILED,
  SET_EMAIL_CLEAR,
  VERIFIED_EMAIL_START,
  VERIFIED_EMAIL_SUCCESS,
  VERIFIED_EMAIL_FAILED,
  VERIFIED_EMAIL_CLEAR,
  RE_SEND_EMAIL_START,
  RE_SEND_EMAIL_SUCCESS,
  RE_SEND_EMAIL_FAILED,
  RE_SEND_EMAIL_CLEAR,
  SSO_EXIST_START,
  SSO_EXIST_SUCCESS,
  SSO_EXIST_FAILED,
  SSO_EXIST_CLEAR,
  MARK_REGISTRATION_COMPLETE,
  GOOGLE_SSO_RESPONSE_SAVE,
  GOOGLE_SSO_RESPONSE_CLEAR,
  APPLE_SSO_RESPONSE_SAVE,
  APPLE_SSO_RESPONSE_CLEAR,
  CHECK_EMAIL_EXIST_START,
  CHECK_EMAIL_EXIST_SUCCESS,
  CHECK_EMAIL_EXIST_FAILED,
  CHECK_EMAIL_EXIST_CLEAR,
  DO_APPLE_LOGIN_START,
  DO_APPLE_LOGIN_SUCCESS,
  DO_APPLE_LOGIN_FAILED,
  DO_APPLE_LOGIN_CLEAR,
  SET_APPLE_SSO_START,
  SET_APPLE_SSO_SUCCESS,
  SET_APPLE_SSO_FAILED,
  SET_APPLE_SSO_CLEAR,
  DO_GOOGLE_LOGIN_START,
  DO_GOOGLE_LOGIN_SUCCESS,
  DO_GOOGLE_LOGIN_FAILED,
  DO_GOOGLE_LOGIN_CLEAR,
  SET_GOOGLE_SSO_START,
  SET_GOOGLE_SSO_SUCCESS,
  SET_GOOGLE_SSO_FAILED,
  SET_GOOGLE_SSO_CLEAR,
  CHECK_EMAIL_START,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILED,
  CHECK_EMAIL_CLEAR,
} from "./RegistrationActionType";

// Start User Registration
export const startUserRegistration = (params) => (dispatch) => {
  dispatch({ type: START_REGISTARTION_START });
  //console.log(`check registration parameters ${JSON.stringify(params)}`);
  return startRegistration(params)
    .then((response) => {
      //console.log(`check registration response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: START_REGISTARTION_SUCCESS, payload: response.Data });
      else {
        dispatch({ type: START_REGISTARTION_FAILED, payload: response });
      }
    })
    .catch((error) => {
      //console.log(`check registration error ${JSON.stringify(error)}`);
      dispatch({
        type: START_REGISTARTION_FAILED,
        payload: validateError(error),
      });
    });
};

// Clear User Registration
export const startRegistrationClear = () => (dispatch) => {
  dispatch({ type: START_REGISTARTION_CLEAR });
};

// User resend OTP
export const resendOTP = (params) => (dispatch) => {
  dispatch({ type: RE_SEND_OTP_START });

  return resendUserOTP(params)
    .then((response) => {
      //console.log(`resend otp response${JSON.stringify(response)}`);
      if (response) {
        dispatch({ type: RE_SEND_OTP_SUCCESS, payload: response });
      } else {
        dispatch({ type: RE_SEND_OTP_FAILED, payload: response });
      }
    })
    .catch((error) => {
      //console.log(`resend otp error ${JSON.stringify(error)}`);
      dispatch({ type: RE_SEND_OTP_FAILED, payload: validateError(error) });
    });
};

// User resend OTP  clear
export const resendOTPClear = () => (dispatch) => {
  dispatch({ type: RE_SEND_OTP_CLEAR });
};

// Verify User OTP
export const verifyUserOtp =
  ({ OtpCode }) =>
  (dispatch) => {
    dispatch({ type: VERIFY_OTP_START });
    //console.log(`OTP code ${OtpCode} `);
    return verifyOtp({ OtpCode })
      .then((response) => {
        //console.log(`verify OTP response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: VERIFY_OTP_SUCCESS, payload: response });
        else {
          dispatch({ type: VERIFY_OTP_FAILED, payload: response });
        }
      })
      .catch((error) => {
        //console.log(`verify OTP error ${JSON.stringify(error)}`);
        dispatch({ type: VERIFY_OTP_FAILED, payload: validateError(error) });
      });
  };

// Clear User OTP
export const verifyUserOtpClear = () => (dispatch) => {
  dispatch({ type: VERIFY_OTP_CLEAR });
};

// Get user Info
export const getUserInformation =
  ({ callback }) =>
  (dispatch) => {
    dispatch({ type: GET_USER_INFO_START });

    return getUserInfo()
      .then((response) => {
        // console.log(
        //   `user information response new ${JSON.stringify(response)}`
        // );
        if (response.Success)
          dispatch({ type: GET_USER_INFO_SUCCESS, payload: response });
        else {
          dispatch({ type: GET_USER_INFO_FAILED, payload: response });
        }
        if (callback) callback(response);
      })
      .catch((error) => {
        //console.log(`user information error ${JSON.stringify(error)}`);
        dispatch({ type: GET_USER_INFO_FAILED, payload: validateError(error) });
        if (callback) callback(validateError(error));
      });
  };

// Get user Info clear
export const getUserInfoClear = () => (dispatch) => {
  dispatch({ type: GET_USER_INFO_CLEAR });
};

// User registration complete
export const completeRegisterUser = (params) => (dispatch) => {
  dispatch({ type: COMPLETE_REGISTRATION_USER_START });
  //console.log(`get register user Param--${JSON.stringify(params)}`);
  return registerUser(params)
    .then(async (response) => {
      //console.log(`get register user response${JSON.stringify(response)}`);
      if (response.Success) {
        // console.warn(
        //   `get register user AuthTokenID ${response.Data.LoggedInParentInfo.AuthTokenID}`
        // );
        // await Keychain.setGenericPassword(
        //   'AuthTokenID',
        //   response.Data.LoggedInParentInfo.AuthTokenID,
        //   {service: 'AuthToken'},
        // );
        dispatch({
          type: COMPLETE_REGISTRATION_USER_SUCCESS,
          payload: response.Data,
        });
        if (params.callback) {
          params.callback(response.Data);
        }
      } else {
        // await Keychain.resetGenericPassword({
        //   service: 'AuthToken',
        // });
        dispatch({
          type: COMPLETE_REGISTRATION_USER_FAILED,
          payload: response,
        });
      }
    })
    .catch((error) => {
      //console.log(`get register user error ${JSON.stringify(error)}`);
      dispatch({
        type: COMPLETE_REGISTRATION_USER_FAILED,
        payload: validateError(error),
      });
    });
};

// user registration complete clear
export const completeRegisterUserClear = () => (dispatch) => {
  dispatch({ type: COMPLETE_REGISTRATION_USER_CLEAR });
};

// Check email
export const setUserEmail = (email) => (dispatch) => {
  dispatch({ type: SET_EMAIL_START });
  //console.log(`check Email parameters ${JSON.stringify(email)} `);
  return setEmail(email)
    .then((response) => {
      //console.log(`check Email response ${JSON.stringify(response)}`);
      if (response.Success) {
        dispatch({
          type: SET_EMAIL_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({ type: SET_EMAIL_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`check Email error ${JSON.stringify(error)}`);
      dispatch({ type: SET_EMAIL_FAILED, payload: validateError(error) });
    });
};

// Check email clear
export const setUserEmailClear = () => (dispatch) => {
  dispatch({ type: SET_EMAIL_CLEAR });
};

// Verified Email
export const verifiedUserEmail = () => (dispatch) => {
  dispatch({ type: VERIFIED_EMAIL_START });

  return getIsEmailVerified()
    .then((response) => {
      // console.log(`verfied Email response ${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: VERIFIED_EMAIL_SUCCESS, payload: response });
      else {
        dispatch({ type: VERIFIED_EMAIL_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`verfied Email error ${JSON.stringify(error)}`);
      dispatch({ type: VERIFIED_EMAIL_FAILED, payload: validateError(error) });
    });
};

// Verified email clear
export const verifiedUserEmailClear = () => (dispatch) => {
  dispatch({ type: VERIFIED_EMAIL_CLEAR });
};

// Resend email
export const reSendEmailUser = () => (dispatch) => {
  dispatch({ type: RE_SEND_EMAIL_START });
  return ResendEmailVerificationMail()
    .then((response) => {
      // console.log(`resend Email response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: RE_SEND_EMAIL_SUCCESS, payload: response });
      else {
        dispatch({ type: RE_SEND_EMAIL_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`resend Email error ${JSON.stringify(error)}`);
      dispatch({ type: RE_SEND_EMAIL_FAILED, payload: validateError(error) });
    });
};

// Resend email clear
export const reSendEmailClear = () => (dispatch) => {
  dispatch({ type: RE_SEND_EMAIL_CLEAR });
};

// SSO acccount exist check
export const ssoUserAccountCheck = (params) => (dispatch) => {
  dispatch({ type: SSO_EXIST_START });
  return ssoUserAccountExist(params)
    .then((response) => {
      // console.log(`SSO user account exist response${JSON.stringify(response)}`);
      if (response.Success) {
        dispatch({
          type: SSO_EXIST_SUCCESS,
          payload: {
            response,
            calledForLogin: params.calledForLogin,
          },
        });
      } else {
        dispatch({
          type: SSO_EXIST_FAILED,
          payload: {
            response,
            calledForLogin: params.calledForLogin,
          },
        });
      }
    })
    .catch((error) => {
      // console.log(`SSO user account exist error ${JSON.stringify(error)}`);
      dispatch({ type: SSO_EXIST_FAILED, payload: validateError(error) });
    });
};

// SSO acccount exist check clear
export const ssoUserAccountCheckClear = () => (dispatch) => {
  dispatch({ type: SSO_EXIST_CLEAR });
};

// Verified email clear
export const markRegisterationComplete = () => (dispatch) => {
  dispatch({ type: MARK_REGISTRATION_COMPLETE });
};

// Save Google SSO response
export const googleSSOSave =
  ({ ssoGoogleResponse }) =>
  (dispatch) => {
    dispatch({
      type: GOOGLE_SSO_RESPONSE_SAVE,
      payload: { ssoGoogleResponse },
    });
  };

// Clear Google SSO response
export const googleSSOClear = () => (dispatch) => {
  dispatch({ type: GOOGLE_SSO_RESPONSE_CLEAR });
};

// Save Apple SSO response
export const appleSSOSave = (ssoAppleResponse) => (dispatch) => {
  dispatch({ type: APPLE_SSO_RESPONSE_SAVE, payload: { ssoAppleResponse } });
};

// Clear Apple SSO response
export const appleSSOClear = () => (dispatch) => {
  dispatch({ type: APPLE_SSO_RESPONSE_CLEAR });
};

// Check email exist
export const checkEmailExist = (params) => (dispatch) => {
  dispatch({ type: CHECK_EMAIL_EXIST_START });
  return checkEmailAccountExist(params)
    .then((response) => {
      // console.log(
      //   `checkEmailExist  account response${JSON.stringify(response)}`
      // );
      if (response.Success)
        dispatch({ type: CHECK_EMAIL_EXIST_SUCCESS, payload: response });
      else {
        dispatch({ type: CHECK_EMAIL_EXIST_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(
      //   `checkEmailExist account exist error ${JSON.stringify(error)}`
      // );
      dispatch({
        type: CHECK_EMAIL_EXIST_FAILED,
        payload: validateError(error),
      });
    });
};

// Clear Check email exist
export const checkEmailExistClear = () => (dispatch) => {
  dispatch({ type: CHECK_EMAIL_EXIST_CLEAR });
};

export const performAppleLogin = (params) => (dispatch) => {
  dispatch({ type: DO_APPLE_LOGIN_START });
  //console.log(`performAppleLogin parameters ${JSON.stringify(params)} `);
  return appleLogin(params)
    .then((response) => {
      //console.log(`performAppleLogin response ${JSON.stringify(response)}`);
      if (response.Success) {
        dispatch({
          type: DO_APPLE_LOGIN_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: DO_APPLE_LOGIN_FAILED,
          payload: { serverResponse: response, appleInfo: params },
        });
      }
    })
    .catch((error) => {
      //console.log(`performAppleLogin error ${JSON.stringify(error)}`);
      dispatch({
        type: DO_APPLE_LOGIN_FAILED,
        payload: { serverResponse: validateError(error), appleInfo: params },
      });
    });
};

export const setAppleLoginClear = () => (dispatch) => {
  dispatch({ type: DO_APPLE_LOGIN_CLEAR });
};

export const setAppleSSOEmail = (params) => (dispatch) => {
  dispatch({ type: SET_APPLE_SSO_START });
  // console.log(`setAppleSSOEmail parameters ${JSON.stringify(params)} `);
  return requestNewEmailAddressApple(params)
    .then((response) => {
      //console.log(`setAppleSSOEmail response ${JSON.stringify(response)}`);
      if (response.Success) {
        dispatch({
          type: SET_APPLE_SSO_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({ type: SET_APPLE_SSO_FAILED, payload: response });
      }
    })
    .catch((error) => {
      //console.log(`setAppleSSOEmail error ${JSON.stringify(error)}`);
      dispatch({ type: SET_APPLE_SSO_FAILED, payload: validateError(error) });
    });
};

export const setAppleSSOEmailClear = () => (dispatch) => {
  dispatch({ type: SET_APPLE_SSO_CLEAR });
};

export const performGoogleLogin = (params) => (dispatch) => {
  dispatch({ type: DO_GOOGLE_LOGIN_START });
  //console.log(`performGoogleLogin parameters ${JSON.stringify(params)} `);
  return googleLogin(params)
    .then((response) => {
      // console.log(`performGoogleLogin response ${JSON.stringify(response)}`);
      if (response.Success) {
        dispatch({
          type: DO_GOOGLE_LOGIN_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: DO_GOOGLE_LOGIN_FAILED,
          payload: { serverResponse: response, googleInfo: params },
        });
      }
    })
    .catch((error) => {
      //console.log(`performGoogleLogin error ${JSON.stringify(error)}`);
      dispatch({
        type: DO_APPLE_LOGIN_FAILED,
        payload: { serverResponse: validateError(error), googleInfo: params },
      });
    });
};

export const setGoogleLoginClear = () => (dispatch) => {
  dispatch({ type: DO_GOOGLE_LOGIN_CLEAR });
};

export const setGoogleSSOEmail = (params) => (dispatch) => {
  dispatch({ type: SET_GOOGLE_SSO_START });
  //console.log(`setGoogleSSOEmail parameters ${JSON.stringify(params)} `);
  return requestNewEmailAddressGoogle(params)
    .then((response) => {
      //console.log(`setGoogleSSOEmail response ${JSON.stringify(response)}`);
      if (response.Success) {
        dispatch({
          type: SET_GOOGLE_SSO_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({ type: SET_GOOGLE_SSO_FAILED, payload: response });
      }
    })
    .catch((error) => {
      //console.log(`setGoogleSSOEmail error ${JSON.stringify(error)}`);
      dispatch({ type: SET_GOOGLE_SSO_FAILED, payload: validateError(error) });
    });
};

export const setGoogleSSOEmailClear = () => (dispatch) => {
  dispatch({ type: SET_GOOGLE_SSO_CLEAR });
};

export const checkUserEmail =
  ({ Email, locale, callback }) =>
  (dispatch) => {
    dispatch({ type: CHECK_EMAIL_START });
    //console.log(`check Email parameters ${Email}  language ${locale} `);
    return checkEmail({ Email, locale })
      .then((response) => {
        //console.log(`check Email${JSON.stringify(response)}`);
        if (response.Success) {
          dispatch({ type: CHECK_EMAIL_SUCCESS, payload: response });
        } else {
          dispatch({ type: CHECK_EMAIL_FAILED, payload: response });
        }
        if (callback) {
          callback(response);
        }
      })
      .catch((error) => {
        //console.log(`check Email ${JSON.stringify(error)}`);
        dispatch({ type: CHECK_EMAIL_FAILED, payload: validateError(error) });
      });
  };

/**
 * Purpose: Clear user Check email
 */
export const checkUserEmailClear = () => (dispatch) => {
  dispatch({ type: CHECK_EMAIL_CLEAR });
};
