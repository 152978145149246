import React from "react";
import { withTranslation } from "react-i18next";
import { BootstrapModal } from "../CommonComponents";
import { Line } from "rc-progress";
import settingsIcon from "../assets/dashboard/settingsIcon.svg";
import { connect } from "react-redux";
import { createLoadingSelector } from "../../common/reducers/selectors";

import {
  showModal,
  hideModal,
  setHelpList,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";
import moment from "moment";

import {
  getcheckInQuestions,
  getCheckInParentQuestionsClear,
  getCheckInParentQuestions,
  getSchoolStudentCheckIn,
  submitCheckInByParent,
  submitCheckInByParentClear,
} from "../../common/actions/checkin";

import RingLoader from "react-spinners/ClipLoader";

import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCurrentThemeColor } from "../../helper/utility";

const loadingSelector = createLoadingSelector([
  "get_school_student_checkin",
  "submit_checkin_parent",
]);

class CheckinQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SchoolStudentList: [],
      selectForCheckin: 0,
      showQuestionScreen: true,
      selectAnswer: 0,
      questionOption: 0,
      selectedOption: -1,
      userAnswers: {},
      checkInQuestions: null,
      disabledNext: true,
      completedPer: 0,
      alreadyCheckedInMessage: this.props.t("Downloading_checkinQestion"),
    };
    this.getQuestions();
  }

  componentDidMount() {
    this.dosetHelpList();
  }
  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "CheckIn",
      screen: GLOBAL_CONST.SCREEN_NAMES.CHECKIN_QUESTIONS,
      title: this.props.t("checkin-help"),
    });
  };

  getQuestions = () => {
    const { record, schoolId, parentCheckIn } = this.props;
    const { doGetcheckInQuestions, doGetCheckInParentQuestions } = this.props;

    if (parentCheckIn === true) {
      doGetCheckInParentQuestions({ schoolID: schoolId });
    } else {
      doGetcheckInQuestions({
        schoolID: record.SchoolID,
        studentID: record.StudentID,
      });
    }
  };

  selectedItem = (item) => {
    const { record, parentCheckIn } = this.props;
    const { checkInQuestions } = this.state;
    const { questionOption } = this.state;
    let schoolQuestionId =
      checkInQuestions.Questions[questionOption].SchoolQuestionID;
    let isParenFlag, studentID;
    if (record != undefined) {
      studentID = record.StudentID;
    } else {
      studentID = null;
    }
    if (parentCheckIn === true) {
      isParenFlag = true;
    } else {
      isParenFlag = false;
    }
    let { userAnswers } = this.state;
    userAnswers[schoolQuestionId] = {
      ...item,
      SchoolQuestionID: schoolQuestionId,
      Skipped: false,
      IsParentQuestion: isParenFlag,
      studentID,
    };

    this.setState({ userAnswers, selectedOption: item.QuestionAnswerID });

    setTimeout(
      function () {
        this.setState({ disabledNext: false });
        if (checkInQuestions.Questions.length - 1 != questionOption) {
          this.showNextQuestion();
        }
      }.bind(this),
      200
    );
  };

  closeModal = () => {
    this.props.doHideModal();
  };

  showNextQuestion = () => {
    const { checkInQuestions } = this.state;
    const { userAnswers } = this.state;
    let { questionOption, selectedOption } = this.state;

    if (
      questionOption >= 0 &&
      questionOption < checkInQuestions.Questions.length
    ) {
      questionOption += 1;
      let schoolQuestionId =
        checkInQuestions.Questions[questionOption].SchoolQuestionID;

      const selectedAnswer = userAnswers[schoolQuestionId];

      if (selectedAnswer) {
        selectedOption = selectedAnswer.QuestionAnswerID;
      } else {
        selectedOption = -1;
      }
    } else {
      selectedOption = -1;
    }

    this.setState({ questionOption, selectedOption });

    this.nextButtonDisabled(questionOption);
  };

  showPreviousQuestion = () => {
    const { checkInQuestions } = this.state;
    const { userAnswers } = this.state;
    let { questionOption, selectedOption } = this.state;

    if (questionOption > 0) {
      questionOption -= 1;
      let schoolQuestionId =
        checkInQuestions.Questions[questionOption].SchoolQuestionID;

      const selectedAnswer = userAnswers[schoolQuestionId];

      if (selectedAnswer) {
        selectedOption = selectedAnswer.QuestionAnswerID;
      } else {
        selectedOption = -1;
      }
    } else {
      selectedOption = -1;
    }

    this.setState({ questionOption, selectedOption });
    this.nextButtonDisabled(questionOption);
  };

  nextButtonDisabled = (currentQuestion) => {
    const { checkInQuestions } = this.state;
    const { userAnswers } = this.state;
    let disabledFlag = false;
    let selectedQuestion;

    if (currentQuestion === undefined) {
      selectedQuestion = 0;
    } else {
      selectedQuestion = currentQuestion;
    }

    let schoolQuestionId =
      checkInQuestions.Questions[selectedQuestion].SchoolQuestionID;
    if (
      currentQuestion === 0 &&
      checkInQuestions.Questions[selectedQuestion].ParentsCanSkip === false
    ) {
      disabledFlag = true;
      this.setState({ disabledNext: disabledFlag });
    } else if (
      userAnswers[schoolQuestionId] === undefined &&
      checkInQuestions.Questions[selectedQuestion].ParentsCanSkip === false
    ) {
      disabledFlag = true;
      this.setState({ disabledNext: disabledFlag });
    } else {
      disabledFlag = false;
      this.setState({ disabledNext: disabledFlag });
    }
  };

  checkIsQuestionAnswered = () => {
    const { userAnswers, questionOption } = this.state;
    const { checkInQuestions } = this.state;
    const question = checkInQuestions.Questions[questionOption];

    return userAnswers[question.SchoolQuestionID];
  };

  submitForm = () => {
    const { record, parentCheckIn, schoolId } = this.props;
    const { doSubmitCheckInByParent } = this.props;
    const { userAnswers } = this.state;
    let isParenFlag, schoolID, studentID;
    if (record != undefined) {
      studentID = record.StudentID;
    } else {
      studentID = null;
    }
    if (parentCheckIn === true) {
      isParenFlag = true;
      schoolID = schoolId;
    } else {
      isParenFlag = false;
      schoolID = record.SchoolID;
    }

    const selectedQuestionAnswers = Object.values(userAnswers);

    let submitQuestionAndAnswers = [];
    for (let i = 0; i < selectedQuestionAnswers.length; i++) {
      submitQuestionAndAnswers.push({
        IsParentQuestion: selectedQuestionAnswers[i].IsParentQuestion,
        StudentID: selectedQuestionAnswers[i].studentID,
        SchoolQuestionID: selectedQuestionAnswers[i].SchoolQuestionID,
        SchoolQuestionAnswerID: selectedQuestionAnswers[i].QuestionAnswerID,
        Skipped: selectedQuestionAnswers[i].Skipped,
      });
    }

    doSubmitCheckInByParent({
      schoolID,
      checkInParent: isParenFlag,
      studentIDs: [studentID],
      questionsAndAnswers: submitQuestionAndAnswers,
    });
  };

  submitWithoutQuestion = () => {
    const { doSubmitCheckInByParent } = this.props;
    const { record } = this.props;
    let isParenFlag, schoolID, studentID;
    schoolID = record.schoolId;

    if (record != undefined) {
      studentID = [record.StudentID];
      isParenFlag = false;
    } else {
      studentID = [];
      isParenFlag = true;
    }

    doSubmitCheckInByParent({
      schoolID,
      checkInParent: isParenFlag,
      studentIDs: studentID,
      questionsAndAnswers: [],
    });
  };

  renderItem = ({ item }) => {
    const { selectedOption } = this.state;
    const isSelected = selectedOption === item.QuestionAnswerID;

    return (
      <div
        key={"answer=" + item.QuestionAnswerID}
        onClick={() => {
          this.selectedItem(item);
        }}
        className={
          isSelected
            ? "selectedForCheckn selected-data custom-select-bx"
            : "custom-select-bx"
        }
      >
        <span
          className={
            isSelected ? "selectedAnswer custom-select-label" : "answerStyle"
          }
        >
          {item.AnswerText_EN}
        </span>
      </div>
    );
  };

  buildAnswers = () => {
    const { questionOption, checkInQuestions } = this.state;
    let answers = [];
    if (
      checkInQuestions &&
      checkInQuestions.Questions &&
      checkInQuestions.Questions[questionOption] &&
      checkInQuestions.Questions[questionOption].Answers
    ) {
      answers = checkInQuestions.Questions[questionOption].Answers;
    }

    const views = [];
    for (var i = 0; i < answers.length; i++) {
      views.push(this.renderItem({ item: answers[i] }));
    }
    return views;
  };
  componentDidUpdate() {
    const {
      getCheckInQuestionsResponse,
      CheckInByParentResponse,
      error,
      doGetCheckInParentQuestionsClear,
      doSubmitCheckInByParentClear,
    } = this.props;
    const { doCallbackRefresh } = this.props;

    if (
      getCheckInQuestionsResponse != null &&
      getCheckInQuestionsResponse.Questions.length > 0
    ) {
      this.setState(
        { checkInQuestions: getCheckInQuestionsResponse },
        this.nextButtonDisabled
      );
      doGetCheckInParentQuestionsClear();
    } else if (
      getCheckInQuestionsResponse != null &&
      getCheckInQuestionsResponse.Questions.length === 0
    ) {
      this.setState({
        alreadyCheckedInMessage: this.props.t("Submitting_checkin"),
      });
      doGetCheckInParentQuestionsClear();
      this.submitForm();
    }

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doSubmitCheckInByParentClear();
    } else if (CheckInByParentResponse) {
      toast.success(this.props.t("Submit_Checkin"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doSubmitCheckInByParentClear();
      doCallbackRefresh();
    }
  }

  buildFooter = () => {
    const { questionOption, disabledNext, checkInQuestions } = this.state;
    const { isFetching } = this.props;
    let questionCount = 0;
    if (
      checkInQuestions &&
      checkInQuestions.Questions &&
      checkInQuestions.Questions[questionOption] &&
      checkInQuestions.Questions[questionOption].Answers
    ) {
      questionCount = checkInQuestions.Questions.length;
    }

    if (questionCount > 0 && questionOption >= 0) {
      let completedQ = questionOption;
      if (this.checkIsQuestionAnswered()) {
        completedQ += 1;
      }
    }

    const views = [];
    if (questionOption > 0) {
      views.push(
        <button
          type="button"
          key={"previousBtn"}
          className="btn btn-default white-btn"
          onClick={() => {
            this.showPreviousQuestion();
          }}
        >
          {this.props.t("Previous")}
        </button>
      );
    }
    if (questionCount - 1 === questionOption) {
      views.push(
        <button
          type="button"
          key={"submitBtn"}
          className="btn btn-default"
          style={{backgroundColor:getCurrentThemeColor(this.props.selectedTheme).buttonDefault}} 
          onClick={this.submitForm}
          disabled={disabledNext === true}
        >
          {isFetching ? (
            <i className="fa-solid fa-arrows-rotate fa-spin"></i>
          ) : null}{" "}
          {this.props.t("settingsScr-submit")}
        </button>
      );
    } else {
      views.push(
        <button
          type="button"
          key={"nextBtn"}
          className="btn btn-default testing"
          style={{
            backgroundColor: getCurrentThemeColor(this.props.selectedTheme)
              .buttonDefault,
          }}
          onClick={() => {
            this.showNextQuestion(questionCount);
          }}
          disabled={disabledNext === true}
        >
          {this.props.t("regNameScr-next")}
        </button>
      );
    }

    return views;
  };
  render() {
    const { isFetching } = this.props;
    const { questionOption, checkInQuestions, alreadyCheckedInMessage } =
      this.state;
    let questionCount = 0;
    let questionText = "";
    let completedPer = 0;
    if (
      checkInQuestions &&
      checkInQuestions.Questions &&
      checkInQuestions.Questions[questionOption] &&
      checkInQuestions.Questions[questionOption].Answers
    ) {
      questionText = checkInQuestions.Questions[questionOption].QuestionText_EN;
      questionCount = checkInQuestions.Questions.length;
    }

    if (questionCount > 0 && questionOption >= 0) {
      let completedQ = questionOption;
      if (this.checkIsQuestionAnswered()) {
        completedQ += 1;
      }
      completedPer = (completedQ * 100) / questionCount;
    }

    return (
      <div className="form-container">
        <div className="form-progress-status">
          <span className="questionRange progressbar-score">
            {questionOption + 1} of {questionCount}
          </span>
          <div className="progressbar-holder">
            <Line
              percent={completedPer}
              strokeWidth="1"
              strokeColor="#7ac943"
            />
          </div>
        </div>

        <div className="QuestionsBox">
          <label className="question">{questionText}</label>
        </div>
        <div className="AnswersList">
          {this.buildAnswers()}
          {this.buildAnswers.length === 0 && isFetching && (
            <div className="loaderHolder mt-30">
              <RingLoader color="#2d3d56" loading={isFetching} size={60} />
            </div>
          )}
        </div>
        <div className="btn-sect">{this.buildFooter()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    getCheckInQuestionsResponse: state.checkin.getCheckInQuestionsResponse,
    userLoginInfo: state.userAccount.userLoginInfo,
    canCheckin: state.checkin.canCheckin,
    isFetching: loadingSelector(state),
    CheckInByParentResponse: state.checkin.CheckInByParentResponse,
    error: state.checkin.error,

    setHelpListData: state.userAccount.setHelpListData,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doGetcheckInQuestions: (data) => dispatch(getcheckInQuestions(data)),
    doGetCheckInParentQuestions: (data) =>
      dispatch(getCheckInParentQuestions(data)),
    doSubmitCheckInByParent: (data) => dispatch(submitCheckInByParent(data)),
    doSubmitCheckInByParentClear: () => dispatch(submitCheckInByParentClear()),
    doGetCheckInParentQuestionsClear: () =>
      dispatch(getCheckInParentQuestionsClear()),

    doeGetSchoolStudentCheckIn: (data) =>
      dispatch(getSchoolStudentCheckIn(data)),

    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    dosetHelpList: (data) => dispatch(setHelpList(data)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CheckinQuestions)
);
