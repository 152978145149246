import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./components/UserAccount/LandingPage";
import ForgotPassword from "./components/UserAccount/ForgotPassword";
import { Dashboard, DismissalTimes, Nokids } from "./components/Dashboard";
import Error from "./Error";

import {
  NewRegisterName,
  NewRegisterEmail,
  NewConfirmEmail,
  NewRegisterPhone,
  NewConfirmPhone,
  NewRegisterPassword,
  NewChooseRole,
  SelectIsParent,
  AccountStatusInfo,
} from "./components/UserAccount/NewRegistration";

import {
  NotificationsList,
  ChangePassword,
  Setting,
} from "./components/Settings";
import { ChildManagement } from "./components/ChildManagement";
import { Checkin } from "./components/Checkin";
import { BootstrapModal, LoadAds } from "./components/CommonComponents";
import { PrivateRoute, TermsConditions } from "./components/CommonComponents";
import { Notifications } from "react-push-notification";
import ScanBarCode from "./components/Scanner/ScanBarCode";
import AppleLoginHandler from "./AppleLoginHandler";
import { ToastContainer } from "react-toastify";
import { DeleteAccount } from "./components/UserAccount/DeleteAccount";

class WebRoutes extends React.Component {
  render() {
    return (
      <div>
        <Notifications />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route path="/newregisterName" element={<NewRegisterName />} />
          <Route path="/newregisterEmail" element={<NewRegisterEmail />} />
          <Route path="/newconfirmEmail" element={<NewConfirmEmail />} />
          <Route
            exact
            path="/newregisterPhone"
            element={<NewRegisterPhone />}
          />
          <Route path="/newconfirmPhone" element={<NewConfirmPhone />} />
          <Route
            path="/newregisterPassword"
            element={<NewRegisterPassword />}
          />

          <Route path="/newchooseRole" element={<NewChooseRole />} />
          <Route path="/isparentselect" element={<SelectIsParent />} />
          <Route path="/accountstatusinfo" element={<AccountStatusInfo />} />

          <Route
            exact
            path="/sso"
            element={
              <PrivateRoute>
                <NewRegisterEmail />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/childManagement"
            element={
              <PrivateRoute>
                <ChildManagement />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/notifications"
            element={
              <PrivateRoute>
                <NotificationsList />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/changePassword"
            element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/setting"
            element={
              <PrivateRoute>
                <Setting />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/termsConditions"
            element={
              <PrivateRoute>
                <TermsConditions />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dismissalTimes"
            element={
              <PrivateRoute>
                <DismissalTimes />
              </PrivateRoute>
            }
          />

          <Route path="/nokids" element={<Nokids />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/appleLogin" component={AppleLoginHandler} />
          <Route
            exact
            path="/checkin"
            element={
              <PrivateRoute>
                <Checkin />
              </PrivateRoute>
            }
          />
          <Route element={<Error />} />
          <Route
            element={
              <PrivateRoute>
                <LoadAds />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/ScanBarCode"
            element={
              <PrivateRoute>
                <ScanBarCode />
              </PrivateRoute>
            }
          />
          <Route exact path="/deleteAccount" element={<DeleteAccount />} />
        </Routes>
        <BootstrapModal myLevel={0} />
        <BootstrapModal myLevel={1} />
        <BootstrapModal myLevel={2} />
        <BootstrapModal myLevel={3} />
        <ToastContainer />
      </div>
    );
  }
}
export default WebRoutes;
