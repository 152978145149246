import {
  GET_STUDENTS_START,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAILED,
  GET_SCHOOL_STUDENT_INFO_CLEAR,
  GET_SCHOOL_INFO_START,
  GET_SCHOOL_INFO_SUCCESS,
  GET_SCHOOL_INFO_FAILED,
  GET_SCHOOL_AND_KIDS_DONE,
  GET_BUS_ROUTE_START,
  GET_BUS_ROUTE_SUCCESS,
  GET_BUS_ROUTE_FAILED,
  GET_STUDENT_AFTER_SCHOOL_START,
  GET_STUDENT_AFTER_SCHOOL_SUCCESS,
  GET_STUDENT_AFTER_SCHOOL_FAILED,
  IS_ANNOUNCE_START,
  IS_ANNOUNCE_SUCCESS,
  IS_ANNOUNCE_FAILED,
  IS_ANNOUNCE_CLEAR,
  GET_DELEGATIONS_START,
  GET_DELEGATIONS_SUCCESS,
  GET_DELEGATIONS_FAILED,
  GET_DELEGATIONS_CLEAR,
  CHANGE_DEFAULT_PICKUP_MODE_START,
  CHANGE_DEFAULT_PICKUP_MODE_SUCCESS,
  CHANGE_DEFAULT_PICKUP_MODE_FAILED,
  CHANGE_DEFAULT_PICKUP_MODE_CLEAR,
  CANCEL_DELEGATION_START,
  CANCEL_DELEGATION_SUCCESS,
  CANCEL_DELEGATION_FAILED,
  CANCEL_DELEGATION_CLEAR,
  GET_OTHER_CHILDREN_START,
  GET_OTHER_CHILDREN_SUCCESS,
  GET_OTHER_CHILDREN_FAILED,
  GET_ABSENT_DATE_RANGE_START,
  GET_ABSENT_DATE_RANGE_SUCCESS,
  GET_ABSENT_DATE_RANGE_FAILED,
  GET_CALENDAR_DATE_RANGE_START,
  GET_CALENDAR_DATE_RANGE_SUCCESS,
  GET_CALENDAR_DATE_RANGE_FAILED,
  SAVE_ABSENTEE_START,
  SAVE_ABSENTEE_SUCCESS,
  SAVE_ABSENTEE_FAILED,
  SAVE_ABSENTEE_CLEAR,
  GET_PICKUP_ENTRIES_START,
  GET_PICKUP_ENTRIES_SUCCESS,
  GET_PICKUP_ENTRIES_FAILED,
  GET_PICKUP_ENTRIES_CLEAR,
  GET_ABSENT_CALENDAR_ENTRIES_RANGE_START,
  GET_ABSENT_CALENDAR_ENTRIES_RANGE_SUCCESS,
  GET_ABSENT_CALENDAR_ENTRIES_RANGE_FAILED,
  GET_ABSENT_CALENDAR_ENTRIES_RANGE_CLEAR,
  CHANGE_DELEGATION_START,
  CHANGE_DELEGATION_SUCCESS,
  CHANGE_DELEGATION_FAILED,
  CHANGE_DELEGATION_CLEAR,
  GET_PICKUP_ENTRIES_MONTH_START,
  GET_PICKUP_ENTRIES_MONTH_SUCCESS,
  GET_PICKUP_ENTRIES_MONTH_FAILED,
  GET_DELEGATE_LIST_START,
  GET_DELEGATE_LIST_SUCCESS,
  GET_DELEGATE_LIST_FAILED,
  GET_DEFAULT_DISMISSAL_START,
  GET_DEFAULT_DISMISSAL_SUCCESS,
  GET_DEFAULT_DISMISSAL_FAILED,
  GET_DEFAULT_DISMISSAL_CLEAR,
  CANCEL_ABSENTEE_START,
  CANCEL_ABSENTEE_SUCCESS,
  CANCEL_ABSENTEE_FAILED,
  CANCEL_ABSENTEE_CLEAR,
  GET_RELATED_DELEGATIONS_START,
  GET_RELATED_DELEGATIONS_SUCCESS,
  GET_RELATED_DELEGATIONS_FAILED,
  CANCEL_MULTIPLE_DELEGATIONS_START,
  CANCEL_MULTIPLE_DELEGATIONS_SUCCESS,
  CANCEL_MULTIPLE_DELEGATIONS_FAILED,
  CANCEL_MULTIPLE_DELEGATIONS_CLEAR,
  GET_AVAILABLE_DISPATCH_START,
  GET_AVAILABLE_DISPATCH_SUCCESS,
  GET_AVAILABLE_DISPATCH_FAILED,
  GET_AVAILABLE_DISPATCH_CLEAR,
  SAVE_SELECTED_GAURDIAN_DETAILS_START,
  GET_DISMISSAL_HISTORY_START,
  GET_DISMISSAL_HISTORY_SUCCESS,
  GET_DISMISSAL_HISTORY_FAILED,
  GET_DISMISSAL_HISTORY_CLEAR,
  GET_POSITION_IN_LINE_START,
  GET_POSITION_IN_LINE_SUCCESS,
  GET_POSITION_IN_LINE_FAILED,
  GET_POSITION_IN_LINE_CLEAR,
  GET_DELEGATIONS_ENTRIES_START,
  GET_DELEGATIONS_ENTRIES_SUCCESS,
  GET_DELEGATIONS_ENTRIES_FAILED,
  GET_DELEGATIONS_ENTRIES_CLEAR,
  TODAYS_KIDS_ANNOUNCED_LIST,
  GET_OTHER_KIDS_DONE,
  GET_OTHER_KIDS_CLEAR,
  GET_RELATED_DELEGATIONS_CLEAR,
  EARLY_DISMISSAL_START,
  EARLY_DISMISSAL_SUCCESS,
  EARLY_DISMISSAL_FAILED,
  EARLY_DISMISSAL_CLEAR,
  CANCEL_EARLY_DISMISSAL_START,
  CANCEL_EARLY_DISMISSAL_SUCCESS,
  CANCEL_EARLY_DISMISSAL_FAILED,
  CANCEL_EARLY_DISMISSAL_CLEAR,
  GET_EARLY_DISMISSAL_DATE_RANGE_START,
  GET_EARLY_DISMISSAL_DATE_RANGE_SUCCESS,
  GET_EARLY_DISMISSAL_DATE_RANGE_FAILED,
  GET_EARLY_DISMISSAL_DATE_RANGE_CLEAR,
  GET_BACK_SCHOOL_START,
  GET_BACK_SCHOOL_SUCCESS,
  GET_BACK_SCHOOL_FAILED,
  SAVE_BACK_SCHOOL_START,
  SAVE_BACK_SCHOOL_SUCCESS,
  SAVE_BACK_SCHOOL_FAILED,
  SAVE_BACK_SCHOOL_CLEAR,
  GET_RELATIONSHIP_START,
  GET_RELATIONSHIP_SUCCESS,
  GET_RELATIONSHIP_FAILED,
  GET_FILL_BACKTOSCHOOL_START,
  GET_FILL_BACKTOSCHOOL_SUCCESS,
  GET_FILL_BACKTOSCHOOL_FAILED,
  GET_SCHOOL_DISPATCH_START,
  GET_SCHOOL_DISPATCH_SUCCESS,
  GET_SCHOOL_DISPATCH_FAILED,
  GET_SCHOOL_DISPATCH_CLEAR,
  SET_CALENDAR_VIEW,
  SET_CALENDAR_VIEW_CLEAR,
} from "./studentActionType";

import {
  getStudents,
  getSchoolInfo,
  GetBusRoutes,
  getStudentAfterSchools,
  submitAnnounce,
  getDelegationsToCurrentParent,
  postDefaultPickupModeChange,
  cancelDelegation,
  getOtherChildren,
  getAbsentEntryForDateRange,
  getCalendarEntryForDateRange,
  saveAbsentee,
  getPickupEntriesForDateRange,
  getDefaultDismissal,
  getAbsentAndCalendarEntriesForDateRange,
  postDelegationChange,
  getDelegates,
  cancelAbsentee,
  getRelatedDelegations,
  cancelDelegations,
  getAvailableDispatchMethods,
  getDismissalHistory,
  getPositionInLine,
  getPreviousAndFuturePickups,
  submitEarlyDismissal,
  dateRangeEarlyDismissal,
  cancelEarlyDismissal,
  getBackToSchoolInfo,
  saveBackToSchoolInfo,
  getRelationshipType,
  getFillbacktoschollinfo,
  getSchoolDispatchMethods,
} from "../../services";
import { validateError } from "../../../helper/utility";

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/getStudents
 * Purpose: Get Students List
 */
export const getStudentsList =
  ({ currenDate, callback }) =>
  (dispatch) => {
    dispatch({ type: GET_STUDENTS_START });
    return getStudents({ currenDate })
      .then((response) => {
        // console.log(`Students List response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: GET_STUDENTS_SUCCESS, payload: response.Data });
        else {
          dispatch({ type: GET_STUDENTS_FAILED, payload: response });
        }
        if (callback) callback();
      })
      .catch((error) => {
        if (callback) callback();
        // console.log(`Students List error ${JSON.stringify(error)}`);
        dispatch({ type: GET_STUDENTS_FAILED, payload: error });
      });
  };

export const schoolAndKidsFetchDone = () => (dispatch) => {
  dispatch({ type: GET_SCHOOL_AND_KIDS_DONE });
};
/**
 * Purpose: student List clear
 */
export const studentsSchoolListClear = () => (dispatch) => {
  dispatch({ type: GET_SCHOOL_STUDENT_INFO_CLEAR });
};

export const otherChildrenFetchDone = () => (dispatch) => {
  dispatch({ type: GET_OTHER_KIDS_DONE });
};

export const otherChildrenListClear = () => (dispatch) => {
  dispatch({ type: GET_OTHER_KIDS_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/getSchoolInfo
 * Purpose: Get Students List
 */
export const getSchoolDetails = () => (dispatch) => {
  dispatch({ type: GET_SCHOOL_INFO_START });
  return getSchoolInfo()
    .then((response) => {
      // console.log(`School details response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: GET_SCHOOL_INFO_SUCCESS, payload: response.Data });
      else {
        dispatch({ type: GET_SCHOOL_INFO_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`School details error ${JSON.stringify(error)}`);
      dispatch({ type: GET_SCHOOL_INFO_FAILED, payload: error });
    });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/GetBusRoutes
 * Purpose: Get GetBusRoutes
 */
export const busRouteDetails =
  ({ studentID }) =>
  (dispatch) => {
    dispatch({ type: GET_BUS_ROUTE_START });
    return GetBusRoutes({ studentID })
      .then((response) => {
        const busRouteNumber = [];
        const getBusRouteNumber = response.Data;
        busRouteNumber.push({
          label: "Select",
          value: 0,
        });
        for (let i = 0; i < getBusRouteNumber.length; i++) {
          busRouteNumber.push({
            label: `${getBusRouteNumber[i].RouteNumber}`,
            value: `${getBusRouteNumber[i].BusRouteID}`,
          });
        }
        // console.log(`BusRoute details response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: GET_BUS_ROUTE_SUCCESS, payload: busRouteNumber });
        else {
          dispatch({ type: GET_BUS_ROUTE_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`BusRoute details error ${JSON.stringify(error)}`);
        dispatch({ type: GET_BUS_ROUTE_FAILED, payload: error });
      });
  };

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/getStudentAfterSchools
 * Purpose: Get getStudentAfterSchools
 */
export const studentAfterSchool =
  ({ studentID }) =>
  (dispatch) => {
    dispatch({ type: GET_STUDENT_AFTER_SCHOOL_START });
    return getStudentAfterSchools({ studentID })
      .then((response) => {
        const afterSchool = [];
        const afterSchoolRecord = response.Data;
        afterSchool.push({
          label: "Select",
          value: 0,
        });
        for (let i = 0; i < afterSchoolRecord.length; i++) {
          afterSchool.push({
            label: `${afterSchoolRecord[i].Name}`,
            value: `${afterSchoolRecord[i].AfterSchoolID}`,
          });
        }
        // console.log(`student after school response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: GET_STUDENT_AFTER_SCHOOL_SUCCESS,
            payload: afterSchool,
          });
        else {
          dispatch({
            type: GET_STUDENT_AFTER_SCHOOL_FAILED,
            payload: response,
          });
        }
      })
      .catch((error) => {
        // console.log(`student after school  error ${JSON.stringify(error)}`);
        dispatch({ type: GET_STUDENT_AFTER_SCHOOL_FAILED, payload: error });
      });
  };

export const announceSubmit = (params) => (dispatch) => {
  dispatch({ type: IS_ANNOUNCE_START });
  // console.log(`Submit Announce announceSubmit param ${JSON.stringify(params)}`);
  return submitAnnounce(params)
    .then((response) => {
      // console.log(`Submit Announce response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: IS_ANNOUNCE_SUCCESS, payload: response });
      else {
        dispatch({ type: IS_ANNOUNCE_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`Submit Announce error ${JSON.stringify(error)}`);
      dispatch({ type: IS_ANNOUNCE_FAILED, payload: error });
    });
};

export const announceSubmitClear = () => (dispatch) => {
  dispatch({ type: IS_ANNOUNCE_CLEAR });
};

export const getDelegationToParent = (params) => (dispatch) => {
  // console.log("GetParams", JSON.stringify(params));
  dispatch({ type: GET_DELEGATIONS_START });
  return getDelegationsToCurrentParent(params)
    .then((response) => {
      // console.log(`get delegation response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({
          type: GET_DELEGATIONS_SUCCESS,
          payload: response.Data.Delegations,
        });
      else {
        dispatch({ type: GET_DELEGATIONS_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`get delegation error ${JSON.stringify(error)}`);
      dispatch({ type: GET_DELEGATIONS_FAILED, payload: error });
    });
};

export const getDelegationToParentClear = () => (dispatch) => {
  dispatch({ type: GET_DELEGATIONS_CLEAR });
};

export const changeDefaultPickupMode = (params) => (dispatch) => {
  dispatch({ type: CHANGE_DEFAULT_PICKUP_MODE_START });
  //console.log(`change deafult pickup mode request ${JSON.stringify(params)}`);
  return postDefaultPickupModeChange(params)
    .then((response) => {
      // console.log(
      //   `change deafult pickup mode response${JSON.stringify(response)}`,
      // );
      if (response.Success) {
        dispatch({
          type: CHANGE_DEFAULT_PICKUP_MODE_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: CHANGE_DEFAULT_PICKUP_MODE_FAILED,
          payload: response,
        });
      }
    })
    .catch((error) => {
      //console.log(`change deafult pickup mode error ${JSON.stringify(error)}`);
      dispatch({
        type: CHANGE_DEFAULT_PICKUP_MODE_FAILED,
        payload: validateError(error),
      });
    });
};

export const changeDefaultPickupModeClear = () => (dispatch) => {
  dispatch({ type: CHANGE_DEFAULT_PICKUP_MODE_CLEAR });
};

export const delegationCancel = (params) => (dispatch) => {
  dispatch({ type: CANCEL_DELEGATION_START });
  return cancelDelegation(params)
    .then((response) => {
      // console.log(`cancel delegation response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: CANCEL_DELEGATION_SUCCESS, payload: response });
      else {
        dispatch({ type: CANCEL_DELEGATION_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`cancel delegation  error ${JSON.stringify(error)}`);
      dispatch({ type: CANCEL_DELEGATION_FAILED, payload: error });
    });
};

export const delegationCancelClear = () => (dispatch) => {
  dispatch({ type: CANCEL_DELEGATION_CLEAR });
};

/**
 * Author:WQ
 * API:   // http://35.167.52.234/api/getOtherChildren
 * Purpose: getOtherChildren
 */
export const getOtherChildrenDetails = () => (dispatch) => {
  dispatch({ type: GET_OTHER_CHILDREN_START });
  return getOtherChildren()
    .then((response) => {
      // console.log(
      //   `Get other children List response${JSON.stringify(response)}`
      // );
      if (response.Success)
        dispatch({ type: GET_OTHER_CHILDREN_SUCCESS, payload: response.Data });
      else {
        dispatch({ type: GET_OTHER_CHILDREN_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`Get other children List error ${JSON.stringify(error)}`);
      dispatch({ type: GET_OTHER_CHILDREN_FAILED, payload: error });
    });
};

export const getAbsentDateRange = (params) => (dispatch) => {
  dispatch({ type: GET_ABSENT_DATE_RANGE_START });
  return getAbsentEntryForDateRange(params)
    .then((response) => {
      // console.log(
      //   `Get  absent date range  response${JSON.stringify(response)}`
      // );
      if (response.Success)
        dispatch({
          type: GET_ABSENT_DATE_RANGE_SUCCESS,
          payload: response.Data,
        });
      else {
        dispatch({ type: GET_ABSENT_DATE_RANGE_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`Get  absent date range error ${JSON.stringify(error)}`);
      dispatch({ type: GET_ABSENT_DATE_RANGE_FAILED, payload: error });
    });
};

export const getCalendarDateRange = (params) => (dispatch) => {
  dispatch({ type: GET_CALENDAR_DATE_RANGE_START });
  return getCalendarEntryForDateRange(params)
    .then((response) => {
      // console.log(
      //   `Get  calendar date range  response${JSON.stringify(response)}`
      // );
      if (response.Success)
        dispatch({
          type: GET_CALENDAR_DATE_RANGE_SUCCESS,
          payload: response.Data,
        });
      else {
        dispatch({ type: GET_CALENDAR_DATE_RANGE_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`Get  calendar date range error ${JSON.stringify(error)}`);
      dispatch({ type: GET_CALENDAR_DATE_RANGE_FAILED, payload: error });
    });
};

export const submitAbsentee = (params) => (dispatch) => {
  dispatch({ type: SAVE_ABSENTEE_START });
  //console.log(`submitAbsentee params ${JSON.stringify(params)}`);
  return saveAbsentee(params)
    .then((response) => {
      // console.log(`submitAbsentee response${JSON.stringify(response)}`);
      if (response.Success) {
        const Data = response.Data;
        const errorRecord = Data.find((item) => {
          return !item.Success;
        });
        if (errorRecord) {
          dispatch({ type: SAVE_ABSENTEE_FAILED, payload: errorRecord });
        } else {
          dispatch({ type: SAVE_ABSENTEE_SUCCESS, payload: response.Data });
        }
      } else {
        dispatch({ type: SAVE_ABSENTEE_FAILED, payload: response });
      }
    })
    .catch((error) => {
      //console.log(`submitAbsentee error ${JSON.stringify(error)}`);
      dispatch({ type: SAVE_ABSENTEE_FAILED, payload: validateError(error) });
    });
};

export const submitAbsenteeClear = () => (dispatch) => {
  dispatch({ type: SAVE_ABSENTEE_CLEAR });
};

export const getPickupEntries = (params) => (dispatch) => {
  dispatch({ type: GET_PICKUP_ENTRIES_START });
  //console.log(`getPickupEntries called params ${JSON.stringify(params)}`);
  return getPickupEntriesForDateRange(params)
    .then((response) => {
      // console.log(
      //   `Get  pickup entries response refresh${JSON.stringify(response)}`,
      // );
      if (response.Success === true) {
        dispatch({ type: GET_PICKUP_ENTRIES_SUCCESS, payload: response.Data });
      } else {
        dispatch({ type: GET_PICKUP_ENTRIES_FAILED, payload: response });
      }
    })
    .catch((error) => {
      //console.log(`Get  pickup entries  error ${JSON.stringify(error)}`);
      dispatch({
        type: GET_PICKUP_ENTRIES_FAILED,
        payload: validateError(error),
      });
    });
};

export const getPickupEntriesClear = (params) => (dispatch) => {
  dispatch({ type: GET_PICKUP_ENTRIES_CLEAR, payload: { params } });
};

export const AbsentAndCalendarEntriesForDateRange = (params) => (dispatch) => {
  dispatch({ type: GET_ABSENT_CALENDAR_ENTRIES_RANGE_START });
  return getAbsentAndCalendarEntriesForDateRange(params)
    .then((response) => {
      // console.log(
      //   `Get absent calendar entires response${JSON.stringify(response)}`
      // );
      if (response.Success)
        dispatch({
          type: GET_ABSENT_CALENDAR_ENTRIES_RANGE_SUCCESS,
          payload: response.Data.AbsenteeData,
        });
      else {
        dispatch({
          type: GET_ABSENT_CALENDAR_ENTRIES_RANGE_FAILED,
          payload: response,
        });
      }
    })
    .catch((error) => {
      // console.log(
      //   `Get absent calendar entires  error ${JSON.stringify(error)}`
      // );
      dispatch({
        type: GET_ABSENT_CALENDAR_ENTRIES_RANGE_FAILED,
        payload: error,
      });
    });
};
export const AbsentAndCalendarEntriesForDateRangeClear = () => (dispatch) => {
  dispatch({ type: GET_ABSENT_CALENDAR_ENTRIES_RANGE_CLEAR });
};

export const changeDelegation = (params) => (dispatch) => {
  // (
  //   "****************** change delegations parameters ******************",
  //   JSON.stringify(params)
  // );
  dispatch({ type: CHANGE_DELEGATION_START });
  // console.log(`Change Delegations request ${JSON.stringify(params)}`);
  return postDelegationChange(params)
    .then((response) => {
      // console.log(`Change Delegations response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: CHANGE_DELEGATION_SUCCESS, payload: response.Data });
      else {
        dispatch({ type: CHANGE_DELEGATION_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`Change Delegations  error ${JSON.stringify(error)}`);
      dispatch({ type: CHANGE_DELEGATION_FAILED, payload: error });
    });
};

export const changeDelegationClear = () => (dispatch) => {
  dispatch({ type: CHANGE_DELEGATION_CLEAR });
};

export const getPickupEntriesMonth = (params) => (dispatch) => {
  dispatch({ type: GET_PICKUP_ENTRIES_MONTH_START });
  return getPickupEntriesForDateRange(params)
    .then((response) => {
      // console.log(`Get  pickup entries for a month response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({
          type: GET_PICKUP_ENTRIES_MONTH_SUCCESS,
          payload: response.Data,
        });
      else {
        dispatch({ type: GET_PICKUP_ENTRIES_MONTH_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(
      //   `Get  pickup entries for a month  error ${JSON.stringify(error)}`
      // );
      dispatch({ type: GET_PICKUP_ENTRIES_MONTH_FAILED, payload: error });
    });
};

export const getDelegateList = () => (dispatch) => {
  dispatch({ type: GET_DELEGATE_LIST_START });
  // console.log("Get getDelegateList details params");
  return getDelegates()
    .then((response) => {
      // console.log(`Get getDelegateList response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({ type: GET_DELEGATE_LIST_SUCCESS, payload: response.Data });
      else {
        dispatch({ type: GET_DELEGATE_LIST_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`Get getDelegateList  error ${JSON.stringify(error)}`);
      dispatch({ type: GET_DELEGATE_LIST_FAILED, payload: error });
    });
};

// GET_DEFAULT_DISMISSAL_START, GET_DEFAULT_DISMISSAL_SUCCESS, GET_DEFAULT_DISMISSAL_FAILED, GET_DEFAULT_DISMISSAL_CLEAR

export const getKidDefaultDismissal =
  ({ selectedKidsIds }) =>
  (dispatch) => {
    dispatch({ type: GET_DEFAULT_DISMISSAL_START });
    // console.log(
    //   `getDefaultDismissal params--${JSON.stringify(selectedKidsIds)}`
    // );
    return getDefaultDismissal({ selectedKidsIds })
      .then((response) => {
        //console.log(`getDefaultDismissal response${JSON.stringify(response)}`);
        if (response.Success) {
          dispatch({
            type: GET_DEFAULT_DISMISSAL_SUCCESS,
            payload: response.Data,
          });
        } else {
          dispatch({ type: GET_DEFAULT_DISMISSAL_FAILED, payload: response });
        }
      })
      .catch((error) => {
        //console.log(`getDefaultDismissal  error ${JSON.stringify(error)}`);
        dispatch({
          type: GET_DEFAULT_DISMISSAL_FAILED,
          payload: validateError(error),
        });
      });
  };

export const getKidDefaultDismissalClear = (params) => (dispatch) => {
  dispatch({ type: GET_DEFAULT_DISMISSAL_CLEAR, payload: { params } });
};

export const absenteeCancel =
  ({ studentId, absenteeDate }) =>
  (dispatch) => {
    dispatch({ type: CANCEL_ABSENTEE_START });
    return cancelAbsentee({ studentId, absenteeDate })
      .then((response) => {
        // console.log(`cancel Absentee response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: CANCEL_ABSENTEE_SUCCESS, payload: response });
        else {
          dispatch({ type: CANCEL_ABSENTEE_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`cancel Absentee   error ${JSON.stringify(error)}`);
        dispatch({ type: CANCEL_ABSENTEE_FAILED, payload: error });
      });
  };

export const absenteeCancelClear = () => (dispatch) => {
  dispatch({ type: CANCEL_ABSENTEE_CLEAR });
};

export const relatedDelegationsDetails =
  ({ calendarId }) =>
  (dispatch) => {
    dispatch({ type: GET_RELATED_DELEGATIONS_START });
    return getRelatedDelegations({ calendarId })
      .then((response) => {
        // console.log(
        //   `Get related Delegations response${JSON.stringify(response)}`
        // );
        if (response.Success)
          dispatch({
            type: GET_RELATED_DELEGATIONS_SUCCESS,
            payload: response.Data,
          });
        else {
          dispatch({ type: GET_RELATED_DELEGATIONS_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`Get related Delegations   error ${JSON.stringify(error)}`);
        dispatch({ type: GET_RELATED_DELEGATIONS_FAILED, payload: error });
      });
  };

export const relatedDelegationsClear = () => (dispatch) => {
  dispatch({ type: GET_RELATED_DELEGATIONS_CLEAR });
};

export const removeMultipleDelegations =
  ({ calendarId }) =>
  (dispatch) => {
    // console.log("calendarIDS", JSON.stringify({ calendarId }));
    dispatch({ type: CANCEL_MULTIPLE_DELEGATIONS_START });
    return cancelDelegations({ calendarId })
      .then((response) => {
        // console.log(
        //   `cancel Multiple delegations response${JSON.stringify(response)}`
        // );
        if (response.Success)
          dispatch({
            type: CANCEL_MULTIPLE_DELEGATIONS_SUCCESS,
            payload: response,
          });
        else {
          dispatch({
            type: CANCEL_MULTIPLE_DELEGATIONS_FAILED,
            payload: response,
          });
        }
      })
      .catch((error) => {
        // console.log(
        //   `cancel Multiple delegations   error ${JSON.stringify(error)}`
        // );
        dispatch({ type: CANCEL_MULTIPLE_DELEGATIONS_FAILED, payload: error });
      });
  };

export const removeMultipleDelegationsClear = () => (dispatch) => {
  dispatch({ type: CANCEL_MULTIPLE_DELEGATIONS_CLEAR });
};

export const getAvailableDispatch =
  ({ studentIDs }) =>
  (dispatch) => {
    dispatch({ type: GET_AVAILABLE_DISPATCH_START });
    // console.log(`Available dispatch param studentIDs${studentIDs}`);
    return getAvailableDispatchMethods({ studentIDs })
      .then((response) => {
        // console.log(`Available dispatch response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({
            type: GET_AVAILABLE_DISPATCH_SUCCESS,
            payload: response.Data,
          });
        else {
          dispatch({ type: GET_AVAILABLE_DISPATCH_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`Available dispatch   error ${JSON.stringify(error)}`);
        dispatch({ type: GET_AVAILABLE_DISPATCH_FAILED, payload: error });
      });
  };

export const getAvailableDispatchClear = () => (dispatch) => {
  dispatch({ type: GET_AVAILABLE_DISPATCH_CLEAR });
};
export const savegaurdianInfo =
  ({ item }) =>
  (dispatch) => {
    dispatch({ type: SAVE_SELECTED_GAURDIAN_DETAILS_START, payload: { item } });
  };

export const getDismissalHistoryList = (params) => (dispatch) => {
  dispatch({ type: GET_DISMISSAL_HISTORY_START });
  // console.log(`Get Dimissal History params ${JSON.stringify(params)}`);
  return getDismissalHistory(params)
    .then((response) => {
      // console.log(`Get Dimissal History response${JSON.stringify(response)}`);
      if (response.Success)
        dispatch({
          type: GET_DISMISSAL_HISTORY_SUCCESS,
          payload: { response: response.Data.CalendarEntries, params },
        });
      else {
        dispatch({ type: GET_DISMISSAL_HISTORY_FAILED, payload: response });
      }
    })
    .catch((error) => {
      // console.log(`Get Dimissal History   error ${JSON.stringify(error)}`);
      dispatch({ type: GET_DISMISSAL_HISTORY_FAILED, payload: error });
    });
};

export const getDismissalHistoryListClear = (params) => (dispatch) => {
  dispatch({ type: GET_DISMISSAL_HISTORY_CLEAR, payload: { params } });
};

export const positionInLineGet =
  ({ pickupModeId, studentId }) =>
  (dispatch) => {
    dispatch({ type: GET_POSITION_IN_LINE_START });
    return getPositionInLine({ pickupModeId, studentId })
      .then((response) => {
        // console.log(`Get Position Line response${JSON.stringify(response)}`);
        if (response)
          dispatch({ type: GET_POSITION_IN_LINE_SUCCESS, payload: response });
        else {
          dispatch({ type: GET_POSITION_IN_LINE_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(`Get Position Line error ${JSON.stringify(error)}`);
        dispatch({ type: GET_POSITION_IN_LINE_FAILED, payload: error });
      });
  };

export const positionInLineGetClear = () => (dispatch) => {
  dispatch({ type: GET_POSITION_IN_LINE_CLEAR });
};

export const getDelatesPreviousAndFuturePickups =
  ({ currenDate }) =>
  (dispatch) => {
    dispatch({ type: GET_DELEGATIONS_ENTRIES_START });
    return getPreviousAndFuturePickups({ currenDate })
      .then((response) => {
        // console.log(
        //   `Get future and previus delegations response${JSON.stringify(response)}`
        // );
        if (response.Success)
          dispatch({
            type: GET_DELEGATIONS_ENTRIES_SUCCESS,
            payload: response.Data.PriorAndFutureDelegations,
          });
        else {
          dispatch({ type: GET_DELEGATIONS_ENTRIES_FAILED, payload: response });
        }
      })
      .catch((error) => {
        // console.log(
        //   `Get future and previus delegations error ${JSON.stringify(error)}`
        // );
        dispatch({ type: GET_DELEGATIONS_ENTRIES_FAILED, payload: error });
      });
  };
export const getDelatesPreviousAndFuturePickupsClear = () => (dispatch) => {
  dispatch({ type: GET_DELEGATIONS_ENTRIES_CLEAR });
};

export const saveTodaysKidsAnnouncedList =
  (kidsAnnouncedToday) => (dispatch) => {
    dispatch({ type: TODAYS_KIDS_ANNOUNCED_LIST, payload: kidsAnnouncedToday });
  };

// Submit Early Dismissal
export const earlyDismissalSubmit = (params) => (dispatch) => {
  dispatch({ type: EARLY_DISMISSAL_START });
  //console.log("Early dismissal submit Action param-->", params);
  return submitEarlyDismissal(params)
    .then((response) => {
      // console.log(`Submit Early Dismissal response${JSON.stringify(response)}`);
      if (response.Success) {
        const Data = response.Data;
        const errorRecord = Data.find((item) => {
          return !item.Success;
        });
        if (errorRecord) {
          dispatch({ type: EARLY_DISMISSAL_FAILED, payload: errorRecord });
        } else {
          dispatch({ type: EARLY_DISMISSAL_SUCCESS, payload: response });
        }
      } else {
        dispatch({ type: EARLY_DISMISSAL_FAILED, payload: response });
      }
    })
    .catch((error) => {
      console.log(`Submit Early Dismissal error ${JSON.stringify(error)}`);
      dispatch({ type: EARLY_DISMISSAL_FAILED, payload: validateError(error) });
    });
};

export const earlyDismissalClear = () => (dispatch) => {
  dispatch({ type: EARLY_DISMISSAL_CLEAR });
};

// Cancel Early Dismissal
export const earlyDismissalCancel =
  ({ id }) =>
  (dispatch) => {
    dispatch({ type: CANCEL_EARLY_DISMISSAL_START });
    //console.log("Cancel dismissal submit Action param-->", id);
    return cancelEarlyDismissal(id)
      .then((response) => {
        // console.log(
        //   `Cancel Early Dismissal response${JSON.stringify(response)}`
        // );
        if (response.Success) {
          dispatch({ type: CANCEL_EARLY_DISMISSAL_SUCCESS, payload: response });
        } else {
          dispatch({ type: CANCEL_EARLY_DISMISSAL_FAILED, payload: response });
        }
      })
      .catch((error) => {
        //console.log(`Cancel Early Dismissal error ${JSON.stringify(error)}`);
        dispatch({
          type: CANCEL_EARLY_DISMISSAL_FAILED,
          payload: validateError(error),
        });
      });
  };

export const cancelEarlyDismissalClear = () => (dispatch) => {
  dispatch({ type: CANCEL_EARLY_DISMISSAL_CLEAR });
};

// Early Dismissal Date Range
export const getEarlyDismissalsForDateRange = (params) => (dispatch) => {
  dispatch({ type: GET_EARLY_DISMISSAL_DATE_RANGE_START });
  return dateRangeEarlyDismissal(params)
    .then((response) => {
      if (response.Success) {
        dispatch({
          type: GET_EARLY_DISMISSAL_DATE_RANGE_SUCCESS,
          payload: response.Data,
        });
      } else {
        dispatch({
          type: GET_EARLY_DISMISSAL_DATE_RANGE_FAILED,
          payload: response,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_EARLY_DISMISSAL_DATE_RANGE_FAILED,
        payload: validateError(error),
      });
    });
};

export const getEarlyDismissalsForDateRangeClear = () => (dispatch) => {
  dispatch({ type: GET_EARLY_DISMISSAL_DATE_RANGE_CLEAR });
};

// Get back to school info
export const getBackToSchoolInformation =
  ({ callback }) =>
  (dispatch) => {
    dispatch({ type: GET_BACK_SCHOOL_START });
    return getBackToSchoolInfo()
      .then((response) => {
        //console.log(`Get back to school info response${JSON.stringify(response)}`);
        if (response.Success)
          dispatch({ type: GET_BACK_SCHOOL_SUCCESS, payload: response.Data });
        else {
          dispatch({ type: GET_BACK_SCHOOL_FAILED, payload: response });
        }
        if (callback) callback(response.Data);
      })
      .catch((error) => {
        dispatch({
          type: GET_BACK_SCHOOL_FAILED,
          payload: validateError(error),
        });
        if (callback) callback([]);
      });
  };

// Save back to school info
export const saveBackToSchoolInformation =
  (backToSchoolInfoData) => (dispatch) => {
    dispatch({ type: SAVE_BACK_SCHOOL_START });
    return saveBackToSchoolInfo(backToSchoolInfoData)
      .then((response) => {
        if (response.Success)
          dispatch({ type: SAVE_BACK_SCHOOL_SUCCESS, payload: response });
        else {
          dispatch({ type: SAVE_BACK_SCHOOL_FAILED, payload: response });
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_BACK_SCHOOL_FAILED,
          payload: validateError(error),
        });
      });
  };

export const saveBackToSchoolInformationClear = () => (dispatch) => {
  dispatch({ type: SAVE_BACK_SCHOOL_CLEAR });
};

// Get Relationship Type
export const getRelationship = () => (dispatch) => {
  dispatch({ type: GET_RELATIONSHIP_START });
  return getRelationshipType()
    .then((response) => {
      if (response)
        dispatch({ type: GET_RELATIONSHIP_SUCCESS, payload: response.Data });
      else {
        dispatch({ type: GET_RELATIONSHIP_FAILED, payload: response });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_RELATIONSHIP_FAILED,
        payload: validateError(error),
      });
    });
};

// Get Fill back to school info
export const getfillBacktoschoolinfoData =
  ({ studentId, callback }) =>
  (dispatch) => {
    dispatch({ type: GET_FILL_BACKTOSCHOOL_START });
    return getFillbacktoschollinfo(studentId)
      .then((response) => {
        if (response.Success) {
          dispatch({
            type: GET_FILL_BACKTOSCHOOL_SUCCESS,
            payload: response.Data,
          });
          if (callback) callback(response.Data);
        } else {
          dispatch({ type: GET_FILL_BACKTOSCHOOL_FAILED, payload: response });
          if (callback) callback([]);
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_FILL_BACKTOSCHOOL_FAILED,
          payload: validateError(error),
        });
      });
  };

export const getSchoolDispatch =
  ({ schoolid }) =>
  (dispatch) => {
    dispatch({ type: GET_SCHOOL_DISPATCH_START });
    return getSchoolDispatchMethods({ schoolid })
      .then((response) => {
        if (response.Success)
          dispatch({
            type: GET_SCHOOL_DISPATCH_SUCCESS,
            payload: response.Data,
          });
        else {
          dispatch({ type: GET_SCHOOL_DISPATCH_FAILED, payload: response });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_SCHOOL_DISPATCH_FAILED,
          payload: validateError(error),
        });
      });
  };

export const getSchoolDispatchClear = () => (dispatch) => {
  dispatch({ type: GET_SCHOOL_DISPATCH_CLEAR });
};

// Calender view switch
export const setCalendarView =
  ({ value }) =>
  (dispatch) => {
    dispatch({ type: SET_CALENDAR_VIEW, payload: value });
  };
export const setCalendarViewClear = () => (dispatch) => {
  dispatch({ type: SET_CALENDAR_VIEW_CLEAR });
};
