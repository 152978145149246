import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getNotificationsList,
  notificationsListClear,
} from "../../common/actions/userAccount";
import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";
import { createLoadingSelector } from "../../common/reducers/selectors";
import RingLoader from "react-spinners/ClipLoader";
import InfiniteScroll from "react-infinite-scroll-component";
const notificationFilter = [
  {
    id: 1,
    name: "Delegation_Text",
    key: "delegation",
  },
  {
    id: 3,
    name: "Transactional_Text",
    key: "transactionalMessage",
  },
  {
    id: 5,
    name: "Absentee_Text",
    key: "absentee",
  },
  {
    id: 6,
    name: "early_dismissal_label",
    key: "earlyDismissal",
  },
  {
    id: 10,
    name: "Manual_Parent_Text",
    key: "manualParentNotification",
  },
  {
    id: 11,
    name: "Present_Text",
    key: "present",
  },
  {
    id: 12,
    name: "check-ins",
    key: "parentCheckInReminder",
  },
];
const loadingSelector = createLoadingSelector(["get_notifications"]);

class NotificationsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilterType: 0,
      showMenu: false,
    };
    this.getNotifications(true);
  }

  componentDidMount() {
    this.dosetHelpList();
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Settings",
      screen: GLOBAL_CONST.SCREEN_NAMES.NOTIFICATIONS_SCREEN,
      title: this.props.t("notificationScr-enablePush"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  componentWillUnmount() {
    const { doNotificationsListClear } = this.props;
    doNotificationsListClear();
  }

  clearAll = () => {
    this.showFilterOptions;
    this.setState({ selectedFilterType: 0 }, () => {
      this.getNotifications(true);
    });
  };

  showFilterOptions = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  selectFilterType = (value) => {
    this.setState({ selectedFilterType: parseInt(value) });
  };

  getNotifications = (reload) => {
    const { doGetNotificationsList, notifications } = this.props;
    const { selectedFilterType } = this.state;
    let LastNotificationID = 0;
    if (reload === false && notifications.length > 0) {
      LastNotificationID =
        notifications[notifications.length - 1].NotificationID;
    }
    doGetNotificationsList({
      LastNotificationID,
      notificationTypeId: selectedFilterType,
    });
  };

  loadMoreRecords = () => {
    const { isFetching, newFetchedNotifications } = this.props;
    if (isFetching) return;
    if (newFetchedNotifications.length < 25) return;
    this.getNotifications(false);
  };

  hasMore = () => {
    const { isFetching, newFetchedNotifications } = this.props;
    let needLoad = true;
    if (isFetching) needLoad = false;
    if (newFetchedNotifications.length < 25) needLoad = false;

    return needLoad;
  };

  showNotificationFilter = () => {
    const { selectedFilterType } = this.state;
    const filterType = notificationFilter.map((item) => (
      <div
        className={
          selectedFilterType == item.id
            ? "notificationItem selectedNotiType selected-data custom-select-bx"
            : "custom-select-bx"
        }
        key={item.id}
        onClick={() => {
          this.selectFilterType(item.id);
        }}
      >
        <span className="custom-select-label">{this.props.t(item.name)}</span>
      </div>
    ));
    return <div>{filterType}</div>;
  };

  renderNotification = () => {
    const { notifications, isFetching } = this.props;
    const { showMenu } = this.state;

    return (
      <div
        className="modal-dialog"
        role="document"
        onClick={(event) => {
          this.setState({ showMenu: false });
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">
                {this.props.t("notificationScr-enablePush")}
              </h4>
              <button
                type="button"
                className="btn help-icon-btn"
                onClick={() => {
                  this.openHelpModal();
                }}
              >
                {this.props.t("regNameScr-needHelp")}
              </button>
            </div>
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>
          <div className="modal-body">
            {notifications.length === 0 && isFetching ? (
              <div className="loaderHolder mt-30">
                <RingLoader color="#2d3d56" loading={isFetching} size={60} />
              </div>
            ) : null}

            <div className="notification-dropdown-holder">
              <div className="showNotificationList">
                <label className="Showing">
                  {this.props.t("notificationScr-showing")}
                </label>
                <button
                  className="All-notifications"
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.showFilterOptions();
                  }}
                >
                  {" "}
                  {this.props.t("Receive_Notifications")}{" "}
                  <i className="fas fa-chevron-down arrow"></i>
                </button>
              </div>

              {showMenu === true ? (
                <div
                  className="notificationFilter"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  {this.showNotificationFilter()}
                  <button
                    type="button"
                    className="notificationfilterSubmit"
                    onClick={this.loadRecordWithCategory}
                  >
                    <font className="filterApplyText">
                      {this.props.t("Apply")}
                    </font>{" "}
                  </button>
                  <button
                    type="button"
                    className="notificationFilterCancel"
                    onClick={this.clearAll}
                  >
                    <span className="text-button">
                      {this.props.t("Clear_Filter_Text")}
                    </span>{" "}
                  </button>
                </div>
              ) : null}
            </div>

            {notifications.length === 0 && !isFetching ? (
              <div>
                <br />
                <br />
                <h6>{this.props.t("No_Data_Text")}</h6>
              </div>
            ) : (
              <div className="notifications-list">
                <div className="notificationHolder">
                  <InfiniteScroll
                    dataLength={notifications.length}
                    next={this.loadMoreRecords}
                    style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
                    hasMore={this.hasMore()}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="modalScrollView"
                  >
                    {notifications.map((item, index) => (
                      <div className="notification-item" key={index + "-" + item.NotificationID}>
                        <div className="notification-text">
                          <p>{item.notificationDescription}</p>
                          {item.linkUrl && item.linkUrl && (
                            <p>
                              <a
                                href={item.linkUrl}
                                className="openLink"
                                rel="noreferrer"
                                target="_blank"
                              >
                                {item.linkUrl}
                              </a>
                            </p>
                          )}
                        </div>
                        <div className="about-notification">
                          <span className="school-name">{item.Schoolname}</span>
                          <span className="notif-date">{item.dateTime}</span>
                        </div>
                        {item.linkUrl && item.linkUrl && (
                          <a
                            href={item.linkUrl}
                            className="divLink"
                            rel="noreferrer"
                            target="_blank"
                          ></a>
                        )}
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  buildListing = () => {
    const { notifications } = this.props;
    const elements = [];
    notifications.map((item) =>
      elements.push(
        <li key={item.NotificationID}>
          <div className="notification-text">
            <p>{item.notificationDescription}</p>
            {item.linkUrl && item.linkUrl && (
              <p>
                <a
                  href={item.linkUrl}
                  className="openLink"
                  rel="noreferrer"
                  target="_blank"
                >
                  {item.linkUrl}
                </a>
              </p>
            )}
          </div>
          <div className="about-notification">
            <span className="school-name">{item.Schoolname}</span>
            <span className="notif-date">{item.dateTime}</span>
          </div>
          {item.linkUrl && item.linkUrl && (
            <a
              href={item.linkUrl}
              className="divLink"
              rel="noreferrer"
              target="_blank"
            ></a>
          )}
        </li>
      )
    );
    return elements;
  };
  loadRecordWithCategory = () => {
    const { isFetching, doNotificationsListClear } = this.props;
    if (isFetching) return;
    doNotificationsListClear();
    this.setState({ showMenu: false });
    this.getNotifications(true);
  };

  render() {
    return this.renderNotification();
  }

  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };
}

function mapStateToProps(state) {
  return {
    notifications: state.userAccount.notifications,
    isFetching: loadingSelector(state),
    error: state.userAccount.error,
    newFetchedNotifications: state.userAccount.newFetchedNotifications,
    setHelpListData: state.userAccount.setHelpListData,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doGetNotificationsList: (data) => dispatch(getNotificationsList(data)),
    doNotificationsListClear: () => dispatch(notificationsListClear()),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsList)
);
