import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";
import {
  notificationSetting,
  notificationSettingUpdate,
  notificationSettingUpdateClear,
  notificationSettingClear,
} from "../../common/actions/userAccount";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createLoadingSelector } from "../../common/reducers/selectors";
import { getMyStyle } from "../../theme/ThemeStyle";
const loadingSelector = createLoadingSelector(["update_notification_setting"]);
class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: false,
      delegationsNotifications: false,
      statusNotification: false,
      emailNotification: false,
      attendanceNotification: false,
      checkinNotification: false,
    };

    this.getNotificationStting();
  }

  componentDidMount() {
    this.dosetHelpList();
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Settings",
      screen: GLOBAL_CONST.SCREEN_NAMES.SETTING_NOTIFICATION,
      title: this.props.t("settingsScr-settings"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  getNotificationStting = () => {
    const { doNotificationSetting } = this.props;
    doNotificationSetting();
  };

  loadSettings = () => {
    const { settingNotifications } = this.props;
    if (settingNotifications !== null) {
      this.setState({
        notification: settingNotifications.ReceiveNotification,
        delegationsNotifications:
          settingNotifications.ReceiveDelegationNotification,
        statusNotification: settingNotifications.ReceiveStatusnnotification,
        emailNotification: settingNotifications.ReceiveEmailNotification,
        attendanceNotification: settingNotifications.ReceivePresentNotification,
        checkinNotification:
          settingNotifications.ReceiveNotificationForParentCheckInReminder,
      });
    }
  };

  updateSetting = () => {
    const {
      notification,
      delegationsNotifications,
      statusNotification,
      emailNotification,
      attendanceNotification,
      checkinNotification,
    } = this.state;
    const { doNotificationSettingUpdate, userLoginInfo } = this.props;

    confirmAlert({
      title: this.props.t("settingsScr-saveSettings"),
      message: this.props.t("settingsScr-saveSettingConfirmAlert"),
      buttons: [
        {
          label: this.props.t("settingsScr-submit"),
          onClick: () => {
            doNotificationSettingUpdate({
              receiveStatusnnotification: statusNotification,
              receiveDelegationNotification: delegationsNotifications,
              receiveEmailNotification: emailNotification,
              receiveNotification: notification,
              receiveNotificationForParentCheckInReminder: checkinNotification,
              receivePresentNotification: attendanceNotification,
              authToken: userLoginInfo.AuthTokenID,
            });
          },
        },
        {
          label: this.props.t("regNameScr-cancel"),
          className: "cancel-btn",
        },
      ],
    });
  };

  componentDidUpdate() {
    const {
      doNotificationSettingUpdateClear,
      updateNotificationSetting,
      error,
      settingNotifications,
      doNotificationSettingClear,
    } = this.props;

    if (settingNotifications !== null) {
      this.loadSettings();
      doNotificationSettingClear();
    }
    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doNotificationSettingUpdateClear();
    } else if (updateNotificationSetting) {
      toast.success(this.props.t("settingsScr-saveSettingSuceess"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doNotificationSettingUpdateClear();
      this.props.doHideModal();
    }
  }

  renderSettingsView = () => {
    const {
      notification,
      delegationsNotifications,
      statusNotification,
      emailNotification,
      attendanceNotification,
      checkinNotification,
    } = this.state;
    const { isFetching } = this.props;
    const styles = getMyStyle(this.props.selectedTheme);
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">
                {this.props.t("settingsScr-settings")}
              </h4>
              <button
                type="button"
                className="btn help-icon-btn"
                onClick={() => {
                  this.openHelpModal();
                }}
              >
                {this.props.t("regNameScr-needHelp")}
              </button>
            </div>
            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>
          <div className="modal-body">
            <form className="form-style1">
              <div className="form-group">
                <ul className="settings-list">
                  <li className="settings-item">
                    <span className="text">
                      {" "}
                      {this.props.t("notificationScr-enablePush")}
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={notification}
                        value={notification}
                        aria-label={this.props.t("notificationScr-enablePush")}
                        onChange={() => {
                          this.setState({ notification: !notification }, () => {
                            if (!this.state.notification) {
                              alert(
                                this.props.t(
                                  "settingsScr-notificationsTurnedONAlert"
                                )
                              );
                            }
                            this.setState({
                              delegationsNotifications: this.state.notification,
                              statusNotification: this.state.notification,
                              emailNotification: this.state.notification,
                              attendanceNotification: this.state.notification,
                              checkinNotification: this.state.notification,
                            });
                          });
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </li>
                  <li className="settings-item">
                    <span className="text">
                      {" "}
                      {this.props.t("Delegation_Notifications")}
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={delegationsNotifications}
                        value={delegationsNotifications}
                        aria-label={this.props.t("Delegation_Notifications")}
                        onChange={() => {
                          this.setState({
                            delegationsNotifications: !delegationsNotifications,
                          });
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </li>
                  <li className="settings-item">
                    <span className="text">
                      {" "}
                      {this.props.t("Status_Notifications")}
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={statusNotification}
                        value={statusNotification}
                        aria-label={this.props.t("Status_Notifications")}
                        onChange={() => {
                          this.setState({
                            statusNotification: !statusNotification,
                          });
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </li>
                  <li className="settings-item">
                    <span className="text">
                      {this.props.t("Email_Notifications")}
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={emailNotification}
                        value={emailNotification}
                        aria-label={this.props.t("Email_Notifications")}
                        onChange={() => {
                          this.setState({
                            emailNotification: !emailNotification,
                          });
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </li>
                  <li className="settings-item">
                    <span className="text">
                      {this.props.t("Attendance_Notifications")}
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={attendanceNotification}
                        value={attendanceNotification}
                        aria-label={this.props.t("Attendance_Notifications")}
                        onChange={() => {
                          this.setState({
                            attendanceNotification: !attendanceNotification,
                          });
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </li>
                  <li className="settings-item">
                    <span className="text">
                      {this.props.t("checkin_notification")}
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={checkinNotification}
                        value={checkinNotification}
                        aria-label={this.props.t("checkin_notification")}
                        onChange={() => {
                          this.setState({
                            checkinNotification: !checkinNotification,
                          });
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </li>
                </ul>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={styles.buttonDefault}
                  onClick={() => {
                    this.updateSetting();
                  }}
                >
                  {isFetching ? (
                    <i className="fa-solid fa-arrows-rotate fa-spin"></i>
                  ) : null}{" "}
                  {this.props.t("changePickupScr-save")}
                </button>
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  };
  closeModal = () => {
    this.props.doHideModal();
  };
  toggleDelegationStiing = () => {
    const { delegationsNotifications } = this.state;
    this.setState({
      delegationsNotifications: !delegationsNotifications,
    });
  };

  render() {
    return this.renderSettingsView();
  }
}

function mapStateToProps(state) {
  return {
    settingNotifications: state.userAccount.settingNotifications,
    error: state.userAccount.error,
    updateNotificationSetting: state.userAccount.updateNotificationSetting,
    userLoginInfo: state.userAccount.userLoginInfo,
    setHelpListData: state.userAccount.setHelpListData,
    isFetching: loadingSelector(state),
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doNotificationSetting: () => dispatch(notificationSetting()),
    doNotificationSettingUpdate: (data) =>
      dispatch(notificationSettingUpdate(data)),
    doNotificationSettingUpdateClear: () =>
      dispatch(notificationSettingUpdateClear()),
    doNotificationSettingClear: () => dispatch(notificationSettingClear()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Setting)
);
