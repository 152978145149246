import {
  GET_SCHOOL_PICKUP_TIME_START,
  GET_SCHOOL_PICKUP_TIME_SUCCESS,
  GET_SCHOOL_PICKUP_TIME_FAILED,
  GET_SCHOOL_PICKUP_MODE_START,
  GET_SCHOOL_PICKUP_MODE_SUCCESS,
  GET_SCHOOL_PICKUP_MODE_FAILED,
  GET_ABSENT_REASON_START,
  GET_ABSENT_REASON_SUCCESS,
  GET_ABSENT_REASON_FAILED,
  GET_SCHOOL_AFTER_SCHOOL_START,
  GET_SCHOOL_AFTER_SCHOOL_SUCCESS,
  GET_SCHOOL_AFTER_SCHOOL_FAILED,
  GET_SCHOOL_PICKUP_TIME_CLEAR,
  GET_HELP_START,
  GET_HELP_SUCCESS,
  GET_HELP_FAILED,
  GET_HELP_CLAER,
  GET_SCHOOLCALENDAR_START,
  GET_SCHOOLCALENDAR_SUCCESS,
  GET_SCHOOLCALENDAR_FAILED,
  GET_SCHOOLCALENDAR_CLEAR,
  GET_SCHOOLCALENDAR_EVENT_START,
  GET_SCHOOLCALENDAR_EVENT_SUCCESS,
  GET_SCHOOLCALENDAR_EVENT_FAILED,
  GET_SCHOOLCALENDAR_EVENT_CLAER,
} from "../actions/school/schoolActionType";
import { LOGOUT_USER } from "../actions/userAccount/userActionType";

export const INITIAL_STATE = {
  schoolDismissalDetails: null,
  schoolPickupModeDetails: [],
  absentReasonResponse: null,
  getAfterSchoolRecord: [],
  helpRecord: null,
  error: null,
  schoolCalendar: null,
  schoolCalendarEvent: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...INITIAL_STATE,
      };

    case GET_SCHOOL_PICKUP_TIME_START:
      return { ...state, error: null, schoolDismissalDetails: null };
    case GET_SCHOOL_PICKUP_TIME_SUCCESS:
      return {
        ...state,
        schoolDismissalDetails: action.payload,
        error: null,
      };
    case GET_SCHOOL_PICKUP_TIME_FAILED:
      return {
        ...state,
        schoolDismissalDetails: null,
        error: action.payload,
      };
    case GET_SCHOOL_PICKUP_TIME_CLEAR:
      return {
        ...state,
        schoolDismissalDetails: null,
        error: null,
      };
    case GET_SCHOOL_PICKUP_MODE_START:
      return { ...state, error: null, schoolPickupModeDetails: [] };
    case GET_SCHOOL_PICKUP_MODE_SUCCESS:
      return {
        ...state,
        schoolPickupModeDetails: action.payload,
        error: null,
      };
    case GET_SCHOOL_PICKUP_MODE_FAILED:
      return {
        ...state,
        schoolPickupModeDetails: [],
        error: action.payload,
      };
    case GET_ABSENT_REASON_START:
      return { ...state, error: null, absentReasonResponse: null };
    case GET_ABSENT_REASON_SUCCESS:
      return {
        ...state,
        absentReasonResponse: action.payload,
        error: null,
      };
    case GET_ABSENT_REASON_FAILED:
      return {
        ...state,
        absentReasonResponse: null,
        error: action.payload,
      };
    case GET_SCHOOL_AFTER_SCHOOL_START:
      return { ...state, error: null, getAfterSchoolRecord: [] };
    case GET_SCHOOL_AFTER_SCHOOL_SUCCESS:
      return {
        ...state,
        getAfterSchoolRecord: action.payload,
        error: null,
      };
    case GET_SCHOOL_AFTER_SCHOOL_FAILED:
      return {
        ...state,
        getAfterSchoolRecord: [],
        error: action.payload,
      };
    case GET_HELP_START:
      return { ...state, error: null, helpRecord: null };
    case GET_HELP_SUCCESS:
      // console.log("GET_HELP_SUCCESS ", action.payload)
      return {
        ...state,
        helpRecord: action.payload,
        error: null,
      };
    case GET_HELP_FAILED:
      return {
        ...state,
        helpRecord: null,
        error: action.payload,
      };
    case GET_HELP_CLAER:
      return {
        ...state,
        helpRecord: null,
        error: null,
      };
      case GET_SCHOOLCALENDAR_START:
        return {
          ...state,
          error: null,
          schoolCalendar: null,
        };
      case GET_SCHOOLCALENDAR_SUCCESS: {
        let schoolCalendar = [...action.payload];
        schoolCalendar = schoolCalendar.sort((a, b) => new Date(a.Date) - new Date(b.Date));
        return {
          ...state,
          schoolCalendar,
          error: null,
        };
      }
      case GET_SCHOOLCALENDAR_FAILED:
        return {
          ...state,
          schoolCalendar: null,
          error: action.payload,
        };
      case GET_SCHOOLCALENDAR_CLEAR:
        return {
          ...state,
          schoolCalendar: null,
          error: null,
        };
      case GET_SCHOOLCALENDAR_EVENT_START:
        return {
          ...state,
          error: null,
          schoolCalendarEvent: [],
        };
      case GET_SCHOOLCALENDAR_EVENT_SUCCESS:
        return {
          ...state,
          schoolCalendarEvent: action.payload,
          error: null,
        };
      case GET_SCHOOLCALENDAR_EVENT_FAILED:
        return {
          ...state,
          schoolCalendarEvent: [],
          error: action.payload,
        };
      case GET_SCHOOLCALENDAR_EVENT_CLAER:
        return {
          ...state,
          schoolCalendarEvent: [],
          error: null,
        };
    default:
      return state;
  }
};
