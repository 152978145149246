import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dom } from "@fortawesome/fontawesome-svg-core";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import MultikidsPickupSummaryView from "./MultikidsPickupSummaryView";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Colors } from "../../../../theme";

import {
  filterByTypes,
  getKidsName,
  getMarkStyle,
  getCurrentThemeColor,
} from "../../../../helper/utility";

import { Dropdown } from "react-bootstrap";

import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
} from "../../../../common/actions/userAccount";
import {
  getPickupEntries,
  getPickupEntriesClear,
  getEarlyDismissalsForDateRange,
  getEarlyDismissalsForDateRangeClear,
} from "../../../../common/actions/students";
import GLOBAL_CONST from "../../../../helper/AppConstants";

import { Line } from "rc-progress";
import "../../styles/style.css";
import MultikidsDismissalSelectHow from "./MultikidsDismissalSelectHow";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { withTranslation } from "react-i18next";
import { createLoadingSelector } from "../../../../common/reducers/selectors";
const loadingSelector = createLoadingSelector(["change_delegation"]);

var currentDate = new Date();
var numberOfDaysToAdd = 5;
const daysHighlighted = new Array(numberOfDaysToAdd).fill(currentDate);
const DISPLAY_PER_PAGE = 15;
var SelectedWeekDays = [];

class MultikidsSelectWhen extends React.Component {
  constructor(props) {
    super(props);

    const DOW = [
      { id: 0, DOW: "SU", value: "sunday", isSelected: false },
      { id: 1, DOW: "MO", value: "monday", isSelected: false },
      { id: 2, DOW: "TU", value: "tuesday", isSelected: false },
      { id: 3, DOW: "WE", value: "wednesday", isSelected: false },
      { id: 4, DOW: "TH", value: "thursday", isSelected: false },
      { id: 5, DOW: "FR", value: "friday", isSelected: false },
      { id: 6, DOW: "SA", value: "saturday", isSelected: false },
    ];

    let selectDismissalDate = this.props.selectDate;
    let markedDates = this.props.markedDates;

    const school = this.props.school;
    const firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);

    if (!selectDismissalDate) selectDismissalDate = new Date();
    if (moment(selectDismissalDate).isBefore(moment()))
      selectDismissalDate = new Date();

    const isSelectedPriorToFirstDay = moment(firstDayOfSchool).isAfter(
      moment(selectDismissalDate)
    );

    if (isSelectedPriorToFirstDay) selectDismissalDate = firstDayOfSchool;

    let selectedTillDate = new Date(selectDismissalDate);
    selectedTillDate.setHours(23);
    selectedTillDate.setMinutes(59);
    selectedTillDate = selectedTillDate.setDate(selectedTillDate.getDate() + 7);

    this.state = {
      DOW,
      isRepeat: false,
      selectedDate: selectDismissalDate,
      selectedTillDate,
      selectedPreviousDate: selectDismissalDate,
      selectedPreviousTillDate: selectedTillDate,
      selectedOption: 0,
      previousSelectedOptions: null,
      repeatOptions: this.buildRepeatOptions(selectDismissalDate),
      isFromDateTimePickerVisible: false,
      isUntilDateTimePickerVisible: false,
      recurringDates: [],
      markedDates: {},
      lastFetchedDate: null,
      currentPageIndex: 0,
      pageIndexes: [],
      manuallExcludedDates: {},
      showCalendarPicker: false,
      showUntillCalendarPicker: false,
      showSelectHowUIFlag: false,
      showPickupSummary: false,
      selectedDateType: "from",
    };
    this.dismissalSelectHow = React.createRef();
  }

  componentDidMount() {
    this.dosetHelpList();
    this.getDismissalsList();
  }
  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "ChildManagement",
      screen: GLOBAL_CONST.SCREEN_NAMES.SELECT_WHEN,
      title: this.props.t("selectWhenScr-selectWhen"),
    });
  };

  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  getDismissalsList = () => {
    const {
      doGetPickupEntries,
      school,
      child,
      doGetEarlyDismissalsForDateRange,
      selectedKids,
    } = this.props;
    const { isLastDayOfSchoolPast } = this.state;

    let selectedKidsIds = selectedKids.map((child) => {
      return child.StudentID;
    });

    let firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);

    let monthStart = new Date();
    const diffFirstDayofSchool = moment(firstDayOfSchool).diff(
      moment(),
      "months"
    );

    if (diffFirstDayofSchool < -12) {
      endDate = moment().add(12, "M").format("MM-DD-YYYY");
      monthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    } else {
      monthStart = firstDayOfSchool;
    }

    let monthEnd = new Date(school.Dates.LastDayOfSchool);

    var startDate = moment(monthStart).format("MM-DD-YYYY");
    var endDate = moment(monthEnd).format("MM-DD-YYYY");

    if (isLastDayOfSchoolPast) {
      endDate = moment().add(12, "M").format("MM-DD-YYYY");
    }

    doGetPickupEntries({
      studentID: selectedKidsIds[0],
      startDate,
      endDate,
    });

    var eStartDate = moment(monthStart).format("YYYY-MM-DDTHH:mm:ss");
    var eEndDate = moment(monthEnd).format("YYYY-MM-DDTHH:mm:ss");

    doGetEarlyDismissalsForDateRange({
      StudentID: selectedKidsIds[0],
      StartDate: eStartDate,
      EndDate: eEndDate,
    });
  };

  getSelectDateDayRecord() {
    const { selectedDate } = this.state;

    const markedDates = { ...this.state.markedDates };

    // console.log("marked Dates--> ",JSON.stringify(markedDates))
    let currentList = [];
    var selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");

    for (const key in markedDates) {
      if (key === selectDateStr) {
        // console.log(
        //   `****************** getDayRecord ${selectDateStr} key ${key} CalendarDate ${markedDates[key].record.CalendarDate}`
        // );
        var selectedMarked = {
          selected: true,
          selectedColor: Colors.blue,
          className: [markedDates[key].className + " " + "selectedDate"],
          record: markedDates[key].record,
        };
        //alert(selectedMarked.record.CalendarDate);
        markedDates[key] = selectedMarked;
      } else {
        if (markedDates[key] && markedDates[key].record) {
          markedDates[key] = getMarkStyle(markedDates[key].record);
        }
      }
    }

    if (markedDates[selectDateStr]) {
      currentList.push(markedDates[selectDateStr]);
    }

    this.setState({ markedDates, currentList });
  }

  getDayRecord(type) {
    const { selectedDate, selectedTillDate } = this.state;
    const setDate = type === "from" ? selectedDate : selectedTillDate;
    const markedDates = { ...this.state.markedDates };
    var selectDateStr = moment(new Date(setDate)).format("YYYY-MM-DD");

    for (const key in markedDates) {
      if (key === selectDateStr) {
        var selectedMarked = {
          selected: true,
          selectedColor: Colors.blue,
          record: markedDates[key].record,
        };
        markedDates[key] = selectedMarked;
      } else {
        markedDates[key] = getMarkStyle(markedDates[key].record);
      }
    }
    this.setState({ markedDates });
  }

  buildRepeatOptions = (selectedDate) => {
    const options = [
      { label: this.props.t("selectWhenScr-none"), value: 0 },
      { label: this.props.t("selectWhenScr-daily"), value: 1 },
    ];
    const weekDay = moment(selectedDate).format("dddd");
    const monthDay = moment(selectedDate).format("Do");
    options.push({
      label: this.props.t("selectWhenScr-recWeekly") + " " + weekDay,
      value: 2,
    });
    options.push({
      label: this.props.t("selectWhenScr-recMonthly") + " " + monthDay,
      value: 3,
    });
    options.push({
      label: this.props.t("selectWhenScr-recWeekdays"),
      value: 4,
    });

    return options;
  };

  selectRecurringOption = (value) => {
    const { repeatOptions } = this.state;
    for (let i = 0; i < repeatOptions.length; i++) {
      if (value == repeatOptions[i].value) return repeatOptions[i].label;
    }
  };

  setRepeatType = (value) => {
    this.setState({ selectedOption: Number(value) });
    this.setRecurringWeekly(value);
    if (value != 0) {
      this.setState(
        {
          isRepeat: true,
        },
        this.buildRecurringDates
      );
    } else {
      this.setState({ isRepeat: false }, this.buildRecurringDates);
    }
  };

  setRecurringWeekly = (selectedOption) => {
    if (selectedOption === 4) {
      const DOW = [
        { id: 0, DOW: "SU", value: "sunday", isSelected: false },
        { id: 1, DOW: "MO", value: "monday", isSelected: true },
        { id: 2, DOW: "TU", value: "tuesday", isSelected: true },
        { id: 3, DOW: "WE", value: "wednesday", isSelected: true },
        { id: 4, DOW: "TH", value: "thursday", isSelected: true },
        { id: 5, DOW: "FR", value: "friday", isSelected: true },
        { id: 6, DOW: "SA", value: "saturday", isSelected: false },
      ];
      this.setState({ DOW });
    } else if (selectedOption === 5) {
      const DOW = [
        { id: 0, DOW: "SU", value: "sunday", isSelected: false },
        { id: 1, DOW: "MO", value: "monday", isSelected: false },
        { id: 2, DOW: "TU", value: "tuesday", isSelected: false },
        { id: 3, DOW: "WE", value: "wednesday", isSelected: false },
        { id: 4, DOW: "TH", value: "thursday", isSelected: false },
        { id: 5, DOW: "FR", value: "friday", isSelected: false },
        { id: 6, DOW: "SA", value: "saturday", isSelected: false },
      ];
      this.setState({ DOW });
    }
  };

  buildRecurringDates = () => {
    const { selectedOption, selectedDate, selectedTillDate } = this.state;
    let { recurringDates } = this.state;
    if (selectedOption === 0) recurringDates = [];
    if (selectedOption == 1)
      recurringDates = this.getDailyRecurringDates(
        selectedDate,
        selectedTillDate
      ).actualDates;
    else if (selectedOption > 1)
      recurringDates = this.getRecurringDates(
        selectedDate,
        selectedTillDate
      ).actualDates;

    this.setState(
      {
        recurringDates,
        manuallExcludedDates: {},
        currentPageIndex: 0,
      },
      () => {
        //this.setSelectedDateMarked();
      }
    );
  };

  getDailyRecurringDates = (startDate, endDate) => {
    let start = moment(startDate);
    let end = moment(endDate);
    let formatedDates = [];
    let actualDates = [];
    const pageIndexes = [];
    pageIndexes.push(0);
    let index = 0;
    for (var m = moment(start); m.isBefore(end); m.add(1, "days")) {
      formatedDates.push(m.format("MM/DD/YYYY"));
      actualDates.push(m.toDate());
      index++;
      if (index % DISPLAY_PER_PAGE === 0) {
        pageIndexes.push(index);
      }
    }

    return {
      formatedDates,
      actualDates,
    };
  };

  getRecurringDates = (startDate, endDate) => {
    const { selectedOption } = this.state;
    if (selectedOption === 4 || selectedOption === 5) {
      return this.recurringWeekdays(startDate, endDate);
    } else {
      const start = moment(startDate);
      const end = moment(endDate);
      let formatedDates = [];
      let actualDates = [];
      const current = start.clone();
      formatedDates.push(start.format("MM/DD/YYYY"));
      actualDates.push(start);
      const unit = selectedOption === 2 ? "d" : "M";
      const value = selectedOption === 2 ? 7 : 1;

      const pageIndexes = [];

      pageIndexes.push(0);
      let index = 0;

      while (current.add(value, unit).isSameOrBefore(end)) {
        const nextDate = current.clone();
        formatedDates.push(nextDate.format("MM/DD/YYYY"));
        actualDates.push(nextDate);
        index++;
        if (index % DISPLAY_PER_PAGE === 0) {
          pageIndexes.push(index);
        }
      }

      return {
        formatedDates,
        actualDates,
      };
    }
  };

  recurringWeekdays = (startDate, endDate) => {
    const { DOW } = this.state;
    let start = moment(startDate);
    let end = moment(endDate);

    const selectedDays = [];

    for (var i = 0; i < DOW.length; i++) {
      if (DOW[i].isSelected) selectedDays.push(DOW[i].DOW.toLowerCase());
    }
    let dt = start;
    let formatedDates = [];
    let actualDates = [];
    const pageIndexes = [];

    pageIndexes.push(0);
    let index = 0;

    while (dt <= end) {
      const isExists = selectedDays.includes(
        moment(dt).format("dd").toLowerCase()
      );
      if (isExists) {
        formatedDates.push(moment(dt).format("MM/DD/YYYY"));
        actualDates.push(dt);
        index++;
        if (index % DISPLAY_PER_PAGE === 0) {
          pageIndexes.push(index);
        }
      }
      const newDt = new Date(dt);
      newDt.setDate(newDt.getDate() + 1);
      dt = newDt;
    }

    return {
      formatedDates,
      actualDates,
    };
  };
  buttonclick() {
    const { x } = this.state;
  }

  onFromDateSelected = (date) => {
    this.hideLegendView();

    var newDate = date.setHours(0, 1, 0, 0);
    let { selectedTillDate } = this.state;
    const isFuture = moment(selectedTillDate).isAfter(moment(newDate));

    if (!isFuture) {
      selectedTillDate = date.setDate(date.getDate() + 1);
    }

    this.setState(
      {
        selectedDate: date,
        repeatOptions: this.buildRepeatOptions(date),
        selectedTillDate,
        selectedDateType: "from",
      },
      () => {
        this.getDayRecord("from");
        this.buildRecurringDates();
      }
    );
  };
  onToDateSelected = (date) => {
    this.hideLegendView();
    const dateSelected = date.setHours(23, 59, 0, 0);
    this.setState(
      { selectedTillDate: dateSelected, selectedDateType: "to" },
      () => {
        this.getDayRecord("to");
        this.buildRecurringDates();
      }
    );
  };
  hideLegendView = (e) => {
    this.setState({
      showCalendarPicker: false,
      showUntillCalendarPicker: false,
    });
  };
  hideUntilLegendView = (e) => {
    this.setState({
      showUntillCalendarPicker: false,
      showCalendarPicker: false,
    });
  };

  showLegendView = (e) => {
    this.setState({
      showCalendarPicker: true,
      showUntillCalendarPicker: false,
    });
  };
  showUntillLegendView = (e) => {
    this.setState({
      showUntillCalendarPicker: true,
      showCalendarPicker: false,
    });
  };

  showSelectHowUI = (e) => {
    const { school } = this.props;
    const { selectedDate } = this.state;

    if (
      school.ReunificationEnabled &&
      moment(selectedDate).isSame(moment(), "date")
    ) {
      alert(this.props.t("emergency_reunification_pickup_msg"));
      return;
    } else {
      this.setState({ showSelectHowUIFlag: !this.state.showSelectHowUIFlag });
      this.dosetHelpList();
    }
  };

  renderLegendUI = () => {
    return (
      <div className={`show-legend untilLegend`}>
        <div className="legend-holder">
          <h5 className="legend-title">{this.props.t("Legend")}</h5>
          <ul className="unstyle-list legend-list">
            <li className="green-legend">
              {this.props.t("Approved_Pickup_Change_Text")}
            </li>
            <li className="black-legend">
              {this.props.t("Approved_EarlyPickup_Change_Text")}
            </li>
            <li className="red-legend">{this.props.t("Mark_Absent_Text")}</li>
            <li className="orange-legend">
              {this.props.t("Pending_Approval_Text_p")}
            </li>
            <li className="gray-legend">
              {this.props.t("Pending_Approval_Text_e")}
            </li>

            <h6 className="text_italic">
              {this.props.t("Pending_Approval_Text2")}
            </h6>
          </ul>
        </div>
      </div>
    );
  };

  saveChangesManuallExcludedDates = (manuallExcludedDates) => {
    this.setState({ manuallExcludedDates });
  };

  getSelectHowUI = () => {
    const { school, selectedKids } = this.props;
    const {
      selectedDate,
      selectedTillDate,
      selectedOption,
      isRepeat,
      manuallExcludedDates,
      recurringDates,
      currentPageIndex,
      pageIndexes,
      markedDates,
    } = this.state;

    let selectData = {};

    if (isRepeat === false) {
      selectData = {
        selectedDate: [moment(selectedDate).format("MM/DD/YYYY")],
        selectedOption: 0,
        showDate: selectedDate,
      };
      const selectRecurringOption = this.selectRecurringOption(
        this.state.selectedOption
      );

      return (
        <MultikidsDismissalSelectHow
          onRef={(ref) => (this.dismissalSelectHow = ref)}
          selectData={selectData}
          doCallbackRefresh={this.props.doCallbackRefresh}
          selectedDate={selectedDate}
          selectedTillDate={selectedTillDate}
          recurringDates={recurringDates}
          selectedOption={selectedOption}
          selectRecurringOption={selectRecurringOption}
          currentPageIndex={currentPageIndex}
          pageIndexes={pageIndexes}
          markedDates={markedDates}
          manuallExcludedDates={manuallExcludedDates}
          saveChangesManuallExcludedDates={this.saveChangesManuallExcludedDates}
          saveChangesCurrentPageIndex={this.saveChangesCurrentPageIndex}
          goBack={() => {
            this.setState({ showSelectHowUIFlag: false });
          }}
          selectedKids={selectedKids}
          school={this.props.school}
        />
      );
    } else {
      selectData = {
        selectedDate:
          selectedOption == 1
            ? this.getDailyRecurringDates(selectedDate, selectedTillDate)
                .formatedDates
            : this.getRecurringDates(selectedDate, selectedTillDate)
                .formatedDates,
        selectedOption,
        showDate: selectedDate,
      };
      const selectRecurringOption = this.selectRecurringOption(
        this.state.selectedOption
      );

      return (
        <MultikidsDismissalSelectHow
          onRef={(ref) => (this.dismissalSelectHow = ref)}
          selectData={selectData}
          doCallbackRefresh={this.props.doCallbackRefresh}
          selectedDate={selectedDate}
          selectedTillDate={selectedTillDate}
          recurringDates={recurringDates}
          selectedOption={selectedOption}
          selectRecurringOption={selectRecurringOption}
          currentPageIndex={currentPageIndex}
          pageIndexes={pageIndexes}
          markedDates={markedDates}
          manuallExcludedDates={manuallExcludedDates}
          saveChangesManuallExcludedDates={this.saveChangesManuallExcludedDates}
          saveChangesCurrentPageIndex={this.saveChangesCurrentPageIndex}
          selectedKids={selectedKids}
          school={this.props.school}
        />
      );
    }
  };

  selectDOW = (item) => {
    let DOW = [...this.state.DOW];
    let selectedDays = { ...this.state.selectedDays };

    item.isSelected = !item.isSelected;
    DOW[item.id] = item;
    if (item.isSelected) {
      selectedDays[item.value.toLowerCase()] = true;
    } else {
      delete selectedDays[item.value.toLowerCase()];
    }
    this.setState({ DOW, selectedDays });
  };

  renderDOW = () => {
    let days = [];
    const { DOW } = this.state;
    for (var i = 0; i < DOW.length; i++) {
      const item = DOW[i];
      days.push(
        <button
          type="button"
          className={
            !item.isSelected
              ? "selected-weekday-btn"
              : "selected-weekday-btn selectedWeekDay"
          }
          onClick={() => {
            this.selectDOW(item);
            this.buildRecurringDates();
          }}
        >
          {item.DOW}
        </button>
      );
    }
    return days;
  };

  getMinMaxDate = (type) => {
    const { selectedDate, selectedTillDate } = this.state;

    let minimumDate, maximumDate;
    const school = this.props.school;
    const firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);
    const isFuture = moment(firstDayOfSchool).isAfter(moment());

    const maxDate = new Date(school.Dates.LastDayOfSchool);
    if (type === "from") {
      minimumDate = isFuture ? firstDayOfSchool : new Date();
      maximumDate = new Date(maxDate);
    } else if (type === "to") {
      minimumDate = new Date(selectedDate);
      minimumDate = minimumDate.setDate(minimumDate.getDate() + 1);
      maximumDate = new Date(maxDate);
    }
    return {
      minimumDate,
      maximumDate,
    };
  };
  getSelectDateUI = () => {
    const {
      selectedDate,
      showCalendarPicker,
      showUntillCalendarPicker,
      selectedTillDate,
      repeatOptions,
      selectedOption,
      markedDates,
    } = this.state;

    const pickupDates = filterByTypes(markedDates);
    const startMinMax = this.getMinMaxDate("from");
    const endMinMax = this.getMinMaxDate("to");

    return (
      <div className="card form-card">
        <div className="form-card-title">
          <div className="fc-titletext-icon">
            <FontAwesomeIcon className="title-icon" icon={faCalendarAlt} />
            <span className="text">
              {this.props.t("createMarkAbScr-selectDate")}
            </span>
          </div>
          <span className="probressbar-holder">
            <Line percent={50} strokeWidth="1" strokeColor="#7ac943" />
          </span>
        </div>
        <form className="form form-style2">
          <div className="form-group">
            <label className="control-label">
              {this.props.t("Select_When_From")}
            </label>
            <div className="datp-calendar-holder">
              <div className="datp-col">
                <DatePicker
                  id="selectFromDate"
                  className="form-control"
                  selected={moment(selectedDate).toDate()}
                  minDate={startMinMax.minimumDate}
                  maxDate={startMinMax.maximumDate}
                  onCalendarOpen={this.showLegendView}
                  onSelect={this.onFromDateSelected}
                  onClickOutside={this.hideLegendView}
                  dateFormat="eee, MMM do yyyy"
                  highlightDates={[
                    {
                      "react-datepicker__day--highlightedRed":
                        pickupDates.pickupRed,
                    },
                    {
                      "react-datepicker__day--highlightedGreen":
                        pickupDates.pickupGreen,
                    },
                    {
                      "react-datepicker__day--highlightedOrange":
                        pickupDates.pickupLightOrange,
                    },
                    {
                      "react-datepicker__day--highlightedGray":
                        pickupDates.pickupGray,
                    },
                    {
                      "react-datepicker__day--highlightedBlack":
                        pickupDates.pickupBlack,
                    },
                  ]}
                  customInput={<CustomInput />}
                >
                  {showCalendarPicker === true && this.renderLegendUI()}
                </DatePicker>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label">
              {this.props.t("Repeat_Pickup_Label")}
            </label>

            <Dropdown
              className="custom-dropdown"
              onSelect={(eventKey, value) => {
                this.setRepeatType(eventKey);
              }}
            >
              <Dropdown.Toggle variant="Secondary">
                {this.selectRecurringOption(this.state.selectedOption)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {repeatOptions.map((data) => {
                  return (
                    <Dropdown.Item
                      className={
                        parseInt(this.state.selectedOption) ===
                        parseInt(data.value)
                          ? "active"
                          : ""
                      }
                      eventKey={data.value}
                      key={data.value}
                    >
                      <span className="dropdownitem-label">{data.label}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {selectedOption == 4 || selectedOption == 5 ? (
              <div className="selected-weekdays">{this.renderDOW()}</div>
            ) : null}
          </div>
          {this.state.isRepeat == true && (
            <div className="form-group">
              <label className="control-label">
                {this.props.t("Select_Until_Label")}
              </label>
              <div className="datp-calendar-holder">
                <div className="datp-col">
                  <DatePicker
                    id="selectTillDate"
                    className="form-control"
                    selected={moment(selectedTillDate).toDate()}
                    minDate={endMinMax.minimumDate}
                    maxDate={endMinMax.maximumDate}
                    onCalendarOpen={this.showUntillLegendView}
                    onSelect={this.onToDateSelected}
                    onClickOutside={this.hideUntilLegendView}
                    dateFormat="eee, MMM do yyyy"
                    highlightDates={[
                      {
                        "react-datepicker__day--highlightedRed":
                          pickupDates.pickupRed,
                      },
                      {
                        "react-datepicker__day--highlightedGreen":
                          pickupDates.pickupGreen,
                      },
                      {
                        "react-datepicker__day--highlightedOrange":
                          pickupDates.pickupLightOrange,
                      },
                      {
                        "react-datepicker__day--highlightedGray":
                          pickupDates.pickupGray,
                      },
                      {
                        "react-datepicker__day--highlightedBlack":
                          pickupDates.pickupBlack,
                      },
                    ]}
                    customInput={<CustomInput />}
                  >
                    {showUntillCalendarPicker === true && this.renderLegendUI()}
                  </DatePicker>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  };

  schedulePickupView = () => {
    const {
      showSelectHowUIFlag,
      selectedDate,
      selectedTillDate,
      selectedOption,
      manuallExcludedDates,
      recurringDates,
      currentPageIndex,
      pageIndexes,
      markedDates,
      showPickupSummary,
    } = this.state;
    const { isFetching, selectedKids } = this.props;
    let kidsName = getKidsName(selectedKids);
    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="scrollY-sm">
            <div className="modal-header">
              <div className="title-section">
                <h4 className="modal-title">
                  {this.props.t("modifyPickupLabel")}
                </h4>
                <p className="mb-5px">
                  <small> {this.props.t("Select_When_Pickup_Label")}</small>
                </p>

                <p className="modal-helptext">{kidsName}</p>
                <button
                  type="button"
                  className="btn help-icon-btn"
                  onClick={() => {
                    this.openHelpModal();
                  }}
                >
                  {this.props.t("regNameScr-needHelp")}
                </button>
              </div>
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  this.closeModal();
                }}
                aria-label={this.props.t("Close_Button")}
              ></button>
            </div>
            {/* <div className="scrollY-sm"> */}
            <div className="modal-body pickup-schedule-holder">
              <div className="row">
                <div className="col-sm-12 col-md-6 border-col">
                  {this.getSelectDateUI()}
                </div>
                <div className="col-sm-12 col-md-6 border-col no-border">
                  {this.getSelectHowUI()}
                </div>

                <div className="col-sm-12 border-col pickup-summary-layout">
                  <div className="border-bx pickupSummaryView-lg">
                    <h3 className="border-bx-title">
                      {this.props.t("Pickup_Summary_Label")}
                    </h3>
                    <div className="border-bx-content">
                      <p>{this.props.t("SelectRepeatedPickup_Summary")}</p>
                      <MultikidsPickupSummaryView
                        selectedDate={selectedDate}
                        selectedTillDate={selectedTillDate}
                        recurringDates={recurringDates}
                        selectedOption={selectedOption}
                        selectRecurringOption={this.selectRecurringOption(
                          selectedOption
                        )}
                        currentPageIndex={currentPageIndex}
                        pageIndexes={pageIndexes}
                        markedDates={markedDates}
                        manuallExcludedDates={manuallExcludedDates}
                        saveChangesManuallExcludedDates={
                          this.saveChangesManuallExcludedDates
                        }
                        saveChangesCurrentPageIndex={
                          this.saveChangesCurrentPageIndex
                        }
                      />
                    </div>
                  </div>
                  <div className="border-bx pickupSummaryView-sm">
                    <h3
                      className="border-bx-title pkp-summary-title"
                      onClick={() => {
                        this.togglePickupSummary();
                      }}
                    >
                      {this.props.t("Pickup_Summary_Label")}
                    </h3>
                    {showPickupSummary === true && (
                      <div className="border-bx-content">
                        <p>{this.props.t("SelectRepeatedPickup_Summary")}</p>
                        <MultikidsPickupSummaryView
                          selectedDate={selectedDate}
                          selectedTillDate={selectedTillDate}
                          recurringDates={recurringDates}
                          selectedOption={selectedOption}
                          selectRecurringOption={this.selectRecurringOption(
                            selectedOption
                          )}
                          currentPageIndex={currentPageIndex}
                          pageIndexes={pageIndexes}
                          markedDates={markedDates}
                          manuallExcludedDates={manuallExcludedDates}
                          saveChangesManuallExcludedDates={
                            this.saveChangesManuallExcludedDates
                          }
                          saveChangesCurrentPageIndex={
                            this.saveChangesCurrentPageIndex
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-center">
              <button
                type="button"
                className="btn btn-default "
                style={{
                  backgroundColor: getCurrentThemeColor(
                    this.props.selectedTheme
                  ).buttonDefault,
                }}
                disabled={this.dismissalSelectHow.isDisable}
                onClick={() => {
                  this.submitForm();
                }}
              >
                {isFetching ? (
                  <i className="fa-solid fa-arrows-rotate fa-spin"></i>
                ) : null}{" "}
                {this.props.t("DismSelectHowScr-makeChange")}
              </button>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  };
  togglePickupSummary = () => {
    const { showPickupSummary } = this.state;
    if (showPickupSummary === true) {
      this.setState({ showPickupSummary: false });
    } else {
      this.setState({ showPickupSummary: true });
    }
  };
  submitForm = () => {
    this.dismissalSelectHow.submitForm();
  };

  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };

  render() {
    return this.schedulePickupView();
  }

  componentDidUpdate() {
    const {
      pickupEntries,
      doGetPickupEntriesClear,
      error,
      earlyDismissalDateRange,
      doGetEarlyDismissalsForDateRangeClear,
    } = this.props;

    let newMarkedDates = {};
    if (pickupEntries !== null && earlyDismissalDateRange !== null) {
      // console.log(
      //   "Inside mark builder " + pickupEntries.CalendarEntries.length
      // );
      newMarkedDates = {};
      for (var i = 0; i < pickupEntries.CalendarEntries.length; i++) {
        const record = { ...pickupEntries.CalendarEntries[i] };
        var day = moment(record.CalendarDate).format("YYYY-MM-DD");
        let earlyDismissalrecord = [];
        if (earlyDismissalDateRange) {
          //console.log("earlyDismissalrecord records ", earlyDismissalDateRange)
          earlyDismissalrecord = earlyDismissalDateRange.filter(
            (item) => moment(item.Date).format("YYYY-MM-DD") === day
          );
        }
        //console.log(`bulding marked dates day ${day}  record.CalendarDate ${record.CalendarDate} `);
        if (record.PM_Name === GLOBAL_CONST.PICKMODE_NAME.EARLY_DEPARTURE) {
          record.isEarlyDepart = true;
          newMarkedDates[day] = getMarkStyle(record);
        } else if (earlyDismissalrecord.length > 0) {
          const edRecord = {
            ...earlyDismissalrecord[0],
            ...record,
          };

          //console.log('earlyDismissalrecord for ', earlyDismissalrecord[0]);
          edRecord.isEarlyDismissal = true;
          edRecord.IsApproved = earlyDismissalrecord[0].IsApproved;
          if (edRecord.DelegateImageUrl === undefined) {
            edRecord.DelegateImageUrl = null;
          }

          newMarkedDates[day] = getMarkStyle(edRecord);
        } else {
          record.isEarlyDismissal = false;
          newMarkedDates[day] = getMarkStyle(record);
          // console.log("pickup for " + day)
        }
      }

      this.setState(
        {
          markedDates: newMarkedDates,
        },
        () => {
          this.getSelectDateDayRecord();
          doGetPickupEntriesClear();
          doGetEarlyDismissalsForDateRangeClear();
        }
      );
    }

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
    }
  }
}
const CustomInput = React.forwardRef((props, ref) => {
  return (
    <input
      className="form-control"
      onClick={props.onClick}
      value={props.value}
      type="text"
      readOnly={true}
      ref={ref}
    />
  );
});
CustomInput.displayName = "CustomInput";
function mapStateToProps(state) {
  return {
    setCreatePickupButtonsUI: state.userAccount.setCreatePickupButtonsUI,
    setHelpListData: state.userAccount.setHelpListData,
    isFetching: loadingSelector(state),

    pickupEntries: state.student.pickupEntries,
    earlyDismissalDateRange: state.student.earlyDismissalDateRange,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),

    doGetPickupEntries: (data) => dispatch(getPickupEntries(data)),
    doGetPickupEntriesClear: () => dispatch(getPickupEntriesClear()),

    doGetEarlyDismissalsForDateRange: (data) =>
      dispatch(getEarlyDismissalsForDateRange(data)),
    doGetEarlyDismissalsForDateRangeClear: (data) =>
      dispatch(getEarlyDismissalsForDateRangeClear(data)),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MultikidsSelectWhen)
);
