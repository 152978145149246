import React from "react";
import { withTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";
import { nextField } from "../../helper/utility";
import {
  getTopics,
  sendSupportRequest,
  sendSupportRequestClear,
} from "../../common/actions/userAccount";
import { createLoadingSelector } from "../../common/reducers/selectors";
const loadingSelector = createLoadingSelector(["send_support_request"]);
import "react-confirm-alert/src/react-confirm-alert.css";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMyStyle } from "../../theme/ThemeStyle";

class ContactSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supportText: "",
      previousSelectedOptions: null,
      previousSelectedSchoolOption: null,
      selectedOption: 0,
      selectedOptionLabel: null,
      selectedSchoolOptions: null,
      selectedSchoolOptionsLabel: null,
      selectedChildren: [],
      isSelected: false,
      schoolsOptions: [],
      SchoolStudentList: [],
      feedback: "",
      customSchoolName: "",
      customStudentName: "",
      showValidator: {},
    };

    this.getTopicsdetails();
  }

  componentDidMount() {
    this.dosetHelpList();
  }
  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Settings",
      screen: GLOBAL_CONST.SCREEN_NAMES.CONTACT_SUPPORT,
      title: this.props.t("helpScr-contact"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  getSchoolStudents = (item) => {
    const { studentsList } = this.props;
    let students = [];
    if (
      studentsList &&
      studentsList.TodaysStudents &&
      studentsList.TodaysStudents.length > 0
    ) {
      const Schoolstudents = studentsList.TodaysStudents;
      for (let i = 0; i < Schoolstudents.length; i++) {
        if (item == Schoolstudents[i].SchoolID) {
          students.push({
            studenId: Schoolstudents[i].StudentID,
            firstName: Schoolstudents[i].Firstname,
            lastName: Schoolstudents[i].Lastname,
          });
        }
      }
    }

    return students;
  };

  setSelected = (item) => {
    const { selectedChildren } = this.state;

    if (selectedChildren.includes(item.studenId)) {
      selectedChildren.splice(selectedChildren.indexOf(item.studenId), 1);
    } else {
      selectedChildren.push(item.studenId);
    }

    this.setState({ selectedChildren });
  };

  listItem = ({ item }) => {
    return (
      <div className="box-checkbox" key={"student-" + item.studenId}>
        <input
          type="checkbox"
          id={item.studenId}
          name="childName"
          onClick={() => this.setSelected(item)}
        />
        <label htmlFor={item.studenId}></label>
        <span className="checkbox-label-text">
          {item.firstName} {""} {item.lastName}
        </span>
      </div>
    );
  };

  getTopicsByID = (value) => {
    const { helpTopics } = this.props;
    if (helpTopics) {
      for (let i = 0; i < helpTopics.length; i++) {
        if (value === helpTopics[i].value) return helpTopics[i].label;
      }
    }
  };

  getSchoolsByID = (value) => {
    const { schoolsOptions } = this.state;
    for (let i = 0; i < schoolsOptions.length; i++) {
      if (value === schoolsOptions[i].value) return schoolsOptions[i].label;
    }
  };

  getTopicsdetails = () => {
    const { schoolInfo, doGetTopics } = this.props;
    let schoolIds = [];

    for (let i = 0; i < schoolInfo.length; i++) {
      schoolIds.push(schoolInfo[i].SchoolID);
    }
    doGetTopics({ schoolIds });
  };

  validate = () => {
    const showValidator = this.validator();

    if (showValidator["topic"]) {
      alert(
        this.props.t("helpScr-contact") +
          "\n" +
          this.props.t("Support_SelectTopc_Alert")
      );
      return false;
    }
    if (showValidator["description"]) {
      alert(
        this.props.t("helpScr-contact") +
          "\n" +
          this.props.t("Support_Feedback_Alert")
      );
      return false;
    }
    if (showValidator["school"]) {
      alert(
        this.props.t("helpScr-contact") +
          "\n" +
          this.props.t("Support_Enter_School_Kid_Info")
      );
      return false;
    }
    if (showValidator["student"]) {
      alert(
        this.props.t("helpScr-contact") +
          "\n" +
          this.props.t("Support_Enter_School_Kid_Info")
      );
      return false;
    }
    if (showValidator["kids"]) {
      alert(
        this.props.t("helpScr-contact") +
          "\n" +
          this.props.t("Support_Select_Kid")
      );
      return false;
    }

    return true;
  };

  validator = () => {
    const {
      selectedChildren,
      selectedSchoolOptions,
      selectedOption,
      feedback,
      customSchoolName,
      customStudentName,
    } = this.state;

    let validate = false;

    if (
      selectedOption === undefined ||
      !selectedOption ||
      selectedOption === 0
    ) {
      alert(this.props.t("Support_SelectTopc_Alert"));
      validate = false;
      return false;
    } else if (feedback === "") {
      alert(this.props.t("Support_Feedback_Alert"));
      validate = false;
      return false;
    }

    let topic = "" + this.getTopicsByID(selectedOption);
    topic = topic.toLowerCase();
    if (
      topic.includes("pickup") ||
      topic.includes("delegate") ||
      topic.includes("announce") ||
      topic.includes("child") ||
      topic.includes("recogida") ||
      topic.includes("anunciar") ||
      topic.includes("delegados") ||
      topic.includes("niño")
    ) {
      if (parseInt(selectedSchoolOptions) === 0 && customSchoolName == "") {
        alert(this.props.t("Support_Enter_School_Kid_Info"));
        validate = false;
        return false;
      } else if (
        parseInt(selectedSchoolOptions) === 0 &&
        customStudentName == ""
      ) {
        alert(this.props.t("Support_Enter_School_Kid_Info"));
        validate = false;
        return false;
      } else if (
        selectedSchoolOptions > 0 &&
        this.getSchoolStudents(selectedSchoolOptions).length > 0 &&
        selectedChildren.length === 0
      ) {
        alert(this.props.t("Support_Select_Kid"));
        validate = false;
        return false;
      }
    }

    return true;
  };

  submitForm = () => {
    const {
      selectedChildren,
      selectedSchoolOptions,
      selectedOption,
      feedback,
      customSchoolName,
      customStudentName,
    } = this.state;
    const { doSendSupportRequest, userLoginInfo } = this.props;

    if (!this.validator()) {
      return;
    } else if (customSchoolName == "" && customStudentName == "") {
      doSendSupportRequest({
        list_HelpTopicId: selectedOption,
        schoolId: selectedSchoolOptions,
        studentIds: selectedChildren,
        customSchoolName: "",
        customStudentName: "",
        feedback: feedback,
        authToken: userLoginInfo.AuthTokenID,
      });
    } else {
      doSendSupportRequest({
        list_HelpTopicId: selectedOption,
        schoolId: selectedSchoolOptions,
        studentIds: selectedChildren,
        customSchoolName: customSchoolName,
        customStudentName: customStudentName,
        feedback: feedback,
        authToken: userLoginInfo.AuthTokenID,
      });
    }
  };

  componentDidUpdate() {
    const { schoolsOptions } = this.state;
    const { schoolInfo, error, dosendSupportRequestClear, supportResponse } =
      this.props;
    let schools = [];

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      dosendSupportRequestClear();
    } else if (supportResponse) {
      toast.success(supportResponse || supportResponse, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      this.closeModal();

      dosendSupportRequestClear();
    }

    if (schoolInfo) {
      for (let i = 0; i < schoolInfo.length; i++) {
        schools.push({
          label: `${schoolInfo[i].Name}`,
          value: `${schoolInfo[i].SchoolID}`,
        });
      }
      schools.push({
        label: this.props.t("Not_List_School"),
        value: "0",
      });

      if (schoolsOptions.length === 0 && schools.length > 0) {
        this.setState({ schoolsOptions: schools });
        this.setState({ selectedSchoolOptions: schools[0].value });
      }
    }
  }

  buildStudentList = (selectedSchoolOptions) => {
    const data = this.getSchoolStudents(selectedSchoolOptions);
    const views = [];
    for (var i = 0; i < data.length; i++) {
      views.push(this.listItem({ item: data[i] }));
    }
    return views;
  };

  handleFeedback = ({ target: { value: feedback } }) =>
    this.setState({ feedback });

  handleCustomSchoolName = ({ target: { value: customSchoolName } }) =>
    this.setState({ customSchoolName });

  handleCustomStudentName = ({ target: { value: customStudentName } }) =>
    this.setState({ customStudentName });

  renderContactSupportView = () => {
    const { selectedOption, schoolsOptions, selectedSchoolOptions, feedback } =
      this.state;
    const { helpTopics, isFetching } = this.props;
    const styles = getMyStyle(this.props.selectedTheme);
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">{this.props.t("helpScr-contact")}</h4>
              <button
                type="button"
                className="btn help-icon-btn"
                onClick={() => {
                  this.openHelpModal();
                }}
              >
                {this.props.t("regNameScr-needHelp")}
              </button>
            </div>

            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>

          <div className="modal-body">
            <div className="form-card">
              <form className="form-style1">
                <div className="form-card-header">
                  <h4 className="form-card-title">
                    {this.props.t("App_Support")}
                  </h4>
                </div>
                <div className="form-group">
                  <label className="control-label">
                    {this.props.t("Describe_Problem")}
                  </label>

                  <Dropdown
                    className="custom-dropdown"
                    onSelect={(eventKey, value) => {
                      this.setState({
                        selectedOption: eventKey,
                      });
                    }}
                    onClick={() => {
                      this.setState({
                        previousSelectedOptions: selectedOption,
                      });
                    }}
                  >
                    <Dropdown.Toggle title={this.getTopicsByID(selectedOption)}>
                      {this.getTopicsByID(selectedOption)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {helpTopics.map((data) => {
                        return (
                          <Dropdown.Item
                            className={
                              selectedOption === data.value ? "active" : ""
                            }
                            eventKey={data.value}
                            key={data.value}
                          >
                            <span className="dropdownitem-label">
                              {data.label}
                            </span>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="form-group">
                  <label className="control-label">
                    {this.props.t("Which_school")}
                  </label>

                  <Dropdown
                    className="custom-dropdown"
                    onSelect={(eventKey, value) => {
                      this.setState({
                        selectedSchoolOptions: eventKey,
                      });
                    }}
                  >
                    <Dropdown.Toggle>
                      {this.getSchoolsByID(selectedSchoolOptions)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {schoolsOptions.map((data) => {
                        return (
                          <Dropdown.Item
                            eventKey={data.value}
                            key={data.value}
                            active={selectedSchoolOptions === data.value}
                          >
                            <span className="dropdownitem-label">
                              {data.label}
                            </span>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="form-group">
                  {parseInt(selectedSchoolOptions) === 0 ? (
                    <input
                      type="text"
                      className="form-control"
                      placeholder={this.props.t("Type_School_Name")}
                      onChange={this.handleCustomSchoolName}
                      onKeyDown={(event) => nextField(event)}
                    />
                  ) : null}
                </div>
                <div className="form-group">
                  {parseInt(selectedSchoolOptions) === 0 ? (
                    <input
                      type="text"
                      className="form-control"
                      placeholder={this.props.t("Name_Of_Child")}
                      name="customStudentName"
                      onChange={this.handleCustomStudentName}
                      onKeyDown={(event) => nextField(event)}
                    />
                  ) : null}
                </div>
                <div className="form-group checkboxlist">
                  {this.buildStudentList(selectedSchoolOptions).length > 0 ? (
                    <label className="control-label">
                      {this.props.t("Which_child")}
                    </label>
                  ) : null}

                  {this.buildStudentList(selectedSchoolOptions)}
                </div>
                <div className="form-group">
                  <label className="control-label">
                    {this.props.t("How_can_we_help")}
                  </label>
                  <textarea
                    className="form-control"
                    placeholder={this.props.t("Descrip_placeHolder")}
                    name="feedback"
                    //col="12"
                    value={feedback}
                    onChange={this.handleFeedback}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") this.submitForm();
                    }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={styles.buttonDefault}
                    onClick={this.submitForm}
                  >
                    {isFetching ? (
                      <i className="fa-solid fa-arrows-rotate fa-spin"></i>
                    ) : null}{" "}
                    {this.props.t("settingsScr-submit")}
                  </button>
                </div>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  };
  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };

  render() {
    return this.renderContactSupportView();
  }
}

function mapStateToProps(state) {
  return {
    schoolInfo: state.student.schoolInfo,
    helpTopics: state.userAccount.helpTopics,
    supportResponse: state.userAccount.supportResponse,
    studentsList: state.student.studentsList,
    isFetching: loadingSelector(state),
    error: state.userAccount.error,
    userLoginInfo: state.userAccount.userLoginInfo,
    setHelpListData: state.userAccount.setHelpListData,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doGetTopics: (data) => dispatch(getTopics(data)),
    doSendSupportRequest: (data) => dispatch(sendSupportRequest(data)),
    dosendSupportRequestClear: () => dispatch(sendSupportRequestClear()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ContactSupport)
);
