import React from "react";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/app.css";
import "./Styles/style.css";
import "./Styles/theme-responsive.css";
import { connect } from "react-redux";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  RegisterFooter,
  RegisterHeader,
  NewRegisterWizard,
  withRouter,
} from "../../CommonComponents";
import { nextField } from "../../../helper/utility";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import {
  feedbackSubmit,
  feedbackSubmitClear,
} from "../../../common/actions/userAccount";

import { createLoadingSelector } from "../../../common/reducers/selectors";
import { withTranslation } from "react-i18next";
import { Line } from "rc-progress";
import { markReadToDashboard } from "../../../common/actions/userAccount/userAccountAction";

const loadingSelector = createLoadingSelector(["submit_feedback"]);

class SelectIsParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolNameInvalid: "",
      childrenInformationInvalid: "",
      dismissalNumberInavlid: "",
      schoolName: "",
      childrenInformation: "",
      dismissalNumber: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  submitForm = () => {
    const { schoolName, childrenInformation, dismissalNumber } = this.state;
    const { doFeedbackSubmit } = this.props;
    if (schoolName == "") {
      this.setState({ schoolNameInvalid: this.props.t("School_Info_Alert") });
      return;
    } else if (childrenInformation == "") {
      this.setState({
        childrenInformationInvalid: this.props.t("Children_Name_Alert"),
      });
      return;
    } else if (dismissalNumber == "") {
      this.setState({
        dismissalNumberInavlid: this.props.t("Children_Dismissal_Number_Alert"),
      });
      return;
    } else {
      var FeedbackToSend = "";
      FeedbackToSend += "School: " + schoolName.concat(", ");
      FeedbackToSend += "Child: " + childrenInformation.concat(", ");
      FeedbackToSend += "Dismissal: " + dismissalNumber;
      doFeedbackSubmit({ feedback: FeedbackToSend });
    }
  };

  goDashboard = () => {
    let screenFrom = null;
    const { location } = this.props;
    if (location && location.state) {
      screenFrom = location.state.screenFrom;
    }
    if (screenFrom === "dashboard" || screenFrom === "childManagement") {
      this.props.navigate(-1);
      this.props.navigate(-1);
    } else {
      this.props.navigate("/childManagement");
    }
  };

  componentDidUpdate() {
    const { doFeedbackSubmitClear, submitFeedbackResponse, error } = this.props;

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doFeedbackSubmitClear();
    } else if (submitFeedbackResponse) {
      toast.success(submitFeedbackResponse.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      this.goDashboard();
      doFeedbackSubmitClear();
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      schoolNameInvalid: "",
      childrenInformationInvalid: "",
      dismissalNumberInavlid: "",
    });
  }

  render() {
    const {
      schoolName,
      childrenInformation,
      dismissalNumber,
      schoolNameInvalid,
      childrenInformationInvalid,
      dismissalNumberInavlid,
    } = this.state;
    const { isFetching, location } = this.props;

    let screenFrom = null;
    if (location && location.state) {
      screenFrom = location.state.screenFrom;
    }

    return (
      <section className="registration accountexists-sect">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  <div className="container-sect">
                    <form className="form-style1">
                      <div className="title-sect mb-40 mt-30">
                        <h3 className="title-3">
                          {this.props.t("IsParent_Header")}
                        </h3>
                        <p className="helptext">
                          {this.props.t("IsParent_disc_Text")}
                        </p>
                      </div>
                      <div className="form-group">
                        <p className="fieldHeading">
                          {" "}
                          {this.props.t("supportNoScr-school-information")}
                        </p>
                        <p className="fieldLabel">
                          {" "}
                          {this.props.t("School_Name_Label")}{" "}
                        </p>
                        <input
                          type="text"
                          className={`form-control ${
                            schoolNameInvalid !== "" ? "input-error" : null
                          }`}
                          placeholder={this.props.t("Placeholder")}
                          name="schoolName"
                          value={schoolName}
                          onChange={this.handleChange}
                          onKeyDown={(event) => nextField(event)}
                        />
                        <span className="error" id="message">
                          {" "}
                          {schoolNameInvalid}
                        </span>
                      </div>

                      <div className="form-group">
                        <p className="fieldHeading">
                          {this.props.t("Children_Info_Label")}
                        </p>
                        <p className="fieldLabel">
                          {this.props.t("Children_Name_label")}
                        </p>
                        <input
                          type="text"
                          className={`form-control ${
                            childrenInformationInvalid !== ""
                              ? "input-error"
                              : null
                          }`}
                          placeholder={this.props.t("Placeholder")}
                          name="childrenInformation"
                          value={childrenInformation}
                          onChange={this.handleChange}
                          onKeyDown={(event) => nextField(event)}
                        />
                        <span className="error" id="message">
                          {" "}
                          {childrenInformationInvalid}
                        </span>
                      </div>

                      <div className="form-group">
                        <p className="fieldLabel">
                          {this.props.t("Children_dismissal_number_label")}
                        </p>
                        <input
                          type="text"
                          className={`form-control ${
                            dismissalNumberInavlid !== "" ? "input-error" : null
                          }`}
                          placeholder={this.props.t("Placeholder")}
                          name="dismissalNumber"
                          value={dismissalNumber}
                          onChange={this.handleChange}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") this.submitForm();
                          }}
                        />
                        <span className="error" id="message">
                          {" "}
                          {dismissalNumberInavlid}
                        </span>
                      </div>

                      <div className="form-group">
                        <p className="carTagText">
                          {this.props.t("Children_dismissal_number_info")}
                        </p>
                      </div>

                      <div className="form-group text-center">
                        <button
                          type="button"
                          onClick={() => {
                            this.submitForm();
                          }}
                          className="btn btn-primary"
                        >
                          {" "}
                          {isFetching ? (
                            <i className="fas fa-sync-alt fa-spin"></i>
                          ) : null}{" "}
                          &nbsp; {this.props.t("Confirm_Button")}
                        </button>
                        <button
                          className="btn close-button mt-10"
                          type="button"
                          onClick={() => {
                            this.props.navigate(-1);
                          }}
                        >
                          {this.props.t("regNameScr-cancel")}
                        </button>
                        <div className="bottom-sect text-center">
                          {screenFrom === "dashboard" ||
                          screenFrom === "childManagement" ||
                          screenFrom === "account" ? (
                            <div className="bottom-sect text-center">
                              <button
                                className="btn close-button goback-btn"
                                type="button"
                                onClick={() => {
                                  this.props.navigate(-1);
                                  this.props.navigate(-1);
                                }}
                              >
                                {this.props.t("goback")}
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <ToastContainer />
                      <RegisterFooter />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    registeredPassword: state.userAccount.registeredPassword,
    registeredFirstname: state.userAccount.registeredFirstname,
    registeredLastname: state.userAccount.registeredLastname,
    registeredResultCache: state.userAccount.registeredResultCache,
    userLoginInfo: state.userAccount.userLoginInfo,
    isFetching: loadingSelector(state),
    getUserInfoResponse: state.userAccount.getUserInfoResponse,
    submitFeedbackResponse: state.userAccount.submitFeedbackResponse,
    error: state.userAccount.error,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doFeedbackSubmit: (data) => dispatch(feedbackSubmit(data)),
    doFeedbackSubmitClear: () => dispatch(feedbackSubmitClear()),
    doMarkReadToDashboard: () => dispatch(markReadToDashboard()),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectIsParent))
);
