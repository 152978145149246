import React from "react";
import { withTranslation } from "react-i18next";
import "react-calendar/dist/Calendar.css";
import { connect } from "react-redux";
import { MainHeader, MainFooter } from "../CommonComponents";
import moment from "moment";
import RingLoader from "react-spinners/ClipLoader";
import {
  logoutUser,
  setHelpList,
  showModal,
  hideModal,
  saveHelpMessage1,
  saveHelpMessage2,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";
import { Nokids } from "../Dashboard";
import {
  getStudentsList,
  getSchoolDetails,
  schoolAndKidsFetchDone,
  getOtherChildrenDetails,
  otherChildrenFetchDone,
  otherChildrenListClear,
  getBackToSchoolInformation,
} from "../../common/actions/students";

import {
  getIsTokenValid,
  getIsTokenValidClear,
  getUserProfileInfo,
} from "../../common/actions/userAccount";

import "./styles/style.css";
import MyKidView from "./MyKidView";
import OtherKidView from "./OtherKidView";
import { createLoadingSelector } from "../../common/reducers/selectors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import {
  getCurrentThemeColor,
  isVisibleSelectMultikids,
  isEarlyDismissalAvailableForParent,
} from "../../helper/utility";
import { getMyStyle } from "../../theme/ThemeStyle";
import AdsView from "../Ads/AdsView";

const loadingSelector = createLoadingSelector([
  "get_students",
  "get_other_children",
  "get_school_info",
  "get_token_valid",
  "get_back_school",
]);
const loadingSelector2 = createLoadingSelector(["get_back_school"]);

function withRouterHook(Component) {
  return function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component navigate={navigate} location={location} {...props} />;
  };
}

class ChildManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      OtherSchoolStudentList: [],
      YourSchoolStudentList: [],
      noKidsTitle: "",
      noKidsDetail: "",
      needBackToSchool: false,
      isBackToSchoolPrompted: false,

      selectedSchool: null,
      isSelectMultiplekids: false,
      selectedKids: [],
      markedDates: {},
      openRow: null,

      isYourChildrenVisible: true,
      isOtherChildrenVisible: false,
    };
  }
  setThemeBody = () => {
    document.body.style.backgroundColor = getCurrentThemeColor(
      this.props.selectedTheme
    ).body;

    document.body.className = `theme-${this.props.selectedTheme}`;
  };

  async componentDidMount() {
    this.setThemeBody();
    const { isTermsAccepted, modalLevelInfo } = this.props;
    this.checkAppleAccountName();
    this.checkTokenValid();
    this.dosetHelpList();
    if (
      modalLevelInfo &&
      modalLevelInfo.length > 0 &&
      (modalLevelInfo[modalLevelInfo.length - 1].modalScreenName ===
        "AddContact" ||
        modalLevelInfo[modalLevelInfo.length - 1].modalScreenName ===
          "EditContact")
    ) {
      this.props.doHideModal();
    }

    setTimeout(() => {
      if (!isTermsAccepted) {
        this.props.navigate("/termsConditions");
      } else {
        this.processBackToSchool(this.state.needBackToSchool);
      }
    }, 200);

    const { doGetUserProfileInfo } = this.props;
    doGetUserProfileInfo((info) => {
      if (info && info.HasVerificationInProgress) {
        confirmAlert({
          title: this.props.t("email_verification"),
          message: this.props.t("newEmail_verify"),
          buttons: [
            {
              label: this.props.t("ok"),
              onClick: () => {
                this.props.navigate("/newconfirmEmail", {
                  state: {
                    userEmail: info.NewEmailAddress,
                    screenFrom: "viewprofile",
                  },
                });
              },
            },
            {
              label: this.props.t("Cancel"),
              className: "cancel-btn",
            },
          ],
        });
      }
    });
  }
  checkAppleAccountName = () => {
    const { userLoginInfo } = this.props;
    if (userLoginInfo && userLoginInfo.firstName.trim() === "") {
      confirmAlert({
        title: this.props.t("UserDetailsMissingTitle"),
        message: this.props.t("userDetalisMissingMessage"),
        buttons: [
          {
            label: this.props.t("settingMainScr-ok"),
            onClick: () => {
              setTimeout(() => {
                this.openViewProfileModal();
              }, 200);
            },
          },
        ],
      });
    }
  };
  openViewProfileModal = () => {
    this.props.doShowModal({
      modalScreenName: "ViewProfile",
      modalClassName: "viewProfileModal sidebar-modal  right",
    });
  };
  processBackToSchool = (needBackToSchool) => {
    if (needBackToSchool) {
      const { nextAskMeLater } = this.props;
      if (
        nextAskMeLater !== null &&
        nextAskMeLater !== undefined &&
        moment().isBefore(nextAskMeLater, "date")
      ) {
        return;
      }
      this.getBackToSchoolInfo();
    }
  };
  getBackToSchoolInfo = () => {
    const { doGetbacktoschoolInfo, studentsList } = this.props;
    const students = studentsList && studentsList.TodaysStudents;
    const studentRecords = [...students];
    const kidsList = studentRecords.filter((item) => !item.IsDelegation);
    doGetbacktoschoolInfo({
      callback: (getBackSchoolInfoData) => {
        if (getBackSchoolInfoData) {
          if (kidsList.length > 1) {
            this.openBackToSchoolKidsModal();
          } else if (kidsList.length === 1) {
            this.props.doShowModal({
              modalScreenName: "backToSchool",
              modalClassName: "backto-school-modal",
              params: {
                studentData: kidsList[0],
                backToSchoolInfoData: getBackSchoolInfoData[0],
                isSingleKid: true,
                doCallbackRefresh: this.doRefreshListing,
              },
            });
          }
          this.setState({ isBackToSchoolPrompted: true });
        }
      },
    });
  };

  openBackToSchoolKidsModal = () => {
    this.props.doShowModal({
      modalScreenName: "backToSchoolKidList",
      modalClassName: "backto-school-modal",
    });
  };

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "ChildManagement",
      screen: GLOBAL_CONST.SCREEN_NAMES.MANAGE_CHILDREN_TAB,
      title: this.props.t("manageChildrenScr-manageChildren"),
    });
  };

  componentWillUnmount() {
    this.setState({ OtherSchoolStudentList: [], YourSchoolStudentList: [] });
  }

  checkTokenValid = () => {
    const { doGetIsTokenValid } = this.props;
    doGetIsTokenValid();
  };

  doRefreshListing = () => {
    this.checkTokenValid();
    this.dosetHelpList();
  };

  initialLoad = () => {
    this.getSchool(this.getStudents);
    this.fetchOtherChildrenList();
  };

  getStudents = () => {
    const { doGetStudentsList } = this.props;
    let currenDate = moment().format("MM-DD-YYYY");
    doGetStudentsList({ currenDate });
  };

  getSchool = (callback) => {
    const { doGetSchoolDetails } = this.props;
    doGetSchoolDetails().then(() => {
      callback();
    });
  };

  fetchOtherChildrenList = () => {
    const { doGetOtherChildrenList } = this.props;
    doGetOtherChildrenList();
  };

  buildYourChildrenList = () => {
    const { studentsList, schoolInfo, isTermsAccepted } = this.props;
    const schools = schoolInfo;
    const students = studentsList && studentsList.TodaysStudents;
    const { yourChildrenRefreshing } = this.state;
    let needBackToSchool = false;

    if (!schools || !students) return;

    const kids = [];
    for (let i = 0; i < schools.length; i++) {
      let schoolstudents = [];
      const LastDayOfSchool = new Date(schools[i].Dates.LastDayOfSchool);
      const isLastDayOfSchoolPast = moment(LastDayOfSchool).isBefore(moment());
      for (let j = 0; j < students.length; j++) {
        if (
          schools[i].SchoolID === students[j].SchoolID &&
          students[j].IsDelegation === false
        ) {
          students[j].isLastDayOfSchoolPast = isLastDayOfSchoolPast;
          schoolstudents.push(students[j]);
        }
        if (students[j].AskForBackToSchool) needBackToSchool = true;
      }
      schoolstudents = schoolstudents.sort((a, b) =>
        a.Firstname.toLowerCase() > b.Firstname.toLowerCase()
          ? 1
          : b.Firstname.toLowerCase() > a.Firstname.toLowerCase()
          ? -1
          : 0
      );

      if (schoolstudents.length > 0) {
        let sectiondata = {
          schoolId: schools[i].SchoolID,
          address: schools[i].Address,
          schoolzip: schools[i].State + " " + schools[i].Zip,
          title: schools[i].Name,
          schoolLat: schools[i].Coordinates.latitude,
          schoolLong: schools[i].Coordinates.longitude,
          AnnounceDistance: schools[i].AnnounceDistance,
          school: schools[i],
          data: schoolstudents,
          isLastDayOfSchoolPast,
        };
        kids.push(sectiondata);
      }
    }
    const sortedSchoolStudentList = kids.sort((a, b) =>
      a.title.toLowerCase() > b.title.toLowerCase()
        ? 1
        : b.title.toLowerCase() > a.title.toLowerCase()
        ? -1
        : 0
    );
    this.setState({
      YourSchoolStudentList: sortedSchoolStudentList,
      needBackToSchool,
    });

    if (isTermsAccepted) {
      this.processBackToSchool(needBackToSchool);
    }
  };

  getYourChildrenView = () => {
    const { YourSchoolStudentList } = this.state;
    const childViews = [];

    for (var i = 0; i < YourSchoolStudentList.length; i++) {
      const { data, school } = YourSchoolStudentList[i];
      for (var k = 0; k < data.length; k++) {
        childViews.push(this.renderMyChild(data[k], school));
      }
    }
    return childViews;
  };

  setParams = (params) => {
    if (params !== null) {
      this.setState({
        //markedDates: params.markedDates,
        selectedSchool: params.school,
      });
    }
  };

  handleRowToggle = (row) => {
    const { openRow } = this.state;
    if (openRow === row) {
      this.setState({ openRow: null }); // Close row if it's already open
    } else {
      this.setState({ openRow: row }); // Open the clicked row
    }
  };

  renderMyChild = (child, school) => {
    return (
      <MyKidView
        key={"mykid-" + child.StudentID}
        child={child}
        school={school}
        selectedSchool={this.state.selectedSchool}
        doSetSelectSchool={(selectedSchool) => {
          this.setState({ selectedSchool }, () => {
            // alert(this.state.selectedSchool.SchoolID);
          });
        }}
        isSelectMultiplekids={this.state.isSelectMultiplekids}
        selectedKids={this.state.selectedKids}
        doSetSelectedKids={(selectedKids) => {
          this.setState({ selectedKids });
        }}
        openRow={this.state.openRow}
        callback={(row) => {
          //this.setParams(data);
          this.handleRowToggle(row);
        }}
      ></MyKidView>
    );
  };

  buildOtherChildrenList = () => {
    const { otherChildrens, schoolInfo } = this.props;
    const schools = schoolInfo;
    const children = otherChildrens;
    if (!schools || !children) return;

    const kids = [];
    for (let i = 0; i < schools.length; i++) {
      let schoolstudents = [];
      for (let j = 0; j < children.length; j++) {
        if (schools[i].SchoolID === children[j].SchoolID) {
          schoolstudents.push(children[j]);
        }
      }

      schoolstudents = schoolstudents.sort((a, b) =>
        a.StudentName.FirstName.toLowerCase() >
        b.StudentName.FirstName.toLowerCase()
          ? 1
          : b.StudentName.FirstName.toLowerCase() >
            a.StudentName.FirstName.toLowerCase()
          ? -1
          : 0
      );

      if (schoolstudents.length > 0) {
        let sectiondata = {
          schoolId: schools[i].SchoolID,
          address: schools[i].Address,
          schoolzip: schools[i].State + " " + schools[i].Zip,
          title: schools[i].Name,
          schoolLat: schools[i].Coordinates.latitude,
          schoolLong: schools[i].Coordinates.longitude,
          AnnounceDistance: schools[i].AnnounceDistance,
          data: schoolstudents,
        };
        kids.push(sectiondata);
      }
    }

    const sortedSchoolStudentList = kids.sort((a, b) =>
      a.title.toLowerCase() > b.title.toLowerCase()
        ? 1
        : b.title.toLowerCase() > a.title.toLowerCase()
        ? -1
        : 0
    );
    this.setState({ OtherSchoolStudentList: sortedSchoolStudentList });
  };

  getOtherChildrenView = () => {
    const { OtherSchoolStudentList } = this.state;
    const { isFetching } = this.props;
    const childViews = [];
    for (var i = 0; i < OtherSchoolStudentList.length; i++) {
      const { data, title } = OtherSchoolStudentList[i];
      for (var k = 0; k < data.length; k++) {
        childViews.push(this.renderOtherChild(data[k], title));
      }
    }

    return childViews;
  };

  renderOtherChild = (child, title) => {
    return (
      <OtherKidView
        key={"otherkid-" + child.StudentID}
        child={child}
        title={title}
      ></OtherKidView>
    );
  };

  showEmpty = () => {
    let noKidsTitle = this.props.t("dashBoardScr-noKidsTitle");
    let noKidsDetail = this.props.t("dashBoardScr-noKidsDetail");
    let noKidsData = { noKidsTitle, noKidsDetail };

    return <Nokids {...noKidsData} screenName={"childManagement"} />;
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      yourChildrenRefreshing,
      otherChildrenRefreshing,
      YourSchoolStudentList,
    } = this.state;

    const {
      isFetching,
      isTokenValidResponse,
      doGetIsTokenValidClear,
      doLogoutUser,
      fetchStudentListDone,
      fetchSchoolInfoDone,
      fetchOtherChildrenDone,
      doSchoolAndKidsFetchDone,
      doOtherChildrenFetchDone,
      schoolInfo,
    } = this.props;

    if (!_.isEqual(this.props.selectedTheme, prevProps.selectedTheme)) {
      this.setThemeBody();
    }

    if (isTokenValidResponse) {
      if (isTokenValidResponse.Success === false) {
        doLogoutUser();
        this.props.navigate("/");
      } else {
        this.initialLoad();
      }
      doGetIsTokenValidClear();
    }

    if (yourChildrenRefreshing === true) {
      if (isFetching === false)
        this.setState({ yourChildrenRefreshing: false, isPullRefresh: false });
    }
    if (otherChildrenRefreshing === true) {
      if (isFetching === false)
        this.setState({ otherChildrenRefreshing: false, isPullRefresh: false });
    }

    if (fetchStudentListDone === true && fetchSchoolInfoDone === true) {
      this.buildYourChildrenList();
      doSchoolAndKidsFetchDone();
    }
    if (fetchOtherChildrenDone === true) {
      this.buildOtherChildrenList();
      doOtherChildrenFetchDone();
    }
  }

  showError = () => {
    const { tokenError, schoolFetchError, studentFetchError } = this.props;
    const msg =
      (tokenError && tokenError.message) ||
      (schoolFetchError && schoolFetchError.message) ||
      (studentFetchError && studentFetchError.message);
    return <div className="alert alert-warning">{msg}</div>;
  };

  ListEmptyComponent = () => {
    const { otherChildrenRefreshing } = this.state;
    const { isFetching, isTokenValidResponse, userLoginInfo } = this.props;

    if (
      isFetching === false &&
      isTokenValidResponse === null &&
      !otherChildrenRefreshing
    ) {
      return (
        <section className="pmk-child-schedule emptylistOtherkids">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 text-center">
                <p>{this.props.t("OtherChildrenNoRecord1")}</p>
                <p>
                  {this.props.t("OtherChildrenNoRecord2")}
                  <span className="blue-text">
                    {userLoginInfo
                      ? userLoginInfo.CountryCode + userLoginInfo.mobile
                      : ""}
                  </span>
                </p>
                <p>{this.props.t("OtherChildrenNoRecord3")}</p>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return null;
    }
  };

  openBarCodeScanner = () => {
    this.props.doShowModal({
      modalScreenName: "scanBarCode",
      modalClassName: "barCodeScannerModal",
      params: {
        doCallbackRefresh: this.doRefreshListing,
      },
    });
  };

  showDismissalAdd = () => {
    const { selectedKids, selectedSchool, markedDates } = this.state;
    const { userLoginInfo } = this.props;
    this.setState({ displayMenu: false });
    //console.debug("ManageChildren school--> ", JSON.stringify(selectedSchool));
    let selectedDate = new Date();
    let setDateForActon = selectedDate;
    if (moment(selectedDate).isBefore(moment())) {
      setDateForActon = new Date();
    }

    this.props.doShowModal({
      modalScreenName: "multikidsSelectWhen",
      modalClassName: "createModifyPickupModal",
      params: {
        selectDate: setDateForActon,
        selectedKids: [...selectedKids],

        school: { ...selectedSchool },

        markedDates,
        doCallbackRefresh: this.doRefreshChildrenListing,
      },
    });
  };

  showCreateMarkAbsent = () => {
    const { selectedKids, selectedSchool } = this.state;
    const { userLoginInfo } = this.props;
    this.setState({ showMenu: false });

    let selectedDate = new Date();
    let setDateForActon = selectedDate;
    if (moment(selectedDate).isBefore(moment())) {
      setDateForActon = new Date();
    }

    this.props.doShowModal({
      modalScreenName: "multikidsCreateMarkAbsent",
      modalClassName: "multikidsCreateMarkAbsent",
      params: {
        selectedDate: setDateForActon,
        selectedKids: [...selectedKids],

        school: { ...selectedSchool },

        doCallbackRefresh: this.doRefreshChildrenListing,
      },
      doCallbackRefresh: this.doRefreshChildrenListing,
    });
  };
  showEarlyDismissal = () => {
    const { selectedKids, selectedSchool, markedDates } = this.state;
    const { userLoginInfo } = this.props;
    this.setState({ showMenu: false });

    let selectedDate = new Date();
    let setDateForActon = selectedDate;
    if (moment(selectedDate).isBefore(moment())) {
      setDateForActon = new Date();
    }

    this.props.doShowModal({
      modalScreenName: "multikdsEarlyDismissal",
      modalClassName: "createModifyPickupModal",
      params: {
        ChildInfo: selectedKids[0],
        selectDate: setDateForActon,
        selectedKids: [...selectedKids],

        school: { ...selectedSchool },

        markedDates,
        doCallbackRefresh: this.doRefreshChildrenListing,
      },
    });
  };
  showChangeDefaultPickupMode = () => {
    const { selectedKids, selectedSchool } = this.state;
    this.setState({ showMenu: false });

    this.props.doShowModal({
      modalScreenName: "multikidsChangeDefaultPickup",
      modalClassName: "changeDefaultPickupModal",
      params: {
        selectedKids: [...selectedKids],

        school: { ...selectedSchool },

        doCallbackRefresh: this.doRefreshChildrenListing,
      },
    });
  };

  doRefreshChildrenListing = () => {
    this.setState(
      {
        isSelectMultiplekids: false,
        displayMenu: false,
        selectedSchool: null,
        selectedKids: [],
        markedDates: {},
        openRow: null,
      },
      () => {
        this.initialLoad();
      }
    );
  };
  openMulKidsSelectOptionsMenu = () => {
    const { selectedKids, selectedSchool, displayMenu } = this.state;

    let ChildInfo = selectedKids[0];
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body multikidslectoptionsmenu-body">
                <div className="optionsmenu-list">
                  <button
                    type="button"
                    className="btn menu-item"
                    onClick={() => {
                      this.showDismissalAdd();
                      onClose();
                    }}
                  >
                    <span className="text">
                      {this.props.t("Change_Pickup_Button")}
                    </span>
                    <i className="fas fa-chevron-right"></i>
                  </button>
                  {ChildInfo && ChildInfo.Permissions.MarkAbsent === true && (
                    <button
                      type="button"
                      className="btn menu-item"
                      onClick={() => {
                        this.showCreateMarkAbsent();
                        onClose();
                      }}
                    >
                      <span className="text">
                        {this.props.t("childDetailScr-mark")}
                      </span>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  )}

                  {isEarlyDismissalAvailableForParent(selectedSchool) && (
                    <button
                      type="button"
                      className="btn menu-item"
                      onClick={() => {
                        this.showEarlyDismissal();
                        onClose();
                      }}
                    >
                      <span className="text">
                        {this.props.t("Early_Dismissal_Text")}
                      </span>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  )}

                  {selectedSchool.AllowDefaultPickupModeChange && (
                    <button
                      type="button"
                      className="btn menu-item"
                      onClick={() => {
                        this.showChangeDefaultPickupMode();
                        onClose();
                      }}
                    >
                      <span className="text">
                        {this.props.t("Default_PickupLabel")}
                      </span>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  showHelpMessage1 = () => {
    return (
      <div
        className="notification-popup helpmessage-bx"
        onClick={() => {
          this.props.doSaveHelpMessage1();
        }}
      >
        <label className="notificationTxt">
          {" "}
          {this.props.t("notification")}
        </label>
        <span className="notification-helptxt">{this.props.t("helpMsg1")}</span>
      </div>
    );
  };
  showHelpMessage2 = () => {
    return (
      <div
        className="notification-popup selectchildren-popup"
        onClick={() => {
          this.props.doSaveHelpMessage2();
        }}
      >
        <label className="notificationTxt">
          {" "}
          {this.props.t("notification")}
        </label>
        <span className="notification-helptxt">{this.props.t("helpMsg2")}</span>
      </div>
    );
  };
  showHelpMessage3 = () => {
    return (
      <div
        className="notification-popup makechanges-popup"
        onClick={() => {
          this.props.doSaveHelpMessage3();
        }}
        style={{
          bottom: this.props.isShowAdvert === true ? "140px" : "62px",
          right: this.props.isShowAdvert === true ? "0" : "40px",
        }}
      >
        <label className="notificationTxt">
          {" "}
          {this.props.t("notification")}
        </label>
        <span className="notification-helptxt">{this.props.t("helpMsg3")}</span>
      </div>
    );
  };

  validateKids = () => {
    const { selectedKids } = this.state;
    if (selectedKids.length === 0) {
      alert(this.props.t("selectchildError"));
      return false;
    } else {
      this.openMulKidsSelectOptionsMenu();
    }
  };

  render() {
    const {
      isFetching,
      helpMessage1,
      helpMessage2,
      helpMessage3,
      selectedTheme,
    } = this.props;
    const styles = getMyStyle(selectedTheme);
    // console.log("selectedTheme", selectedTheme);

    const {
      YourSchoolStudentList,
      OtherSchoolStudentList,
      isSelectMultiplekids,
      selectedKids,
      isYourChildrenVisible,
    } = this.state;
    const { isShowAdvert } = this.props;
    let isShowSelectMultkidsButtons = isVisibleSelectMultikids(
      YourSchoolStudentList
    );

    return (
      <main className="pmk-body graySubtle-bg">
        <MainHeader />

        <section className="pmk-welcome-sect">
          <div className="container-fluid">
            <div className="row flex-middle">
              <div className="col-xs-12 col-sm-8">
                <div className="title-sect">
                  <h1 className="title-style2" style={styles.titleStyle2}>
                    {this.props.t("manageChildrenScr-manageChildren")}
                  </h1>
                  <p className="welcome-text helptext" style={styles.helpTxt}>
                    {this.props.t("manageChildrenScr-manageChildrenDelegate")}
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4 text-right">
                <div className="date-text">
                  {moment().format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="your-other-children-sect">
          <div className="container-fluid">
            <div className="manageChildrenTabs">
              <div className="children-headermenu">
                <ul
                  className="nav nav-tabs nav-tabs-style1"
                  role="tablist"
                  style={styles.tabHolder}
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      style={styles.navTabs}
                      id="your-children-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#your-children-cont"
                      href="#your-children-cont"
                      role="tab"
                      aria-controls="yourchildren-tab"
                      aria-selected="true"
                      onClick={() => {
                        this.setState({
                          isYourChildrenVisible: true,
                          isOtherChildrenVisible: false,
                        });
                      }}
                    >
                      {this.props.t("selectCheckScr-children")}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      style={styles.navTabs}
                      id="other-children-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#other-children-cont"
                      href="#other-children-cont"
                      role="tab"
                      aria-controls="other-children"
                      aria-selected="false"
                      onClick={() => {
                        this.setState({
                          isYourChildrenVisible: false,
                          isOtherChildrenVisible: true,
                        });
                      }}
                    >
                      {this.props.t("manageChildrenScr-otherChildren")}
                    </a>
                  </li>
                </ul>
                {isYourChildrenVisible &&
                !isSelectMultiplekids &&
                isShowSelectMultkidsButtons ? (
                  <button
                    type="button"
                    className="btn-bars btn-notification"
                    onClick={() => {
                      this.props.doSaveHelpMessage1();
                      this.setState({
                        isSelectMultiplekids: true,
                        openRow: null,
                        selectedKids: [],
                        selectedSchool: null,
                      });
                    }}
                  >
                    <i className="fas fa-bars"></i>
                  </button>
                ) : (
                  isYourChildrenVisible &&
                  isShowSelectMultkidsButtons && (
                    <button
                      type="button"
                      className="btn-bars btn-white"
                      onClick={() => {
                        this.setState({
                          isSelectMultiplekids: false,
                          selectedKids: [],
                          selectedSchool: null,
                        });
                      }}
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                  )
                )}

                {isYourChildrenVisible &&
                  (helpMessage1 === 0 || helpMessage1 === undefined) &&
                  isShowSelectMultkidsButtons &&
                  this.showHelpMessage1()}
              </div>

              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="your-children-cont"
                  role="tabpanel"
                  aria-labelledby="yourchildren-tab"
                >
                  {isFetching ? (
                    <div className="loaderHolder mt-60">
                      <RingLoader
                        color={
                          getCurrentThemeColor(this.props.selectedTheme).loader
                        }
                        loading={isFetching}
                        size={60}
                      />
                    </div>
                  ) : !isFetching && YourSchoolStudentList.length === 0 ? (
                    this.showEmpty()
                  ) : (
                    <div className="table-holder">
                      {isYourChildrenVisible &&
                        (helpMessage2 === undefined || helpMessage2 < 5) &&
                        isSelectMultiplekids &&
                        isShowSelectMultkidsButtons &&
                        this.showHelpMessage2()}

                      <table className="table table-borderless table-style1 your-children-tbl">
                        <thead>
                          <tr>
                            <th width="30%" scope="col">
                              {this.props.t("manageChildrenScr-childLabel")}
                            </th>
                            <th width="20%" scope="col" className="hidden-xs">
                              {this.props.t(
                                "manageChildrenScr-dismissalModeLabel"
                              )}
                            </th>
                            <th width="20%" scope="col">
                              {this.props.t(
                                "manageChildrenScr-dismissalStatusLabel"
                              )}
                            </th>
                            <th width="30%" scope="col" className="hidden-xs">
                              {this.props.t("School")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.getYourChildrenView()}</tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="other-children-cont"
                  role="tabpanel"
                  aria-labelledby="otherchildren-tab"
                >
                  {!isFetching && OtherSchoolStudentList.length === 0 ? (
                    this.ListEmptyComponent()
                  ) : (
                    <table className="table table-borderless table-style1 your-children-tbl">
                      <thead>
                        <tr>
                          <th width="30%" scope="col">
                            {this.props.t("manageChildrenScr-childLabel")}
                          </th>
                          <th width="20%" scope="col">
                            {this.props.t("manageChildrenScr-ParentsName")}
                          </th>
                          <th width="20%" scope="col" className="hidden-xs">
                            {this.props.t("otherChildrenScr-nextPickupLabel")}
                          </th>
                          <th width="30%" scope="col" className="hidden-xs">
                            {this.props.t("School")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>{this.getOtherChildrenView()}</tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {!isSelectMultiplekids && (
          <div
            className="qrcode-btn"
            style={{
              bottom: isShowAdvert === true ? "85px" : "5px",
            }}
            onClick={() => {
              this.openBarCodeScanner();
            }}
          >
            <i className="fas fa-qrcode"></i>
          </div>
        )}
        {isYourChildrenVisible && isSelectMultiplekids && (
          <div
            className="mulikidselectoptions-btn"
            style={{
              bottom: isShowAdvert === true ? "85px" : "5px",
              right: isShowAdvert === true ? "20px" : "60px",
            }}
            onClick={() => {
              this.validateKids();
            }}
          >
            <i className="fas fa-ellipsis-h"></i>
          </div>
        )}

        {isYourChildrenVisible &&
          (helpMessage3 === undefined || helpMessage3 < 5) &&
          isSelectMultiplekids &&
          selectedKids.length > 0 &&
          this.showHelpMessage3()}
        {this.props.isShowAdvert && <AdsView />}

        <ToastContainer />
        <MainFooter />
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    otherChildrens: state.student.otherChildrens,
    studentsList: state.student.studentsList,
    schoolInfo: state.student.schoolInfo,
    fetchStudentListDone: state.student.fetchStudentListDone,
    fetchSchoolInfoDone: state.student.fetchSchoolInfoDone,
    fetchOtherChildrenDone: state.student.fetchOtherChildrenDone,
    isTokenValidResponse: state.userAccount.isTokenValidResponse,
    isFetching: loadingSelector(state),
    error: state.student.error,

    tokenError: state.userAccount.tokenError,
    userLoginInfo: state.userAccount.userLoginInfo,
    schoolFetchError: state.student.schoolFetchError,
    studentFetchError: state.student.studentFetchError,
    otherChildrensFetchError: state.student.otherChildrensFetchError,
    setHelpListData: state.userAccount.setHelpListData,
    displayAds: state.userAccount.displayAds,
    isTermsAccepted: state.userAccount.isTermsAccepted,
    getBackSchoolInfoData: state.student.getBackSchoolInfo,
    nextAskMeLater: state.userAccount.nextAskMeLater,

    modalLevelInfo: state.userAccount.modalLevelInfo,

    helpMessage1: state.userAccount.helpMessage1,
    helpMessage2: state.userAccount.helpMessage2,
    helpMessage3: state.userAccount.helpMessage3,

    userProfile: state.userAccount.userProfile,
    selectedTheme: state.userAccount.selectedTheme,

    isShowAdvert: state.ads.isShowAdvert,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doGetOtherChildrenList: () => dispatch(getOtherChildrenDetails()),
    doGetStudentsList: (data) => dispatch(getStudentsList(data)),
    doGetSchoolDetails: () => dispatch(getSchoolDetails()),
    doGetIsTokenValid: (data) => dispatch(getIsTokenValid(data)),
    doGetIsTokenValidClear: () => dispatch(getIsTokenValidClear()),
    doSchoolAndKidsFetchDone: () => dispatch(schoolAndKidsFetchDone()),
    doOtherChildrenListClear: () => dispatch(otherChildrenListClear()),
    doLogoutUser: () => dispatch(logoutUser()),
    doOtherChildrenFetchDone: () => dispatch(otherChildrenFetchDone()),
    doGetbacktoschoolInfo: (data) => dispatch(getBackToSchoolInformation(data)),

    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doSaveHelpMessage1: () => dispatch(saveHelpMessage1()),
    doSaveHelpMessage2: () => dispatch(saveHelpMessage2()),

    doGetUserProfileInfo: (callback) => dispatch(getUserProfileInfo(callback)),
  };
}

export default withTranslation()(
  withRouterHook(connect(mapStateToProps, mapDispatchToProps)(ChildManagement))
);
