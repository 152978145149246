import { getEnvironment, getAxios } from "./config";
import { validateResponse } from "../../helper/utility";

/**
 * API call to http://35.167.52.234/api/GetSchoolPickupTimes
 * Purpose: Get School Details
 */
export const GetSchoolPickupTimes = async ({ SchoolID }) => {
  const param = "";
  const PostURL = `${getEnvironment()}school/GetSchoolPickupTimes?SchoolID=${SchoolID}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const GetSchoolPickupModes = async ({ SchoolID }) => {
  const param = "";
  const PostURL = `${getEnvironment()}school/GetSchoolPickupModes?SchoolID=${SchoolID}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const GetReasonsForAbsence = async ({ SchoolID }) => {
  const param = "";
  const PostURL = `${getEnvironment()}school/GetReasonsForAbsence?SchoolID=${SchoolID}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const GetSchoolAfterSchools = async ({ SchoolID }) => {
  const param = "";
  const PostURL = `${getEnvironment()}school/GetSchoolAfterSchools?SchoolID=${SchoolID}`;
  const FullUrl = PostURL + param;
  // console.log("url for after school", FullUrl);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const getSchoolInfo2 = async ({ SchoolID, IncludeDismissalTimes }) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetSchoolInfo?SchoolID=${SchoolID}&IncludeDismissalTimes=${IncludeDismissalTimes}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const getHelp = async ({ schoolId, pageName, stackType }) => {
  const param = "";
  let PostURL = "";

  if (stackType === "AuthStack" || stackType === "Registration") {
    PostURL = `${getEnvironment()}school/GetHelp_Open?PageName=${pageName}`;
  } else {
    PostURL = `${getEnvironment()}school/GetHelp?SchoolID=${schoolId}&PageName=${pageName}`;
  }

  const FullUrl = PostURL + param;

  // console.log("HelpURL-  " + FullUrl);

  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const getSchoolCalendarData = async ({ schoolId }) => {
  const param = "";
  const PostURL = `${getEnvironment()}school/GetSchoolCalendar?schoolId=${schoolId}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const getSchoolCalendarEventData = async ({ schoolId }) => {
  const param = "";
  const PostURL = `${getEnvironment()}school/GetSchoolCalendarEventTypes?schoolId=${schoolId}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};
