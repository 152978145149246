import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import RingLoader from "react-spinners/ClipLoader";
import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
} from "../../../common/actions/userAccount";
import {
  changeDefaultPickupMode,
  changeDefaultPickupModeClear,
  getAvailableDispatch,
  getAvailableDispatchClear,
  getKidDefaultDismissal,
  getKidDefaultDismissalClear,
} from "../../../common/actions/students";
import { createLoadingSelector } from "../../../common/reducers/selectors";
import GLOBAL_CONST from "../../../helper/AppConstants";
const loadingSelector = createLoadingSelector([
  "get_school_pickup_mode",
  "change_default_pickup_mode",
]);
import "react-confirm-alert/src/react-confirm-alert.css";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getKidsName, getCurrentThemeColor } from "../../../helper/utility";

class MultikidsChangeDefaultPickup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOptionPickupMode: "Select",
      selectedBusRouteOptionLabel: "Select",
      selectedOptionPickupBy: "Select",
      previousSelectedOptionsPickupMode: null,
      previousSelectedOptionsPickupBy: null,
      selectedSubPickupModeOption: "Select",
      previousSelectedSubpickupModeOption: null,

      selectedBusRouteOption: "Select",
      previousSelecteBusRouteOption: null,

      selectChild: false,
      pickupModeList: [],
      schoolSubOptions: [],
      busRouteSubOptions: [],
      avatarSource: null,
    };

    this.getDispatchList();
  }
  componentDidMount() {
    this.dosetHelpList();
  }
  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "ChildManagement",
      screen: GLOBAL_CONST.SCREEN_NAMES.DEFAULT_PICKUP_MODE,
      title: this.props.t("changePickupScr-pickupMode"),
    });
  };

  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  getDispatchList = () => {
    const { selectedKids, doGetAvailableDispatch } = this.props;
    const selectedKidsIds = selectedKids.map((child) => {
      return child.StudentID;
    });

    doGetAvailableDispatch({ studentIDs: selectedKidsIds });
    const { doGetKidDefaultDismissal } = this.props;
    doGetKidDefaultDismissal({ selectedKidsIds });
  };
  componentWillUnmount() {
    this.props.doGetAvailableDispatchClear();
    this.props.doGetKidDefaultDismissalClear();
  }

  submitForm = () => {
    const { doChangeDefaultPickupMode, defaultKidsDismissalResponse } =
      this.props;
    const {
      selectedOptionPickupMode,
      selectedSubPickupModeOption,
      selectedBusRouteOption,
    } = this.state;

    const { selectedKids } = this.props;
    let selectedkidArr = [];
    if (selectedKids) {
      selectedKids.filter((kid) => {
        selectedkidArr.push(kid.StudentID);
      });
    }

    if (selectedOptionPickupMode === "Select") {
      alert(this.props.t("Select_PickupMode_Alert"));
    } else if (
      selectedOptionPickupMode == 4 &&
      selectedSubPickupModeOption == "Select"
    ) {
      alert(this.props.t("Select_AfterSchool_Program_alert"));
    } else if (
      selectedOptionPickupMode == 1 &&
      selectedBusRouteOption == "Select"
    ) {
      alert(this.props.t("Select_Bus_Number_Alert"));
    } else {
      let pickupModeSubItem;

      if (selectedOptionPickupMode == 1) {
        pickupModeSubItem = selectedBusRouteOption;
      } else if (selectedOptionPickupMode == 4) {
        pickupModeSubItem = selectedSubPickupModeOption;
      } else {
        pickupModeSubItem = 0;
      }

      let isSameAsDefault = false;
      let name = [];
      for (let i = 0; i < defaultKidsDismissalResponse.length; i += 1) {
        if (
          parseInt(defaultKidsDismissalResponse[i].PickupModeID, 10) ===
            parseInt(selectedOptionPickupMode, 10) &&
          pickupModeSubItem === 0
        ) {
          isSameAsDefault = true;
          name.push(selectedKids[i].Firstname + " " + selectedKids[i].Lastname);
        }

        if (pickupModeSubItem !== 0) {
          if (
            parseInt(defaultKidsDismissalResponse[i].PickupModeID, 10) == 4 &&
            parseInt(defaultKidsDismissalResponse[i].AfterSchoolID, 10) ===
              parseInt(pickupModeSubItem, 10)
          ) {
            isSameAsDefault = true;
            name.push(
              selectedKids[i].Firstname + " " + selectedKids[i].Lastname
            );
          } else if (
            parseInt(defaultKidsDismissalResponse[i].PickupModeID, 10) == 1 &&
            parseInt(defaultKidsDismissalResponse[i].BusRouteID, 10) ===
              parseInt(pickupModeSubItem, 10)
          ) {
            isSameAsDefault = true;
            name.push(
              selectedKids[i].Firstname + " " + selectedKids[i].Lastname
            );
          }
        }
      }

      if (isSameAsDefault) {
        alert(
          this.props.t("pickup_default_with_Kidname1") +
            name +
            this.props.t("pickup_default_with_Kidname2")
        );
        return;
      }

      doChangeDefaultPickupMode({
        studentID: selectedkidArr,
        pickupModeID: selectedOptionPickupMode,
        pickupModeSubItem,
      });
    }
  };

  getPickupModeById = (value) => {
    const { pickupModeList } = this.state;
    for (let i = 0; i < pickupModeList.length; i++) {
      if (value === pickupModeList[i].value) return pickupModeList[i].label;
    }
  };

  getAfterSchoolProgramById = (value) => {
    const { schoolSubOptions } = this.state;
    for (let i = 0; i < schoolSubOptions.length; i++) {
      if (value === schoolSubOptions[i].value) return schoolSubOptions[i].label;
    }
  };

  getBusRouteById = (value) => {
    const { busRouteSubOptions } = this.state;
    for (let i = 0; i < busRouteSubOptions.length; i++) {
      if (value === busRouteSubOptions[i].value)
        return busRouteSubOptions[i].label;
    }
  };

  renderChangeDefaultPickupView = () => {
    const { isFetching, selectedKids, school } = this.props;
    const {
      selectedOptionPickupMode,
      selectedSubPickupModeOption,
      selectedBusRouteOption,
      pickupModeList,
      schoolSubOptions,
      busRouteSubOptions,
    } = this.state;

    const defaultPickupMode = this.props.defaultKidsDismissalResponse;
    let kidsName = getKidsName(selectedKids);

    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="scrollY-sm">
            <div className="modal-header">
              <div className="title-section">
                <h4 className="modal-title">
                  {this.props.t("changePickupScr-pickupMode")}
                </h4>
                <button
                  type="button"
                  className="btn help-icon-btn"
                  onClick={() => {
                    this.openHelpModal();
                  }}
                >
                  {this.props.t("regNameScr-needHelp")}
                </button>

                <p className="modal-otherinfo">
                  {kidsName} <br />
                  {selectedKids[0].SchoolName} <br />
                  {defaultPickupMode && defaultPickupMode.length > 0 && (
                    <span>
                      {defaultPickupMode[0].PickupModeName}{" "}
                      {defaultPickupMode[0].PickupModeSubName != null
                        ? defaultPickupMode[0].PickupModeSubName
                        : null}
                    </span>
                  )}
                </p>
              </div>

              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  this.closeModal();
                }}
                aria-label={this.props.t("Close_Button")}
              ></button>
            </div>

            <div className="modal-body">
              <div className="card form-card">
                <form className="form form-style2">
                  <div className="form-group">
                    <label className="control-label">
                      {/* {this.props.t("changePickupScr-pickupMode")} */}
                    </label>
                    <Dropdown
                      className="custom-dropdown"
                      onSelect={(eventKey, value) => {
                        this.setState({
                          selectedOptionPickupMode: eventKey,
                        });
                      }}
                      onToggle={() => {
                        this.setState({
                          previousSelectedOptionsPickupMode:
                            selectedOptionPickupMode,
                        });
                      }}
                    >
                      <Dropdown.Toggle>
                        {this.getPickupModeById(selectedOptionPickupMode)}
                        {pickupModeList.length === 0 && (
                          <RingLoader
                            color="#2d3d56"
                            loading={true}
                            size={20}
                          />
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {pickupModeList.map((data) => {
                          return (
                            <Dropdown.Item
                              className={
                                selectedOptionPickupMode == data.value
                                  ? "active"
                                  : ""
                              }
                              eventKey={data.value}
                              key={data.value}
                            >
                              <span className="dropdownitem-label">
                                {data.label}
                              </span>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="form-group">
                    {selectedOptionPickupMode == 4 ? (
                      <label className="control-label">
                        {this.props.t("After_School_Program")}{" "}
                      </label>
                    ) : selectedOptionPickupMode == 1 ? (
                      <label className="control-label">
                        {this.props.t("Bus_Route")}
                      </label>
                    ) : null}

                    {selectedOptionPickupMode == 4 ? (
                      <Dropdown
                        className="custom-dropdown"
                        onSelect={(eventKey, value) => {
                          this.setState({
                            selectedSubPickupModeOption: eventKey,
                          });
                        }}
                      >
                        <Dropdown.Toggle>
                          {this.getAfterSchoolProgramById(
                            selectedSubPickupModeOption
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {schoolSubOptions.map((data) => {
                            return (
                              <Dropdown.Item
                                className={
                                  selectedSubPickupModeOption === data.value
                                    ? "active"
                                    : ""
                                }
                                eventKey={data.value}
                                key={data.value}
                              >
                                <span className="dropdownitem-label">
                                  {data.label}
                                </span>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : selectedOptionPickupMode == 1 ? (
                      <Dropdown
                        className="custom-dropdown"
                        onSelect={(eventKey, value) => {
                          this.setState({
                            selectedBusRouteOption: eventKey,
                          });
                        }}
                      >
                        <Dropdown.Toggle>
                          {this.getBusRouteById(selectedBusRouteOption)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {busRouteSubOptions.map((data) => {
                            return (
                              <Dropdown.Item
                                eventKey={data.value}
                                key={data.value}
                                className={
                                  selectedBusRouteOption === data.value
                                    ? "active"
                                    : ""
                                }
                              >
                                <span className="dropdownitem-label">
                                  {data.label}
                                </span>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default white-cancel-btn"
                onClick={() => {
                  this.closeModal();
                }}
              >
                {this.props.t("regNameScr-cancel")}
              </button>
              <button
                type="button"
                className="btn btn-default"
                style={{
                  backgroundColor: getCurrentThemeColor(
                    this.props.selectedTheme
                  ).buttonDefault,
                }}
                onClick={() => {
                  this.submitForm();
                }}
              >
                {isFetching ? (
                  <i className="fa-solid fa-arrows-rotate fa-spin"></i>
                ) : null}{" "}
                {this.props.t("changePickupScr-save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };

  componentDidUpdate() {
    const {
      availableDispatch,
      changeDefaultPickupModeResponse,
      error,
      doChangeDefaultPickupModeClear,
      doGetAvailableDispatchClear,
      doCallbackRefresh,
    } = this.props;

    const { pickupModeList, schoolSubOptions, busRouteSubOptions } = this.state;

    let schoolOptions = [];
    let busOptions = [];
    let pickupModes = [];

    if (availableDispatch) {
      let methodsAvailableToEveryone =
        availableDispatch.MethodsAvailableToEveryone;
      pickupModes.push({
        label: `${this.props.t("Select_Dismissal_Label")}`,
        value: "Select",
      });
      for (let i = 0; i < methodsAvailableToEveryone.length; i++) {
        pickupModes.push({
          label: `${methodsAvailableToEveryone[i].Name}`,
          value: `${methodsAvailableToEveryone[i].PickupModeID}`,
        });
      }

      if (pickupModeList.length === 0 && pickupModes.length > 0) {
        this.setState({ pickupModeList: pickupModes });
      }
      schoolOptions.push({
        label: `${this.props.t("Select_Dismissal_Label")}`,
        value: `Select`,
      });
      for (let i = 0; i < methodsAvailableToEveryone.length; i++) {
        const subSchool = methodsAvailableToEveryone[i].SubOptions;
        if (methodsAvailableToEveryone[i].PickupModeID === 4) {
          for (let j = 0; j < subSchool.length; j++) {
            schoolOptions.push({
              label: `${subSchool[j].Name}`,
              value: `${subSchool[j].ID}`,
            });
          }

          break;
        }
      }
      if (schoolSubOptions.length === 0 && schoolOptions.length > 0) {
        schoolOptions = schoolOptions.sort((a, b) => {
          if (a.label == "Select" || b.label == "Select") return 1;
          return a.label.toLowerCase() > b.label.toLowerCase()
            ? 1
            : b.label.toLowerCase() > a.label.toLowerCase()
            ? -1
            : 0;
        });
        this.setState({ schoolSubOptions: schoolOptions });
      }
      busOptions.push({
        label: `${this.props.t("Select_Dismissal_Label")}`,
        value: `Select`,
      });

      for (let i = 0; i < methodsAvailableToEveryone.length; i++) {
        const subBus = methodsAvailableToEveryone[i].SubOptions;
        if (methodsAvailableToEveryone[i].PickupModeID === 1) {
          for (let j = 0; j < subBus.length; j++) {
            busOptions.push({
              label: `${subBus[j].Name}`,
              value: `${subBus[j].ID}`,
            });
          }

          break;
        }
      }
      if (busRouteSubOptions.length === 0 && busOptions.length > 0) {
        busOptions = busOptions.sort((a, b) => {
          if (a.label == "Select" || b.label == "Select") return 1;
          return a.label.toLowerCase() > b.label.toLowerCase()
            ? 1
            : b.label.toLowerCase() > a.label.toLowerCase()
            ? -1
            : 0;
        });

        this.setState({ busRouteSubOptions: busOptions });
      }
    }

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doChangeDefaultPickupModeClear();
    } else if (changeDefaultPickupModeResponse) {
      let msg =
        changeDefaultPickupModeResponse &&
        changeDefaultPickupModeResponse.Data[0] &&
        changeDefaultPickupModeResponse.Data[0].Message;

      toast.success(msg, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      this.closeModal();
      doChangeDefaultPickupModeClear();
      doGetAvailableDispatchClear();
      if (doCallbackRefresh) doCallbackRefresh();
    }
  }

  render() {
    return this.renderChangeDefaultPickupView();
  }
}

function mapStateToProps(state) {
  return {
    isFetching: loadingSelector(state),
    changeDefaultPickupModeResponse:
      state.student.changeDefaultPickupModeResponse,
    availableDispatch: state.student.availableDispatch,
    error: state.student.error,
    setHelpListData: state.userAccount.setHelpListData,
    defaultKidsDismissalResponse: state.student.defaultKidsDismissalResponse,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doChangeDefaultPickupMode: (data) =>
      dispatch(changeDefaultPickupMode(data)),
    doChangeDefaultPickupModeClear: () =>
      dispatch(changeDefaultPickupModeClear()),
    doGetAvailableDispatch: (data) => dispatch(getAvailableDispatch(data)),
    doGetAvailableDispatchClear: () => dispatch(getAvailableDispatchClear()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),

    doGetKidDefaultDismissal: (data) => dispatch(getKidDefaultDismissal(data)),
    doGetKidDefaultDismissalClear: () =>
      dispatch(getKidDefaultDismissalClear()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MultikidsChangeDefaultPickup)
);
