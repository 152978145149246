import React from "react";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Bootstrap,
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  showModal,
  hideModal,
  setHelpList,
  setOldHelpSetting,
  ssoUser_generateOTPForTransaction,
  ssoUser_generateOTPForTransactionClear,
} from "../../../common/actions/userAccount";
import { getAbsentReason } from "../../../common/actions/school";
import {
  submitAbsentee,
  submitAbsenteeClear,
  getPickupEntries,
  getPickupEntriesClear,
  getEarlyDismissalsForDateRange,
  getEarlyDismissalsForDateRangeClear,
} from "../../../common/actions/students";

import { event } from "jquery";
import moment from "moment";
import { Colors } from "../../../theme";
import {
  getMarkStyle,
  isTodayAndAnnounced,
  filterByTypes,
  getKidsName,
  getCurrentThemeColor,
} from "../../../helper/utility";
import GLOBAL_CONST from "../../../helper/AppConstants";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createLoadingSelector } from "../../../common/reducers/selectors";

const loadingSelector = createLoadingSelector([
  "get_absent_reason",
  "save_absentee",
  "sso_user_send_otp_transaction",
  "sso_user_send_otp_transaction",
]);

class MultikidsCreateMarkAbsent extends React.Component {
  constructor(props) {
    super(props);
    this.setRepeat = this.setRepeat.bind(this);
    const { selectedKids, school } = this.props;
    const firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);

    let selectedDate = this.props.selectedDate;

    if (!selectedDate) {
      selectedDate = new Date();
    }

    if (isTodayAndAnnounced(new Date(selectedDate), selectedKids[0])) {
      selectedDate.setDate(new Date().getDate() + 1);
    }

    const isSelectedPriorToFirstDay = moment(firstDayOfSchool).isAfter(
      moment(selectedDate)
    );
    if (isSelectedPriorToFirstDay) selectedDate = firstDayOfSchool;

    let selectedTillDate = new Date(selectedDate);
    selectedTillDate = selectedTillDate.setDate(selectedTillDate.getDate() + 1);
    this.state = {
      isRepeat: false,
      selectedDate,
      selectedOption: this.props.t("Select_Dismissal_Label"),
      selectedTillDate,
      previousSelectedOptions: this.props.t("Select_Dismissal_Label"),
      isFromDateTimePickerVisible: false,
      isUntilateTimePickerVisible: false,
      markedDates: {},
      currentList: [],
      password: "",

      showCalendarPicker: false,
      showUntilCalendar: false,
      showSelectHowUIFlag: false,
      showWhenCalendarPicker: false,
      showUntilCalendarPicker: false,

      selectedAbsentReason: this.props.t("Select_Dismissal_Label"),
      otp_deleteAccount: "",
      isShowOTPTxt: false,
      otp: "",
    };

    this.getAbsenteeReason();
  }
  componentDidMount() {
    this.dosetHelpList();
    this.getDismissalsList();
  }
  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "ChildManagement",
      screen: GLOBAL_CONST.SCREEN_NAMES.MARK_ABSENT,
      title: this.props.t("childDetailScr-mark"),
    });
  };

  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  getDismissalsList = () => {
    const {
      doGetPickupEntries,
      school,
      doGetEarlyDismissalsForDateRange,
      selectedKids,
    } = this.props;
    const { isLastDayOfSchoolPast } = this.state;

    let selectedKidsIds = selectedKids.map((child) => {
      return child.StudentID;
    });

    let firstDayOfSchool = new Date(school.Dates.FirstDayOfSchool);

    let monthStart = new Date();
    const diffFirstDayofSchool = moment(firstDayOfSchool).diff(
      moment(),
      "months"
    );

    if (diffFirstDayofSchool < -12) {
      endDate = moment().add(12, "M").format("MM-DD-YYYY");
      monthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    } else {
      monthStart = firstDayOfSchool;
    }

    let monthEnd = new Date(school.Dates.LastDayOfSchool);

    var startDate = moment(monthStart).format("MM-DD-YYYY");
    var endDate = moment(monthEnd).format("MM-DD-YYYY");

    if (isLastDayOfSchoolPast) {
      endDate = moment().add(12, "M").format("MM-DD-YYYY");
    }

    doGetPickupEntries({
      studentID: selectedKidsIds[0],
      startDate,
      endDate,
    });

    var eStartDate = moment(monthStart).format("YYYY-MM-DDTHH:mm:ss");
    var eEndDate = moment(monthEnd).format("YYYY-MM-DDTHH:mm:ss");

    doGetEarlyDismissalsForDateRange({
      StudentID: selectedKidsIds[0],
      StartDate: eStartDate,
      EndDate: eEndDate,
    });
  };

  changeMonth = (value) => {
    this.calendar.addMonth(value);
    const { currentSelectedMonth } = this.state;
    const currMonth = moment(currentSelectedMonth).add(value, "M");
    this.setState({
      currentSelectedMonth: currMonth,
      selectedDate: currMonth.toDate(),
    });
  };
  getAbsenteeReason = () => {
    const { doGetAbsentReason, selectedKids } = this.props;

    doGetAbsentReason({ SchoolID: selectedKids[0].SchoolID });
  };

  onFromDateSelected = (value) => {
    this.hideLegendView();
    //this.setState({ selectedDate: date });
    var firstDate = value;
    const newDate = new Date(firstDate);

    let { selectedTillDate } = this.state;

    const isFuture = moment(selectedTillDate).isAfter(moment(newDate));
    if (!isFuture) {
      selectedTillDate = new Date(newDate.setDate(newDate.getDate() + 1));
    }
    this.setState({ selectedDate: firstDate, selectedTillDate });
  };
  onToDateSelected = (date) => {
    this.hideLegendView();
    this.setState({ selectedTillDate: date });
  };
  hideLegendView = (e) => {
    this.setState({ showCalendarPicker: false });
  };

  showLegendView = (e) => {
    this.setState({ showCalendarPicker: true });
    this.setState({ showWhenCalendarPicker: true });
    this.setState({ showUntilCalendarPicker: false });
  };
  showUntilLegendView = (e) => {
    this.setState({ showCalendarPicker: true });
    this.setState({ showUntilCalendarPicker: true });
    this.setState({ showWhenCalendarPicker: false });
  };
  showUntilCalendarPicker = (e) => {
    this.setState({ showUntilCalendar: true });
  };

  showSelectHowUI = (e) => {
    this.setState({ showSelectHowUIFlag: true });
  };

  renderLegendUI = () => {
    return (
      <div className={`datp-col show-legend`} id="legnedList">
        <div className="legend-holder">
          <h5 className="legend-title">{this.props.t("Legend")}</h5>
          <ul className="unstyle-list legend-list">
            <li className="green-legend">
              {this.props.t("Approved_Pickup_Change_Text")}
            </li>
            <li className="black-legend">
              {this.props.t("Approved_EarlyPickup_Change_Text")}
            </li>
            <li className="red-legend">{this.props.t("Mark_Absent_Text")}</li>
            <li className="orange-legend">
              {this.props.t("Pending_Approval_Text_p")}
            </li>
            <li className="gray-legend">
              {this.props.t("Pending_Approval_Text_e")}
            </li>

            <h6 className="text_italic">
              {this.props.t("Pending_Approval_Text2")}
            </h6>
          </ul>
        </div>
      </div>
    );
  };

  getSelectDateDayRecord() {
    const { selectedDate } = this.state;

    const markedDates = { ...this.state.markedDates };

    // console.log("marked Dates--> ",JSON.stringify(markedDates))
    let currentList = [];
    var selectDateStr = moment(new Date(selectedDate)).format("YYYY-MM-DD");

    for (const key in markedDates) {
      if (key === selectDateStr) {
        // console.log(
        //   `****************** getDayRecord ${selectDateStr} key ${key} CalendarDate ${markedDates[key].record.CalendarDate}`
        // );
        var selectedMarked = {
          selected: true,
          selectedColor: Colors.blue,
          className: [markedDates[key].className + " " + "selectedDate"],
          record: markedDates[key].record,
        };
        //alert(selectedMarked.record.CalendarDate);
        markedDates[key] = selectedMarked;
      } else {
        if (markedDates[key] && markedDates[key].record) {
          markedDates[key] = getMarkStyle(markedDates[key].record);
        }
      }
    }

    if (markedDates[selectDateStr]) {
      currentList.push(markedDates[selectDateStr]);
    }

    this.setState({ markedDates, currentList });
  }

  getDayRecord(type) {
    const { selectedDate, selectedTillDate } = this.state;
    const setDate = type === "from" ? selectedDate : selectedTillDate;
    const markedDates = { ...this.state.markedDates };
    var selectDateStr = moment(new Date(setDate)).format("YYYY-MM-DD");

    for (const key in markedDates) {
      if (key === selectDateStr) {
        var selectedMarked = {
          selected: true,
          selectedColor: Colors.blue,
          record: markedDates[key].record,
        };
        markedDates[key] = selectedMarked;
      } else {
        markedDates[key] = getMarkStyle(markedDates[key].record);
      }
    }
    this.setState({ markedDates });
  }

  renderMarkAbsentView = () => {
    const {
      selectedOption,
      showCalendarPicker,
      selectedDate,
      selectedTillDate,
      showWhenCalendarPicker,
      showUntilCalendarPicker,
      isRepeat,
      markedDates,
    } = this.state;
    const { isFetching, selectedKids } = this.props;
    const pickupDates = filterByTypes(markedDates);
    const absentReason = [];
    const { absentReasonResponse } = this.props;

    if (absentReasonResponse != null) {
      const absentReasonResult = absentReasonResponse.AbsenceReasons;
      if (absentReasonResult != null && absentReasonResult != undefined) {
        absentReason.push({
          key: "Select",
          value: this.props.t("Select_Dismissal_Label"),
        });
        for (let i = 0; i < absentReasonResult.length; i++) {
          absentReason.push({
            key: `${absentReasonResult[i].Name}`,
            value: `${absentReasonResult[i].Name}`,
          });
        }
      }
    }

    let minUntilDate = moment().toDate();
    if (selectedDate !== null) {
      minUntilDate = new Date(selectedDate);
      minUntilDate = minUntilDate.setDate(minUntilDate.getDate() + 1);
    }
    let kidsName = getKidsName(selectedKids);

    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="scrollY-sm">
            <div className="modal-header">
              <div className="title-section">
                <h4 className="modal-title">
                  {this.props.t("childDetailScr-mark")}
                </h4>
                <p className="mb-5px">
                  <small> {this.props.t("mark-absentFor")}</small>
                </p>

                <p className="modal-helptext">{kidsName}</p>
                <button
                  type="button"
                  className="btn help-icon-btn"
                  onClick={() => {
                    this.openHelpModal();
                  }}
                >
                  {this.props.t("regNameScr-needHelp")}
                </button>
              </div>

              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  this.closeModal();
                }}
                aria-label={this.props.t("Close_Button")}
              ></button>
            </div>

            <div className="markabsentformsect">
              <div className="modal-body">
                <div className="card form-card markAbsentForm">
                  <form className="form form-style2">
                    <div className="row ">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            {this.props.t("createMarkAbScr-when")}
                          </label>
                          <div
                            id="whenCalendarHolder"
                            className="datp-calendar-holder"
                          >
                            <div className="datp-col">
                              <DatePicker
                                id="selectFromDate"
                                key={
                                  selectedKids[0].StudentID + "selectFromDate"
                                }
                                className="form-control"
                                selected={moment(selectedDate).toDate()}
                                minDate={moment().toDate()}
                                onCalendarOpen={this.showLegendView}
                                onSelect={this.onFromDateSelected}
                                onClickOutside={this.hideLegendView}
                                dateFormat="eee, MMM do yyyy"
                                highlightDates={[
                                  {
                                    "react-datepicker__day--highlightedRed":
                                      pickupDates.pickupRed,
                                  },
                                  {
                                    "react-datepicker__day--highlightedGreen":
                                      pickupDates.pickupGreen,
                                  },
                                  {
                                    "react-datepicker__day--highlightedOrange":
                                      pickupDates.pickupLightOrange,
                                  },
                                  {
                                    "react-datepicker__day--highlightedGray":
                                      pickupDates.pickupGray,
                                  },
                                  {
                                    "react-datepicker__day--highlightedBlack":
                                      pickupDates.pickupBlack,
                                  },
                                ]}
                                customInput={<CustomInput />}
                              >
                                {showCalendarPicker === true &&
                                  showWhenCalendarPicker === true &&
                                  this.renderLegendUI()}
                              </DatePicker>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            {this.props.t("markAbsentScr-whyWill")} {kidsName}
                            {this.props.t("markAbsentScr-be")}
                          </label>

                          <Dropdown
                            className="custom-dropdown"
                            onSelect={this.handleSelect}
                          >
                            <Dropdown.Toggle>{selectedOption}</Dropdown.Toggle>
                            <Dropdown.Menu>
                              {absentReason.map((data) => {
                                return (
                                  <Dropdown.Item
                                    className={
                                      selectedOption === data.value
                                        ? "active"
                                        : ""
                                    }
                                    eventKey={data.value}
                                    key={data.key}
                                  >
                                    <span className="dropdownitem-label">
                                      {data.value}
                                    </span>
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <div className="form-input-holder">
                            <span className="text">
                              {" "}
                              {this.props.t("createMarkAbScr-repeatThis")}
                            </span>
                            <label className="switch">
                              <input
                                type="checkbox"
                                name="repeatChangeStatus"
                                checked={isRepeat}
                                value={isRepeat}
                                onChange={() => {
                                  this.setState({
                                    isRepeat: !isRepeat,
                                  });
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      {isRepeat === true && (
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="control-label">
                              {this.props.t("createMarkAbScr-until")}
                            </label>
                            <div
                              id="whenCalendarHolder"
                              className="datp-calendar-holder"
                            >
                              <div className="datp-col">
                                <DatePicker
                                  id="selectTillDate"
                                  key={
                                    selectedKids[0].StudentID + "selectTillDate"
                                  }
                                  className="form-control"
                                  selected={moment(selectedTillDate).toDate()}
                                  minDate={minUntilDate}
                                  onCalendarOpen={this.showUntilLegendView}
                                  onSelect={this.onToDateSelected}
                                  onClickOutside={this.hideLegendView}
                                  dateFormat="eee, MMM do yyyy"
                                  highlightDates={[
                                    {
                                      "react-datepicker__day--highlightedRed":
                                        pickupDates.pickupRed,
                                    },
                                    {
                                      "react-datepicker__day--highlightedGreen":
                                        pickupDates.pickupGreen,
                                    },
                                    {
                                      "react-datepicker__day--highlightedOrange":
                                        pickupDates.pickupLightOrange,
                                    },
                                    {
                                      "react-datepicker__day--highlightedGray":
                                        pickupDates.pickupGray,
                                    },
                                    {
                                      "react-datepicker__day--highlightedBlack":
                                        pickupDates.pickupBlack,
                                    },
                                  ]}
                                  customInput={<CustomInput />}
                                >
                                  {showCalendarPicker === true &&
                                    showUntilCalendarPicker === true &&
                                    this.renderLegendUI()}
                                </DatePicker>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default white-cancel-btn"
                  onClick={() => {
                    this.closeModal();
                  }}
                >
                  {this.props.t("editChildScr-cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    backgroundColor: getCurrentThemeColor(
                      this.props.selectedTheme
                    ).buttonDefault,
                  }}
                  onClick={() => {
                    if (this.validateForm()) {
                      if (
                        absentReasonResponse.PasswordRequiredForChange === true
                      )
                        this.openPasswordOtpEntryModal();
                      else this.submitForm();
                    }
                  }}
                >
                  {isFetching ? (
                    <i className="fa-solid fa-arrows-rotate fa-spin"></i>
                  ) : null}{" "}
                  {this.props.t("changePickupScr-save")}
                </button>
              </div>
            </div>

            {/* {this.state.displayPasswordEntry === true &&
              this.showPasswordOptions()} */}
          </div>
        </div>
      </div>
    );
  };
  handleSelect = (e) => {
    this.setState({ selectedOption: e });
  };
  handlePassword = (password) => {
    this.setState({ password });
  };
  handleChange = (otp) => {
    this.setState({ otp, otpError: "" });
  };

  openPasswordOtpEntryModal = () => {
    const { isShowOTPTxt, otpError, otp } = this.state;
    const { isFetching } = this.props;
    this.props.doShowModal({
      modalScreenName: "passwordOtpEntry",
      modalClassName: "passwordOtpEntryModal",
      params: {
        helpTxt: this.props.t("sso_markabsent_title"),
        passowrdSavebuttonTxt: this.props.t("CONFIRM_MarkAbsent_Btn"),
        isFetching: isFetching,
        doGenerateOtp: this.generateOtpForTransaction,
        isShowOTPTxt: isShowOTPTxt,
        handleOtpChange: this.handleChange,
        otpError: otpError,
        otp: otp,
        verifyOtp: this.verifyOtp,
        handlePassword: this.handlePassword,
        confirmPassword: this.confirmPassword,
        doCallbackSetOTP: this.handleChange,
        doCallbackSetPassword: this.handlePassword,
      },
    });
  };
  generateOtpForTransaction = () => {
    const { school } = this.props;
    this.props.doSsoUser_generateOTPForTransaction({
      SchoolId: school.SchoolID,
    });
    this.setState({ isShowOTPTxt: true });
  };

  verifyOtp = () => {
    this.submitForm();
  };

  componentDidUpdate() {
    const absentReason = [];
    const {
      absentReasonResponse,
      error,
      submitAbsenteeResponse,
      doSubmitAbsenteeClear,
      doCallbackRefresh,

      pickupEntries,
      doGetPickupEntriesClear,

      earlyDismissalDateRange,
      doGetEarlyDismissalsForDateRangeClear,
    } = this.props;

    let newMarkedDates = {};
    if (pickupEntries !== null && earlyDismissalDateRange !== null) {
      // console.log(
      //   "Inside mark builder " + pickupEntries.CalendarEntries.length
      // );
      newMarkedDates = {};
      for (var i = 0; i < pickupEntries.CalendarEntries.length; i++) {
        const record = { ...pickupEntries.CalendarEntries[i] };
        var day = moment(record.CalendarDate).format("YYYY-MM-DD");
        let earlyDismissalrecord = [];
        if (earlyDismissalDateRange) {
          //console.log("earlyDismissalrecord records ", earlyDismissalDateRange)
          earlyDismissalrecord = earlyDismissalDateRange.filter(
            (item) => moment(item.Date).format("YYYY-MM-DD") === day
          );
        }
        //console.log(`bulding marked dates day ${day}  record.CalendarDate ${record.CalendarDate} `);
        if (record.PM_Name === GLOBAL_CONST.PICKMODE_NAME.EARLY_DEPARTURE) {
          record.isEarlyDepart = true;
          newMarkedDates[day] = getMarkStyle(record);
        } else if (earlyDismissalrecord.length > 0) {
          const edRecord = {
            ...earlyDismissalrecord[0],
            ...record,
          };

          //console.log('earlyDismissalrecord for ', earlyDismissalrecord[0]);
          edRecord.isEarlyDismissal = true;
          edRecord.IsApproved = earlyDismissalrecord[0].IsApproved;
          if (edRecord.DelegateImageUrl === undefined) {
            edRecord.DelegateImageUrl = null;
          }

          newMarkedDates[day] = getMarkStyle(edRecord);
        } else {
          record.isEarlyDismissal = false;
          newMarkedDates[day] = getMarkStyle(record);
          // console.log("pickup for " + day)
        }
      }

      this.setState(
        {
          markedDates: newMarkedDates,
        },
        () => {
          this.getSelectDateDayRecord();
          doGetPickupEntriesClear();
          doGetEarlyDismissalsForDateRangeClear();
        }
      );
    }

    if (absentReasonResponse != null) {
      const absentReasonResult = absentReasonResponse.AbsenceReasons;
      if (absentReasonResult != null && absentReasonResult != undefined) {
        absentReason.push({
          key: "Select",
          value: `${this.props.t("Select_Dismissal_Label")}`,
        });
        for (let i = 0; i < absentReasonResult.length; i++) {
          absentReason.push({
            key: `${absentReasonResult[i].Name}`,
            value: `${absentReasonResult[i].Name}`,
          });
        }
      }
    }

    if (error) {
      toast.error(error.Message || error.message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doSubmitAbsenteeClear();
    } else if (submitAbsenteeResponse) {
      toast.success(this.props.t("createMarkAbScr-Success"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      doSubmitAbsenteeClear();
      this.setState({ otp: "", password: "" }, () => {
        this.props.doHideModal();
        this.closeModal();
      });

      if (doCallbackRefresh) doCallbackRefresh();
    }
  }
  getRecurringDates = (startDate, endDate) => {
    let start = moment(startDate);
    let end = moment(endDate);
    let dates = [];

    for (var m = moment(start); m.isBefore(end); m.add(1, "days")) {
      dates.push(m.format("MM-D-YYYY"));
    }
    dates.push(end.format("MM-D-YYYY"));

    return dates;
  };

  validateForm = () => {
    const { selectedOption, selectedDate, selectedTillDate, isRepeat } =
      this.state;

    if (selectedDate == "Select Date") {
      alert(this.props.t("selectWhenScr-selectDate"));
      return false;
    } else if (
      selectedOption == undefined ||
      selectedOption == this.props.t("Select_Dismissal_Label")
    ) {
      alert(this.props.t("markAbsentScr-selectReason"));
      return false;
    } else if (isRepeat === true) {
      if (selectedTillDate == "Select Date") {
        alert(this.props.t("Select_Till_Date"));
        return false;
      }
    }

    return true;
  };

  submitForm = () => {
    const { doSubmitAbsentee, absentReasonResponse, selectedKids } = this.props;

    const {
      selectedOption,
      selectedDate,
      selectedTillDate,
      isRepeat,
      password,
      otp,
    } = this.state;

    let selectedkidArr = [];
    if (selectedKids) {
      selectedKids.filter((kid) => {
        selectedkidArr.push(kid.StudentID);
      });
    }

    if (isRepeat === false) {
      let selectFromDate = moment(selectedDate).format("MM/DD/YYYY");
      let params = {
        absenteeDate: [selectFromDate],
        reason: selectedOption,
        studentId: selectedkidArr,
        password,
      };
      if (absentReasonResponse.PasswordRequiredForChange === true) {
        params.password = password;
        params.OtpCode = otp;
      }
      doSubmitAbsentee(params);
    } else if (isRepeat === true) {
      let selectFromDate = moment(selectedDate).format("MM/DD/YYYY");
      let selectTillDate = moment(selectedTillDate).format("MM/DD/YYYY");
      let params = {
        absenteeDate: this.getRecurringDates(selectFromDate, selectTillDate),
        absenteetilldate: selectTillDate,
        reason: selectedOption,
        studentId: selectedkidArr,
      };
      if (absentReasonResponse.PasswordRequiredForChange === true) {
        params.password = password;
        params.OtpCode = otp;
      }
      doSubmitAbsentee(params);
    }
  };

  setRepeat(value) {
    return this.setState({
      isRepeat: !this.state.isRepeat,
    });
  }

  setSelectedDate = (day) => {
    const newDate = new Date(day.year, day.month - 1, day.day);
    this.setState({ selectedDate: newDate }, () => {
      this.getDayRecord("from");
    });
  };
  setSelectedTillDate = (day) => {
    const newDate = new Date(day.year, day.month - 1, day.day);
    this.setState({ selectedTillDate: newDate }, () => {
      this.getDayRecord("to");
    });
  };

  confirmPassword = () => {
    if (this.state.password === "") {
      alert(this.props.t("createMarkAbScr-passowrdError"));
      return false;
    }

    this.submitForm();
  };

  closeModal = () => {
    this.props.doHideModal();
    this.props.doSetOldHelpSetting();
  };

  render() {
    return this.renderMarkAbsentView();
  }
}
const CustomInput = React.forwardRef((props, ref) => {
  return (
    <input
      className="form-control"
      onClick={props.onClick}
      value={props.value}
      type="text"
      readOnly={true}
      ref={ref}
    />
  );
});
CustomInput.displayName = "CustomInput";

function mapStateToProps(state) {
  return {
    absentReasonResponse: state.school.absentReasonResponse,
    submitAbsenteeResponse: state.student.submitAbsenteeResponse,
    error: state.student.error,
    setHelpListData: state.userAccount.setHelpListData,
    isFetching: loadingSelector(state),

    loginType: state.userAccount.loginType,
    ssoUserGenerateOTPResponse: state.userAccount.ssoUserGenerateOTPResponse,
    userLoginInfo: state.userAccount.userLoginInfo,

    pickupEntries: state.student.pickupEntries,
    earlyDismissalDateRange: state.student.earlyDismissalDateRange,
    selectedTheme: state.userAccount.selectedTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doGetAbsentReason: (data) => dispatch(getAbsentReason(data)),
    doSubmitAbsentee: (data) => dispatch(submitAbsentee(data)),
    doSubmitAbsenteeClear: () => dispatch(submitAbsenteeClear()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doSetOldHelpSetting: () => dispatch(setOldHelpSetting()),
    doSsoUser_generateOTPForTransaction: (data) =>
      dispatch(ssoUser_generateOTPForTransaction(data)),
    doSsoUser_generateOTPForTransactionClear: () =>
      dispatch(ssoUser_generateOTPForTransactionClear()),

    doGetPickupEntries: (data) => dispatch(getPickupEntries(data)),
    doGetPickupEntriesClear: () => dispatch(getPickupEntriesClear()),

    doGetEarlyDismissalsForDateRange: (data) =>
      dispatch(getEarlyDismissalsForDateRange(data)),
    doGetEarlyDismissalsForDateRangeClear: (data) =>
      dispatch(getEarlyDismissalsForDateRangeClear(data)),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MultikidsCreateMarkAbsent)
);
