import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  showModal,
  hideModal,
  ssoUser_generateOTPForTransaction,
  ssoUser_generateOTPForTransactionClear,
} from "../../common/actions/userAccount";
import { createLoadingSelector } from "../../common/reducers/selectors";
import { isSSOLogin } from "../../helper/utility";
import OtpInput from "react-otp-input";
import RingLoader from "react-spinners/ClipLoader";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const loadingSelector = createLoadingSelector([
  "save_absentee",
  "early_dismissal",
  "sso_user_send_otp_transaction",
  "sso_user_send_otp_transaction",
]);

class PasswordOtpEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      otp: "",
      isShowOTPTxt: false,
    };
  }
  handleChange = (otp) => {
    this.setState({ otp, otpError: "" }, this.props.doCallbackSetOTP(otp));
    if (otp.length == 7) {
      setTimeout(() => {
        this.props.verifyOtp();
      }, 100);
    }
  };
  handlePassword = ({ target: { value: password } }) => {
    this.setState({ password }, this.props.doCallbackSetPassword(password));
  };

  render() {
    const { isShowOTPTxt } = this.state;
    const { isFetching } = this.props;

    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <form className="form form-style2">
              <div className="form-card-title">
                {!isSSOLogin()
                  ? this.props.t("Enter_Password")
                  : this.props.t("Verification")}
              </div>
              {isSSOLogin() && this.props.helpTxt && (
                <p className="helptext">{this.props.helpTxt}</p>
              )}

              {isSSOLogin() ? (
                <div className="otp-form-holder">
                  <div className="form-group phone-otp">
                    <a
                      onClick={() => {
                        this.props.doGenerateOtp();
                        this.setState({ isShowOTPTxt: true });
                      }}
                      className="btn needhelp-label mb-10"
                    >
                      {isShowOTPTxt
                        ? this.props.t("regConPhoneScr-resendCode")
                        : this.props.t("generate_OTP")}
                    </a>
                    <div className="form-group">
                      <div
                        className="sso-otp"
                        style={{
                          pointerEvents: isShowOTPTxt ? "auto" : "none",
                        }}
                      >
                        <OtpInput
                          inputType="tel"
                          inputStyle={`${
                            isShowOTPTxt
                              ? "inputStyle form-control input-sm"
                              : "inputStyle form-control input-sm otp-filled"
                          }`}
                          value={this.state.otp}
                          onChange={this.handleChange}
                          numInputs={7}
                          shouldAutoFocus={isShowOTPTxt ? true : false}
                          isInputNum
                          renderInput={(props) => <input {...props} />}
                        />
                        <span className="error" id="message">
                          {" "}
                          {this.props.otpError}
                        </span>
                      </div>
                    </div>

                    <div className="form-group btn-sect">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          this.props.verifyOtp();
                        }}
                      >
                        {isFetching ? (
                          <i className="fa-solid fa-arrows-rotate fa-spin"></i>
                        ) : null}{" "}
                        {this.props.t("verify")}
                      </button>
                      <button
                        className="btn btn-default red-cancel-btn"
                        onClick={() => {
                          this.closeModal();
                        }}
                      >
                        {this.props.t("regNameScr-cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="password-form-holder">
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      name="PasswordRequiredForChange"
                      onChange={this.handlePassword}
                      placeholder={this.props.t("loginScreen-password")}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") this.props.confirmPassword();
                      }}
                    />
                  </div>

                  <div className="form-group btn-sect passwordButtonSect">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={() => {
                        this.props.confirmPassword();
                      }}
                    >
                      {isFetching ? (
                        <i className="fa-solid fa-arrows-rotate fa-spin"></i>
                      ) : null}{" "}
                      {this.props.passowrdSavebuttonTxt}
                    </button>
                    <button
                      className="btn btn-default red-cancel-btn"
                      onClick={() => {
                        this.closeModal();
                      }}
                    >
                      {this.props.t("regNameScr-cancel")}
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate() {
    const {
      ssoUserGenerateOTPResponse,
      doSsoUser_generateOTPForTransactionClear,
    } = this.props;
    if (
      ssoUserGenerateOTPResponse &&
      ssoUserGenerateOTPResponse.Success === true
    ) {
      toast.success(this.props.t("otp_send"), {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doSsoUser_generateOTPForTransactionClear();
    }
  }

  closeModal = () => {
    this.props.doHideModal();
  };

  backToHelptList = () => {
    this.props.doHideModal();
  };
}

function mapStateToProps(state) {
  return {
    setHelpListData: state.userAccount.setHelpListData,
    ssoUserGenerateOTPResponse: state.userAccount.ssoUserGenerateOTPResponse,
    isFetching: loadingSelector(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    doSsoUser_generateOTPForTransaction: (data) =>
      dispatch(ssoUser_generateOTPForTransaction(data)),
    doSsoUser_generateOTPForTransactionClear: () =>
      dispatch(ssoUser_generateOTPForTransactionClear()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PasswordOtpEntry)
);
