import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        navigate={navigate}
        location={location}
        {...props}
        router={{ params }}
      />
    );
  }

  return ComponentWithRouterProp;
}
