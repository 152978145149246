import { getEnvironment, getAxios } from "./config";
import { validateResponse } from "../../helper/utility";

export const getStudents = async ({ currenDate }) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/getstudents?ParentLocalDate=${currenDate}`;
  const FullUrl = PostURL + param;
  // console.log(`getStudents API ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/getSchoolInfo
 * Purpose: Get School Details
 */
export const getSchoolInfo = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetSchoolInfo`;
  const FullUrl = PostURL + param;
  // console.log(`getSchoolInfo API ${FullUrl}`);
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/GetBusRoutes
 * Purpose: Get GetBusRoutes
 */
export const GetBusRoutes = async ({ studentID }) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetBusRoutes?StudentID=${studentID}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const getStudentAfterSchools = async ({ studentID }) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetStudentAfterSchools?StudentID=${studentID}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};
export const submitAnnounce = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/announce`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, params);
  return validateResponse(response);
};

export const getDelegationsToCurrentParent = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetDelegationsToCurrentParent`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    StudentID: params.studentId,
    StartDate: params.startDate,
    LastRecord: params.lastRecord,
    PageSize: params.pageSize,
  });
  return validateResponse(response);
};

export const postDefaultPickupModeChange = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/bulkstudent/PostDefaultPickupModeChange`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    StudentIDs: params.studentID,
    PickupModeID: params.pickupModeID,
    PickupModeSubItem: params.pickupModeSubItem,
  });
  return validateResponse(response);
};

export const cancelDelegation = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/CancelDelegation`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    calendarId: params.calendarId,
    CancelToDate: params.cancelToDate,
    isCancelAll: params.isCancelAll,
  });

  return validateResponse(response);
};

/**
 * API call to http://35.167.52.234/api/GetOtherChildren
 * Purpose: GetOtherChildren
 */
export const getOtherChildren = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetOtherChildren`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const getAbsentEntryForDateRange = async (params) => {
  const PostURL = `${getEnvironment()}student/GetAbsentEntryForDateRange`;

  const response = await getAxios().post(PostURL, {
    StudentID: params.studentID,
    StartDate: params.startDate,
    EndDate: params.endDate,
  });
  return validateResponse(response);
};

export const getCalendarEntryForDateRange = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetAbsentEntryForDateRange`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    StudentID: params.studentID,
    StartDate: params.startDate,
    EndDate: params.endDate,
  });
  return validateResponse(response);
};

export const saveAbsentee = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/bulkstudent/SaveAbsentee`;
  const FullUrl = PostURL + param;
  const body = {
    AbsenteeDates: params.absenteeDate,
    Reason: params.reason,
    StudentIds: params.studentId,
    Password: params.password,
    OtpCode: params.OtpCode,
  };

  //console.log(`saveAbsentee ${FullUrl} body `, body);

  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const getPickupEntriesForDateRange = async (params) => {
  const param = "";
  // const PostURL = `${getEnvironment()}v2/bulkstudent/GetPickupEntriesForDateRange`;
  const PostURL = `${getEnvironment()}student/GetPickupEntriesForDateRange`;
  const FullUrl = PostURL + param;

  //console.log(`getPickupEntriesForDateRange  called FullUrl ${FullUrl}`);
  const response = await getAxios().post(FullUrl, {
    // StudentIDs: params.studentID,
    StudentID: params.studentID,
    StartDate: params.startDate,
    EndDate: params.endDate,
  });
  // console.log(
  //   `getPickupEntriesForDateRange  called FullUrl completed ${FullUrl} body ${JSON.stringify(
  //     {
  //       // StudentIDs: params.studentID,
  //       StudentID: params.studentID,
  //       StartDate: params.startDate,
  //       EndDate: params.endDate,
  //     }
  //   )}`
  // );
  return validateResponse(response);
};

export const getAbsentAndCalendarEntriesForDateRange = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetAbsentAndCalendarEntriesForDateRange`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    StudentID: params.studentID,
    StartDate: params.startDate,
    EndDate: params.endDate,
  });
  return validateResponse(response);
};

export const postDelegationChange = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/bulkstudent/PostDelegationChange`;
  const FullUrl = PostURL + param;

  const body = {
    StudentIds: params.studentID,
    SchoolID: params.schoolID,
    DatesForChange: params.fromDate,
    isDelegated: params.isDelegated,
    isDelegatedToGuardian: params.isDelegatedToGuardian,
    guardianFirstName: params.guardianFirstName,
    guardianLastName: params.guardianLastName,
    guardianPhone: params.guardianPhone,
    institutionId: params.institutionId,
    pickUpModeId: params.pickUpModeId,
    busRouteId: params.busRouteId,
  };

  if (params.recurringTypeID) {
    body.RecurringTypeID = params.recurringTypeID;
  }

  // console.log(`postDelegationChange URL ${FullUrl} ${JSON.stringify(body)}`);
  const response = await getAxios().post(FullUrl, body);
  return validateResponse(response);
};

export const getDelegates = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}account/GetApprovedDelegates_Active?FetchAll=true`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const getDefaultDismissal = async ({ selectedKidsIds }) => {
  const param = "";
  // const PostURL = `${getEnvironment()}student/GetDefaultPickupMode?StudentID=${studentId}`;
  const PostURL = `${getEnvironment()}v2/bulkstudent/GetDefaultPickupMode`;
  const FullUrl = PostURL + param;
  const body = {
    StudentIds: selectedKidsIds,
  };
  // console.log(" getDefaultDismissal Full url with body --", FullUrl, body);
  const response = await getAxios().post(FullUrl, body);

  return validateResponse(response);
};

export const cancelAbsentee = async ({ studentId, absenteeDate }) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/CancelAbsentee`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    StudentID: studentId,
    AbsenteeDate: absenteeDate,
  });

  return validateResponse(response);
};

export const cancelDelegations = async ({ calendarId }) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/CancelDelegations`;
  const FullUrl = PostURL + param;

  // console.log("calendar", calendarId);

  const response = await getAxios().post(FullUrl, calendarId);
  return validateResponse(response);
};

export const getRelatedDelegations = async ({ calendarId }) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetRelatedDelegations`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    CalendarID: calendarId,
  });
  return validateResponse(response);
};
export const getAvailableDispatchMethods = async ({ studentIDs }) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetAvailableDispatchMethods`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    studentIDs,
  });
  return validateResponse(response);
};

export const getDismissalHistory = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/GetDismissalHistory`;

  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    StudentID: params.StudentID,
    LastDismissalID: params.DispatchID,
    PageSize: 100,
  });

  return validateResponse(response);
};
export const getPositionInLine = async ({ pickupModeId, studentId }) => {
  const param = "";
  const PostURL = `${getEnvironment()}school/GetPositionInLine`;
  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl, {
    PickupModeID: pickupModeId,
    StudentID: studentId,
  });

  return validateResponse(response);
};

export const getPreviousAndFuturePickups = async ({ currenDate }) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/getstudents?ParentLocalDate=${currenDate}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);
  return validateResponse(response);
};

// Submit Early Dismissal
export const submitEarlyDismissal = async (params) => {
  const param = "";
  const PostURL = `${getEnvironment()}v2/bulkstudent/SubmitEarlyDismissalRequest`;

  const FullUrl = PostURL + param;
  // console.log(
  //   ` Submit Early Dismissal Full URL with params  ${FullUrl} Param ${JSON.stringify(
  //     params
  //   )}`
  // );
  const response = await getAxios().post(FullUrl, params);

  return validateResponse(response);
};

// Cancel Early Dismissal
export const cancelEarlyDismissal = async (id) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/CancelEarlyDismissal?Id=${id}`;

  const FullUrl = PostURL + param;
  const response = await getAxios().post(FullUrl);

  return validateResponse(response);
};

// Date Range Early Dismissal
export const dateRangeEarlyDismissal = async (params) => {
  const PostURL = `${getEnvironment()}student/GetEarlyDismissalsForDateRange`;

  const FullUrl = PostURL;
  const response = await getAxios().post(FullUrl, params);

  return validateResponse(response);
};

//  Get back to school Info
export const getBackToSchoolInfo = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}student/getBackToSchoolInfo`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

//  save back to school Info
export const saveBackToSchoolInfo = async (params) => {
  const PostURL = `${getEnvironment()}student/saveBackToSchoolInfo`;
  const FullUrl = PostURL;
  const passData = {
    Students: [params],
  };
  const response = await getAxios().post(FullUrl, passData);
  return validateResponse(response);
};

//  Get Relationship type
export const getRelationshipType = async () => {
  const param = "";
  const PostURL = `${getEnvironment()}SystemData/RelationshipTypes`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

//  Get Fill back to school info
export const getFillbacktoschollinfo = async (studentId) => {
  const param = "";
  const PostURL = `${getEnvironment()}student/fillbacktoschoolinfo?studentId=${studentId}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);

  return validateResponse(response);
};

export const getSchoolDispatchMethods = async ({ schoolid }) => {
  const param = "";
  const PostURL = `${getEnvironment()}school/GetSchoolDispatchMethods?schoolid=${schoolid}`;
  const FullUrl = PostURL + param;
  const response = await getAxios().get(FullUrl);
  return validateResponse(response);
};
