import React from "react";

class Error extends React.Component {
  render() {
    return (
      <div>
        <h1>Oops! Page not found!</h1>
      </div>
    );
  }
}
export default Error;
