import React from "react";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "../../Styles/app.css";
import "./Styles/style.css";
import "./Styles/theme-responsive.css";
import { connect } from "react-redux";
import {
  saveRegistrationInfo,
  showModal,
  hideModal,
  setHelpList,
  nameUpdate,
  nameUpdateClear,
} from "../../../common/actions/userAccount";

import { nextField } from "../../../helper/utility";
import GLOBAL_CONST from "../../../helper/AppConstants";
import {
  RegisterFooter,
  RegisterHeader,
  SubmitButton,
  NewRegisterWizard,
  withRouter,
} from "../../CommonComponents";

import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import { withTranslation } from "react-i18next";
import { createLoadingSelector } from "../../../common/reducers/selectors";
import { Line } from "rc-progress";
import { ToastContainer } from "react-toastify";
const loadingSelector = createLoadingSelector(["register_name"]);

class RegisterName extends React.Component {
  constructor(props) {
    super(props);

    const {
      registeredFirstname,
      registeredLastname,
      ssoGoogleResponse,
      ssoType,
    } = this.props;

    this.state = {
      firstName: ssoType
        ? ssoGoogleResponse.ssoGoogleResponse.user.givenName
        : registeredFirstname
        ? registeredFirstname
        : "",
      lastName: ssoType
        ? ssoGoogleResponse.ssoGoogleResponse.user.familyName
        : registeredLastname
        ? registeredLastname
        : "",
      nameInvalid: "",
      lastNameInvalid: "",
    };

    this.dosetHelpList();
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Registration",
      screen: GLOBAL_CONST.SCREEN_NAMES.REGISTRATION_NAME,
      title: this.props.t("regCardScr-regAccount"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  handleFirstNameChange = ({ target: { value: firstName } }) =>
    this.setState({ firstName: firstName, nameInvalid: "" });

  handleLastNameChange = ({ target: { value: lastName } }) =>
    this.setState({ lastName: lastName, lastNameInvalid: "" });

  submitForm = () => {
    const { firstName, lastName } = this.state;
    const { doSaveRegistrationInfo } = this.props;

    if (firstName.trim() === "") {
      this.setState({ nameInvalid: this.props.t("First_Name_Alert") });
      return;
    }
    if (lastName.trim() === "") {
      this.setState({ lastNameInvalid: this.props.t("Last_Name_Alert") });
      return;
    }

    doSaveRegistrationInfo({
      registeredFirstname: firstName,
      registeredLastname: lastName,
    });

    this.props.doNameUpdate({
      firstName: firstName,
      lastName: lastName,
    });
  };

  componentDidUpdate() {
    const { updateNameResponse, doNameUpdateClear } = this.props;
    if (updateNameResponse && updateNameResponse.Success) {
      this.props.navigate("/newregisterPassword");
      doNameUpdateClear();
    }
  }

  render() {
    const { nameInvalid, firstName, lastName, lastNameInvalid } = this.state;
    const { isFetching } = this.props;

    return (
      <section className="registration accountexists-sect">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  <NewRegisterWizard
                    webPage={this.props.location.pathname}
                    isBackBtnVisible={true}
                  />
                  <div className="probressbar-holder">
                    <Line percent={84} strokeWidth="1" strokeColor="#7AC943" />
                  </div>
                  <div className="container-sect">
                    <form className="form-style1">
                      <div className="title-sect mb-40">
                        <h3 className="title-3">
                          {this.props.t("regNameScr-questName")}
                        </h3>
                        <p className="helptext">
                          {this.props.t("regNameScr-descript")}
                        </p>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${
                            nameInvalid !== "" ? "input-error" : null
                          }`}
                          placeholder={this.props.t("regNameScr-firstName")}
                          value={firstName}
                          onChange={this.handleFirstNameChange}
                          name="firstName"
                          maxLength={30}
                          onKeyDown={(event) => nextField(event)}
                        />
                        <span className="error" id="message">
                          {" "}
                          {nameInvalid}
                        </span>
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${
                            lastNameInvalid !== "" ? "input-error" : null
                          }`}
                          placeholder={this.props.t("regNameScr-lastName")}
                          value={lastName}
                          onChange={this.handleLastNameChange}
                          name="lastName"
                          maxLength={30}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") this.submitForm();
                          }}
                        />
                        <span className="error" id="message">
                          {" "}
                          {lastNameInvalid}
                        </span>
                      </div>

                      <div className="form-group mb-40">
                        <button
                          type="button"
                          className="btn needhelp-label"
                          onClick={() => {
                            this.openHelpModal();
                          }}
                        >
                          {this.props.t("regNameScr-needHelp")}
                        </button>
                      </div>
                      <SubmitButton
                        nextAction={this.submitForm}
                        loading={isFetching}
                      />
                    </form>
                  </div>
                  <ToastContainer />
                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    setHelpListData: state.userAccount.setHelpListData,
    isFetching: loadingSelector(state),
    registeredFirstname: state.userAccount.registeredFirstname,
    registeredLastname: state.userAccount.registeredLastname,
    ssoGoogleResponse: state.userAccount.ssoGoogleResponse,
    ssoType: state.userAccount.ssoType,
    updateNameResponse: state.userAccount.updateNameResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doSaveRegistrationInfo: (data) => dispatch(saveRegistrationInfo(data)),

    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doNameUpdate: (data) => dispatch(nameUpdate(data)),
    doNameUpdateClear: () => dispatch(nameUpdateClear()),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterName))
);
