import React from "react";
import GLOBAL_CONST from "../../helper/AppConstants";
import { connect } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";

import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import "../Settings/Themeselection.css";
import { hideModal, saveSelectedTheme } from "../../common/actions/userAccount";

import { useNavigate, useLocation } from "react-router-dom";
import { getCurrentThemeColor } from "../../helper/utility";

function withRouterHook(Component) {
  return function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component navigate={navigate} location={location} {...props} />;
  };
}

class ThemeSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_SelectedTheme: this.props.selectedTheme,
      isAutoSelected: this.props.isAutoTheme,
    };
  }

  saveTheme = (theme, isAuto) => {
    this.setState({
      user_SelectedTheme: theme,
      isAutoSelected: isAuto,
    });
    setTimeout(() => {
      this.props.doSaveSelectedTheme({
        selectedTheme: theme,
        isAuto,
      });
      document.body.style.backgroundColor = getCurrentThemeColor(theme).body
      this.closeModal();
      this.props.navigate("/childManagement");
    }, 100);
  };

  renderThemeSelectionView = () => {
    const { isAutoSelected } = this.state;
    const { selectedTheme } = this.props;

    return (
      <div className="themeselection-sect">
        <div className="theme-header">
          <div className="theme-wrap">
            <div className="theme-titlesect">
              <button
                type="button"
                className="back-btn btn"
                onClick={() => {
                  this.closeModal();
                }}
              >
                <i className="icon fas fa-arrow-left"></i>
              </button>
              <h3 className="theme-title">{this.props.t("ThemeSelection")}</h3>
            </div>
          </div>
        </div>
        <div className="themeselect-body">
          <div className="theme-wrap">
            <small>{this.props.t("settings_applies")}</small>
          </div>
          <div className="theme-cardbx">
            <ul className="theme-list">
              <li className="theme-item">
                <a
                  className="linkStyle"
                  onClick={() => {
                    this.saveTheme(GLOBAL_CONST.THEME.DARK, false);
                  }}
                >
                  {selectedTheme === GLOBAL_CONST.THEME.DARK &&
                  !isAutoSelected ? (
                    <i className="icon checked fa-solid fa-check"></i>
                  ) : (
                    <i className="icon"></i>
                  )}
                  <span className="text">{this.props.t("dark_Theme")}</span>
                </a>
              </li>
              <li className="theme-item">
                <a
                  className="linkStyle"
                  onClick={() => {
                    this.saveTheme(GLOBAL_CONST.THEME.LIGHT, false);
                  }}
                >
                  {selectedTheme === GLOBAL_CONST.THEME.LIGHT &&
                  !isAutoSelected ? (
                    <i className="icon checked fa-solid fa-check"></i>
                  ) : (
                    <i className="icon"></i>
                  )}
                  <span className="text">{this.props.t("light_Theme")}</span>
                </a>
              </li>

              <li className="theme-item">
                <a
                  className="linkStyle"
                  onClick={() => {
                    if (
                      window.matchMedia &&
                      window.matchMedia("(prefers-color-scheme: dark)").matches
                    ) {
                      this.saveTheme(GLOBAL_CONST.THEME.DARK, true);
                    } else {
                      this.saveTheme(GLOBAL_CONST.THEME.LIGHT, true);
                    }
                  }}
                >
                  {isAutoSelected ? (
                    <i className="icon checked fa-solid fa-check"></i>
                  ) : (
                    <i className="icon"></i>
                  )}
                  <span className="text">{this.props.t("device_theme")}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
  closeModal = () => {
    this.props.doHideModal();
  };

  render() {
    return this.renderThemeSelectionView();
  }
}
function mapStateToProps(state) {
  return {
    selectedTheme: state.userAccount.selectedTheme,
    isAutoTheme: state.userAccount.isAutoTheme,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doHideModal: () => dispatch(hideModal()),
    doSaveSelectedTheme: (data) => dispatch(saveSelectedTheme(data)),
  };
}

export default withTranslation()(
  withRouterHook(connect(mapStateToProps, mapDispatchToProps)(ThemeSelection))
);
