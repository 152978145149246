import React, { useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getRelationship } from "../../common/actions/students";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import { createLoadingSelector } from "../../common/reducers/selectors";
import RingLoader from "react-spinners/ClipLoader";

const loadingSelector = createLoadingSelector(["get_relationship"]);

class SelectRelationship extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      records: [],
      allRecords: [],
      selectedRelationTypeData: {},
      selectedRelationshipOption: this.props.selectedRelationshipOption
        ? this.props.selectedRelationshipOption
        : "",
      serchedvalue: "",
    };

    this.CustomMenu = React.forwardRef(function CustomMenu(
      { children, style, className, "aria-labelledby": labeledBy },
      ref
    ) {
      const [value, setValue] = React.useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <div className="input-group searchbox">
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search relation..."
              name="serchedvalue"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />

            <i className="fas fa-search input-icon"></i>
          </div>

          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value ||
                child.props.children.props.children
                  .toLowerCase()
                  .startsWith(value)
            )}
          </ul>
        </div>
      );
    });
    this.setValue = ({ target: { value: serchedvalue } }) => {
      this.setState({ serchedvalue });
    };
  }

  componentDidMount() {
    const { doGetRelationship } = this.props;
    doGetRelationship();
  }
  componentWillUnmount() {
    this.setState = {
      searchText: "",
      records: [],
      allRecords: [],
      serchedvalue: "",
      showDropDown: false,
    };
  }
  componentDidUpdate() {
    const { relationshipList } = this.props;
    const { allRecords } = this.state;
    if (
      allRecords.length === 0 &&
      relationshipList !== null &&
      relationshipList !== undefined &&
      relationshipList.length > 0
    ) {
      this.setState({
        allRecords: relationshipList,
        records: relationshipList,
      });
    }
  }

  searchAction = (text) => {
    const { allRecords } = this.state;
    const newData = allRecords.filter(function (item) {
      const itemData = item.Name ? item.Name.toUpperCase() : "".toUpperCase();
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({ records: newData, searchText: text });
  };
  addNewRelationship = () => {
    const { searchText, records } = this.state;
    const { StudentID, doCallback } = this.props;
    const selectedRelationData = {};
    selectedRelationData["Id"] = StudentID;
    selectedRelationData["StudentId"] = StudentID;
    selectedRelationData["Relationship"] = searchText;
    selectedRelationData["RelationshipTypeId"] = null;
    selectedRelationData["RelationshipTypeOther"] = searchText;
    selectedRelationData["Name"] = searchText;
    this.setState({
      selectedRelationshipOption: searchText,
      selectedRelationTypeData: selectedRelationData,
    });
    this.setState({ showDropDown: false });
    if (doCallback) doCallback(selectedRelationData);
  };
  setSelectedRelationData = (item) => {
    const { StudentID, doCallback } = this.props;
    const selectedRelationData = {};
    selectedRelationData["Id"] = StudentID;
    selectedRelationData["StudentId"] = StudentID;
    selectedRelationData["RelationshipTypeId"] = item.Id;
    selectedRelationData["RelationshipTypeOther"] = null;
    selectedRelationData["Relationship"] = item.Name;
    selectedRelationData["Name"] = item.Name;

    this.setState({
      selectedRelationshipOption: item.Id,
      selectedRelationTypeData: selectedRelationData,
    });
    this.setState({ showDropDown: false });
    if (doCallback) doCallback(selectedRelationData);
  };
  toggleDropdown = () => {
    const { showDropDown } = this.state;
    this.setState({ showDropDown: !showDropDown });
  };
  getRelationNamebyId = (value) => {
    const { records } = this.state;
    for (let i = 0; i < records.length; i++) {
      if (
        parseInt(value) === parseInt(records[i].Id) ||
        value == records[i].Name
      ) {
        return records[i];
      }
    }
    return {
      Name: value,
    };
  };

  render() {
    const { isFetching, studentName, stackType, selectedRelationshipName } =
      this.props;
    const { searchText, records, selectedRelationshipOption, showDropDown } =
      this.state;
    let strDisplay = "";

    if (selectedRelationshipName) {
      strDisplay = studentName + "'s " + selectedRelationshipName;
    } else if (selectedRelationshipOption)
      strDisplay =
        studentName +
        "'s " +
        this.getRelationNamebyId(selectedRelationshipOption).Name;
    else if (stackType === "ChildManagement") {
      strDisplay = this.props.t("Select_Dismissal_Label");
    } else strDisplay = studentName;

    return (
      <div className="relationshipwith-view">
        <div
          title="Select"
          className={
            showDropDown
              ? "custom-dropdown dropdown show"
              : "custom-dropdown dropdown"
          }
        >
          <button
            type="button"
            className="dropdown-toggle btn btn-primary"
            onClick={() => {
              this.toggleDropdown();
            }}
          >
            {strDisplay}
            {records.length === 0 && isFetching && (
              <RingLoader color="#2d3d56" loading={true} size={20} />
            )}
          </button>
          <div
            className={showDropDown ? "dropdown-menu show" : "dropdown-menu"}
          >
            <div className="input-group searchbox">
              <input
                type="text"
                className="form-control search-input"
                name="searchText"
                placeholder={this.props.t("SEARCH")}
                onChange={(event) => {
                  this.searchAction(event.target.value);
                }}
                value={searchText}
                maxLength={30}
              />

              {records.length == 0 ? (
                <button
                  type="button"
                  className="btn addrelation-btn input-icon"
                  onClick={(event) => this.addNewRelationship()}
                >
                  {" "}
                  <i className="fas fa-plus-circle"></i>
                  <span className="custom-tooltip">
                    <em className="tooltip-text">{this.props.t("Add")}</em>
                  </span>
                </button>
              ) : (
                <i className="fas fa-search input-icon"></i>
              )}
            </div>
            <ul className="list-unstyled">
              {records.map((data) => {
                return (
                  <a
                    key={data.Id}
                    className={
                      selectedRelationshipName === data.Name ||
                      parseInt(selectedRelationshipOption) === parseInt(data.Id)
                        ? "active dropdown-item"
                        : "dropdown-item"
                    }
                    role="button"
                    onClick={() => {
                      this.setSelectedRelationData(data);
                    }}
                  >
                    <span className="dropdownitem-label">{data.Name}</span>
                  </a>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoginInfo: state.userAccount.userLoginInfo,
    error: state.userAccount.error,
    relationshipList: state.student.relationshipList,
    isFetching: loadingSelector(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doGetRelationship: () => dispatch(getRelationship()),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SelectRelationship)
);
