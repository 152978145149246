
import React from "react";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "./components/CommonComponents";
class AppleLoginHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section className="pmk-child-schedule noKidsSect">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 text-center">
              <div className="noKidsLayout">
                <div className="noKidsDetails">
                  <h2 className="title">{"Apple Login Handler"}</h2>
                  <div className="nokids-content">
                  </div>
                  <div className="btn-sect">
                   

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {

  };
}
function mapDispatchToProps(dispatch) {
  return {
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AppleLoginHandler))
);

