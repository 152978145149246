import React from "react";
import _ from "lodash";
import "./../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/app.css";
import "./Styles/style.css";
import "./Styles/theme-responsive.css";
import { connect } from "react-redux";

import {
  RegisterFooter,
  RegisterHeader,
  NewRegisterWizard,
  SubmitButton,
  withRouter,
} from "../../CommonComponents";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import {
  saveRegistrationInfo,
  setEnvironment,
  showModal,
  hideModal,
  setHelpList,
  setUserEmail,
  setUserEmailClear,
  logoutUser,
  completeRegisterUser,
  completeRegisterUserClear,
  saveLoginCredentialsClear,
  setAppleLoginCredentialsClear,
  saveLoginCredentials,
} from "../../../common/actions/userAccount";
import {
  checkUserEmail,
  checkUserEmailClear,
  setAppleSSOEmail,
  setAppleSSOEmailClear,
  setGoogleSSOEmail,
  setGoogleSSOEmailClear,
} from "../../../common/actions/userAccount/RegistrationAction";

import {
  markReadToDashboard,
  nameUpdate,
  nameUpdateClear,
  sendUserWelcomeEmail,
} from "../../../common/actions/userAccount/userAccountAction";
import GLOBAL_CONST from "../../../helper/AppConstants";
import { createPassword, getLanguageCode, getAppleRedirectURL } from "../../../helper/utility";

import { createLoadingSelector } from "../../../common/reducers/selectors";
import { withTranslation } from "react-i18next";
import { Line } from "rc-progress";
import { Slide, toast, ToastContainer } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";

import AppleLogin from "react-apple-login";
import "react-toastify/dist/ReactToastify.css";

import { jwtDecode } from "jwt-decode";

const loadingSelector = createLoadingSelector([
  "set_email",
  "get_user_profile",
  "set_apple_sso",
  "set_google_sso",
  "complete_registration_user",
  "update_name",
  "check_email",
]);

class RegisterEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userEmail: "",
      emailInvalid: "",
      waitForVerifyEmail: false,
    };

    this.dosetHelpList();
  }

  componentDidMount() { }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Registration",
      screen: GLOBAL_CONST.SCREEN_NAMES.REGISTRATION_EMAIL,
      title: this.props.t("regCardScr-regAccount"),
    });
  };
  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  async saveCredentials(password) {
    try {
      const { registeredPhone, registeredCountryCode } = this.props;
      // console.log(
      //   `saveCredentials countryCode ${registeredCountryCode} registeredPhone ${registeredPhone} password ${password}`
      // );

      let credentials = {
        registeredPhone,
        registeredCountryCode,
        password,
      };
      this.props.doSaveLoginCredentials({
        loginCredentials: credentials,
      });
    } catch (err) {
      //console.log("saveCredentials ", err);
    }
  }

  handleEmailChange = ({ target: { value: userEmail } }) =>
    this.setState({ userEmail, emailInvalid: "" });

  submitForm = () => {
    const { doSetUserEmail, userLoginInfo, doSaveRegistrationInfo } =
      this.props;
    const { userEmail } = this.state;
    var reg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isValid = reg.test(userEmail);
    if (reg.test(userEmail) === false) {
      this.setState({ emailInvalid: this.props.t("regAcctScr-emailError") });
      return;
    }

    if (userEmail.endsWith(".con")) {
      this.setState({ emailInvalid: this.props.t("regAcctScr-emailError") });
      return;
    }

    if (userEmail !== "" && userEmail !== undefined) {
      let email = "";
      email = userEmail.replace(".staging", "");
      email = userEmail.replace(".develop", "");
      email = userEmail.replace(".localhost", "");

      if (userLoginInfo && email === userLoginInfo.Email) {
        toast.error("You are already registered with this email address", {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        return;
      }
      if (!userLoginInfo || !userLoginInfo.userId) {
        this.props.doCheckEmail({
          Email: email,
          locale: getLanguageCode(),
          callback: (response) => {
            if (response.Success) {
              const generatedPassword = createPassword();
              this.saveCredentials(generatedPassword);
              doSaveRegistrationInfo({ registeredPassword: generatedPassword });
              this.props.doCompleteRegisterUser({
                Password: generatedPassword,
                Firstname: " ",
                Lastname: " ",
                callback: (res) => {
                  if (
                    res &&
                    res.LoggedInParentInfo &&
                    res.LoggedInParentInfo.AuthTokenID
                  ) {
                    doSetUserEmail({
                      Email: email,
                    });
                  }
                },
              });
            } else {
              toast.error(response.Message, {
                autoClose: 2000,
                transition: Slide,
                hideProgressBar: true,
                icon: false,
              });
            }
          },
        });
      } else {
        doSetUserEmail({
          Email: email,
        });
      }
    } else {
      this.setState({ emailInvalid: this.props.t("Email_Alert") });
    }
  };

  SSOProcess = () => {
    // If no kids then take user to onboarding else on app dashboard.
    const {
      getUserInfoResponse,
      doMarkReadToDashboard,
      route,
      userLoginInfo,
      registeredFirstname,
      registeredLastname,
      doSendWelcomeEmail,
    } = this.props;
    // console.log('SSOProcess userLoginInfo ', userLoginInfo);
    if (
      userLoginInfo &&
      (!userLoginInfo.firstName || userLoginInfo.firstName.trim() === "") &&
      registeredFirstname &&
      registeredFirstname !== ""
    ) {
      this.props.doNameUpdate({
        firstName: registeredFirstname,
        lastName: registeredLastname,
      });
      doSendWelcomeEmail();
    }

    let screenFrom = null;
    const { location } = this.props;
    if (location && location.state) {
      screenFrom = location.state.screenFrom;
    }

    if (screenFrom === "account") {
      this.props.navigate(-1);
      setTimeout(() => {
        this.openViewProfileModal();
      }, 200);

      return;
    }
    if (
      getUserInfoResponse &&
      getUserInfoResponse.Data &&
      (getUserInfoResponse.Data.KidsAtSchools > 0 ||
        getUserInfoResponse.Data.KidsDelegatedCurrently)
    ) {
      this.props.navigate("/childManagement");
    } else {
      this.props.navigate("/newchooseRole", {
        state: { screenFrom: "registration_chooserole" },
      });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      setEmailResponse,
      doSetUserEmailClear,
      doSaveRegistrationInfo,
      appleSSOResponse,
      doSetAppleSSOEmailClear,
      googleSSOResponse,
      doSetGoogleSSOEmailClear,
      completeRegisterUserResponse,
      docompleteRegisterUserClear,
    } = this.props;
    const { userEmail } = this.state;

    if (completeRegisterUserResponse) {
      docompleteRegisterUserClear();
    }

    if (setEmailResponse && setEmailResponse.Success === false) {
      toast.error(setEmailResponse.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      doSetUserEmailClear();
    } else if (setEmailResponse && setEmailResponse.Success === true) {
      toast.success(setEmailResponse.Message, {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });
      let email = "";
      email = userEmail.replace(".staging", "");
      email = userEmail.replace(".develop", "");
      email = userEmail.replace(".localhost", "");
      doSaveRegistrationInfo({ registeredEmail: email });
      let screenFrom = null;
      const { location } = this.props;
      if (location && location.state) {
        screenFrom = location.state.screenFrom;
      }

      this.props.navigate("/newconfirmEmail", {
        state: { userEmail, screenFrom },
      });
      doSetUserEmailClear();
    }
    if (appleSSOResponse) {
      if (appleSSOResponse.Success === false) {
        toast.error(appleSSOResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        doSetAppleSSOEmailClear();
      } else {
        toast.success(appleSSOResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });
        this.SSOProcess();
        doSetAppleSSOEmailClear();
      }
    }
    if (googleSSOResponse) {
      if (googleSSOResponse.Success === false) {
        toast.error(googleSSOResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        doSetGoogleSSOEmailClear();
      } else {
        toast.success(googleSSOResponse.Message, {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        this.SSOProcess();
        doSetGoogleSSOEmailClear();
      }
    }
  }

  saveSSOUserName = (givenName, familyName) => {
    const { userLoginInfo, doSaveRegistrationInfo } = this.props;
    if (
      givenName &&
      familyName &&
      userLoginInfo &&
      (!userLoginInfo.firstName || userLoginInfo.firstName.trim() === "")
    ) {
      doSaveRegistrationInfo({
        registeredFirstname: givenName,
        registeredLastname: familyName,
      });
    }
  };
  processGoogle = (userInfo, idToken) => {
    const { userLoginInfo } = this.props;

    // console.log('userInfo ', userInfo);
    // console.log('userLoginInfo ', userLoginInfo);
    if (!userInfo.email) {
      alert(
        "Google Account",
        "Your Google account does not have an email address. Please try using another email address."
      );
      return;
    }
    try {
      if (
        userInfo &&
        userInfo.email &&
        userLoginInfo &&
        userInfo.email === userLoginInfo.Email
      ) {
        toast.error("You are already registered with this email address", {
          autoClose: 2000,
          transition: Slide,
          hideProgressBar: true,
          icon: false,
        });

        return;
      }
    } catch (ex) {
      console.log(ex);
    }
    if (!userLoginInfo || !userLoginInfo.userId) {
      this.props.doCheckEmail({
        Email: userInfo.email,
        locale: getLanguageCode(),
        callback: (cresponse) => {
          if (cresponse.Success) {
            const generatedPassword = createPassword();
            this.props.doCompleteRegisterUser({
              Password: generatedPassword,
              Firstname:
                userInfo && userInfo.given_name ? userInfo.given_name : " ",
              Lastname:
                userInfo && userInfo.family_name ? userInfo.family_name : " ",
              callback: (response) => {
                if (
                  response &&
                  response.LoggedInParentInfo &&
                  response.LoggedInParentInfo.AuthTokenID
                ) {
                  this.saveSSOUserName(
                    userInfo.given_name,
                    userInfo.family_name
                  );

                  this.props.doSetGoogleSSOEmail({
                    idToken: idToken,
                  });
                }
              },
            });
          } else {
            toast.error(cresponse.Message, {
              autoClose: 2000,
              transition: Slide,
              hideProgressBar: true,
              icon: false,
            });
          }
        },
      });
    } else {
      this.props.doSetGoogleSSOEmail({
        idToken: idToken,
      });
    }
  };

  openViewProfileModal = () => {
    this.props.doShowModal({
      modalScreenName: "ViewProfile",
      modalClassName: "viewProfileModal sidebar-modal  right",
    });
  };

  render() {
    const { isFetching, userLoginInfo } = this.props;

    const { userEmail, waitForVerifyEmail, emailInvalid } = this.state;
    let screenFrom = null;
    const { location } = this.props;
    if (location && location.state) {
      screenFrom = location.state.screenFrom;
    }
    let screenWidth = window.innerWidth;
    const redirectURL = getAppleRedirectURL(window)
    return (
      <section className="registration accountexists-sect">
        <div className="wrapper">
          <div className="landing-wrapper">
            <div className="row h-100vh nomargin-row">
              <RegisterHeader />
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 sidebar-col">
                <div className="sidebar-wrapper">
                  <div className="probressbar-holder">
                    <Line percent={56} strokeWidth="1" strokeColor="#7AC943" />
                  </div>
                  <div className="container-sect mt-40">
                    <form
                      className="form-style1"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {screenFrom !== "account" && (
                        <div className="title-sect mb-40">
                          <h4 className="title-5">
                            {this.props.t("more_steps")}
                          </h4>
                          <h3 className="title-3">
                            {userLoginInfo
                              ? userLoginInfo.firstName +
                              " " +
                              userLoginInfo.lastName
                              : ""}
                          </h3>
                        </div>
                      )}
                      {screenFrom === "account" && (
                        <div className="help-text mb-40">
                          {this.props.t("updateEmailText")}
                        </div>
                      )}
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="text"
                            style={{
                              borderColor: waitForVerifyEmail
                                ? "#7ac943"
                                : "#37383b",
                            }}
                            className={`form-control ${emailInvalid !== "" ? "input-error" : ""
                              }`}
                            placeholder={this.props.t("regAcctScr-emailAddrs")}
                            value={userEmail}
                            onChange={this.handleEmailChange}
                            name="email"
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                this.submitForm();
                              }
                            }}
                          />
                          {waitForVerifyEmail ? (
                            <i className="fas fa-check-circle validemail-icon"></i>
                          ) : null}
                        </div>

                        <span className="error" id="message">
                          {" "}
                          {emailInvalid}
                        </span>
                      </div>

                      <div className="form-group mb-40">
                        <button
                          type="button"
                          className="btn needhelp-label"
                          onClick={() => {
                            this.openHelpModal();
                          }}
                        >
                          {this.props.t("regNameScr-needHelp")}
                        </button>
                      </div>

                      <div className="text-center">
                        <SubmitButton
                          nextAction={this.submitForm}
                          loading={isFetching}
                        />
                      </div>
                      <div className="form-group mb-40 text-center">
                        <div className="title-border">
                          <span>{this.props.t("orcontinuewith")}</span>
                        </div>
                        <div className="ssoButtonsWrap">
                          <div className="google-sect">
                            <GoogleLogin
                              useOneTap
                              theme={"outline"}
                              type={"standard"}
                              text={this.props.t("continueGoogle")}
                              width={
                                parseInt(screenWidth) >= 1050 ? "" : "200"
                              }
                              onSuccess={async (credentialResponse) => {
                                if (
                                  credentialResponse &&
                                  credentialResponse.credential != null
                                ) {
                                  const idToken = credentialResponse.credential;
                                  const userInfo = jwtDecode(
                                    credentialResponse.credential
                                  );
                                  this.processGoogle(userInfo, idToken);
                                }
                              }}
                              onError={() => {
                                console.log("Login Failed");
                              }}
                            />
                          </div>
                          <div className="applebtn-sect">
                            <AppleLogin
                              clientId={GLOBAL_CONST.APPLE_SSO_CONFIG.clientId}
                              redirectURI={
                                redirectURL
                              }
                              usePopup={true}
                              callback={this.appleResponse}
                              scope="email"
                              responseMode="query"
                              height={30}
                              render={(renderProps) => (
                                <button
                                  onClick={renderProps.onClick}
                                  className="applelogin-btn"
                                >
                                  <i className="fab fa-apple apple-icon"></i>
                                  <span>{this.props.t("continueApple")}</span>
                                </button>
                              )}
                            />
                          </div>
                        </div>

                        <div className="bottom-sect text-center">
                          <button
                            className="btn close-button"
                            type="button"
                            onClick={() => {
                              this.props.navigate(-1);
                            }}
                          >
                            {this.props.t("goback")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <ToastContainer /> */}
                  <RegisterFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  appleResponse = (response) => {
    if (!response.error) {
      if (response != null) {
        this.processAppleLogin(response);
      } else {
        this.props.doSetAppleLoginCredentialsClear();
      }
    } else {
      this.props.doSetAppleLoginCredentialsClear();
    }
  };

  processAppleLogin = (response) => {
    const { userLoginInfo } = this.props;
    const decoded = jwtDecode(response.authorization.id_token);
    if (!decoded.email) {
      alert(
        "Apple Account",
        "Your Apple account does not have an email address. Please try using another email account."
      );
      return;
    }

    if (
      decoded &&
      decoded.email &&
      userLoginInfo &&
      userLoginInfo &&
      decoded.email === userLoginInfo.Email
    ) {
      toast.error("You are already registered with this email address", {
        autoClose: 2000,
        transition: Slide,
        hideProgressBar: true,
        icon: false,
      });

      return;
    }

    if (!userLoginInfo || !userLoginInfo.userId) {
      this.props.doCheckEmail({
        Email: decoded.email,
        locale: getLanguageCode(),
        callback: (cresponse) => {
          if (cresponse.Success) {
            const generatedPassword = createPassword();
            const fullName =
              response && response.user && response.user.name
                ? response.user.name
                : null;

            this.props.doCompleteRegisterUser({
              Password: generatedPassword,
              Firstname:
                fullName && fullName.firstName ? fullName.firstName : "",
              Lastname: fullName && fullName.lastName ? fullName.lastName : "",
              callback: (res) => {
                if (
                  res &&
                  res.LoggedInParentInfo &&
                  res.LoggedInParentInfo.AuthTokenID
                ) {
                  if (response && response.user && response.user.name) {
                    this.saveSSOUserName(
                      response.user.name.firstName,
                      response.user.name.lastName
                    );
                  } else {
                    this.props.doSaveRegistrationInfo({
                      registeredFirstname: "",
                      registeredLastname: "",
                    });
                  }

                  this.props.doSetAppleSSOEmail({
                    identityToken: response.authorization.id_token,
                  });
                }
              },
            });
          } else {
            toast.error(cresponse.Message, {
              autoClose: 2000,
              transition: Slide,
              hideProgressBar: true,
              icon: false,
            });
          }
        },
      });
    } else {
      this.props.doSetAppleSSOEmail({
        identityToken: response.authorization.id_token,
      });
    }
  };
}

function mapStateToProps(state) {
  return {
    isFetching: loadingSelector(state),
    error: state.userAccount.error,
    setHelpListData: state.userAccount.setHelpListData,

    setEmailResponse: state.userAccount.setEmailResponse,
    userLoginInfo: state.userAccount.userLoginInfo,
    registeredEmail: state.userAccount.registeredEmail,
    getUserInfoResponse: state.userAccount.getUserInfoResponse,
    appleSSOResponse: state.userAccount.appleSSOResponse,
    googleSSOResponse: state.userAccount.googleSSOResponse,
    selectedTheme: state.userAccount.selectedTheme,
    appleLoginUsedCount: state.userAccount.appleLoginUsedCount,
    completeRegisterUserResponse:
      state.userAccount.completeRegisterUserResponse,
    registeredFirstname: state.userAccount.registeredFirstname,
    registeredLastname: state.userAccount.registeredLastname,
    registeredPassword: state.userAccount.registeredPassword,
    registeredPhone: state.userAccount.registeredPhone,
    registeredCountryCode: state.userAccount.registeredCountryCode,
    registeredCountryName: state.userAccount.registeredCountryName,
    checkEmailResponse: state.userAccount.checkEmailResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doSetEnvironment: (data) => dispatch(setEnvironment(data)),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),

    doSetUserEmail: (data) => dispatch(setUserEmail(data)),
    doSetUserEmailClear: () => dispatch(setUserEmailClear()),
    doSaveRegistrationInfo: (data) => dispatch(saveRegistrationInfo(data)),
    doMarkReadToDashboard: () => dispatch(markReadToDashboard()),
    doSetAppleSSOEmail: (data) => dispatch(setAppleSSOEmail(data)),
    doSetAppleSSOEmailClear: () => dispatch(setAppleSSOEmailClear()),
    doLogout: (data) => dispatch(logoutUser(data)),
    doSetGoogleSSOEmail: (data) => dispatch(setGoogleSSOEmail(data)),
    doSetGoogleSSOEmailClear: () => dispatch(setGoogleSSOEmailClear()),
    doCompleteRegisterUser: (data) => dispatch(completeRegisterUser(data)),
    docompleteRegisterUserClear: () => dispatch(completeRegisterUserClear()),
    doNameUpdate: (data) => dispatch(nameUpdate(data)),
    doNameUpdateClear: () => dispatch(nameUpdateClear()),
    doCheckEmail: (data) => dispatch(checkUserEmail(data)),
    doCheckEmailClear: () => dispatch(checkUserEmailClear()),
    doSetAppleLoginCredentialsClear: () =>
      dispatch(setAppleLoginCredentialsClear()),
    doSaveLoginCredentials: (data) => dispatch(saveLoginCredentials(data)),
    doSendWelcomeEmail: () => dispatch(sendUserWelcomeEmail()),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterEmail))
);
