import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  showModal,
  hideModal,
  setHelpList,
} from "../../common/actions/userAccount";
import GLOBAL_CONST from "../../helper/AppConstants";
import {
  getSchoolCalendar,
  getSchoolCalendarEventType,
  getSchoolCalendarClear,
} from "../../common/actions/school";
import { setCalendarViewClear } from "../../common/actions/students";
import { createLoadingSelector } from "../../common/reducers/selectors";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const loadingSelector = createLoadingSelector(["get_school_pickup_time"]);

class EventDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.dosetHelpList();
  }

  dosetHelpList = () => {
    this.props.dosetHelpList({
      stackType: "Dashboard",
      screen: GLOBAL_CONST.SCREEN_NAMES.SCHOOL_DETAILS,
      title: this.props.t("schoolDetailsScr-schoolDetail"),
    });
  };

  setDismissalsTimesHelp = () => {
    this.props.dosetHelpList({
      stackType: "Dashboard",
      screen: GLOBAL_CONST.SCREEN_NAMES.SCHOOL_DISMISSAL_TIME,
      title: this.props.t("dismissalTimeScr-schoolTime"),
    });
    setTimeout(this.openHelpModal, 200);
  };

  openHelpModal = () => {
    this.props.doShowModal({
      modalScreenName: "HelpListing",
      modalClassName: "helpSupportModal sidebar-modal right",
      params: {
        stackType: this.props.setHelpListData.stackType,
        screen: this.props.setHelpListData.screen,
        title: this.props.setHelpListData.title,
      },
    });
  };

  changeMonth = (value) => {
    this.calendar.addMonth(value);
    const { currentSelectedMonth } = this.state;
    const currMonth = moment(currentSelectedMonth).add(value, "M");
    this.setState(
      {
        currentSelectedMonth: currMonth,
        selectedDate: currMonth.toDate(),
      },
      this.filterCurrentMonth
    );
  };
  filterCurrentMonth = () => {
    const { currentSelectedMonth } = this.state;
    const currentMonth = moment(currentSelectedMonth).format("MM YYYY");
    let schoolCalendarRecords = [...this.state.schoolCalendarRecords];
    schoolCalendarRecords = schoolCalendarRecords.filter((item) => {
      return currentMonth === moment(item.Date).format("MM YYYY");
    });
    this.setState({
      currentMonthRecords: schoolCalendarRecords,
    });
  };

  renderEventType = (eventTypes) => {
    let eventView = [];
    if (eventTypes) {
      for (let i = 0; i < eventTypes.length; i += 1) {
        eventView.push(
          <div>
            <i className="fas fa-circle"></i>
            <p>{eventTypes[i].Name}</p>
          </div>
        );
      }
    }
    return eventView;
  };
  renderHeader = () => {
    return <p className="currentmonth">{"August"}</p>;
  };

  onDayPress = (day) => {
    const { markdCalendar } = this.state;
    const selectedDay = moment(day).format("YYYY-MM-DD");
    if (markdCalendar[selectedDay]) {
      const record = markdCalendar[selectedDay].record;
      const eventName = record.Name;
      this.showDayDetail(eventName);
    }
  };

  showDayDetail = (eventName) => {
    toast.info(eventName, {
      autoClose: 2000,
      transition: Slide,
      hideProgressBar: true,
    });
  };

  buildCalendarRecordView = (schoolCalendarRecord) => {
    return (
      <div className="schedule-list">
        <div
          className="schoolname-header dismissal-schedule-header"
          onClick={() => {
            this.openEventDetails(schoolCalendarRecord);
          }}
        >
          <div className="icon-title">
            <i
              className="fas fa-calendar-day schedular-icon"
              style={{ backgroundColor: schoolCalendarRecord.EventColor }}
            ></i>

            <div className="title-info">
              <h3 className="school-title gray-title">
                {moment(schoolCalendarRecord.Date).format("ddd DD MMM YYYY")}
              </h3>
              <div className="blue-text">{schoolCalendarRecord.Name}</div>
              <div className="helptext description">
                {schoolCalendarRecord.Description}
              </div>
            </div>
          </div>
          <i className="fas fa-chevron-right"></i>
        </div>
      </div>
    );
  };
  renderSchoolCalendar = () => {
    const { schoolCalendarRecords, currentMonthRecords } = this.state;
    const { calendarView } = this.props;
    let schoolCalendarView = [];
    let records = calendarView ? currentMonthRecords : schoolCalendarRecords;
    if (records) {
      records.forEach((element) => {
        schoolCalendarView.push(this.buildCalendarRecordView(element));
      });
    }
    return schoolCalendarView;
  };

  render() {
    const { Event } = this.props;
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title-section">
              <h4 className="modal-title">{Event.Name}</h4>
            </div>

            <button
              type="button"
              className="close-btn"
              onClick={() => {
                this.closeModal();
              }}
              aria-label={this.props.t("Close_Button")}
            ></button>
          </div>

          <div className="modal-body  modal-popup schoolDetails">
            <div className="schoolname-header dismissal-schedule-header">
              <div className="icon-title">
                <div className="title-info">
                  <div className="mb-12">
                    <div className="icon-label">{"Date:"}</div>
                    <h3 className="school-title gray-title">
                      {moment(Event.Date).format("ddd DD MMM YYYY")}
                    </h3>
                  </div>
                  <div className="mb-12">
                    <div className="icon-label">{"Event Type:"}</div>
                    <div className="blue-text">{Event.Name}</div>
                  </div>

                  <div className="mb-12">
                    <div className="icon-label">{"Event Description:"}</div>
                    <div className="helptext description">
                      {Event.Description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  closeModal = () => {
    this.props.doHideModal();
  };
}

function mapStateToProps(state) {
  return {
    error: state.student.error,
    setHelpListData: state.userAccount.setHelpListData,
    isFetching: loadingSelector(state),
    schoolInfo: state.student.schoolInfo,
    calendarView: state.student.calendarView,
    schoolCalendarEventResponse: state.school.schoolCalendarEvent,
    schoolCalendar: state.school.schoolCalendar,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    doShowModal: (data) => dispatch(showModal(data)),
    doHideModal: () => dispatch(hideModal()),
    dosetHelpList: (data) => dispatch(setHelpList(data)),
    dosetCalendarViewClear: () => dispatch(setCalendarViewClear()),
    doSchoolCalendarEventsType: (data) =>
      dispatch(getSchoolCalendarEventType(data)),
    doGetSchoolCalendar: (data) => dispatch(getSchoolCalendar(data)),
    doGetSchoolCalendarClear: (data) => dispatch(getSchoolCalendarClear(data)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EventDetails)
);
